import { TypedDetailViewProps } from "../../components/detailView/DetailView";
import { UseGtbFormReturn } from "../../hooks/formHandling/useGtbForm";
import "../../components/spinner.css";
import { SupplyChainOperatorDetails } from "../supplyChainOperatorTypes";
import SupplyChainOperatorBasicData from "../../supplyChainOperator/details/SupplyChainOperatorBasicData";
import { useHelpContext } from "../../help/context-sensitivity/useHelpContext";
import EntityDetailView from "../../components/entity/EntityDetailView";
import Tabs, { MultiTabProps, SingleTabProps, TabKey } from "../../components/tabs/Tabs";
import { OrganisationReadOnlyProps } from "../../organisation/details/organisationDetailViewProps";
import SupplyChainOperatorFacilities from "./SupplyChainOperatorFacilities";
import SupplyChainOperatorOtherData from "./SupplyChainOperatorOtherData";
import SupplyChainOperatorAddressData from "./SupplyChainOperatorAddressData";
import SupplyChainOperatorCertificationRelations from "./SupplyChainOperatorCertificationRelations";
import SupplyChainOperatorSupplyingRelations from "./SupplyChainOperatorSupplyingRelations";
import SupplyChainOperatorSubcontractors from "./SupplyChainOperatorSubcontractors";
import SupplyChainOperatorPrincipals from "./SupplyChainOperatorPrincipals";
import { externalIdComponentTypeEnum } from "../../externalId/externalIdTypes";
import SupplyChainOperatorLicences from "./SupplyChainOperatorLicences";
import { useMemo } from "react";
import SupplyChainOperatorNewRelation, { NewSupplyChainOperatorRelationProps } from "./SupplyChainOperatorNewRelation";
import { StandardPickListFlags } from "../../components/Input/pickList/typedPicklist/StandardPickList";
import { CertificationBodyPickListFlags } from "../../components/Input/pickList/typedPicklist/CertificationBodyPickList";
import { SupplyChainOperatorPickListFlags } from "../../components/Input/pickList/typedPicklist/SupplyChainOperatorPickList";
import SupplyChainOperatorScopeCertificates from "./SupplyChainOperatorScopeCertificates";
import SupplyChainOperatorPreGtbTransactionCertificates from "./SupplyChainOperatorPreGtbTransactionCertificates";
import SupplyChainOperatorTransactionCertificates from "./SupplyChainOperatorTransactionCertificates";
import SupplyChainOperatorBuyers from "./SupplyChainOperatorBuyers";
import SupplyChainOperatorSellers from "./SupplyChainOperatorSellers";
import useIsAuthorised from "../../auth/useIsAuthorised";
import { insertIf } from "../../utils/arrayUtils";
import SupplyChainOperatorOrganicMaterialPurchases from "./SupplyChainOperatorOrganicMaterialPurchases";

export default function SupplyChainOperatorDetailView({
    detailViewProps,
    form,
    readOnlyProps,
    reducedAccess,
    subListVisible,
    typeOfRegistrationId,
    externalIdComponentType,
    supplyChainOperatorId,
    newSupplyChainOperatorRelationProps,
    standardPickListFlags,
    certificationBodyPickListFlags,
    supplyChainOperatorPickListFlags,
    requiresLegalNameInEnglish,
    ...rest
}: SupplyChainOperatorDetailViewProps) {
    useHelpContext("supply-chain-operator-details");
    const { hasAccessRight } = useIsAuthorised();

    const sublistTabs = useMemo<SupplyChainOperatorTab[]>(
        () => [
            {
                heading: "supplyChainOperator.detail.facilities_tab",
                element: (
                    <SupplyChainOperatorFacilities
                        form={form}
                        readOnlyProps={readOnlyProps}
                        supplyChainOperatorId={supplyChainOperatorId}
                    />
                ),
            },
            ...insertIf<SupplyChainOperatorTab>(hasAccessRight("readScopeCertificate"), {
                heading: "supplyChainOperator.detail.scopeCertificates_tab",
                element: <SupplyChainOperatorScopeCertificates />,
            }),
            ...insertIf<SupplyChainOperatorTab>(hasAccessRight("readTransactionCertificate"), {
                heading: "supplyChainOperator.detail.transactionCertificates_tab",
                element: <SupplyChainOperatorTransactionCertificates />,
            }),
        ],
        [form, hasAccessRight, readOnlyProps, supplyChainOperatorId]
    );

    const moreListsTab = useMemo<MultiTabProps<SupplyChainOperatorTabKeys>>(
        () => ({
            heading: "supplyChainOperator.detail.moreLists_tab",
            childTabs: [
                {
                    heading: "supplyChainOperator.detail.certificationRelations_tab",
                    element: <SupplyChainOperatorCertificationRelations />,
                },
                {
                    heading: "supplyChainOperator.detail.subcontractors_tab",
                    element: <SupplyChainOperatorSubcontractors />,
                },
                {
                    heading: "supplyChainOperator.detail.principals_tab",
                    element: <SupplyChainOperatorPrincipals />,
                },
                ...insertIf<SupplyChainOperatorTab>(hasAccessRight("readOperationLevelOrganisation"), {
                    heading: "supplyChainOperator.detail.buyers_tab",
                    element: <SupplyChainOperatorBuyers />,
                }),
                ...insertIf<SupplyChainOperatorTab>(hasAccessRight("readOperationLevelOrganisation"), {
                    heading: "supplyChainOperator.detail.sellers_tab",
                    element: <SupplyChainOperatorSellers />,
                }),
                ...insertIf<SupplyChainOperatorTab>(hasAccessRight("readOperationLevelOrganisation"), {
                    heading: "supplyChainOperator.detail.supplyingRelations_tab",
                    element: <SupplyChainOperatorSupplyingRelations />,
                }),
                ...insertIf<SupplyChainOperatorTab>(hasAccessRight("readRawMaterialProduction"), {
                    heading: "supplyChainOperator.detail.organicMaterialPurchases_tab",
                    element: <SupplyChainOperatorOrganicMaterialPurchases />,
                }),
                ...insertIf<SupplyChainOperatorTab>(hasAccessRight("readPreGtbTc"), {
                    heading: "supplyChainOperator.detail.preGtbTransactionCertificates_tab",
                    element: <SupplyChainOperatorPreGtbTransactionCertificates />,
                }),
                ...insertIf<SupplyChainOperatorTab>(hasAccessRight("readMasterData"), {
                    heading: "supplyChainOperator.detail.scoLicences_tab",
                    element: <SupplyChainOperatorLicences />,
                }),
            ],
        }),
        [hasAccessRight]
    );

    const newRelationTabs = useMemo<SupplyChainOperatorTab[]>(
        () => [
            {
                heading: "supplyChainOperator.detail.newRelation_tab",
                element: (
                    <SupplyChainOperatorNewRelation
                        form={form}
                        readOnlyProps={readOnlyProps}
                        newSupplyChainOperatorRelationProps={newSupplyChainOperatorRelationProps}
                        standardPickListFlags={standardPickListFlags}
                        certificationBodyPickListFlags={certificationBodyPickListFlags}
                        supplyChainOperatorPickListFlags={supplyChainOperatorPickListFlags}
                    />
                ),
            },
        ],
        [
            certificationBodyPickListFlags,
            form,
            newSupplyChainOperatorRelationProps,
            readOnlyProps,
            standardPickListFlags,
            supplyChainOperatorPickListFlags,
        ]
    );

    return (
        <EntityDetailView {...rest} detailViewProps={detailViewProps} form={form}>
            <Tabs<SupplyChainOperatorDetails, SupplyChainOperatorTabKeys>
                tabs={[
                    {
                        heading: "supplyChainOperator.detail.basicData_tab",
                        element: (
                            <SupplyChainOperatorBasicData
                                form={form}
                                readOnlyProps={readOnlyProps}
                                reducedAccess={reducedAccess}
                                requiresLegalNameInEnglish={requiresLegalNameInEnglish}
                            />
                        ),
                    },
                    {
                        heading: "supplyChainOperator.detail.addressData_tab",
                        element: (
                            <SupplyChainOperatorAddressData
                                form={form}
                                readOnlyProps={readOnlyProps}
                                typeOfRegistrationId={typeOfRegistrationId}
                            />
                        ),
                    },
                    ...insertIf<SupplyChainOperatorTab>(subListVisible && !reducedAccess, ...sublistTabs),
                    ...insertIf<SupplyChainOperatorTab>(!!newSupplyChainOperatorRelationProps, ...newRelationTabs),
                    ...insertIf<MultiTabProps<SupplyChainOperatorTabKeys>>(
                        subListVisible && !reducedAccess,
                        moreListsTab
                    ),
                    ...insertIf<SupplyChainOperatorTab>(!(reducedAccess && externalIdComponentType === "NONE"), {
                        heading: "supplyChainOperator.detail.otherData_tab",
                        element: (
                            <SupplyChainOperatorOtherData
                                form={form}
                                readOnlyProps={readOnlyProps}
                                reducedAccess={reducedAccess}
                                externalIdComponentType={externalIdComponentType}
                            />
                        ),
                    }),
                ]}
                errors={form.form.formState.errors}
                errorToTab={{
                    id: "internal",
                    isReducedAccess: "internal",
                    countryArea: "supplyChainOperator.detail.addressData_tab",
                    stateProvince: "supplyChainOperator.detail.addressData_tab",
                    systemId: "internal",
                    businessName: "supplyChainOperator.detail.basicData_tab",
                    website: "supplyChainOperator.detail.basicData_tab",
                    organisationalEmail: "supplyChainOperator.detail.basicData_tab",
                    organisationalPhoneNumber: "supplyChainOperator.detail.basicData_tab",
                    startDate: "supplyChainOperator.detail.basicData_tab",
                    endDate: "supplyChainOperator.detail.basicData_tab",
                    town: "supplyChainOperator.detail.addressData_tab",
                    postCode: "supplyChainOperator.detail.addressData_tab",
                    addressDetails1: "supplyChainOperator.detail.addressData_tab",
                    addressDetails2: "supplyChainOperator.detail.addressData_tab",
                    addressDetails3: "supplyChainOperator.detail.addressData_tab",
                    latitude: "supplyChainOperator.detail.addressData_tab",
                    longitude: "supplyChainOperator.detail.addressData_tab",
                    organisationStatus: "supplyChainOperator.detail.basicData_tab",
                    legalName: "supplyChainOperator.detail.basicData_tab",
                    fixedRegistrationId: "supplyChainOperator.detail.basicData_tab",
                    mainFacility: "supplyChainOperator.detail.facilities_tab",
                    brandNames: "supplyChainOperator.detail.otherData_tab",
                    membership: "supplyChainOperator.detail.otherData_tab",
                    publicContactPersonName: "supplyChainOperator.detail.basicData_tab",
                    publicContactPersonEmail: "supplyChainOperator.detail.basicData_tab",
                    organisationalContactPersonName: "supplyChainOperator.detail.basicData_tab",
                    organisationalContactPersonEmail: "supplyChainOperator.detail.basicData_tab",
                    cbInternalId: "supplyChainOperator.detail.otherData_tab",
                    relationType: "supplyChainOperator.detail.newRelation_tab",
                    standard: "supplyChainOperator.detail.newRelation_tab",
                    certificationBody: "supplyChainOperator.detail.newRelation_tab",
                    principalSupplyChainOperator: "supplyChainOperator.detail.newRelation_tab",
                    legalNameInEnglish: "supplyChainOperator.detail.basicData_tab",
                    sellerSupplyChainOperator: "supplyChainOperator.detail.newRelation_tab",
                }}
            />
        </EntityDetailView>
    );
}

type SupplyChainOperatorTab = SingleTabProps<SupplyChainOperatorTabKeys>;

type SupplyChainOperatorTabKeys = TabKey<
    | "supplyChainOperator.detail.basicData_tab"
    | "supplyChainOperator.detail.addressData_tab"
    | "supplyChainOperator.detail.facilities_tab"
    | "supplyChainOperator.detail.scopeCertificates_tab"
    | "supplyChainOperator.detail.transactionCertificates_tab"
    | "supplyChainOperator.detail.certificationRelations_tab"
    | "supplyChainOperator.detail.subcontractors_tab"
    | "supplyChainOperator.detail.principals_tab"
    | "supplyChainOperator.detail.buyers_tab"
    | "supplyChainOperator.detail.sellers_tab"
    | "supplyChainOperator.detail.supplyingRelations_tab"
    | "supplyChainOperator.detail.organicMaterialPurchases_tab"
    | "supplyChainOperator.detail.scoLicences_tab"
    | "supplyChainOperator.detail.preGtbTransactionCertificates_tab"
    | "supplyChainOperator.detail.newRelation_tab"
    | "supplyChainOperator.detail.otherData_tab"
    | "supplyChainOperator.detail.moreLists_tab"
>;

export interface SupplyChainOperatorDetailViewProps extends TypedDetailViewProps<SupplyChainOperatorDetails> {
    readOnlyProps: SupplyChainOperatorReadOnlyProps;
    subListVisible?: boolean;
    reducedAccess: boolean;
    typeOfRegistrationId?: string;
    externalIdComponentType: externalIdComponentTypeEnum;
    supplyChainOperatorId?: string;
    newSupplyChainOperatorRelationProps?: NewSupplyChainOperatorRelationProps;
    standardPickListFlags?: StandardPickListFlags;
    certificationBodyPickListFlags?: CertificationBodyPickListFlags;
    supplyChainOperatorPickListFlags?: SupplyChainOperatorPickListFlags;
    requiresLegalNameInEnglish: boolean;
}

export interface SupplyChainOperatorDetailViewTabProps {
    form: UseGtbFormReturn<SupplyChainOperatorDetails>;
    readOnlyProps: SupplyChainOperatorReadOnlyProps;
    externalIdComponentType?: externalIdComponentTypeEnum;
    typeOfRegistrationId?: string;
    reducedAccess?: SupplyChainOperatorDetailViewProps["reducedAccess"];
    supplyChainOperatorId?: string;
    newSupplyChainOperatorRelationProps?: NewSupplyChainOperatorRelationProps;
    standardPickListFlags?: StandardPickListFlags;
    certificationBodyPickListFlags?: CertificationBodyPickListFlags;
    supplyChainOperatorPickListFlags?: SupplyChainOperatorPickListFlags;
    requiresLegalNameInEnglish?: boolean;
}

export interface SupplyChainOperatorReadOnlyProps extends OrganisationReadOnlyProps {
    isLegalNameReadOnly: boolean;
    isPublicContactPersonNameReadOnly: boolean;
    isPublicContactPersonEmailReadOnly: boolean;
    isOrganisationalContactPersonNameReadOnly: boolean;
    isOrganisationalContactPersonEmailReadOnly: boolean;
    isFixedRegistrationIdReadOnly: boolean;
    isMainFacilityReadOnly: boolean;
    isBrandNamesReadOnly: boolean;
    isMembershipReadOnly: boolean;
    isCbInternalIdReadOnly: boolean;
    isLegalNameInEnglishReadOnly: boolean;
}
