import Radiobutton, { GtbRadiobuttonProps } from "./Radiobutton";
import { joinClassNames } from "../../../utils/StringUtils";
import AccessibleLabel from "../../AccessibleLabel";
import "./radiobuttonGroup.css";
import useGtbTranslation, { I18nKey } from "../../../i18n/useGtbTranslation";
import useGtbTooltip from "../../tooltip/useGtbTooltip";
import React, { useCallback } from "react";
import HelperText, { HelperTextProps } from "../HelperText";

export interface RadiobuttonGroupProps<ItemType> {
    label: I18nKey;
    options: ItemType[] | undefined;
    optionProps?: (item: ItemType) => Partial<GtbRadiobuttonProps>;
    orientation?: "horizontal" | "vertical";
    itemLabel: (option: ItemType) => string;
    itemId: keyof ItemType;
    name: string;
    readOnly?: boolean;
    className?: string;
    onChange?: GtbRadiobuttonProps["onChange"];
    helperText?: HelperTextProps["helperText"];
    error?: boolean;
}

function _RadiobuttonGroup<ItemType>(
    {
        label,
        options,
        itemId,
        itemLabel,
        name,
        className,
        readOnly,
        orientation = "horizontal",
        error,
        helperText,
        optionProps,
        ...rest
    }: RadiobuttonGroupProps<ItemType>,
    ref: React.ForwardedRef<HTMLInputElement>
) {
    const { tooltipProps, tooltip } = useGtbTooltip(label);

    return (
        <div
            className={joinClassNames(
                orientation === "horizontal" ? "horizontalRadiobuttonGroup" : "verticalRadiobuttonGroup",
                error ? "inputError" : null,
                className
            )}
        >
            <div {...tooltipProps}>
                <AccessibleLabel id={"radio_" + name + "_label"} label={label} />
                {tooltip}
            </div>
            <div
                aria-readonly={readOnly}
                role="radiogroup"
                aria-labelledby={"radio_" + name + "_label"}
                className={orientation === "horizontal" ? "flexRow" : "flexColumn"}
            >
                {options?.map((option) => (
                    <Radiobutton
                        readOnly={readOnly}
                        key={name + "_" + option[itemId]}
                        id={name + "_" + option[itemId]}
                        value={option[itemId]}
                        label={itemLabel(option)}
                        name={name}
                        ref={ref}
                        {...(optionProps ? optionProps(option) : {})}
                        {...rest}
                    />
                ))}
            </div>
            <HelperText helperText={helperText} />
        </div>
    );
}

const RadiobuttonGroup = React.forwardRef(_RadiobuttonGroup) as unknown as <ItemType>(
    props: RadiobuttonGroupProps<ItemType> & { ref?: React.ForwardedRef<HTMLInputElement> }
) => ReturnType<typeof _RadiobuttonGroup>;

export default RadiobuttonGroup;

/**
 * Helper to build the options array out of a object which keys should be the identifier and which values should be the labels
 * @param obj the obj which should be converted to a options array
 */
export const buildRadiobuttonOptionsFromObject: (obj: { [key: string]: string }) => {
    options: { id: string; label: string }[];
    itemId: "id";
    itemLabel: (item: { label: string }) => string;
} = (obj) => {
    return {
        options: Object.entries(obj).map((e) => {
            return { id: e[0], label: e[1] };
        }),
        itemId: "id",
        itemLabel: (item) => item.label,
    };
};

export function useTranslatedRadiobuttonOptionsFromObject(): (obj: { [key: string]: I18nKey }) => {
    options: { id: string; label: I18nKey }[];
    itemId: "id";
    itemLabel: (item: { label: I18nKey }) => string;
} {
    const translation = useGtbTranslation();

    return useCallback(
        (obj: { [key: string]: I18nKey }) => ({
            options: Object.entries(obj).map((e) => {
                return { id: e[0], label: e[1] };
            }),
            itemId: "id",
            itemLabel: (item: { label: I18nKey }) => translation(item.label),
        }),
        [translation]
    );
}
