import { useMemo } from "react";
import useIsAuthorised from "../auth/useIsAuthorised";
import { useDeleteAction, useShowDetailsAction } from "../utils/defaultActions";
import { useQueryBuilder } from "../components/grid/component/useQueryBuilder";
import useResolvedRoute from "../components/routing/useResolvedRoute";
import { SubListProps } from "../components/grid/SubList";
import { PreGtbTransactionCertificateSummary } from "./preGtbTransactionCertificateTypes";
import { defaultPreGtbTransactionCertificateSortOption } from "./usePreGtbTransactionCertificateListView";
import { GridColumn } from "../components/grid/component/Grid";
import { buildDataColumn, buildDateColumn, buildTextColumn } from "../components/grid/ColumnProvider";
import { EnumEntityType } from "../components/Input/pickList/typedEnumPicklist/enumEntityType";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";

const columns: GridColumn<PreGtbTransactionCertificateSummary>[] = [
    buildTextColumn({ key: "tcNumber", title: "preGtbTransactionCertificate.list.tcNumber_column", minWidth: 141 }),
    buildTextColumn({
        key: "standardAcronym",
        title: "preGtbTransactionCertificate.list.standard_column",
        minWidth: 126,
    }),
    buildDateColumn({
        key: "dateOfIssue",
        title: "preGtbTransactionCertificate.list.dateOfIssue_column",
        minWidth: 156,
    }),
    buildDataColumn<PreGtbTransactionCertificateSummary, EnumEntityType>({
        key: "status",
        title: "preGtbTransactionCertificate.list.status_column",
        url: "/certificate-service/references/pre-gtb-tc-statuses",
        itemId: "id",
        itemLabel: "name",
        minWidth: 232,
    }),
    buildTextColumn({
        key: "sellersLicenceNumber",
        title: "preGtbTransactionCertificate.list.sellersLicenceNumber_column",
        minWidth: 123,
        visible: false,
    }),
];

export default function usePreGtbTransactionCertificateSubList() {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedId, getResolvedEditUrl, getResolvedCreateUrl } = useResolvedRoute();

    const { query, columnOptions, forceReload } = useQueryBuilder<PreGtbTransactionCertificateSummary>(
        `/certificate-service/pre-gtb-tcs`,
        {
            sort: defaultPreGtbTransactionCertificateSortOption,
            filterActive: false,
            supplyChainOperatorId: getResolvedId("supplyChainOperator"),
        }
    );

    const showDetailsAction = useShowDetailsAction(
        (item: PreGtbTransactionCertificateSummary) => getResolvedEditUrl("preGtbTransactionCertificate", item.id),
        query.parameter
    );

    const deleteAction = useDeleteAction(
        (item: PreGtbTransactionCertificateSummary) => `/certificate-service/pre-gtb-tcs/${item.id}`,
        () => forceReload(),
        "deleteTransactionCertificate"
    );

    const contextMenuActions = useGridRowContextMenuActions(showDetailsAction, deleteAction);

    return useMemo<SubListProps<PreGtbTransactionCertificateSummary>>(
        () => ({
            query,
            columns: columns,
            uniqueDataKey: "id",
            onItemDoubleClick: showDetailsAction,
            header: "preGtbTransactionCertificate.list.header_sublist",
            itemActions: contextMenuActions,
            columnOptions,
            createUrl: hasAccessRight("createTransactionCertificate")
                ? getResolvedCreateUrl("supplyChainOperatorPreGtbTransactionCertificate")
                : undefined,
            tableName: "supplyChainOperatorPreGtbTransactionCertificate",
        }),
        [columnOptions, contextMenuActions, getResolvedCreateUrl, hasAccessRight, query, showDetailsAction]
    );
}
