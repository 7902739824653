import { UseGtbFormReturn } from "../../../../../../hooks/formHandling/useGtbForm";
import { TcMaterialDetails } from "../tcMaterialTypes";
import { joinClassNames } from "../../../../../../utils/StringUtils";
import GtbTextField from "../../../../../../components/Input/GtbTextField";
import RawMaterialPickList from "../../../../../../components/Input/pickList/typedPicklist/RawMaterialPickList";
import { LabeledCheckbox } from "../../../../../../components/Input/Checkbox";
import GtbNumberField, {
    GtbNumberFieldOneFixedDecimal,
    GtbNumberFieldProps,
} from "../../../../../../components/Input/GtbNumberField";
import { ReadOnlyProps } from "../../../../../../utils/typeUtils";
import "./tcMaterialBasicData.css";
import ControlledPickList from "../../../../../../components/Input/pickList/ControlledPickList";

export default function TcMaterialBasicData({
    form,
    rawMaterialPrintVisible,
    onPercentageChange,
    readOnly,
}: TcMaterialBasicDataProps) {
    return (
        <div className={joinClassNames("formGrid", "tcMaterialBasicData")}>
            <GtbTextField
                label={"tcMaterial.detail.productNumber_input"}
                className={"productNumber"}
                readOnly
                {...form.registerWithErrors("tcProductTcProductNumber")}
            />
            <GtbTextField
                label={"tcMaterial.detail.productCategoryPrint_input"}
                className={"productCategoryPrint"}
                readOnly
                {...form.registerWithErrors("tcProductProductCategoryPrint")}
            />
            <GtbTextField
                label={"tcMaterial.detail.productDetailPrint_input"}
                className={"productDetailPrint"}
                readOnly
                {...form.registerWithErrors("tcProductProductDetailPrint")}
            />
            <GtbTextField
                label={"tcMaterial.detail.labelGrade_input"}
                className={"labelGrade"}
                readOnly
                {...form.registerWithErrors("tcProductLabelGradeLabelGrade")}
            />
            <RawMaterialPickList
                label={"tcMaterial.detail.rawMaterial_input"}
                control={form.form.control}
                className={"rawMaterial"}
                name={"material"}
                readOnly
            />
            {rawMaterialPrintVisible && (
                <GtbTextField
                    label={"tcMaterial.detail.rawMaterialPrint_input"}
                    className={"rawMaterialPrint"}
                    readOnly
                    {...form.registerWithErrors("materialPrint")}
                />
            )}
            <LabeledCheckbox
                id={"certified"}
                label={"tcMaterial.detail.certified_input"}
                className={"certified"}
                {...form.form.register("certified")}
                readOnly
            />
            <GtbNumberField
                label={"tcMaterial.detail.percentage_input"}
                className={"percentage"}
                readOnly={readOnly.percentage}
                unit="%"
                decimalPlaces={2}
                {...form.registerNumberWithErrors("percentage", { onChange: onPercentageChange })}
            />
            <GtbNumberFieldOneFixedDecimal
                label={"tcMaterial.detail.certifiedWeight_input"}
                name={"certifiedWeight"}
                className={"certifiedWeight"}
                control={form.form.control}
                decimalPlaces={1}
                unit="kg"
                readOnly
            />
            <ControlledPickList
                label={"tcMaterial.detail.origin_input"}
                control={form.form.control}
                name={"origin"}
                className={"origin"}
                data={[]}
                loadAdditionalItem={(item: string) => {
                    return Promise.resolve({ name: item });
                }}
                multiple
                itemId="name"
                itemLabel="name"
                readOnly
            />
        </div>
    );
}

export interface TcMaterialBasicDataProps {
    form: UseGtbFormReturn<TcMaterialDetails>;
    rawMaterialPrintVisible: boolean;
    readOnly: ReadOnlyProps<Pick<TcMaterialDetails, "percentage">>;
    onPercentageChange: GtbNumberFieldProps["onChange"];
}
