import { joinClassNames } from "../../../utils/StringUtils";
import { UseGtbFormReturn } from "../../../hooks/formHandling/useGtbForm";
import "./additionalMaterialBasicData.css";
import { MaterialContentRuleDetails } from "../../materialContentRuleTypes";
import GtbTextField from "../../../components/Input/GtbTextField";
import GtbNumberField from "../../../components/Input/GtbNumberField";
import { AdditionalMaterialDetails } from "../additionalMaterialTypes";
import CertifiedMaterialPickList from "../../../components/Input/pickList/typedPicklist/CertifiedMaterialPickList";
import { LabelGradeReference } from "../../../labelGrade/labelGradeTypes";

export default function AdditionalMaterialBasicData({
    form,
    readOnly,
    materialContentRule,
    labelGrade,
    standard,
    standardVersion,
}: AdditionalMaterialBasicDataProps) {
    return (
        <div className={joinClassNames("additionalMaterialBasicData", "formGrid")}>
            <GtbTextField
                readOnly
                label="additionalMaterial.detail.standardVersion_input"
                className="standardVersion"
                value={standardVersion}
            />
            <GtbTextField
                readOnly
                label="additionalMaterial.detail.labelGrade_input"
                className="labelGrade"
                value={labelGrade?.labelGrade}
            />
            <GtbNumberField
                readOnly
                label="additionalMaterial.detail.minimumPercentage_input"
                className="minPercentage"
                unit="%"
                value={labelGrade?.minPercentage}
            />
            <GtbTextField
                readOnly
                label="additionalMaterial.detail.certifiedMaterial_input"
                className="certifiedMaterial"
                value={`${materialContentRule?.certifiedMaterialName} (${materialContentRule?.certifiedMaterialCode})`}
            />
            {materialContentRule && (
                <CertifiedMaterialPickList
                    control={form.form.control}
                    standardId={standard}
                    className="rawMaterial"
                    name="rawMaterial"
                    label="additionalMaterial.detail.rawMaterial_input"
                    readOnly={readOnly}
                />
            )}
            <GtbNumberField
                {...form.registerNumberWithErrors("maximumPercentage")}
                readOnly={readOnly}
                label="additionalMaterial.detail.maximumPercentage_input"
                className="maximumPercentage"
                unit="%"
            />
        </div>
    );
}

export interface AdditionalMaterialBasicDataProps {
    form: UseGtbFormReturn<AdditionalMaterialDetails>;
    readOnly: boolean;
    materialContentRule?: MaterialContentRuleDetails;
    labelGrade?: LabelGradeReference;
    standard?: string;
    standardVersion?: string;
}
