import { buildSchema, validateString } from "../utils/yupExtension";
import { ExternalIdDetails, externalIdTypeEnum } from "./externalIdTypes";

export const externalIdValidationSchema = buildSchema<ExternalIdDetails>({
    parent: validateString().isRequired(),
    certificationBody: validateString().when("type", {
        is: (type: externalIdTypeEnum) => type === "CB",
        then: validateString().isRequired(),
    }),
    externalId: validateString().isRequired().hasMaxLength(40),
});
