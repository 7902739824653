import UserDetailView from "./UserDetailView";

import useUserProfile from "./useUserProfile";
import { useHelpContext } from "../../help/context-sensitivity/useHelpContext";

function UserProfileView() {
    const userDetailViewProps = useUserProfile();

    useHelpContext("profile");

    return <UserDetailView {...userDetailViewProps} />;
}

export default UserProfileView;
