import GtbTextField, { GtbTextFieldProps } from "./GtbTextField";
import { Controller, Path } from "react-hook-form";
import { FieldValues } from "react-hook-form/dist/types";
import { UseGtbFormReturn } from "../../hooks/formHandling/useGtbForm";
import useGtbTranslation, { I18nKey } from "../../i18n/useGtbTranslation";

/**
 * If possible use GtbTextField instead. This is only used in cases a rerender of the component is needed because of an
 * internal value change
 */
export default function ControlledTextField<FormItemType extends FieldValues>({
    control,
    name,
    unit,
    ...rest
}: ControlledTextFieldProps<FormItemType>) {
    const translation = useGtbTranslation();

    return (
        <Controller
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <GtbTextField
                    onChange={onChange}
                    value={value ?? ""}
                    error={!!error}
                    helperText={error?.message && translation(error.message as I18nKey)}
                    endAdornment={unit}
                    {...rest}
                />
            )}
            name={name}
        />
    );
}

interface ControlledTextFieldProps<FormItemType extends FieldValues>
    extends Omit<GtbTextFieldProps, "value" | "onChange" | "helperText" | "error"> {
    control: UseGtbFormReturn<FormItemType>["form"]["control"];
    name: Path<FormItemType>;
    unit?: string;
}
