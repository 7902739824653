import * as React from "react";
import { ComponentPropsWithoutRef, FormEvent, FormEventHandler, useMemo } from "react";

interface FormProps extends ComponentPropsWithoutRef<"form"> {}

function Form({ children, onSubmit, ...props }: FormProps) {
    const _onSubmit = useMemo(() => buildSubmitHandler(onSubmit), [onSubmit]);
    return (
        <form {..._onSubmit} {...props}>
            {children}
        </form>
    );
}

export default Form;

const buildSubmitHandler = (onSubmit?: FormEventHandler<HTMLFormElement>) => {
    return onSubmit
        ? {
              onSubmit: (e: FormEvent<HTMLFormElement>) => {
                  e.preventDefault();
                  onSubmit(e);
              },
          }
        : {};
};
