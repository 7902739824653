import React from "react";
import OrganicMaterialTransactionCertificateBasicData, {
    OrganicMaterialTransactionCertificateBasicDataProps,
} from "./OrganicMaterialTransactionCertificateBasicData";
import { OrganicMaterialTransactionCertificateDetails } from "../organicMaterialTransactionCertificateTypes";
import { useHelpContext } from "../../../../../../help/context-sensitivity/useHelpContext";
import EntityDetailView from "../../../../../../components/entity/EntityDetailView";
import Tabs, { TabKey } from "../../../../../../components/tabs/Tabs";
import { TypedDetailViewProps } from "../../../../../../components/detailView/DetailView";

export default function OrganicMaterialTransactionCertificateDetailView({
    detailViewProps,
    form,
    readOnly,
    tcFileUrl,
    ...rest
}: OrganicMaterialTransactionCertificateDetailViewProps) {
    useHelpContext("organic-material-transaction-certificate-details");

    return (
        <EntityDetailView {...rest} detailViewProps={detailViewProps} form={form}>
            <Tabs<OrganicMaterialTransactionCertificateDetails, OrganicMaterialTransactionCertificateTabs>
                tabs={[
                    {
                        heading: "organicMaterialTransactionCertificate.detail.basicData_tab",
                        element: (
                            <OrganicMaterialTransactionCertificateBasicData
                                form={form}
                                readOnly={readOnly}
                                tcFileUrl={tcFileUrl}
                            />
                        ),
                    },
                ]}
                errors={form.form.formState.errors}
                errorToTab={{
                    id: "internal",
                    buyerLegalName: "organicMaterialTransactionCertificate.detail.basicData_tab",
                    certifiedOrganicMaterialName: "organicMaterialTransactionCertificate.detail.basicData_tab",
                    organicMaterialProducerName: "organicMaterialTransactionCertificate.detail.basicData_tab",
                    organicMaterialProductionName: "organicMaterialTransactionCertificate.detail.basicData_tab",
                    tcNumber: "organicMaterialTransactionCertificate.detail.basicData_tab",
                    tcType: "organicMaterialTransactionCertificate.detail.basicData_tab",
                    standard: "organicMaterialTransactionCertificate.detail.basicData_tab",
                    tcCopy: "organicMaterialTransactionCertificate.detail.basicData_tab",
                }}
            />
        </EntityDetailView>
    );
}

type OrganicMaterialTransactionCertificateTabs = TabKey<"organicMaterialTransactionCertificate.detail.basicData_tab">;

export interface OrganicMaterialTransactionCertificateDetailViewProps
    extends TypedDetailViewProps<OrganicMaterialTransactionCertificateDetails>,
        OrganicMaterialTransactionCertificateBasicDataProps {}
