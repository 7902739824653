import { joinClassNames } from "../../utils/StringUtils";
import GtbTextField from "../../components/Input/GtbTextField";
import "./labelGradeBasicData.css";
import { LabelGradeDetailViewTabProps } from "./LabelGradeDetailView";
import SubList from "../../components/grid/SubList";
import TypeOfMaterialPicklist from "../../components/Input/pickList/typedPicklist/TypeOfMaterialPicklist";
import GtbNumberField from "../../components/Input/GtbNumberField";
import useMaterialContentRuleSubList from "../../materialContentRule/useMaterialContentRuleSubList";

function LabelGradeBasicData({ form, readOnly, standardVersionName, subListVisible }: LabelGradeDetailViewTabProps) {
    const materialContentRuleListOptions = useMaterialContentRuleSubList();

    return (
        <div className={joinClassNames("labelGradeBasicData", "formGrid")}>
            <GtbTextField
                {...form.registerWithErrors("labelGrade")}
                readOnly={readOnly}
                label={"labelGrade.detail.labelGrade_input"}
                className="name"
            />
            <GtbTextField
                label={"labelGrade.detail.standardVersion_input"}
                className="standardVersion"
                readOnly
                value={standardVersionName}
            />
            <TypeOfMaterialPicklist
                control={form.form.control}
                readOnly={readOnly}
                name="typeOfMaterial"
                className="typeOfMaterial"
                label="labelGrade.detail.typeOfMaterial_input"
            />
            <GtbNumberField
                {...form.registerNumberWithErrors("minPercentage")}
                readOnly={readOnly}
                label="labelGrade.detail.minPercentage_input"
                className="minPercentage"
                unit="%"
            />
            {subListVisible && <SubList {...materialContentRuleListOptions} className="materialContentRules" />}
        </div>
    );
}

export default LabelGradeBasicData;
