import { TypedDetailViewProps } from "../../components/detailView/DetailView";
import { ApplicationLogDetails } from "../applicationLogTypes";
import useApplicationLogEdit from "./useApplicationLogEdit";
import { UseGtbFormReturn } from "../../hooks/formHandling/useGtbForm";
import ApplicationLogData from "./ApplicationLogData";
import { useHelpContext } from "../../help/context-sensitivity/useHelpContext";
import EntityDetailView from "../../components/entity/EntityDetailView";

function ApplicationLogEditView() {
    const { form, ...rest } = useApplicationLogEdit();
    useHelpContext("application-log-details");
    return (
        <EntityDetailView form={form} {...rest}>
            <ApplicationLogData form={form} />
        </EntityDetailView>
    );
}

export default ApplicationLogEditView;

export interface ApplicationLogEditViewProps extends TypedDetailViewProps<ApplicationLogDetails> {}

export interface ApplicationLogDetailViewTabProps {
    form: UseGtbFormReturn<ApplicationLogDetails>;
}
