import ExternalView from "../external/ExternalView";
import LoginForm from "./LoginForm";
import useLogin from "./useLogin";
import OneTimePasswordForm from "./OneTimePasswordForm";
import React from "react";

function LoginView() {
    const {
        loginRegister,
        isLoginLoading,
        onLogin,
        additionalAuthFactorType,
        oneTimePasswordForm,
        isOneTimePasswordLoading,
        onOneTimePassword,
        isOneTimePasswordExpired,
        backToLogin,
        isAdditionalAuthFactorSkippable,
        infoMessage,
    } = useLogin();
    return (
        <ExternalView header="loginPage.login_header">
            {additionalAuthFactorType === "NONE" ? (
                <LoginForm
                    register={loginRegister}
                    isLoading={isLoginLoading}
                    onSubmit={onLogin}
                    infoMessage={infoMessage}
                />
            ) : (
                <OneTimePasswordForm
                    form={oneTimePasswordForm}
                    isLoading={isOneTimePasswordLoading}
                    onSubmit={onOneTimePassword}
                    isOneTimePasswordExpired={isOneTimePasswordExpired}
                    backToLogin={backToLogin}
                    additionalAuthFactorType={additionalAuthFactorType}
                    isAdditionalAuthFactorSkippable={isAdditionalAuthFactorSkippable}
                />
            )}
        </ExternalView>
    );
}

export default LoginView;
