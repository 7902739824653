import React, { ChangeEventHandler } from "react";
import { AccessibleCheckbox } from "../../../Input/Checkbox";
import { I18nKey } from "../../../../i18n/useGtbTranslation";

interface SelectionCellProps {
    onChange: ChangeEventHandler<HTMLInputElement>;
    selected: boolean;
    ariaLabel: I18nKey;
}

const SelectionCell: React.FC<SelectionCellProps> = ({ ariaLabel, onChange, selected }) => {
    return (
        <AccessibleCheckbox className="listItemSelection" label={ariaLabel} onChange={onChange} checked={selected} />
    );
};

export default React.memo(SelectionCell);
