import { FormEventHandler, useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatchBeforeLeave } from "../routing/GtbRouter";
import GtbButton from "../GtbButton";
import useGtbTranslation from "../../i18n/useGtbTranslation";
import {
    UseFormAfterSubmitAction,
    UseFormErrorResolver,
    UseFormSubmitCallback,
} from "../../hooks/formHandling/useFormSubmit";
import { FieldValues } from "react-hook-form/dist/types";
import { backendUrlType } from "../../hooks/useAxios";
import { HistoryParams } from "../../history/useHistoryDialog";
import useUnsavedChangesDialog from "./useUnsavedChangesDialog";
import useErrorDialog from "../dialog/useErrorDialog";

function useDetailView<ItemType extends FieldValues>({
    onSubmit,
    afterSubmitAction,
    isLoading,
    isDirty,
    reloadData,
    resolveErrors,
}: UseDetailViewProps<ItemType>): UseDetailViewReturn {
    const translation = useGtbTranslation();
    const { setBeforeLeaveAction, unsetBeforeLeaveAction } = useDispatchBeforeLeave();
    const { showDialog, closeDialog } = useErrorDialog();
    const { showDialog: showUnsavedChangesDialog, closeDialog: closeUnsavedChangesDialog } = useUnsavedChangesDialog();

    const handleSubmit = useCallback(
        (afterSubmit?: Function) => {
            if (!(!isLoading && onSubmit)) {
                return;
            }
            onSubmit((item) => afterSubmit?.(item), resolveErrors);
        },
        [isLoading, onSubmit, resolveErrors]
    );

    const updateBeforeLeaveAction = useCallback(
        () =>
            setBeforeLeaveAction((e) => {
                showUnsavedChangesDialog(
                    () => {
                        closeUnsavedChangesDialog();
                        e.proceedNavigation();
                    },
                    async () => {
                        closeUnsavedChangesDialog();
                        handleSubmit(() => e.proceedNavigation());
                    }
                );
            }),
        [closeUnsavedChangesDialog, handleSubmit, setBeforeLeaveAction, showUnsavedChangesDialog]
    );

    const openConfirmDialogBeforeLeave = useRef(updateBeforeLeaveAction);

    useEffect(() => {
        openConfirmDialogBeforeLeave.current = updateBeforeLeaveAction;
    }, [updateBeforeLeaveAction]);

    useEffect(() => {
        if (isDirty) {
            openConfirmDialogBeforeLeave.current();
        } else if (!isDirty) {
            unsetBeforeLeaveAction();
        }
    }, [isDirty, unsetBeforeLeaveAction]);

    const onFormSubmit = useCallback(() => {
        handleSubmit(afterSubmitAction);
    }, [afterSubmitAction, handleSubmit]);

    const undoChanges = useCallback(() => {
        if (isDirty) {
            showDialog({
                title: "components.detailView.undoChanges_dialogTitle",
                message: "components.detailView.undoChanges_dialogText",
                footer: (
                    <>
                        <GtbButton
                            variant="secondary"
                            onClick={async () => {
                                closeDialog();
                                reloadData();
                            }}
                        >
                            {translation("components.dialog.yes_button")}
                        </GtbButton>
                        <GtbButton onClick={closeDialog}>{translation("components.dialog.no_button")}</GtbButton>
                    </>
                ),
            });
        } else {
            reloadData();
        }
    }, [isDirty, showDialog, translation, closeDialog, reloadData]);

    return useMemo(() => {
        return {
            onSubmit: onSubmit ? onFormSubmit : undefined,
            isLoading,
            undoChanges,
        };
    }, [isLoading, onFormSubmit, onSubmit, undoChanges]);
}

export default useDetailView;

export interface UseDetailViewProps<ItemType extends FieldValues> {
    onSubmit?: UseFormSubmitCallback<ItemType>;
    afterSubmitAction?: UseFormAfterSubmitAction<ItemType>;
    resolveErrors?: UseFormErrorResolver<ItemType>;
    isLoading: boolean;
    isDirty?: boolean;
    reloadData: () => void;
    noteRootUrl?: backendUrlType;
    goToMapUrl?: string;
    historyParams?: HistoryParams;
}

export interface UseDetailViewReturn {
    onSubmit?: FormEventHandler<HTMLFormElement>;
    isLoading?: boolean;
    undoChanges?: () => void;
}
