import { TcMaterialDetailViewProps } from "./TcMaterialDetailView";
import { defaultTcMaterialSortOption } from "../useTcMaterialSubList";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { buildTcMaterialValidationSchema } from "./tcMaterialValidationSchema";
import useIsAuthorised from "../../../../../../auth/useIsAuthorised";
import useResolvedRoute from "../../../../../../components/routing/useResolvedRoute";
import { transactionCertificateReadOnlyByStatus } from "../../../../../transactionCertificateTypes";
import { TcMaterialDetails } from "../tcMaterialTypes";
import { useCallback, useMemo } from "react";
import useDomainEntityDetailsEdit from "../../../../../../hooks/details/useDomainEntityDetailsEdit";
import useSetFormValue from "../../../../../../hooks/formHandling/useSetFormValue";
import { calculateCertifiedMaterialWeight } from "../../../../../../utils/calculateWeightUtils";

export default function useTcMaterialEdit(): TcMaterialDetailViewProps {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedId } = useResolvedRoute();

    const tcId = getResolvedId("transactionCertificate");
    const tcProductId = getResolvedId("tcProduct");
    const tcMaterialId = getResolvedId("tcMaterial");

    const canSave = useCallback(
        (item: TcMaterialDetails) =>
            !transactionCertificateReadOnlyByStatus(item.transactionCertificateStatus) &&
            hasAccessRight("updateTransactionCertificate", [item.transactionCertificateStatus]),
        [hasAccessRight]
    );

    const { detailViewProps, form, isReadOnly, title, recordNavigationProps, initialData } =
        useDomainEntityDetailsEdit<TcMaterialDetails>({
            baseUrl: `/certificate-service/transaction-certificates/${tcId}/tc-products/${tcProductId}/tc-materials/${tcMaterialId}`,
            frontendRoute: "tcMaterial",
            recordNavigationDefaultSort: defaultTcMaterialSortOption,
            titleBuilder: (item) => item.materialPrint ?? item.materialName,
            resolver: yupResolver(buildTcMaterialValidationSchema),
            canSave,
            historyColumns: [{ identifier: "percentage", title: "tcMaterial.history.percentage_column" }],
        });

    const readOnly = useMemo<TcMaterialDetailViewProps["readOnly"]>(
        () => ({
            percentage: isReadOnly,
        }),
        [isReadOnly]
    );

    const setFormValue = useSetFormValue(form.form);

    const onPercentageChange: TcMaterialDetailViewProps["onPercentageChange"] = useCallback(
        (event) => {
            if (!initialData?.certified) {
                return;
            }
            const percentage = event.target.value;
            const calculatedCertifiedWeight = calculateCertifiedMaterialWeight(
                percentage,
                initialData.tcProductNetShippingWeight,
                initialData.tcProductSupplementaryWeight
            );
            setFormValue("certifiedWeight", calculatedCertifiedWeight);
        },
        [
            initialData?.certified,
            initialData?.tcProductNetShippingWeight,
            initialData?.tcProductSupplementaryWeight,
            setFormValue,
        ]
    );

    return {
        detailViewProps,
        form,
        readOnly,
        title,
        recordNavigationProps,
        rawMaterialPrintVisible: !!initialData?.materialPrint,
        onPercentageChange,
    };
}
