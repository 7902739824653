import { useHelpContext } from "../../help/context-sensitivity/useHelpContext";
import { TypedDetailViewProps } from "../../components/detailView/DetailView";
import EntityDetailView from "../../components/entity/EntityDetailView";
import { CertificationRelationDetails } from "../certificationRelationTypes";
import CertificationBodyPickList from "../../components/Input/pickList/typedPicklist/CertificationBodyPickList";
import { joinClassNames } from "../../utils/StringUtils";
import "./certificationRelationData.css";
import GtbTextField from "../../components/Input/GtbTextField";
import ControlledDatePicker from "../../components/Input/datepicker/ControlledDatePicker";
import SupplyChainOperatorPickList, {
    SupplyChainOperatorPickListFlags,
} from "../../components/Input/pickList/typedPicklist/SupplyChainOperatorPickList";
import StandardPickList from "../../components/Input/pickList/typedPicklist/StandardPickList";
import EnumEntityRadiobuttonGroup from "../../components/Input/radiobutton/EnumEntityRadiobuttonGroup";
import useDefaultStandardPickListFlagsForRelations from "../../hooks/details/useDefaultStandardPickListFlagsForRelations";
import { LabeledCheckbox } from "../../components/Input/Checkbox";
import useSetFormValue from "../../hooks/formHandling/useSetFormValue";
import ControlledTextField from "../../components/Input/ControlledTextField";

export default function CertificationRelationDetailView({
    detailViewProps,
    form,
    supplyChainOperatorPickListFlags,
    readOnlyProps,
    ...rest
}: CertificationRelationDetailViewProps) {
    useHelpContext("certification-relation-details");

    const setFormValue = useSetFormValue(form.form);
    const standardPickListFlags = useDefaultStandardPickListFlagsForRelations();

    return (
        <EntityDetailView {...rest} detailViewProps={detailViewProps} form={form}>
            <div className={joinClassNames("certificationRelationData", "formGrid")}>
                <CertificationBodyPickList
                    control={form.form.control}
                    name="certificationBody"
                    label="certificationRelation.detail.certificationBody_input"
                    className="certificationBody"
                    readOnly={readOnlyProps.certificationBodyReadOnly}
                />
                <SupplyChainOperatorPickList
                    control={form.form.control}
                    name="supplyChainOperator"
                    label="certificationRelation.detail.supplyChainOperator_input"
                    className="supplyChainOperator"
                    readOnly={readOnlyProps.supplyChainOperatorReadOnly}
                    flags={supplyChainOperatorPickListFlags}
                    onChangeListener={(_event, _value, selectedOption) =>
                        setFormValue("recertificationEmail", selectedOption?.organisationalEmail)
                    }
                />
                <StandardPickList
                    control={form.form.control}
                    name="standard"
                    label="certificationRelation.detail.standard_input"
                    className="standard"
                    readOnly={readOnlyProps.standardReadOnly}
                    flags={standardPickListFlags}
                />
                <GtbTextField
                    {...form.registerWithErrors("cbLicenceNumber")}
                    label="certificationRelation.detail.cbLicenceNumber_input"
                    className="cbLicenceNumber"
                    readOnly={readOnlyProps.cbLicenceNumberReadOnly}
                />
                <ControlledTextField
                    label="certificationRelation.detail.recertificationEmail_input"
                    control={form.form.control}
                    name="recertificationEmail"
                    className="recertificationEmail"
                    readOnly={readOnlyProps.recertificationEmailReadOnly}
                />
                <LabeledCheckbox
                    {...form.form.register("certificationBodyCc")}
                    label="certificationRelation.detail.certificationBodyCc_checkbox"
                    id={"certificationBodyCc"}
                    className="certificationBodyCc"
                    readOnly={readOnlyProps.recertificationEmailReadOnly}
                />
                <EnumEntityRadiobuttonGroup
                    label={"certificationRelation.detail.accessLevel_input"}
                    readOnly={readOnlyProps.accessLevelReadOnly}
                    {...form.form.register("accessLevel")}
                    className="accessLevel"
                    name="accessLevel"
                    referenceUrl="/certificate-service/references/certification-relation-access-levels"
                />

                <ControlledDatePicker
                    control={form.form.control}
                    name="startDate"
                    label="certificationRelation.detail.startDate_input"
                    className="startDate"
                    readOnly={readOnlyProps.startDateReadOnly}
                />
                <ControlledDatePicker
                    control={form.form.control}
                    name="endDate"
                    label="certificationRelation.detail.endDate_input"
                    className="endDate"
                    readOnly={readOnlyProps.endDateReadOnly}
                />
            </div>
        </EntityDetailView>
    );
}

export interface CertificationRelationDetailViewProps extends TypedDetailViewProps<CertificationRelationDetails> {
    readOnlyProps: CertificationRelationReadOnlyProps;
    supplyChainOperatorPickListFlags?: SupplyChainOperatorPickListFlags;
}

export interface CertificationRelationReadOnlyProps {
    certificationBodyReadOnly: boolean;
    supplyChainOperatorReadOnly: boolean;
    standardReadOnly: boolean;
    cbLicenceNumberReadOnly: boolean;
    recertificationEmailReadOnly: boolean;
    certificationBodyCcReadOnly: boolean;
    accessLevelReadOnly: boolean;
    startDateReadOnly: boolean;
    endDateReadOnly: boolean;
    supplyChainOperatorPickListFlags?: SupplyChainOperatorPickListFlags;
}
