import EntityDetailView from "../../components/entity/EntityDetailView";
import { TypedDetailViewProps } from "../../components/detailView/DetailView";
import { ScopeDetails } from "../scopeTypes";
import { joinClassNames } from "../../utils/StringUtils";
import GtbTextField from "../../components/Input/GtbTextField";
import GtbTextArea from "../../components/Input/GtbTextArea";
import ControlledDatePicker from "../../components/Input/datepicker/ControlledDatePicker";
import "./scopeData.css";
import { useHelpContext } from "../../help/context-sensitivity/useHelpContext";

export default function ScopeDetailView({ form, isReadOnly, standard, ...rest }: ScopeDetailViewProps) {
    useHelpContext("scope-details");
    return (
        <EntityDetailView {...rest} form={form}>
            <div className={joinClassNames("scopeData", "formGrid")}>
                <GtbTextField
                    {...form.registerWithErrors("name")}
                    readOnly={isReadOnly}
                    label="scope.detail.scope_input"
                    className="scope"
                />
                <GtbTextField
                    label="scope.detail.standard_input"
                    className="standard"
                    readOnly={true}
                    value={standard}
                />
                <GtbTextArea
                    {...form.registerWithErrors("description")}
                    readOnly={isReadOnly}
                    label="scope.detail.description_input"
                    className="description"
                />
                <ControlledDatePicker
                    control={form.form.control}
                    readOnly={isReadOnly}
                    name="startDate"
                    label="scope.detail.startDate_input"
                    className="startDate"
                />
                <ControlledDatePicker
                    control={form.form.control}
                    readOnly={isReadOnly}
                    name="endDate"
                    label="scope.detail.endDate_input"
                    className="endDate"
                />
            </div>
        </EntityDetailView>
    );
}

export interface ScopeDetailViewProps extends TypedDetailViewProps<ScopeDetails> {
    isReadOnly: boolean;
    standard: string;
}
