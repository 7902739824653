import CertificationBodyDetailView from "./CertificationBodyDetailView";
import useCertificationBodyCreate from "./useCertificationBodyCreate";

function CertificationBodyCreateView() {
    const certificationBodyDetailProps = useCertificationBodyCreate();

    return <CertificationBodyDetailView {...certificationBodyDetailProps} />;
}

export default CertificationBodyCreateView;
