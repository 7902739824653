import useResolvedRoute, { getCreateUrl } from "../../components/routing/useResolvedRoute";
import * as React from "react";
import { useCallback } from "react";
import { useGtbNavigate } from "../../components/routing/GtbRouter";
import UseWarningDialog from "../../components/dialog/UseWarningDialog";
import { useQuery } from "../../hooks/useAxios";
import { OrganicMaterialProducerDetails } from "../organicMaterialProducerTypes";
import { useNavigationContext } from "../../components/breadcrumb/useNavigationContext";
import { DialogNoYesOptions } from "../../components/dialog/DialogOptions";

export default function useAcceptOrganicMaterialProducerDuplicate() {
    const navigate = useGtbNavigate(true);
    const { getResolvedEditUrl } = useResolvedRoute();
    const { showDialog: _showCreateSupplyingRelationDialog, closeDialog: _closeCreateSupplyingRelationDialog } =
        UseWarningDialog();
    const navigationContext = useNavigationContext();

    const navigateToOrganicMaterialProducer = useCallback(
        (itemId: string) => {
            navigate(getResolvedEditUrl("organicMaterialProducer", itemId));
        },
        [getResolvedEditUrl, navigate]
    );

    const navigateToSupplyingRelation = useCallback(
        (itemId: string) => {
            navigationContext[navigationContext.length - 1].navigationContextCleanup();
            navigate(getCreateUrl("supplyingRelation"), { state: { omp: itemId } });
        },
        [navigate, navigationContext]
    );

    const showCreateSupplyingRelationDialog = useCallback(
        (itemId: string) => {
            _showCreateSupplyingRelationDialog({
                title: "organicMaterialProducer.detail.duplicateCreateSupplyingRelation_dialogTitle",
                message: "organicMaterialProducer.detail.duplicateCreateSupplyingRelation_dialogText",
                footer: (
                    <DialogNoYesOptions
                        onYes={() => {
                            _closeCreateSupplyingRelationDialog();
                            navigateToSupplyingRelation(itemId);
                        }}
                        onNo={_closeCreateSupplyingRelationDialog}
                    />
                ),
            });
        },
        [_closeCreateSupplyingRelationDialog, _showCreateSupplyingRelationDialog, navigateToSupplyingRelation]
    );

    const { runQuery } = useQuery<OrganicMaterialProducerDetails>({
        url: "",
        enabled: false,
    });

    return useCallback(
        (itemId: string) => {
            runQuery({
                url: "/certificate-service/organic-material-producers/" + itemId,
                defaultForbiddenHandler: false,
            })
                .then(() => {
                    navigateToOrganicMaterialProducer(itemId);
                })
                .catch((error) => {
                    if (error.statusCode === 403) {
                        showCreateSupplyingRelationDialog(itemId);
                    } else {
                        navigateToOrganicMaterialProducer(itemId);
                    }
                });
        },
        [runQuery, navigateToOrganicMaterialProducer, showCreateSupplyingRelationDialog]
    );
}
