import { yupResolver } from "@hookform/resolvers/yup";
import useIsAuthorised from "../../auth/useIsAuthorised";
import { useCallback, useMemo } from "react";
import useResolvedRoute from "../../components/routing/useResolvedRoute";
import useDomainEntityDetailsEdit from "../../hooks/details/useDomainEntityDetailsEdit";
import { TransactionCertificateDetails } from "../transactionCertificateTypes";
import { defaultTransactionCertificateSortOption } from "../useTransactionCertificateListView";
import { transactionCertificateValidationSchema } from "./transactionCertificateValidationSchema";
import { TransactionCertificateDetailViewProps } from "./TransactionCertificateDetailView";
import { TransactionCertificateStatusType } from "../../auth/roleTypes";
import useConformanceCheck from "../../conformance/useConformanceCheck";
import { humanReadableFileSize } from "../../utils/formatter";
import useErrorDialog from "../../components/dialog/useErrorDialog";

export default function useTransactionCertificateEdit(): TransactionCertificateDetailViewProps {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedId } = useResolvedRoute();
    const transactionCertificateId = getResolvedId("transactionCertificate");
    const handleConformanceCheck = useConformanceCheck<TransactionCertificateDetails>();
    const { showDialog: showErrorDialog } = useErrorDialog();

    const canSave = useCallback(
        (item: TransactionCertificateDetails) => {
            if (item) {
                return hasAccessRight("updateTransactionCertificate", [item.status]);
            }
            return false;
        },
        [hasAccessRight]
    );

    const { detailViewProps, form, initialData, isReadOnly, title, recordNavigationProps } =
        useDomainEntityDetailsEdit<TransactionCertificateDetails>({
            baseUrl: "/certificate-service/transaction-certificates/" + transactionCertificateId,
            frontendRoute: "transactionCertificate",
            recordNavigationDefaultSort: defaultTransactionCertificateSortOption,
            titleBuilder: (item) => item.tcNumber,
            resolver: yupResolver(transactionCertificateValidationSchema),
            canSave,
            resolveErrors: (error, item, retrySaveAction) => {
                if (error.statusCode === 409 && error.data && error.data === "signing_routine_internal_problem") {
                    showErrorDialog({
                        title: "documentCertification.signingRoutineInternalProblemDialogTitle",
                        message: "documentCertification.signingRoutineInternalProblem",
                    });
                    return true;
                }
                return handleConformanceCheck(error, item, retrySaveAction);
            },
            historyColumns: [
                {
                    identifier: "cbTcReferenceNumber",
                    title: "transactionCertificate.history.cbTcReferenceNumber_column",
                },
                { identifier: "facilityAsSeller", title: "transactionCertificate.history.facilityAsSeller_column" },
                { identifier: "buyer", title: "transactionCertificate.history.buyer_column" },
                { identifier: "facilityAsBuyer", title: "transactionCertificate.history.facilityAsBuyer_column" },
                { identifier: "status", title: "transactionCertificate.history.status_column" },
                { identifier: "scopeCertificate", title: "transactionCertificate.history.scopeCertificate_column" },
                { identifier: "dateOfIssue", title: "transactionCertificate.history.dateOfIssue_column" },
                { identifier: "placeOfIssue", title: "transactionCertificate.history.placeOfIssue_column" },
                { identifier: "approvingPerson", title: "transactionCertificate.history.approvingPerson_column" },
                {
                    identifier: "doNotDisplayLastProcessorOnPrintedTc",
                    title: "transactionCertificate.history.doNotDisplayOnPrintedTc_column",
                },
                {
                    identifier: "declarationsBySeller",
                    title: "transactionCertificate.history.declarationsBySeller_column",
                },
                { identifier: "certificateOrigin", title: "transactionCertificate.history.originOfCertificate_column" },
                {
                    identifier: "certificateDocument",
                    title: "transactionCertificate.history.certificateDocument_column",
                },
                { identifier: "cbCertificate", title: "transactionCertificate.history.cbCertificate_column" },
                { identifier: "documentHash", title: "transactionCertificate.history.documentHash_column" },
                { identifier: "hashAlgorithm", title: "transactionCertificate.history.hashAlgorithm_column" },
                { identifier: "documentSize", title: "transactionCertificate.history.documentSize_column" },
            ],
        });

    const isReadOnlyByTransactionCertificateStatus = useMemo<boolean>(() => {
        const allowedStatuses: TransactionCertificateStatusType[] = ["DRAFT", "AWAITING_APPROVAL", "REJECTED"];
        return !initialData?.status || !allowedStatuses.includes(initialData.status);
    }, [initialData?.status]);

    const readOnly = useMemo<TransactionCertificateDetailViewProps["readOnly"]>(
        () => ({
            certificationBody: true,
            cbTcReferenceNumber: isReadOnly || isReadOnlyByTransactionCertificateStatus,
            seller: true,
            facilityAsSeller: isReadOnly || isReadOnlyByTransactionCertificateStatus,
            buyer: isReadOnly || isReadOnlyByTransactionCertificateStatus,
            facilityAsBuyer: isReadOnly || isReadOnlyByTransactionCertificateStatus,
            scopeCertificate: isReadOnly || isReadOnlyByTransactionCertificateStatus,
            approvingPerson: isReadOnly || isReadOnlyByTransactionCertificateStatus,
            doNotDisplayLastProcessorOnPrintedTc: isReadOnly || isReadOnlyByTransactionCertificateStatus,
            declarationsBySeller: isReadOnly || isReadOnlyByTransactionCertificateStatus,
            status: isReadOnly,
        }),
        [isReadOnly, isReadOnlyByTransactionCertificateStatus]
    );

    return {
        detailViewProps,
        form,
        readOnly,
        title,
        recordNavigationProps,
        transactionCertificateId,
        transactionCertificateStatus: initialData?.status,
        subListVisible: true,
        createRoute: hasAccessRight("createTransactionCertificate") ? "transactionCertificate" : undefined,
        documentTabVisible: !!initialData?.documentHash,
        humanReadableDocumentSize: initialData?.documentSize ? humanReadableFileSize(initialData?.documentSize) : null,
    };
}
