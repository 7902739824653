import useResolvedRoute, { getCreateUrl } from "../../components/routing/useResolvedRoute";
import * as React from "react";
import { useCallback } from "react";
import useAuth from "../../auth/useAuth";
import { useGtbNavigate } from "../../components/routing/GtbRouter";
import { useModal } from "../../components/modal/ModalProvider";
import { Dialog } from "../../components/dialog/useDialog";
import DialogHeader from "../../components/dialog/DialogHeader";
import DialogFooter from "../../components/dialog/DialogFooter";
import GtbButton from "../../components/GtbButton";
import DialogTextContent from "../../components/dialog/DialogTextContent";
import useGtbTranslation from "../../i18n/useGtbTranslation";
import UseWarningDialog from "../../components/dialog/UseWarningDialog";
import { useQuery } from "../../hooks/useAxios";
import { SupplyChainOperatorDetails, SupplyChainOperatorReference } from "../supplyChainOperatorTypes";
import { useNavigationContext } from "../../components/breadcrumb/useNavigationContext";
import { DialogNoYesOptions } from "../../components/dialog/DialogOptions";

export default function useAcceptSupplyChainOperatorDuplicate() {
    const {
        currentUser: { authorisationRole },
    } = useAuth();
    const navigate = useGtbNavigate(true);
    const { getResolvedEditUrl } = useResolvedRoute();
    const { showModal: _showClientSubcontractorDialog, closeModal: _closeClientSubcontractorDialog } = useModal();
    const { showDialog: _showCreateBusinessRelationDialog, closeDialog: _closeCreateBusinessRelationDialog } =
        UseWarningDialog();
    const navigationContext = useNavigationContext();

    const navigateToSupplyChainOperator = useCallback(
        (itemId: string) => {
            navigate(getResolvedEditUrl("supplyChainOperator", itemId));
        },
        [getResolvedEditUrl, navigate]
    );

    const navigateToCertificationRelation = useCallback(
        (itemId: string) => {
            navigationContext[navigationContext.length - 1].navigationContextCleanup();
            navigate(getCreateUrl("certificationRelation"), { state: { sco: itemId } });
        },
        [navigate, navigationContext]
    );

    const navigateToSubcontractingRelation = useCallback(
        (itemId: string) => {
            navigationContext[navigationContext.length - 1].navigationContextCleanup();
            navigate(getCreateUrl("subcontractingRelation"), { state: { sco: itemId } });
        },
        [navigate, navigationContext]
    );

    const createBusinessRelation = useCallback(
        (itemId: string) => {
            if (authorisationRole.type === "STANDARD_BODY") {
                navigateToCertificationRelation(itemId);
            } else if (authorisationRole.type === "CERTIFICATION_BODY") {
                _showClientSubcontractorDialog(
                    <ClientSubcontractorDialog
                        closeDialog={_closeClientSubcontractorDialog}
                        itemId={itemId}
                        handleClient={navigateToCertificationRelation}
                        handleSubcontractor={navigateToSubcontractingRelation}
                    />
                );
            } else if (authorisationRole.type === "SUPPLY_CHAIN_OPERATOR") {
                navigateToSubcontractingRelation(itemId);
            }
        },
        [
            _showClientSubcontractorDialog,
            authorisationRole.type,
            _closeClientSubcontractorDialog,
            navigateToCertificationRelation,
            navigateToSubcontractingRelation,
        ]
    );

    const showCreateBusinessRelationDialog = useCallback(
        (itemId: string) => {
            _showCreateBusinessRelationDialog({
                title: "supplyChainOperator.detail.duplicateCreateBusinessRelation_dialogTitle",
                message: "supplyChainOperator.detail.duplicateCreateBusinessRelation_dialogText",
                footer: (
                    <DialogNoYesOptions
                        onYes={() => {
                            _closeCreateBusinessRelationDialog();
                            createBusinessRelation(itemId);
                        }}
                        onNo={_closeCreateBusinessRelationDialog}
                    />
                ),
            });
        },
        [_closeCreateBusinessRelationDialog, _showCreateBusinessRelationDialog, createBusinessRelation]
    );

    const { runQuery } = useQuery<SupplyChainOperatorDetails>({
        url: "",
        enabled: false,
    });

    return useCallback(
        (itemId: string) => {
            runQuery({ url: "/certificate-service/supply-chain-operators/" + itemId, defaultForbiddenHandler: false })
                .then(() => {
                    navigateToSupplyChainOperator(itemId);
                })
                .catch((error) => {
                    if (error.statusCode === 403) {
                        showCreateBusinessRelationDialog(itemId);
                    } else {
                        navigateToSupplyChainOperator(itemId);
                    }
                });
        },
        [runQuery, navigateToSupplyChainOperator, showCreateBusinessRelationDialog]
    );
}

function ClientSubcontractorDialog({
    closeDialog,
    itemId,
    handleClient,
    handleSubcontractor,
}: {
    closeDialog: () => void;
    itemId: string;
    handleClient: (itemId: string) => void;
    handleSubcontractor: (itemId: string) => void;
}) {
    const translation = useGtbTranslation();

    const { data: supplyChainOperator } = useQuery<SupplyChainOperatorReference>({
        url: "/certificate-service/references/supply-chain-operators/" + itemId,
    });

    return (
        <Dialog
            dialog={{
                header: (
                    <DialogHeader
                        title={
                            "supplyChainOperator.detail.duplicateCreateBusinessRelationClientSubcontractor_dialogTitle"
                        }
                        variant="warning"
                        onClick={() => closeDialog()}
                    />
                ),
                content: (
                    <DialogTextContent
                        message={{
                            key: "supplyChainOperator.detail.duplicateCreateBusinessRelationClientSubcontractor_dialogText",
                            options: {
                                supplyChainOperatorLegalName:
                                    supplyChainOperator?.legalName ?? supplyChainOperator?.businessName,
                            },
                        }}
                    />
                ),
                footer: (
                    <DialogFooter>
                        <GtbButton variant="secondary" onClick={() => closeDialog()}>
                            {translation("components.dialog.cancel_button")}
                        </GtbButton>
                        <GtbButton
                            variant="secondary"
                            onClick={() => {
                                closeDialog();
                                handleSubcontractor(itemId);
                            }}
                        >
                            {translation(
                                "supplyChainOperator.detail.duplicateCreateBusinessRelationClientSubcontractor_dialogButtonSubcontractor"
                            )}
                        </GtbButton>
                        <GtbButton
                            onClick={() => {
                                closeDialog();
                                handleClient(itemId);
                            }}
                        >
                            {translation(
                                "supplyChainOperator.detail.duplicateCreateBusinessRelationClientSubcontractor_dialogButtonClient"
                            )}
                        </GtbButton>
                    </DialogFooter>
                ),
            }}
        />
    );
}
