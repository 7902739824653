import useExternalFacilityIdListViewList from "./useExternalFacilityIdListViewList";
import EntityListView from "../../components/entity/EntityListView";
import { useHelpContext } from "../../help/context-sensitivity/useHelpContext";

export default function ExternalFacilityIdListView() {
    const externalFacilityIdGridOptions = useExternalFacilityIdListViewList();
    useHelpContext("external-facility-id-list");

    return <EntityListView heading="externalFacilityId.list.header" gridOptions={externalFacilityIdGridOptions} />;
}
