import { joinClassNames } from "../../utils/StringUtils";
import { UseGtbFormReturn } from "../../hooks/formHandling/useGtbForm";
import { ScopeCertificateDetails } from "../scopeCertificateTypes";
import ControlledFileUploadComponent from "../../components/Input/UploadComponent/ControlledFileUploadComponent";
import EnumEntityRadiobuttonGroup from "../../components/Input/radiobutton/EnumEntityRadiobuttonGroup";
import "./scopeCertificateDocument.css";
import GtbTextField from "../../components/Input/GtbTextField";

export interface ScopeCertificateDocumentProps {
    form: UseGtbFormReturn<ScopeCertificateDetails>;
    scopeCertificateId?: string;
    humanReadableDocumentSize: string | null;
}

export default function ScopeCertificateDocument({
    form,
    scopeCertificateId,
    humanReadableDocumentSize,
}: ScopeCertificateDocumentProps) {
    return (
        <div className={joinClassNames("scopeCertificateDocument", "formGrid")}>
            <EnumEntityRadiobuttonGroup
                label={"scopeCertificate.detail.originOfCertificate_input"}
                readOnly
                {...form.form.register("certificateOrigin")}
                className="certificateOrigin"
                referenceUrl="/certificate-service/references/origination-of-certificates"
            />
            <ControlledFileUploadComponent
                control={form.form.control}
                name="certificateDocument"
                label="scopeCertificate.detail.certificateDocument_input"
                acceptedTypes={"application/pdf"}
                fileUrl={`/certificate-service/scope-certificates/${scopeCertificateId}/certificate-document`}
                className="certificateDocument"
                maxFileSize={0}
                readOnly
            />
            <ControlledFileUploadComponent
                control={form.form.control}
                name="cbCertificate"
                label="scopeCertificate.detail.cbCertificate_input"
                acceptedTypes={"application/pdf"}
                fileUrl={`/certificate-service/scope-certificates/${scopeCertificateId}/cb-certificate`}
                className="cbCertificate"
                readOnly
            />
            <GtbTextField
                label="scopeCertificate.detail.documentHash_input"
                {...form.registerWithErrors("documentHash")}
                readOnly
                className="documentHash"
            />
            <GtbTextField
                label="scopeCertificate.detail.hashAlgorithm_input"
                {...form.registerWithErrors("hashAlgorithm")}
                readOnly
                className="hashAlgorithm"
            />
            <GtbTextField
                label="scopeCertificate.detail.documentSize_input"
                value={humanReadableDocumentSize}
                readOnly
                className="documentSize"
            />
        </div>
    );
}
