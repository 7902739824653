import { buildDateColumn, buildNumberColumn, buildTextColumn } from "../components/grid/ColumnProvider";
import useListView from "../hooks/useListView";
import { GridColumn } from "../components/grid/component/Grid";
import { AllocatedTcNumberSummary } from "./allocatedTcNumberTypes";
import { useMemo } from "react";
import { AllocatedTcNumberListViewProps } from "./AllocatedTcNumberListView";

const columns: GridColumn<AllocatedTcNumberSummary>[] = [
    buildTextColumn({ key: "tcNumber", title: "allocatedTcNumber.list.tcNumber_column", minWidth: 141 }),
    buildNumberColumn({ key: "blockNumber", title: "allocatedTcNumber.list.blockNumber_column", minWidth: 163 }),
    buildDateColumn({ key: "dateIssued", title: "allocatedTcNumber.list.dateIssued_column", minWidth: 146 }),
    buildDateColumn({ key: "dateClaimed", title: "allocatedTcNumber.list.dateClaimed_column", minWidth: 155 }),
    buildTextColumn({ key: "apiUserSystemId", title: "allocatedTcNumber.list.apiUserSystemId_column", minWidth: 286 }),
    buildTextColumn({
        key: "apiUserGivenName",
        title: "allocatedTcNumber.list.apiUserGivenName_column",
        minWidth: 236,
    }),
];

export default function useAllocatedTcNumberList(): AllocatedTcNumberListViewProps {
    const {
        queryBuilder: { query, columnOptions, queryCommands },
    } = useListView<AllocatedTcNumberSummary>({
        navigationContextLabel: "allocatedTcNumber.breadcrumb",
        // FIXME - After merge of frontend-refactoring branch change this to an API
        baseQuery: "/certificate-service/allocated-tc-numbers",
        defaultSortOption: {
            field: "tcNumber",
            direction: "DESC",
        },
    });

    return useMemo(() => {
        return {
            query,
            columns,
            uniqueDataKey: "id",
            limit: 100,
            tableName: "allocatedTcNumber",
            columnOptions,
            queryCommands,
        };
    }, [columnOptions, query, queryCommands]);
}
