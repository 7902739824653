import GridRow from "./GridRow";
import LoadingSpinner from "../../LoadingSpinner";

interface GridFooterProps {
    isLoading: boolean;
    columnCount: number;
}

function GridLoadingFooter({ isLoading, columnCount }: GridFooterProps) {
    if (!isLoading) {
        return null;
    }

    return (
        <tfoot className={"loadingFooter"}>
            <GridRow>
                <td colSpan={columnCount}>
                    <LoadingSpinner label={"components.grid.loadingRecords_accessibleLabel"} size={45} />
                </td>
            </GridRow>
        </tfoot>
    );
}

export default GridLoadingFooter;
