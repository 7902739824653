import useGtbTranslation, { I18nKey } from "../../i18n/useGtbTranslation";
import { ComponentPropsWithoutRef } from "react";

interface GtbNavProps extends Omit<ComponentPropsWithoutRef<"nav">, "aria-label"> {
    "aria-label": I18nKey;
}

export default function GtbNav({ "aria-label": ariaLabel, ...rest }: GtbNavProps) {
    const translation = useGtbTranslation();
    return <nav aria-label={translation(ariaLabel)} {...rest} />;
}
