import useAuth from "../../auth/useAuth";
import { useCallback, useEffect, useState } from "react";
import { useModal } from "../../components/modal/ModalProvider";
import AcceptTermsOfUseDialog from "./AcceptTermsOfUseDialog";
import { LogoutReasonType } from "../../login/LogoutView";
import { useGtbNavigate } from "../../components/routing/GtbRouter";

export default function useAcceptTermsOfUse() {
    const [dialogHasBeenShown, setDialogHasBeenShown] = useState(false);
    const { currentUser } = useAuth();
    const { showModal: showDialog, closeModal: closeDialog } = useModal();
    const navigate = useGtbNavigate();

    const logout = useCallback(() => {
        const logoutReason: LogoutReasonType = { logoutReason: "declinedToU" };
        navigate({ pathname: "/logout" }, { state: logoutReason });
    }, [navigate]);

    const handleDecline = useCallback(() => {
        closeDialog();
        logout();
    }, [closeDialog, logout]);

    useEffect(() => {
        if (!currentUser.acceptedToU && !dialogHasBeenShown) {
            setDialogHasBeenShown(true);
            showDialog(
                <AcceptTermsOfUseDialog
                    title="components.acceptedToUDialog.dialogTitle"
                    closeDialog={closeDialog}
                    acceptTouUrl="/certificate-service/users/me/terms-of-use"
                    touTextUrl="/certificate-service/terms-of-use"
                    onDecline={handleDecline}
                />
            );
        }
    }, [closeDialog, currentUser.acceptedToU, dialogHasBeenShown, handleDecline, showDialog]);
}
