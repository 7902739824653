import InfinityScrollSublistTab from "../../../components/tabs/InfinityScrollSublistTab";
import usePreGtbProductSubList from "./usePreGtbProductSubList";
import { PreGtbTransactionCertificateDetails } from "../../preGtbTransactionCertificateTypes";

export default function PreGtbProducts({
    preGtbTcStatus,
}: {
    preGtbTcStatus?: PreGtbTransactionCertificateDetails["status"];
}) {
    const preGtbProductSubList = usePreGtbProductSubList(preGtbTcStatus);
    return <InfinityScrollSublistTab {...preGtbProductSubList} />;
}
