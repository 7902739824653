import DropdownLinkItem from "../../../components/dropdown/DropdownLinkItem";
import { getBaseUrl } from "../../../components/routing/useResolvedRoute";
import MainMenuItem from "../MainMenuItem";
import useIsAuthorised from "../../../auth/useIsAuthorised";

export default function MasterDataMenu() {
    const { hasAccessRight } = useIsAuthorised();

    return (
        <MainMenuItem name={"mainLayout.mainMenu.masterData_menuTitle"}>
            {hasAccessRight("readMasterData") && (
                <>
                    <DropdownLinkItem
                        to={getBaseUrl("standard")}
                        label={"mainLayout.mainMenu.masterData_menuEntries.standards_button"}
                    />
                    <DropdownLinkItem
                        to={getBaseUrl("countryArea")}
                        label={"mainLayout.mainMenu.masterData_menuEntries.countriesAreas_button"}
                    />
                    <DropdownLinkItem
                        to={getBaseUrl("rawMaterial")}
                        label={"mainLayout.mainMenu.masterData_menuEntries.rawMaterials_button"}
                    />
                    <DropdownLinkItem
                        to={getBaseUrl("productCategory")}
                        label={"mainLayout.mainMenu.masterData_menuEntries.productCategories_button"}
                    />
                    <DropdownLinkItem
                        to={getBaseUrl("productDetail")}
                        label={"mainLayout.mainMenu.masterData_menuEntries.productDetails_button"}
                    />
                    <DropdownLinkItem
                        to={getBaseUrl("processCategory")}
                        label={"mainLayout.mainMenu.masterData_menuEntries.processCategories_button"}
                    />
                </>
            )}
        </MainMenuItem>
    );
}
