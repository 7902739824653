import { useCallback } from "react";
import { FieldValues } from "react-hook-form/dist/types";
import { error, info, warning } from "../../utils/notification/notification";
import useGtbTranslation, { I18nFunction } from "../../i18n/useGtbTranslation";
import { UseGtbFormReturn, ValidationError } from "./useGtbForm";

function useFormSubmit<ItemType extends FieldValues>(
    form: UseGtbFormReturn<ItemType>,
    submitAction: useFormSubmitAction<ItemType>,
    options: { omitSuccessMessage: boolean; omitHandleBackendFormError?: boolean } = { omitSuccessMessage: false }
) {
    const translation = useGtbTranslation();

    const submit = useCallback(
        (
            item: ItemType,
            afterSubmit?: UseFormAfterSubmitAction<ItemType>,
            resolveErrors?: UseFormErrorResolver<ItemType>
        ) => {
            submitAction({ body: item })
                .then((savedItem) => {
                    afterSubmit?.(savedItem);
                    if (!options?.omitSuccessMessage) {
                        info(translation("components.detailView.savedSuccessfully_toast"));
                    }
                })
                .catch((err) => {
                    if (
                        !resolveErrors?.(err, item, (retryItem) => submit(retryItem, afterSubmit)) &&
                        !options?.omitHandleBackendFormError
                    ) {
                        handleBackendFormError(err, form.setErrors, translation);
                    }
                });
        },
        [form.setErrors, options?.omitHandleBackendFormError, options?.omitSuccessMessage, submitAction, translation]
    );

    return useCallback<UseFormSubmitCallback<ItemType>>(
        (afterSubmit, resolveErrors) => {
            return form.form.handleSubmit(
                (body) => submit(body, afterSubmit, resolveErrors),
                () => error(translation("error.detailView.savingNotSuccessful_toast"))
            )();
        },
        [form.form, submit, translation]
    );
}

export default useFormSubmit;

export type useFormSubmitAction<ItemType extends FieldValues> = ({ body }: { body: ItemType }) => Promise<ItemType>;

/**
 * action to be called after the item got submitted
 */
export type UseFormAfterSubmitAction<ItemType extends FieldValues> = (savedItem: ItemType) => void;
/**
 * function for resolving possible errors manually. If this function is not given or returns false, the default error handler will still be called
 */
export type UseFormErrorResolver<ItemType extends FieldValues> = (
    err: any,
    item: ItemType,
    retrySaveAction: UseFormAfterSubmitAction<ItemType>
) => boolean;

export type UseFormSubmitCallback<ItemType extends FieldValues> = (
    afterSubmit?: UseFormAfterSubmitAction<ItemType>,
    resolveErrors?: UseFormErrorResolver<ItemType>
) => void;

const handleBackendFormError = (
    err: any,
    setErrors: ((validationErrors: ValidationError<any>) => void) | undefined,
    translation: I18nFunction
) => {
    if (err.statusCode === 400 || err.statusCode === 409) {
        if (setErrors && err?.data?.validationErrors) {
            if (err.data.validationErrors.versionInfo === undefined) {
                setErrors(err.data.validationErrors);
                error(translation("error.detailView.savingNotSuccessful_toast"));
            } else {
                warning(translation("error.detailView.editByOtherUser_toast"));
            }
        } else {
            error(translation("error.detailView.internalApplicationError_toast"));
        }
    } else if (err.statusCode === 404) {
        error(translation("error.detailView.recordHasBeenDeleted_toast"));
    } else {
        error(translation("error.detailView.internalServerError_toast"));
    }
};
