import { buildSchema, validateDate, validateString } from "../../utils/yupExtension";
import { PreGtbTransactionCertificateDetails } from "../preGtbTransactionCertificateTypes";
import { BaseSchema } from "yup";

export const preGtbTransactionCertificateValidationSchema = buildSchema<PreGtbTransactionCertificateDetails>({
    tcNumber: validateString().hasMaxLength(30).isRequired(),
    standard: validateString().isRequired(),
    certificationBody: validateString().isRequired(),
    buyer: validateString().isRequired(),
    sellersLicenceNumber: validateString().hasMaxLength(30).isRequired(),
    dateOfIssue: validateDate().isRequired(),
    status: validateString().isRequired() as BaseSchema<PreGtbTransactionCertificateDetails["status"]>,
});
