import { useCallback } from "react";
import DialogHeader from "./DialogHeader";
import GtbButton from "../GtbButton";
import DialogTextContent from "./DialogTextContent";
import DialogFooter from "./DialogFooter";
import useDialog, { DialogProps } from "./useDialog";
import useGtbTranslation from "../../i18n/useGtbTranslation";

function UseWarningDialog() {
    const translation = useGtbTranslation();
    const { showDialog: _showDialog, closeDialog } = useDialog();

    return {
        showDialog: useCallback(
            ({
                title,
                message,
                footer = <GtbButton onClick={closeDialog}>{translation("components.dialog.ok_button")}</GtbButton>,
            }: DialogProps) => {
                _showDialog({
                    header: <DialogHeader title={title} variant="warning" onClick={closeDialog} />,
                    content: <DialogTextContent message={message} />,
                    footer: <DialogFooter>{footer}</DialogFooter>,
                });
            },
            [closeDialog, translation, _showDialog]
        ),
        closeDialog,
    };
}

export default UseWarningDialog;
