import useExternalIdCreate from "../../details/useExternalIdCreate";
import { useMemo } from "react";
import { Nullable } from "../../../utils/typeUtils";
import useResolvedRoute, { routeEnum } from "../../../components/routing/useResolvedRoute";
import { ExternalIdDetails } from "../../externalIdTypes";

export default function useExternalOrganicMaterialProducerIdCreate() {
    const { getResolvedId } = useResolvedRoute();
    const organicMaterialProducerId = getResolvedId("organicMaterialProducer");
    const externalOrganicMaterialProducerIdRoute = useMemo<routeEnum>(
        () =>
            organicMaterialProducerId
                ? "organicMaterialProducerExternalOrganicMaterialProducerId"
                : "externalOrganicMaterialProducerId",
        [organicMaterialProducerId]
    );

    const partiallyInitialData = useMemo<Partial<Nullable<ExternalIdDetails>>>(() => {
        return {
            parent: organicMaterialProducerId ?? null,
            parentBusinessName: null,
            parentEntityType: "ORGANIC_MATERIAL_PRODUCER",
        };
    }, [organicMaterialProducerId]);

    return useExternalIdCreate({
        i18nKey: "externalOrganicMaterialProducerId",
        routeEnumKey: externalOrganicMaterialProducerIdRoute,
        partiallyInitialData,
    });
}
