import useSupplyChainOperatorListView from "./useSupplyChainOperatorListView";
import { useHelpContext } from "../help/context-sensitivity/useHelpContext";
import EntityListView from "../components/entity/EntityListView";

export default function SupplyChainOperatorListView() {
    const supplyChainOperatorGridOptions = useSupplyChainOperatorListView();
    useHelpContext("supply-chain-operator-list");

    return <EntityListView heading="supplyChainOperator.list.header" gridOptions={supplyChainOperatorGridOptions} />;
}
