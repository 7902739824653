import React, { ReactElement, ReactNode, useCallback, useState } from "react";
import Backdrop, { focusedElementType } from "./Backdrop";

type ModalContextType = {
    closeModal: () => void;
    showModal: (Modal: ReactElement, focusedElement?: focusedElementType) => void;
};

const modalContext = React.createContext<ModalContextType | null>(null);

const ModalProvider = ({ children }: { children: ReactNode }) => {
    const [modal, _showModal] = useState<{ Modal: ReactElement; focusedElement: focusedElementType } | null>(null);
    const closeModal = useCallback(() => {
        _showModal(null);
    }, []);

    const showModal = useCallback((Modal: ReactElement, focusedElement?: focusedElementType) => {
        _showModal({ Modal, focusedElement: focusedElement ?? "last" });
    }, []);

    return (
        <modalContext.Provider value={{ closeModal, showModal }}>
            {children}
            {modal != null && <Backdrop focusedElement={modal.focusedElement}>{modal.Modal}</Backdrop>}
        </modalContext.Provider>
    );
};

const useModal = () => {
    const context = React.useContext(modalContext) as ModalContextType;
    if (context === undefined) {
        throw new Error("useModal must be used within a ModalProvider");
    }

    return context;
};

export { ModalProvider, useModal };
