import useResolvedRoute from "../../components/routing/useResolvedRoute";
import useIsAuthorised from "../../auth/useIsAuthorised";
import TlsCertificateComponent from "../../components/tlsCertificate/TlsCertificateComponent";

export default function CertificationBodyTLSCertificate() {
    const { isCBAdmin } = useIsAuthorised();
    const { getResolvedId } = useResolvedRoute();
    const certificationBodyId = getResolvedId("certificationBody");

    return (
        <div className="formGrid">
            <TlsCertificateComponent
                tlsCertificateDataUrl={`/certificate-service/certification-bodies/${certificationBodyId}/tls-certificate`}
                tlsCertificateFileUrl={`/certificate-service/certification-bodies/${certificationBodyId}/tls-certificate-file`}
                readOnly={!isCBAdmin}
            />
        </div>
    );
}
