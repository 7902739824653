import "./mainMenu.css";
import GtbNav from "../../components/routing/GtbNav";
import MasterDataMenu from "./menus/MasterDataMenu";
import OrganisationMenu from "./menus/OrganisationMenu";
import ScopeCertificateMenu from "./menus/ScopeCertificateMenu";
import TransactionCertificateMenu from "./menus/TransactionCertificateMenu";
import PreGtbTransactionCertificateMenu from "./menus/PreGtbTransactionCertificateMenu";
import BulkReportMenu from "./menus/BulkReportMenu";
import RawMaterialProductionMenu from "./menus/RawMaterialProductionMenu";
import useIsAuthorised from "../../auth/useIsAuthorised";
import { useBuildArrowNavigationListener } from "../../utils/a11yUtils";
import useRemoveTabindex from "../../hooks/useRemoveTabindex";

/**
 * Functional component for displaying the main menu of the application below the header
 * @constructor
 */
function MainMenu() {
    const { hasAccessRight, isSystemAdmin, isSBAdmin, isSBStaffMemeber } = useIsAuthorised();
    const arrowNavigationListener = useBuildArrowNavigationListener("li", "horizontal");

    const removeTabIndex = useRemoveTabindex<HTMLUListElement>();

    return (
        <GtbNav className="mainMenu" aria-label={"mainLayout.mainMenu.mainNavigation_accessibleLabel"}>
            <ul onKeyDown={arrowNavigationListener} {...removeTabIndex}>
                <MasterDataMenu />
                <OrganisationMenu />
                <ScopeCertificateMenu />
                <TransactionCertificateMenu />
                <PreGtbTransactionCertificateMenu />
                {hasAccessRight("dataListsInMenuSystem") && <RawMaterialProductionMenu />}
                {hasAccessRight("useBulkReport") && (isSystemAdmin || isSBAdmin || isSBStaffMemeber) && (
                    <BulkReportMenu />
                )}
            </ul>
        </GtbNav>
    );
}

export default MainMenu;
