import { buildDateColumn, buildTextColumn } from "../components/grid/ColumnProvider";
import { CertificationLicenceDetails, CertificationLicenceSummary } from "./certificationLicenceTypes";
import { useMemo } from "react";
import useIsAuthorised from "../auth/useIsAuthorised";
import { useDeleteAction, useShowDetailsAction } from "../utils/defaultActions";
import { SortOption, useQueryBuilder } from "../components/grid/component/useQueryBuilder";
import { GridColumn } from "../components/grid/component/Grid";
import useResolvedRoute from "../components/routing/useResolvedRoute";
import { SubListProps } from "../components/grid/SubList";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";

export const defaultCertificationLicenceSortOption: SortOption<
    CertificationLicenceSummary | CertificationLicenceDetails
> = {
    field: "licencingCode",
    direction: "ASC",
};

const columns: GridColumn<CertificationLicenceSummary>[] = [
    buildTextColumn({ key: "standardOfficialName", title: "certificationLicence.list.standard_column", minWidth: 126 }),
    buildTextColumn({ key: "licencingCode", title: "certificationLicence.list.licencingCode_column", minWidth: 132 }),
    buildDateColumn({
        key: "startDate",
        title: "certificationLicence.list.startDate_column",
        minWidth: 130,
    }),
    buildDateColumn({
        key: "endDate",
        title: "certificationLicence.list.endDate_column",
        minWidth: 124,
    }),
];

export default function useCertificationLicenceSubList() {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedId, getResolvedEditUrl, getResolvedCreateUrl } = useResolvedRoute();

    const { query, columnOptions, forceReload } = useQueryBuilder<CertificationLicenceSummary>(
        `/certificate-service/certification-bodies/${getResolvedId("certificationBody")}/certification-licences`,
        {
            sort: defaultCertificationLicenceSortOption,
            filterActive: false,
        }
    );

    const showDetailsAction = useShowDetailsAction(
        (item: CertificationLicenceSummary) => getResolvedEditUrl("certificationLicence", item.id),
        query.parameter
    );

    const deleteAction = useDeleteAction(
        (item: CertificationLicenceSummary) =>
            `/certificate-service/certification-bodies/${getResolvedId("certificationBody")}/certification-licences/${
                item.id
            }`,
        () => forceReload(),
        "deleteCertificationLevelOrganisation"
    );

    const contextMenuActions = useGridRowContextMenuActions(showDetailsAction, deleteAction);

    return useMemo<SubListProps<CertificationLicenceSummary>>(
        () => ({
            query,
            columns,
            uniqueDataKey: "id" as keyof CertificationLicenceSummary,
            onItemDoubleClick: showDetailsAction,
            header: "certificationLicence.list.header",
            itemActions: contextMenuActions,
            columnOptions,
            createUrl: hasAccessRight("createCertificationLevelOrganisation")
                ? getResolvedCreateUrl("certificationLicence")
                : undefined,
            tableName: "certificationLicences",
        }),
        [columnOptions, contextMenuActions, getResolvedCreateUrl, hasAccessRight, query, showDetailsAction]
    );
}
