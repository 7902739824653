import { Link, LinkProps } from "react-router-dom";
import { useGtbNavigate } from "./GtbRouter";
import useGtbTranslation, { I18nKey } from "../../i18n/useGtbTranslation";

export interface GtbLinkProps extends Omit<LinkProps, "aria-label" | "title"> {
    disabled?: boolean;
    beforeNavigate?: () => void;
    forceNavigation?: boolean;
    "aria-describedby"?: string;
    "aria-label"?: I18nKey;
    title?: I18nKey;
}

function GtbLink({
    to,
    className,
    style,
    replace,
    state,
    beforeNavigate,
    forceNavigation,
    onClick,
    disabled,
    children,
    title,
    "aria-describedby": ariaDescribedBy,
    "aria-label": ariaLabel,
    target,
    ...rest
}: GtbLinkProps) {
    const navigate = useGtbNavigate(forceNavigation);
    const translation = useGtbTranslation();

    if (disabled) {
        return (
            // see https://a11y-guidelines.orange.com/en/articles/disable-elements/
            // this is how a disabled link is still accessible by screen readers
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
                role="link"
                aria-disabled
                aria-label={ariaLabel ? translation(ariaLabel) : undefined}
                aria-describedby={ariaDescribedBy}
                className={className}
                style={style}
                title={title ? translation(title) : undefined}
            >
                {children}
            </a>
        );
    }
    return (
        <Link
            onClick={
                target
                    ? onClick
                    : (event) => {
                          event.preventDefault();
                          onClick?.(event);
                          navigate(to, replace || state ? { replace, state } : undefined, beforeNavigate);
                      }
            }
            to={to}
            aria-describedby={ariaDescribedBy}
            aria-label={ariaLabel ? translation(ariaLabel) : undefined}
            className={className}
            style={style}
            children={children}
            title={title ? translation(title) : undefined}
            target={target}
            {...rest}
        />
    );
}

export default GtbLink;
