import { joinClassNames } from "../../utils/StringUtils";
import "./materialContentRuleBasicData.css";
import { UseGtbFormReturn } from "../../hooks/formHandling/useGtbForm";
import { MaterialContentRuleDetails } from "../materialContentRuleTypes";
import GtbTextField from "../../components/Input/GtbTextField";
import SubList from "../../components/grid/SubList";
import useAdditionalMaterialSubList from "../additionalMaterial/useAdditionalMaterialSubList";
import CertifiedMaterialPickList from "../../components/Input/pickList/typedPicklist/CertifiedMaterialPickList";
import GtbNumberField from "../../components/Input/GtbNumberField";
import { LabelGradeReference } from "../../labelGrade/labelGradeTypes";

export default function MaterialContentRuleBasicData({
    form,
    readOnly,
    standard,
    standardVersion,
    labelGrade,
    subListVisible,
}: MaterialContentRuleBasicDataProps) {
    const additionalMaterialSubListOptions = useAdditionalMaterialSubList();

    return (
        <div className={joinClassNames("materialContentRuleBasicData", "formGrid")}>
            <GtbTextField
                readOnly
                label="materialContentRule.detail.standardVersion_input"
                className="standardVersion"
                value={standardVersion}
            />
            <GtbTextField
                readOnly
                label="materialContentRule.detail.labelGrade_input"
                className="labelGrade"
                value={labelGrade?.labelGrade}
            />
            <GtbNumberField
                readOnly
                label="materialContentRule.detail.minPercentage_input"
                className="minPercentage"
                unit="%"
                value={labelGrade?.minPercentage}
            />
            <CertifiedMaterialPickList
                control={form.form.control}
                standardId={standard}
                className="certifiedMaterial"
                name="certifiedMaterial"
                label="materialContentRule.detail.certifiedMaterial_input"
                readOnly={readOnly}
            />
            {subListVisible && <SubList {...additionalMaterialSubListOptions} className="additionalMaterial" />}
        </div>
    );
}

export interface MaterialContentRuleBasicDataProps {
    form: UseGtbFormReturn<MaterialContentRuleDetails>;
    readOnly: boolean;
    standard?: string;
    standardVersion?: string;
    labelGrade?: LabelGradeReference;
    subListVisible?: boolean;
}
