import useEventMonitorEdit from "./useEventMonitorEdit";
import EventMonitorDetailView from "./EventMonitorDetailView";

function EventMonitorEditView() {
    const eventMonitorDetailProps = useEventMonitorEdit();

    return <EventMonitorDetailView {...eventMonitorDetailProps} />;
}

export default EventMonitorEditView;
