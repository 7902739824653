import InfinityScrollSubList, { SubListProps } from "../grid/InfinityScrollSubList";
import { joinClassNames } from "../../utils/StringUtils";
import "./sublistTab.css";
import { Entity } from "../../utils/typeUtils";

export default function InfinityScrollSublistTab<ItemType extends Entity>({
    className,
    ...rest
}: SubListProps<ItemType>) {
    return <InfinityScrollSubList className={joinClassNames("sublistTab", className)} {...rest} />;
}
