/**
 * composes all higher order components to one component
 * @param rest
 */
const hocCompose =
    (...rest: Function[]) =>
    (x: Function) =>
        rest.reduceRight((y, f) => f(y), x);

export default hocCompose;
