import { useModal } from "../modal/ModalProvider";
import * as React from "react";
import { ReactNode, useCallback, useEffect } from "react";
import { Dialog } from "./useDialog";
import DialogHeader, { DialogHeaderProps } from "./DialogHeader";
import DialogFooter from "./DialogFooter";
import { IconName } from "../Icon";
import useGtbForm, { UseGtbFormReturn } from "../../hooks/formHandling/useGtbForm";
import { yupResolver } from "@hookform/resolvers/yup";
import { ValidationSchema } from "../../utils/yupExtension";
import { FieldValues } from "react-hook-form/dist/types";
import { axiosMutationType, backendUrlType, useMutation } from "../../hooks/useAxios";
import { I18nKey } from "../../i18n/useGtbTranslation";
import useFormSubmit, { UseFormAfterSubmitAction, UseFormErrorResolver } from "../../hooks/formHandling/useFormSubmit";
import LoadingSpinner from "../LoadingSpinner";
import { Nullable } from "../../utils/typeUtils";
import { DialogOptions, DialogOptionsProps } from "./DialogOptions";
import Form from "../Form";

export default function useFormDialog<ItemType extends FieldValues>({
                                                                        ...formDialogProps
                                                                    }: UseFormDialogProps<ItemType>) {
    const { showModal: _showDialog, closeModal: closeDialog } = useModal();

    return useCallback(
        (itemLoader: Promise<Nullable<ItemType>>, url: backendUrlType | undefined = formDialogProps.url) => {
            _showDialog(
                <FormDialog {...formDialogProps} url={url} itemLoader={itemLoader} closeDialog={closeDialog} />,
            );
        },
        [_showDialog, formDialogProps, closeDialog],
    );
}

function FormDialog<ItemType extends FieldValues>({
                                                      title,
                                                      variant = "info",
                                                      icon,
                                                      itemLoader,
                                                      schema,
                                                      renderContent,
                                                      method = "put",
                                                      url,
                                                      afterSubmit,
                                                      closeDialog,
                                                      className = "form-dialog",
                                                      resolveErrors,
                                                  }: FormDialogProps<ItemType>) {
    const [isFormReady, setIsFormReady] = React.useState(false);
    const form = useGtbForm<ItemType>({
        resolver: yupResolver(schema),
    });
    const { isLoading, runQuery: save } = useMutation({ method: method, url: url ?? "" });

    const handleSubmit = useFormSubmit<ItemType>(form, save, { omitSuccessMessage: true });

    useEffect(() => {
        // @ts-ignore it is the same only with all fields being able to be null
        itemLoader.then(form.form.reset).then(() => setIsFormReady(true));
    }, [form.form.reset, itemLoader]);

    const onSubmit = useCallback(() => {
        handleSubmit((savedItem) => {
            closeDialog();
            afterSubmit?.(savedItem);
        }, resolveErrors);
    }, [afterSubmit, closeDialog, handleSubmit]);

    return (
        <Form onSubmit={onSubmit} className="formDialog">
            <Dialog
                dialog={{
                    className: className,
                    header: (
                        <DialogHeader
                            title={title}
                            variant={variant}
                            icon={icon}
                            onClick={() => {
                                closeDialog();
                            }}
                        />
                    ),
                    content: isFormReady && form.form.formState ? renderContent(form) : <LoadingSpinner />,
                    footer: (
                        <DialogFooter>
                            <DialogOptions
                                options={[
                                    { onClick: closeDialog, label: "components.dialog.cancel_button" },
                                    {
                                        onClick: onSubmit,
                                        label: "components.dialog.save_button",
                                        disabled: isLoading,
                                    },
                                ]}
                            />
                        </DialogFooter>
                    ),
                }}
            />
        </Form>
    );
}

interface UseFormDialogProps<ItemType extends FieldValues>
    extends Omit<FormDialogProps<ItemType>, "closeDialog" | "itemLoader"> {
}

interface FormDialogProps<ItemType extends FieldValues> {
    title: I18nKey;
    variant?: DialogHeaderProps["variant"];
    icon: IconName;
    itemLoader: Promise<Nullable<ItemType>>;
    schema: ValidationSchema<ItemType>;
    renderContent: (form: UseGtbFormReturn<ItemType>) => ReactNode;
    method?: axiosMutationType;
    url?: backendUrlType;
    afterSubmit?: UseFormAfterSubmitAction<ItemType>;
    closeDialog: DialogOptionsProps["onClick"];
    className?: string;
    resolveErrors?: UseFormErrorResolver<ItemType>;
}
