import { useQuery } from "../hooks/useAxios";
import { CountryAreaDetails } from "./countryAreaTypes";
import { useEffect, useState } from "react";
import { UseGtbFormReturn } from "../hooks/formHandling/useGtbForm";
import { useFormState } from "react-hook-form";

export default function useTypeOfRegistrationId(form: UseGtbFormReturn<any>, isRegistrationIdTypeForOmp = false) {
    const countryAreaId = form.form.watch("countryArea");
    const { isDirty: isCountryAreaDirty } = useFormState({ control: form.form.control, name: "countryArea" });
    const [typeOfRegistration, setTypeOfRegistration] = useState<{
        typeOfRegistrationId: string;
        isFixedRegistrationIdReadOnly: boolean;
    }>({ typeOfRegistrationId: "", isFixedRegistrationIdReadOnly: false });

    const { runQuery: runCountryAreaQuery } = useQuery<CountryAreaDetails>({
        url: "",
        enabled: false,
    });

    useEffect(() => {
        if (countryAreaId) {
            runCountryAreaQuery({ url: "/certificate-service/references/countries/" + countryAreaId }).then(
                (countryArea) => {
                    const registrationIdType = isRegistrationIdTypeForOmp
                        ? countryArea?.registrationIdTypeOmp
                        : countryArea?.registrationIdType;

                    if (registrationIdType) {
                        setTypeOfRegistration({
                            typeOfRegistrationId: registrationIdType,
                            isFixedRegistrationIdReadOnly: false,
                        });
                    } else {
                        setTypeOfRegistration({ typeOfRegistrationId: "", isFixedRegistrationIdReadOnly: true });
                        if (isCountryAreaDirty) {
                            form.form.setValue("fixedRegistrationId", null, { shouldDirty: false });
                        }
                    }
                }
            );
        } else {
            setTypeOfRegistration({ typeOfRegistrationId: "", isFixedRegistrationIdReadOnly: true });
            if (isCountryAreaDirty) {
                form.form.setValue("fixedRegistrationId", null, { shouldDirty: false });
            }
        }
    }, [countryAreaId, form.form, isCountryAreaDirty, isRegistrationIdTypeForOmp, runCountryAreaQuery]);

    return typeOfRegistration;
}
