import useApplicationLogList from "./useApplicationLogList";
import { useHelpContext } from "../help/context-sensitivity/useHelpContext";
import EntityListView, { EntityListViewGridProps } from "../components/entity/EntityListView";
import GtbPickList, { GtbPickListProps } from "../components/Input/pickList/GtbPickList";
import { ApplicationLogSummary } from "./applicationLogTypes";
import "./applicationLogListView.css";

function ApplicationLogListView() {
    const { listProps, currentLogLevelProps } = useApplicationLogList();
    useHelpContext("application-log-list");
    return (
        <EntityListView heading={"applicationLog.list.header"} gridOptions={listProps}>
            <GtbPickList className="currentLogLevel" {...currentLogLevelProps} />
        </EntityListView>
    );
}

export default ApplicationLogListView;

export interface ApplicationLogListViewProps {
    listProps: EntityListViewGridProps<ApplicationLogSummary>;
    currentLogLevelProps: GtbPickListProps<{ id: string; label: string }>;
}
