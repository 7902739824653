import { joinClassNames } from "../../utils/StringUtils";
import ControlledDatePicker from "../../components/Input/datepicker/ControlledDatePicker";
import { UseGtbFormReturn } from "../../hooks/formHandling/useGtbForm";
import { TransactionCertificateDetails } from "../transactionCertificateTypes";
import GtbTextField from "../../components/Input/GtbTextField";
import SupplyChainOperatorPickList, {
    supplyChainOperatorPickListCombinedClassName,
    SupplyChainOperatorPickListFlags,
    supplyChainOperatorPickListItemPresentation,
    supplyChainOperatorPickListReferenceUrl,
} from "../../components/Input/pickList/typedPicklist/SupplyChainOperatorPickList";
import CertificationBodyPickList from "../../components/Input/pickList/typedPicklist/CertificationBodyPickList";
import {
    facilityPickListCombinedClassName,
    FacilityPickListFlags,
    facilityPickListItemPresentation,
    facilityPickListScoReferenceUrlBuilder,
} from "../../components/Input/pickList/typedPicklist/FacilityPickList";
import TransactionCertificateStatusPickList from "../../components/Input/pickList/typedPicklist/TransactionCertificateStatusPickList";
import { DependentPicklist, FlaggedQuery } from "../../components/Input/pickList/typedPicklist/DependentPicklist";
import { ScopeCertificateReference } from "../../scopeCertificate/scopeCertificateTypes";
import {
    userPickListCombinedClassName,
    UserPickListFlags,
    userPickListItemPresentation,
    userPickListReferenceUrl,
} from "../../components/Input/pickList/typedPicklist/UserPickList";
import "./transactionCertificateBasicData.css";

export interface TransactionCertificateBasicDataProps {
    form: UseGtbFormReturn<TransactionCertificateDetails>;
    tcId?: string;
    readOnly: {
        certificationBody: boolean;
        cbTcReferenceNumber: boolean;
        seller: boolean;
        facilityAsSeller: boolean;
        buyer: boolean;
        facilityAsBuyer: boolean;
        scopeCertificate: boolean;
        approvingPerson: boolean;
        status: boolean;
    };
}

export default function TransactionCertificateBasicData({
    form,
    tcId,
    readOnly,
}: TransactionCertificateBasicDataProps) {
    return (
        <div className={joinClassNames("transactionCertificateBasicData", "formGrid")}>
            <CertificationBodyPickList
                label="transactionCertificate.detail.certificationBody_input"
                control={form.form.control}
                name="certificationBody"
                className="certificationBody"
                flags={{ withValidCertificationRelation: true }}
                readOnly={readOnly.certificationBody}
            />
            <GtbTextField
                label="transactionCertificate.detail.cbTcReferenceNumber_input"
                {...form.registerWithErrors("cbTcReferenceNumber")}
                readOnly={readOnly.cbTcReferenceNumber}
                className="cbTcReferenceNumber"
            />
            <SupplyChainOperatorPickList
                label="transactionCertificate.detail.seller_input"
                control={form.form.control}
                name="seller"
                className="seller"
                flags={{ isActiveClientOfCbAndHasOperationalValidSc: true }}
                readOnly={readOnly.seller}
            />
            <DependentPicklist
                label="transactionCertificate.detail.facilityAsSeller_input"
                form={form.form}
                name="facilityAsSeller"
                {...facilityPickListItemPresentation}
                urlBuilder={(sco): FlaggedQuery<FacilityPickListFlags> => ({
                    url: facilityPickListScoReferenceUrlBuilder(sco),
                    flags: { independentLegalEntities: true },
                })}
                className={facilityPickListCombinedClassName("facilityAsSeller")}
                parentField="seller"
                readOnly={readOnly.facilityAsSeller}
            />
            <DependentPicklist
                label="transactionCertificate.detail.buyer_input"
                form={form.form}
                name="buyer"
                {...supplyChainOperatorPickListItemPresentation}
                className={supplyChainOperatorPickListCombinedClassName("buyer")}
                urlBuilder={(sco): FlaggedQuery<SupplyChainOperatorPickListFlags> => ({
                    url: supplyChainOperatorPickListReferenceUrl,
                    flags: { potentialBuyersForTcToSellerId: sco },
                })}
                parentField="seller"
                readOnly={readOnly.buyer}
            />
            <DependentPicklist
                label="transactionCertificate.detail.facilityAsBuyer_input"
                form={form.form}
                name="facilityAsBuyer"
                {...facilityPickListItemPresentation}
                urlBuilder={(sco): FlaggedQuery<FacilityPickListFlags> => ({
                    url: facilityPickListScoReferenceUrlBuilder(sco),
                    flags: { independentLegalEntities: true },
                })}
                className={facilityPickListCombinedClassName("facilityAsBuyer")}
                parentField="buyer"
                readOnly={readOnly.facilityAsBuyer}
            />
            <GtbTextField
                label="transactionCertificate.detail.tcNumber_input"
                {...form.registerWithErrors("tcNumber")}
                className="tcNumber"
                readOnly
            />
            <TransactionCertificateStatusPickList
                control={form.form.control}
                name="status"
                label="transactionCertificate.detail.status_input"
                flags={{ onlyAvailable: true, transactionCertificateId: tcId }}
                className="status"
                readOnly={readOnly.status}
            />
            <DependentPicklist<TransactionCertificateDetails, ScopeCertificateReference>
                label="transactionCertificate.detail.scopeCertificate_input"
                form={form.form}
                name="scopeCertificate"
                parentField={["seller", "certificationBody"]}
                urlBuilder={([seller, certBody]) => ({
                    url: "/certificate-service/references/scope-certificates",
                    flags: {
                        activeCertificationRelationWithCertificationBody: certBody,
                        activeCertificationRelationWithSeller: seller,
                    },
                })}
                itemId="id"
                itemLabel={(sc) => `${sc.standardAcronymVersionNumber} - ${sc.scRevisionNumber}`}
                className="scopeCertificate"
                readOnly={readOnly.scopeCertificate}
            />
            <ControlledDatePicker
                control={form.form.control}
                name="dateOfIssue"
                label="transactionCertificate.detail.dateOfIssue_input"
                className="dateOfIssue"
                readOnly
            />
            <GtbTextField
                label="transactionCertificate.detail.placeOfIssue_input"
                {...form.registerWithErrors("placeOfIssue")}
                className="placeOfIssue"
                readOnly
            />
            <DependentPicklist
                label="transactionCertificate.detail.approvingPerson_input"
                form={form.form}
                name="approvingPerson"
                {...userPickListItemPresentation}
                urlBuilder={(): FlaggedQuery<UserPickListFlags> => ({
                    url: userPickListReferenceUrl,
                    flags: { canApproveTCs: true },
                })}
                className={userPickListCombinedClassName("approvingPerson")}
                parentField="certificationBody"
                readOnly={readOnly.approvingPerson}
            />
        </div>
    );
}
