import { TypedDetailViewProps } from "../../components/detailView/DetailView";
import "../../components/spinner.css";
import { DynamicUserGroupDetails } from "../dynamicUserGroupTypes";
import EntityDetailView from "../../components/entity/EntityDetailView";
import { useHelpContext } from "../../help/context-sensitivity/useHelpContext";
import DynamicUserGroupBasicData from "./DynamicUserGroupBasicData";

export default function DynamicUserGroupDetailView({
                                                       detailViewProps,
                                                       form,
                                                       ...rest
                                                   }: DynamicUserGroupDetailViewProps) {
    useHelpContext("dynamic-user-group-details");
    return (
        <EntityDetailView form={form} detailViewProps={detailViewProps} {...rest}>
            <DynamicUserGroupBasicData form={form} />
        </EntityDetailView>
    );
}

export interface DynamicUserGroupDetailViewProps
    extends TypedDetailViewProps<DynamicUserGroupDetails> {
}
