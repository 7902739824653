import { ComponentType, useEffect } from "react";
import { GridProps } from "../DataGrid";
import { useQuery } from "../../../hooks/useAxios";
import { buildQuery } from "../component/useQueryBuilder";
import { handleResponseError } from "../../../utils/errorHandler";
import { Entity, RequireInheritedProps } from "../../../utils/typeUtils";

export interface WithDataProps<ItemType extends Entity> extends RequireInheritedProps<GridProps<ItemType>, "query"> {}

export function withData<ItemType extends Entity>(Grid: ComponentType<GridProps<ItemType> & WithDataProps<ItemType>>) {
    return ({ query, isLoading: isGridLoading, ...rest }: GridProps<ItemType> & WithDataProps<ItemType>) => {
        const { data, isLoading, runQuery, isError } = useQuery<ItemType[]>({ url: "", enabled: false });

        useEffect(() => {
            runQuery({ url: buildQuery(query) }).catch(handleResponseError);
        }, [query, runQuery]);

        return (
            <Grid
                {...rest}
                data={data}
                total={data?.length}
                isLoading={isGridLoading || isLoading}
                isError={isError}
                query={query}
            />
        );
    };
}
