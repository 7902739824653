import { Control, Controller, Path, PathValue } from "react-hook-form";

import GtbPickList, { GtbPickListProps } from "./GtbPickList";
import useGtbTranslation, { I18nKey } from "../../../i18n/useGtbTranslation";
import { FieldValues } from "react-hook-form/dist/types";

export interface ControlledPickListProps<FormItemType extends FieldValues, ItemType>
    extends Omit<GtbPickListProps<ItemType>, "value" | "onChange"> {
    control: Control<FormItemType>;
    name: keyof FormItemType;
    onChangeListener?: GtbPickListProps<ItemType>["onChange"];
}

function ControlledPickList<FormItemType extends FieldValues, ItemType>({
    control,
    name,
    onChangeListener,
    ...rest
}: ControlledPickListProps<FormItemType, ItemType>) {
    const translation = useGtbTranslation();

    return (
        <Controller
            name={name as Path<FormItemType>}
            control={control}
            defaultValue={null as PathValue<FormItemType, Path<FormItemType>>}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
                return (
                    <GtbPickList
                        value={value}
                        onChange={(event, newValue, newOption) => {
                            onChange({
                                target: {
                                    name,
                                    value: newValue,
                                },
                            });

                            if (onChangeListener) onChangeListener(event, newValue, newOption);
                        }}
                        error={!!error}
                        helperText={error?.message && translation(error?.message as I18nKey)}
                        {...rest}
                    />
                );
            }}
        />
    );
}

export default ControlledPickList;
