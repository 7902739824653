import { joinClassNames } from "../../utils/StringUtils";
import "./organicMaterialProducerNewRelation.css";
import SupplyChainOperatorPickList from "../../components/Input/pickList/typedPicklist/SupplyChainOperatorPickList";
import { OrganicMaterialProducerDetailViewTabProps } from "./OrganicMaterialProducerDetailView";
import { OrganicMaterialProducerCreateDetails } from "../organicMaterialProducerTypes";

export default function OrganicMaterialProducerNewRelation({
    form,
}: OrganicMaterialProducerDetailViewTabProps<OrganicMaterialProducerCreateDetails>) {
    return (
        <div className={joinClassNames("organicMaterialProducerNewRelation", "formGrid")}>
            <SupplyChainOperatorPickList
                control={form.form.control}
                name="supplyChainOperator"
                label="organicMaterialProducer.detail.supplyChainOperator_input"
                className="supplyChainOperator"
            />
        </div>
    );
}
