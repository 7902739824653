import { buildSchema, validateDate, validateString } from "../../utils/yupExtension";
import { UserDetails } from "../userTypes";

export const userValidationSchema = buildSchema<UserDetails>({
    username: validateString().isRequired().hasMaxLength(40),
    givenName: validateString().isRequired().hasMaxLength(40),
    authorisationRole: validateString().isRequired(),
    surname: validateString().isRequired().hasMaxLength(40),
    standardBody: validateString().isRequired(),
    emailAddress: validateString().isRequired().hasMaxLength(100),
    startDate: validateDate().isRequired(),
    endDate: validateDate(),
    testDataStamp: validateString().hasMaxLength(95),
});
