import { TypedDetailViewProps } from "../../components/detailView/DetailView";
import useDeduplicationLogEdit from "./useDeduplicationLogEdit";
import { UseGtbFormReturn } from "../../hooks/formHandling/useGtbForm";
import DeduplicationLogData from "./DeduplicationLogData";
import { useHelpContext } from "../../help/context-sensitivity/useHelpContext";
import { DeduplicationLogDetails } from "../deduplicationLogTypes";
import EntityDetailView from "../../components/entity/EntityDetailView";

function DeduplicationLogEditView() {
    const { form, ...rest } = useDeduplicationLogEdit();
    useHelpContext("deduplication-log-details");
    return (
        <EntityDetailView {...rest} form={form}>
            <DeduplicationLogData form={form} />
        </EntityDetailView>
    );
}

export default DeduplicationLogEditView;

export interface DeduplicationLogEditViewProps extends TypedDetailViewProps<DeduplicationLogDetails> {}

export interface DeduplicationLogDetailViewTabProps {
    form: UseGtbFormReturn<DeduplicationLogDetails>;
}
