import EntityListView from "../components/entity/EntityListView";
import useStandardListView from "./useStandardListView";
import { useHelpContext } from "../help/context-sensitivity/useHelpContext";

export default function StandardListView() {
    const gridOptions = useStandardListView();
    useHelpContext("standard-list");

    return <EntityListView heading="standard.list.header" gridOptions={gridOptions} />;
}
