import { useMemo } from "react";
import { buildTextColumn } from "../components/grid/ColumnProvider";
import useListView from "../hooks/useListView";
import { useShowDetailsAction } from "../utils/defaultActions";
import { GridColumn } from "../components/grid/component/Grid";
import useResolvedRoute from "../components/routing/useResolvedRoute";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";
import { MailTemplateDetails, MailTemplateSummary } from "./mailTemplateTypes";
import { SortOption } from "../components/grid/component/useQueryBuilder";

const columns: GridColumn<MailTemplateSummary>[] = [
    buildTextColumn({ key: "title", title: "mailTemplate.list.title_column", minWidth: 100 }),
    buildTextColumn({ key: "subject", title: "mailTemplate.list.subject_column", minWidth: 90, visible: false }),
    buildTextColumn({ key: "body", title: "mailTemplate.list.body_column", minWidth: 100, visible: false }),
];

export const defaultMailTemplateSortOption: SortOption<MailTemplateSummary | MailTemplateDetails> = {
    field: "title",
    direction: "ASC",
};

export default function useMailTemplateListView() {
    const { getResolvedEditUrl } = useResolvedRoute();
    const {
        queryBuilder: { query, columnOptions, queryCommands },
    } = useListView<MailTemplateSummary>({
        navigationContextLabel: "mailTemplate.breadcrumb",
        baseQuery: "/certificate-service/mail-templates",
        defaultSortOption: defaultMailTemplateSortOption,
    });

    const showDetailsAction = useShowDetailsAction(
        (item: MailTemplateSummary) => getResolvedEditUrl("mailTemplate", item.id),
        query.parameter
    );

    const contextMenuActions = useGridRowContextMenuActions(showDetailsAction);

    return useMemo(() => {
        return {
            uniqueDataKey: "id" as keyof MailTemplateSummary,
            query,
            columns,
            columnOptions,
            onItemDoubleClick: showDetailsAction,
            createUrl: undefined,
            tableName: "mailTemplate",
            itemActions: contextMenuActions,
            queryCommands,
        };
    }, [query, columnOptions, showDetailsAction, contextMenuActions, queryCommands]);
}
