import { PreGtbTcMaterialDetailViewProps } from "./PreGtbTcMaterialDetailView";
import useDetailsCreate from "../../../../hooks/details/useDetailsCreate";
import { PreGtbProductDetails } from "../../products/preGtbProductTypes";
import { useQuery } from "../../../../hooks/useAxios";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import useResolvedRoute from "../../../../components/routing/useResolvedRoute";
import { useEffect, useMemo, useState } from "react";
import { Nullable } from "../../../../utils/typeUtils";
import { PreGtbTcMaterialDetails } from "../preGtbTcMaterialsTypes";
import { buildPreGtbTcMaterialValidationSchema } from "./preGtbTcMaterialValidationSchema";
import useCalculatePreGtbTcMaterialCertifiedWeight from "./useCalculatePreGtbTcMaterialCertifiedWeight";
import { LabelGradeReference } from "../../../../labelGrade/labelGradeTypes";

const readOnly: PreGtbTcMaterialDetailViewProps["readOnly"] = {
    rawMaterial: false,
    rawMaterialPrint: false,
    percentage: false,
    origin: false,
};

export default function usePreGtbTcMaterialCreate(): PreGtbTcMaterialDetailViewProps {
    const [labelGradeTypeOfMaterialId, setLabelGradeTypeOfMaterialId] = useState<string | null>(null);
    const { getResolvedId } = useResolvedRoute();
    const preGtbTcId = getResolvedId("preGtbTransactionCertificate");
    const preGtbTcProductId = getResolvedId("preGtbProduct");

    const { data: preGtbTcProduct, isLoading: isPreGtbTcProductLoading } = useQuery<PreGtbProductDetails>({
        url: `/certificate-service/pre-gtb-tcs/${preGtbTcId}/pre-gtb-products/${preGtbTcProductId}`,
    });

    const { isLoading: isLabelGradeLoading, runQuery: runLabelGradeQuery } = useQuery<LabelGradeReference>({
        url: ``,
        enabled: false,
    });

    useEffect(() => {
        if (!isPreGtbTcProductLoading && preGtbTcProduct?.labelGrade) {
            runLabelGradeQuery({
                url: `/certificate-service/references/label-grades/${preGtbTcProduct.labelGrade}`,
            }).then((response) => setLabelGradeTypeOfMaterialId(response.typeOfMaterial));
        }
    }, [isPreGtbTcProductLoading, preGtbTcProduct, runLabelGradeQuery]);

    const initialData = useMemo<Nullable<PreGtbTcMaterialDetails>>(
        () => ({
            id: null,
            rawMaterial: null,
            rawMaterialPrint: null,
            rawMaterialPrintRequired: false,
            rawMaterialName: null,
            percentage: null,
            certified: false,
            certifiedWeight: null,
            origin: [],
            joinedOriginNames: null,
            labelGradeId: preGtbTcProduct?.labelGrade ?? null,
            labelGradeLabelGrade: preGtbTcProduct?.labelGradeLabelGrade ?? null,
            preGtbTcProductDetailPrint: preGtbTcProduct?.productDetailPrint ?? null,
            preGtbTcProductCategoryPrint: preGtbTcProduct?.productCategoryPrint ?? null,
            preGtbTcProductNumber: preGtbTcProduct?.productNumber ?? null,
            preGtbTcNumber: preGtbTcProduct?.preGtbTcTcNumber ?? null,
            preGtbTcStatus: null,
        }),
        [preGtbTcProduct]
    );

    const { detailViewProps, form, title } = useDetailsCreate<PreGtbTcMaterialDetails>({
        title: "preGtbTcMaterial.detail.newRecord_title",
        frontendRoute: "preGtbTcMaterial",
        initialData,
        resolver: yupResolver(buildPreGtbTcMaterialValidationSchema),
        saveUrl: `/certificate-service/pre-gtb-tcs/${preGtbTcId}/pre-gtb-products/${preGtbTcProductId}/pre-gtb-tc-materials`,
    });

    const isCertified = form.form.watch("certified");
    const rawMaterialPrintRequired = form.form.watch("rawMaterialPrintRequired");
    const percentage = form.form.watch("percentage");

    useCalculatePreGtbTcMaterialCertifiedWeight(
        form,
        preGtbTcProduct,
        isCertified,
        percentage,
        isPreGtbTcProductLoading
    );

    return {
        title,
        detailViewProps: {
            ...detailViewProps,
            isLoading: detailViewProps.isLoading || isPreGtbTcProductLoading || isLabelGradeLoading,
        },
        preGtbTcId,
        labelGradeTypeOfMaterialId,
        isCertified: isCertified,
        rawMaterialPrintVisible: !!rawMaterialPrintRequired,
        form,
        readOnly,
        createRoute: "preGtbTcMaterial",
    };
}
