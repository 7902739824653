import { useCallback, useEffect, useState } from "react";
import { AuthContextType, AuthType, localStorageGtbToken } from "./useAuth";
import useGtbTranslation from "../i18n/useGtbTranslation";
import { useQuery } from "../hooks/useAxios";
import { error } from "../utils/notification/notification";
import jwtDecode from "jwt-decode";

type JwtStatus = "pending" | "valid";

export default function useRefetchJwt(
    setAuth: AuthContextType["setAuth"],
    logout: AuthContextType["logout"],
    jwt?: AuthType["jwt"],
    refreshToken?: AuthType["refreshToken"]
) {
    const translation = useGtbTranslation();
    const { runQuery } = useQuery({ url: "", enabled: false });
    const [jwtStatus, setJwtStatus] = useState<JwtStatus>("pending");

    const sendRefreshToken = useCallback(() => {
        runQuery({
            url: "/certificate-service/auth/" + refreshToken,
            defaultForbiddenHandler: false,
        })
            .then(setAuth)
            .catch(() => {
                error(translation("error.jwtRefetcher.loginAgain_toast"));
                logout();
            });
    }, [runQuery, refreshToken, setAuth, translation, logout]);
    const trySendRefreshToken = useCallback(
        (currentJwt: string) => {
            if (!document.hasFocus()) {
                const listenerMethod = () => {
                    window.removeEventListener("mousemove", listenerMethod);
                    window.removeEventListener("mousedown", listenerMethod);
                    window.removeEventListener("wheel", listenerMethod);
                    const localJwt = localStorage.getItem(localStorageGtbToken);
                    //Only refresh, if jwt has not been changed by another tab
                    if (localJwt && currentJwt === (JSON.parse(localJwt) as AuthType).jwt) sendRefreshToken();
                };
                window.addEventListener("mousemove", listenerMethod);
                window.addEventListener("mousedown", listenerMethod);
                window.addEventListener("wheel", listenerMethod);
            } else {
                sendRefreshToken();
            }
        },
        [sendRefreshToken]
    );

    useEffect(() => {
        if (!jwt) {
            setJwtStatus("valid");
            return;
        }

        const { exp } = jwtDecode<{ exp: number }>(jwt);
        const expiresInMilliseconds = exp * 1000 - new Date().getTime();
        if (expiresInMilliseconds > 0) {
            setJwtStatus("valid");
        }

        const timer = setTimeout(() => {
            trySendRefreshToken(jwt);
        }, expiresInMilliseconds);
        return () => {
            clearTimeout(timer);
        };
    }, [jwt, trySendRefreshToken]);

    return jwtStatus;
}
