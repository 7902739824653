/**
 * function for changing the value of a property of an object.
 * If the value is falsy, the property gets removed from the object.
 * Otherwise, the value of the property gets either changed or added.
 * @param object the object from which the property shall either be changed, added or removed
 * @param property the property
 * @param value the value
 */
export const setOrUnsetProperty = <T>(object: T | {}, property: keyof T, value: T[keyof T]) => {
    if (value) {
        return { ...object, [property]: value };
    }
    const { [property]: _, ...newObj } = object;
    return newObj;
};
