import { ComponentType, useCallback, useEffect, useMemo, useReducer, useState } from "react";
import ColumnSettingsComponent, { ColumnSettings } from "./ColumnSettingsComponent";
import { GridColumn } from "../../component/Grid";
import { useMutation, useQuery } from "../../../../hooks/useAxios";
import { GridProps } from "../../DataGrid";
import { handleResponseError } from "../../../../utils/errorHandler";
import { Entity } from "../../../../utils/typeUtils";

export interface WithColumnSettingsProps {
    tableName: string;
}

interface ColumnSettingState {
    columns: GridColumn<any>[];
    configurableColumns: ColumnSettings[];
}

interface ColumnSettingAction {
    availableColumns: GridColumn<any>[];
    preferences?: ColumnSettings[];
}

const reducer = (
    state: ColumnSettingState | undefined,
    action: ColumnSettingAction
): ColumnSettingState | undefined => {
    let newState: ColumnSettingState;
    const availableColumns = action.availableColumns;
    if (action.preferences) {
        const selectablePreferences = action.preferences.filter((pref) =>
            availableColumns.some((col) => col.header.title === pref.title)
        );
        newState = {
            columns: [
                ...availableColumns.filter((col) => !isConfigurableColumn(col)),
                ...selectablePreferences.map((pref) => {
                    return columnSettingToColumn(
                        pref,
                        availableColumns.find((col) => col.header.title === pref.title)
                    ) as GridColumn<any>;
                }),
            ],
            configurableColumns: [
                ...selectablePreferences,
                ...availableColumns
                    .filter(
                        (col) =>
                            isConfigurableColumn(col) &&
                            !action.preferences?.some((pref) => pref.title === col.header.title)
                    )
                    .map(columnToColumnSetting),
            ],
        };
    } else {
        newState = {
            columns: availableColumns,
            configurableColumns: availableColumns.filter(isConfigurableColumn).map(columnToColumnSetting),
        };
    }
    return newState;
};

export default function withColumnSettings<ItemType extends Entity>(
    Grid: ComponentType<GridProps<ItemType> & WithColumnSettingsProps>
) {
    return ({
        columns: availableColumns,
        isLoading: isDataLoading,
        tableName,
        gridControlsRight,
        ...rest
    }: GridProps<ItemType> & WithColumnSettingsProps) => {
        const [state, dispatch] = useReducer(reducer, undefined);
        const [preferences, setPreferences] = useState();

        const setPreferencesOrDefault = useCallback((preferencesOfUser, statusCode) => {
            if (statusCode === 200) {
                setPreferences(preferencesOfUser);
            } else {
                setPreferences(undefined);
            }
        }, []);

        const { runQuery: loadPreferences, isLoading: isLoadingPreferences } = useQuery<ColumnSettings[]>({
            url: "/certificate-service/preferences/tables/" + tableName,
            onSuccess: setPreferencesOrDefault,
        });
        const { runQuery: savePreferences, isLoading: isSavingPreferences } = useMutation({
            method: "put",
            url: "/certificate-service/preferences/tables/" + tableName,
        });

        const { runQuery: resetPreferences, isLoading: isResettingPreferences } = useMutation({
            method: "delete",
            url: "/certificate-service/preferences/tables/" + tableName,
        });

        useEffect(() => {
            dispatch({ availableColumns, preferences });
        }, [availableColumns, preferences]);

        const isLoading = useMemo(
            () => isDataLoading || isSavingPreferences || isLoadingPreferences || isResettingPreferences,
            [isDataLoading, isSavingPreferences, isLoadingPreferences, isResettingPreferences]
        );

        const applyColumnSettings: applyColumnSettingsFunc = useCallback(
            (columnSettings) => {
                savePreferences({ body: columnSettings })
                    .then(() => loadPreferences())
                    .catch(handleResponseError);
            },
            [loadPreferences, savePreferences]
        );

        const resetColumnSettings = useCallback(() => {
            resetPreferences()
                .then(() => {
                    setPreferences(undefined);
                })
                .catch(handleResponseError);
        }, [resetPreferences]);

        const changeColumnWidth = useCallback(
            (title: string, width: number) => {
                applyColumnSettings(
                    state!.configurableColumns.map((col) => {
                        if (col.title === title) {
                            return { ...col, width };
                        }
                        return col;
                    })
                );
            },
            [applyColumnSettings, state]
        );

        const columns = useMemo(
            () =>
                state
                    ? state.columns.map((col) => {
                          return { ...col, header: { ...col.header, onWidthChange: changeColumnWidth } };
                      })
                    : [],
            [changeColumnWidth, state]
        );

        return (
            <Grid
                tableName={tableName}
                gridControlsRight={[
                    ...(gridControlsRight ?? []),
                    <ColumnSettingsComponent
                        columns={state?.configurableColumns}
                        applyColumnSettings={applyColumnSettings}
                        resetColumnSettings={resetColumnSettings}
                        key="columnSettings"
                    />,
                ]}
                columns={columns}
                isLoading={isLoading}
                {...rest}
            />
        );
    };
}

export type applyColumnSettingsFunc = (newColumnSettings: ColumnSettings[]) => void;

const isConfigurableColumn = (column: GridColumn<any>) => {
    return !!column.header.title;
};

const columnToColumnSetting = (column: GridColumn<any>) => {
    return {
        title: column.header.title as string,
        visible: column.visible,
        width: column.header.width,
    };
};

const columnSettingToColumn = (setting: ColumnSettings, column?: GridColumn<any>) => {
    if (!column) {
        return;
    }
    return { ...column, header: { ...column.header, width: setting.width }, visible: setting.visible };
};
