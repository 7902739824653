import { SortOption } from "../components/grid/component/useQueryBuilder";
import { AllowedMaterialDetails, AllowedMaterialSummary } from "./allowedMaterialTypes";
import useAllowedMaterialSubList from "./useAllowedMaterialSubList";
import useResolvedRoute from "../components/routing/useResolvedRoute";

export const defaultRawMaterialAllowedMaterialsSortOption: SortOption<AllowedMaterialSummary | AllowedMaterialDetails> =
    {
        field: "standardOfficialName",
        direction: "ASC",
    };

export default function useRawMaterialAllowedMaterialSubList() {
    const { getResolvedCreateUrl } = useResolvedRoute();
    const { getResolvedId } = useResolvedRoute();
    const rawMaterialId = getResolvedId("rawMaterial");

    const { ...allowedMaterialListOptions } = useAllowedMaterialSubList({
        baseQueryParamKey: "rawMaterialId",
        baseQueryParamValue: rawMaterialId!,
        sortOption: defaultRawMaterialAllowedMaterialsSortOption,
        column: {
            key: "standardOfficialName",
            title: "allowedMaterial.list.standard_column",
            minWidth: 135,
        },
        createUrl: getResolvedCreateUrl("rawMaterialAllowedMaterial"),
    });

    return allowedMaterialListOptions;
}
