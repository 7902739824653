import "./tcproductMaterialComposition.css";
import useTcMaterialSubList from "./useTcMaterialSubList";
import { TcProductBasicDataProps } from "../TcProductBasicData";
import { joinClassNames } from "../../../../../utils/StringUtils";
import GtbTextField from "../../../../../components/Input/GtbTextField";
import InfinityScrollSubList from "../../../../../components/grid/InfinityScrollSubList";

export default function TcProductMaterialComposition({ selectedScProduct }: TcProductBasicDataProps) {
    const tcMaterialSubList = useTcMaterialSubList();

    return (
        <div className={joinClassNames("tcProductMaterialComposition", "formGrid")}>
            <GtbTextField
                label="tcProduct.detail.productCategoryPrint_input"
                readOnly
                className="productCategoryPrint"
                value={selectedScProduct?.productCategoryPrint ?? ""}
            />
            <GtbTextField
                label="tcProduct.detail.productDetailPrint_input"
                readOnly
                className="productDetailPrint"
                value={selectedScProduct?.productDetailPrint ?? ""}
            />
            <GtbTextField
                label="tcProduct.detail.labelGrade_input"
                readOnly
                className="labelGrade"
                value={selectedScProduct?.labelGradeLabelGrade ?? ""}
            />
            <InfinityScrollSubList {...tcMaterialSubList} className="tcMaterials" />
        </div>
    );
}
