import useGtbTranslation, { I18nKey } from "../../../i18n/useGtbTranslation";
import { useCallback, useMemo } from "react";
import { enUS } from "date-fns/locale";

export const monthI18nKey: Array<I18nKey> = [
    "components.datePicker.months.january_label",
    "components.datePicker.months.february_label",
    "components.datePicker.months.march_label",
    "components.datePicker.months.april_label",
    "components.datePicker.months.may_label",
    "components.datePicker.months.june_label",
    "components.datePicker.months.july_label",
    "components.datePicker.months.august_label",
    "components.datePicker.months.september_label",
    "components.datePicker.months.october_label",
    "components.datePicker.months.november_label",
    "components.datePicker.months.december_label",
];

export const dayI18nKey: Array<I18nKey> = [
    "components.datePicker.days.sunday_label",
    "components.datePicker.days.monday_label",
    "components.datePicker.days.tuesday_label",
    "components.datePicker.days.wednesday_label",
    "components.datePicker.days.thursday_label",
    "components.datePicker.days.friday_label",
    "components.datePicker.days.saturday_label",
];

export default function useDatePickerLocalization() {
    const translation = useGtbTranslation();

    const month = useCallback(
        (...args: Array<any>) => {
            return translation(monthI18nKey[args[0]]);
        },
        [translation]
    );

    const day = useCallback(
        (...args: Array<any>) => {
            return translation(dayI18nKey[args[0]]);
        },
        [translation]
    );

    const locale = useMemo<Locale>(() => {
        return {
            ...enUS,
            localize: {
                ...enUS.localize!,
                month,
                day,
            },
        };
    }, [day, month]);

    const localeText = useMemo(() => {
        return {
            nextMonth: translation("components.datePicker.nextMonth_button"),
            previousMonth: translation("components.datePicker.previousMonth_button"),
            calendarViewSwitchingButtonAriaLabel: (view: any) =>
                view === "year"
                    ? translation("components.datePicker.yearToCalendarViewSwitch_accessibleLabel")
                    : translation("components.datePicker.calendarToYearViewSwitch_accessibleLabel"),
            openDatePickerDialogue: () => translation("components.datePicker.openDatePicker_accessibleLabel"),
        };
    }, [translation]);

    return { locale, localeText };
}
