import useDetailsCreate from "../../hooks/details/useDetailsCreate";
import { yupResolver } from "@hookform/resolvers/yup";
import { Nullable } from "../../utils/typeUtils";
import { ProductCategoryDetails } from "../productCategoryTypes";
import { productCategoryValidationSchema } from "./productCategoryValidationSchema";
import { routeEnum } from "../../components/routing/useResolvedRoute";
import { getInitialTranslatableValue } from "../../i18n/languageTypes";

const initialData: Nullable<ProductCategoryDetails> = {
    code: null,
    id: null,
    name: getInitialTranslatableValue(),
    pickListSequence: null,
    startDate: null,
    endDate: null,
};

function useProductCategoryCreate() {
    const { detailViewProps, form, title } = useDetailsCreate<ProductCategoryDetails>({
        title: "productCategory.detail.newRecord_title",
        frontendRoute: "productCategory",
        initialData,
        resolver: yupResolver(productCategoryValidationSchema),
        saveUrl: "/certificate-service/product-categories",
    });

    return {
        title,
        detailViewProps,
        form,
        navigationEnabled: false,
        isReadOnly: false,
        createRoute: "productCategory" as routeEnum,
    };
}

export default useProductCategoryCreate;
