import { useMemo } from "react";
import { UploadLogDetails, UploadLogSummary } from "./uploadLogTypes";
import { buildDateTimeColumn, buildSystemEnumColumn, buildTextColumn } from "../components/grid/ColumnProvider";
import { useShowDetailsAction } from "../utils/defaultActions";
import useListView from "../hooks/useListView";
import { SortOption } from "../components/grid/component/useQueryBuilder";
import { GridColumn } from "../components/grid/component/Grid";
import useResolvedRoute from "../components/routing/useResolvedRoute";
import useIsAuthorised from "../auth/useIsAuthorised";
import { UploadLogListViewProps } from "./UploadLogListView";
import { logLevel } from "../applicationLog/applicationLogTypes";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";

export const defaultUploadLogSortOption: SortOption<UploadLogSummary | UploadLogDetails> = {
    field: "timestamp",
    direction: "DESC",
};

const columns: GridColumn<UploadLogSummary>[] = [
    buildDateTimeColumn({ key: "timestamp", title: "uploadLog.list.timestamp_column", minWidth: 141 }),
    buildSystemEnumColumn({
        key: "logLevel",
        title: "uploadLog.list.logLevel_column",
        minWidth: 151,
        enumRecord: logLevel,
    }),
    buildTextColumn({ key: "message", title: "uploadLog.list.message_column", minWidth: 124 }),
    buildTextColumn({ key: "className", title: "uploadLog.list.className_column", minWidth: 145 }),
    buildTextColumn({
        key: "certificationBodyBusinessName",
        title: "uploadLog.list.certificationBody_column",
        minWidth: 192,
    }),
    buildTextColumn({ key: "remarks", title: "uploadLog.list.remarks_column", minWidth: 124 }),
];

export default function useUploadLogList() {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedEditUrl } = useResolvedRoute();

    const {
        queryBuilder: { query, columnOptions, queryCommands },
    } = useListView<UploadLogSummary>({
        navigationContextLabel: "uploadLog.breadcrumb",
        baseQuery: "/log-service/uploadlogs",
        defaultSortOption: defaultUploadLogSortOption,
    });

    const showDetailsAction = useShowDetailsAction(
        (item: UploadLogSummary) => getResolvedEditUrl("uploadLog", item.id),
        query.parameter
    );

    const contextMenuActions = useGridRowContextMenuActions(showDetailsAction);

    return useMemo<UploadLogListViewProps>(() => {
        return {
            listProps: {
                query,
                columns,
                uniqueDataKey: "id",
                limit: 100,
                onItemDoubleClick: hasAccessRight("useUploadLog") ? showDetailsAction : undefined,
                createUrl: undefined,
                tableName: "uploadLog",
                columnOptions,
                itemActions: contextMenuActions,
                queryCommands,
            },
        };
    }, [columnOptions, contextMenuActions, hasAccessRight, query, queryCommands, showDetailsAction]);
}
