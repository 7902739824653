import { buildSchema, validateDate, validateString } from "../../utils/yupExtension";
import { accessLevelEnum, SupplyingRelationDetails } from "../supplyingRelationTypes";
import { BaseSchema } from "yup";

export const supplyingRelationValidationSchema = buildSchema<SupplyingRelationDetails>({
    supplyChainOperator: validateString().isRequired(),
    organicMaterialProducer: validateString().isRequired(),
    accessLevel: validateString().isRequired() as BaseSchema<accessLevelEnum>,
    startDate: validateDate().isRequired(),
});
