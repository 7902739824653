import { TypedDetailViewProps } from "../../components/detailView/DetailView";
import { UseGtbFormReturn } from "../../hooks/formHandling/useGtbForm";
import "../../components/spinner.css";
import { ByproductInputCombinationDetails } from "../byproductInputCombinationTypes";
import ByproductInputCombinationBasicData from "./ByproductInputCombinationBasicData";
import EntityDetailView from "../../components/entity/EntityDetailView";
import { useHelpContext } from "../../help/context-sensitivity/useHelpContext";

export default function ByproductInputCombinationDetailView({
    detailViewProps,
    form,
    isReadOnly,
    ...rest
}: ByproductInputCombinationDetailViewProps) {
    useHelpContext("by-product-input-combination-details");
    return (
        <EntityDetailView form={form} detailViewProps={detailViewProps} {...rest}>
            <ByproductInputCombinationBasicData form={form} readOnly={isReadOnly} />
        </EntityDetailView>
    );
}

export interface ByproductInputCombinationDetailViewProps
    extends TypedDetailViewProps<ByproductInputCombinationDetails> {
    isReadOnly: boolean;
}

export interface ByproductInputCombinationDetailViewTabProps {
    form: UseGtbFormReturn<ByproductInputCombinationDetails>;
    readOnly: boolean;
}
