import useFormDialog from "../components/dialog/useFormDialog";
import { UseGtbFormReturn } from "../hooks/formHandling/useGtbForm";
import React, { useCallback } from "react";
import DialogContent from "../components/dialog/DialogContent";
import { emergencyShutdownValidationSchema } from "./emergencyShutdownValidationSchema";
import { EmergencyShutdown } from "./emergencyShutdownTypes";
import { LabeledCheckbox } from "../components/Input/Checkbox";
import GtbTextField from "../components/Input/GtbTextField";
import useAuth from "../auth/useAuth";
import { useMutation } from "../hooks/useAxios";
import { error, info } from "../utils/notification/notification";
import useGtbTranslation from "../i18n/useGtbTranslation";
import GtbPasswordField from "../components/Input/GtbPasswordField";

export default function useEmergencyShutdownDialog() {
    const { currentUser } = useAuth();
    const { runQuery: requestOtp } = useMutation({ method: "post", url: "/certificate-service/emergency-shutdown/otp-request" });
    const translation = useGtbTranslation();

    const showFormDialog = useFormDialog({
        icon: "warning",
        variant: "warning",
        className: "emergencyShutdownDialog",
        renderContent: renderEmergencyShutdownDialogContent,
        schema: emergencyShutdownValidationSchema,
        title: "components.emergencyShutdownDialog.dialog_title",
        afterSubmit: () => info(translation("components.emergencyShutdownDialog.shutdown_successful_message")),
        resolveErrors: (err) => {
            if (err.data) {
                error(translation({
                    key: "components.emergencyShutdownDialog.shutdown_unsuccessful_message",
                    options: { "reason": err.data },
                }));
                return true;
            }
            return false;
        },
    });

    return useCallback(() => {
        if (!currentUser.totpReady) {
            requestOtp().catch(() => {
                error(translation("components.emergencyShutdownDialog.otp_request_error"));
            });
        }
        showFormDialog(Promise.resolve({
            userInterface: false,
            uploadApi: false,
            websiteApi: false,
            certificateAuthenticationInterface: false,
            password: null,
            otp: null,
        }), "/certificate-service/emergency-shutdown");
    }, []);

}

const renderEmergencyShutdownDialogContent = (form: UseGtbFormReturn<EmergencyShutdown>) => {
    return (
        <DialogContent className="formGrid">
            <LabeledCheckbox
                id="shutdownUserInterface"
                label="components.emergencyShutdownDialog.user_interface_checkbox"
                {...form.form.register("userInterface")} />
            <LabeledCheckbox
                id="shutdownUploadApi"
                label="components.emergencyShutdownDialog.upload_api_checkbox"
                {...form.form.register("uploadApi")} />
            <LabeledCheckbox
                id="shutdownWebsiteApi"
                label="components.emergencyShutdownDialog.website_api_checkbox"
                {...form.form.register("websiteApi")} />
            <LabeledCheckbox
                id="shutdownCertificateAuthenticationInterface"
                label="components.emergencyShutdownDialog.certificate_authentication_interface_checkbox"
                {...form.form.register("certificateAuthenticationInterface")} />
            <GtbPasswordField label="components.emergencyShutdownDialog.password_input" {...form.registerWithErrors("password")} />
            <GtbTextField label="components.emergencyShutdownDialog.otp_input" {...form.registerWithErrors("otp")} />
        </DialogContent>
    );
};
