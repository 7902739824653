import { useHelpContext } from "../help/context-sensitivity/useHelpContext";
import EntityListView from "../components/entity/EntityListView";
import useTransactionCertificateListView from "./useTransactionCertificateListView";
import { I18nKey } from "../i18n/useGtbTranslation";

export type TransactionCertificateSearchContext =
    | "ALL"
    | "IN_PREPARATION"
    | "AWAITING_APPROVAL"
    | "VALID"
    | "WITHDRAWN"
    | "VALID_CLIENT"
    | "WITHDRAWN_CLIENT"
    | "IN_PREPARATION_CLIENT"
    | "AWAITING_APPROVAL_CLIENT";

export const transactionCertificateSearchContext: Record<TransactionCertificateSearchContext, I18nKey> = {
    ALL: "transactionCertificate.list.all_header",
    IN_PREPARATION: "transactionCertificate.list.inPreparation_header",
    AWAITING_APPROVAL: "transactionCertificate.list.awaitingApproval_header",
    VALID: "transactionCertificate.list.valid_header",
    WITHDRAWN: "transactionCertificate.list.withdrawn_header",
    VALID_CLIENT: "transactionCertificate.list.valid_client_header",
    WITHDRAWN_CLIENT: "transactionCertificate.list.withdrawn_client_header",
    IN_PREPARATION_CLIENT: "transactionCertificate.list.in_preparation_client_header",
    AWAITING_APPROVAL_CLIENT: "transactionCertificate.list.awaiting_approval_client_header",
};

export default function TransactionCertificateListView({
    searchContext,
}: {
    searchContext: TransactionCertificateSearchContext;
}) {
    const transactionCertificateGridOptions = useTransactionCertificateListView(searchContext);

    useHelpContext("transaction-certificate-list");

    return (
        <EntityListView
            heading={transactionCertificateSearchContext[searchContext]}
            gridOptions={transactionCertificateGridOptions}
        />
    );
}
