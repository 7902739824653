import yup, { validateString } from "../../utils/yupExtension";
import { notifyStandardBodyAdminsOptionsEnumType } from "../eventMonitorTypes";

export const eventMonitorValidationSchema = yup.object().shape({
    eventType: validateString().isRequired(),
    name: validateString().isRequired().hasMaxLength(40),
    signallingThreshold: validateString().isRequired(),
    observationFrameInSeconds: validateString().isRequired(),
    additionalText: validateString().hasMaxLength(1000),
    notifySpecificStandardBodyAdmin: validateString().when("notifyStandardBodyAdminsOptionsEnum", {
        is: (notifyStandardBodyAdminsOptionsEnum: notifyStandardBodyAdminsOptionsEnumType) =>
            notifyStandardBodyAdminsOptionsEnum === "SPECIFIC",
        then: validateString().isRequired(),
    }),
});
