import { ReactNode } from "react";
import Form from "../Form";
import DetailViewInterface from "./DetailViewInterface";
import "../detailView.css";
import useDetailView, { UseDetailViewProps } from "./useDetailView";
import LoadingSpinner from "../LoadingSpinner";
import { UseGtbFormReturn } from "../../hooks/formHandling/useGtbForm";
import { routeEnum } from "../routing/useResolvedRoute";
import { FieldValues } from "react-hook-form/dist/types";
import { HistoryParams } from "../../history/useHistoryDialog";
import { backendUrlType } from "../../hooks/useAxios";
import { RecordNavigationProps } from "../card/navigation/RecordNavigation";
import { I18nKey } from "../../i18n/useGtbTranslation";
import { CardHeaderProps } from "../card/CardHeader";

/**
 * Component for encapsulating functionality of a standard detail view
 */
function DetailView<ItemType extends FieldValues>({
    detailViewProps,
    children,
    createRoute,
}: DetailViewProps<ItemType>) {
    const { onSubmit, isLoading, undoChanges } = useDetailView<ItemType>(detailViewProps);
    if (isLoading) {
        return (
            <div className="detailViewLoadingPlaceholder">
                <LoadingSpinner />
            </div>
        );
    }

    return (
        <Form className="detailView" onSubmit={onSubmit}>
            {children}
            <DetailViewInterface
                canEdit={!!onSubmit}
                undoChanges={undoChanges}
                createRoute={createRoute}
                noteRootUrl={detailViewProps.noteRootUrl}
                historyParams={detailViewProps.historyParams}
                goToMapUrl={detailViewProps.goToMapUrl}
            />
        </Form>
    );
}

export default DetailView;

interface DetailViewProps<ItemType extends FieldValues> {
    detailViewProps: UseDetailViewProps<ItemType>;
    children: ReactNode;
    createRoute?: routeEnum;
    noteRootUrl?: backendUrlType;
    historyParams?: HistoryParams;
}

export interface TypedDetailViewProps<ItemType extends FieldValues> {
    detailViewProps: UseDetailViewProps<ItemType>;
    form: UseGtbFormReturn<ItemType>;
    title?: I18nKey;
    subtitle?: CardHeaderProps["subtitle"];
    recordNavigationProps?: RecordNavigationProps;
    createRoute?: routeEnum;
}
