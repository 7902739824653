import { useMemo } from "react";
import { StandardPickListFlags } from "../../components/Input/pickList/typedPicklist/StandardPickList";
import useAuth from "../../auth/useAuth";

export default function useDefaultStandardPickListFlagsForRelations() {
    const {
        currentUser: { authorisationRole },
    } = useAuth();

    return useMemo<StandardPickListFlags>(
        () => ({
            organisation_type: authorisationRole.type === "STANDARD_BODY" ? "STANDARD_BODY" : undefined,
            typeOfStandard: authorisationRole.type !== "STANDARD_BODY" ? "TYPICAL" : undefined,
        }),
        [authorisationRole.type]
    );
}
