import { joinClassNames } from "../../utils/StringUtils";
import "./standardAllowedMaterials.css";
import SubList from "../../components/grid/SubList";
import useStandardAllowedMaterialSubList from "../../allowedMaterial/useStandardAllowedMaterialSubList";

function StandardAllowedMaterials() {
    const allowedMaterialListOptions = useStandardAllowedMaterialSubList();

    return (
        <div className={joinClassNames("standardAllowedMaterials", "formGrid")}>
            <SubList {...allowedMaterialListOptions} className="allowedMaterials" />
        </div>
    );
}

export default StandardAllowedMaterials;
