import { useCallback } from "react";
import { useMutation } from "../../../hooks/useAxios";
import { error, info } from "../../../utils/notification/notification";
import { idHandlingMode, useMultiSelectStore } from "../../../utils/multiSelectStore";
import { buildURL } from "../component/useQueryBuilder";
import { GridProps } from "../DataGrid";
import { Entity, RequireInheritedProps } from "../../../utils/typeUtils";
import useGtbTranslation, { I18nKey } from "../../../i18n/useGtbTranslation";
import { SimpleGridColumn } from "../component/SimpleGrid";
import useFileDownload from "../../../hooks/useFileDownload";

export default function useBulkExport<ItemType extends Entity>({
    type,
    total,
    query,
    columns,
}: UseBulkExportProps<ItemType>) {
    const translation = useGtbTranslation();
    const { selectedItems, allSelectedState, isBlacklist } = useMultiSelectStore();
    const startDownload = useFileDownload();
    const { runQuery } = useMutation({
        method: "post",
        url: buildURL(query.url + "/export", { ...query.parameter, sort: undefined, filterActive: undefined }),
    });

    return useCallback(() => {
        if (!total) {
            info(translation("components.grid.nothingToExport_toast"));
            return;
        }

        const allItemsSelected = allSelectedState === "all" || allSelectedState === "none";
        const assuredPresentIdArray = allItemsSelected ? [] : selectedItems ?? [];
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const parsedColumns = columns
            .filter((column) => column.key !== "gridSelectCol")
            .map((column) => {
                return {
                    identifier: column.key,
                    title: translation(column.header.title as I18nKey),
                };
            });

        const body = {
            exportVariant: type,
            columns: parsedColumns,
            sorting: query.parameter.sort,
            filter: query.parameter.filter,
            ids: assuredPresentIdArray,
            idHandlingMode: isBlacklist ? idHandlingMode.EXCLUDE : idHandlingMode.INCLUDE,
            localTimeZone: timeZone,
        };

        runQuery({ body })
            .then(startDownload)
            .catch(() => error(translation("error.grid.unexpectedError_message")));
    }, [
        total,
        allSelectedState,
        selectedItems,
        columns,
        type,
        query.parameter.sort,
        query.parameter.filter,
        isBlacklist,
        runQuery,
        startDownload,
        translation,
    ]);
}

export interface UseBulkExportProps<ItemType extends Entity>
    extends RequireInheritedProps<GridProps<ItemType>, "query"> {
    total?: GridProps<ItemType>["total"];
    columns: GridProps<ItemType>["columns"];
    type: "XLSX" | "CSV";
}

export function useSimplifiedExport(
    url: string,
    type: "XLSX" | "CSV",
    columns: SimpleGridColumn[],
    onFinished?: Function
) {
    const translation = useGtbTranslation();
    const startDownload = useFileDownload();
    const { runQuery } = useMutation({
        method: "post",
        url,
    });

    return useCallback(() => {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const body = {
            exportVariant: type,
            columns: columns,
            localTimeZone: timeZone,
        };

        runQuery({ body })
            .then(startDownload)
            .then(() => onFinished?.())
            .catch(() => error(translation("error.grid.unexpectedError_message")));
    }, [type, columns, runQuery, startDownload, onFinished, translation]);
}
