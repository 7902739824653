import { buildSchema, validateDate, validateString } from "../../utils/yupExtension";
import { AccreditationRelationDetails } from "../accreditationRelationTypes";

export const accreditationRelationValidationSchema = buildSchema<AccreditationRelationDetails>({
    certificationBody: validateString().isRequired(),
    accreditationBody: validateString().isRequired(),
    standardVersion: validateString().isRequired(),
    accreditationNumber: validateString().hasMaxLength(15).isRequired(),
    startDate: validateDate().isRequired(),
});
