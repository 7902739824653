import { useCallback } from "react";
import { FieldPath, FieldPathValue } from "react-hook-form";
import { UseGtbFormReturn } from "./useGtbForm";
import { FieldValues } from "react-hook-form/dist/types";

export default function useSetFormValue<ItemType extends FieldValues>(form: UseGtbFormReturn<ItemType>["form"]) {
    return useCallback(
        <ItemKey extends FieldPath<ItemType>>(
            fieldName: ItemKey,
            valueToSet?: FieldPathValue<ItemType, ItemKey>,
            shouldDirty = true
        ) =>
            form.setValue(fieldName, valueToSet ?? null!, {
                shouldDirty,
                shouldValidate: form.formState.isSubmitted,
            }),
        [form]
    );
}
