import GtbTextField from "../../components/Input/GtbTextField";
import { joinClassNames } from "../../utils/StringUtils";
import "./userData.css";
import { LabeledCheckbox } from "../../components/Input/Checkbox";
import ControlledDatePicker from "../../components/Input/datepicker/ControlledDatePicker";
import { UserDetailViewTabProps } from "./UserDetailView";
import AuthorisationRolePickList from "../../components/Input/pickList/typedPicklist/AuthorisationRolePicklist";
import OrganisationPickList from "../../components/Input/pickList/typedPicklist/OrganisationPickList";
import StandardBodyPickList from "../../components/Input/pickList/typedPicklist/StandardBodyPickList";

export default function UserData({ form, readOnlyProps }: UserDetailViewTabProps) {
    return (
        <div className={joinClassNames("userData", "formGrid")}>
            <GtbTextField
                {...form.registerWithErrors("username")}
                onChange={(e) => {
                    // only allow lowercase chars
                    e.target.value = e.target.value.toLocaleLowerCase();
                }}
                readOnly={readOnlyProps.isUsernameReadOnly}
                label="user.detail.username_input"
                className="username"
            />
            <AuthorisationRolePickList
                control={form.form.control}
                name="authorisationRole"
                readOnly={readOnlyProps.isRoleReadOnly}
                className="role"
                label="user.detail.authorisationRole_input"
            />
            <LabeledCheckbox
                {...form.form.register("blocked")}
                readOnly={readOnlyProps.isBlockedReadOnly}
                label="user.detail.blocked_input"
                id="blocked"
                className="blocked"
            />
            <GtbTextField
                {...form.registerWithErrors("givenName")}
                readOnly={readOnlyProps.isGivenNameReadOnly}
                label="user.detail.givenName_input"
                className="givenName"
            />
            <GtbTextField
                {...form.registerWithErrors("surname")}
                readOnly={readOnlyProps.isSurnameReadOnly}
                label="user.detail.surname_input"
                className="surname"
            />
            <OrganisationPickList
                form={form.form}
                label="user.detail.organisation_input"
                parentField="authorisationRole"
                readOnly={readOnlyProps.isOrganisationReadOnly}
                name="organisation"
                className="organisation"
            />
            <StandardBodyPickList
                control={form.form.control}
                name="standardBody"
                readOnly={readOnlyProps.isStandardBodyReadOnly}
                className="standardBody"
                label="user.detail.standardBody_input"
                flags={{ fetch_all: true }}
            />
            <GtbTextField
                {...form.registerWithErrors("emailAddress")}
                readOnly={readOnlyProps.isEmailAddressReadOnly}
                label="user.detail.emailAddress_input"
                className="email"
            />
            <div className="activation-period">
                <ControlledDatePicker
                    control={form.form.control}
                    readOnly={readOnlyProps.isStartDateReadOnly}
                    name="startDate"
                    label="user.detail.startDate_input"
                    className="startDate"
                />
                <ControlledDatePicker
                    control={form.form.control}
                    readOnly={readOnlyProps.isEndDateReadOnly}
                    name="endDate"
                    label="user.detail.endDate_input"
                    className="endDate"
                />
            </div>
        </div>
    );
}
