import { useEffect } from "react";
import { PreGtbTcMaterialDetails } from "../preGtbTcMaterialsTypes";
import { UseGtbFormReturn } from "../../../../hooks/formHandling/useGtbForm";
import { PreGtbProductDetails } from "../../products/preGtbProductTypes";
import { calculateCertifiedMaterialWeight } from "../../../../utils/calculateWeightUtils";

export default function useCalculatePreGtbTcMaterialCertifiedWeight(
    form: UseGtbFormReturn<PreGtbTcMaterialDetails, any>,
    preGtbTcProduct: PreGtbProductDetails | undefined,
    isCertified: boolean,
    percentage: number,
    isLoading: boolean
) {
    useEffect(() => {
        let calculatedCertifiedWeight = 0;

        if (!isLoading && isCertified && preGtbTcProduct) {
            calculatedCertifiedWeight = calculateCertifiedMaterialWeight(
                percentage,
                preGtbTcProduct.netShippingWeight,
                preGtbTcProduct.supplementaryWeight
            );
        }

        form.form.setValue("certifiedWeight", calculatedCertifiedWeight);
    }, [form.form, isCertified, isLoading, percentage, preGtbTcProduct]);
}
