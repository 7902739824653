import { UseGtbFormReturn } from "../../../../hooks/formHandling/useGtbForm";
import { joinClassNames } from "../../../../utils/StringUtils";
import GtbTextField from "../../../../components/Input/GtbTextField";
import "./tcProductLogisticData.css";
import { TcProductDetails } from "../tcProductTypes";
import ControlledDatePicker from "../../../../components/Input/datepicker/ControlledDatePicker";
import GtbTextArea from "../../../../components/Input/GtbTextArea";
import { ScProductReference } from "../../../../scopeCertificate/details/products/scProductTypes";
import DependentScFacilityPickList from "../../../../components/Input/pickList/typedPicklist/DependentScFacilityPickList";
import { TcProductReadOnlyProps } from "./TcProductDetailView";
import useSetFormValue from "../../../../hooks/formHandling/useSetFormValue";
import { useCallback, useEffect, useState } from "react";
import { ScFacilityReference } from "../../../../scopeCertificate/details/products/details/processCategories/details/facilities/scFacilityTypes";

export interface TcProductLogisticDataProps {
    form: UseGtbFormReturn<TcProductDetails>;
    readOnlyProps: TcProductReadOnlyProps;
    selectedScProduct?: ScProductReference;
}

export default function TcProductLogisticData({ form, readOnlyProps, selectedScProduct }: TcProductLogisticDataProps) {
    const setFormValue = useSetFormValue(form.form);

    const selectedProcessors = form.form.watch("tcProcessors");
    const lastProcessor = form.form.watch("lastProcessor");
    const [lastProcessorFilteredOptions, setLastProcessorFilteredOptions] = useState<string[]>([]);

    useEffect(() => {
        if (selectedProcessors && lastProcessor && !selectedProcessors.includes(lastProcessor)) {
            setFormValue("lastProcessor");
        }
        setLastProcessorFilteredOptions(selectedProcessors ?? []);
    }, [lastProcessor, selectedProcessors, setFormValue]);

    const lastProcessorFilter = useCallback(
        (item: ScFacilityReference) => lastProcessorFilteredOptions.includes(item.id),
        [lastProcessorFilteredOptions]
    );

    return (
        <div className={joinClassNames("tcProductLogisticData", "formGrid")}>
            <GtbTextField
                label="tcProduct.detail.productCategoryPrint_input"
                readOnly
                className="productCategoryPrint"
                value={selectedScProduct?.productCategoryPrint ?? ""}
            />
            <GtbTextField
                label="tcProduct.detail.productDetailPrint_input"
                readOnly
                className="productDetailPrint"
                value={selectedScProduct?.productDetailPrint ?? ""}
            />
            <GtbTextField
                label="tcProduct.detail.labelGrade_input"
                readOnly
                className="labelGrade"
                value={selectedScProduct?.labelGradeLabelGrade ?? ""}
            />
            <GtbTextField
                label="tcProduct.detail.orderNumber_input"
                {...form.registerWithErrors("orderNumber")}
                readOnly={readOnlyProps.readOnly}
                className="orderNumber"
            />
            <GtbTextField
                label="tcProduct.detail.articleNumber_input"
                {...form.registerWithErrors("articleNumber")}
                readOnly={readOnlyProps.readOnly}
                className="articleNumber"
            />
            <ControlledDatePicker
                readOnly={readOnlyProps.readOnly}
                control={form.form.control}
                name="productionDate"
                label="tcProduct.detail.productionDate_input"
                className="productionDate"
            />
            <DependentScFacilityPickList
                label="tcProduct.detail.processors_input"
                form={form.form}
                name="tcProcessors"
                readOnly={readOnlyProps.readOnly}
                className="processors"
                parentField={"relatedScProduct"}
                multiple
            />
            <DependentScFacilityPickList
                label="tcProduct.detail.lastProcessor_input"
                form={form.form}
                name="lastProcessor"
                readOnly={readOnlyProps.readOnly}
                className="lastProcessor"
                parentField={"relatedScProduct"}
                filter={lastProcessorFilter}
            />
            <GtbTextArea
                label="tcProduct.detail.additionalInformation_input"
                {...form.registerWithErrors("additionalInformation")}
                className="additionalInformation"
                readOnly={readOnlyProps.readOnly}
            />
        </div>
    );
}
