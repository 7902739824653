import useIsAuthorised from "../auth/useIsAuthorised";
import { SortOption } from "../components/grid/component/useQueryBuilder";
import { useMemo } from "react";
import { GridColumn } from "../components/grid/component/Grid";
import useListView from "../hooks/useListView";
import { useDeleteAction, useShowDetailsAction } from "../utils/defaultActions";
import { CertificationBodyDetails, CertificationBodySummary } from "./certificationBodyTypes";
import {
    buildDataColumn,
    buildDateColumn,
    buildNumberColumn,
    buildTextColumn,
} from "../components/grid/ColumnProvider";
import useResolvedRoute, { getCreateUrl } from "../components/routing/useResolvedRoute";
import { EntityListViewGridProps } from "../components/entity/EntityListView";
import { EnumEntityType } from "../components/Input/pickList/typedEnumPicklist/enumEntityType";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";

export const defaultCertificationBodySortOption: SortOption<CertificationBodySummary | CertificationBodyDetails> = {
    field: "systemId",
    direction: "ASC",
};

const columns: GridColumn<CertificationBodySummary>[] = [
    buildTextColumn({ key: "systemId", title: "certificationBody.list.systemId_column", minWidth: 134 }),
    buildTextColumn({ key: "businessName", title: "certificationBody.list.businessName_column", minWidth: 174 }),
    buildTextColumn({
        key: "stateProvinceCountryAreaName",
        title: "certificationBody.list.countryArea_column",
        minWidth: 155,
    }),
    buildDataColumn<CertificationBodySummary, EnumEntityType>({
        key: "organisationStatus",
        title: "certificationBody.list.status_column",
        minWidth: 190,
        url: "/certificate-service/references/organisation-statuses",
        itemId: "id",
        itemLabel: "name",
    }),
    buildTextColumn({
        key: "addressDetails1",
        title: "certificationBody.list.addressDetail1_column",
        minWidth: 180,
        visible: false,
    }),
    buildTextColumn({
        key: "addressDetails2",
        title: "certificationBody.list.addressDetail2_column",
        minWidth: 180,
        visible: false,
    }),
    buildTextColumn({
        key: "addressDetails3",
        title: "certificationBody.list.addressDetail3_column",
        minWidth: 180,
        visible: false,
    }),
    buildTextColumn({
        key: "authorisedPerson",
        title: "certificationBody.list.authorisedPerson_column",
        minWidth: 183,
        visible: false,
    }),
    buildDateColumn({ key: "endDate", title: "certificationBody.list.endDate_column", minWidth: 124, visible: false }),
    buildTextColumn({
        key: "legalName",
        title: "certificationBody.list.legalName_column",
        minWidth: 145,
        visible: false,
    }),
    buildTextColumn({
        key: "organisationalEmail",
        title: "certificationBody.list.organisationalEmailAddress_column",
        minWidth: 280,
        visible: false,
    }),
    buildTextColumn({
        key: "organisationalPhoneNumber",
        title: "certificationBody.list.organisationalPhoneNumber_column",
        minWidth: 284,
        visible: false,
    }),
    buildTextColumn({
        key: "postCode",
        title: "certificationBody.list.postcode_column",
        minWidth: 128,
        visible: false,
    }),
    buildDateColumn({
        key: "startDate",
        title: "certificationBody.list.startDate_column",
        minWidth: 130,
        visible: false,
    }),
    buildTextColumn({ key: "town", title: "certificationBody.list.town_column", minWidth: 123, visible: false }),
    buildTextColumn({ key: "website", title: "certificationBody.list.website_column", minWidth: 123, visible: false }),
    buildNumberColumn({
        key: "latitude",
        title: "certificationBody.list.latitude_column",
        allowDecimals: true,
        allowNegative: true,
        minWidth: 119,
        visible: false,
    }),
    buildNumberColumn({
        key: "longitude",
        title: "certificationBody.list.longitude_column",
        allowDecimals: true,
        allowNegative: true,
        minWidth: 134,
        visible: false,
    }),
];

function useCertificationBodyListView() {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedEditUrl } = useResolvedRoute();

    const {
        queryBuilder: { forceReload, columnOptions, query, queryCommands },
    } = useListView<CertificationBodySummary>({
        navigationContextLabel: "certificationBody.breadcrumb",
        baseQuery: "/certificate-service/certification-bodies",
        defaultSortOption: defaultCertificationBodySortOption,
    });

    const showDetailsAction = useShowDetailsAction(
        (item: CertificationBodySummary) => getResolvedEditUrl("certificationBody", item.id),
        query.parameter
    );

    const deleteAction = useDeleteAction(
        (item: CertificationBodySummary) => `/certificate-service/certification-bodies/${item.id}`,
        () => forceReload(),
        "deleteCertificationLevelOrganisation"
    );

    const actions = useGridRowContextMenuActions(showDetailsAction, deleteAction);

    return useMemo<EntityListViewGridProps<CertificationBodySummary>>(() => {
        return {
            query,
            columns: columns,
            uniqueDataKey: "id" as keyof CertificationBodySummary,
            onItemDoubleClick: showDetailsAction,
            createUrl: hasAccessRight("createCertificationLevelOrganisation")
                ? getCreateUrl("certificationBody")
                : undefined,
            tableName: "certificationBody",
            columnOptions,
            itemActions: actions,
            queryCommands,
            bulkDeleteAccessRight: () => hasAccessRight("deleteCertificationLevelOrganisation"),
        };
    }, [query, showDetailsAction, hasAccessRight, columnOptions, actions, queryCommands]);
}

export default useCertificationBodyListView;
