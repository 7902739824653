import { useQuery } from "../../../../../../hooks/useAxios";
import useResolvedRoute from "../../../../../../components/routing/useResolvedRoute";
import { useMemo } from "react";
import { Nullable } from "../../../../../../utils/typeUtils";
import { ScProcessCategoryDetails } from "../scProcessCategoryTypes";
import { ScProductReference } from "../../../scProductTypes";
import useDetailsCreate from "../../../../../../hooks/details/useDetailsCreate";
import { yupResolver } from "@hookform/resolvers/yup";
import { ScProcessCategoryValidationSchema } from "./scProcessCategoryValidationSchema";
import { ScProcessCategoryDetailViewProps } from "./ScProcessCategoryDetailView";

export default function useScProcessCategoryCreate(): ScProcessCategoryDetailViewProps {
    const { getResolvedId } = useResolvedRoute();
    const scopeCertificateId = getResolvedId("scopeCertificate");
    const scProductId = getResolvedId("scProduct");

    const { data: scProduct, isLoading: isParentLoading } = useQuery<ScProductReference>({
        url: `/certificate-service/references/sc-products/${scProductId}`,
    });

    const initialData = useMemo<Nullable<ScProcessCategoryDetails>>(
        () => ({
            id: null,
            scopeCertificateSupplyChainOperatorBusinessName: scProduct?.supplyChainOperatorBusinessName ?? null,
            scProductScProductNumber: scProduct?.scProductNumber ?? null,
            scProductProductCategoryName: scProduct?.productCategoryName ?? null,
            scProductProductCategoryPrint: scProduct?.productCategoryPrint ?? null,
            scProductProductDetailName: scProduct?.productDetailName ?? null,
            scProductProductDetailPrint: scProduct?.productDetailPrint ?? null,
            scProductLabelGradeLabelGrade: scProduct?.labelGradeLabelGrade ?? null,
            processCategory: null,
            processCategoryPrint: null,
            scopeCertificateStatus: null,
            processCategoryName: null,
        }),
        [scProduct]
    );

    const { detailViewProps, form, title } = useDetailsCreate<ScProcessCategoryDetails>({
        title: "scProcessCategory.detail.newRecord_title",
        frontendRoute: "scProcessCategory",
        initialData,
        resolver: yupResolver(ScProcessCategoryValidationSchema),
        saveUrl: `/certificate-service/scope-certificates/${scopeCertificateId}/sc-products/${scProductId}/sc-process-categories`,
    });

    return {
        title,
        detailViewProps: {
            ...detailViewProps,
            isLoading: detailViewProps.isLoading || isParentLoading,
        },
        form,
        readOnly: false,
        createRoute: "scProcessCategory",
        subListVisible: false,
    };
}
