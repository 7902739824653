import { Control } from "react-hook-form";
import ControlledFileUploadComponent, { ControlledFileUploadComponentProps } from "./ControlledFileUploadComponent";
import { env } from "../../../env";

function ControlledImageUploadComponent({ imageUrl: fileUrl, ...rest }: ControlledImageUploadComponentProps) {
    return (
        <ControlledFileUploadComponent
            acceptedTypes={env.REACT_APP_VALID_UPLOAD_IMAGE_TYPES}
            fileUrl={fileUrl}
            {...rest}
        />
    );
}

export default ControlledImageUploadComponent;

interface ControlledImageUploadComponentProps
    extends Omit<ControlledFileUploadComponentProps, "acceptedTypes" | "fileUrl"> {
    imageUrl: string;
    control: Control<any>;
}
