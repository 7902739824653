import useDetailsCreate from "../../hooks/details/useDetailsCreate";
import { yupResolver } from "@hookform/resolvers/yup";
import { Nullable } from "../../utils/typeUtils";
import { PreGtbTransactionCertificateDetails } from "../preGtbTransactionCertificateTypes";
import { PreGtbTransactionCertificateDetailViewProps } from "./PreGtbTransactionCertificateDetailView";
import { preGtbTransactionCertificateValidationSchema } from "./preGtbTransactionCertificateValidationSchema";
import useAuth from "../../auth/useAuth";
import useCheckForEqualCanonicalTcNumber from "./useCheckForEqualCanonicalTcNumber";
import useConformanceCheck from "../../conformance/useConformanceCheck";
import { useMemo } from "react";
import useResolvedRoute from "../../components/routing/useResolvedRoute";

export default function usePreGtbTransactionCertificateCreate(): PreGtbTransactionCertificateDetailViewProps {
    const {
        currentUser: {
            authorisationRole: { type: currentUserOrganisationType },
            organisation,
        },
    } = useAuth();
    const { getResolvedId } = useResolvedRoute();

    const buyer = useMemo(
        () =>
            getResolvedId("supplyChainOperator") ??
            (currentUserOrganisationType === "SUPPLY_CHAIN_OPERATOR" ? organisation : null),
        [currentUserOrganisationType, getResolvedId, organisation]
    );

    const checkForEqualCanonicalTcNumber = useCheckForEqualCanonicalTcNumber();
    const handleConformanceCheck = useConformanceCheck<PreGtbTransactionCertificateDetails>();

    const initialData: Nullable<PreGtbTransactionCertificateDetails> = useMemo(
        () => ({
            sellersLicenceNumber: null,
            dateOfIssue: null,
            id: null,
            certificationBody: null,
            status: "PRELIMINARY",
            buyer,
            tcNumber: null,
            standard: null,
        }),
        [buyer]
    );

    const readOnly: PreGtbTransactionCertificateDetailViewProps["readOnly"] = useMemo(
        () => ({
            id: true,
            status: true,
            buyer: !!buyer,
            certificationBody: false,
            tcNumber: false,
            dateOfIssue: false,
            sellersLicenceNumber: false,
            standard: false,
        }),
        [buyer]
    );

    const { detailViewProps, form, title } = useDetailsCreate<PreGtbTransactionCertificateDetails>({
        title: "preGtbTransactionCertificate.detail.newRecord_title",
        frontendRoute: "preGtbTransactionCertificate",
        initialData,
        afterCreation: checkForEqualCanonicalTcNumber,
        resolver: yupResolver(preGtbTransactionCertificateValidationSchema),
        saveUrl: "/certificate-service/pre-gtb-tcs",
        resolveErrors: (error, item, retrySaveAction) => handleConformanceCheck(error, item, retrySaveAction),
    });

    return {
        title,
        detailViewProps,
        form,
        readOnly,
        organisationType: currentUserOrganisationType,
        createRoute: "preGtbTransactionCertificate",
        preGtbTcId: undefined,
        subListVisible: false,
        restrictBuyersToSelectedStandard: !buyer && currentUserOrganisationType === "CERTIFICATION_BODY",
    };
}
