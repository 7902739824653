import { DatePicker } from "@mui/x-date-pickers";
import { joinClassNames } from "../../../utils/StringUtils";
import Icon from "../../Icon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import "./datepicker.css";
import GtbTextField from "../GtbTextField";
import { I18nKey } from "../../../i18n/useGtbTranslation";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import useDatePickerLocalization from "./useDatePickerLocalization";
import { asUTC } from "../../../utils/dateUtils";

export const dateFormat = "yyyy-MM-dd";

export interface GtbDatePickerProps {
    value: Date | null;
    onChange: (value: Date | null, keyboardInputValue?: string | undefined) => void;
    label?: I18nKey;
    className?: string;
    error?: boolean;
    helperText?: string;
    readOnly?: boolean;
}

const GtbDatePicker = ({ readOnly, className, error, helperText, label, value, ...rest }: GtbDatePickerProps) => {
    const { locale, localeText } = useDatePickerLocalization();

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale} localeText={localeText}>
            <DatePicker
                readOnly={readOnly}
                inputFormat={dateFormat}
                mask={"____-__-__"}
                className={joinClassNames("date-picker", className)}
                dayOfWeekFormatter={(day) => day}
                renderInput={(params) => (
                    <GtbTextField
                        {...params}
                        readOnly={readOnly}
                        label={label}
                        aria-label={params["aria-label"] as I18nKey}
                        rows={1}
                        minRows={1}
                        maxRows={1}
                        error={error}
                        helperText={helperText}
                    />
                )}
                components={{
                    OpenPickerIcon: CalenderIcon,
                    LeftArrowIcon: PreviousIcon,
                    RightArrowIcon: NextIcon,
                }}
                value={asUTC(value)}
                {...rest}
            />
        </LocalizationProvider>
    );
};

const CalenderIcon = () => <Icon name={"calendar"} size={22} />;

const PreviousIcon = () => <Icon name={"chevron-left"} size={19} />;

const NextIcon = () => <Icon name={"chevron-right"} size={19} />;

export default GtbDatePicker;
