import useDetailsCreate from "../../hooks/details/useDetailsCreate";
import { yupResolver } from "@hookform/resolvers/yup";
import { UserCreateDetails, UserDetails } from "../userTypes";
import { userValidationSchema } from "./userValidationSchema";
import { Nullable } from "../../utils/typeUtils";
import { UserDetailReadOnlyProps, UserDetailVisibilityProps } from "./UserDetailView";
import { pageUrl } from "../../utils/windowFunctions";
import { routeEnum } from "../../components/routing/useResolvedRoute";
import useAuth from "../../auth/useAuth";

const initialData: Nullable<UserCreateDetails> = {
    authorisationRole: null,
    blocked: false,
    standardBody: null,
    emailAddress: null,
    endDate: null,
    givenName: null,
    id: null,
    lastFailedLogin: null,
    lastSuccessfulLogin: null,
    organisation: null,
    startDate: new Date(),
    surname: null,
    systemId: null,
    testDataStamp: null,
    guiTheme: null,
    username: null,
    url: pageUrl() + "/resetPassword",
    authorisationRoleLevel: null,
    totpReady: false,
    isOrganisationMandatory: false,
    acceptedToU: false,
    acceptedGoogleToU: false,
};

const readOnlyProps: UserDetailReadOnlyProps = {
    isBlockedReadOnly: false,
    isStandardBodyReadOnly: false,
    isEmailAddressReadOnly: false,
    isEndDateReadOnly: false,
    isGivenNameReadOnly: false,
    isOrganisationReadOnly: false,
    isRoleReadOnly: false,
    isStartDateReadOnly: false,
    isSurnameReadOnly: false,
    isTestDataStampReadOnly: false,
    isUsernameReadOnly: false,
};

function useUserCreate() {
    const {
        currentUser: { authorisationRole },
    } = useAuth();
    const { detailViewProps, form, title } = useDetailsCreate<UserDetails>({
        title: "user.detail.newRecord_title",
        frontendRoute: "user",
        initialData: initialData,
        resolver: yupResolver(userValidationSchema),
        saveUrl: "/certificate-service/users",
    });
    const visibilityProps: UserDetailVisibilityProps = {
        isTestDataStampVisible: authorisationRole.level === 1,
        isChangePasswordButtonVisible: false,
        isAcceptedToUVisible: false,
    };

    return {
        detailViewProps,
        form,
        title,
        navigationEnabled: false,
        readOnlyProps: readOnlyProps,
        visibilityProps,
        createRoute: "user" as routeEnum,
    };
}

export default useUserCreate;
