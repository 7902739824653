import { useHelpContext } from "../help/context-sensitivity/useHelpContext";
import EntityListView from "../components/entity/EntityListView";
import useBuyingRelationListView from "./useBuyingRelationListView";

export default function BuyingRelationListView() {
    const buyingRelationGridOptions = useBuyingRelationListView();
    useHelpContext("buying-relation-list");

    return <EntityListView heading="buyingRelation.list.header" gridOptions={buyingRelationGridOptions} />;
}
