import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { RecordNavigationType } from "../recordNavigationType";
import { backendUrlType, useQuery } from "../../../hooks/useAxios";
import { buildNewQuery, buildQuery, QueryParameters, SortOption } from "../../grid/component/useQueryBuilder";
import { useLocation } from "react-router-dom";
import { RecordNavigationLink, RecordNavigationProps } from "./RecordNavigation";

export interface UseRecordNavigation<ItemType> {
    baseUrl: backendUrlType;
    basicFrontendUrl: string;
    defaultSortOption: SortOption<ItemType>;
    loadOnChange: any;
    defaultQueryParams?: {};
}

export default function useRecordNavigation<ItemType>({
    baseUrl,
    defaultSortOption,
    basicFrontendUrl,
    loadOnChange,
    defaultQueryParams,
}: UseRecordNavigation<ItemType>): RecordNavigationProps | undefined {
    const { state } = useLocation();
    const query = useMemo(
        () =>
            buildNewQuery(
                baseUrl + "/recordNavigation",
                (state as { recordNavigationQueryParams: QueryParameters<ItemType> })?.recordNavigationQueryParams ??
                    ({ sort: defaultSortOption, ...defaultQueryParams } as QueryParameters<ItemType>)
            ),
        [defaultQueryParams, baseUrl, defaultSortOption, state]
    );

    const [currentRecordNavigation, setCurrentRecordNavigation] = useState<RecordNavigationProps>();
    const {
        data: recordNavigation,
        runQuery,
        isLoading,
    } = useQuery<RecordNavigationType>({
        url: buildQuery(query),
        enabled: false,
    });

    const getLinkProps = useCallback(
        (keyUrl: string | null): RecordNavigationLink => {
            return {
                disabled: !keyUrl,
                pathname: `${basicFrontendUrl}/${keyUrl}`,
                state: { recordNavigationQueryParams: query.parameter },
            };
        },
        [basicFrontendUrl, query.parameter]
    );

    const buildRecordNavigation = useCallback(
        (recordNavigationProps: RecordNavigationType, recordSequenceChanged: boolean): RecordNavigationProps => {
            return {
                first: getLinkProps(recordNavigationProps.first),
                previous: getLinkProps(recordNavigationProps.previous),
                current: getLinkProps(recordNavigationProps.current),
                next: getLinkProps(recordNavigationProps.next),
                last: getLinkProps(recordNavigationProps.last),
                recordSequenceChanged,
            };
        },
        [getLinkProps]
    );

    const reloadRecordNavigation = useCallback(() => {
        const oldState = recordNavigation;
        runQuery().then((newState) => {
            if (
                oldState &&
                oldState.current === newState.current &&
                JSON.stringify(oldState) !== JSON.stringify(newState)
            ) {
                setCurrentRecordNavigation(buildRecordNavigation(newState, true));
            } else {
                setCurrentRecordNavigation(buildRecordNavigation(newState, false));
            }
        });
    }, [buildRecordNavigation, recordNavigation, runQuery]);

    // So that the reload function is not triggered multiple times
    const reloadRecordNavigationRef = useRef<Function>();

    useEffect(() => {
        reloadRecordNavigationRef.current = reloadRecordNavigation;
    }, [reloadRecordNavigation]);

    useEffect(() => {
        if (loadOnChange) {
            //only reload if the trigger changed and is truthy
            reloadRecordNavigationRef.current?.();
        }
    }, [loadOnChange]);

    return !isLoading ? currentRecordNavigation : undefined;
}
