import { I18nKey } from "../i18n/useGtbTranslation";

export const required = "validation.required_message";

export const maxLength: (value: number) => [number, I18nKey] = (value: number) => {
    return [value, { key: "validation.maxLength_message", options: { value: value } }];
};

export const minLength: (value: number) => [number, I18nKey] = (value: number) => {
    return [value, { key: "validation.minLength_message", options: { value: value } }];
};

export const minValue: (value: number) => [number, I18nKey] = (value) => {
    return [value, { key: "validation.minValue_message", options: { value: value } }];
};

export const maxValue: (value: number) => [number, I18nKey] = (value) => {
    return [value, { key: "validation.maxValue_message", options: { value: value } }];
};
