import { useCallback } from "react";
import { I18nKey } from "../../i18n/useGtbTranslation";
import { useMutation } from "../../hooks/useAxios";
import useWarningDialog from "../../components/dialog/UseWarningDialog";
import { DialogOptions, DialogYesNoOptions } from "../../components/dialog/DialogOptions";
import useConfirmationDialog from "../../components/dialog/UseConfirmationDialog";
import useLoadingDialog from "../../components/dialog/useLoadingDialog";

export default function useBulkResetTermsOfUseAcceptanceDialog() {
    const { showDialog, closeDialog } = useWarningDialog();
    const showSecondLevel = useShowSecondLevelOfBulkResetTermsOfUseAcceptanceDialog();

    return useCallback(() => {
        showDialog({
            title: "components.bulkResetTermsOfUseAcceptanceDialog.dialog_title",
            message: "components.bulkResetTermsOfUseAcceptanceDialog.first_level_message",
            footer: <DialogYesNoOptions onYes={showSecondLevel} onNo={closeDialog} />,
        });
    }, [showDialog, showSecondLevel, closeDialog]);
}

function useShowSecondLevelOfBulkResetTermsOfUseAcceptanceDialog() {
    const { showDialog, closeDialog } = useWarningDialog();
    const showResetTermsOfUseResult = useShowResetTermsOfUseResultDialog();
    const { showDialog: showLoadingDialog } = useLoadingDialog();

    const { runQuery } = useMutation({
        method: "put",
        url: "/certificate-service/users/bulk-reset-terms-of-use-acceptance",
        enabled: false,
    });

    return useCallback(
        () =>
            showDialog({
                title: "components.bulkResetTermsOfUseAcceptanceDialog.dialog_title",
                message: "components.bulkResetTermsOfUseAcceptanceDialog.second_level_message",
                footer: (
                    <DialogOptions
                        options={[
                            {
                                label: "components.bulkResetTermsOfUseAcceptanceDialog.second_level_continue_button",
                                onClick: () => {
                                    showLoadingDialog("components.bulkResetTermsOfUseAcceptanceDialog.dialog_title");
                                    runQuery()
                                        .then(() =>
                                            showResetTermsOfUseResult(
                                                "components.bulkResetTermsOfUseAcceptanceDialog.success_message"
                                            )
                                        )
                                        .catch(() =>
                                            showResetTermsOfUseResult(
                                                "components.bulkResetTermsOfUseAcceptanceDialog.error_message"
                                            )
                                        );
                                },
                            },
                            {
                                label: "components.bulkResetTermsOfUseAcceptanceDialog.second_level_cancel_button",
                                onClick: closeDialog,
                            },
                        ]}
                    />
                ),
            }),
        [closeDialog, runQuery, showDialog, showLoadingDialog, showResetTermsOfUseResult]
    );
}

function useShowResetTermsOfUseResultDialog() {
    const showDialog = useConfirmationDialog();

    return useCallback(
        (message: I18nKey) =>
            showDialog({
                title: "components.bulkResetTermsOfUseAcceptanceDialog.dialog_title",
                message,
            }),
        [showDialog]
    );
}
