import { useMemo } from "react";
import { OrganicMaterialOriginDetails, OrganicMaterialOriginSummary } from "./organicMaterialOriginTypes";
import { SortOption, useQueryBuilder } from "../../../components/grid/component/useQueryBuilder";
import useIsAuthorised from "../../../auth/useIsAuthorised";
import useResolvedRoute from "../../../components/routing/useResolvedRoute";
import { GridColumn } from "../../../components/grid/component/Grid";
import { buildNumberColumn, buildTextColumn } from "../../../components/grid/ColumnProvider";
import { useDeleteAction, useShowDetailsAction } from "../../../utils/defaultActions";
import useGridRowContextMenuActions from "../../../components/grid/component/cell/useGridRowContextMenuActions";
import { SubListProps } from "../../../components/grid/SubList";

const columns: GridColumn<OrganicMaterialOriginSummary>[] = [
    buildTextColumn({
        key: "organicMaterialProductionDisplayName",
        title: "organicMaterialOrigin.list.organicMaterialProduction_column",
        minWidth: 200,
    }),
    buildNumberColumn({
        key: "transferredQuantity",
        title: "organicMaterialOrigin.list.transferredQuantity_column",
        fixedDecimal: true,
        minWidth: 200,
    }),
];

export const defaultOrganicMaterialOriginSortOption: SortOption<
    OrganicMaterialOriginSummary | OrganicMaterialOriginDetails
> = {
    field: "organicMaterialProductionDisplayName",
    direction: "ASC",
};

export default function useOrganicMaterialOriginSubList(
    isFrozen: boolean,
    initialAvailableQuantityIsSet: boolean,
    afterDeletion: Function
) {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedId, getResolvedEditUrl, getResolvedCreateUrl } = useResolvedRoute();
    const organicMaterialPurchaseId = getResolvedId("organicMaterialPurchase");

    const { query, columnOptions, forceReload } = useQueryBuilder<OrganicMaterialOriginSummary>(
        `/certificate-service/organic-material-purchases/${organicMaterialPurchaseId}/organic-material-origins`,
        {
            sort: defaultOrganicMaterialOriginSortOption,
            filterActive: false,
        }
    );

    const showDetailsAction = useShowDetailsAction(
        (item: OrganicMaterialOriginSummary) => getResolvedEditUrl("organicMaterialOrigin", item.id),
        query.parameter
    );

    const deleteAction = useDeleteAction(
        (item: OrganicMaterialOriginSummary) =>
            `/certificate-service/organic-material-purchases/${organicMaterialPurchaseId}/organic-material-origins/${item.id}`,
        () => {
            afterDeletion();
            forceReload();
        },
        "deleteRawMaterialProduction"
    );

    const contextMenuActions = useGridRowContextMenuActions(
        showDetailsAction,
        !isFrozen && !initialAvailableQuantityIsSet ? deleteAction : undefined
    );

    return useMemo<SubListProps<OrganicMaterialOriginSummary>>(
        () => ({
            query,
            columns: columns,
            uniqueDataKey: "id" as keyof OrganicMaterialOriginSummary,
            onItemDoubleClick: showDetailsAction,
            header: "organicMaterialOrigin.list.header",
            itemActions: contextMenuActions,
            columnOptions,
            createUrl:
                !isFrozen && !initialAvailableQuantityIsSet && hasAccessRight("createRawMaterialProduction")
                    ? getResolvedCreateUrl("organicMaterialOrigin")
                    : undefined,
            tableName: "organicMaterialOrigin",
        }),
        [
            columnOptions,
            contextMenuActions,
            getResolvedCreateUrl,
            hasAccessRight,
            initialAvailableQuantityIsSet,
            isFrozen,
            query,
            showDetailsAction,
        ]
    );
}
