import React, { ReactNode } from "react";

const GridRow = React.memo(
    ({ children, onDoubleClick, ...rest }: { children: ReactNode; onDoubleClick?: () => void }) => {
        return (
            <tr {...rest} onDoubleClick={onDoubleClick}>
                {children}
            </tr>
        );
    }
);

export default GridRow;
