import StringFilter, { FilterValidator, StringFilterProps } from "./StringFilter";
import { useCallback } from "react";
import { splitFilterByOr } from "./filterUtils";

interface NumberFilterProps<ItemType> extends Omit<StringFilterProps<ItemType>, "filterValidator"> {
    allowNegative?: boolean;
    allowDecimals?: boolean;
}

export default function NumberFilter<ItemType>({
    allowNegative = false,
    allowDecimals = false,
    ...rest
}: NumberFilterProps<ItemType>) {
    const validator = useCallback<FilterValidator>(
        (value) => {
            // has to match REGEX in IntegerFilterValue.java
            // has to match REGEX in DecimalFilterValue.java
            const regex = new RegExp(
                `^\\s*((NULL!)|(([<>]?=?|(<>)?)\\s*${allowNegative ? "-?\\s*" : ""}${
                    allowDecimals ? "(\\d+\\.?\\d*|\\d*\\.?\\d+)" : "\\d+"
                }))\\s*$`,
                "g"
            );
            return splitFilterByOr(value).every((part) => regex.test(part))
                ? undefined
                : "error.grid.noTextFilterInNumericColumn_message";
        },

        [allowDecimals, allowNegative]
    );

    return <StringFilter filterValidator={validator} {...rest} />;
}
