import { useEffect, useRef, useState } from "react";

/**
 * Uses a modified version of useInterval as proposed at
 * https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 *
 * It can be updated while running by using a specific timestamp instead of an offset.
 *
 * @param callback the callback which shall be called when nextExecution is reached
 * @param nextExecution timestamp of the next time the callback shall be called
 */
export function useUpdatableTimeout(callback: () => void, nextExecution: number) {
    useTimer("timeout", callback, nextExecution);
}

/**
 * Uses a modified version of useInterval as proposed at
 * https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 *
 * @param callback the callback which shall be called on every interval
 * @param delay time between intervals
 */
export function useInterval(callback: () => void, delay: number) {
    useTimer("interval", callback, delay);
}

/**
 * A stopwatch representing a boolean flag which is true for a given amount of time.
 *
 * Any API user can access the stopwatches state via thet 'isRunning'-property. Every time the stopwatch is started,
 * the 'isRunning' property is true as long as the given delay is not yet reached.
 *
 * @param delay the time the internal flag is set to true in ms every time 'start()' is called
 */
export function useStopwatch(delay: number) {
    const [startStopwatch, setStartStopwatch] = useState(false);

    useEffect(() => {
        if (startStopwatch) {
            const timer = setTimeout(() => setStartStopwatch(false), delay);
            return () => clearTimeout(timer);
        }
    }, [delay, startStopwatch]);

    return {
        isRunning: startStopwatch,
        start: () => setStartStopwatch(true),
    };
}

function useTimer(type: TimerType, callback: () => void, delay: number) {
    const savedCallback = useRef<() => void>();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            if (savedCallback.current) {
                savedCallback.current();
            }
        }

        if (delay !== null) {
            if (type === "timeout") {
                ///////////////////////////////////////////////////////////////////
                // IMPORTANT: The usage of Date.now() in timeout calculation is
                //            necessary in order to have changing execution
                //            times. Therefore, a never changing offset cannot
                //            be used because this useEffect() would never be
                //            called again.
                ///////////////////////////////////////////////////////////////////
                const id = setTimeout(tick, delay - Date.now());
                return () => clearTimeout(id);
            } else {
                let id = setInterval(tick, delay);
                return () => clearInterval(id);
            }
        }
    }, [delay, type]);
}

type TimerType = "timeout" | "interval";
