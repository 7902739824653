import { useDetachAction } from "../utils/defaultActions";
import { useQueryBuilder } from "../components/grid/component/useQueryBuilder";
import { OrganicMaterialProductionScSummary } from "./organicMaterialProductionScTypes";
import { defaultOrganicMaterialProductionScSortOption } from "./useOrganicMaterialProductionScListView";
import useOrganicMaterialProductionScSubList from "./useOrganicMaterialProductionScSubList";
import useResolvedRoute from "../components/routing/useResolvedRoute";

export default function useOrganicMaterialProductionOrganicMaterialProductionScSubList() {
    const { getResolvedId } = useResolvedRoute();
    const organicMaterialProductionId = getResolvedId("organicMaterialProduction");

    const { query, columnOptions, forceReload } = useQueryBuilder<OrganicMaterialProductionScSummary>(
        `/certificate-service/organic-material-production-scs`,
        {
            sort: defaultOrganicMaterialProductionScSortOption,
            filterActive: false,
            organicMaterialProductionId: organicMaterialProductionId,
        }
    );

    const detachAction = useDetachAction(
        (item: OrganicMaterialProductionScSummary) =>
            `certificate-service/organic-material-productions/${organicMaterialProductionId}/omcrTo/${item.id}`,
        forceReload,
        "updateRawMaterialProduction"
    );

    return useOrganicMaterialProductionScSubList({
        query,
        columnOptions,
        deleteDetachContextMenuAction: detachAction,
        tableName: "organicMaterialProductionOrganicMaterialProductionSc",
    });
}
