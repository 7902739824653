import { GtbTooltipProps } from "./useGtbTooltip";

function GtbTooltip(tooltipProps: GtbTooltipProps) {
    //TODO With ARIA 1.3 this components can be removed. Its only needed as a replacement for aria-description
    return (
        <span hidden id={tooltipProps["aria-describedby"]}>
            {tooltipProps.title}
        </span>
    );
}

export default GtbTooltip;
