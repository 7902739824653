import { useEffect, useMemo } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getBaseUrl } from "../components/routing/useResolvedRoute";
import useAuth from "../auth/useAuth";

export interface LogoutReasonType {
    logoutReason?: "manualLogout" | "timeOut" | "declinedToU";
}

function LogoutView() {
    const { logout } = useAuth();
    const state = useLocation().state as LogoutReasonType;

    useEffect(() => {
        logout();
    }, [logout]);

    const logoutReason = useMemo<LogoutReasonType>(() => {
        return state?.logoutReason ? state : { logoutReason: "manualLogout" };
    }, [state]);

    return <Navigate to={getBaseUrl("login")} replace state={logoutReason} />;
}

export default LogoutView;
