import useIsAuthorised from "../../../../auth/useIsAuthorised";
import { useCallback, useEffect, useMemo } from "react";
import useDomainEntityDetailsEdit from "../../../../hooks/details/useDomainEntityDetailsEdit";
import useResolvedRoute from "../../../../components/routing/useResolvedRoute";
import { TcProductDetailViewProps } from "./TcProductDetailView";
import { TcProductDetails } from "../tcProductTypes";
import { defaultTcProductSortOption } from "../useTcProductSubList";
import { yupResolver } from "@hookform/resolvers/yup";
import { tcProductValidationSchema } from "./tcProductValidationSchema";
import useTcProductDetail from "./useTcProductDetail";
import { useTcProductMapUrl } from "./mapView/TcProductMapView";
import { transactionCertificateReadOnlyByStatus } from "../../../transactionCertificateTypes";
import { TransactionCertificateStatusType } from "../../../../auth/roleTypes";
import { useFormState } from "react-hook-form";
import useWarningDialog from "../../../../components/dialog/UseWarningDialog";
import { useQuery } from "../../../../hooks/useAxios";

export default function useTcProductEdit(): TcProductDetailViewProps {
    const { showDialog } = useWarningDialog();
    const { hasAccessRight } = useIsAuthorised();

    const { getResolvedId } = useResolvedRoute();
    const transactionCertificateId = getResolvedId("transactionCertificate");
    const tcProductId = getResolvedId("tcProduct");

    const canSave = useCallback(
        (item: TcProductDetails) => {
            if (item) {
                return (
                    hasAccessRight("updateTransactionCertificate", [item.transactionCertificateStatus]) &&
                    !transactionCertificateReadOnlyByStatus(item.transactionCertificateStatus)
                );
            }
            return false;
        },
        [hasAccessRight]
    );

    const { runQuery: queryProduct, isLoading: isReQueryingProduct } = useQuery({
        url: `/certificate-service/transaction-certificates/${transactionCertificateId}/tc-products/${tcProductId}`,
        enabled: false,
    });

    const { detailViewProps, form, isReadOnly, title, recordNavigationProps, initialData } =
        useDomainEntityDetailsEdit<TcProductDetails>({
            baseUrl: `/certificate-service/transaction-certificates/${transactionCertificateId}/tc-products/${tcProductId}`,
            frontendRoute: "tcProduct",
            recordNavigationDefaultSort: defaultTcProductSortOption,
            titleBuilder: (item) => item.tcProductNumber,
            resolver: yupResolver(tcProductValidationSchema),
            canSave,
            historyColumns: [
                { identifier: "tcProductNumber", title: "tcProduct.history.tcProductNumber_column" },
                {
                    identifier: "relatedScProduct",
                    title: "tcProduct.history.relatedScProduct_column",
                },
                { identifier: "usdaNopCompliant", title: "tcProduct.history.usdaNop_column" },
                { identifier: "numberOfUnits", title: "tcProduct.history.numberOfUnits_column" },
                { identifier: "unitName", title: "tcProduct.history.unitName_column" },
                { identifier: "netShippingWeight", title: "tcProduct.history.netShippingWeight_column" },
                { identifier: "supplementaryWeight", title: "tcProduct.history.supplementaryWeight_column" },
                { identifier: "byProductWeight", title: "tcProduct.history.byProductWeight_column" },
                { identifier: "orderNumber", title: "tcProduct.history.orderNumber_column" },
                { identifier: "articleNumber", title: "tcProduct.history.articleNumber_column" },
                { identifier: "productionDate", title: "tcProduct.history.productionDate_column" },
                { identifier: "tcProcessors", title: "tcProduct.history.processors_column" },
                { identifier: "lastProcessor", title: "tcProduct.history.lastProcessor_column" },
                { identifier: "additionalInformation", title: "tcProduct.history.additionalInformation_column" },
            ],
        });

    const transactionCertificateStatus = useMemo<TransactionCertificateStatusType | undefined>(
        () => initialData?.transactionCertificateStatus,
        [initialData?.transactionCertificateStatus]
    );

    const isReadOnlyByTransactionCertificateStatus = useMemo<boolean>(
        () => transactionCertificateReadOnlyByStatus(transactionCertificateStatus),
        [transactionCertificateStatus]
    );

    const { selectedScProduct, byProductWeightVisible } = useTcProductDetail(form);
    const buildGoToMapUrl = useTcProductMapUrl();

    const {
        dirtyFields: { relatedScProduct: isRelatedScProductDirty = false },
    } = useFormState({ control: form.form.control, name: "relatedScProduct" });

    useEffect(() => {
        if (isRelatedScProductDirty) {
            showDialog({
                title: "tcProduct.detail.relatedScProductChangedDialog_title",
                message: "tcProduct.detail.relatedScProductChangedDialog_message",
            });
        }
    }, [isRelatedScProductDirty, showDialog]);

    const refetchUsdaNopCompliance = useCallback(() => {
        queryProduct().then((product: TcProductDetails) =>
            form.form.resetField("usdaNopCompliant", {
                defaultValue: product.usdaNopCompliant,
            })
        );
    }, [queryProduct, form.form]);

    return {
        scopeCertificateId: initialData?.scopeCertificateId,
        selectedScProduct,
        detailViewProps: {
            ...detailViewProps,
            isLoading: detailViewProps.isLoading || isReQueryingProduct,
            goToMapUrl: initialData ? buildGoToMapUrl(initialData.id) : undefined,
        },
        form,
        readOnlyProps: {
            readOnly: isReadOnly || isReadOnlyByTransactionCertificateStatus,
        },
        materialCompositionTabVisible: !isRelatedScProductDirty,
        tcStatus: transactionCertificateStatus,
        title,
        recordNavigationProps,
        createRoute:
            transactionCertificateStatus &&
            !isReadOnlyByTransactionCertificateStatus &&
            hasAccessRight("updateTransactionCertificate", [transactionCertificateStatus])
                ? "tcProduct"
                : undefined,
        byProductWeightVisible,
        sublistVisible: true,
        refetchUsdaNopCompliance,
        cbProductReferenceNumber: initialData?.cbProductReferenceNumber,
    };
}
