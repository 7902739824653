import { humanReadableFileSize } from "../../../utils/formatter";
import { FileResource } from "./FileUploadComponent";
import { backendUrlType, useQuery } from "../../../hooks/useAxios";
import useFileDownload from "../../../hooks/useFileDownload";
import { useCallback, useMemo } from "react";
import useGtbTranslation from "../../../i18n/useGtbTranslation";

export default function FileDownloadLink({ file, fileUrl }: FileDownloadLinkProps) {
    const translation = useGtbTranslation();
    const download = useFileDownload();

    const { runQuery } = useQuery({ url: "", enabled: false });

    const downloadFile = useCallback(() => {
        if (!file) {
            return;
        }
        if (file.base64Content) {
            download(file).finally();
        } else {
            runQuery({ url: fileUrl }).then(download);
        }
    }, [download, file, fileUrl, runQuery]);

    const linkProperties = useMemo(
        () =>
            file.id
                ? {
                      href: "/api" + fileUrl,
                      download: file.name,
                  }
                : { href: file.name, download: file.name },
        [file.id, file.name, fileUrl]
    );

    return (
        <a
            {...linkProperties}
            onClick={(e) => {
                e.preventDefault();
                downloadFile();
            }}
            title={translation({
                key: "components.fileUpload.downloadFile_button",
                options: { name: file.name, size: humanReadableFileSize(file.size) },
            })}
        >
            {translation({
                key: "components.fileUpload.downloadFile_button_tooltip",
                options: { name: file.name, size: humanReadableFileSize(file.size) },
            })}
        </a>
    );
}

export interface FileDownloadLinkProps {
    file: FileResource;
    fileUrl: backendUrlType;
}
