import { useMemo } from "react";
import useIsAuthorised from "../auth/useIsAuthorised";
import { useDeleteAction, useShowDetailsAction } from "../utils/defaultActions";
import { useQueryBuilder } from "../components/grid/component/useQueryBuilder";
import useResolvedRoute from "../components/routing/useResolvedRoute";
import { SubListProps } from "../components/grid/SubList";
import {
    accreditationRelationColumns,
    defaultAccreditationRelationSortOption,
} from "./useAccreditationRelationListView";
import { AccreditationRelationSummary } from "./accreditationRelationTypes";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";

export default function useAccreditationRelationSubList() {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedId, getResolvedEditUrl, getResolvedCreateUrl } = useResolvedRoute();

    const { query, columnOptions, forceReload } = useQueryBuilder<AccreditationRelationSummary>(
        "/certificate-service/accreditation-relations",
        {
            sort: defaultAccreditationRelationSortOption,
            filterActive: false,
            certificationBodyId: getResolvedId("certificationBody"),
        }
    );

    const showDetailsAction = useShowDetailsAction(
        (item: AccreditationRelationSummary) => getResolvedEditUrl("certificationBodyAccreditationRelation", item.id),
        query.parameter
    );

    const deleteAction = useDeleteAction(
        (item: AccreditationRelationSummary) => `/certificate-service/accreditation-relations/${item.id}`,
        () => forceReload(),
        "deleteCertificationLevelOrganisation"
    );

    const contextMenuActions = useGridRowContextMenuActions(showDetailsAction, deleteAction);

    return useMemo<SubListProps<AccreditationRelationSummary>>(
        () => ({
            query,
            columns: accreditationRelationColumns,
            uniqueDataKey: "id",
            onItemDoubleClick: showDetailsAction,
            header: "accreditationRelation.list.header",
            itemActions: contextMenuActions,
            columnOptions,
            createUrl: hasAccessRight("createCertificationLevelOrganisation")
                ? getResolvedCreateUrl("certificationBodyAccreditationRelation")
                : undefined,
            tableName: "accreditationRelations",
        }),
        [columnOptions, contextMenuActions, getResolvedCreateUrl, hasAccessRight, query, showDetailsAction]
    );
}
