import useGtbTranslation from "../../i18n/useGtbTranslation";
import useIsAuthorised from "../../auth/useIsAuthorised";
import useResolvedRoute, { routeEnum } from "../../components/routing/useResolvedRoute";
import { useCallback, useMemo, useState } from "react";
import useDomainEntityDetailsEdit from "../../hooks/details/useDomainEntityDetailsEdit";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { OrganicMaterialProducerDetails } from "../organicMaterialProducerTypes";
import {
    OrganicMaterialProducerDetailViewProps,
    OrganicMaterialProducerReadOnlyProps,
} from "./OrganicMaterialProducerDetailView";
import useTypeOfRegistrationId from "../../countryArea/useTypeOfRegistrationId";
import { externalIdComponentTypeEnum } from "../../externalId/externalIdTypes";
import useAuth from "../../auth/useAuth";
import { defaultOrganicMaterialProducerSortOption } from "../useOrganicMaterialProducerListView";
import { buildOrganicMaterialProducerValidationSchema } from "./organicMaterialProducerValidationSchema";
import { useOrganisationDefaultReadOnlyProps } from "../../organisation/details/organisationDetailViewProps";
import useRequiresLegalNameInEnglish from "../../organisation/useRequiresLegalNameInEnglish";

export default function useOrganicMaterialProducerEditView(): OrganicMaterialProducerDetailViewProps<OrganicMaterialProducerDetails> {
    const translation = useGtbTranslation();
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedId } = useResolvedRoute();
    const organicMaterialProducerId = getResolvedId("organicMaterialProducer");
    const {
        currentUser: { authorisationRole },
    } = useAuth();

    const [requiresLegalNameInEnglish, setRequiresLegalNameInEnglish] = useState(false);

    const canSave = useCallback(
        (item: OrganicMaterialProducerDetails) => {
            if (item) {
                return hasAccessRight("updateOperationLevelOrganisation", [item.organisationStatus]);
            }
            return false;
        },
        [hasAccessRight]
    );

    const { detailViewProps, form, initialData, isReadOnly, title, recordNavigationProps } =
        useDomainEntityDetailsEdit<OrganicMaterialProducerDetails>({
            baseUrl: "/certificate-service/organic-material-producers/" + organicMaterialProducerId,
            frontendRoute: "organicMaterialProducer",
            recordNavigationDefaultSort: defaultOrganicMaterialProducerSortOption,
            titleBuilder: (item) => item.businessName,
            resolver: yupResolver(buildOrganicMaterialProducerValidationSchema(requiresLegalNameInEnglish)),
            canSave,
            historyColumns: [
                { identifier: "systemId", title: "organicMaterialProducer.history.systemId_column" },
                { identifier: "legalName", title: "organicMaterialProducer.history.legalName_column" },
                {
                    identifier: "legalNameInEnglish",
                    title: "organicMaterialProducer.history.legalNameInEnglish_column",
                },
                { identifier: "businessName", title: "organicMaterialProducer.history.businessName_column" },
                { identifier: "addressDetails1", title: "organicMaterialProducer.history.addressDetail1_column" },
                { identifier: "addressDetails2", title: "organicMaterialProducer.history.addressDetail2_column" },
                { identifier: "addressDetails3", title: "organicMaterialProducer.history.addressDetail3_column" },
                { identifier: "latitude", title: "organicMaterialProducer.history.latitude_column" },
                { identifier: "longitude", title: "organicMaterialProducer.history.longitude_column" },
                { identifier: "postCode", title: "organicMaterialProducer.history.postcode_column" },
                { identifier: "town", title: "organicMaterialProducer.history.town_column" },
                { identifier: "stateProvince", title: "organicMaterialProducer.history.stateProvince_column" },
                {
                    identifier: "organisationalEmail",
                    title: "organicMaterialProducer.history.organisationalEmailAddress_column",
                },
                { identifier: "website", title: "organicMaterialProducer.history.website_column" },
                {
                    identifier: "organisationalPhoneNumber",
                    title: "organicMaterialProducer.history.organisationalPhoneNumber_column",
                },
                { identifier: "organisationStatus", title: "organicMaterialProducer.history.status_column" },
                { identifier: "startDate", title: "organicMaterialProducer.history.startDate_column" },
                { identifier: "endDate", title: "organicMaterialProducer.history.endDate_column" },
                {
                    identifier: "fixedRegistrationId",
                    title: "organicMaterialProducer.history.fixedRegistrationId_column",
                },
                { identifier: "numberOfFarmers", title: "organicMaterialProducer.history.numberOfFarmers_column" },
            ],
        });

    const { typeOfRegistrationId, isFixedRegistrationIdReadOnly } = useTypeOfRegistrationId(form, true);

    const defaultReadOnlyProps = useOrganisationDefaultReadOnlyProps(isReadOnly);

    const readOnlyProps = useMemo<OrganicMaterialProducerReadOnlyProps>(
        () => ({
            ...defaultReadOnlyProps,
            isFixedRegistrationIdReadOnly: isReadOnly || isFixedRegistrationIdReadOnly,
            isNumberOfFarmersReadOnly: isReadOnly,
            isCbInternalIdReadOnly: isReadOnly,
            isLegalNameInEnglishReadOnly: isReadOnly,
        }),
        [defaultReadOnlyProps, isFixedRegistrationIdReadOnly, isReadOnly]
    );

    const externalIdComponentType = useMemo<externalIdComponentTypeEnum>(() => {
        if (authorisationRole.type === "CERTIFICATION_BODY") {
            return "FIELD";
        } else if (authorisationRole.type === "SUPPLY_CHAIN_OPERATOR") {
            return "NONE";
        } else {
            return "SUBLIST";
        }
    }, [authorisationRole.type]);

    const createRoute = useMemo<routeEnum | undefined>(() => {
        return hasAccessRight("createOperationLevelOrganisation") ? "organicMaterialProducer" : undefined;
    }, [hasAccessRight]);

    useRequiresLegalNameInEnglish(form, setRequiresLegalNameInEnglish);

    return {
        detailViewProps,
        form,
        readOnlyProps,
        title,
        requiresLegalNameInEnglish,
        subtitle: translation({
            key: "organicMaterialProducer.detail.subtitle",
            options: { systemId: initialData?.["systemId"] },
        }),
        recordNavigationProps,
        createRoute,
        typeOfRegistrationId,
        externalIdComponentType,
        isNewRelationTabVisible: false,
        subListVisible: true,
    };
}
