import { TypedDetailViewProps } from "../../components/detailView/DetailView";
import { UseGtbFormReturn } from "../../hooks/formHandling/useGtbForm";
import EventMonitorData from "./EventMonitorData";
import { EventMonitorDetails } from "../eventMonitorTypes";
import EntityDetailView from "../../components/entity/EntityDetailView";
import { useHelpContext } from "../../help/context-sensitivity/useHelpContext";

export default function EventMonitorDetailView({ detailViewProps, form, ...rest }: EventMonitorDetailViewProps) {
    useHelpContext("siem-configuration-details");
    return (
        <EntityDetailView detailViewProps={detailViewProps} form={form} {...rest}>
            <EventMonitorData form={form} />
        </EntityDetailView>
    );
}

export interface EventMonitorDetailViewProps extends TypedDetailViewProps<EventMonitorDetails> {}

export interface EventMonitorDetailViewTabProps {
    form: UseGtbFormReturn<EventMonitorDetails>;
}
