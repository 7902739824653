import * as React from "react";
import { useCallback, useState } from "react";
import DialogHeader from "../../components/dialog/DialogHeader";
import DialogFooter from "../../components/dialog/DialogFooter";
import DialogContent from "../../components/dialog/DialogContent";
import useGtbTranslation from "../../i18n/useGtbTranslation";
import RadiobuttonGroup from "../../components/Input/radiobutton/RadiobuttonGroup";
import GtbButton from "../../components/GtbButton";
import useDialog, { Dialog } from "../../components/dialog/useDialog";
import { useModal } from "../../components/modal/ModalProvider";
import { Duplicate } from "./useResolveDuplicates";
import DialogTextContent from "../../components/dialog/DialogTextContent";
import { UseFormAfterSubmitAction } from "../formHandling/useFormSubmit";
import { FieldValues } from "react-hook-form/dist/types";
import { DialogOptions } from "../../components/dialog/DialogOptions";

export default function useResolveDuplicateDialog<ItemType extends FieldValues>() {
    const { showModal: _showDialog, closeModal: closeDialog } = useModal();
    return useCallback(
        (
            potentialDuplicates: DetectDuplicateDialogProps<ItemType>["potentialDuplicates"],
            toCreate: DetectDuplicateDialogProps<ItemType>["toCreate"],
            retrySaveAction: DetectDuplicateDialogProps<ItemType>["retrySaveAction"],
            acceptDuplicate: DetectDuplicateDialogProps<ItemType>["acceptDuplicate"]
        ) => {
            _showDialog(
                <DetectDuplicateDialog
                    potentialDuplicates={potentialDuplicates}
                    toCreate={toCreate}
                    closeDialog={closeDialog}
                    retrySaveAction={retrySaveAction}
                    acceptDuplicate={acceptDuplicate}
                />
            );
        },
        [_showDialog, closeDialog]
    );
}

export interface DetectDuplicateDialogProps<ItemType extends FieldValues>
    extends UseConfirmNoDuplicateDialog<ItemType> {
    closeDialog: Function;
    acceptDuplicate: (itemId: string) => void;
}

function DetectDuplicateDialog<ItemType extends FieldValues>({
    potentialDuplicates,
    closeDialog,
    acceptDuplicate,
    ...confirmNoDuplicateProps
}: DetectDuplicateDialogProps<ItemType>) {
    const translation = useGtbTranslation();
    const [duplicate, setDuplicate] = useState<string>();
    const showConfirmNoDuplicateDialog = _useConfirmNoDuplicateDialog({
        potentialDuplicates,
        ...confirmNoDuplicateProps,
    });

    return (
        <Dialog
            dialog={{
                header: (
                    <DialogHeader
                        title={"components.detailView.duplicateResolveDialog.dialogTitle"}
                        variant="warning"
                        onClick={() => closeDialog()}
                    />
                ),
                content: (
                    <DialogContent>
                        <RadiobuttonGroup
                            orientation="vertical"
                            onChange={(evt) => setDuplicate(evt.target.value)}
                            label={{
                                key: "components.detailView.duplicateResolveDialog.dialogText",
                                options: { count: potentialDuplicates.length },
                            }}
                            options={potentialDuplicates}
                            itemLabel={(item) =>
                                translation({
                                    key: "components.detailView.duplicateResolveDialog.duplicatePresentation",
                                    options: {
                                        entityName: item.legalName ?? item.businessName,
                                        countryArea: item.countryArea,
                                        stateProvince: item.stateProvince,
                                        systemId: item.systemId,
                                        interpolation: { escapeValue: false },
                                    },
                                })
                            }
                            itemId="id"
                            name="duplicate"
                        />
                    </DialogContent>
                ),
                footer: (
                    <DialogFooter>
                        <GtbButton
                            variant="secondary"
                            disabled={!duplicate}
                            onClick={() => {
                                closeDialog();
                                acceptDuplicate(duplicate!);
                            }}
                        >
                            {translation("components.detailView.duplicateResolveDialog.selectMatch_button")}
                        </GtbButton>
                        <GtbButton variant="secondary" onClick={() => showConfirmNoDuplicateDialog()}>
                            {translation("components.detailView.duplicateResolveDialog.noMatch_button")}
                        </GtbButton>
                        <GtbButton onClick={() => closeDialog()}>
                            {translation("components.dialog.cancel_button")}
                        </GtbButton>
                    </DialogFooter>
                ),
            }}
        />
    );
}

interface UseConfirmNoDuplicateDialog<ItemType extends FieldValues> {
    toCreate: ItemType;
    potentialDuplicates: Duplicate[];
    retrySaveAction: UseFormAfterSubmitAction<ItemType>;
}

function _useConfirmNoDuplicateDialog<ItemType extends FieldValues>({
    toCreate,
    potentialDuplicates,
    retrySaveAction,
}: UseConfirmNoDuplicateDialog<ItemType>) {
    const { showDialog, closeDialog } = useDialog();

    return useCallback(
        () =>
            showDialog({
                header: (
                    <DialogHeader
                        title={"components.detailView.duplicateResolveDialog.dialogTitle"}
                        variant="warning"
                        onClick={() => closeDialog()}
                    />
                ),
                content: (
                    <DialogTextContent message={"components.detailView.duplicateResolveDialog.noMatch_dialogText"} />
                ),
                footer: (
                    <DialogFooter>
                        <DialogOptions
                            options={[
                                {
                                    onClick: () => {
                                        retrySaveAction({
                                            ...toCreate,
                                            ignorePotentialDuplicates: potentialDuplicates.map((d) => d.id),
                                        });
                                        closeDialog();
                                    },
                                    label: "components.dialog.ok_button",
                                },
                                {
                                    onClick: closeDialog,
                                    label: "components.dialog.cancel_button",
                                },
                            ]}
                        />
                    </DialogFooter>
                ),
            }),
        [closeDialog, potentialDuplicates, retrySaveAction, showDialog, toCreate]
    );
}
