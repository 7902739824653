import useGtbTranslation from "../../i18n/useGtbTranslation";
import "./worldMap.css";
import Icon from "../Icon";
import { CSSProperties, ReactNode } from "react";
import SvgWorldMapPaths from "./SvgWorldMapPaths";

export const worldMapWidth = 360;
export const worldMapHeight = 146;

export const productMarkerLegendId = "legend_productMarker" as const;
export const materialMarkerLegendId = "legend_materialMarker" as const;

export default function WorldMap({ children }: { children: ReactNode }) {
    const translation = useGtbTranslation();

    return (
        <div className="worldMap">
            <svg
                aria-hidden={false}
                role="group"
                aria-labelledby="worldMapTitle"
                xmlns="http://www.w3.org/2000/svg"
                viewBox={`0 0 ${worldMapWidth} ${worldMapHeight}`}
                fill="var(--world-map-land)"
                stroke="var(--world-map-sea)"
                strokeWidth="0.2"
                style={{ "--_worldMapWidth": worldMapWidth } as CSSProperties}
            >
                <title id="worldMapTitle">{translation("components.worldMap.map_accessibleLabel")}</title>
                <SvgWorldMapPaths />
                {children}
            </svg>
            <div className="worldMap-legend-anchor">
                <div className="worldMap-legend">
                    <span id={productMarkerLegendId}>
                        <Icon name="circle" size={10} className="productMarker" />
                        {translation("components.worldMap.products_label")}
                    </span>
                    <span id={materialMarkerLegendId}>
                        <Icon name="circle" size={10} className="materialMarker" />
                        {translation("components.worldMap.rawMaterials_label")}
                    </span>
                </div>
            </div>
        </div>
    );
}

const mapLonLeft = -180;
const mapLonRight = 180;
const mapLonDelta = mapLonRight - mapLonLeft;
const mapLatBottom = -56;
const mapLatTop = 90;
const mapLatDelta = mapLatTop - mapLatBottom;

export const latLonToEquirectangular = (latitude: number, longitude: number) => {
    const x = (longitude - mapLonLeft) * (worldMapWidth / mapLonDelta);
    const y = (latitude * -1 + mapLatTop) * (worldMapHeight / mapLatDelta);

    return { x, y };
};
