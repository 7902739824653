import { ReactNode, useEffect } from "react";
import useLanguage from "./LanguageContextProvider";
import { useMutation } from "../hooks/useAxios";
import useAuth from "../auth/useAuth";
import useGtbTranslation from "./useGtbTranslation";
import { error } from "../utils/notification/notification";

export default function LanguageBackendUpdater({ children }: { children: ReactNode }) {
    const translation = useGtbTranslation();
    const currentLanguage = useLanguage();
    const { currentUser } = useAuth();

    const { runQuery: updateUserLanguageInBackend } = useMutation({
        method: "put",
        url: "certificate-service/users/me/language",
        enabled: false,
    });

    useEffect(() => {
        if (currentUser && document.visibilityState === "visible") {
            updateUserLanguageInBackend({
                body: { language: currentLanguage },
            }).catch(() => error(translation("error.misc.generic_error")));
        }
    }, [currentLanguage, currentUser, translation, updateUserLanguageInBackend]);

    return <>{children}</>;
}
