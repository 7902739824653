import { TypedDetailViewProps } from "../../components/detailView/DetailView";
import { UseGtbFormReturn } from "../../hooks/formHandling/useGtbForm";
import "../../components/spinner.css";
import { StandardBodyDetails } from "../standardBodyTypes";
import StandardBodyBasicData from "../../standardBody/details/StandardBodyBasicData";
import { useHelpContext } from "../../help/context-sensitivity/useHelpContext";
import EntityDetailView from "../../components/entity/EntityDetailView";
import { OrganisationReadOnlyProps } from "../../organisation/details/organisationDetailViewProps";
import OrganisationAddressData from "../../organisation/details/OrganisationAddressData";
import Tabs, { TabKey } from "../../components/tabs/Tabs";
import StandardBodyTLSCertificate from "./StandardBodyTLSCertificate";
import { insertIf } from "../../utils/arrayUtils";

export default function StandardBodyDetailView({
    detailViewProps,
    form,
    logoUrl,
    readOnlyProps,
    tlsTabVisible,
    ...rest
}: StandardBodyDetailViewProps) {
    useHelpContext("standard-body-details");
    return (
        <EntityDetailView {...rest} detailViewProps={detailViewProps} form={form}>
            <Tabs<
                StandardBodyDetails,
                TabKey<
                    | "standardBody.detail.basicData_tab"
                    | "standardBody.detail.addressData_tab"
                    | "standardBody.detail.tls_certificate_tab"
                >
            >
                tabs={[
                    {
                        heading: "standardBody.detail.basicData_tab",
                        element: <StandardBodyBasicData form={form} logoUrl={logoUrl} readOnlyProps={readOnlyProps} />,
                    },
                    {
                        heading: "standardBody.detail.addressData_tab",
                        element: (
                            <OrganisationAddressData
                                form={form}
                                readOnlyProps={readOnlyProps}
                                organisationI18nKey={"standardBody"}
                            />
                        ),
                    },
                    ...insertIf(tlsTabVisible, {
                        heading: "standardBody.detail.tls_certificate_tab" as const,
                        element: <StandardBodyTLSCertificate />,
                    }),
                ]}
                errors={form.form.formState.errors}
                errorToTab={{
                    id: "internal",
                    countryArea: "standardBody.detail.addressData_tab",
                    stateProvince: "standardBody.detail.addressData_tab",
                    systemId: "internal",
                    businessName: "standardBody.detail.basicData_tab",
                    website: "standardBody.detail.basicData_tab",
                    organisationalEmail: "standardBody.detail.basicData_tab",
                    organisationalPhoneNumber: "standardBody.detail.basicData_tab",
                    startDate: "standardBody.detail.basicData_tab",
                    endDate: "standardBody.detail.basicData_tab",
                    town: "standardBody.detail.addressData_tab",
                    postCode: "standardBody.detail.addressData_tab",
                    addressDetails1: "standardBody.detail.addressData_tab",
                    addressDetails2: "standardBody.detail.addressData_tab",
                    addressDetails3: "standardBody.detail.addressData_tab",
                    latitude: "standardBody.detail.addressData_tab",
                    longitude: "standardBody.detail.addressData_tab",
                    organisationStatus: "standardBody.detail.basicData_tab",
                    legalName: "standardBody.detail.basicData_tab",
                    logo: "standardBody.detail.basicData_tab",
                }}
            />
        </EntityDetailView>
    );
}

export interface StandardBodyDetailViewProps extends TypedDetailViewProps<StandardBodyDetails> {
    readOnlyProps: StandardBodyReadOnlyProps;
    tlsTabVisible: boolean;
    logoUrl: string;
}

export interface StandardBodyBasicDataProps {
    form: UseGtbFormReturn<StandardBodyDetails>;
    logoUrl: string;
    readOnlyProps: StandardBodyReadOnlyProps;
}

export interface StandardBodyReadOnlyProps extends OrganisationReadOnlyProps {
    isLegalNameReadOnly: boolean;
    isLogoReadOnly: boolean;
}
