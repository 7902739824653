import {
    buildSchema,
    validateArray,
    validateArrayIsNotEmpty,
    validateNumber,
    validateString,
} from "../../../../utils/yupExtension";
import { PreGtbTcMaterialDetails } from "../preGtbTcMaterialsTypes";

export const buildPreGtbTcMaterialValidationSchema = buildSchema<PreGtbTcMaterialDetails>({
    percentage: validateNumber().isRequired().hasMinValue(1).hasMaxValue(100),
    rawMaterial: validateString().isRequired(),
    rawMaterialPrint: validateString().when("rawMaterialPrintRequired", {
        is: true,
        then: validateString().isRequired(),
    }),
    origin: validateArray().when("certified", {
        is: true,
        then: validateArrayIsNotEmpty(),
    }),
});
