export function getNumberAsDecimalPresentation(value: number): string | number {
    if (value.toString().indexOf(".") === -1) {
        return value + ".0";
    }
    if (value.toString().endsWith(".")) {
        return value + "0";
    }
    if (value.toString().split(".")[1].length > 1) {
        return getNumberAsDecimalPresentation(Math.round(value * 10) / 10);
    }
    return value;
}
