import { useEffect, useRef } from "react";
import useGtbTranslation from "../i18n/useGtbTranslation";
import { Wrapper } from "@googlemaps/react-wrapper";
import { env } from "../env";
import LoadingSpinner from "./LoadingSpinner";

interface GoogleMapProps {
    longitude: number;
    latitude: number;
}

export default function GoogleMap({ longitude, latitude }: GoogleMapProps) {
    return (
        <Wrapper
            apiKey={env.REACT_APP_GOOGLE_API_KEY}
            render={() => <LoadingSpinner label="components.locationMap.loadingMap_accessibleLabel" />}
        >
            <MapComponent center={{ lng: longitude, lat: latitude }} />
        </Wrapper>
    );
}

function MapComponent({ center }: { center: google.maps.LatLngLiteral }) {
    const ref = useRef<HTMLDivElement>(null);
    const translation = useGtbTranslation();

    useEffect(() => {
        const map = new google.maps.Map(ref.current!, {
            center,
            zoom: 12,
            disableDefaultUI: true,
            zoomControl: true,
        });
        const marker = new google.maps.Marker({ position: center, map });
        marker.addListener("click", () =>
            window.open(`https://www.google.com/maps/search/?api=1&query=${center.lat},${center.lng}`, "_blank")
        );
    });

    return (
        <div
            role="application"
            aria-label={translation("components.locationMap.map_accessibleLabel")}
            ref={ref}
            id="map"
        />
    );
}
