import useExternalIdEdit from "../../details/useExternalIdEdit";
import useResolvedRoute, { routeEnum } from "../../../components/routing/useResolvedRoute";

export default function useExternalOrganicMaterialProducerIdEdit() {
    const { getResolvedId } = useResolvedRoute();

    const routeEnumKey: routeEnum = getResolvedId("externalOrganicMaterialProducerId")
        ? "externalOrganicMaterialProducerId"
        : "organicMaterialProducerExternalOrganicMaterialProducerId";

    return useExternalIdEdit({
        i18nKey: "externalOrganicMaterialProducerId",
        routeEnumKey,
        recordNavigationParam:
            routeEnumKey === "externalOrganicMaterialProducerId" ? "typeOfExternalIdParentEntity" : "parentId",
        recordNavigationParamValue:
            routeEnumKey === "externalOrganicMaterialProducerId"
                ? "ORGANIC_MATERIAL_PRODUCER"
                : getResolvedId("organicMaterialProducer")!,
    });
}
