import {styled, Tooltip, tooltipClasses, TooltipProps} from "@mui/material";

/**
 * Reference: https://mui.com/material-ui/react-tooltip/#CustomizedTooltips.tsx
 */
export default styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'white',
        color: 'black',
        maxWidth: 500,
        fontSize: theme.typography.pxToRem(14),
        border: '1px solid black',
        borderRadius: 0,
        maxHeight: 400,
        overflow: "auto",
        whiteSpace: "pre-line"
    },
}));
