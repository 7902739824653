import { useCallback, useMemo } from "react";
import { AccessRights, StateSpecificAccessRight } from "./roleTypes";
import useAuth from "./useAuth";
import { keysWithoutValueType, keysWithValueType } from "../utils/typeUtils";

export default function useIsAuthorised() {
    const { currentUser: { authorisationRole } = { authorisationRole: undefined } } = useAuth();

    const isCBAdmin = useMemo(
        () => authorisationRole?.type === "CERTIFICATION_BODY" && authorisationRole?.level === 3,
        [authorisationRole]
    );

    const isSBAdmin = useMemo(
        () => authorisationRole?.type === "STANDARD_BODY" && authorisationRole?.level === 2,
        [authorisationRole]
    );

    const isSBStaffMemeber = useMemo(
        () => authorisationRole?.type === "STANDARD_BODY" && authorisationRole?.level === 3,
        [authorisationRole]
    );

    const isSystemAdmin = useMemo(
        () => authorisationRole?.type === "NONE" && authorisationRole?.level === 1,
        [authorisationRole]
    );

    const hasAccessRight = useCallback(
        <R extends AccessRightKey | StateSpecificAccessRightKey>(
            right: R,
            state?: R extends StateSpecificAccessRightKey ? AccessRights[R]["grantedStates"] : never,
            allStatesRequired?: R extends StateSpecificAccessRightKey ? boolean : never
        ) => {
            if (!authorisationRole) {
                return false;
            }
            const resolvedRight = authorisationRole[right];
            if (!resolvedRight || !resolvedRight.granted) {
                return false;
            }
            if ("grantedStates" in resolvedRight && !resolvedRight.grantedStates.length) {
                return false;
            }
            if (!state) {
                return true;
            }
            if (allStatesRequired) {
                // @ts-ignore
                return state.every((value) => resolvedRight.grantedStates.includes(value));
            }
            // @ts-ignore
            return state.some((value) => resolvedRight.grantedStates.includes(value));
        },
        [authorisationRole]
    );

    const isRoleLevelAtLeast = useCallback(
        (minimumRoleLevel: number) => {
            // Lower level = higher access right
            if (authorisationRole?.level) {
                return authorisationRole.level <= minimumRoleLevel;
            }

            return false;
        },
        [authorisationRole]
    );

    return {
        hasAccessRight,
        isCBAdmin,
        isRoleLevelAtLeast,
        isSBAdmin,
        isSBStaffMemeber,
        isSystemAdmin,
    };
}

export type AccessRightKey = keyof keysWithoutValueType<AccessRights, StateSpecificAccessRight<any>>;

export type StateSpecificAccessRightKey = keyof keysWithValueType<AccessRights, StateSpecificAccessRight<any>>;
