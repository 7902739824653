import { joinClassNames } from "../../utils/StringUtils";
import { ApplicationLogDetailViewTabProps } from "./ApplicationLogEditView";
import GtbTextField from "../../components/Input/GtbTextField";
import GtbTextArea from "../../components/Input/GtbTextArea";
import "./applicationLogData.css";
import GtbReadOnlyDateTimeField from "../../components/Input/GtbReadOnlyDateTimeField";

function ApplicationLogData({ form }: ApplicationLogDetailViewTabProps) {
    return (
        <div className={joinClassNames("applicationLogData", "formGrid")}>
            <GtbReadOnlyDateTimeField
                className="timestamp"
                label="applicationLog.detail.timestamp_input"
                control={form.form.control}
                name="timestamp"
            />
            <GtbTextField
                className="level"
                readOnly
                label="applicationLog.detail.logLevel_input"
                {...form.registerWithErrors("logLevel")}
            />
            <GtbTextArea
                className="message"
                readOnly
                label="applicationLog.detail.message_input"
                {...form.registerWithErrors("message")}
            />
            <GtbTextField
                className="className"
                readOnly
                label="applicationLog.detail.className_input"
                {...form.registerWithErrors("className")}
            />
            <GtbTextField
                className="session"
                readOnly
                label="applicationLog.detail.sessionId_input"
                {...form.registerWithErrors("sessionId")}
            />
            <GtbTextArea
                className="remarks"
                label="applicationLog.detail.remarks_input"
                {...form.registerWithErrors("remarks")}
            />
        </div>
    );
}

export default ApplicationLogData;
