import { FieldValues } from "react-hook-form/dist/types";
import { TypedPicklist } from "./typedPicklistType";
import DataPickList from "../DataPickList";
import { joinClassNames } from "../../../../utils/StringUtils";
import { DynamicUserGroupReference } from "../../../../dynamicUserGroup/dynamicUserGroupTypes";

export default function DynamicUserGroupPickList<FormItemType extends FieldValues>({
    className,
    ...rest
}: TypedPicklist<FormItemType, DynamicUserGroupReference>) {
    return (
        <DataPickList
            className={joinClassNames("dynamicUserGroupPickList", className)}
            itemId="id"
            itemLabel={(option) => option.name}
            queryUrl="/certificate-service/references/dynamic-user-groups"
            {...rest}
        />
    );
}
