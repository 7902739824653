import { useHelpContext } from "../../help/context-sensitivity/useHelpContext";
import { TypedDetailViewProps } from "../../components/detailView/DetailView";
import EntityDetailView from "../../components/entity/EntityDetailView";
import { SupplyingRelationDetails } from "../supplyingRelationTypes";
import { joinClassNames } from "../../utils/StringUtils";
import "./supplyingRelationData.css";
import SupplyChainOperatorPickList from "../../components/Input/pickList/typedPicklist/SupplyChainOperatorPickList";
import OrganicMaterialProducerPickList from "../../components/Input/pickList/typedPicklist/OrganicMaterialProducerPickList";
import ControlledDatePicker from "../../components/Input/datepicker/ControlledDatePicker";
import EnumEntityRadiobuttonGroup from "../../components/Input/radiobutton/EnumEntityRadiobuttonGroup";

export default function SupplyingRelationDetailView({
    detailViewProps,
    form,
    readOnlyProps: { accessLevelReadOnly, scoReadOnly, readOnly, ompReadOnly, endDateReadOnly },
    ...rest
}: SupplyingRelationDetailViewProps) {
    useHelpContext("supplying-relation-details");

    return (
        <EntityDetailView {...rest} detailViewProps={detailViewProps} form={form}>
            <div className={joinClassNames("supplyingRelationData", "formGrid")}>
                <SupplyChainOperatorPickList
                    control={form.form.control}
                    name="supplyChainOperator"
                    label="supplyingRelation.detail.supplyChainOperator_input"
                    className="supplyChainOperator"
                    readOnly={readOnly || scoReadOnly}
                />
                <OrganicMaterialProducerPickList
                    control={form.form.control}
                    name="organicMaterialProducer"
                    label="supplyingRelation.detail.organicMaterialProducer_input"
                    flags={{ allActive: true }}
                    className="organicMaterialProducer"
                    readOnly={readOnly || ompReadOnly}
                />
                <EnumEntityRadiobuttonGroup
                    label="supplyingRelation.detail.accessLevel_input"
                    {...form.form.register("accessLevel")}
                    name="accessLevel"
                    readOnly={readOnly || accessLevelReadOnly}
                    className="accessLevel"
                    referenceUrl="/certificate-service/references/supplying-relation-access-levels"
                />
                <ControlledDatePicker
                    control={form.form.control}
                    name="startDate"
                    label="supplyingRelation.detail.startDate_input"
                    className="startDate"
                    readOnly={readOnly}
                />
                <ControlledDatePicker
                    control={form.form.control}
                    name="endDate"
                    label="supplyingRelation.detail.endDate_input"
                    className="endDate"
                    readOnly={readOnly || endDateReadOnly}
                />
            </div>
        </EntityDetailView>
    );
}

export interface SupplyingRelationDetailViewProps extends TypedDetailViewProps<SupplyingRelationDetails> {
    readOnlyProps: SupplyingRelationReadOnlyProps;
}

export interface SupplyingRelationReadOnlyProps {
    readOnly: boolean;
    scoReadOnly: boolean;
    accessLevelReadOnly: boolean;
    ompReadOnly: boolean;
    endDateReadOnly: boolean;
}
