import React from "react";
import { useHelpContext } from "../../../../help/context-sensitivity/useHelpContext";
import EntityDetailView from "../../../../components/entity/EntityDetailView";
import Tabs, { TabKey } from "../../../../components/tabs/Tabs";
import { ShipmentDetails } from "../shipmentTypes";
import { TypedDetailViewProps } from "../../../../components/detailView/DetailView";
import ShipmentBasicData, { ShipmentBasicDataProps } from "./ShipmentBasicData";

export default function ShipmentDetailView({
    detailViewProps,
    transactionCertificateId,
    form,
    readOnly,
    visibility,
    ...rest
}: ShipmentDetailViewProps) {
    useHelpContext("shipment-details");

    return (
        <EntityDetailView {...rest} detailViewProps={detailViewProps} form={form}>
            <Tabs<ShipmentDetails, ShipmentTabs>
                tabs={[
                    {
                        heading: "shipment.detail.basicData_tab",
                        element: (
                            <ShipmentBasicData
                                form={form}
                                readOnly={readOnly}
                                visibility={visibility}
                                transactionCertificateId={transactionCertificateId}
                            />
                        ),
                    },
                ]}
                errors={form.form.formState.errors}
                errorToTab={{
                    id: "internal",
                    tcStatus: "internal",
                    consignee: "shipment.detail.basicData_tab",
                    countryOfDestination: "shipment.detail.basicData_tab",
                    shipmentDate: "shipment.detail.basicData_tab",
                    grossShippingWeight: "shipment.detail.basicData_tab",
                    shipmentNumber: "shipment.detail.basicData_tab",
                    invoiceNumbersAndDates: "shipment.detail.basicData_tab",
                    tcBuyerLegalName: "shipment.detail.basicData_tab",
                    tcFacilityAsBuyerBusinessName: "shipment.detail.basicData_tab",
                    tcFacilityAsSellerBusinessName: "shipment.detail.basicData_tab",
                    tcSellerLegalName: "shipment.detail.basicData_tab",
                    shipmentDocumentNumber: "shipment.detail.basicData_tab",
                }}
            />
        </EntityDetailView>
    );
}

type ShipmentTabs = TabKey<"shipment.detail.basicData_tab">;

export interface ShipmentDetailViewProps extends TypedDetailViewProps<ShipmentDetails>, ShipmentBasicDataProps {}
