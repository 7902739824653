import useGtbTranslation from "../../i18n/useGtbTranslation";

function HelpPageErrorMessage() {
    const translation = useGtbTranslation();
    return (
        <>
            <div className={"help-page-error-title"}>{translation("error.help.invalidTopic_message")}</div>
            <div>{translation("error.help.wrongUrl_message")}</div>
        </>
    );
}

export default HelpPageErrorMessage;
