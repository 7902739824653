import useGtbTranslation, { I18nKey } from "../i18n/useGtbTranslation";

interface AccessibleLabelProps {
    label: I18nKey;
    id: string;
}

/**
 * Component to label other components that should not be labeled with the default label component like divs.
 * The component to be labeled has to have the "aria-labelledby" attribute set to the given id.
 * @param id the id of the label
 * @param label the label text
 * @constructor
 */
function AccessibleLabel({ id, label }: AccessibleLabelProps) {
    const translation = useGtbTranslation();

    return (
        <span className="accessibleLabel" id={id}>
            {translation(label)}
        </span>
    );
}

export default AccessibleLabel;
