import { TypedPicklist } from "./typedPicklistType";
import DataPickList from "../DataPickList";
import { joinClassNames } from "../../../../utils/StringUtils";

import "./typedPickList.css";
import { AccreditationBodyReference } from "../../../../accreditationBody/accreditationBodyTypes";
import { FieldValues } from "react-hook-form/dist/types";

export default function AccreditationBodyPickList<FormItemType extends FieldValues>({
    className,
    ...rest
}: TypedPicklist<FormItemType, AccreditationBodyReference>) {
    return (
        <DataPickList
            className={joinClassNames("accreditationBodyPickList", className)}
            itemId="id"
            selectedItemLabel="legalName"
            itemLabel={(option) =>
                `${option.legalName}\n${option.town}, ${option.stateProvinceCountryAreaName}, (${option.stateProvinceIsoCode})`
            }
            queryUrl="/certificate-service/references/accreditation-bodies"
            {...rest}
        />
    );
}
