import { ReactNode } from "react";
import { joinClassNames } from "../../utils/StringUtils";
import useGtbTranslation, { I18nKey } from "../../i18n/useGtbTranslation";

export interface CardHeaderProps {
    title?: I18nKey;
    subtitle?: ReactNode;
    children?: ReactNode;
    className?: string;
}

function CardHeader({ title, subtitle, className, children }: CardHeaderProps) {
    const translation = useGtbTranslation();

    return (
        <div className={joinClassNames("cardHeader", className)}>
            <h1 className="cardHeading">{title ? translation(title) : ""}</h1>
            {subtitle && <p className="cardSubtitle">{subtitle}</p>}
            {children && <div className="cardHeaderContent">{children}</div>}
        </div>
    );
}

export default CardHeader;
