import { buildSchema, validateDate, validateNumber, validateString } from "../../../../utils/yupExtension";
import { ShipmentDetails } from "../shipmentTypes";

const nowMinus6Months = () => {
    let now = new Date();
    now.setMonth(now.getMonth() - 6);
    now.setHours(0, 0, 0, 0);
    return now;
};

export const buildShipmentValidationSchema = () =>
    buildSchema<ShipmentDetails>({
        shipmentDate: validateDate().isRequired().hasMaxValue(new Date(), "validation.noFutureDate_message"),
        grossShippingWeight: validateNumber().isRequired().hasMinValue(0.1),
        countryOfDestination: validateString().isRequired(),
        consignee: validateString().isRequired().hasMaxLength(500),
        shipmentDocumentNumber: validateString().isRequired().hasMaxLength(50),
        invoiceNumbersAndDates: validateString().isRequired().hasMaxLength(500),
    });
