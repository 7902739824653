import { InputQuantityDetailViewProps } from "./InputQuantityDetailView";
import useResolvedRoute from "../../../../../../components/routing/useResolvedRoute";
import { Entity, Nullable } from "../../../../../../utils/typeUtils";
import { InputQuantityDetails } from "../inputQuantityTypes";
import useDetailsCreate from "../../../../../../hooks/details/useDetailsCreate";
import { yupResolver } from "@hookform/resolvers/yup";
import { inputQuantityValidationSchema } from "./inputQuantityValidationSchema";
import { FieldPath, useWatch } from "react-hook-form";
import { useCallback, useEffect } from "react";
import useSetFormValue from "../../../../../../hooks/formHandling/useSetFormValue";
import useInputSourceNameProvider from "./inputSource/useInputSourceNameProvider";

const initialData: Nullable<InputQuantityDetails> = {
    id: null,
    inputNumber: null,
    typeOfInputSource: null,
    quantity: null,
    transactionCertificateStatus: null,
    inputProduct: null,
    currentInputSource: null,
    inputPreGtbTcProduct: null,
    inputOrganicMaterialPurchase: null,
    inputByProduct: null,
    currentInputOrganicMaterialPurchase: null,
};

const readOnly: InputQuantityDetailViewProps["readOnly"] = {
    typeOfInputSource: false,
    quantity: false,
};

export default function useInputQuantityCreate(): InputQuantityDetailViewProps {
    const { getResolvedId } = useResolvedRoute();
    const tcId = getResolvedId("transactionCertificate")!;
    const tcProductId = getResolvedId("tcProduct")!;
    const getInputSourceName = useInputSourceNameProvider();

    const { detailViewProps, form, title } = useDetailsCreate<InputQuantityDetails>({
        title: "inputQuantity.detail.newRecord_title",
        frontendRoute: "inputQuantity",
        initialData,
        resolver: yupResolver(inputQuantityValidationSchema),
        saveUrl: `/certificate-service/transaction-certificates/${tcId}/tc-products/${tcProductId}/input-quantities`,
    });

    const setFormValue = useSetFormValue(form.form);

    const [
        currentSourceType,
        currentInputProduct,
        currentInputPreGtbProduct,
        currentInputByProduct,
        currentInputOrganicMaterialPurchase,
    ] = useWatch({
        control: form.form.control,
        name: [
            "typeOfInputSource",
            "currentInputProduct",
            "currentInputPreGtbProduct",
            "currentInputByProduct",
            "currentInputOrganicMaterialPurchase",
        ],
    });

    const setInputSource = useCallback(
        (fieldName: FieldPath<InputQuantityDetails>, source?: Entity) => {
            if (source) {
                setFormValue(fieldName, source.id);
            }
        },
        [setFormValue]
    );

    useEffect(() => {
        let currentInputSource = null;
        if (currentSourceType === "TC_PRODUCT") {
            currentInputSource = getInputSourceName({ type: "TC_PRODUCT", inputSource: currentInputProduct });
            setInputSource("inputProduct", currentInputProduct);
        } else if (currentSourceType === "PRE_GTB_TC_PRODUCT") {
            currentInputSource = getInputSourceName({
                type: "PRE_GTB_TC_PRODUCT",
                inputSource: currentInputPreGtbProduct,
            });
            setInputSource("inputPreGtbTcProduct", currentInputPreGtbProduct);
        } else if (currentSourceType === "BY_PRODUCT") {
            currentInputSource = getInputSourceName({ type: "BY_PRODUCT", inputSource: currentInputByProduct });
            setInputSource("inputByProduct", currentInputByProduct);
        } else if (currentSourceType === "ORGANIC_MATERIAL_PURCHASE") {
            currentInputSource = getInputSourceName({
                type: "ORGANIC_MATERIAL_PURCHASE",
                inputSource: currentInputOrganicMaterialPurchase,
            });
            setInputSource("inputOrganicMaterialPurchase", currentInputOrganicMaterialPurchase);
        }
        setFormValue("currentInputSource", currentInputSource);
    }, [
        currentInputByProduct,
        currentInputOrganicMaterialPurchase,
        currentInputPreGtbProduct,
        currentInputProduct,
        currentSourceType,
        getInputSourceName,
        setFormValue,
        setInputSource,
    ]);

    return {
        title,
        detailViewProps,
        form,
        createRoute: "inputQuantity",
        currentSourceType,
        readOnly,
        inputSourceSelectionVisible: true,
    };
}
