import GtbTextField from "../../components/Input/GtbTextField";
import "./organisationBasicData.css";
import ControlledDatePicker from "../../components/Input/datepicker/ControlledDatePicker";
import { OrganisationDetailViewTabProps } from "./organisationDetailViewProps";
import { I18nKey } from "../../i18n/useGtbTranslation";
import { OrganisationDetails } from "../organisationTypes";
import { Path } from "react-hook-form";

function OrganisationBasicData<OrganisationType extends OrganisationDetails>({
    form,
    readOnlyProps,
    organisationI18nKey,
}: OrganisationDetailViewTabProps<OrganisationType>) {
    return (
        <>
            <GtbTextField
                {...form.registerWithErrors("businessName" as Path<OrganisationType>)}
                readOnly={readOnlyProps.isBusinessNameReadOnly}
                label={(organisationI18nKey + ".detail.businessName_input") as I18nKey}
                className="businessName"
            />
            <GtbTextField
                {...form.registerWithErrors("website" as Path<OrganisationType>)}
                readOnly={readOnlyProps.isWebsiteReadOnly}
                label={(organisationI18nKey + ".detail.website_input") as I18nKey}
                className="website"
            />
            <GtbTextField
                {...form.registerWithErrors("organisationalEmail" as Path<OrganisationType>)}
                readOnly={readOnlyProps.isOrganisationalEmailReadOnly}
                label={(organisationI18nKey + ".detail.organisationalEmailAddress_input") as I18nKey}
                className="organisationalEmail"
            />
            <GtbTextField
                {...form.registerWithErrors("organisationalPhoneNumber" as Path<OrganisationType>)}
                readOnly={readOnlyProps.isOrganisationalPhoneNumberReadOnly}
                label={(organisationI18nKey + ".detail.organisationalPhoneNumber_input") as I18nKey}
                className="organisationalPhoneNumber"
            />
            <ControlledDatePicker
                control={form.form.control}
                readOnly={readOnlyProps.isStartDateReadOnly}
                name="startDate"
                label={(organisationI18nKey + ".detail.startDate_input") as I18nKey}
                className="startDate"
            />
            <ControlledDatePicker
                control={form.form.control}
                readOnly={readOnlyProps.isEndDateReadOnly}
                name="endDate"
                label={(organisationI18nKey + ".detail.endDate_input") as I18nKey}
                className="endDate"
            />
            <GtbTextField
                {...form.registerWithErrors("organisationStatus" as Path<OrganisationType>)}
                readOnly
                label={(organisationI18nKey + ".detail.status_input") as I18nKey}
                className="status"
            />
        </>
    );
}

export default OrganisationBasicData;
