import { TypedDetailViewProps } from "../../components/detailView/DetailView";
import { UploadLogDetails } from "../uploadLogTypes";
import useUploadLogEdit from "./useUploadLogEdit";
import { UseGtbFormReturn } from "../../hooks/formHandling/useGtbForm";
import UploadLogData from "./UploadLogData";
import { useHelpContext } from "../../help/context-sensitivity/useHelpContext";
import EntityDetailView from "../../components/entity/EntityDetailView";

function UploadLogEditView() {
    const { form, ...rest } = useUploadLogEdit();
    useHelpContext("upload-log-details");
    return (
        <EntityDetailView form={form} {...rest}>
            <UploadLogData form={form} />
        </EntityDetailView>
    );
}

export default UploadLogEditView;

export interface UploadLogEditViewProps extends TypedDetailViewProps<UploadLogDetails> {}

export interface UploadLogDetailViewTabProps {
    form: UseGtbFormReturn<UploadLogDetails>;
}
