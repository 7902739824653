import { PreGtbProductReference } from "../../../../../../../preGtbTransactionCertificate/details/products/preGtbProductTypes";
import InputSourceSelectionList, {
    InputSourceSelectionListProps,
} from "../../../../../../../components/grid/InputSourceSelectionList";
import { GridColumn } from "../../../../../../../components/grid/component/Grid";
import { buildNumberColumn, buildTextColumn } from "../../../../../../../components/grid/ColumnProvider";

export default function PreGtbProductInputSourceSelectionList({
    ...rest
}: Pick<InputSourceSelectionListProps<PreGtbProductReference>, "className" | "onItemClick" | "selectedItemId">) {
    return (
        <InputSourceSelectionList<PreGtbProductReference>
            tableName="preGtbProductInputSources"
            url="/certificate-service/references/input-sources/pre-gtb-products"
            columns={preGtbProductInputSourceColumns}
            sortField="preGtbTcTcNumber"
            {...rest}
        />
    );
}

const preGtbProductInputSourceColumns: GridColumn<PreGtbProductReference>[] = [
    buildTextColumn({ key: "preGtbTcTcNumber", title: "preGtbProduct.list.tcNumber_column", minWidth: 180 }),
    buildTextColumn({ key: "productNumber", title: "preGtbProduct.list.productNumber_column", minWidth: 180 }),
    buildTextColumn({
        key: "productCategoryName",
        title: "preGtbProduct.list.productCategory_column",
        minWidth: 188,
    }),
    buildTextColumn({
        key: "productCategoryCode",
        title: "preGtbProduct.list.productCategoryCode_column",
        minWidth: 123,
    }),
    buildTextColumn({
        key: "productDetailName",
        title: "preGtbProduct.list.productDetail_column",
        minWidth: 163,
    }),
    buildTextColumn({
        key: "productDetailCode",
        title: "preGtbProduct.list.productDetailCode_column",
        minWidth: 123,
    }),
    buildTextColumn({
        key: "labelGradeLabelGrade",
        title: "preGtbProduct.list.labelGrade_column",
        minWidth: 146,
    }),
    buildTextColumn({
        key: "inputSourceMaterialComposition",
        title: "preGtbProduct.list.materialComposition_column",
        minWidth: 217,
        disableSorting: true,
        disableFiltering: true,
    }),
    buildNumberColumn({
        key: "availableVolume",
        title: "preGtbProduct.list.availableVolume_column",
        fixedDecimal: true,
        minWidth: 227,
    }),
];
