export function getStandardVersionName(version?: {
    standardAcronym: VersionDetails["standardAcronym"];
    versionNumber: VersionDetails["versionNumber"];
}): string {
    return version ? `${version.standardAcronym} ${version.versionNumber}` : "";
}

export interface VersionSummary {
    id: string;
    systemId: string;
    versionNumber: string;
    startDate: Date;
    endDate: Date;
    defaultValidityPeriod: number;
    recertificationPeriod: number;
    recertificationBindingPeriod: number;
    rawMaterialsText: string;
    processingText: string;
    cugsText: string;
    hideUsdaNopCompliance: boolean;
}

export interface VersionDetails {
    id: string;
    systemId: string;
    versionNumber: string;
    standard?: string;
    standardAcronym: string;
    logo?: string | null;
    startDate?: Date | null;
    endDate?: Date | null;
    defaultValidityPeriod?: number;
    recertificationPeriod?: number;
    recertificationBindingPeriod?: number;
    rawMaterialsText?: string;
    processingText?: string;
    cugsText?: string;
    hideUsdaNopCompliance?: boolean;
}

export interface VersionReference {
    id: string;
    standardAcronym: string;
    versionNumber: string;
}
