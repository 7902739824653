import { joinClassNames } from "../../../utils/StringUtils";
import { UseGtbFormReturn } from "../../../hooks/formHandling/useGtbForm";
import { VersionDetails } from "../versionTypes";
import { LabeledCheckbox } from "../../../components/Input/Checkbox";
import "./versionTcDeclaration.css";
import GtbTextArea from "../../../components/Input/GtbTextArea";

function VersionTcDeclaration({ form, readOnly }: VersionTcDeclarationProps) {
    return (
        <div className={joinClassNames("versionTcDeclaration", "formGrid")}>
            <GtbTextArea
                {...form.registerWithErrors("rawMaterialsText")}
                readOnly={readOnly}
                label="standardVersion.detail.rawMaterials_input"
                className="rawMaterials"
            />
            <GtbTextArea
                {...form.registerWithErrors("processingText")}
                readOnly={readOnly}
                label="standardVersion.detail.processing_input"
                className="processing"
            />
            <LabeledCheckbox
                {...form.form.register("hideUsdaNopCompliance")}
                readOnly={readOnly}
                id={"usda-nop"}
                label="standardVersion.detail.usdaNop_input"
                className="usda"
            />
            <GtbTextArea
                {...form.registerWithErrors("cugsText")}
                readOnly={readOnly}
                label="standardVersion.detail.cugs_input"
                className="cugs"
            />
        </div>
    );
}

export default VersionTcDeclaration;

interface VersionTcDeclarationProps {
    form: UseGtbFormReturn<VersionDetails>;
    readOnly: boolean;
}
