import { TypedDetailViewProps } from "../../components/detailView/DetailView";
import UserData from "./UserData";
import LoginData from "./LoginData";
import { UserDetails } from "../userTypes";
import { UseGtbFormReturn } from "../../hooks/formHandling/useGtbForm";
import Tabs, { TabKey } from "../../components/tabs/Tabs";
import EntityDetailView from "../../components/entity/EntityDetailView";

export default function UserDetailView({
    detailViewProps,
    form,
    readOnlyProps,
    visibilityProps,
    activateTotp,
    resetTotp,
    totpReady,
    ...rest
}: UserDetailViewProps) {
    return (
        <EntityDetailView {...rest} detailViewProps={detailViewProps} form={form}>
            <Tabs<UserDetails, TabKey<"user.detail.userData_tab" | "user.detail.loginData_tab">>
                tabs={[
                    {
                        heading: "user.detail.userData_tab",
                        element: (
                            <UserData form={form} readOnlyProps={readOnlyProps} visibilityProps={visibilityProps} />
                        ),
                    },
                    {
                        heading: "user.detail.loginData_tab",
                        element: (
                            <LoginData
                                form={form}
                                readOnlyProps={readOnlyProps}
                                visibilityProps={visibilityProps}
                                activateTotp={activateTotp}
                                resetTotp={resetTotp}
                                totpReady={totpReady}
                            />
                        ),
                    },
                ]}
                errors={form.form.formState.errors}
                errorToTab={{
                    id: "internal",
                    systemId: "internal",
                    givenName: "user.detail.userData_tab",
                    surname: "user.detail.userData_tab",
                    emailAddress: "user.detail.userData_tab",
                    username: "user.detail.userData_tab",
                    authorisationRole: "user.detail.userData_tab",
                    organisation: "user.detail.userData_tab",
                    blocked: "user.detail.userData_tab",
                    lastSuccessfulLogin: "user.detail.loginData_tab",
                    lastFailedLogin: "user.detail.loginData_tab",
                    startDate: "user.detail.userData_tab",
                    endDate: "user.detail.userData_tab",
                    testDataStamp: "user.detail.loginData_tab",
                    standardBody: "user.detail.userData_tab",
                    guiTheme: "internal",
                    authorisationRoleLevel: "internal",
                    totpReady: "user.detail.loginData_tab",
                    isOrganisationMandatory: "internal",
                    acceptedToU: "user.detail.loginData_tab",
                    acceptedGoogleToU: "user.detail.loginData_tab",
                }}
            />
        </EntityDetailView>
    );
}

export interface UserDetailViewProps extends TypedDetailViewProps<UserDetails> {
    readOnlyProps: UserDetailReadOnlyProps;
    visibilityProps: UserDetailVisibilityProps;
    resetTotp?: () => void;
    activateTotp?: () => void;
    totpReady?: boolean;
}

export interface UserDetailViewTabProps {
    form: UseGtbFormReturn<UserDetails>;
    readOnlyProps: UserDetailReadOnlyProps;
    visibilityProps: UserDetailVisibilityProps;
    resetTotp?: () => void;
    activateTotp?: () => void;
    totpReady?: boolean;
}

export interface UserDetailReadOnlyProps {
    isUsernameReadOnly: boolean;
    isRoleReadOnly: boolean;
    isBlockedReadOnly: boolean;
    isGivenNameReadOnly: boolean;
    isSurnameReadOnly: boolean;
    isOrganisationReadOnly: boolean;
    isStandardBodyReadOnly: boolean;
    isEmailAddressReadOnly: boolean;
    isStartDateReadOnly: boolean;
    isEndDateReadOnly: boolean;
    isTestDataStampReadOnly: boolean;
}

export interface UserDetailVisibilityProps {
    isTestDataStampVisible: boolean;
    isChangePasswordButtonVisible: boolean;
    isAcceptedToUVisible: boolean;
}
