import { UseGtbFormReturn } from "../../../../hooks/formHandling/useGtbForm";
import { joinClassNames } from "../../../../utils/StringUtils";
import GtbTextField from "../../../../components/Input/GtbTextField";
import { ShipmentDetails } from "../shipmentTypes";
import ControlledDatePicker from "../../../../components/Input/datepicker/ControlledDatePicker";
import { GtbNumberFieldOneFixedDecimal } from "../../../../components/Input/GtbNumberField";
import CountryAreaPickList from "../../../../components/Input/pickList/typedPicklist/CountryAreaPickList";
import "./shipmentBasicData.css";
import ControlledInputWrapper from "../../../../components/Input/inputWrapper/ControlledInputWrapper";
import useSetFormValue from "../../../../hooks/formHandling/useSetFormValue";
import GtbTextArea from "../../../../components/Input/GtbTextArea";
import GtbButton from "../../../../components/GtbButton";
import useGtbTranslation from "../../../../i18n/useGtbTranslation";
import { useQuery } from "../../../../hooks/useAxios";
import { SupplyChainOperatorReference } from "../../../../supplyChainOperator/supplyChainOperatorTypes";
import { useWatch } from "react-hook-form";
import { ReadOnlyProps } from "../../../../utils/typeUtils";

export interface ShipmentBasicDataProps {
    form: UseGtbFormReturn<ShipmentDetails>;
    transactionCertificateId: string;
    readOnly: boolean;
    visibility: ReadOnlyProps<
        Pick<ShipmentDetails, "tcFacilityAsSellerBusinessName" | "tcFacilityAsBuyerBusinessName">
    >;
}

export default function ShipmentBasicData({
    form,
    readOnly,
    transactionCertificateId,
    visibility,
}: ShipmentBasicDataProps) {
    const translation = useGtbTranslation();
    const setFormValue = useSetFormValue(form.form);
    const buyer = useWatch({ control: form.form.control, name: "tcBuyerLegalName" });

    const { runQuery: fetchBuyerAddressDetails, isLoading: isBuyerAddressDetailsLoading } =
        useQuery<SupplyChainOperatorReference>({
            url: `/certificate-service/transaction-certificates/${transactionCertificateId}/buyer-address`,
            enabled: false,
        });

    return (
        <div className={joinClassNames("shipmentBasicData", "formGrid")}>
            <GtbTextField
                label="shipment.detail.shipmentNumber_input"
                {...form.registerWithErrors("shipmentNumber")}
                readOnly
                className="shipmentNumber"
            />
            <GtbTextField
                label="shipment.detail.shipmentDocumentNumber_input"
                {...form.registerWithErrors("shipmentDocumentNumber")}
                readOnly={readOnly}
                className="shipmentDocumentNumber"
            />
            <ControlledDatePicker
                label="shipment.detail.shipmentDate_input"
                control={form.form.control}
                name="shipmentDate"
                readOnly={readOnly}
                className="shipmentDate"
            />
            <GtbTextField
                label="shipment.detail.seller_input"
                {...form.registerWithErrors("tcSellerLegalName")}
                readOnly
                className="seller"
            />
            {visibility.tcFacilityAsSellerBusinessName && (
                <GtbTextField
                    label="shipment.detail.facilityAsSeller_input"
                    {...form.registerWithErrors("tcFacilityAsSellerBusinessName")}
                    readOnly
                    className="facilityAsSeller"
                />
            )}
            <GtbTextField
                label="shipment.detail.buyer_input"
                {...form.registerWithErrors("tcBuyerLegalName")}
                readOnly
                className="buyer"
            />
            {visibility.tcFacilityAsBuyerBusinessName && (
                <GtbTextField
                    label="shipment.detail.facilityAsBuyer_input"
                    {...form.registerWithErrors("tcFacilityAsBuyerBusinessName")}
                    readOnly
                    className="facilityAsBuyer"
                />
            )}
            <GtbNumberFieldOneFixedDecimal
                label="shipment.detail.grossShippingWeight_input"
                control={form.form.control}
                name="grossShippingWeight"
                readOnly={readOnly}
                decimalPlaces={1}
                className="grossShippingWeight"
                unit="kg"
            />
            <CountryAreaPickList
                label="shipment.detail.countryAreaOfDestination_input"
                control={form.form.control}
                name="countryOfDestination"
                readOnly={readOnly}
                className="countryOfDestination"
            />
            <GtbTextArea
                label="shipment.detail.invoiceNumbersAndDates_input"
                {...form.registerWithErrors("invoiceNumbersAndDates")}
                readOnly={readOnly}
                minRows={4}
                className="invoiceNumbersAndDates"
            />
            <ControlledInputWrapper
                label="shipment.detail.consignee_input"
                control={form.form.control}
                name={"consignee"}
                labelFor="consignee"
                className="consignee"
                readOnly={readOnly || isBuyerAddressDetailsLoading}
                controlledInput={(value, onChange) => (
                    <>
                        <GtbTextArea
                            id="consignee"
                            className="inputWrapperTextField"
                            minRows={4}
                            value={value ?? ""}
                            onChange={onChange}
                            readOnly={readOnly || isBuyerAddressDetailsLoading}
                        />
                        <GtbButton
                            variant="secondary"
                            disabled={readOnly || isBuyerAddressDetailsLoading || !buyer}
                            className="inputWrapperEndButton"
                            onClick={() =>
                                fetchBuyerAddressDetails().then((address) => setFormValue("consignee", address))
                            }
                        >
                            {translation("shipment.detail.copyFromBuyer_button")}
                        </GtbButton>
                    </>
                )}
            />
        </div>
    );
}
