import { yupResolver } from "@hookform/resolvers/yup";
import { labelGradeValidationSchema } from "./labelGradeValidationSchema";
import { useQuery } from "../../hooks/useAxios";
import useIsAuthorised from "../../auth/useIsAuthorised";
import { LabelGradeDetails } from "../labelGradeTypes";
import { defaultLabelGradeSortOption } from "../useLabelGradeSubList";
import { getStandardVersionName, VersionDetails } from "../../standard/version/versionTypes";
import { useMemo } from "react";
import useResolvedRoute, { routeEnum } from "../../components/routing/useResolvedRoute";
import useGtbTranslation from "../../i18n/useGtbTranslation";
import useDomainEntityDetailsEdit from "../../hooks/details/useDomainEntityDetailsEdit";

function useLabelGradeEdit() {
    const translation = useGtbTranslation();
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedId } = useResolvedRoute();
    const standardId = getResolvedId("standard");
    const standardVersionId = getResolvedId("standardVersion");
    const labelGradeId = getResolvedId("labelGrade");

    const { data: standardVersion, isLoading: isVersionLoading } = useQuery<VersionDetails>({
        url: "/certificate-service/references/standards/" + standardId + "/standard-versions/" + standardVersionId,
    });

    const { detailViewProps, form, initialData, isReadOnly, title, recordNavigationProps } =
        useDomainEntityDetailsEdit<LabelGradeDetails>({
            baseUrl:
                "/certificate-service/standards/" +
                standardId +
                "/standard-versions/" +
                standardVersionId +
                "/label-grades/" +
                labelGradeId,
            resolver: yupResolver(labelGradeValidationSchema),
            frontendRoute: "labelGrade",
            recordNavigationDefaultSort: defaultLabelGradeSortOption,
            titleBuilder: (item) => item.labelGrade,
            canSave: hasAccessRight("updateMasterData"),
            historyColumns: [
                { identifier: "systemId", title: "labelGrade.history.systemId_column" },
                { identifier: "standardVersion", title: "labelGrade.history.standardVersion_column" },
                { identifier: "labelGrade", title: "labelGrade.history.labelGrade_column" },
                { identifier: "typeOfMaterial", title: "labelGrade.history.typeOfMaterial_column" },
                { identifier: "minPercentage", title: "labelGrade.history.minPercentage_column" },
            ],
        });

    const createRoute = useMemo<routeEnum | undefined>(() => {
        return hasAccessRight("createMasterData") ? "labelGrade" : undefined;
    }, [hasAccessRight]);

    return useMemo(() => {
        return {
            detailViewProps: { ...detailViewProps, isLoading: detailViewProps.isLoading || isVersionLoading },
            form,
            isReadOnly,
            subListVisible: true,
            title,
            subtitle: translation({ key: "labelGrade.detail.subtitle", options: { systemId: initialData?.systemId } }),
            standardVersionName: getStandardVersionName(standardVersion),
            recordNavigationProps,
            createRoute,
        };
    }, [
        createRoute,
        detailViewProps,
        form,
        initialData?.systemId,
        isReadOnly,
        isVersionLoading,
        recordNavigationProps,
        standardVersion,
        title,
        translation,
    ]);
}

export default useLabelGradeEdit;
