import { joinClassNames } from "../../utils/StringUtils";

import GtbTextArea from "../../components/Input/GtbTextArea";
import "./countryAreaAdditionalData.css";
import { CountryAreaDetailViewTabProps } from "./CountryAreaDetailView";

function CountryAreaAdditionalData({ form, readOnly }: CountryAreaDetailViewTabProps) {
    return (
        <div className={joinClassNames("countryAreaAdditionalData", "formGrid")}>
            <GtbTextArea
                {...form.registerWithErrors("addressPattern")}
                readOnly={readOnly}
                minRows={8}
                maxRows={8}
                label={"countryArea.detail.addressPattern_input"}
                className="addressPattern"
            />

            <GtbTextArea
                {...form.registerWithErrors("welcomeMessage")}
                readOnly={readOnly}
                label={"countryArea.detail.welcomeMessage_input"}
                className="welcomeMessage"
            />
        </div>
    );
}

export default CountryAreaAdditionalData;
