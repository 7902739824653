import {
    buildSchema,
    validateDate,
    validateNumber,
    validateString,
    validateTranslation,
} from "../../utils/yupExtension";
import { ProductCategoryDetails } from "../productCategoryTypes";

export const productCategoryValidationSchema = buildSchema<ProductCategoryDetails>({
    code: validateString().isRequired().hasMinLength(6).hasMaxLength(6),
    pickListSequence: validateNumber().isRequired(),
    name: validateTranslation(40),
    startDate: validateDate(),
    endDate: validateDate(),
});
