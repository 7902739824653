import useAuthorisationRoleListView from "./useAuthorisationRoleListView";
import EntityListViewWithoutInfinityScroll from "../components/entity/EntityListViewExcludingInfinityScroll";
import { useHelpContext } from "../help/context-sensitivity/useHelpContext";

export default function AuthorisationRoleListView() {
    const authorisationRoleGridOptions = useAuthorisationRoleListView();
    useHelpContext("authorisation-role-list");
    return (
        <EntityListViewWithoutInfinityScroll
            heading="authorisationRole.list.header"
            gridOptions={authorisationRoleGridOptions}
        />
    );
}
