import useDialog from "../dialog/useDialog";
import useGtbTranslation, { I18nKey } from "../../i18n/useGtbTranslation";
import { useCallback } from "react";
import DialogHeader from "../dialog/DialogHeader";
import DialogContent from "../dialog/DialogContent";
import GtbButton from "../GtbButton";
import DialogFooter from "../dialog/DialogFooter";

export default function useTlsCertificateAnalysisFailedDialog() {
    const { showDialog, closeDialog } = useDialog();
    const translation = useGtbTranslation();

    return useCallback(
        (failedCertificationErrorCodes: Array<I18nKey & string> = []) => {
            showDialog({
                header: (
                    <DialogHeader
                        title="components.tlsCertificate.analysis_failed_dialog_title"
                        variant={"error"}
                        onClick={closeDialog}
                    />
                ),
                content: (
                    <DialogContent>
                        <p>{translation("components.tlsCertificate.analysis_failed_dialog_message")}</p>
                        <ul className="tls_error_list">
                            {failedCertificationErrorCodes.map((errorCode) => {
                                return <li key={errorCode}>{translation(errorCode)}</li>;
                            })}
                        </ul>
                    </DialogContent>
                ),
                footer: (
                    <DialogFooter>
                        <GtbButton onClick={closeDialog}>{translation("components.dialog.ok_button")}</GtbButton>
                    </DialogFooter>
                ),
            });
        },
        [closeDialog, showDialog, translation]
    );
}
