import ExternalView from "../external/ExternalView";

import ForgotPasswordForm from "./ForgotPasswordForm";
import React from "react";

function ForgotPasswordView() {
    return (
        <ExternalView header="loginPage.reset_password_header">
            <ForgotPasswordForm />
        </ExternalView>
    );
}

export default ForgotPasswordView;
