import { SortOption, useQueryBuilder } from "../../../../../../../components/grid/component/useQueryBuilder";
import { ScFacilityDetails, ScFacilitySummary } from "./scFacilityTypes";
import { GridColumn } from "../../../../../../../components/grid/component/Grid";
import { buildTextColumn } from "../../../../../../../components/grid/ColumnProvider";
import useIsAuthorised from "../../../../../../../auth/useIsAuthorised";
import useResolvedRoute from "../../../../../../../components/routing/useResolvedRoute";
import { useDeleteAction, useShowDetailsAction } from "../../../../../../../utils/defaultActions";
import { useMemo } from "react";
import { SubListProps } from "../../../../../../../components/grid/SubList";
import { scopeCertificateReadOnlyByStatus } from "../../../../../../scopeCertificateTypes";
import { scopeCertificateStatusType } from "../../../../../../../auth/roleTypes";
import useGridRowContextMenuActions from "../../../../../../../components/grid/component/cell/useGridRowContextMenuActions";

export const defaultScFacilitySortOption: SortOption<ScFacilitySummary | ScFacilityDetails> = {
    field: "scopeCertificateSupplyChainOperatorBusinessName",
    direction: "ASC",
};

const columns: GridColumn<ScFacilitySummary>[] = [
    buildTextColumn({
        key: "scopeCertificateSupplyChainOperatorBusinessName",
        title: "scFacility.list.supplyChainOperator_column",
        minWidth: 20,
    }),
    buildTextColumn({ key: "facilityBusinessName", title: "scFacility.list.facility_column", minWidth: 20 }),
];

export default function useScFacilitySubList(scopeCertificateStatus?: scopeCertificateStatusType) {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedId, getResolvedEditUrl, getResolvedCreateUrl } = useResolvedRoute();
    const scopeCertificateId = getResolvedId("scopeCertificate");
    const scProductId = getResolvedId("scProduct");
    const scProcessCategoryId = getResolvedId("scProcessCategory");

    const { query, columnOptions, forceReload } = useQueryBuilder<ScFacilitySummary>(
        `/certificate-service/scope-certificates/${scopeCertificateId}/sc-products/${scProductId}/sc-process-categories/${scProcessCategoryId}/sc-facilities`,
        {
            sort: defaultScFacilitySortOption,
            filterActive: false,
        }
    );

    const showDetailsAction = useShowDetailsAction(
        (item: ScFacilitySummary) => getResolvedEditUrl("scFacility", item.id),
        query.parameter
    );

    const deleteAction = useDeleteAction(
        (item: ScFacilitySummary) =>
            `/certificate-service/scope-certificates/${scopeCertificateId}/sc-products/${scProductId}/sc-process-categories/${scProcessCategoryId}/sc-facilities/${item.id}`,
        () => forceReload(),
        "updateScopeCertificate",
        scopeCertificateStatus ? [scopeCertificateStatus] : []
    );

    const isReadOnlyByScopeCertificateStatus = useMemo<boolean>(
        () => scopeCertificateReadOnlyByStatus(scopeCertificateStatus),
        [scopeCertificateStatus]
    );

    const contextMenuActions = useGridRowContextMenuActions(
        showDetailsAction,
        !isReadOnlyByScopeCertificateStatus ? deleteAction : undefined
    );

    return useMemo<SubListProps<ScFacilitySummary>>(
        () => ({
            query,
            columns,
            uniqueDataKey: "id" as keyof ScFacilitySummary,
            onItemDoubleClick: showDetailsAction,
            header: "scFacility.list.header",
            itemActions: contextMenuActions,
            columnOptions,
            createUrl:
                scopeCertificateStatus &&
                hasAccessRight("updateScopeCertificate", [scopeCertificateStatus]) &&
                !isReadOnlyByScopeCertificateStatus
                    ? getResolvedCreateUrl("scFacility")
                    : undefined,
            tableName: "scFacilities",
        }),
        [
            columnOptions,
            contextMenuActions,
            getResolvedCreateUrl,
            hasAccessRight,
            isReadOnlyByScopeCertificateStatus,
            query,
            scopeCertificateStatus,
            showDetailsAction,
        ]
    );
}
