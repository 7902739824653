import { useQuery } from "../../../../hooks/useAxios";
import { ScProductReference } from "../../../../scopeCertificate/details/products/scProductTypes";
import { UseGtbFormReturn } from "../../../../hooks/formHandling/useGtbForm";
import { TcProductDetails } from "../tcProductTypes";
import { useEffect, useMemo } from "react";
import { useFormState } from "react-hook-form";
import useSetFormValue from "../../../../hooks/formHandling/useSetFormValue";

export default function useTcProductDetail(form: UseGtbFormReturn<TcProductDetails>) {
    const selectedScProductId = form.form.watch("relatedScProduct");
    const { isDirty: isRelatedScProductDirty } = useFormState({ control: form.form.control, name: "relatedScProduct" });
    const setFormValue = useSetFormValue(form.form);

    const { data: selectedScProduct } = useQuery<ScProductReference>({
        url: `/certificate-service/references/sc-products/${selectedScProductId}`,
        enabled: !!selectedScProductId,
    });

    const { data: byProductWeightVisible } = useQuery<boolean>({
        url: `/certificate-service/references/sc-products/by-product-weight-visible/${selectedScProductId}`,
        enabled: !!selectedScProductId,
    });

    useEffect(() => {
        if (isRelatedScProductDirty && (!selectedScProductId || !byProductWeightVisible)) {
            setFormValue("byProductWeight");
            setFormValue("availableByProductQuantity");
        }
    }, [byProductWeightVisible, isRelatedScProductDirty, selectedScProductId, setFormValue]);

    return useMemo(() => {
        if (selectedScProductId) {
            return {
                selectedScProduct,
                byProductWeightVisible,
            };
        }
        return { selectedScProduct: undefined, byProductWeightVisible: undefined };
    }, [byProductWeightVisible, selectedScProduct, selectedScProductId]);
}
