import { TypedDetailViewProps } from "../../components/detailView/DetailView";
import { UseGtbFormReturn } from "../../hooks/formHandling/useGtbForm";
import { StandardDetails, standardTypeEnum } from "../standardTypes";
import StandardBasicData from "./StandardBasicData";
import StandardScopes from "./StandardScopes";
import StandardAllowedMaterials from "./StandardAllowedMaterials";
import { useHelpContext } from "../../help/context-sensitivity/useHelpContext";
import Tabs, { TabKey, TabProps } from "../../components/tabs/Tabs";
import EntityDetailView from "../../components/entity/EntityDetailView";
import { useMemo } from "react";

export default function StandardDetailView({
    form,
    isReadOnly,
    subListVisible,
    currentTypeOfStandard,
    ...rest
}: StandardDetailViewProps) {
    useHelpContext("standard-details");

    const sublistTabs = useMemo<
        TabProps<
            TabKey<
                "standard.detail.basicData_tab" | "standard.detail.scopes_tab" | "standard.detail.allowedMaterials_tab"
            >
        >[]
    >(
        () => [
            {
                heading: "standard.detail.scopes_tab",
                element: <StandardScopes />,
            },
            {
                heading: "standard.detail.allowedMaterials_tab",
                element: <StandardAllowedMaterials />,
            },
        ],
        []
    );

    return (
        <EntityDetailView {...rest} form={form}>
            <Tabs<
                StandardDetails,
                TabKey<
                    | "standard.detail.basicData_tab"
                    | "standard.detail.scopes_tab"
                    | "standard.detail.allowedMaterials_tab"
                >
            >
                tabs={[
                    {
                        heading: "standard.detail.basicData_tab",
                        element: (
                            <StandardBasicData
                                form={form}
                                readOnly={isReadOnly}
                                currentTypeOfStandard={currentTypeOfStandard}
                                subListVisible={subListVisible}
                            />
                        ),
                    },
                    ...(subListVisible ? sublistTabs : []),
                ]}
                errors={form.form.formState.errors}
                errorToTab={{
                    id: "internal",
                    acronym: "standard.detail.basicData_tab",
                    officialName: "standard.detail.basicData_tab",
                    typeOfStandard: "standard.detail.basicData_tab",
                    standardBody: "standard.detail.basicData_tab",
                    transactionCertificatesMandatory: "standard.detail.basicData_tab",
                }}
            />
        </EntityDetailView>
    );
}

interface StandardDetailViewProps extends TypedDetailViewProps<StandardDetails> {
    subListVisible: boolean;
    currentTypeOfStandard: standardTypeEnum | null;
    isReadOnly: boolean;
}

export interface StandardDetailViewTabProps {
    form: UseGtbFormReturn<StandardDetails>;
    currentTypeOfStandard: standardTypeEnum | null;
    readOnly: boolean;
    subListVisible: boolean;
}
