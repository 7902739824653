import "./externalOrganicMaterialProducerIdDetailView.css";
import ExternalIdDetailView, { ExternalIdReadOnlyProps } from "../../details/ExternalIdDetailView";
import { useHelpContext } from "../../../help/context-sensitivity/useHelpContext";
import EntityDetailView from "../../../components/entity/EntityDetailView";
import { joinClassNames } from "../../../utils/StringUtils";
import { TypedDetailViewProps } from "../../../components/detailView/DetailView";
import OrganicMaterialProducerPickList from "../../../components/Input/pickList/typedPicklist/OrganicMaterialProducerPickList";
import { ExternalIdDetails } from "../../externalIdTypes";

export default function ExternalOrganicMaterialProducerIdDetailView({
    detailViewProps,
    form,
    readOnlyProps,
    ...rest
}: ExternalOrganicMaterialProducerIdDetailViewProps) {
    useHelpContext("external-organic-material-producer-id-details");
    return (
        <EntityDetailView {...rest} detailViewProps={detailViewProps} form={form}>
            <div
                className={joinClassNames("externalOrganicMaterialProducerIdData", "externalIdDetailView", "formGrid")}
            >
                <OrganicMaterialProducerPickList
                    control={form.form.control}
                    label="externalOrganicMaterialProducerId.detail.organicMaterialProducer_input"
                    className="organicMaterialProducer"
                    readOnly={readOnlyProps.isParentEntityReadOnly}
                    name="parent"
                />
                <ExternalIdDetailView
                    form={form}
                    readOnlyProps={readOnlyProps}
                    i18nKey="externalOrganicMaterialProducerId"
                />
            </div>
        </EntityDetailView>
    );
}

export interface ExternalOrganicMaterialProducerIdDetailViewProps extends TypedDetailViewProps<ExternalIdDetails> {
    readOnlyProps: ExternalIdReadOnlyProps;
}
