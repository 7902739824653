import { FocusEventHandler, useCallback, useEffect, useMemo, useState } from "react";
import GtbTextField, { GtbTextFieldProps } from "../../../Input/GtbTextField";
import Icon from "../../../Icon";
import { FilterComponentProps } from "../../ColumnProvider";
import useGtbTranslation, { I18nKey } from "../../../../i18n/useGtbTranslation";
import "./gridfilter.css";

export type FilterValidator = (value: string) => I18nKey | undefined;

export interface StringFilterProps<ItemType>
    extends FilterComponentProps<ItemType>,
        Omit<GtbTextFieldProps, "className" | "startAdornment" | "placeholder" | "title"> {
    itemKey: string;
    title: I18nKey;
    filterValidator?: FilterValidator;
}

function StringFilter<ItemType>({
    itemKey,
    title,
    filter,
    setFilter,
    filterKey,
    filterValidator,
    ...rest
}: StringFilterProps<ItemType>) {
    const [internalValue, setInternalValue] = useState<string>(filter ? filter : "");
    const initialFilter = useMemo(() => (filter ? filter : ""), [filter]);
    const translation = useGtbTranslation();
    const [error, setError] = useState<I18nKey>();

    useEffect(() => {
        setError(filterValidator?.(initialFilter));
        setInternalValue(initialFilter);
    }, [initialFilter, filterValidator]);

    const changeFilter = useCallback(() => {
        const _error = filterValidator?.(internalValue);
        setError(_error);
        if (!_error) {
            setFilter(filterKey, internalValue);
        }
    }, [filterKey, internalValue, setFilter, filterValidator]);

    const changeOnBlur: FocusEventHandler<HTMLInputElement> = useCallback(
        (event) => {
            if (event.target.value !== initialFilter) {
                changeFilter();
            }
        },
        [changeFilter, initialFilter]
    );

    return (
        <div
            onKeyDown={(event) => {
                if (event.key === "Enter") {
                    event.preventDefault();
                    changeFilter();
                }
            }}
            className={"gridFilter"}
        >
            <GtbTextField
                id={itemKey + "Filter"}
                aria-label={{ key: "components.grid.filterBy_accessibleLabel", options: { title } }}
                value={internalValue}
                onChange={(e) => setInternalValue(e.target.value)}
                startAdornment={<Icon name={"search"} size={14} />}
                placeholder={translation("components.grid.filterTextPlaceholder")}
                onBlur={changeOnBlur}
                error={!!error}
                helperText={error ? translation(error) : undefined}
                {...rest}
            />
        </div>
    );
}

export default StringFilter;
