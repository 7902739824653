import { TypedDetailViewProps } from "../../components/detailView/DetailView";
import AllowedMaterialBasicData from "./AllowedMaterialBasicData";
import { AllowedMaterialDetails } from "../allowedMaterialTypes";
import EntityDetailView from "../../components/entity/EntityDetailView";
import { useHelpContext } from "../../help/context-sensitivity/useHelpContext";

export default function AllowedMaterialDetailView({
    detailViewProps,
    form,
    readOnlyProps,
    ...rest
}: AllowedMaterialDetailViewProps) {
    useHelpContext("allowed-material-details");
    return (
        <EntityDetailView detailViewProps={detailViewProps} form={form} {...rest}>
            <AllowedMaterialBasicData form={form} readOnlyProps={readOnlyProps} />
        </EntityDetailView>
    );
}

export interface AllowedMaterialDetailViewProps extends TypedDetailViewProps<AllowedMaterialDetails> {
    readOnlyProps: AllowedMaterialReadOnlyProps;
}

export interface AllowedMaterialReadOnlyProps {
    isStandardReadOnly: boolean;
    isRawMaterialReadOnly: boolean;
}
