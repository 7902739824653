import useDetailsCreate from "../../hooks/details/useDetailsCreate";
import { yupResolver } from "@hookform/resolvers/yup";
import { FacilityDetails } from "../facilityTypes";
import { buildFacilityValidationSchema } from "./facilityValidationSchema";
import { FacilityReadOnlyProps } from "./FacilityDetailView";
import useResolvedRoute, { routeEnum } from "../../components/routing/useResolvedRoute";
import {
    organisationDefaultInitialData,
    useOrganisationDefaultReadOnlyProps,
} from "../../organisation/details/organisationDetailViewProps";
import useTypeOfRegistrationId from "../../countryArea/useTypeOfRegistrationId";
import { useQuery } from "../../hooks/useAxios";
import { SupplyChainOperatorReference } from "../../supplyChainOperator/supplyChainOperatorTypes";
import { useEffect, useMemo, useState } from "react";
import { externalIdComponentTypeEnum } from "../../externalId/externalIdTypes";
import useResolveDuplicates from "../../hooks/details/useResolveDuplicates";
import useAcceptFacilityDuplicate from "./useAcceptFacilityDuplicate";
import useRequiresLegalNameInEnglish from "../../organisation/useRequiresLegalNameInEnglish";
import { Nullable } from "../../utils/typeUtils";

export default function useFacilityCreate() {
    const { getResolvedId } = useResolvedRoute();
    const supplyChainOperatorId = getResolvedId("supplyChainOperator");
    const [requiresLegalNameInEnglish, setRequiresLegalNameInEnglish] = useState(false);
    const acceptDuplicate = useAcceptFacilityDuplicate();
    const duplicateResolver = useResolveDuplicates<FacilityDetails>(acceptDuplicate);

    const { data: supplyChainOperator } = useQuery<SupplyChainOperatorReference>({
        url: "/certificate-service/references/supply-chain-operators/" + supplyChainOperatorId,
    });

    const initialData = useMemo<Nullable<FacilityDetails> | undefined>(() => {
        if (!supplyChainOperator) {
            return undefined;
        }
        return {
            ...organisationDefaultInitialData,
            legalName: null,
            businessName: null,
            fixedRegistrationId: null,
            independentLegalEntity: false,
            numberOfEmployees: null,
            cbInternalId: null,
            legalNameInEnglish: null,
            organisationalEmail: supplyChainOperator.organisationalEmail,
        };
    }, [supplyChainOperator]);

    const { detailViewProps, form, title } = useDetailsCreate<FacilityDetails>({
        title: "facility.detail.newRecord_title",
        frontendRoute: "facility",
        resolveErrors: duplicateResolver,
        initialData,
        resolver: yupResolver(buildFacilityValidationSchema(requiresLegalNameInEnglish)),
        saveUrl: "/certificate-service/supply-chain-operators/" + supplyChainOperatorId + "/facilities",
    });

    const { typeOfRegistrationId, isFixedRegistrationIdReadOnly } = useTypeOfRegistrationId(form);

    const independentLegalEntity = form.form.watch("independentLegalEntity");

    const defaultReadOnlyProps = useOrganisationDefaultReadOnlyProps(false);

    useEffect(() => {
        if (independentLegalEntity === false) {
            form.form.setValue("legalName", null, {
                shouldDirty: true,
            });
        }
    }, [form.form, independentLegalEntity]);

    const readOnlyProps = useMemo<FacilityReadOnlyProps>(
        () => ({
            ...defaultReadOnlyProps,
            isFixedRegistrationIdReadOnly: isFixedRegistrationIdReadOnly,
            isMainFacilityReadOnly: false,
            isBrandNamesReadOnly: false,
            isMembershipReadOnly: false,
            isPublicContactPersonNameReadOnly: false,
            isPublicContactPersonEmailReadOnly: false,
            isOrganisationalContactPersonNameReadOnly: false,
            isOrganisationalContactPersonEmailReadOnly: false,
            isIndependentLegalEntityReadOnly: false,
            isNumberOfEmployeesReadOnly: false,
            isCbInternalIdReadOnly: false,
            isLegalNameInEnglishReadOnly: false,
        }),
        [defaultReadOnlyProps, isFixedRegistrationIdReadOnly]
    );

    useRequiresLegalNameInEnglish(form, setRequiresLegalNameInEnglish);

    return {
        title,
        detailViewProps,
        form,
        navigationEnabled: false,
        readOnlyProps,
        createRoute: "facility" as routeEnum,
        typeOfRegistrationId,
        supplyChainOperatorLegalName: supplyChainOperator?.legalName,
        externalIdComponentType: "NONE" as externalIdComponentTypeEnum,
        supplyChainOperator: supplyChainOperator?.businessName,
        requiresLegalNameInEnglish,
    };
}
