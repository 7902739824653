import { buildDateColumn, buildTextColumn } from "../components/grid/ColumnProvider";
import { useMemo } from "react";
import useIsAuthorised from "../auth/useIsAuthorised";
import { useDeleteAction, useShowDetailsAction } from "../utils/defaultActions";
import { SortOption, useQueryBuilder } from "../components/grid/component/useQueryBuilder";
import { ScopeDetails, ScopeSummary } from "./scopeTypes";
import { GridColumn } from "../components/grid/component/Grid";
import useResolvedRoute from "../components/routing/useResolvedRoute";
import { SubListProps } from "../components/grid/SubList";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";

export const defaultScopeSortOption: SortOption<ScopeSummary | ScopeDetails> = {
    field: "name",
    direction: "ASC",
};

const columns: GridColumn<ScopeSummary>[] = [
    buildTextColumn({ key: "name", title: "scope.list.scope_column", minWidth: 104 }),
    buildTextColumn({ key: "description", title: "scope.list.description_column", minWidth: 145 }),
    buildDateColumn({ key: "endDate", title: "scope.list.endDate_column", visible: false, minWidth: 124 }),
    buildDateColumn({ key: "startDate", title: "scope.list.startDate_column", visible: false, minWidth: 130 }),
];

function useScopeSubList() {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedEditUrl, getResolvedCreateUrl, getResolvedId } = useResolvedRoute();
    const standardId = getResolvedId("standard");

    const { query, columnOptions, forceReload } = useQueryBuilder<ScopeSummary>(
        `/certificate-service/standards/${standardId}/scopes`,
        {
            sort: defaultScopeSortOption,
            filterActive: false,
        }
    );

    const showDetailsAction = useShowDetailsAction(
        (item) => getResolvedEditUrl("scope", item.id),
        query.parameter
    );

    const deleteAction = useDeleteAction(
        (item: ScopeSummary) => `/certificate-service/standards/${standardId}/scopes/${item.id}`,
        forceReload,
        "deleteMasterData"
    );

    const contextMenuActions = useGridRowContextMenuActions(showDetailsAction, deleteAction);

    return useMemo<SubListProps<ScopeSummary>>(
        () => ({
            query,
            columns,
            columnOptions,
            uniqueDataKey: "id",
            onItemDoubleClick: showDetailsAction,
            header: "scope.list.header",
            itemActions: contextMenuActions,
            createUrl: hasAccessRight("createMasterData") ? getResolvedCreateUrl("scope") : undefined,
            tableName: "scopes",
        }),
        [contextMenuActions, columnOptions, getResolvedCreateUrl, hasAccessRight, query, showDetailsAction]
    );
}

export default useScopeSubList;
