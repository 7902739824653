import { joinClassNames } from "../../utils/StringUtils";
import GtbTextField from "../../components/Input/GtbTextField";
import "./rawMaterialBasicData.css";
import ControlledDatePicker from "../../components/Input/datepicker/ControlledDatePicker";
import { RawMaterialDetailViewTabProps } from "./RawMaterialDetailView";
import GtbNumberField from "../../components/Input/GtbNumberField";
import SubList from "../../components/grid/SubList";
import useRawMaterialAllowedMaterialSubList from "../../allowedMaterial/useRawMaterialAllowedMaterialSubList";
import CurrentLanguageWatchField from "../../i18n/CurrentLanguageWatchField";
import TypeOfMaterialPicklist from "../../components/Input/pickList/typedPicklist/TypeOfMaterialPicklist";
import { LabeledCheckbox } from "../../components/Input/Checkbox";

function RawMaterialBasicData({ form, readOnly, subListVisible }: RawMaterialDetailViewTabProps) {
    const allowedMaterialListOptions = useRawMaterialAllowedMaterialSubList();

    return (
        <div className={joinClassNames("rawMaterialBasicData", "formGrid")}>
            <GtbTextField
                {...form.registerWithErrors("code")}
                readOnly={readOnly}
                label="rawMaterial.detail.code_input"
                className="code"
            />
            <GtbNumberField
                {...form.registerNumberWithErrors("pickListSequence")}
                readOnly={readOnly}
                label="rawMaterial.detail.pickListSequence_input"
                className="pickListSequence"
            />
            <LabeledCheckbox
                {...form.form.register("printNameMandatory")}
                readOnly={readOnly}
                label={"rawMaterial.detail.printNameMandatory_input"}
                id="printNameMandatory"
                className="printNameMandatory"
            />
            <CurrentLanguageWatchField
                form={form}
                field={"name"}
                label="rawMaterial.detail.name_input"
                className="name"
            />
            <TypeOfMaterialPicklist
                control={form.form.control}
                readOnly={readOnly}
                name="typeOfMaterial"
                className="typeOfMaterial"
                label="rawMaterial.detail.typeOfMaterial_input"
            />
            <ControlledDatePicker
                control={form.form.control}
                readOnly={readOnly}
                name="startDate"
                label="rawMaterial.detail.startDate_input"
                className="startDate"
            />
            <ControlledDatePicker
                control={form.form.control}
                readOnly={readOnly}
                name="endDate"
                label="rawMaterial.detail.endDate_input"
                className="endDate"
            />
            {subListVisible && <SubList {...allowedMaterialListOptions} className="allowedMaterial" />}
        </div>
    );
}

export default RawMaterialBasicData;
