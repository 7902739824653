import { SortOption } from "../components/grid/component/useQueryBuilder";
import { useMemo } from "react";
import { GridColumn } from "../components/grid/component/Grid";
import useListView from "../hooks/useListView";
import { useDeleteAction, useShowDetailsAction } from "../utils/defaultActions";
import { buildDateColumn, buildTextColumn } from "../components/grid/ColumnProvider";
import useResolvedRoute from "../components/routing/useResolvedRoute";
import { ScoLicenceDetails, ScoLicenceSummary } from "./scoLicenceTypes";
import { EntityListViewGridProps } from "../components/entity/EntityListView";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";

export const defaultScoLicenceSortOption: SortOption<ScoLicenceSummary | ScoLicenceDetails> = {
    field: "standardOfficialName",
    direction: "ASC",
};

const columns: GridColumn<ScoLicenceSummary>[] = [
    buildTextColumn({ key: "standardOfficialName", title: "scoLicence.list.standard_column", minWidth: 126 }),
    buildTextColumn({
        key: "supplyChainOperatorBusinessName",
        title: "scoLicence.list.supplyChainOperator_column",
        minWidth: 235,
    }),
    buildTextColumn({ key: "licenceNumber", title: "scoLicence.list.licenceNumber_column", minWidth: 179 }),
    buildDateColumn({
        key: "startDate",
        title: "scoLicence.list.startDate_column",
        minWidth: 130,
    }),
    buildDateColumn({
        key: "endDate",
        title: "scoLicence.list.endDate_column",
        minWidth: 124,
    }),
    buildDateColumn({
        key: "bannedUntil",
        title: "scoLicence.list.bannedUntil_column",
        minWidth: 155,
    }),
];

export default function useScoLicenceListView() {
    const { getResolvedEditUrl } = useResolvedRoute();

    const {
        queryBuilder: { forceReload, columnOptions, query, queryCommands },
    } = useListView<ScoLicenceSummary>({
        navigationContextLabel: "scoLicence.breadcrumb",
        baseQuery: "/certificate-service/sco-licences",
        defaultSortOption: defaultScoLicenceSortOption,
    });

    const showDetailsAction = useShowDetailsAction(
        (item: ScoLicenceSummary) => getResolvedEditUrl("scoLicenceNumber", item.id),
        query.parameter
    );

    const deleteAction = useDeleteAction(
        (item: ScoLicenceSummary) => `/certificate-service/sco-licences/${item.id}`,
        () => forceReload(),
        "deleteCertificationLevelOrganisation"
    );

    const actions = useGridRowContextMenuActions(showDetailsAction, deleteAction);

    return useMemo<EntityListViewGridProps<ScoLicenceSummary>>(() => {
        return {
            query,
            columns,
            uniqueDataKey: "id",
            onItemDoubleClick: showDetailsAction,
            createUrl: undefined,
            tableName: "scoLicences",
            columnOptions,
            itemActions: actions,
            queryCommands,
        };
    }, [query, showDetailsAction, columnOptions, actions, queryCommands]);
}
