import { asUTC } from "./dateUtils";

export function pad(number: number, places: number = 2) {
    return (number + "").padStart(places, "0");
}

export type Precision = "ms" | "m";

const formatToIso = (date: string | Date, precision: Precision = "ms") => {
    if (date === undefined || date === null) return null;
    // convert string to UTC date
    if (!(date instanceof Date) && !date.endsWith("Z")) date = date + "Z";

    const _p = precision === "ms" ? 0 : 2;

    const dateToFormat = new Date(date);
    return (
        dateToFormat.getFullYear() +
        "-" +
        pad(dateToFormat.getMonth() + 1) +
        "-" +
        pad(dateToFormat.getDate()) +
        " " +
        pad(dateToFormat.getHours()) +
        ":" +
        pad(dateToFormat.getMinutes()) +
        (_p < 2 ? ":" + pad(dateToFormat.getSeconds()) : "") +
        (_p === 0 ? "." + (dateToFormat.getMilliseconds() / 1000).toFixed(3).slice(2, 5) : "")
    );
};
export const formatTimestamp = (date: any, precision: Precision = "ms") => formatToIso(date, precision);

export const formatDate = (date?: Date | string) => {
    const dateToFormat = asUTC(date);
    if (!dateToFormat) {
        return null;
    }
    if (dateToFormat.toString() === "Invalid") {
        return null;
    }
    return `${dateToFormat.getFullYear()}-${pad(dateToFormat.getMonth() + 1)}-${pad(dateToFormat.getDate())}`;
};

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 *
 * @return Formatted string.
 */
export function humanReadableFileSize(bytes: number) {
    const thresh = 1024;
    const decimalPlaces = 1;

    if (Math.abs(bytes) < thresh) {
        return bytes + " B";
    }

    const units = ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    let u = -1;
    const r = 10 ** decimalPlaces;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    let size = bytes.toFixed(decimalPlaces);
    size = size.endsWith("0") ? size.slice(0, -2) : size;

    return size + " " + units[u];
}

/**
 * Prints a given number string formatted as a hex string.
 * If the given string cannot be parsed, it returns it unchanged.
 *
 * @param numberStr the string to format
 *
 * @return the formatted string
 */
export function formatToHex(numberStr: string) {
    if (isNaN(Number(numberStr))) {
        return numberStr;
    }

    return BigInt(numberStr).toString(16).toUpperCase();
}
