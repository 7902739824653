import RelationConfirmationView from "../external/RelationConfirmationView";

export default function CertificationRelationConfirmationView() {
    return (
        <RelationConfirmationView
            relationRoute={"certificationRelationConfirmation"}
            backendUrlBuilder={(certificationRelationId) =>
                `/certificate-service/certification-relations/${certificationRelationId}/confirmation`
            }
        />
    );
}
