import DropdownItem from "../../dropdown/DropdownItem";
import { ComponentType, useMemo } from "react";
import { GridProps } from "../DataGrid";
import DropdownComponent from "../../DropdownComponent";
import useIsAuthorised, { AccessRightKey } from "../../../auth/useIsAuthorised";
import useBulkDelete from "./useBulkDelete";
import useBulkExport from "./useBulkExport";
import { Entity, RequireInheritedProps } from "../../../utils/typeUtils";
import useGtbTranslation from "../../../i18n/useGtbTranslation";

export default function withBulkActions<ItemType extends Entity>(
    Grid: ComponentType<GridProps<ItemType> & WithBulkActionProps<ItemType>>
) {
    return ({
        columns,
        gridControlsLeft,
        queryCommands,
        total,
        query,
        bulkDeleteAccessRight,
        ...rest
    }: GridProps<ItemType> & WithBulkActionProps<ItemType>) => {
        const translation = useGtbTranslation();
        const handleExportXLSX = useBulkExport({ query, total, columns, type: "XLSX" });
        const handleExportCSV = useBulkExport({ query, total, columns, type: "CSV" });
        const handleDelete = useBulkDelete({ queryCommands, total, query });
        const { hasAccessRight } = useIsAuthorised();

        const isDeleteAllowed = useMemo(() => {
            if (bulkDeleteAccessRight instanceof Function) {
                return bulkDeleteAccessRight();
            } else if (bulkDeleteAccessRight) {
                return hasAccessRight(bulkDeleteAccessRight);
            }
        }, [bulkDeleteAccessRight, hasAccessRight]);

        return (
            <Grid
                total={total}
                query={query}
                gridControlsLeft={[
                    <DropdownComponent
                        key="bulkActionSelection"
                        label={"components.grid.bulkActions_menuTitle"}
                        className="medium-button"
                    >
                        <DropdownItem key="exportXLSXButton" onClick={handleExportXLSX}>
                            {translation("components.grid.exportExcel_bulkActionsMenuEntry")}
                        </DropdownItem>
                        <DropdownItem key="exportCSVButton" onClick={handleExportCSV}>
                            {translation("components.grid.exportCSV_bulkActionsMenuEntry")}
                        </DropdownItem>
                        {isDeleteAllowed && (
                            <DropdownItem key="deleteButton" className="deleteButton" onClick={handleDelete}>
                                {translation("components.grid.delete_bulkActionsMenuEntry")}
                            </DropdownItem>
                        )}
                    </DropdownComponent>,
                    ...(gridControlsLeft ?? []),
                ]}
                columns={columns}
                queryCommands={queryCommands}
                {...rest}
            />
        );
    };
}

export interface WithBulkActionProps<ItemType extends Entity>
    extends RequireInheritedProps<GridProps<ItemType>, "total" | "queryCommands" | "query"> {
    bulkDeleteAccessRight?: AccessRightKey | Function;
}

export interface WithBulkActionAfterDataProps<ItemType extends Entity>
    extends Omit<WithBulkActionProps<ItemType>, "total"> {}
