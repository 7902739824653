import { FieldValues } from "react-hook-form/dist/types";
import { joinClassNames } from "../../../../utils/StringUtils";
import { PickListItemPresentationProps } from "../GtbPickList";
import { ScFacilityReference } from "../../../../scopeCertificate/details/products/details/processCategories/details/facilities/scFacilityTypes";
import { DependentPicklist, DependentPicklistProps } from "./DependentPicklist";

interface ScFacilityPicklistProps<FormItemType extends FieldValues>
    extends DependentPicklistProps<FormItemType, ScFacilityReference> {
    flags?: ScFacilityPickListFlags;
}

export interface ScFacilityPickListFlags {
    scProductId?: string;
}

export const scFacilityPickListCombinedClassName = (className?: string) =>
    joinClassNames("scFacilityPickList", className);
export const scFacilityPickListItemPresentation: PickListItemPresentationProps<ScFacilityReference> = {
    itemId: "id",
    itemLabel: (option) =>
        `${option.scProcessCategoryName} - ${option.facilityBusinessName} (${option.scopeCertificateSupplyChainOperatorBusinessName})`,
};

export default function DependentScFacilityPickList<FormItemType extends FieldValues>({
    className,
    ...rest
}: ScFacilityPicklistProps<FormItemType>) {
    return (
        <DependentPicklist
            {...scFacilityPickListItemPresentation}
            urlBuilder={(scProductId) => ({
                url: "/certificate-service/references/sc-facilities",
                flags: { scProductId },
            })}
            className={scFacilityPickListCombinedClassName(className)}
            {...rest}
        />
    );
}
