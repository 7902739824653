import useCertificationBodyListView from "./useCertificationBodyListView";
import { useHelpContext } from "../help/context-sensitivity/useHelpContext";
import EntityListView from "../components/entity/EntityListView";

export default function CertificationBodyListView() {
    const certificationBodyGridOptions = useCertificationBodyListView();
    useHelpContext("certification-body-list");

    return <EntityListView heading="certificationBody.list.header" gridOptions={certificationBodyGridOptions} />;
}
