import RelationConfirmationView from "../external/RelationConfirmationView";

export default function BuyingRelationConfirmationView() {
    return (
        <RelationConfirmationView
            relationRoute={"buyingRelationConfirmation"}
            backendUrlBuilder={(buyingRelationId) =>
                `/certificate-service/buying-relations/${buyingRelationId}/confirmation`
            }
        />
    );
}
