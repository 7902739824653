import axios, { AxiosRequestHeaders, AxiosRequestTransformer } from "axios";
import { useCallback, useEffect, useReducer, useRef } from "react";
import { useGtbNavigate } from "../components/routing/GtbRouter";
import { env } from "../env";
import { getBaseUrl } from "../components/routing/useResolvedRoute";
import { reloadWindow } from "../utils/windowFunctions";

export function useQuery<T>({ url, enabled = true, onSuccess, onError }: UseQueryProps<T>) {
    const request = useAxios<T>({ method: "get", url, enabled, onSuccess, onError });

    return {
        ...request,
    };
}

export function useMutation<T>({ method, url, enabled = false, onSuccess, onError }: UseMutationProps<T>) {
    const request = useAxios<T>({ method: method, url, enabled, onSuccess, onError });

    return {
        ...request,
    };
}

const createRequestReducer =
    <T>() =>
    (state: UseAxiosState<T>, action: UseAxiosAction<T>): UseAxiosState<T> => {
        const { type, payload } = action;
        switch (type) {
            case "start":
                return { ...state, status: "loading" };
            case "success":
                return { ...state, status: "success", data: payload as T, error: null };
            case "error":
                return { ...state, status: "error", error: payload as UseAxiosError };
        }
    };

export function toUtcStringWithoutTimezoneConversion(date: Date) {
    const pad = function (num: number, places: number = 2) {
        return (num + "").padStart(places, "0");
    };

    return (
        date.getFullYear() +
        "-" +
        pad(date.getMonth() + 1) +
        "-" +
        pad(date.getDate()) +
        "T" +
        pad(date.getHours()) +
        ":" +
        pad(date.getMinutes()) +
        ":" +
        pad(date.getSeconds()) +
        "." +
        pad(date.getMilliseconds(), 3) +
        "Z"
    );
}

function dateTransformer(data: any, headers: AxiosRequestHeaders): any {
    if (data instanceof Date) {
        return toUtcStringWithoutTimezoneConversion(data);
    }
    if (Array.isArray(data)) {
        return data.map((val) => dateTransformer(val, headers));
    }
    if (typeof data === "object" && data !== null) {
        return Object.fromEntries(Object.entries(data).map(([key, val]) => [key, dateTransformer(val, headers)]));
    }
    return data;
}

const defaultTransformers = (): AxiosRequestTransformer[] => {
    const { transformRequest } = axios.defaults;
    if (!transformRequest) {
        return [];
    } else if (Array.isArray(transformRequest)) {
        return transformRequest;
    } else {
        return [transformRequest];
    }
};

const axiosInstance = axios.create({
    baseURL: env.REACT_APP_BACKEND_BASE_URL,
    transformRequest: [dateTransformer, ...defaultTransformers()],
});

export const setAuthorizationHeader = (jwt?: string) => {
    setHeader("Authorization", jwt ? "Bearer " + jwt : undefined);
};

export const setLanguageHeader = (language?: string) => {
    setHeader("Accept-Language", language);
};

const setHeader = (key: string, value?: string) => {
    if (value) {
        axiosInstance.defaults.headers.common[key] = value;
    } else {
        delete axiosInstance.defaults.headers.common[key];
    }
};

function useAxios<T>({ method, url, body, enabled, onSuccess, onError }: UseAxiosProps<T>) {
    const navigate = useRef(useGtbNavigate(true));
    const [state, dispatch] = useReducer(createRequestReducer<T>(), {
        status: enabled ? "loading" : "idle",
        data: undefined,
        error: null,
    });
    const abortControllerRef = useRef(new AbortController());

    useEffect(() => {
        const controller = abortControllerRef.current;
        return () => {
            controller.abort();
        };
    }, []);

    const runQuery = useCallback(
        async ({
            url: _url = url,
            body: _body,
            defaultForbiddenHandler = true,
            defaultServiceUnavailableHandler = true,
        }: {
            url?: backendUrlType;
            body?: {};
            defaultForbiddenHandler?: boolean;
            defaultServiceUnavailableHandler?: boolean;
        } = {}) => {
            dispatch({ type: "start" });
            return axiosInstance
                .request({ method, url: _url, data: _body ?? body, signal: abortControllerRef.current.signal })
                .then((resp) => {
                    const payload = resp.data;
                    onSuccess?.(payload, resp.status);
                    dispatch({ type: "success", payload });
                    return payload;
                })
                .catch((e) => {
                    if (axios.isCancel(e)) {
                        return {
                            then: () => {
                                /**/
                            },
                        };
                    }
                    const status = e?.response?.status;
                    if (defaultServiceUnavailableHandler && status && status >= 502 && status <= 504) {
                        // The reload triggers the DowntimeChecker to test the connection again
                        // and displays the 503 error page
                        reloadWindow();
                        return;
                    }
                    if (defaultForbiddenHandler && e?.response?.status === 403) {
                        navigate.current(getBaseUrl("errorForbidden"));
                        return;
                    }
                    const payload = { statusCode: e.response?.status, data: e.response?.data, completeError: e };
                    onError?.(payload);
                    dispatch({
                        type: "error",
                        payload,
                    });
                    throw payload;
                });
        },
        [url, method, body, onSuccess, onError]
    );

    useEffect(() => {
        if (enabled) {
            runQuery().catch(() => {
                /* do not do anything with the error.
                If runQuery gets called here, the error should be handled through state or through onError */
            });
        }
    }, [enabled, runQuery]);

    return {
        isIdle: state.status === "idle",
        isLoading: state.status === "loading",
        isSuccess: state.status === "success",
        isError: state.status === "error",
        runQuery,
        ...state,
    };
}

interface UseQueryProps<T> extends Omit<UseAxiosProps<T>, "method"> {}

interface UseMutationProps<T> extends Omit<UseAxiosProps<T>, "method"> {
    method: axiosMutationType;
}

export type useAxiosOnSuccess<T> = (data: T, statusCode?: number) => void;
export type useAxiosOnError = (error: UseAxiosError) => void;

export type axiosMutationType = "post" | "put" | "delete";
export type axiosQueryType = "get";

export type axiosMethodType = axiosMutationType | axiosQueryType;

export type backendUrlType = string;

export interface UseAxiosProps<T> {
    method: axiosMethodType;
    url: backendUrlType;
    body?: any;
    enabled?: boolean;
    onSuccess?: useAxiosOnSuccess<T>;
    onError?: useAxiosOnError;
}

export interface UseAxiosError {
    statusCode?: number;
    data: any;
    completeError: Error;
}

export type useAxiosStatus = "idle" | "loading" | "success" | "error";

interface UseAxiosState<T> {
    status: useAxiosStatus;
    data: T | undefined;
    error: null | UseAxiosError;
}

interface UseAxiosAction<T> {
    type: "start" | "success" | "error";
    payload?: T | UseAxiosError;
}
