import useProductDetailEdit from "./useProductDetailEdit";
import ProductDetailDetailView from "./ProductDetailDetailView";

function ProductDetailEditView() {
    const productDetailDetailProps = useProductDetailEdit();

    return <ProductDetailDetailView {...productDetailDetailProps} />;
}

export default ProductDetailEditView;
