import { yupResolver } from "@hookform/resolvers/yup";
import useIsAuthorised from "../../auth/useIsAuthorised";
import { useCallback, useMemo } from "react";
import useResolvedRoute from "../../components/routing/useResolvedRoute";
import useDomainEntityDetailsEdit from "../../hooks/details/useDomainEntityDetailsEdit";
import {
    PreGtbTransactionCertificateDetails,
    preGtbTransactionCertificateReadOnlyByStatus,
} from "../preGtbTransactionCertificateTypes";
import { defaultPreGtbTransactionCertificateSortOption } from "../usePreGtbTransactionCertificateListView";
import { preGtbTransactionCertificateValidationSchema } from "./preGtbTransactionCertificateValidationSchema";
import { PreGtbTransactionCertificateDetailViewProps } from "./PreGtbTransactionCertificateDetailView";
import useAuth from "../../auth/useAuth";
import useCheckForEqualCanonicalTcNumber from "./useCheckForEqualCanonicalTcNumber";
import useConformanceCheck from "../../conformance/useConformanceCheck";

export default function usePreGtbTransactionCertificateEdit(): PreGtbTransactionCertificateDetailViewProps {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedId } = useResolvedRoute();
    const preGtbTcId = getResolvedId("preGtbTransactionCertificate");
    const {
        currentUser: {
            authorisationRole: { type: currentUserOrganisationType },
        },
    } = useAuth();

    const checkForEqualCanonicalTcNumber = useCheckForEqualCanonicalTcNumber();
    const handleConformanceCheck = useConformanceCheck<PreGtbTransactionCertificateDetails>();

    const canSave = useCallback(
        (item: PreGtbTransactionCertificateDetails) => {
            if (item) {
                return hasAccessRight("updatePreGtbTc", [item.status]);
            }
            return false;
        },
        [hasAccessRight]
    );

    const { detailViewProps, initialData, form, isReadOnly, title, recordNavigationProps } =
        useDomainEntityDetailsEdit<PreGtbTransactionCertificateDetails>({
            baseUrl: "/certificate-service/pre-gtb-tcs/" + preGtbTcId,
            frontendRoute: "preGtbTransactionCertificate",
            recordNavigationDefaultSort: defaultPreGtbTransactionCertificateSortOption,
            titleBuilder: (item) => item.tcNumber,
            resolver: yupResolver(preGtbTransactionCertificateValidationSchema),
            canSave,
            afterSaveAction: checkForEqualCanonicalTcNumber,
            historyColumns: [
                { identifier: "tcNumber", title: "preGtbTransactionCertificate.history.tcNumber_column" },
                { identifier: "standard", title: "preGtbTransactionCertificate.history.standard_column" },
                {
                    identifier: "certificationBody",
                    title: "preGtbTransactionCertificate.history.certificationBody_column",
                },
                { identifier: "buyer", title: "preGtbTransactionCertificate.history.buyer_column" },
                {
                    identifier: "sellersLicenceNumber",
                    title: "preGtbTransactionCertificate.history.sellersLicenceNumber_column",
                },
                { identifier: "dateOfIssue", title: "preGtbTransactionCertificate.history.dateOfIssue_column" },
                { identifier: "status", title: "preGtbTransactionCertificate.history.status_column" },
            ],
            resolveErrors: (error, item, retrySaveAction) => handleConformanceCheck(error, item, retrySaveAction),
        });

    const preGtbTcStatus = useMemo<PreGtbTransactionCertificateDetails["status"] | undefined>(
        () => initialData?.status,
        [initialData?.status]
    );

    const readOnly = useMemo<PreGtbTransactionCertificateDetailViewProps["readOnly"]>(() => {
        const readOnlyByStatus = preGtbTransactionCertificateReadOnlyByStatus(preGtbTcStatus) || isReadOnly;
        return {
            id: true,
            status: isReadOnly,
            buyer: readOnlyByStatus,
            certificationBody: readOnlyByStatus,
            tcNumber: readOnlyByStatus,
            dateOfIssue: readOnlyByStatus,
            sellersLicenceNumber: readOnlyByStatus,
            standard: readOnlyByStatus,
        };
    }, [isReadOnly, preGtbTcStatus]);

    return {
        detailViewProps,
        form,
        preGtbTcStatus,
        subListVisible: true,
        readOnly,
        organisationType: currentUserOrganisationType,
        title,
        recordNavigationProps,
        preGtbTcId,
        createRoute: hasAccessRight("createPreGtbTc") ? "preGtbTransactionCertificate" : undefined,
        restrictBuyersToSelectedStandard: currentUserOrganisationType === "CERTIFICATION_BODY",
    };
}
