import useGtbTranslation, { I18nKey } from "../../i18n/useGtbTranslation";
import GtbButton from "../GtbButton";

export function DialogNoYesOptions({ onYes, onNo }: SpecialisedDialogOptionsProps) {
    return (
        <DialogOptions
            options={[
                { onClick: onNo, label: "components.dialog.no_button" },
                { onClick: onYes, label: "components.dialog.yes_button" },
            ]}
        />
    );
}

export function DialogYesNoOptions({ onYes, onNo }: SpecialisedDialogOptionsProps) {
    return (
        <DialogOptions
            options={[
                { onClick: onYes, label: "components.dialog.yes_button" },
                { onClick: onNo, label: "components.dialog.no_button" },
            ]}
        />
    );
}

export function DialogOptions({ options }: { options: DialogOptionsProps[] }) {
    const translation = useGtbTranslation();
    return (
        <>
            {options.map(({ label, onClick, disabled }, index, { length }) => (
                <GtbButton
                    key={label as string}
                    variant={index === length - 1 ? "primary" : "secondary"}
                    disabled={disabled}
                    onClick={onClick}
                >
                    {translation(label)}
                </GtbButton>
            ))}
        </>
    );
}

export interface SpecialisedDialogOptionsProps {
    onYes: () => void;
    onNo: () => void;
}

export interface DialogOptionsProps {
    onClick: () => void;
    label: I18nKey;
    disabled?: boolean;
}
