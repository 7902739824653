import {
    buildSchema,
    validateDate,
    validateNumber,
    validateString,
    validateTranslation,
} from "../../utils/yupExtension";
import { StateProvinceDetails } from "../stateProvinceTypes";

export const stateProvinceValidationSchema = buildSchema<StateProvinceDetails>({
    name: validateTranslation(50),
    isoCode: validateString().isRequired().hasMinLength(4).hasMaxLength(6),
    startDate: validateDate(),
    endDate: validateDate(),
    latitude: validateNumber().isRequired().hasMinValue(-90).hasMaxValue(90),
    longitude: validateNumber().isRequired().hasMinValue(-180).hasMaxValue(180),
});
