import { TypedDetailViewProps } from "../../components/detailView/DetailView";
import { UseGtbFormReturn } from "../../hooks/formHandling/useGtbForm";
import "../../components/spinner.css";
import { AccreditationBodyDetails } from "../accreditationBodyTypes";
import AccreditationBodyBasicData from "../../accreditationBody/details/AccreditationBodyBasicData";
import { useHelpContext } from "../../help/context-sensitivity/useHelpContext";
import EntityDetailView from "../../components/entity/EntityDetailView";
import Tabs, { TabKey } from "../../components/tabs/Tabs";
import { OrganisationReadOnlyProps } from "../../organisation/details/organisationDetailViewProps";
import OrganisationAddressData from "../../organisation/details/OrganisationAddressData";

export default function AccreditationBodyDetailView({
    detailViewProps,
    form,
    readOnlyProps,
    ...rest
}: AccreditationBodyDetailViewProps) {
    useHelpContext("accreditation-body-details");
    return (
        <EntityDetailView {...rest} detailViewProps={detailViewProps} form={form}>
            <Tabs<
                AccreditationBodyDetails,
                TabKey<"accreditationBody.detail.basicData_tab" | "accreditationBody.detail.addressData_tab">
            >
                tabs={[
                    {
                        heading: "accreditationBody.detail.basicData_tab",
                        element: <AccreditationBodyBasicData form={form} readOnlyProps={readOnlyProps} />,
                    },
                    {
                        heading: "accreditationBody.detail.addressData_tab",
                        element: (
                            <OrganisationAddressData
                                form={form}
                                readOnlyProps={readOnlyProps}
                                organisationI18nKey={"accreditationBody"}
                            />
                        ),
                    },
                ]}
                errors={form.form.formState.errors}
                errorToTab={{
                    id: "internal",
                    countryArea: "accreditationBody.detail.addressData_tab",
                    stateProvince: "accreditationBody.detail.addressData_tab",
                    systemId: "internal",
                    businessName: "accreditationBody.detail.basicData_tab",
                    website: "accreditationBody.detail.basicData_tab",
                    organisationalEmail: "accreditationBody.detail.basicData_tab",
                    organisationalPhoneNumber: "accreditationBody.detail.basicData_tab",
                    startDate: "accreditationBody.detail.basicData_tab",
                    endDate: "accreditationBody.detail.basicData_tab",
                    town: "accreditationBody.detail.addressData_tab",
                    postCode: "accreditationBody.detail.addressData_tab",
                    addressDetails1: "accreditationBody.detail.addressData_tab",
                    addressDetails2: "accreditationBody.detail.addressData_tab",
                    addressDetails3: "accreditationBody.detail.addressData_tab",
                    latitude: "accreditationBody.detail.addressData_tab",
                    longitude: "accreditationBody.detail.addressData_tab",
                    organisationStatus: "accreditationBody.detail.basicData_tab",
                    legalName: "accreditationBody.detail.basicData_tab",
                }}
            />
        </EntityDetailView>
    );
}

export interface AccreditationBodyDetailViewProps extends TypedDetailViewProps<AccreditationBodyDetails> {
    readOnlyProps: AccreditationBodyReadOnlyProps;
}

export interface AccreditationBodyDetailViewTabProps {
    form: UseGtbFormReturn<AccreditationBodyDetails>;
    readOnlyProps: AccreditationBodyReadOnlyProps;
}

export interface AccreditationBodyReadOnlyProps extends OrganisationReadOnlyProps {
    isLegalNameReadOnly: boolean;
}
