import { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useState } from "react";

export default function makeStore<StateType>(
    initialState: StateType
): [FC<{ children: ReactNode }>, () => Dispatch<SetStateAction<StateType>>, () => StateType] {
    const setStoreContext = createContext<Dispatch<SetStateAction<StateType>>>(() => {
        /* has to have default value */
    });
    const storeContext = createContext<StateType>(initialState);

    const StoreProvider = ({ children }: { children: ReactNode }) => {
        const [store, setStore] = useState(initialState);

        return (
            <setStoreContext.Provider value={setStore}>
                <storeContext.Provider value={store}>{children}</storeContext.Provider>
            </setStoreContext.Provider>
        );
    };

    function useSetStore() {
        return useContext(setStoreContext);
    }

    function useStore() {
        return useContext(storeContext);
    }

    return [StoreProvider, useSetStore, useStore];
}
