import { ReactNode } from "react";
import "./authorisationTypeComponent.css";
import useGtbTranslation, { I18nKey } from "../../../i18n/useGtbTranslation";

function AuthorisationTypeComponent({ label, children }: { label: I18nKey; children: ReactNode }) {
    const translation = useGtbTranslation();

    return (
        <section className="authorisationType">
            <h2>{translation(label)}</h2>
            {children}
        </section>
    );
}

export default AuthorisationTypeComponent;
