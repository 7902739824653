import { Id, toast, ToastOptions } from "react-toastify";
import "./notification.css";
import { joinClassNames } from "../StringUtils";

export const info: NotificationMessage = (content) => {
    notification(content, 5, { className: "info-notification" });
};

export const warning: NotificationMessage = (content) => {
    notification(content, 15, { className: "warning-notification" });
};

export const error: NotificationMessage = (content) => {
    notification(content, 15, { className: "error-notification" });
};

const notification: Notification = (content, closeAfterSeconds, options) => {
    const classNames = joinClassNames(options?.className as string, "notification");
    return toast(content, {
        ...options,
        className: classNames,
        autoClose: closeAfterSeconds ? closeAfterSeconds * 1000 : false,
        progressClassName: "notification-progress",
        closeOnClick: false,
    });
};

interface NotificationMessage {
    (content: string): void;
}

interface NotificationOptions extends Omit<ToastOptions, "autoClose" | "progressClassName" | "closeOnClick"> {}

interface Notification {
    (content: string, closeAfterSeconds: number | false, options?: NotificationOptions): Id;
}
