import { HelpPageSummary } from "../helpPageTypes";
import GtbLink from "../../components/routing/GtbLink";
import { useParams } from "react-router-dom";
import { useCallback } from "react";
import Icon from "../../components/Icon";
import { getBaseUrl } from "../../components/routing/useResolvedRoute";
import GtbNav from "../../components/routing/GtbNav";

export default function HelpViewNavigation({ className, helpPages }: HelpViewNavigationProps) {
    const { "*": helpPageIdentifier } = useParams();

    const isSelected = useCallback(
        (entry: HelpPageSummary) => helpPageIdentifier === entry.affiliation,
        [helpPageIdentifier]
    );

    return (
        <GtbNav aria-label="help.navigation_accessibleLabel" className={className}>
            <ul>
                {helpPages?.map((entry) => {
                    return (
                        <li className="help-navigation-item" key={entry.affiliation}>
                            <div className="icon-wrapper">
                                {isSelected(entry) && (
                                    <Icon
                                        name={"chevron-right"}
                                        size={12}
                                        className={"selected-indicator-icon activeHelpPageIcon-left"}
                                    />
                                )}
                                <GtbLink
                                    className={
                                        isSelected(entry) ? "help-navigation-link-selected" : "help-navigation-link"
                                    }
                                    to={getBaseUrl("help") + "/" + encodeURIComponent(entry.affiliation)}
                                >
                                    {entry.title}
                                </GtbLink>
                                {isSelected(entry) && (
                                    <Icon
                                        name={"chevron-left"}
                                        size={12}
                                        className={"selected-indicator-icon activeHelpPageIcon-right"}
                                    />
                                )}
                            </div>
                        </li>
                    );
                })}
            </ul>
        </GtbNav>
    );
}

export interface HelpViewNavigationProps {
    className?: string;
    helpPages: HelpPageSummary[];
}
