import { buildDateColumn, buildNumberColumn, buildTextColumn } from "../components/grid/ColumnProvider";
import { StateProvinceDetails, StateProvinceSummary } from "./stateProvinceTypes";
import { useMemo } from "react";
import useIsAuthorised from "../auth/useIsAuthorised";
import { useDeleteAction, useShowDetailsAction } from "../utils/defaultActions";
import { SortOption, useQueryBuilder } from "../components/grid/component/useQueryBuilder";
import { GridColumn } from "../components/grid/component/Grid";
import useResolvedRoute from "../components/routing/useResolvedRoute";
import { SubListProps } from "../components/grid/SubList";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";

export const defaultStateProvinceSortOption: SortOption<StateProvinceSummary | StateProvinceDetails> = {
    field: "isoCode",
    direction: "ASC",
};

const columns: GridColumn<StateProvinceSummary>[] = [
    buildTextColumn({ key: "isoCode", title: "stateProvince.list.isoCode_column", minWidth: 126 }),
    buildTextColumn({ key: "name", title: "stateProvince.list.stateProvinceName_column", minWidth: 213 }),
    buildDateColumn({ key: "endDate", title: "stateProvince.list.endDate_column", minWidth: 124, visible: false }),
    buildDateColumn({ key: "startDate", title: "stateProvince.list.startDate_column", minWidth: 130, visible: false }),
    buildNumberColumn({
        key: "latitude",
        title: "stateProvince.list.latitude_column",
        allowDecimals: true,
        allowNegative: true,
        minWidth: 119,
        visible: false,
    }),
    buildNumberColumn({
        key: "longitude",
        title: "stateProvince.list.longitude_column",
        allowDecimals: true,
        allowNegative: true,
        minWidth: 134,
        visible: false,
    }),
];

function useStateProvinceSubList() {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedId, getResolvedEditUrl, getResolvedCreateUrl } = useResolvedRoute();

    const { query, columnOptions, forceReload } = useQueryBuilder<StateProvinceSummary>(
        `/certificate-service/countries/${getResolvedId("countryArea")}/states-provinces`,
        {
            sort: defaultStateProvinceSortOption,
            filterActive: false,
        }
    );

    const showDetailsAction = useShowDetailsAction(
        (item: StateProvinceSummary) => getResolvedEditUrl("stateProvince", item.id),
        query.parameter
    );

    const deleteAction = useDeleteAction(
        (item: StateProvinceSummary) =>
            `/certificate-service/countries/${getResolvedId("countryArea")}/states-provinces/${item.id}`,
        () => forceReload(),
        "deleteMasterData"
    );

    const contextMenuActions = useGridRowContextMenuActions(showDetailsAction, deleteAction);

    return useMemo<SubListProps<StateProvinceSummary>>(
        () => ({
            query,
            columns,
            uniqueDataKey: "id" as keyof StateProvinceSummary,
            onItemDoubleClick: showDetailsAction,
            header: "stateProvince.list.header",
            itemActions: contextMenuActions,
            columnOptions,
            createUrl: hasAccessRight("createMasterData") ? getResolvedCreateUrl("stateProvince") : undefined,
            tableName: "statesProvinces",
        }),
        [columnOptions, contextMenuActions, getResolvedCreateUrl, hasAccessRight, query, showDetailsAction]
    );
}

export default useStateProvinceSubList;
