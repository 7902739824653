import { UseGtbFormReturn } from "../../../hooks/formHandling/useGtbForm";
import { VersionDetails } from "../versionTypes";
import { joinClassNames } from "../../../utils/StringUtils";
import GtbTextField from "../../../components/Input/GtbTextField";
import ControlledDatePicker from "../../../components/Input/datepicker/ControlledDatePicker";
import GtbNumberField from "../../../components/Input/GtbNumberField";
import "./versionBasicData.css";
import SubList from "../../../components/grid/SubList";
import ControlledImageUploadComponent from "../../../components/Input/UploadComponent/ControlledImageUploadComponent";
import useLabelGradeSubList from "../../../labelGrade/useLabelGradeSubList";
import useGtbTranslation from "../../../i18n/useGtbTranslation";

function VersionBasicData({
    form,
    readOnly,
    versionNumberReadOnly,
    standard,
    logoUrl,
    labelGradesVisible,
}: VersionBasicDataProps) {
    const translations = useGtbTranslation();
    const labelGradeListOptions = useLabelGradeSubList();

    return (
        <div className={joinClassNames("versionBasicData", "formGrid")}>
            <GtbTextField
                {...form.registerWithErrors("versionNumber")}
                readOnly={readOnly || versionNumberReadOnly}
                label="standardVersion.detail.versionNumber_input"
                className="versionNumber"
            />
            <GtbTextField
                value={standard}
                readOnly={true}
                label="standardVersion.detail.standard_input"
                className="standard"
            />
            <ControlledImageUploadComponent
                label="standardVersion.detail.logo_input"
                imageUrl={logoUrl}
                readOnly={readOnly}
                control={form.form.control}
                name="logo"
                className="logo"
            />
            <ControlledDatePicker
                control={form.form.control}
                readOnly={readOnly}
                name="startDate"
                label="standardVersion.detail.startDate_input"
                className="startDate"
            />
            <ControlledDatePicker
                control={form.form.control}
                readOnly={readOnly}
                name="endDate"
                label="standardVersion.detail.endDate_input"
                className="endDate"
            />
            <GtbNumberField
                {...form.registerNumberWithErrors("defaultValidityPeriod")}
                readOnly={readOnly}
                label="standardVersion.detail.defaultValidityPeriod_input"
                unit={translations("standardVersion.detail.months_unit")}
                className="defaultValidityPeriod"
            />
            <GtbNumberField
                {...form.registerNumberWithErrors("recertificationPeriod")}
                readOnly={readOnly}
                label="standardVersion.detail.recertificationPeriod_input"
                unit={translations("standardVersion.detail.days_unit")}
                className="recertificationPeriod"
            />
            <GtbNumberField
                {...form.registerNumberWithErrors("recertificationBindingPeriod")}
                readOnly={readOnly}
                label="standardVersion.detail.recertificationBindingPeriod_input"
                unit={translations("standardVersion.detail.days_unit")}
                className="recertificationBindingPeriod"
            />

            {labelGradesVisible && <SubList {...labelGradeListOptions} className="labelGrades" />}
        </div>
    );
}

export default VersionBasicData;

interface VersionBasicDataProps {
    form: UseGtbFormReturn<VersionDetails>;
    readOnly: boolean;
    versionNumberReadOnly: boolean;
    standard: string;
    labelGradesVisible: boolean;
    logoUrl: string;
}
