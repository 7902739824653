import useProcessCategoryCreate from "./useProcessCategoryCreate";
import ProcessCategoryDetailView from "./ProcessCategoryDetailView";

function ProcessCategoryCreateView() {
    const processCategoryDetailProps = useProcessCategoryCreate();

    return <ProcessCategoryDetailView {...processCategoryDetailProps} />;
}

export default ProcessCategoryCreateView;
