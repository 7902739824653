import { yupResolver } from "@hookform/resolvers/yup";
import useIsAuthorised from "../../auth/useIsAuthorised";
import { StandardBodyDetails } from "../standardBodyTypes";
import { standardBodyValidationSchema } from "./standardBodyValidationSchema";
import { defaultStandardBodySortOption } from "../useStandardBodyListView";
import { StandardBodyReadOnlyProps } from "./StandardBodyDetailView";
import { useCallback, useMemo } from "react";
import useResolvedRoute, { routeEnum } from "../../components/routing/useResolvedRoute";
import useGtbTranslation from "../../i18n/useGtbTranslation";
import useDomainEntityDetailsEdit from "../../hooks/details/useDomainEntityDetailsEdit";
import { useOrganisationDefaultReadOnlyProps } from "../../organisation/details/organisationDetailViewProps";

function useStandardBodyEdit() {
    const translation = useGtbTranslation();
    const { hasAccessRight, isRoleLevelAtLeast } = useIsAuthorised();
    const { getResolvedId } = useResolvedRoute();
    const standardBodyId = getResolvedId("standardBody");

    const canSave = useCallback(
        (item: StandardBodyDetails) => {
            if (item) {
                return hasAccessRight("updateStandardBody", [item.organisationStatus]);
            }
            return false;
        },
        [hasAccessRight]
    );

    const tlsTabVisible = useMemo(() => isRoleLevelAtLeast(2), [isRoleLevelAtLeast]);

    const { detailViewProps, form, initialData, isReadOnly, title, recordNavigationProps } =
        useDomainEntityDetailsEdit<StandardBodyDetails>({
            baseUrl: "/certificate-service/standard-bodies/" + standardBodyId,
            frontendRoute: "standardBody",
            recordNavigationDefaultSort: defaultStandardBodySortOption,
            titleBuilder: (item) => item.businessName,
            resolver: yupResolver(standardBodyValidationSchema),
            canSave,
            historyColumns: [
                { identifier: "systemId", title: "standardBody.history.systemId_column" },
                { identifier: "legalName", title: "standardBody.history.legalName_column" },
                { identifier: "businessName", title: "standardBody.history.businessName_column" },
                { identifier: "addressDetails1", title: "standardBody.history.addressDetail1_column" },
                { identifier: "addressDetails2", title: "standardBody.history.addressDetail2_column" },
                { identifier: "addressDetails3", title: "standardBody.history.addressDetail3_column" },
                { identifier: "latitude", title: "standardBody.history.latitude_column" },
                { identifier: "longitude", title: "standardBody.history.longitude_column" },
                { identifier: "postCode", title: "standardBody.history.postcode_column" },
                { identifier: "town", title: "standardBody.history.town_column" },
                { identifier: "stateProvince", title: "standardBody.history.stateProvince_column" },
                {
                    identifier: "organisationalEmail",
                    title: "standardBody.history.organisationalEmailAddress_column",
                },
                { identifier: "website", title: "standardBody.history.website_column" },
                {
                    identifier: "organisationalPhoneNumber",
                    title: "standardBody.history.organisationalPhoneNumber_column",
                },
                { identifier: "organisationStatus", title: "standardBody.history.status_column" },
                { identifier: "startDate", title: "standardBody.history.startDate_column" },
                { identifier: "endDate", title: "standardBody.history.endDate_column" },
            ],
        });

    const defaultReadOnlyProps = useOrganisationDefaultReadOnlyProps(isReadOnly);

    const readOnlyProps = useMemo<StandardBodyReadOnlyProps>(
        () => ({
            ...defaultReadOnlyProps,
            isLogoReadOnly: isReadOnly,
        }),
        [defaultReadOnlyProps, isReadOnly]
    );

    const createRoute = useMemo<routeEnum | undefined>(() => {
        return hasAccessRight("createStandardBody") ? "standardBody" : undefined;
    }, [hasAccessRight]);

    return {
        detailViewProps,
        form,
        readOnlyProps,
        logoUrl: `/certificate-service/standard-bodies/${standardBodyId}/logo`,
        title,
        subtitle: translation({
            key: "standardBody.detail.subtitle",
            options: { systemId: initialData?.["systemId"] },
        }),
        recordNavigationProps,
        createRoute,
        tlsTabVisible,
    };
}

export default useStandardBodyEdit;
