import { buildSchema, validateString } from "../../../../utils/yupExtension";
import { ScProductDetails } from "../scProductTypes";

export const buildScProductValidationSchema = (labelGradeRequired: boolean) =>
    buildSchema<ScProductDetails>({
        cbScProductReferenceNumber: validateString().hasMaxLength(30),
        productCategory: validateString().isRequired(),
        productCategoryPrint: validateString().hasMaxLength(50).isRequired(),
        productDetail: validateString().isRequired(),
        productDetailPrint: validateString().hasMaxLength(50).isRequired(),
        labelGrade: labelGradeRequired ? validateString().isRequired() : validateString(),
    });
