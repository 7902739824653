import { yupResolver } from "@hookform/resolvers/yup";
import { timeBasedMailValidationSchema } from "./timeBasedMailValidationSchema";
import { TimeBasedMailDetails } from "../timeBasedMailTypes";
import { TimeBasedMailDetailViewProps } from "./TimeBasedMailDetailView";
import useResolvedRoute from "../../components/routing/useResolvedRoute";
import useDomainEntityDetailsEdit from "../../hooks/details/useDomainEntityDetailsEdit";
import { defaultTimeBasedMailSortOption } from "../useTimeBasedMailListView";
import useIsAuthorised from "../../auth/useIsAuthorised";
import useTargetDateTime from "./useTargetDateTime";

export default function useTimeBasedMailEdit(): TimeBasedMailDetailViewProps {
    const { getResolvedId } = useResolvedRoute();
    const timeBasedMailId = getResolvedId("timeBasedMail");
    const { hasAccessRight } = useIsAuthorised();

    const { detailViewProps, form, title, recordNavigationProps, isReadOnly, initialData } =
        useDomainEntityDetailsEdit<TimeBasedMailDetails>({
            baseUrl: "/certificate-service/time-based-mails/" + timeBasedMailId,
            resolver: yupResolver(timeBasedMailValidationSchema),
            titleBuilder: (item) => item.subject,
            canSave: (item) => !item.sent && hasAccessRight("updateTimeBasedMail"),
            frontendRoute: "timeBasedMail",
            historyColumns: [
                { identifier: "subject", title: "timeBasedMail.history.subject_column" },
                { identifier: "targetDateTime", title: "timeBasedMail.history.targetTime_column" },
                { identifier: "sent", title: "timeBasedMail.history.sent_column" },
                { identifier: "body", title: "timeBasedMail.history.body_column" },
                { identifier: "dynamicUserGroup", title: "timeBasedMail.history.userGroup_column" },
            ],
            recordNavigationDefaultSort: defaultTimeBasedMailSortOption,
        });

    useTargetDateTime(form, initialData);

    return {
        detailViewProps,
        form,
        title,
        recordNavigationProps,
        readOnly: isReadOnly,
        createRoute: "timeBasedMail",
    };
}
