import * as React from "react";
import { useCallback, useState } from "react";
import DialogHeader from "../components/dialog/DialogHeader";
import DialogFooter from "../components/dialog/DialogFooter";
import DialogContent from "../components/dialog/DialogContent";
import useGtbTranslation from "../i18n/useGtbTranslation";
import GtbButton from "../components/GtbButton";
import { Dialog } from "../components/dialog/useDialog";
import { useModal } from "../components/modal/ModalProvider";
import { OrganicMaterialProductionSummary } from "./organicMaterialProductionTypes";
import { LabeledCheckbox } from "../components/Input/Checkbox";
import "./useAddOrganicMaterialProductionDialog.css";

export default function useAddOrganicMaterialProductionDialog() {
    const { showModal: _showDialog, closeModal: closeDialog } = useModal();
    return useCallback(
        (
            potentialOrganicMaterialProductions: AddOrganicMaterialProductionDialogProps["potentialOrganicMaterialProductions"],
            acceptOrganicMaterialProductions: AddOrganicMaterialProductionDialogProps["acceptOrganicMaterialProductions"],
            onCreateOrganicMaterialProduction: AddOrganicMaterialProductionDialogProps["navigateToOrganicMaterialProductionCreate"]
        ) => {
            _showDialog(
                <AddOrganicMaterialProductionDialog
                    potentialOrganicMaterialProductions={potentialOrganicMaterialProductions}
                    closeDialog={closeDialog}
                    acceptOrganicMaterialProductions={acceptOrganicMaterialProductions}
                    navigateToOrganicMaterialProductionCreate={onCreateOrganicMaterialProduction}
                />
            );
        },
        [_showDialog, closeDialog]
    );
}

export interface AddOrganicMaterialProductionDialogProps {
    potentialOrganicMaterialProductions: OrganicMaterialProductionSummary[];
    closeDialog: Function;
    acceptOrganicMaterialProductions: (itemId: string[]) => void;
    navigateToOrganicMaterialProductionCreate: () => void;
}

function AddOrganicMaterialProductionDialog({
    potentialOrganicMaterialProductions,
    closeDialog,
    acceptOrganicMaterialProductions,
    navigateToOrganicMaterialProductionCreate,
}: AddOrganicMaterialProductionDialogProps) {
    const translation = useGtbTranslation();
    const [selectedOrganicMaterialProductions, setSelectedOrganicMaterialProductions] = useState(new Set<string>());
    const [addSelectedButtonEnabled, setAddSelectedButtonEnabled] = useState(false);

    const addSelectedOrganicMaterialProduction = useCallback((itemId: string) => {
        setSelectedOrganicMaterialProductions((prevState) => {
            prevState.add(itemId);
            setAddSelectedButtonEnabled(true);
            return prevState;
        });
    }, []);

    const deleteSelectedOrganicMaterialProduction = useCallback((itemId: string) => {
        setSelectedOrganicMaterialProductions((prevState) => {
            prevState.delete(itemId);
            setAddSelectedButtonEnabled(prevState.size > 0);
            return prevState;
        });
    }, []);

    return (
        <Dialog
            dialog={{
                header: (
                    <DialogHeader
                        title={"organicMaterialProductionSc.addOrganicMaterialProductionDialog.dialogTitle"}
                        variant="warning"
                        onClick={() => closeDialog()}
                    />
                ),
                content: (
                    <>
                        <DialogContent className="addOrganicMaterialProductionDialog">
                            {potentialOrganicMaterialProductions?.map((potentialOrganicMaterialProduction) => {
                                return (
                                    <LabeledCheckbox
                                        id={"potentialOrganicMaterialProduction"}
                                        label={{
                                            key: "organicMaterialProductionSc.addOrganicMaterialProductionDialog.organicMaterialProductionPresentation",
                                            options: {
                                                certifiedOrganicMaterialName:
                                                    potentialOrganicMaterialProduction.certifiedOrganicMaterialName,
                                                harvestYear: potentialOrganicMaterialProduction.harvestYear,
                                                town: potentialOrganicMaterialProduction.town,
                                                sizeOfProductionArea:
                                                    potentialOrganicMaterialProduction.sizeOfProductionArea,
                                                interpolation: { escapeValue: false },
                                            },
                                        }}
                                        className="organicMaterialProduction"
                                        onChange={(event) =>
                                            event.target.checked
                                                ? addSelectedOrganicMaterialProduction(
                                                      potentialOrganicMaterialProduction.id
                                                  )
                                                : deleteSelectedOrganicMaterialProduction(
                                                      potentialOrganicMaterialProduction.id
                                                  )
                                        }
                                        key={potentialOrganicMaterialProduction.id}
                                    />
                                );
                            })}
                        </DialogContent>
                        <div className="addOrganicMaterialProductionDialogMessage">
                            <span>
                                {translation(
                                    "organicMaterialProductionSc.addOrganicMaterialProductionDialog.dialogText"
                                )}
                            </span>
                        </div>
                    </>
                ),
                footer: (
                    <DialogFooter>
                        <GtbButton onClick={() => closeDialog()} variant="secondary">
                            {translation("components.dialog.cancel_button")}
                        </GtbButton>
                        <GtbButton
                            variant="secondary"
                            disabled={!addSelectedButtonEnabled}
                            onClick={() => {
                                closeDialog();
                                acceptOrganicMaterialProductions(
                                    Array.from(selectedOrganicMaterialProductions.values())
                                );
                            }}
                        >
                            {translation(
                                "organicMaterialProductionSc.addOrganicMaterialProductionDialog.addSelected_button"
                            )}
                        </GtbButton>
                        <GtbButton
                            onClick={() => {
                                closeDialog();
                                navigateToOrganicMaterialProductionCreate();
                            }}
                        >
                            {translation("organicMaterialProductionSc.addOrganicMaterialProductionDialog.new_button")}
                        </GtbButton>
                    </DialogFooter>
                ),
            }}
        />
    );
}
