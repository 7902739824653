import useStateProvinceCreate from "./useStateProvinceCreate";
import StateProvinceDetailView from "./StateProvinceDetailView";

function StateProvinceCreateView() {
    const stateProvinceDetailsProperties = useStateProvinceCreate();

    return <StateProvinceDetailView {...stateProvinceDetailsProperties} />;
}

export default StateProvinceCreateView;
