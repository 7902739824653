import useIsAuthorised from "../auth/useIsAuthorised";
import { SortOption } from "../components/grid/component/useQueryBuilder";
import { useMemo } from "react";
import { GridColumn } from "../components/grid/component/Grid";
import useListView from "../hooks/useListView";
import { useDeleteAction, useShowDetailsAction } from "../utils/defaultActions";
import { RawMaterialDetails, RawMaterialSummary } from "./rawMaterialTypes";
import {
    buildBooleanColumn,
    buildDataColumn,
    buildDateColumn,
    buildNumberColumn,
    buildTextColumn,
} from "../components/grid/ColumnProvider";
import { EntityListViewGridProps } from "../components/entity/EntityListView";
import useResolvedRoute, { getCreateUrl } from "../components/routing/useResolvedRoute";
import { EnumEntityType } from "../components/Input/pickList/typedEnumPicklist/enumEntityType";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";

export const defaultRawMaterialSortOption: SortOption<RawMaterialSummary | RawMaterialDetails> = {
    field: "code",
    direction: "ASC",
};

const columns: GridColumn<RawMaterialSummary>[] = [
    buildTextColumn({ key: "code", title: "rawMaterial.list.code_column", minWidth: 123 }),
    buildTextColumn({ key: "name", title: "rawMaterial.list.name_column", minWidth: 123 }),
    buildDataColumn<RawMaterialSummary, EnumEntityType>({
        key: "typeOfMaterial",
        title: "rawMaterial.list.typeOfMaterial_column",
        minWidth: 311,
        url: "/certificate-service/references/type-of-materials",
        itemId: "id",
        itemLabel: "name",
    }),
    buildNumberColumn({
        key: "pickListSequence",
        minWidth: 195,
        title: "rawMaterial.list.pickListSequence_column",
    }),
    buildBooleanColumn({
        key: "printNameMandatory",
        title: "rawMaterial.list.printNameMandatory_column",
        minWidth: 209,
        visible: false,
    }),
    buildDateColumn({
        key: "endDate",
        title: "rawMaterial.list.endDate_column",
        minWidth: 124,
        visible: false,
    }),
    buildDateColumn({
        key: "startDate",
        title: "rawMaterial.list.startDate_column",
        minWidth: 130,
        visible: false,
    }),
];

function useRawMaterialListView() {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedEditUrl } = useResolvedRoute();

    const {
        queryBuilder: { forceReload, columnOptions, query, queryCommands },
    } = useListView<RawMaterialSummary>({
        navigationContextLabel: "rawMaterial.breadcrumb",
        baseQuery: "/certificate-service/raw-materials",
        defaultSortOption: defaultRawMaterialSortOption,
    });

    const showDetailsAction = useShowDetailsAction(
        (item: RawMaterialSummary) => getResolvedEditUrl("rawMaterial", item.id),
        query.parameter
    );

    const deleteAction = useDeleteAction(
        (item: RawMaterialSummary) => `/certificate-service/raw-materials/${item.id}`,
        () => forceReload(),
        "deleteMasterData"
    );

    const actions = useGridRowContextMenuActions(showDetailsAction, deleteAction);

    return useMemo<EntityListViewGridProps<RawMaterialSummary>>(() => {
        return {
            query,
            columns: columns,
            uniqueDataKey: "id" as keyof RawMaterialSummary,
            onItemDoubleClick: showDetailsAction,
            createUrl: hasAccessRight("createMasterData") ? getCreateUrl("rawMaterial") : undefined,
            tableName: "rawMaterial",
            columnOptions,
            itemActions: actions,
            queryCommands,
            bulkDeleteAccessRight: "deleteMasterData",
        };
    }, [query, showDetailsAction, hasAccessRight, columnOptions, actions, queryCommands]);
}

export default useRawMaterialListView;
