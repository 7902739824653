import DataPickList from "../DataPickList";
import { CountryAreaReference } from "../../../../countryArea/countryAreaTypes";
import { TypedPicklist } from "./typedPicklistType";
import { joinClassNames } from "../../../../utils/StringUtils";

import "./typedPickList.css";
import { FieldValues } from "react-hook-form/dist/types";

function CountryAreaPickList<FormItemType extends FieldValues>({
    className,
    ...rest
}: TypedPicklist<FormItemType, CountryAreaReference>) {
    return (
        <DataPickList
            className={joinClassNames("countryAreaPickList", className)}
            itemId="id"
            itemLabel="name"
            queryUrl="/certificate-service/references/countries"
            {...rest}
        />
    );
}

export default CountryAreaPickList;
