import { useMemo } from "react";
import { buildBooleanColumn, buildDateTimeColumn, buildTextColumn } from "../components/grid/ColumnProvider";
import useListView from "../hooks/useListView";
import { useDeleteAction, useShowDetailsAction } from "../utils/defaultActions";
import { GridColumn } from "../components/grid/component/Grid";
import useResolvedRoute, { getCreateUrl } from "../components/routing/useResolvedRoute";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";
import { TimeBasedMailDetails, TimeBasedMailSummary } from "./timeBasedMailTypes";
import { SortOption } from "../components/grid/component/useQueryBuilder";
import useIsAuthorised from "../auth/useIsAuthorised";

const columns: GridColumn<TimeBasedMailSummary>[] = [
    buildTextColumn({ key: "subject", title: "timeBasedMail.list.subject_column", minWidth: 120 }),
    buildDateTimeColumn({
        key: "targetDateTime",
        title: "timeBasedMail.list.targetTime_column",
        minWidth: 145,
        precision: "m",
    }),
    buildTextColumn({ key: "dynamicUserGroupName", title: "timeBasedMail.list.userGroup_column", minWidth: 150 }),
    buildBooleanColumn({ key: "sent", title: "timeBasedMail.list.sent_column", minWidth: 100 }),
];

export const defaultTimeBasedMailSortOption: SortOption<TimeBasedMailSummary | TimeBasedMailDetails> = {
    field: "subject",
    direction: "ASC",
};

export default function useTimeBasedMailListView() {
    const { getResolvedEditUrl } = useResolvedRoute();
    const { hasAccessRight } = useIsAuthorised();

    const {
        queryBuilder: { query, columnOptions, queryCommands, forceReload },
    } = useListView<TimeBasedMailSummary>({
        navigationContextLabel: "timeBasedMail.breadcrumb",
        baseQuery: "/certificate-service/time-based-mails",
        defaultSortOption: defaultTimeBasedMailSortOption,
    });

    const showDetailsAction = useShowDetailsAction(
        (item: TimeBasedMailSummary) => getResolvedEditUrl("timeBasedMail", item.id),
        query.parameter
    );

    const deleteAction = useDeleteAction(
        (item: TimeBasedMailSummary) => `/certificate-service/time-based-mails/${item.id}`,
        forceReload,
        "deleteTimeBasedMail"
    );

    const contextMenuActions = useGridRowContextMenuActions(showDetailsAction, deleteAction);

    return useMemo(() => {
        return {
            uniqueDataKey: "id" as keyof TimeBasedMailSummary,
            query,
            columns,
            columnOptions,
            onItemDoubleClick: showDetailsAction,
            createUrl: hasAccessRight("createTimeBasedMail") ? getCreateUrl("timeBasedMail") : undefined,
            tableName: "timeBasedMail",
            itemActions: contextMenuActions,
            queryCommands,
            bulkDeleteAccessRight: () => hasAccessRight("deleteTimeBasedMail"),
        };
    }, [query, columnOptions, showDetailsAction, hasAccessRight, contextMenuActions, queryCommands]);
}
