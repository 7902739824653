import { yupResolver } from "@hookform/resolvers/yup";
import { useMemo } from "react";
import { OrganicMaterialTransactionCertificateDetails } from "../organicMaterialTransactionCertificateTypes";
import { OrganicMaterialTransactionCertificateDetailViewProps } from "./OrganicMaterialTransactionCertificateDetailView";
import { defaultOrganicMaterialTransactionCertificateSortOption } from "../useOrganicMaterialTransactionCertificateSubList";
import { organicMaterialTransactionCertificateValidationSchema } from "./organicMaterialTransactionCertificateValidationSchema";
import useIsAuthorised from "../../../../../../auth/useIsAuthorised";
import useResolvedRoute, { routeEnum } from "../../../../../../components/routing/useResolvedRoute";
import useDomainEntityDetailsEdit from "../../../../../../hooks/details/useDomainEntityDetailsEdit";

export default function useOrganicMaterialTransactionCertificateEdit(): OrganicMaterialTransactionCertificateDetailViewProps {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedId } = useResolvedRoute();
    const organicMaterialPurchaseId = getResolvedId("organicMaterialPurchase");
    const organicMaterialOriginId = getResolvedId("organicMaterialOrigin");
    const organicMaterialTransactionCertificateId = getResolvedId("organicMaterialTransactionCertificate");

    const { detailViewProps, form, isReadOnly, title, recordNavigationProps } =
        useDomainEntityDetailsEdit<OrganicMaterialTransactionCertificateDetails>({
            baseUrl: `/certificate-service/organic-material-purchases/${organicMaterialPurchaseId}/organic-material-origins/${organicMaterialOriginId}/organic-material-tcs/${organicMaterialTransactionCertificateId}`,
            frontendRoute: "organicMaterialTransactionCertificate",
            recordNavigationDefaultSort: defaultOrganicMaterialTransactionCertificateSortOption,
            titleBuilder: (item) => item.tcNumber,
            resolver: yupResolver(organicMaterialTransactionCertificateValidationSchema),
            canSave: hasAccessRight("updateRawMaterialProduction"),
            historyColumns: [
                {
                    identifier: "tcNumber",
                    title: "organicMaterialTransactionCertificate.history.tcNumber_column",
                },
                {
                    identifier: "tcType",
                    title: "organicMaterialTransactionCertificate.history.type_column",
                },
                {
                    identifier: "standard",
                    title: "organicMaterialTransactionCertificate.history.standard_column",
                },
                {
                    identifier: "tcCopy",
                    title: "organicMaterialTransactionCertificate.history.tcFile_column",
                },
            ],
        });

    const readOnly = useMemo<OrganicMaterialTransactionCertificateDetailViewProps["readOnly"]>(
        () => ({
            tcNumber: isReadOnly,
            tcType: isReadOnly,
            standard: isReadOnly,
            tcCopy: isReadOnly,
        }),
        [isReadOnly]
    );

    const createRoute = useMemo<routeEnum | undefined>(() => {
        return hasAccessRight("createRawMaterialProduction") ? "organicMaterialTransactionCertificate" : undefined;
    }, [hasAccessRight]);

    return {
        detailViewProps,
        form,
        readOnly,
        title,
        recordNavigationProps,
        createRoute,
        tcFileUrl: `/certificate-service/organic-material-purchases/${organicMaterialPurchaseId}/organic-material-origins/${organicMaterialOriginId}/organic-material-tcs/${organicMaterialTransactionCertificateId}/tc-copy`,
    };
}
