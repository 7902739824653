import { ProductConversionDetails } from "../productConversionTypes";
import { Nullable } from "../../utils/typeUtils";
import useDetailsCreate from "../../hooks/details/useDetailsCreate";
import { productConversionValidationSchema } from "./productConversionValidationSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { routeEnum } from "../../components/routing/useResolvedRoute";

const initialData: Nullable<ProductConversionDetails> = {
    id: null,
    inputProductCategory: null,
    inputProductDetail: null,
    maxConversion: null,
    minConversion: null,
    outputProductCategory: null,
    outputProductDetail: null,
    processCategory: null,
    rawMaterial: null,
    inputProductCategoryName: null,
    inputProductDetailName: null,
    outputProductCategoryName: null,
    outputProductDetailName: null,
    processCategoryName: null,
    rawMaterialName: null,
};

export default function useProductConversionCreate() {
    const { detailViewProps, form, title } = useDetailsCreate<ProductConversionDetails>({
        title: "productConversions.detail.newRecord_title",
        frontendRoute: "productConversion",
        initialData,
        resolver: yupResolver(productConversionValidationSchema),
        saveUrl: "/certificate-service/product-conversions",
    });

    return {
        title,
        detailViewProps,
        form,
        navigationEnabled: false,
        isReadOnly: false,
        createRoute: "productConversion" as routeEnum,
    };
}
