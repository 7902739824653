import { useMemo } from "react";
import { UseGtbFormReturn } from "../../../../../../../hooks/formHandling/useGtbForm";
import { InputQuantityDetails } from "../../inputQuantityTypes";
import ListPlaceholder from "../../../../../../../components/grid/ListPlaceholder";
import { useWatch } from "react-hook-form";
import useSetFormValue from "../../../../../../../hooks/formHandling/useSetFormValue";
import TcProductInputSourceSelectionList from "./TcProductInputSourceSelectionList";
import PreGtbProductInputSourceList from "./PreGtbProductInputSourceList";
import TcByProductInputSourceSelectionList from "./TcByProductInputSourceSelectionList";
import OrganicMaterialPurchaseInputSourceSelectionList from "./OrganicMaterialPurchaseInputSourceList";

const className = "inputSourceSubList";
export default function InputSourceSelectionComponent({
    form,
    currentSourceType,
}: {
    form: UseGtbFormReturn<InputQuantityDetails>;
    currentSourceType: InputQuantityDetails["typeOfInputSource"];
}) {
    const setFormValue = useSetFormValue(form.form);
    const [inputProduct, inputPreGtbTcProduct, inputByProduct, inputOrganicMaterialPurchase] = useWatch({
        control: form.form.control,
        name: ["inputProduct", "inputPreGtbTcProduct", "inputByProduct", "inputOrganicMaterialPurchase"],
    });

    return useMemo(() => {
        const commonProps = { id: `sourceType-${currentSourceType}`, className: className };
        switch (currentSourceType) {
            case "TC_PRODUCT":
                return (
                    <TcProductInputSourceSelectionList
                        {...commonProps}
                        selectedItemId={inputProduct}
                        onItemClick={(item) => setFormValue("currentInputProduct", item)}
                    />
                );
            case "PRE_GTB_TC_PRODUCT":
                return (
                    <PreGtbProductInputSourceList
                        {...commonProps}
                        selectedItemId={inputPreGtbTcProduct}
                        onItemClick={(item) => setFormValue("currentInputPreGtbProduct", item)}
                    />
                );
            case "BY_PRODUCT":
                return (
                    <TcByProductInputSourceSelectionList
                        {...commonProps}
                        selectedItemId={inputByProduct}
                        onItemClick={(item) => setFormValue("currentInputByProduct", item)}
                    />
                );
            case "ORGANIC_MATERIAL_PURCHASE":
                return (
                    <OrganicMaterialPurchaseInputSourceSelectionList
                        {...commonProps}
                        selectedItemId={inputOrganicMaterialPurchase}
                        onItemClick={(item) => setFormValue("currentInputOrganicMaterialPurchase", item)}
                    />
                );
            default:
                return (
                    <ListPlaceholder
                        className={className}
                        message={"inputQuantity.detail.inputSourceListPlaceholder_message"}
                        header={"inputQuantity.detail.inputSourceList_header"}
                    />
                );
        }
    }, [
        currentSourceType,
        inputByProduct,
        inputOrganicMaterialPurchase,
        inputPreGtbTcProduct,
        inputProduct,
        setFormValue,
    ]);
}
