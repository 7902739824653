import yup, { validateDate, validateNumber, validateString } from "../../../utils/yupExtension";

export const versionValidationSchema = yup.object().shape({
    versionNumber: validateString().isRequired().hasMaxLength(10),
    defaultValidityPeriod: validateNumber().isRequired().hasMinValue(1),
    recertificationPeriod: validateNumber().isRequired().hasMinValue(1),
    recertificationBindingPeriod: validateNumber().isRequired().hasMinValue(1),
    rawMaterialsText: validateString().isRequired().hasMaxLength(1000),
    processingText: validateString().isRequired().hasMaxLength(1000),
    cugsText: validateString().isRequired().hasMaxLength(1000),
    startDate: validateDate().isRequired(),
    endDate: validateDate(),
});
