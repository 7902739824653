import React, { ReactNode } from "react";
import GtbIconButton from "../GtbIconButton";
import "./drawer.css";
import { joinClassNames } from "../../utils/StringUtils";
import useGtbTranslation, { I18nKey } from "../../i18n/useGtbTranslation";

export interface DrawerProps {
    title: I18nKey;
    className?: string;
    children: ReactNode;
    closeDrawer: () => void;
}

/**
 * Drawer Component should only be used in combination with useModal-hook
 * @param title the title text
 * @param closeDrawer callback when close button is clicked
 * @param className Additional classname for the drawer
 * @param children the content component of the drawer
 * @constructor
 */
export default function Drawer({ title, closeDrawer, className, children }: DrawerProps) {
    const translation = useGtbTranslation();

    return (
        <div aria-modal aria-labelledby="drawerTitle" className={joinClassNames("drawer", className)} role="dialog">
            <div className="drawerHeading">
                <h1 id="drawerTitle">{translation(title)}</h1>
                <GtbIconButton
                    className="drawer-close-button"
                    label="components.drawer.closeDrawer_button"
                    iconName={"cross"}
                    size={20}
                    onClick={closeDrawer}
                />
            </div>
            <div className="drawerContainer">{children}</div>
        </div>
    );
}
