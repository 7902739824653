import { buildSchema, validateDate, validateString } from "../../utils/yupExtension";
import { BuyingRelationDetails } from "../buyingRelationTypes";

export const buyingRelationValidationSchema = buildSchema<BuyingRelationDetails>({
    sellerSupplyChainOperator: validateString().isRequired(),
    buyerSupplyChainOperator: validateString().isRequired(),
    standard: validateString().isRequired(),
    startDate: validateDate().isRequired(),
    endDate: validateDate(),
});
