import useNavigatableDetailsEdit from "../../hooks/details/useNavigatableDetailsEdit";
import { yupResolver } from "@hookform/resolvers/yup";
import { UploadLogEditViewProps } from "./UploadLogEditView";
import { UploadLogDetails } from "../uploadLogTypes";
import { uploadLogValidationSchema } from "./uploadLogValidationSchema";
import { formatTimestamp } from "../../utils/formatter";
import { defaultUploadLogSortOption } from "../useUploadLogList";
import useResolvedRoute from "../../components/routing/useResolvedRoute";

function useUploadLogEdit(): UploadLogEditViewProps {
    const { getResolvedId } = useResolvedRoute();
    const uploadLogId = getResolvedId("uploadLog");

    const { detailViewProps, form, initialData, title, recordNavigationProps } =
        useNavigatableDetailsEdit<UploadLogDetails>({
            baseUrl: "/log-service/uploadlogs/" + uploadLogId,
            resolver: yupResolver(uploadLogValidationSchema),
            titleBuilder: (item) => `${formatTimestamp(item.timestamp)}`,
            canSave: true,
            recordNavigationDefaultSort: defaultUploadLogSortOption,
            frontendRoute: "uploadLog",
        });

    return {
        detailViewProps,
        form,
        title,
        subtitle: initialData?.logLevel,
        recordNavigationProps,
    };
}

export default useUploadLogEdit;
