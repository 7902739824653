import AcceptTermsOfUseDialog from "./AcceptTermsOfUseDialog";
import { useModal } from "../../components/modal/ModalProvider";
import { useCallback } from "react";

export default function useAcceptGoogleTermsOfUse() {
    const { showModal: showDialog, closeModal: closeDialog } = useModal();
    return useCallback(
        () =>
            showDialog(
                <AcceptTermsOfUseDialog
                    title="components.acceptedToUDialog.acceptGooglesToU_dialog_title"
                    closeDialog={closeDialog}
                    acceptTouUrl="/certificate-service/users/me/google-terms-of-use"
                    touTextUrl="/certificate-service/google-terms-of-use"
                    onDecline={closeDialog}
                />
            ),
        [closeDialog, showDialog]
    );
}
