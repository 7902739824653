import { OrganicMaterialProductionDetailViewTabProps } from "./OrganicMaterialProductionDetailView";
import OrganicMaterialProducerPickList from "../../components/Input/pickList/typedPicklist/OrganicMaterialProducerPickList";
import RawMaterialPickList from "../../components/Input/pickList/typedPicklist/RawMaterialPickList";
import { GtbNumberFieldOneFixedDecimal } from "../../components/Input/GtbNumberField";
import { LabeledCheckbox } from "../../components/Input/Checkbox";
import "./organicMaterialProductionBasicData.css";
import useGtbTranslation from "../../i18n/useGtbTranslation";
import HarvestYearPickList from "./HarvestYearPickList";
import useOrganicMaterialProductionOrganicMaterialProductionScSubList from "../../organicMaterialProductionScopeCertificate/useOrganicMaterialProductionOrganicMaterialProductionScSubList";
import InfinityScrollSubList from "../../components/grid/InfinityScrollSubList";

export default function OrganicMaterialProductionBasicData({
    form,
    readOnlyProps,
    subListVisible,
}: OrganicMaterialProductionDetailViewTabProps) {
    const translation = useGtbTranslation();
    const organicMaterialProductionScListOptions = useOrganicMaterialProductionOrganicMaterialProductionScSubList();

    return (
        <div className={"formGrid organicMaterialProducerBasicData"}>
            <OrganicMaterialProducerPickList
                control={form.form.control}
                name={"organicMaterialProducer"}
                readOnly
                label={"organicMaterialProduction.detail.organicMaterialProducer_input"}
                className={"organicMaterialProducer"}
            />
            <RawMaterialPickList
                control={form.form.control}
                name={"certifiedOrganicMaterial"}
                readOnly={readOnlyProps.isCertifiedOrganicMaterialReadOnly}
                label={"organicMaterialProduction.detail.certifiedOrganicMaterial_input"}
                className={"certifiedOrganicMaterial"}
                flags={{ typeOfMaterial: ["ORGANIC", "IN_CONVERSION"] }}
            />
            <LabeledCheckbox
                {...form.form.register("usdaNopCompliant")}
                readOnly={readOnlyProps.isUsdaNopCompliantReadOnly}
                id={"usdaNopCompliant"}
                label={"organicMaterialProduction.detail.usdaNopCompliant_input"}
                className={"usdaNopCompliant"}
            />
            <HarvestYearPickList
                control={form.form.control}
                name="startOfHarvestYear"
                className={"harvestYear"}
                label={"organicMaterialProduction.detail.harvestYear_input"}
                readOnly={readOnlyProps.isHarvestYearReadOnly}
            />
            <GtbNumberFieldOneFixedDecimal
                control={form.form.control}
                name={"sizeOfProductionArea"}
                readOnly={readOnlyProps.isSizeOfProductionAreaReadOnly}
                label={"organicMaterialProduction.detail.sizeOfProductionArea_input"}
                className={"sizeOfProductionArea"}
                unit={translation("organicMaterialProduction.detail.sizeOfProductionArea_input_unit")}
                decimalPlaces={1}
            />
            <GtbNumberFieldOneFixedDecimal
                control={form.form.control}
                name={"productionEstimate"}
                readOnly={readOnlyProps.isProductionEstimateReadOnly}
                label={"organicMaterialProduction.detail.productionEstimate_input"}
                className={"estimatedQuantity"}
                unit={translation("organicMaterialProduction.detail.productionEstimate_input_unit")}
                decimalPlaces={1}
            />
            <GtbNumberFieldOneFixedDecimal
                control={form.form.control}
                name={"productionSold"}
                readOnly={readOnlyProps.isProductionSoldReadOnly}
                label={"organicMaterialProduction.detail.productionSold_input"}
                className={"soldQuantity"}
                unit={translation("organicMaterialProduction.detail.productionSold_input_unit")}
                decimalPlaces={1}
            />
            {subListVisible && (
                <InfinityScrollSubList
                    {...organicMaterialProductionScListOptions}
                    className="organicMaterialProductionSc"
                />
            )}
        </div>
    );
}
