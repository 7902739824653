import useRawMaterialEdit from "./useRawMaterialEdit";
import RawMaterialDetailView from "./RawMaterialDetailView";

function RawMaterialEditView() {
    const rawMaterialDetailProps = useRawMaterialEdit();

    return <RawMaterialDetailView {...rawMaterialDetailProps} />;
}

export default RawMaterialEditView;
