import { TypedDetailViewProps } from "../../components/detailView/DetailView";
import { UseGtbFormReturn } from "../../hooks/formHandling/useGtbForm";
import "../../components/spinner.css";
import { RawMaterialDetails } from "../rawMaterialTypes";
import RawMaterialBasicData from "../../rawMaterial/details/RawMaterialBasicData";
import EntityDetailView from "../../components/entity/EntityDetailView";
import { useHelpContext } from "../../help/context-sensitivity/useHelpContext";
import Tabs, { TabKey } from "../../components/tabs/Tabs";
import { buildLanguageVersionTab } from "../../i18n/LanguageVersionTab";
import "./rawMaterialLanguageVersion.css";

export default function RawMaterialDetailView({
    detailViewProps,
    form,
    isReadOnly,
    subListVisible,
    ...rest
}: RawMaterialDetailViewProps) {
    useHelpContext("raw-material-details");
    return (
        <EntityDetailView {...rest} detailViewProps={detailViewProps} form={form}>
            <Tabs<RawMaterialDetails, TabKey<"rawMaterial.detail.basicData_tab" | "translation.languageVersion_tab">>
                tabs={[
                    {
                        heading: "rawMaterial.detail.basicData_tab",
                        element: (
                            <RawMaterialBasicData form={form} readOnly={isReadOnly} subListVisible={subListVisible} />
                        ),
                    },
                    buildLanguageVersionTab({ form, readOnly: isReadOnly, fields: ["name"], baseName: "rawMaterial" }),
                ]}
                errors={form.form.formState.errors}
                errorToTab={{
                    id: "internal",
                    code: "rawMaterial.detail.basicData_tab",
                    pickListSequence: "rawMaterial.detail.basicData_tab",
                    name: "translation.languageVersion_tab",
                    typeOfMaterial: "rawMaterial.detail.basicData_tab",
                    startDate: "rawMaterial.detail.basicData_tab",
                    endDate: "rawMaterial.detail.basicData_tab",
                    printNameMandatory: "rawMaterial.detail.basicData_tab",
                }}
            />
        </EntityDetailView>
    );
}

export interface RawMaterialDetailViewProps extends TypedDetailViewProps<RawMaterialDetails> {
    subListVisible?: boolean;
    isReadOnly: boolean;
}

export interface RawMaterialDetailViewTabProps {
    form: UseGtbFormReturn<RawMaterialDetails>;
    readOnly: boolean;
    subListVisible?: boolean;
}
