import { useMemo } from "react";
import useIsAuthorised from "../auth/useIsAuthorised";
import { useDeleteAction, useShowDetailsAction } from "../utils/defaultActions";
import { useQueryBuilder } from "../components/grid/component/useQueryBuilder";
import useResolvedRoute from "../components/routing/useResolvedRoute";
import { SubListProps } from "../components/grid/SubList";
import { TransactionCertificateSummary } from "./transactionCertificateTypes";
import { defaultTransactionCertificateSortOption } from "./useTransactionCertificateListView";
import { GridColumn } from "../components/grid/component/Grid";
import {
    buildBooleanColumn,
    buildDataColumn,
    buildDateColumn,
    buildTextColumn,
} from "../components/grid/ColumnProvider";
import { EnumEntityType } from "../components/Input/pickList/typedEnumPicklist/enumEntityType";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";

const columns: GridColumn<TransactionCertificateSummary>[] = [
    buildTextColumn({ key: "tcNumber", title: "transactionCertificate.list.tcNumber_column", minWidth: 141 }),
    buildTextColumn({
        key: "scopeCertificateStandardVersion",
        title: "transactionCertificate.list.standardVersion_column",
        minWidth: 187,
    }),
    buildTextColumn({
        key: "buyerBusinessName",
        title: "transactionCertificate.list.buyer_column",
        minWidth: 123,
    }),
    buildDataColumn<TransactionCertificateSummary, EnumEntityType>({
        key: "status",
        title: "transactionCertificate.list.status_column",
        url: "/certificate-service/references/transaction-certificate-statuses",
        itemId: "id",
        itemLabel: "name",
        minWidth: 232,
    }),
    buildTextColumn({
        key: "cbTcReferenceNumber",
        title: "transactionCertificate.list.cbTcReferenceNumber_column",
        minWidth: 188,
        visible: false,
    }),
    buildTextColumn({
        key: "certificationBodyBusinessName",
        title: "transactionCertificate.list.certificationBody_column",
        minWidth: 193,
        visible: false,
    }),
    buildDateColumn({
        key: "dateOfIssue",
        title: "transactionCertificate.list.dateOfIssue_column",
        minWidth: 156,
        visible: false,
    }),
    buildTextColumn({
        key: "declarationsBySeller",
        title: "transactionCertificate.list.declarationsBySeller_column",
        minWidth: 222,
        visible: false,
    }),
    buildBooleanColumn({
        key: "doNotDisplayLastProcessorOnPrintedTc",
        title: "transactionCertificate.list.doNotDisplayLastProcessorOnPrintedTc_column",
        minWidth: 389,
        visible: false,
    }),
    buildTextColumn({
        key: "facilityAsSellerBusinessName",
        title: "transactionCertificate.list.facilityAsSeller_column",
        minWidth: 180,
        visible: false,
    }),
    buildTextColumn({
        key: "facilityAsBuyerBusinessName",
        title: "transactionCertificate.list.facilityAsBuyer_column",
        minWidth: 182,
        visible: false,
    }),
    buildTextColumn({
        key: "placeOfIssue",
        title: "transactionCertificate.list.placeOfIssue_column",
        minWidth: 163,
        visible: false,
    }),
    buildTextColumn({
        key: "referencedSc",
        title: "transactionCertificate.list.referencedSc_column",
        minWidth: 170,
        visible: false,
    }),
];

export default function useTransactionCertificateSubList() {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedId, getResolvedEditUrl, getResolvedCreateUrl } = useResolvedRoute();

    const { query, columnOptions, forceReload } = useQueryBuilder<TransactionCertificateSummary>(
        `/certificate-service/transaction-certificates`,
        {
            sort: defaultTransactionCertificateSortOption,
            filterActive: false,
            sellerId: getResolvedId("supplyChainOperator"),
        }
    );

    const showDetailsAction = useShowDetailsAction(
        (item: TransactionCertificateSummary) => getResolvedEditUrl("transactionCertificate", item.id),
        query.parameter
    );

    const deleteAction = useDeleteAction(
        (item: TransactionCertificateSummary) => `/certificate-service/transaction-certificates/${item.id}`,
        () => forceReload(),
        "deleteTransactionCertificate"
    );

    const contextMenuActions = useGridRowContextMenuActions(showDetailsAction, deleteAction);

    return useMemo<SubListProps<TransactionCertificateSummary>>(
        () => ({
            query,
            columns: columns,
            uniqueDataKey: "id",
            onItemDoubleClick: showDetailsAction,
            header: "transactionCertificate.list.sublist_header",
            itemActions: contextMenuActions,
            columnOptions,
            createUrl: hasAccessRight("createTransactionCertificate")
                ? getResolvedCreateUrl("supplyChainOperatorTransactionCertificate")
                : undefined,
            tableName: "supplyChainOperatorTransactionCertificate",
        }),
        [columnOptions, contextMenuActions, getResolvedCreateUrl, hasAccessRight, query, showDetailsAction]
    );
}
