import { FilterComponentProps } from "../../ColumnProvider";
import { GtbTextFieldProps } from "../../../Input/GtbTextField";
import { AccessibleCheckbox } from "../../../Input/Checkbox";
import useGtbTranslation, { I18nKey } from "../../../../i18n/useGtbTranslation";

interface BooleanFilterProps<ItemType>
    extends FilterComponentProps<ItemType>,
        Omit<GtbTextFieldProps, "className" | "startAdornment" | "placeholder" | "title"> {
    itemKey: string;
    title: I18nKey;
}

function BooleanFilter<ItemType>({ itemKey, title, filter, filterKey, setFilter }: BooleanFilterProps<ItemType>) {
    const translation = useGtbTranslation();

    return (
        <AccessibleCheckbox
            id={itemKey + "Filter"}
            checked={filter === "true"}
            onChange={() => setFilter(filterKey, getNextFilter(filter))}
            indeterminate={filter === undefined}
            label={{ key: "components.grid.filterBy_accessibleLabel", options: { title: translation(title) } }}
        />
    );
}

function getNextFilter(filter: any) {
    if (filter === undefined) {
        return "true";
    }
    if (filter === "true") {
        return "false";
    }
    return undefined;
}

export default BooleanFilter;
