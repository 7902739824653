import { joinClassNames } from "../../utils/StringUtils";
import ControlledDatePicker from "../../components/Input/datepicker/ControlledDatePicker";
import { UseGtbFormReturn } from "../../hooks/formHandling/useGtbForm";
import { PreGtbTransactionCertificateDetails } from "../preGtbTransactionCertificateTypes";
import GtbTextField from "../../components/Input/GtbTextField";
import SupplyChainOperatorPickList, {
    supplyChainOperatorPickListCombinedClassName,
    supplyChainOperatorPickListItemPresentation,
    supplyChainOperatorPickListReferenceUrl,
} from "../../components/Input/pickList/typedPicklist/SupplyChainOperatorPickList";
import CertificationBodyPickList from "../../components/Input/pickList/typedPicklist/CertificationBodyPickList";
import StandardPickList, {
    StandardPickListFlags,
} from "../../components/Input/pickList/typedPicklist/StandardPickList";
import PreGtbTransactionCertificateStatusPickList from "../../components/Input/pickList/typedPicklist/PreGtbTransactionCertificateStatusPickList";
import "./preGtbTransactionCertificateBasicData.css";
import { ReadOnlyProps } from "../../utils/typeUtils";
import { DependentPicklist } from "../../components/Input/pickList/typedPicklist/DependentPicklist";

export interface PreGtbTransactionCertificateBasicDataProps {
    form: UseGtbFormReturn<PreGtbTransactionCertificateDetails>;
    preGtbTcId?: string;
    organisationType: StandardPickListFlags["organisation_type"];
    readOnly: ReadOnlyProps<PreGtbTransactionCertificateDetails>;
    restrictBuyersToSelectedStandard: boolean;
}

export default function PreGtbTransactionCertificateBasicData({
    form,
    preGtbTcId,
    organisationType,
    readOnly,
    restrictBuyersToSelectedStandard,
}: PreGtbTransactionCertificateBasicDataProps) {
    return (
        <div className={joinClassNames("preGtbTransactionCertificateBasicData", "formGrid")}>
            <GtbTextField
                label="preGtbTransactionCertificate.detail.tcNumber_input"
                {...form.registerWithErrors("tcNumber")}
                readOnly={readOnly.tcNumber}
                className="tcNumber"
            />
            <CertificationBodyPickList
                label="preGtbTransactionCertificate.detail.certificationBody_input"
                control={form.form.control}
                name="certificationBody"
                className="certificationBody"
                flags={{ all: true }}
                readOnly={readOnly.certificationBody}
            />
            <StandardPickList
                label="preGtbTransactionCertificate.detail.standard_input"
                control={form.form.control}
                name="standard"
                className="standard"
                flags={{ organisation_type: organisationType }}
                readOnly={readOnly.standard}
            />
            {!restrictBuyersToSelectedStandard && (
                <SupplyChainOperatorPickList
                    label="preGtbTransactionCertificate.detail.buyer_input"
                    control={form.form.control}
                    name="buyer"
                    className="buyer"
                    flags={{ accessiblePreGtbTcBuyer: true }}
                    readOnly={readOnly.buyer}
                />
            )}
            {restrictBuyersToSelectedStandard && (
                <DependentPicklist
                    form={form.form}
                    parentField="standard"
                    label="preGtbTransactionCertificate.detail.buyer_input"
                    name="buyer"
                    {...supplyChainOperatorPickListItemPresentation}
                    className={supplyChainOperatorPickListCombinedClassName("buyer")}
                    urlBuilder={(standard) => ({
                        url: supplyChainOperatorPickListReferenceUrl,
                        flags: {
                            accessiblePreGtbTcBuyerForStandard: standard,
                        },
                    })}
                    readOnly={readOnly.buyer}
                />
            )}
            <GtbTextField
                label="preGtbTransactionCertificate.detail.sellersLicenceNumber_input"
                {...form.registerWithErrors("sellersLicenceNumber")}
                readOnly={readOnly.sellersLicenceNumber}
                className="sellersLicenceNumber"
            />
            <ControlledDatePicker
                control={form.form.control}
                name="dateOfIssue"
                label="preGtbTransactionCertificate.detail.dateOfIssue_input"
                className="dateOfIssue"
                readOnly={readOnly.dateOfIssue}
            />
            <PreGtbTransactionCertificateStatusPickList
                control={form.form.control}
                name="status"
                label="preGtbTransactionCertificate.detail.status_input"
                flags={{ onlyAvailable: true, preGtbTcId }}
                className="status"
                readOnly={readOnly.status}
            />
        </div>
    );
}
