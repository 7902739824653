import { UseGtbFormReturn } from "../../hooks/formHandling/useGtbForm";
import { ScopeCertificateDetails } from "../scopeCertificateTypes";
import GtbTextArea from "../../components/Input/GtbTextArea";

export interface ScopeCertificateAdditionalDataProps {
    form: UseGtbFormReturn<ScopeCertificateDetails>;
    readOnly: { additionalDeclaration: boolean };
}

export default function ScopeCertificateAdditionalData({ form, readOnly }: ScopeCertificateAdditionalDataProps) {
    return (
        <div className="formGrid">
            <GtbTextArea
                label="scopeCertificate.detail.additionalDeclaration_input"
                {...form.registerWithErrors("additionalDeclaration")}
                className="additionalDeclaration"
                readOnly={readOnly.additionalDeclaration}
            />
        </div>
    );
}
