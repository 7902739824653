import { SortOption } from "../components/grid/component/useQueryBuilder";
import { useMemo } from "react";
import { GridColumn } from "../components/grid/component/Grid";
import useListView from "../hooks/useListView";
import { useDeleteAction, useShowDetailsAction } from "../utils/defaultActions";
import { buildDataColumn, buildDateColumn, buildTextColumn } from "../components/grid/ColumnProvider";
import useResolvedRoute, { getCreateUrl } from "../components/routing/useResolvedRoute";
import { EntityListViewGridProps } from "../components/entity/EntityListView";
import { SubcontractingRelationDetails, SubcontractingRelationSummary } from "./subcontractingRelationTypes";
import { EnumEntityType } from "../components/Input/pickList/typedEnumPicklist/enumEntityType";
import useIsAuthorised from "../auth/useIsAuthorised";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";

export const defaultSubcontractingRelationSortOption: SortOption<
    SubcontractingRelationSummary | SubcontractingRelationDetails
> = {
    field: "principalSupplyChainOperatorBusinessName",
    direction: "ASC",
};

const columns: GridColumn<SubcontractingRelationSummary>[] = [
    buildTextColumn({
        key: "principalSupplyChainOperatorBusinessName",
        title: "subcontractingRelation.list.principal_column",
        minWidth: 124,
    }),
    buildTextColumn({
        key: "subcontractorSupplyChainOperatorBusinessName",
        title: "subcontractingRelation.list.subcontractor_column",
        minWidth: 166,
    }),
    buildTextColumn({
        key: "standardAcronym",
        title: "subcontractingRelation.list.standard_column",
        minWidth: 123,
    }),
    buildDataColumn<SubcontractingRelationSummary, EnumEntityType>({
        key: "accessLevel",
        title: "subcontractingRelation.list.accessLevel_column",
        minWidth: 137,
        url: "/certificate-service/references/subcontracting-relation-access-levels",
        itemId: "id",
        itemLabel: "name",
    }),
    buildDataColumn<SubcontractingRelationSummary, EnumEntityType>({
        key: "confirmationMethod",
        title: "subcontractingRelation.list.confirmationMethod_column",
        minWidth: 202,
        itemId: "id",
        itemLabel: "name",
        url: "/certificate-service/references/confirmation-methods",
    }),
    buildDateColumn({
        key: "startDate",
        title: "subcontractingRelation.list.startDate_column",
        minWidth: 130,
        visible: false,
    }),
    buildDateColumn({
        key: "endDate",
        title: "subcontractingRelation.list.endDate_column",
        minWidth: 124,
        visible: false,
    }),
];

export default function useSubcontractingRelationListView() {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedEditUrl } = useResolvedRoute();

    const {
        queryBuilder: { forceReload, columnOptions, query, queryCommands },
    } = useListView<SubcontractingRelationSummary>({
        navigationContextLabel: "subcontractingRelation.breadcrumb",
        baseQuery: "/certificate-service/subcontracting-relations",
        defaultSortOption: defaultSubcontractingRelationSortOption,
    });

    const showDetailsAction = useShowDetailsAction(
        (item: SubcontractingRelationSummary) => getResolvedEditUrl("subcontractingRelation", item.id),
        query.parameter
    );

    const deleteAction = useDeleteAction(
        (item: SubcontractingRelationSummary) => `/certificate-service/subcontracting-relations/${item.id}`,
        () => forceReload(),
        "deleteOperationLevelOrganisation"
    );

    const actions = useGridRowContextMenuActions(showDetailsAction, deleteAction);

    return useMemo<EntityListViewGridProps<SubcontractingRelationSummary>>(() => {
        return {
            query,
            columns,
            uniqueDataKey: "id",
            onItemDoubleClick: showDetailsAction,
            createUrl: hasAccessRight("createOperationLevelOrganisation")
                ? getCreateUrl("subcontractingRelation")
                : undefined,
            tableName: "subcontractingRelation",
            columnOptions,
            itemActions: actions,
            queryCommands,
            bulkDeleteAccessRight: () => hasAccessRight("deleteOperationLevelOrganisation"),
        };
    }, [query, showDetailsAction, hasAccessRight, columnOptions, actions, queryCommands]);
}
