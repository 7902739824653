import { SortOption } from "../components/grid/component/useQueryBuilder";
import { GridColumn } from "../components/grid/component/Grid";
import {
    buildBooleanColumn,
    buildDataColumn,
    buildDateColumn,
    buildTextColumn,
} from "../components/grid/ColumnProvider";
import useIsAuthorised from "../auth/useIsAuthorised";
import useResolvedRoute, { getCreateUrl } from "../components/routing/useResolvedRoute";
import useListView from "../hooks/useListView";
import { useDeleteAction, useShowDetailsAction } from "../utils/defaultActions";
import { useMemo } from "react";
import { EntityListViewGridProps } from "../components/entity/EntityListView";
import { TransactionCertificateDetails, TransactionCertificateSummary } from "./transactionCertificateTypes";
import { EnumEntityType } from "../components/Input/pickList/typedEnumPicklist/enumEntityType";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";
import {
    transactionCertificateSearchContext,
    TransactionCertificateSearchContext,
} from "./TransactionCertificateListView";

export const defaultTransactionCertificateSortOption: SortOption<
    TransactionCertificateSummary | TransactionCertificateDetails
> = {
    field: "tcNumber",
    direction: "ASC",
};

const columns: GridColumn<TransactionCertificateSummary>[] = [
    buildTextColumn({ key: "tcNumber", title: "transactionCertificate.list.tcNumber_column", minWidth: 141 }),
    buildTextColumn({
        key: "scopeCertificateStandardVersion",
        title: "transactionCertificate.list.standardVersion_column",
        minWidth: 187,
    }),
    buildTextColumn({
        key: "sellerBusinessName",
        title: "transactionCertificate.list.seller_column",
        minWidth: 123,
    }),
    buildTextColumn({
        key: "buyerBusinessName",
        title: "transactionCertificate.list.buyer_column",
        minWidth: 123,
    }),
    buildDataColumn<TransactionCertificateSummary, EnumEntityType>({
        key: "status",
        title: "transactionCertificate.list.status_column",
        url: "/certificate-service/references/transaction-certificate-statuses",
        itemId: "id",
        itemLabel: "name",
        minWidth: 232,
    }),
    buildTextColumn({
        key: "approvingPersonUsername",
        title: "transactionCertificate.list.approvingPerson_column",
        minWidth: 194,
        visible: false,
    }),
    buildTextColumn({
        key: "cbTcReferenceNumber",
        title: "transactionCertificate.list.cbTcReferenceNumber_column",
        minWidth: 188,
        visible: false,
    }),
    buildTextColumn({
        key: "certificationBodyBusinessName",
        title: "transactionCertificate.list.certificationBody_column",
        minWidth: 193,
        visible: false,
    }),
    buildDateColumn({
        key: "dateOfIssue",
        title: "transactionCertificate.list.dateOfIssue_column",
        minWidth: 156,
        visible: false,
    }),
    buildTextColumn({
        key: "declarationsBySeller",
        title: "transactionCertificate.list.declarationsBySeller_column",
        minWidth: 222,
        visible: false,
    }),
    buildBooleanColumn({
        key: "doNotDisplayLastProcessorOnPrintedTc",
        title: "transactionCertificate.list.doNotDisplayLastProcessorOnPrintedTc_column",
        minWidth: 389,
        visible: false,
    }),
    buildTextColumn({
        key: "facilityAsSellerBusinessName",
        title: "transactionCertificate.list.facilityAsSeller_column",
        minWidth: 180,
        visible: false,
    }),
    buildTextColumn({
        key: "facilityAsBuyerBusinessName",
        title: "transactionCertificate.list.facilityAsBuyer_column",
        minWidth: 182,
        visible: false,
    }),
    buildTextColumn({
        key: "placeOfIssue",
        title: "transactionCertificate.list.placeOfIssue_column",
        minWidth: 163,
        visible: false,
    }),
    buildTextColumn({
        key: "referencedSc",
        title: "transactionCertificate.list.referencedSc_column",
        minWidth: 170,
        visible: false,
    }),
];

export default function useTransactionCertificateListView(searchContext: TransactionCertificateSearchContext) {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedEditUrl } = useResolvedRoute();

    const {
        queryBuilder: { forceReload, columnOptions, query, queryCommands },
    } = useListView<TransactionCertificateSummary>({
        navigationContextLabel: transactionCertificateSearchContext[searchContext],
        baseQuery: "/certificate-service/transaction-certificates",
        defaultSortOption: defaultTransactionCertificateSortOption,
        additionalQueryParameter: { tcsc: searchContext },
    });

    const showDetailsAction = useShowDetailsAction(
        (item: TransactionCertificateSummary) => getResolvedEditUrl("transactionCertificate", item.id),
        query.parameter
    );

    const deleteAction = useDeleteAction(
        (item: TransactionCertificateSummary) => `/certificate-service/transaction-certificates/${item.id}`,
        forceReload,
        "deleteTransactionCertificate"
    );

    const actions = useGridRowContextMenuActions(showDetailsAction, deleteAction);

    return useMemo<EntityListViewGridProps<TransactionCertificateSummary>>(() => {
        return {
            query,
            columns: columns,
            uniqueDataKey: "id",
            onItemDoubleClick: showDetailsAction,
            createUrl: hasAccessRight("createTransactionCertificate")
                ? getCreateUrl("transactionCertificate")
                : undefined,
            tableName: "transactionCertificate",
            columnOptions,
            itemActions: actions,
            queryCommands,
            bulkDeleteAccessRight: () => hasAccessRight("deleteTransactionCertificate"),
        };
    }, [query, showDetailsAction, hasAccessRight, columnOptions, actions, queryCommands]);
}
