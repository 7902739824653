import useExternalOrganicMaterialProducerIdListViewList from "./useExternalOrganicMaterialProducerIdListViewList";
import EntityListView from "../../components/entity/EntityListView";
import { useHelpContext } from "../../help/context-sensitivity/useHelpContext";

export default function ExternalOrganicMaterialProducerIdListView() {
    const externalOrganicMaterialProducerIdGridOptions = useExternalOrganicMaterialProducerIdListViewList();
    useHelpContext("external-organic-material-producer-id-list");

    return (
        <EntityListView
            heading="externalOrganicMaterialProducerId.list.header"
            gridOptions={externalOrganicMaterialProducerIdGridOptions}
        />
    );
}
