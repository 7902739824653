import React, { useMemo } from "react";
import { useHelpContext } from "../../../../help/context-sensitivity/useHelpContext";
import EntityDetailView from "../../../../components/entity/EntityDetailView";
import Tabs, { TabKey, TabProps } from "../../../../components/tabs/Tabs";
import { TypedDetailViewProps } from "../../../../components/detailView/DetailView";
import { TcProductDetails } from "../tcProductTypes";
import TcProductBasicData from "./TcProductBasicData";
import TcProductQuantitativeData from "./TcProductQuantitativeData";
import TcProductLogisticData from "./TcProductLogisticData";
import { ScProductReference } from "../../../../scopeCertificate/details/products/scProductTypes";
import { TransactionCertificateDetails } from "../../../transactionCertificateTypes";
import { insertIf } from "../../../../utils/arrayUtils";
import TcProductMaterialComposition from "./tcMaterial/TcProductMaterialComposition";

export default function TcProductDetailView({
    detailViewProps,
    form,
    scopeCertificateId,
    readOnlyProps,
    selectedScProduct,
    byProductWeightVisible,
    sublistVisible,
    materialCompositionTabVisible,
    tcStatus,
    refetchUsdaNopCompliance,
    cbProductReferenceNumber,
    ...rest
}: TcProductDetailViewProps) {
    useHelpContext("tc-product-details");

    const materialCompositionTab = useMemo<TabProps<TcProductTabs>[]>(
        () => [
            {
                heading: "tcProduct.detail.materialComposition_tab",
                element: (
                    <TcProductMaterialComposition
                        form={form}
                        readOnlyProps={readOnlyProps}
                        selectedScProduct={selectedScProduct}
                    />
                ),
            },
        ],
        [form, readOnlyProps, selectedScProduct]
    );

    return (
        <EntityDetailView {...rest} detailViewProps={detailViewProps} form={form}>
            <Tabs<TcProductDetails, TcProductTabs>
                tabs={[
                    {
                        heading: "tcProduct.detail.basicData_tab",
                        element: (
                            <TcProductBasicData
                                form={form}
                                scopeCertificateId={scopeCertificateId}
                                readOnlyProps={readOnlyProps}
                                selectedScProduct={selectedScProduct}
                                cbProductReferenceNumber={cbProductReferenceNumber}
                            />
                        ),
                    },
                    {
                        heading: "tcProduct.detail.quantitativeData_tab",
                        element: (
                            <TcProductQuantitativeData
                                form={form}
                                readOnlyProps={readOnlyProps}
                                selectedScProduct={selectedScProduct}
                                byProductWeightVisible={byProductWeightVisible}
                                sublistVisible={sublistVisible}
                                afterDeletion={refetchUsdaNopCompliance!}
                            />
                        ),
                    },
                    {
                        heading: "tcProduct.detail.logisticData_tab",
                        element: (
                            <TcProductLogisticData
                                form={form}
                                readOnlyProps={readOnlyProps}
                                selectedScProduct={selectedScProduct}
                            />
                        ),
                    },
                    ...insertIf<TabProps<TcProductTabs>>(materialCompositionTabVisible, ...materialCompositionTab),
                ]}
                errors={form.form.formState.errors}
                errorToTab={{
                    id: "internal",
                    tcProductNumber: "tcProduct.detail.basicData_tab",
                    relatedScProduct: "tcProduct.detail.basicData_tab",
                    sellerLegalName: "tcProduct.detail.basicData_tab",
                    sellerFacilityBusinessName: "tcProduct.detail.basicData_tab",
                    buyerLegalName: "tcProduct.detail.basicData_tab",
                    buyerFacilityBusinessName: "tcProduct.detail.basicData_tab",
                    usdaNopCompliant: "tcProduct.detail.basicData_tab",
                    numberOfUnits: "tcProduct.detail.quantitativeData_tab",
                    unitName: "tcProduct.detail.quantitativeData_tab",
                    netShippingWeight: "tcProduct.detail.quantitativeData_tab",
                    supplementaryWeight: "tcProduct.detail.quantitativeData_tab",
                    certifiedWeight: "tcProduct.detail.quantitativeData_tab",
                    nonCertifiedWeight: "tcProduct.detail.quantitativeData_tab",
                    availableQuantity: "tcProduct.detail.quantitativeData_tab",
                    byProductWeight: "tcProduct.detail.quantitativeData_tab",
                    availableByProductQuantity: "tcProduct.detail.quantitativeData_tab",
                    orderNumber: "tcProduct.detail.logisticData_tab",
                    articleNumber: "tcProduct.detail.logisticData_tab",
                    productionDate: "tcProduct.detail.logisticData_tab",
                    tcProcessors: "tcProduct.detail.logisticData_tab",
                    lastProcessor: "tcProduct.detail.logisticData_tab",
                    additionalInformation: "tcProduct.detail.logisticData_tab",
                    transactionCertificateStatus: "internal",
                    scopeCertificateId: "internal",
                    cbProductReferenceNumber: "tcProduct.detail.basicData_tab",
                }}
            />
        </EntityDetailView>
    );
}

type TcProductTabs = TabKey<
    | "tcProduct.detail.basicData_tab"
    | "tcProduct.detail.quantitativeData_tab"
    | "tcProduct.detail.logisticData_tab"
    | "tcProduct.detail.materialComposition_tab"
>;

export interface TcProductDetailViewProps extends TypedDetailViewProps<TcProductDetails> {
    scopeCertificateId?: string;
    readOnlyProps: TcProductReadOnlyProps;
    selectedScProduct?: ScProductReference;
    byProductWeightVisible?: boolean;
    materialCompositionTabVisible: boolean;
    tcStatus?: TransactionCertificateDetails["status"];
    sublistVisible: boolean;
    refetchUsdaNopCompliance?: Function;
    cbProductReferenceNumber?: string;
}

export interface TcProductReadOnlyProps {
    readOnly: boolean;
}
