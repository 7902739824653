import { Nullable, withChangedValueType } from "../utils/typeUtils";
import { env } from "../env";

export const DEFAULT_LANGUAGE = "en";
export const LANGUAGE_LOCAL_STORAGE_KEY = "gtb-language";

export const calculateRestrictedLanguages: () => string[] = () =>
    env.REACT_APP_RESTRICTED_LANGUAGES.split(",").map((language) => language.trim());

export type languageEnum = "en" | "de";

export const language: Record<languageEnum, string> = {
    en: "English",
    de: "Deutsch",
};

enum languageKey {
    en,
    de,
}

export type LanguageKey = keyof typeof languageKey;

export const getLanguageKeys = () => Object.keys(languageKey).filter((key) => isNaN(Number(key))) as LanguageKey[];

export const getInitialTranslatableValue = (): Nullable<TranslatableValue> =>
    getLanguageKeys().reduce((acc, currentKey) => {
        acc[currentKey] = null;
        return acc;
    }, {} as Nullable<TranslatableValue>);

export type TranslatableValue = {
    [key in LanguageKey]: string;
};

export type WithTranslationAsString<T> = withChangedValueType<T, TranslatableValue, string>;
