import { I18nKey } from "../../../i18n/useGtbTranslation";
import GtbPickList from "./GtbPickList";
import { SyntheticEvent, useMemo } from "react";

export interface UncontrolledEnumPicklistProps {
    enumRecords: Record<string, string>;
    value?: any;
    onChange: (
        event: SyntheticEvent,
        value:
            | Record<string, string>[keyof Record<string, string>]
            | Record<string, string>[keyof Record<string, string>][]
            | null
    ) => void;
    label?: I18nKey;
    className?: string;
    readOnly?: boolean;
    disableClearable?: boolean;
    borderless?: boolean;
}

export function UncontrolledEnumPickList({ label, enumRecords, ...rest }: UncontrolledEnumPicklistProps) {
    const enumPickListOptions = useMemo(() => buildEnumPicklistOptionsFromObject(enumRecords), [enumRecords]);

    return <GtbPickList label={label} itemId="id" itemLabel="label" data={enumPickListOptions} {...rest} />;
}

export const buildEnumPicklistOptionsFromObject: (obj: { [key: string]: string }) => {
    id: string;
    label: string;
}[] = (obj) => {
    return Object.entries(obj).map((e) => {
        return { id: e[0], label: e[1] };
    });
};
