import StringFilter from "./component/filter/StringFilter";
import BooleanCell from "./component/cell/BooleanCell";
import { GridColumn } from "./component/Grid";
import { filterType, setFilterType, SortOption, toggleSortOptionType } from "./component/useQueryBuilder";
import { formatDate, formatTimestamp, Precision } from "../../utils/formatter";
import { ComponentType, ReactNode } from "react";
import BooleanFilter from "./component/filter/BooleanFilter";
import { GridBodyCellProps } from "./component/GridBodyCell";
import { I18nKey } from "../../i18n/useGtbTranslation";
import DataPicklistFilter from "./component/filter/DataPicklistFilter";
import { backendUrlType } from "../../hooks/useAxios";
import OptionFilter from "./component/filter/OptionFilter";
import NumberFilter from "./component/filter/NumberFilter";
import DateFilter from "./component/filter/DateFilter";

/**
 * Builds a text column
 * @param key the key of the ItemType
 * @param minWidth the initial minimum Width when columns get automatically sized by the browser
 * @param title the heading(default is capitalized key)
 * @param contentProvider the function to get the value to be displayed(default is key prop of item)
 * @param visible  true, if the column should be displayed. False otherwise
 * @param disableSorting  if True, sorting on the column should be disabled.
 */
export const buildTextColumn = <ItemType,>({
    key,
    minWidth,
    title,
    contentProvider = (item) => item[key],
    visible = true,
    disableSorting = false,
    disableFiltering = false,
}: TextColumnProps<ItemType>): GridColumn<ItemType> => {
    return {
        key,
        visible,
        header: buildHeader(
            title,
            key,
            minWidth,
            ({ ...rest }) => <StringFilter itemKey={key} title={title} {...rest} />,
            disableSorting,
            disableFiltering
        ),
        bodyItem: buildBody(contentProvider),
    };
};

/**
 * Builds a number column
 * @param key the key of the ItemType
 * @param minWidth the initial minimum Width when columns get automatically sized by the browser
 * @param title the heading(default is capitalized key)
 * @param fixedDecimal if a ".0" should be appended on integers
 * @param contentProvider the function to get the value to be displayed(default is key prop of item)
 * @param visible true, if the column should be displayed. False otherwise
 * @param disableSorting  if True, sorting on the column should be disabled.
 * @param allowNegative true, if negative values should be allowed
 * @param allowDecimals true, if decimal values should be allowed
 */
export const buildNumberColumn = <ItemType,>({
    key,
    minWidth,
    title,
    fixedDecimal = false,
    allowDecimals = fixedDecimal,
    contentProvider = (item) =>
        fixedDecimal && item[key]?.toString().indexOf(".") === -1 ? item[key] + ".0" : item[key],
    visible = true,
    disableSorting = false,
    allowNegative,
}: NumberColumnProps<ItemType>): GridColumn<ItemType> => {
    return {
        key,
        visible,
        header: buildHeader(
            title,
            key,
            minWidth,
            ({ ...rest }) => (
                <NumberFilter
                    itemKey={key}
                    title={title}
                    allowNegative={allowNegative}
                    allowDecimals={allowDecimals}
                    {...rest}
                />
            ),
            disableSorting
        ),
        bodyItem: { ...buildBody(contentProvider) },
        className: "numberColumn",
    };
};

/**
 * Builds a Column representing boolean values
 * @param key the key of the ItemType -> the checkbox will present the key value from ItemType
 * @param minWidth the initial minimum Width when columns get automatically sized by the browser
 * @param title the heading(default is capitalized key)
 * @param visible true, if the column should be displayed. False otherwise
 * @param disableSorting  if True, sorting on the column should be disabled.
 */
export const buildBooleanColumn = <ItemType,>({
    key,
    minWidth,
    title,
    visible = true,
    disableSorting = false,
}: BooleanColumnProps<ItemType>): GridColumn<ItemType> => {
    return {
        key,
        visible,
        header: buildHeader(
            title,
            key,
            minWidth,
            ({ ...rest }) => <BooleanFilter itemKey={key} title={title} {...rest} />,
            disableSorting
        ),
        bodyItem: buildBody((item: ItemType) => <BooleanCell title={title} value={!!item[key]} />),
    };
};

function buildDateFilter<ItemType>(key: string, title: I18nKey) {
    return ({ ...rest }: FilterComponentProps<ItemType>) => <DateFilter itemKey={key} title={title} {...rest} />;
}

/**
 * Builds a date time column
 * @param key the key of the ItemType
 * @param minWidth the initial minimum Width when columns get automatically sized by the browser
 * @param title the heading(default is capitalized key)
 * @param visible true, if the column should be displayed. False otherwise
 */
export const buildDateColumn = <ItemType,>({
    key,
    minWidth,
    title,
    visible = true,
}: DateColumnProps<ItemType>): GridColumn<ItemType> => {
    return {
        key,
        visible,
        header: buildHeader(title, key, minWidth, buildDateFilter(key, title)),
        bodyItem: buildBody((item) => formatDate(item[key])),
    };
};

/**
 * Builds a date time column
 * @param key the key of the ItemType
 * @param minWidth the initial minimum Width when columns get automatically sized by the browser
 * @param title the heading(default is capitalized key)
 * @param visible true, if the column should be displayed. False otherwise
 * @param precision the precision of the presentation value
 */
export const buildDateTimeColumn = <ItemType,>({
    key,
    minWidth,
    title,
    visible = true,
    precision,
}: DateTimeColumnProps<ItemType>): GridColumn<ItemType> => {
    return {
        key,
        visible,
        header: buildHeader(title, key, minWidth, buildDateFilter(key, title)),
        bodyItem: buildBody((item) => formatTimestamp(item[key], precision)),
    };
};

/**
 * Builds a column for showing and filtering enum values
 * @param key the key of the ItemType
 * @param minWidth the initial minimum Width when columns get automatically sized by the browser
 * @param title the heading(default is capitalized key)
 * @param url the backend url for retrieving the possible filters
 * @param itemId the id of the filter attribute
 * @param itemLabel the label of the filter attribute
 * @param visible true, if the column should be displayed. False otherwise
 */
export const buildDataColumn = <ItemType, FilterDataType>({
    key,
    minWidth,
    title,
    url,
    itemId,
    itemLabel,
    visible = true,
}: OptionColumnProps<ItemType, FilterDataType>): GridColumn<ItemType> => {
    return {
        key,
        visible,
        header: buildHeader(title, key, minWidth, ({ ...rest }) => (
            <DataPicklistFilter url={url} itemKey={key} itemId={itemId} itemLabel={itemLabel} title={title} {...rest} />
        )),
        bodyItem: buildBody((item) => item[key]),
    };
};

/**
 * Builds a column for showing and filtering <strong>system</strong> enum values
 * @param key the key of the ItemType
 * @param minWidth the initial minimum Width when columns get automatically sized by the browser
 * @param title the heading(default is capitalized key)
 * @param enumRecord the Record presenting the enum and the corresponding translations/human-readable values
 * @param visible true, if the column should be displayed. False otherwise
 * @param translation function to translate
 */
export const buildSystemEnumColumn = <ItemType,>({
    key,
    minWidth,
    title,
    enumRecord,
    visible = true,
}: EnumColumnProps<ItemType>): GridColumn<ItemType> => {
    return {
        key,
        visible,
        header: buildHeader(title, key, minWidth, ({ ...rest }) => (
            <OptionFilter options={enumRecord} itemKey={key} title={title} {...rest} />
        )),
        bodyItem: buildBody((item) => enumRecord[item[key]]),
    };
};

const buildHeader = <ItemType,>(
    title: ColumnProps<ItemType>["title"] & I18nKey,
    key: ColumnProps<ItemType>["key"],
    minWidth: ColumnProps<ItemType>["minWidth"],
    FilterComponent?: ComponentType<FilterComponentProps<ItemType>>,
    disableSorting?: boolean,
    disableFiltering?: boolean
) => {
    return {
        key,
        minWidth,
        title,
        sortEnabled: !disableSorting,
        FilterComponent: !disableFiltering && FilterComponent,
    };
};

const buildBody = <ItemType,>(contentProvider: contentProviderFunc<ItemType>) => {
    // @ts-ignore
    const item: GridBodyCellProps<ItemType> = { contentProvider };
    return item;
};

type contentProviderFunc<ItemType> = (item: ItemType & { [key in itemKeyType<ItemType>]?: string }) => ReactNode;

interface TextColumnProps<ItemType> extends ColumnProps<ItemType> {
    contentProvider?: contentProviderFunc<ItemType>;
}

interface NumberColumnProps<ItemType> extends ColumnProps<ItemType> {
    contentProvider?: contentProviderFunc<ItemType>;
    allowNegative?: boolean;
    allowDecimals?: boolean;
    fixedDecimal?: boolean;
}

interface BooleanColumnProps<ItemType> extends ColumnProps<ItemType> {}

interface DateColumnProps<ItemType> extends ColumnProps<ItemType> {}

interface DateTimeColumnProps<ItemType> extends ColumnProps<ItemType> {
    precision?: Precision;
}

interface OptionColumnProps<ItemType, FilterDataType> extends ColumnProps<ItemType> {
    url: backendUrlType;
    itemId: keyof FilterDataType;
    itemLabel: keyof FilterDataType;
}

interface EnumColumnProps<ItemType> extends ColumnProps<ItemType> {
    enumRecord: Record<any, string>;
}

export interface FilterComponentProps<ItemType> {
    filter: any;
    setFilter: setFilterType<ItemType>;
    filterKey: itemKeyType<ItemType>;
}

export interface ColumnOptions<ItemType> {
    sort: { sortOption: SortOption<ItemType>; toggleSortOption: toggleSortOptionType<ItemType> };
    filter: {
        value: filterType<ItemType> & { [key in Extract<keyof ItemType, string>]?: any };
        setFilter: setFilterType<ItemType>;
        filterActive: boolean;
    };
}

export type itemKeyType<ItemType> = keyof ItemType &
    keyof { [R in keyof ItemType as R extends string ? R : never]: string } &
    string;

export interface ColumnProps<ItemType> {
    key: itemKeyType<ItemType>;
    minWidth: number;
    visible?: boolean;
    title: I18nKey;
    disableSorting?: boolean;
    disableFiltering?: boolean;
}
