import { yupResolver } from "@hookform/resolvers/yup";
import useIsAuthorised from "../../auth/useIsAuthorised";
import { MaterialContentRuleDetails } from "../materialContentRuleTypes";
import { materialContentRuleValidationSchema } from "./materialContentRuleValidationSchema";
import { defaultMaterialContentRulesSortOption } from "../useMaterialContentRuleSubList";
import useResolvedRoute, { routeEnum } from "../../components/routing/useResolvedRoute";
import { useMemo } from "react";
import useDomainEntityDetailsEdit from "../../hooks/details/useDomainEntityDetailsEdit";
import { useQuery } from "../../hooks/useAxios";
import { LabelGradeReference } from "../../labelGrade/labelGradeTypes";
import { VersionReference } from "../../standard/version/versionTypes";

export default function useMaterialContentRuleEdit() {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedId } = useResolvedRoute();
    const standardId = getResolvedId("standard");
    const standardVersionId = getResolvedId("standardVersion");
    const labelGradeId = getResolvedId("labelGrade");
    const materialContentRuleId = getResolvedId("materialContentRule");

    const { data: standardVersion, isLoading: isVersionLoading } = useQuery<VersionReference>({
        url: "/certificate-service/references/standard-versions/" + standardVersionId,
    });

    const { data: labelGrade, isLoading: isLabelGradeLoading } = useQuery<LabelGradeReference>({
        url: "/certificate-service/references/label-grades/" + labelGradeId,
    });

    const { detailViewProps, form, initialData, isReadOnly, title, recordNavigationProps } =
        useDomainEntityDetailsEdit<MaterialContentRuleDetails>({
            baseUrl: "/certificate-service/material-content-rules/" + materialContentRuleId,
            resolver: yupResolver(materialContentRuleValidationSchema),
            frontendRoute: "materialContentRule",
            recordNavigationDefaultSort: defaultMaterialContentRulesSortOption,
            titleBuilder: (item) => `${item.certifiedMaterialCode} ${item.certifiedMaterialName}`,
            canSave: hasAccessRight("updateMasterData"),
            historyColumns: [
                {
                    identifier: "certifiedMaterial",
                    title: "materialContentRule.history.certifiedMaterial_column",
                },
            ],
        });

    const createRoute = useMemo<routeEnum | undefined>(() => {
        return hasAccessRight("createMasterData") ? "materialContentRule" : undefined;
    }, [hasAccessRight]);

    return {
        detailViewProps: {
            ...detailViewProps,
            isLoading: detailViewProps.isLoading || isLabelGradeLoading || isVersionLoading,
        },
        form,
        title,
        initialData,
        readOnly: isReadOnly,
        standard: standardId,
        standardVersion: standardVersion
            ? `${standardVersion.standardAcronym} ${standardVersion.versionNumber}`
            : undefined,
        labelGrade,
        subListVisible: true,
        recordNavigationProps,
        createRoute,
    };
}
