import useExternalIdSubList from "../useExternalIdSubList";
import useResolvedRoute from "../../components/routing/useResolvedRoute";

export default function useExternalOrganicMaterialProducerIdSubList() {
    const { getResolvedId } = useResolvedRoute();
    const organicMaterialProducerId = getResolvedId("organicMaterialProducer");

    return useExternalIdSubList({
        tableName: "organicMaterialProducerExternalOrganicMaterialProducerIds",
        i18nKey: "externalOrganicMaterialProducerId",
        routeEnumKey: organicMaterialProducerId
            ? "organicMaterialProducerExternalOrganicMaterialProducerId"
            : "externalOrganicMaterialProducerId",
        parentIdParamValue: organicMaterialProducerId,
    });
}
