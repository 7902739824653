import useDetailsCreate from "../../hooks/details/useDetailsCreate";
import { yupResolver } from "@hookform/resolvers/yup";
import { Nullable } from "../../utils/typeUtils";
import { AllowedMaterialDetailViewProps, AllowedMaterialReadOnlyProps } from "./AllowedMaterialDetailView";
import { allowedMaterialValidationSchema } from "./allowedMaterialValidationSchema";
import { AllowedMaterialDetails } from "../allowedMaterialTypes";
import { useMemo } from "react";
import useResolvedRoute, { routeEnum } from "../../components/routing/useResolvedRoute";

export default function useAllowedMaterialCreate(): AllowedMaterialDetailViewProps {
    const { getResolvedId } = useResolvedRoute();
    const standardId = getResolvedId("standard");
    const rawMaterialId = getResolvedId("rawMaterial");

    const initialData = useMemo<Nullable<AllowedMaterialDetails>>(() => {
        return {
            id: null,
            standard: standardId || null,
            rawMaterial: rawMaterialId || null,
            systemId: null,
            standardOfficialName: null,
            rawMaterialName: null,
        };
    }, [rawMaterialId, standardId]);

    const { detailViewProps, form, title } = useDetailsCreate<AllowedMaterialDetails>({
        title: "allowedMaterial.detail.newRecord_title",
        frontendRoute: "allowedMaterial",
        initialData,
        resolver: yupResolver(allowedMaterialValidationSchema),
        saveUrl: "/certificate-service/allowed-materials",
    });

    const readOnlyProps: AllowedMaterialReadOnlyProps = {
        isRawMaterialReadOnly: !!rawMaterialId,
        isStandardReadOnly: !!standardId,
    };

    return {
        detailViewProps,
        form,
        title,
        readOnlyProps,
        createRoute: (rawMaterialId ? "rawMaterialAllowedMaterial" : "standardAllowedMaterial") as routeEnum,
    };
}
