import { GridProps } from "../DataGrid";
import { ComponentType } from "react";
import GridItemCount from "../component/GridItemCount";
import { Entity } from "../../../utils/typeUtils";

export function withTotalItemCount<ItemType extends Entity>(Grid: ComponentType<GridProps<ItemType>>) {
    return ({ total, gridControlsRight, ...rest }: GridProps<ItemType>) => {
        return (
            <Grid
                key="itemCount"
                {...rest}
                total={total}
                gridControlsRight={[
                    <GridItemCount
                        key="itemCount"
                        itemCountTranslation={{
                            key: "components.grid.itemCount",
                            options: { count: total },
                        }}
                    />,
                    ...(gridControlsRight ?? []),
                ]}
            />
        );
    };
}
