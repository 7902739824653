import { ReactElement, useMemo } from "react";
import useGtbTranslation, { I18nKey } from "../i18n/useGtbTranslation";

export type IconName =
    | "help"
    | "settings"
    | "users"
    | "caret-down"
    | "eye"
    | "eye-slash"
    | "cross"
    | "info"
    | "warning"
    | "error"
    | "search"
    | "pencil"
    | "plus"
    | "floppyDisk"
    | "comment"
    | "history"
    | "undo"
    | "back"
    | "chevron-left"
    | "chevron-right"
    | "chevrons-left"
    | "chevrons-right"
    | "calendar"
    | "logo"
    | "arrow-up"
    | "arrow-down"
    | "checkmark"
    | "new-record"
    | "notepad"
    | "clock"
    | "percent"
    | "download"
    | "dashboardArrow"
    | "trash"
    | "locationMarker"
    | "circle";

export interface IconProps {
    name: IconName;
    size?: number;
    className?: string;
    accessibility?: { id: string; label: I18nKey };
}

/**
 *  Creates an icon representing the given IconName
 * @param name the name of the icon
 * @param size the size of the icon
 * @param className the class name
 * @param accessibility if present, the Icon will be read out by screen readers with the given label. Also, a title with the given id will be added.
 * @constructor
 */
function Icon({ name, size = 25, className, accessibility }: IconProps) {
    const translation = useGtbTranslation();

    const accessibleIconProps = useMemo(() => {
        if (!accessibility) {
            return {};
        }
        return {
            "aria-hidden": false,
            role: "img",
            "aria-labelledby": accessibility.id,
        };
    }, [accessibility]);

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox={iconPropertyRecord[name].viewBox}
            height={size}
            width={size}
            /* fills the icon with the color property from its parent*/
            fill="currentColor"
            aria-hidden={true}
            className={className}
            {...accessibleIconProps}
        >
            {accessibility && (
                <title id={accessibleIconProps["aria-labelledby"]}>{translation(accessibility.label)}</title>
            )}
            {iconPropertyRecord[name].paths}
        </svg>
    );
}

export default Icon;

/**
 * Record for required properties for icons used as a svg. The properties can be requested by the iconName as the key
 */
const iconPropertyRecord: Record<IconName, { viewBox: string; paths: ReactElement }> = {
    help: {
        viewBox: "0 0 22.138 22.138",
        paths: (
            <path d="M11.069,0A11.069,11.069,0,1,0,22.138,11.069,11.069,11.069,0,0,0,11.069,0Zm1.107,18.817H9.962V16.6H12.176ZM14.467,10.239l-1,1.018a3.7682,3.7682,0,0,0-1.3,3.132H9.962v-.553a4.45508,4.45508,0,0,1,1.3-3.132l1.373-1.395a2.16415,2.16415,0,0,0,.653-1.561,2.214,2.214,0,0,0-4.428,0H6.641a4.428,4.428,0,0,1,8.855,0A3.52162,3.52162,0,0,1,14.467,10.239Z" />
        ),
    },
    settings: {
        viewBox: "0 0 23.31799 23.32404",
        paths: (
            <path d="M21.393,11.65906A3,3,0,0,1,23.318,8.859a11.89462,11.89462,0,0,0-1.439-3.467,3.04016,3.04016,0,0,1-1.221.261A2.994,2.994,0,0,1,17.92,1.439,11.85854,11.85854,0,0,0,14.459,0a3,3,0,0,1-3.877,1.723A2.99983,2.99983,0,0,1,8.859,0,11.89472,11.89472,0,0,0,5.392,1.439,2.994,2.994,0,0,1,2.653,5.653a2.94145,2.94145,0,0,1-1.221-.261A12.15448,12.15448,0,0,0,0,8.866a3,3,0,0,1,1.72711,3.87518A2.99991,2.99991,0,0,1,.006,14.466a11.89472,11.89472,0,0,0,1.439,3.467A3,3,0,0,1,5.4,21.885a11.96409,11.96409,0,0,0,3.467,1.439,2.993,2.993,0,0,1,5.587,0,11.89476,11.89476,0,0,0,3.467-1.439,3,3,0,0,1,3.953-3.953,11.96305,11.96305,0,0,0,1.439-3.467A3.01491,3.01491,0,0,1,21.393,11.65906Zm-9.678,4.852h-.002a4.857,4.857,0,1,1,.002,0Z" />
        ),
    },
    users: {
        viewBox: "0 0 25.34929 22.97144",
        paths: (
            <g>
                <path d="M16.41718,7.24359a7.19825,7.19825,0,0,1-.74268,3.192c.16693.016.32837.05035.49951.05035h.001A5.24309,5.24309,0,1,0,12.994,1.10138,7.23731,7.23731,0,0,1,16.41718,7.24359Z" />
                <path d="M25.349,19.001v-1.7a5.506,5.506,0,0,0-5.50494-5.5l.001-.004H19.16a7.11747,7.11747,0,0,1-3.29119.639,7.51358,7.51358,0,0,1,4.48028,6.86553V20.971H23.4126a1.93625,1.93625,0,0,0,1.93646-1.966Z" />
                <path d="M9.17456,12.48621a5.212,5.212,0,0,0,.82959-.08362,5.16739,5.16739,0,0,0,1.56421-.5199,5.20375,5.20375,0,1,0-2.3938.60352Z" />
                <path d="M12.84442,13.79688h-.684a7.13817,7.13817,0,0,1-2.98584.65527A7.06332,7.06332,0,0,1,7.88,14.32379a7.24054,7.24054,0,0,1-1.69122-.52691h-.684A5.50615,5.50615,0,0,0,0,19.30157v1.70386a1.96653,1.96653,0,0,0,1.966,1.966H16.38318a1.96647,1.96647,0,0,0,1.96594-1.966V19.30157A5.5061,5.5061,0,0,0,12.84442,13.79688Z" />
            </g>
        ),
    },
    "caret-down": {
        viewBox: "0 0 15.252 9.04",
        paths: (
            <path
                d="m1.414 1.414 6.212 6.212 6.212-6.212"
                strokeLinecap="round"
                strokeWidth="2"
                fill="none"
                stroke="currentColor"
            />
        ),
    },
    eye: {
        viewBox: "0 0 512 512",
        paths: (
            <>
                <circle cx="256" cy="256" r="64" />
                <path d="M490.84 238.6c-26.46-40.92-60.79-75.68-99.27-100.53C349 110.55 302 96 255.66 96c-42.52 0-84.33 12.15-124.27 36.11c-40.73 24.43-77.63 60.12-109.68 106.07a31.92 31.92 0 0 0-.64 35.54c26.41 41.33 60.4 76.14 98.28 100.65C162 402 207.9 416 255.66 416c46.71 0 93.81-14.43 136.2-41.72c38.46-24.77 72.72-59.66 99.08-100.92a32.2 32.2 0 0 0-.1-34.76ZM256 352a96 96 0 1 1 96-96a96.11 96.11 0 0 1-96 96Z" />
            </>
        ),
    },
    "eye-slash": {
        viewBox: "0 0 512 512",
        paths: (
            <>
                <path d="M432 448a15.92 15.92 0 0 1-11.31-4.69l-352-352a16 16 0 0 1 22.62-22.62l352 352A16 16 0 0 1 432 448ZM248 315.85l-51.79-51.79a2 2 0 0 0-3.39 1.69a64.11 64.11 0 0 0 53.49 53.49a2 2 0 0 0 1.69-3.39Zm16-119.7L315.87 248a2 2 0 0 0 3.4-1.69a64.13 64.13 0 0 0-53.55-53.55a2 2 0 0 0-1.72 3.39Z" />
                <path d="M491 273.36a32.2 32.2 0 0 0-.1-34.76c-26.46-40.92-60.79-75.68-99.27-100.53C349 110.55 302 96 255.68 96a226.54 226.54 0 0 0-71.82 11.79a4 4 0 0 0-1.56 6.63l47.24 47.24a4 4 0 0 0 3.82 1.05a96 96 0 0 1 116 116a4 4 0 0 0 1.05 3.81l67.95 68a4 4 0 0 0 5.4.24a343.81 343.81 0 0 0 67.24-77.4ZM256 352a96 96 0 0 1-93.3-118.63a4 4 0 0 0-1.05-3.81l-66.84-66.87a4 4 0 0 0-5.41-.23c-24.39 20.81-47 46.13-67.67 75.72a31.92 31.92 0 0 0-.64 35.54c26.41 41.33 60.39 76.14 98.28 100.65C162.06 402 207.92 416 255.68 416a238.22 238.22 0 0 0 72.64-11.55a4 4 0 0 0 1.61-6.64l-47.47-47.46a4 4 0 0 0-3.81-1.05A96 96 0 0 1 256 352Z" />
            </>
        ),
    },
    cross: {
        viewBox: "0 0 15.958 15.52",
        paths: (
            <g fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="2">
                <path d="m1.414 14.106 13.13-12.692" />
                <path d="M14.544 14.106 1.414 1.414" />
            </g>
        ),
    },
    info: {
        viewBox: "0 0 38 38",
        paths: (
            <path
                d="M19,0A19,19,0,1,0,38,19,19,19,0,0,0,19,0m1.5,28.75h-3v-12h3Zm.933-16.443A2.535,2.535,0,0,1,19,14.25a2.455,2.455,0,0,1-.5-.051,2.5,2.5,0,0,1-1.929-1.892,2.44,2.44,0,0,1-.068-.557,2.531,2.531,0,0,1,.051-.5,2.5,2.5,0,0,1,4.9,0,2.532,2.532,0,0,1,.051.5,2.5,2.5,0,0,1-.068.557"
                fill="currentColor"
            />
        ),
    },
    warning: {
        viewBox: "0 0 38.712 34.908",
        paths: (
            <path
                d="M38.323,30.431,21.967,1.523a3,3,0,0,0-5.222,0L.389,30.431A3,3,0,0,0,3,34.908H35.712a3,3,0,0,0,2.611-4.477M17.855,9.908h3v12h-3Zm3.95,17.5a2.5,2.5,0,0,1-4.9,0,2.531,2.531,0,0,1-.051-.5,2.455,2.455,0,0,1,.067-.553,2.535,2.535,0,0,1,2.433-1.947,2.455,2.455,0,0,1,.5.051,2.526,2.526,0,0,1,2,2.449,2.532,2.532,0,0,1-.051.5"
                fill="currentColor"
            />
        ),
    },
    error: {
        viewBox: "0 0 38 38",
        paths: (
            <path
                d="M19,0A19,19,0,1,0,38,19,19,19,0,0,0,19,0m8.06,24.94-2.121,2.121L19,21.122,13.06,27.061,10.939,24.94,16.878,19l-5.939-5.94L13.06,10.94,19,16.879l5.94-5.939,2.121,2.121L21.121,19Z"
                fill="currentColor"
            />
        ),
    },
    search: {
        viewBox: "0 0 14 14",
        paths: (
            <path
                d="M11.685,9.822a5.537,5.537,0,1,0-1.864,1.862l3.757,3.756,1.863-1.864Zm-4.721.56a3.416,3.416,0,1,1,3.417-3.414,3.421,3.421,0,0,1-3.417,3.414Z"
                fill="currentColor"
            />
        ),
    },
    pencil: {
        viewBox: "0 0 12.994 12.994",
        paths: <path d="M9.746,0,8.121,1.624,11.37,4.873l1.624-1.624ZM6.5,3.249,0,9.746v3.249H3.249l6.5-6.5Z" />,
    },
    plus: {
        viewBox: "0 0 448 512",
        paths: (
            <path d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z" />
        ),
    },
    floppyDisk: {
        viewBox: "0 0 23.606 25",
        paths: (
            <path d="M23.531,5.72a1.9,1.9,0,0,0-.218-.489,1.827,1.827,0,0,0-.218-.282L18.933.542A1.71,1.71,0,0,0,17.982.03.7.7,0,0,0,17.789,0H1.769A1.743,1.743,0,0,0,.3.826a1.921,1.921,0,0,0-.266.67A2,2,0,0,0,0,1.873V23.127A1.823,1.823,0,0,0,1.769,25H21.838a1.684,1.684,0,0,0,.989-.32,1.857,1.857,0,0,0,.641-.824,1.906,1.906,0,0,0,.1-.351,2.006,2.006,0,0,0,.036-.377V6.258a1.949,1.949,0,0,0-.075-.539M16.855,9.07H6.751a.829.829,0,0,1-.8-.851V1.495H17.659V8.219a.829.829,0,0,1-.8.851" />
        ),
    },
    comment: {
        viewBox: "0 0 24 21.225",
        paths: (
            <path d="M21.609 0H2.391A2.392 2.392 0 0 0 0 2.391v12.217A2.392 2.392 0 0 0 2.391 17h10.138l2.665 3.783a1.041 1.041 0 0 0 1.7 0L19.559 17h2.047a2.391 2.391 0 0 0 2.391-2.392V2.391A2.391 2.391 0 0 0 21.609 0Z" />
        ),
    },
    history: {
        viewBox: "0 0 27.573 23.634",
        paths: (
            <path d="M15.756 0A11.818 11.818 0 0 0 3.939 11.817H0l5.108 5.108.092.184 5.3-5.292H6.565a9.235 9.235 0 1 1 2.7 6.486l-1.86 1.865A11.814 11.814 0 1 0 15.756 0Zm-1.313 6.565v6.565l5.62 3.335.945-1.589-4.6-2.731v-5.58Z" />
        ),
    },
    undo: {
        viewBox: "0 0 22.695 25.115",
        paths: (
            <g fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="2">
                <path d="M1,2.1v9.16h9.161" />
                <path d="M2.381,9.752,9.175,2.957a6.682,6.682,0,0,1,9.45,9.448L7.658,23.372" />
            </g>
        ),
    },
    back: {
        viewBox: "0 0 23.254 22.456",
        paths: (
            <g fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="2">
                <path d="m7.892 8.086-6.477 6.477 6.477 6.478" />
                <path d="M1.413 14.564h20.84V1h-10.7" />
            </g>
        ),
    },
    "chevron-left": {
        viewBox: "0 0 12.853 22.874",
        paths: (
            <path
                d="M11.439 1.414 1.415 11.437 11.439 21.46"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeWidth="2"
            />
        ),
    },
    "chevrons-left": {
        viewBox: "0 0 23.955 22.874",
        paths: (
            <g fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="2">
                <path d="M22.54 1.414 12.516 11.437 22.54 21.46" />
                <path d="M11.439 1.414 1.415 11.437 11.439 21.46" />
            </g>
        ),
    },
    "chevron-right": {
        viewBox: "0 0 12.853 22.874",
        paths: (
            <path
                d="m1.414 21.46 10.024-10.023L1.414 1.414"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeWidth="2"
            />
        ),
    },
    "chevrons-right": {
        viewBox: "0 0 23.955 22.874",
        paths: (
            <g fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="2">
                <path d="m1.414 21.459 10.024-10.023L1.414 1.413" />
                <path d="M12.516 21.459 22.54 11.436 12.516 1.413" />
            </g>
        ),
    },
    calendar: {
        viewBox: "0 0 19.484 22.267",
        paths: (
            <path
                fill="currentColor"
                d="M.522 8.35h18.44a.523.523 0 0 1 .522.522v11.307a2.088 2.088 0 0 1-2.084 2.088H2.088A2.088 2.088 0 0 1 0 20.179V8.872a.523.523 0 0 1 .522-.522Zm18.961-1.913V4.871A2.088 2.088 0 0 0 17.4 2.783h-2.092V.522A.523.523 0 0 0 14.787 0h-1.74a.523.523 0 0 0-.522.522v2.261H6.958V.522A.523.523 0 0 0 6.437 0H4.7a.523.523 0 0 0-.522.522v2.261h-2.09A2.088 2.088 0 0 0 0 4.871v1.566a.523.523 0 0 0 .522.522h18.44a.523.523 0 0 0 .521-.522Z"
            />
        ),
    },
    logo: {
        viewBox: "0 0 130 130",
        paths: (
            <>
                <path
                    fill={"#118e49"}
                    d="m69.78 69.017 28.097 48.664c.708 1.226 2.478 1.226 3.186 0l28.095-48.664c.708-1.226-.177-2.759-1.592-2.759H71.374c-1.416 0-2.301 1.533-1.593 2.76z"
                >
                    <animate
                        attributeName="fill-opacity"
                        begin="1s"
                        dur="1s"
                        values="1;.2;1"
                        calcMode="linear"
                        repeatCount="indefinite"
                    />
                </path>

                <path
                    fill={"#9dd85b"}
                    d="M109.845 22.296 97.397 34.422a1.166 1.166 0 0 0-.078 1.585c4.51 5.485 7.741 12.188 9.104 19.346.106.556.579.96 1.144.96h17.542c.9 0 1.614-.797 1.49-1.69-1.662-11.957-6.928-23.306-14.578-32.243-.558-.651-1.562-.682-2.176-.084z"
                >
                    <animate
                        attributeName="fill-opacity"
                        begin="100ms"
                        dur="1s"
                        values="1;.2;1"
                        calcMode="linear"
                        repeatCount="indefinite"
                    />
                </path>

                <path
                    fill={"#cf9c27"}
                    d="m93.674 30.624 12.45-12.129c.615-.6.608-1.606-.03-2.18C98.574 9.555 89.442 4.56 79.349 1.98c-.832-.213-1.677.341-1.833 1.186l-3.17 17.103c-.112.602.26 1.175.85 1.338a43.512 43.512 0 0 1 16.892 9.053c.463.401 1.147.39 1.586-.037z"
                >
                    <animate
                        attributeName="fill-opacity"
                        begin="200ms"
                        dur="1s"
                        values="1;.2;1"
                        calcMode="linear"
                        repeatCount="indefinite"
                    />
                </path>

                <path
                    fill={"#118e49"}
                    d="M69.114 19.302l3.17-17.105c.156-.844-.434-1.664-1.287-1.765a63.314 63.314 0 0 0-30.113 3.794c-.8.307-1.167 1.244-.808 2.024l7.278 15.785c.257.557.891.811 1.468.603a43.338 43.338 0 0 1 19.017-2.392c.609.06 1.163-.342 1.275-.944z"
                >
                    <animate
                        attributeName="fill-opacity"
                        begin="300ms"
                        dur="1s"
                        values="1;.2;1"
                        calcMode="linear"
                        repeatCount="indefinite"
                    />
                </path>
                <path
                    fill={"#0c655f"}
                    d="M42.505 24.217 35.243 8.465c-.36-.781-1.315-1.11-2.07-.698A64.087 64.087 0 0 0 10.766 28.22c-.48.714-.239 1.695.506 2.125l15.027 8.676c.53.306 1.194.148 1.544-.353a43.952 43.952 0 0 1 14.17-12.945c.53-.304.748-.95.492-1.506z"
                >
                    <animate
                        attributeName="fill-opacity"
                        begin="400ms"
                        dur="1s"
                        values="1;.2;1"
                        calcMode="linear"
                        repeatCount="indefinite"
                    />
                </path>
                <path
                    fill={"#118e49"}
                    d="M23.657 43.639 8.59 34.94c-.744-.43-1.714-.147-2.093.624A63.313 63.313 0 0 0 .022 65.216c.022.858.783 1.516 1.638 1.437l17.31-1.59a1.167 1.167 0 0 0 1.057-1.183 43.338 43.338 0 0 1 4.094-18.724c.26-.555.066-1.211-.464-1.517z"
                >
                    <animate
                        attributeName="fill-opacity"
                        begin="500ms"
                        dur="1s"
                        values="1;.2;1"
                        calcMode="linear"
                        repeatCount="indefinite"
                    />
                </path>
                <path
                    fill={"#d76952"}
                    d="M19.443 70.359 2.12 71.951c-.856.078-1.485.869-1.35 1.717a63.314 63.314 0 0 0 11.825 27.953c.514.687 1.515.785 2.167.228l13.218-11.29a1.167 1.167 0 0 0 .181-1.576 43.338 43.338 0 0 1-7.463-17.653 1.169 1.169 0 0 0-1.255-.971z"
                >
                    <animate
                        attributeName="fill-opacity"
                        begin="600ms"
                        dur="1s"
                        values="1;.2;1"
                        calcMode="linear"
                        repeatCount="indefinite"
                    />
                </path>
                <path
                    fill={"#3f9a96"}
                    d="m31.422 94.613-13.205 11.278c-.653.558-.712 1.564-.112 2.179 7.09 7.257 15.893 12.827 25.752 16.053.817.268 1.696-.23 1.908-1.064l4.273-16.829a1.168 1.168 0 0 0-.76-1.39 43.68 43.68 0 0 1-16.272-10.16 1.167 1.167 0 0 0-1.584-.067z"
                >
                    <animate
                        attributeName="fill-opacity"
                        begin="700ms"
                        dur="1s"
                        values="1;.2;1"
                        calcMode="linear"
                        repeatCount="indefinite"
                    />
                </path>

                <path
                    fill={"#0c655f"}
                    d="M77.841 104.862c-6.654 2.381-14.26 3.006-21.31 1.815a1.164 1.164 0 0 0-1.335.858l-4.278 16.85c-.211.83.317 1.685 1.159 1.843 11.69 2.19 24.314.878 35.225-3.539.835-.338 1.165-1.353.715-2.133L79.25 105.37a1.161 1.161 0 0 0-1.409-.508z"
                >
                    <animate
                        attributeName="fill-opacity"
                        begin="800ms"
                        dur="1s"
                        values="1;.2;1"
                        calcMode="linear"
                        repeatCount="indefinite"
                    />
                </path>
            </>
        ),
    },
    "arrow-up": {
        viewBox: "0 0 8.068 12.914",
        paths: (
            <path d="M.293 5.154c.391.391 1.023.391 1.414 0l1.327-1.326v8.086c0 .552.448 1 1 1s1-.448 1-1V3.828l1.327 1.327c.391.391 1.023.391 1.414 0s.391-1.023 0-1.414L4.035 0 .293 3.74c-.391.391-.391 1.024 0 1.414z" />
        ),
    },
    "arrow-down": {
        viewBox: "0 0 8.068 12.914",
        paths: (
            <path d="M7.775 7.759c-.391-.391-1.023-.391-1.414 0L5.034 9.086V1c0-.552-.448-1-1-1s-1 .448-1 1v8.086L1.707 7.759c-.391-.391-1.023-.391-1.414 0s-.391 1.023 0 1.414l3.74 3.74 3.742-3.74c.391-.39.391-1.023 0-1.414z" />
        ),
    },
    checkmark: {
        viewBox: "0 0 30.16 22.491",
        paths: (
            <path d="m10.244 22.049-9.8-9.8a1.508 1.508 0 0 1 0-2.133l2.133-2.133a1.508 1.508 0 0 1 2.133 0l6.6 6.6L25.453.442a1.508 1.508 0 0 1 2.133 0l2.133 2.133a1.508 1.508 0 0 1 0 2.133L12.376 22.05a1.508 1.508 0 0 1-2.132-.001Z" />
        ),
    },
    notepad: {
        viewBox: "0 0 21.375 28.125",
        paths: (
            <g fill="currentColor">
                <path d="M14.625 6.962h6.4a.348.348 0 0 0 .352-.352A2.081 2.081 0 0 0 20.625 5L15.257.527a2.258 2.258 0 0 0-1.448-.52.519.519 0 0 0-.52.52v5.1a1.335 1.335 0 0 0 1.336 1.335Z" />
                <path d="M11.46 5.625V0H2.25A2.257 2.257 0 0 0 0 2.25v23.625a2.257 2.257 0 0 0 2.25 2.25h16.875a2.257 2.257 0 0 0 2.25-2.25V8.789h-6.75a3.169 3.169 0 0 1-3.165-3.164Z" />
            </g>
        ),
    },
    clock: {
        viewBox: "0 0 29.457 29.457",
        paths: (
            <path d="M14.725 0a14.728 14.728 0 1 0 14.732 14.725A14.726 14.726 0 0 0 14.725 0Zm.991 16a.994.994 0 0 1-.991.991h-6.8a.9915.9915 0 1 1 0-1.983h5.806V5.665a.9915.9915 0 1 1 1.983 0Z" />
        ),
    },
    percent: {
        viewBox: "0 0 28 28",
        paths: (
            <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4">
                <path d="m24.5 3.5-21 21" />
                <path d="M9.5 5.75A3.75 3.75 0 1 1 5.75 2 3.75 3.75 0 0 1 9.5 5.75Z" />
                <path d="M26 22.25a3.75 3.75 0 1 1-3.75-3.75A3.75 3.75 0 0 1 26 22.25Z" />
            </g>
        ),
    },
    "new-record": {
        viewBox: "0 0 23.607 30.284",
        paths: (
            <>
                <path
                    d="M13.1,6.888h6.334a.344.344,0,0,0,.348-.34V6.539a2.062,2.062,0,0,0-.745-1.593L13.727.521A2.239,2.239,0,0,0,12.294.006a.514.514,0,0,0-.515.512V5.567A1.321,1.321,0,0,0,13.1,6.888Z"
                    transform="translate(1.369 0.001)"
                />
                <path d="M17.265,15.813a8.07,8.07,0,0,1,3.885.991V8.7H14.471a3.137,3.137,0,0,1-3.132-3.131V0H2.227A2.232,2.232,0,0,0,0,2.226V25.6a2.232,2.232,0,0,0,2.227,2.226h7.9a8.124,8.124,0,0,1,7.136-12.015Z" />
                <path
                    d="M20.012,17.105a6.335,6.335,0,1,0,2.458,5A6.3,6.3,0,0,0,20.012,17.105Zm0,5.736a.867.867,0,0,1-.48.16H17.02v2.513a.894.894,0,0,1-1.786,0V23H12.722a.893.893,0,0,1,0-1.786h2.513V18.7a.893.893,0,1,1,1.786,0v2.512h2.512a.88.88,0,0,1,.48,1.626Z"
                    transform="translate(1.137 1.833)"
                />
            </>
        ),
    },
    download: {
        viewBox: "0 0 10.005 12.149",
        paths: (
            <path
                d="M19,10.715H16.144V6.427H11.856v4.288H9l5,5ZM9,17.147v1.429H19V17.147H9Z"
                transform="translate(-8.997 -6.427)"
                fill="#7e8a91"
            />
        ),
    },
    dashboardArrow: {
        viewBox: "0 0 59.932 70.591",
        paths: (
            <path d="M49.151.346c-.638-.53-1.585-.441-2.112.195-.53.637-.442 1.583.194 2.112l6.769 5.623C27.01 11.046 13.778 26.936 7.403 40.02.306 54.587.009 68.483 0 69.067c-.013.828.648 1.51 1.476 1.522.008.001.017.001.025.001.817 0 1.486-.656 1.5-1.476.002-.138.309-13.899 7.145-27.874 8.726-17.835 23.616-27.891 44.249-29.966l-5.726 6.893c-.53.637-.442 1.583.194 2.112.28.233.62.346.958.346.431 0 .858-.185 1.154-.542l8.957-10.781L49.151.346z" />
        ),
    },
    trash: {
        viewBox: "0 0 12.727 15.272",
        paths: (
            <path d="M1.273 13.579a1.7 1.7 0 0 0 1.7 1.693h6.788a1.7 1.7 0 0 0 1.7-1.693V3.818H1.273ZM12.727 1.273H9.545L8.48 0H4.25L3.182 1.273H0v1.272h12.727Z" />
        ),
    },
    locationMarker: {
        viewBox: "0 0 41.5 55.239",
        paths: (
            <switch>
                <g>
                    <path
                        d="M34.892 6.608C31.273 2.989 26.273.75 20.75.75S10.227 2.989 6.608 6.608.75 15.227.75 20.75c0 11.026 7.564 14.392 12.564 21.881 3.085 4.621 5.194 8.716 6.357 11.175.431.911 1.727.911 2.158 0 1.163-2.459 3.272-6.554 6.357-11.175 5-7.489 12.564-10.855 12.564-21.881 0-5.523-2.239-10.523-5.858-14.142z"
                        strokeWidth={5}
                        strokeMiterlimit={10}
                        stroke="currentColor"
                        fill="none"
                    />
                    <circle cx="20.75" cy="20.75" r="7.436" fill="currentColor" />
                </g>
            </switch>
        ),
    },
    circle: {
        viewBox: "0 0 10 10",
        paths: <circle cx="5" cy="5" r="5" fill="currentColor" />,
    },
};
