import { InputQuantityDetails } from "../inputQuantityTypes";
import InputQuantityBasicData, { InputQuantityBasicDataProps } from "./InputQuantityBasicData";
import { TypedDetailViewProps } from "../../../../../../components/detailView/DetailView";
import Tabs, { TabKey } from "../../../../../../components/tabs/Tabs";
import { useHelpContext } from "../../../../../../help/context-sensitivity/useHelpContext";
import EntityDetailView from "../../../../../../components/entity/EntityDetailView";

export default function InputQuantityDetailView({
    form,
    readOnly,
    currentSourceType,
    inputSourceSelectionVisible,
    ...rest
}: InputQuantityDetailViewProps) {
    useHelpContext("input-quantity-details");

    return (
        <EntityDetailView {...rest} form={form}>
            <Tabs<InputQuantityDetails, InputQuantityTabs>
                tabs={[
                    {
                        heading: "inputQuantity.detail.basicData_tab",
                        element: (
                            <InputQuantityBasicData
                                form={form}
                                readOnly={readOnly}
                                currentSourceType={currentSourceType}
                                inputSourceSelectionVisible={inputSourceSelectionVisible}
                            />
                        ),
                    },
                ]}
                errors={form.form.formState.errors}
                errorToTab={{
                    id: "internal",
                    transactionCertificateStatus: "internal",
                    inputNumber: "inputQuantity.detail.basicData_tab",
                    typeOfInputSource: "inputQuantity.detail.basicData_tab",
                    inputProduct: "inputQuantity.detail.basicData_tab",
                    currentInputSource: "inputQuantity.detail.basicData_tab",
                    inputByProduct: "inputQuantity.detail.basicData_tab",
                    inputPreGtbTcProduct: "inputQuantity.detail.basicData_tab",
                    inputOrganicMaterialPurchase: "inputQuantity.detail.basicData_tab",
                    quantity: "inputQuantity.detail.basicData_tab",
                    currentInputOrganicMaterialPurchase: "internal",
                }}
            />
        </EntityDetailView>
    );
}

type InputQuantityTabs = TabKey<"inputQuantity.detail.basicData_tab">;

export interface InputQuantityDetailViewProps
    extends TypedDetailViewProps<InputQuantityDetails>,
        InputQuantityBasicDataProps {}
