import useIsAuthorised from "../auth/useIsAuthorised";
import { SortOption } from "../components/grid/component/useQueryBuilder";
import { useMemo } from "react";
import { GridColumn } from "../components/grid/component/Grid";
import useListView from "../hooks/useListView";
import { useDeleteAction, useShowDetailsAction } from "../utils/defaultActions";
import { buildDateColumn, buildTextColumn } from "../components/grid/ColumnProvider";
import useResolvedRoute, { getCreateUrl } from "../components/routing/useResolvedRoute";
import { AccreditationRelationDetails, AccreditationRelationSummary } from "./accreditationRelationTypes";
import { EntityListViewGridProps } from "../components/entity/EntityListView";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";

export const defaultAccreditationRelationSortOption: SortOption<
    AccreditationRelationSummary | AccreditationRelationDetails
> = {
    field: "accreditationNumber",
    direction: "ASC",
};

export const accreditationRelationColumns: GridColumn<AccreditationRelationSummary>[] = [
    buildTextColumn({
        key: "accreditationBodyBusinessName",
        title: "accreditationRelation.list.accreditationBody_column",
        minWidth: 201,
    }),
    buildTextColumn({
        key: "standardAcronymVersionNumber",
        title: "accreditationRelation.list.standardversion_column",
        minWidth: 123,
    }),
    buildTextColumn({
        key: "accreditationNumber",
        title: "accreditationRelation.list.accreditationNumber_column",
        minWidth: 222,
    }),
    buildDateColumn({ key: "startDate", title: "accreditationRelation.list.startDate_column", minWidth: 130 }),
    buildDateColumn({ key: "endDate", title: "accreditationRelation.list.endDate_column", minWidth: 124 }),
];

const columns: GridColumn<AccreditationRelationSummary>[] = [
    buildTextColumn({
        key: "certificationBodyBusinessName",
        title: "accreditationRelation.list.certificationBody_column",
        minWidth: 193,
    }),
    ...accreditationRelationColumns,
];

export default function useAccreditationRelationListView() {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedEditUrl } = useResolvedRoute();

    const {
        queryBuilder: { forceReload, columnOptions, query, queryCommands },
    } = useListView<AccreditationRelationSummary>({
        navigationContextLabel: "accreditationRelation.breadcrumb",
        baseQuery: "/certificate-service/accreditation-relations",
        defaultSortOption: defaultAccreditationRelationSortOption,
    });

    const showDetailsAction = useShowDetailsAction(
        (item: AccreditationRelationSummary) => getResolvedEditUrl("accreditationRelation", item.id),
        query.parameter
    );

    const deleteAction = useDeleteAction(
        (item: AccreditationRelationSummary) => `/certificate-service/accreditation-relations/${item.id}`,
        () => forceReload(),
        "deleteCertificationLevelOrganisation"
    );

    const actions = useGridRowContextMenuActions(showDetailsAction, deleteAction);

    return useMemo<EntityListViewGridProps<AccreditationRelationSummary>>(() => {
        return {
            query,
            columns,
            uniqueDataKey: "id",
            onItemDoubleClick: showDetailsAction,
            createUrl: hasAccessRight("createCertificationLevelOrganisation")
                ? getCreateUrl("accreditationRelation")
                : undefined,
            tableName: "accreditationRelation",
            columnOptions,
            itemActions: actions,
            queryCommands,
            bulkDeleteAccessRight: () => hasAccessRight("deleteCertificationLevelOrganisation"),
        };
    }, [query, showDetailsAction, hasAccessRight, columnOptions, actions, queryCommands]);
}
