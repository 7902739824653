import useResolvedRoute, { routeEnum, routes } from "../components/routing/useResolvedRoute";
import { backendUrlType, useMutation } from "../hooks/useAxios";
import { useEffect } from "react";
import ExternalView from "./ExternalView";
import LoadingSpinner from "../components/LoadingSpinner";

interface RelationConfirmationViewProps {
    relationRoute: routeEnum;
    backendUrlBuilder: (parentId: string) => backendUrlType;
}

export default function RelationConfirmationView({ relationRoute, backendUrlBuilder }: RelationConfirmationViewProps) {
    const { getResolvedId } = useResolvedRoute();
    const parentId = getResolvedId(routes[relationRoute].routeParent!);
    const confirmationId = getResolvedId(relationRoute);

    const {
        data,
        error,
        runQuery: confirmRelation,
    } = useMutation({
        method: "post",
        url: "",
    });

    useEffect(() => {
        confirmRelation({
            url: backendUrlBuilder(parentId!),
            body: { token: confirmationId },
        }).finally(/*Do nothing*/);
    }, [backendUrlBuilder, parentId, confirmRelation, confirmationId]);

    return (
        <ExternalView header="components.externalView.relationConfirmation_header">
            {!data && !error && <LoadingSpinner />}
            {!!error && JSON.stringify(error)}
            {data}
        </ExternalView>
    );
}
