import { UseGtbFormReturn } from "../../../../hooks/formHandling/useGtbForm";
import { joinClassNames } from "../../../../utils/StringUtils";
import GtbTextField from "../../../../components/Input/GtbTextField";
import "./tcProductQuantitativeData.css";
import { TcProductDetails } from "../tcProductTypes";
import GtbNumberField, { GtbNumberFieldOneFixedDecimal } from "../../../../components/Input/GtbNumberField";
import { ScProductReference } from "../../../../scopeCertificate/details/products/scProductTypes";
import useGtbTranslation from "../../../../i18n/useGtbTranslation";
import useSetFormValue from "../../../../hooks/formHandling/useSetFormValue";
import { TcProductReadOnlyProps } from "./TcProductDetailView";
import InfinityScrollSubList from "../../../../components/grid/InfinityScrollSubList";
import useInputQuantitySubList from "./useInputQuantitySubList";

export interface TcProductQuantitativeDataProps {
    form: UseGtbFormReturn<TcProductDetails>;
    readOnlyProps: TcProductReadOnlyProps;
    selectedScProduct?: ScProductReference;
    byProductWeightVisible?: boolean;
    sublistVisible?: boolean;
    afterDeletion: Function;
}

export default function TcProductQuantitativeData({
    form,
    readOnlyProps,
    selectedScProduct,
    byProductWeightVisible,
    sublistVisible,
    afterDeletion,
}: TcProductQuantitativeDataProps) {
    const setFormValue = useSetFormValue(form.form);
    const translation = useGtbTranslation();
    const inputQuantitySubListOptions = useInputQuantitySubList({
        readOnly: readOnlyProps.readOnly,
        afterDeletion,
    });

    return (
        <div className={joinClassNames("tcProductQuantitativeData", "formGrid")}>
            <GtbTextField
                label="tcProduct.detail.productCategoryPrint_input"
                readOnly
                className="productCategoryPrint"
                value={selectedScProduct?.productCategoryPrint ?? ""}
            />
            <GtbTextField
                label="tcProduct.detail.productDetailPrint_input"
                readOnly
                className="productDetailPrint"
                value={selectedScProduct?.productDetailPrint ?? ""}
            />
            <GtbTextField
                label="tcProduct.detail.labelGrade_input"
                readOnly
                className="labelGrade"
                value={selectedScProduct?.labelGradeLabelGrade ?? ""}
            />
            <GtbNumberField
                readOnly={readOnlyProps.readOnly}
                label="tcProduct.detail.numberOfUnits_input"
                className="numberOfUnits"
                {...form.registerNumberWithErrors("numberOfUnits")}
            />
            <GtbTextField
                label="tcProduct.detail.unitName_input"
                {...form.registerWithErrors("unitName")}
                readOnly={readOnlyProps.readOnly}
                className="unitName"
            />
            <GtbNumberFieldOneFixedDecimal
                readOnly={readOnlyProps.readOnly}
                label="tcProduct.detail.netShippingWeight_input"
                className="netShippingWeight"
                unit={"kg"}
                control={form.form.control}
                name="netShippingWeight"
                decimalPlaces={1}
                //@ts-ignore
                onChangeListener={(event) => setFormValue("availableQuantity", event.target.value)}
            />
            <GtbNumberFieldOneFixedDecimal
                readOnly={readOnlyProps.readOnly}
                label="tcProduct.detail.supplementaryWeight_input"
                className="supplementaryWeight"
                unit={"kg"}
                control={form.form.control}
                name="supplementaryWeight"
                decimalPlaces={1}
            />
            <GtbNumberFieldOneFixedDecimal
                readOnly
                label="tcProduct.detail.certifiedWeight_input"
                className="certifiedWeight"
                unit={"kg"}
                control={form.form.control}
                name="certifiedWeight"
                decimalPlaces={1}
            />
            <span id="certifiedWeightDescription">
                {translation("preGtbProduct.detail.certifiedWeight_description")}
            </span>
            <GtbNumberFieldOneFixedDecimal
                readOnly
                label="tcProduct.detail.nonCertifiedWeight_input"
                className="nonCertifiedWeight"
                unit={"kg"}
                control={form.form.control}
                name="nonCertifiedWeight"
                decimalPlaces={1}
            />
            <GtbNumberFieldOneFixedDecimal
                readOnly
                label="tcProduct.detail.availableQuantity_input"
                className="availableQuantity"
                unit={"kg"}
                control={form.form.control}
                name="availableQuantity"
                decimalPlaces={1}
            />
            {byProductWeightVisible && (
                <GtbNumberFieldOneFixedDecimal
                    readOnly={readOnlyProps.readOnly}
                    label="tcProduct.detail.byProductWeight_input"
                    className="byProductWeight"
                    unit={"kg"}
                    control={form.form.control}
                    name="byProductWeight"
                    decimalPlaces={1}
                    //@ts-ignore
                    onChangeListener={(event) => setFormValue("availableByProductQuantity", event.target.value)}
                />
            )}
            {byProductWeightVisible && (
                <GtbNumberFieldOneFixedDecimal
                    readOnly
                    label="tcProduct.detail.availableByProductQuantity_input"
                    className="availableByProductQuantity"
                    unit={"kg"}
                    control={form.form.control}
                    name="availableByProductQuantity"
                    decimalPlaces={1}
                />
            )}
            {sublistVisible && <InfinityScrollSubList {...inputQuantitySubListOptions} className="inputQuantities" />}
        </div>
    );
}
