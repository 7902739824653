import GtbTextField from "../../components/Input/GtbTextField";
import { OrganisationDetailViewTabProps } from "./organisationDetailViewProps";
import CountryAreaPickList from "../../components/Input/pickList/typedPicklist/CountryAreaPickList";
import StateProvincePickList from "../../components/Input/pickList/typedPicklist/StateProvincePickList";
import { I18nKey } from "../../i18n/useGtbTranslation";
import { OrganisationDetails } from "../organisationTypes";
import { Path } from "react-hook-form";
import GtbNumberField from "../../components/Input/GtbNumberField";
import "./organisationAddressData.css";
import OrganisationMap from "./OrganisationMap";

export default function OrganisationAddressData<OrganisationType extends OrganisationDetails>({
    form,
    readOnlyProps,
    organisationI18nKey,
    children,
}: OrganisationDetailViewTabProps<OrganisationType>) {
    return (
        <div className={`organisationAddressData formGrid ${organisationI18nKey}AddressData`}>
            <GtbTextField
                {...form.registerWithErrors("town" as Path<OrganisationType>)}
                readOnly={readOnlyProps.isTownReadOnly}
                label={(organisationI18nKey + ".detail.town_input") as I18nKey}
                className="town"
            />
            <GtbTextField
                {...form.registerWithErrors("postCode" as Path<OrganisationType>)}
                readOnly={readOnlyProps.isPostCodeReadOnly}
                label={(organisationI18nKey + ".detail.postcode_input") as I18nKey}
                className="postCode"
            />
            <CountryAreaPickList
                control={form.form.control}
                readOnly={readOnlyProps.isCountryAreaReadOnly}
                name="countryArea"
                className="countryArea"
                label={(organisationI18nKey + ".detail.countryArea_input") as I18nKey}
            />
            <StateProvincePickList
                form={form.form}
                readOnly={readOnlyProps.isCountryAreaReadOnly}
                name="stateProvince"
                parentField={"countryArea" as Path<OrganisationType>}
                className="stateProvince"
                label={(organisationI18nKey + ".detail.stateProvince_input") as I18nKey}
            />
            <GtbTextField
                {...form.registerWithErrors("addressDetails1" as Path<OrganisationType>)}
                readOnly={readOnlyProps.isAddressDetails1ReadOnly}
                label={(organisationI18nKey + ".detail.addressDetail1_input") as I18nKey}
                className="addressDetails1"
            />
            <GtbTextField
                {...form.registerWithErrors("addressDetails2" as Path<OrganisationType>)}
                readOnly={readOnlyProps.isAddressDetails2ReadOnly}
                label={(organisationI18nKey + ".detail.addressDetail2_input") as I18nKey}
                className="addressDetails2"
            />
            <GtbTextField
                {...form.registerWithErrors("addressDetails3" as Path<OrganisationType>)}
                readOnly={readOnlyProps.isAddressDetails3ReadOnly}
                label={(organisationI18nKey + ".detail.addressDetail3_input") as I18nKey}
                className="addressDetails3"
            />
            <GtbNumberField
                label={(organisationI18nKey + ".detail.latitude_input") as I18nKey}
                {...form.registerNumberWithErrors("latitude" as Path<OrganisationType>)}
                className="latitude"
                readOnly={readOnlyProps.isLatitudeReadOnly}
                decimalPlaces={8}
                allowNegative
            />
            <GtbNumberField
                label={(organisationI18nKey + ".detail.longitude_input") as I18nKey}
                {...form.registerNumberWithErrors("longitude" as Path<OrganisationType>)}
                className="longitude"
                readOnly={readOnlyProps.isLongitudeReadOnly}
                decimalPlaces={8}
                allowNegative
            />
            {children}
            <OrganisationMap control={form.form.control} />
        </div>
    );
}
