import { TypedPicklist } from "./typedPicklistType";
import DataPickList from "../DataPickList";
import "./typedPickList.css";
import { joinClassNames } from "../../../../utils/StringUtils";
import { FieldValues } from "react-hook-form/dist/types";
import { SupplyChainOperatorReference } from "../../../../supplyChainOperator/supplyChainOperatorTypes";
import { PickListItemPresentationProps } from "../GtbPickList";

interface SupplyChainOperatorPickListProps<FormItemType extends FieldValues>
    extends TypedPicklist<FormItemType, SupplyChainOperatorReference> {
    flags?: SupplyChainOperatorPickListFlags;
    itemLabel?: PickListItemPresentationProps<SupplyChainOperatorReference>["itemLabel"];
}

export interface SupplyChainOperatorPickListFlags {
    isClientOfCurrentUser?: boolean;
    isActiveClientOfCbAndHasOperationalValidSc?: boolean;
    allActive?: boolean;
    activeBuyingRelationTo?: string;
    accessiblePreGtbTcBuyer?: boolean;
    accessiblePreGtbTcBuyerForStandard?: string;
    potentialBuyersForTcToSellerId?: string;
    potentialPrincipal?: boolean;
}

export const supplyChainOperatorPickListCombinedClassName = (className?: string) =>
    joinClassNames("supplyChainOperatorPickList", className);
export const supplyChainOperatorPickListItemPresentation: PickListItemPresentationProps<SupplyChainOperatorReference> =
    {
        itemId: "id",
        selectedItemLabel: ({ legalName, legalNameInEnglish }) =>
            `${legalName}${legalNameInEnglish ? " (" + legalNameInEnglish + ")" : ""}`,
        itemLabel: ({ legalName, legalNameInEnglish, town, stateProvinceCountryAreaName, stateProvinceIsoCode }) =>
            [legalName, legalNameInEnglish, `${town}, ${stateProvinceCountryAreaName}, (${stateProvinceIsoCode})`]
                .filter((s) => s)
                .join("\n"),
    };
export const supplyChainOperatorPickListReferenceUrl = "/certificate-service/references/supply-chain-operators";

export default function SupplyChainOperatorPickList<FormItemType extends FieldValues>({
    className,
    ...rest
}: SupplyChainOperatorPickListProps<FormItemType>) {
    return (
        <DataPickList
            className={supplyChainOperatorPickListCombinedClassName(className)}
            {...supplyChainOperatorPickListItemPresentation}
            queryUrl={supplyChainOperatorPickListReferenceUrl}
            {...rest}
        />
    );
}
