import { useHelpContext } from "../help/context-sensitivity/useHelpContext";
import EntityListView from "../components/entity/EntityListView";
import usePreGtbTransactionCertificateListView from "./usePreGtbTransactionCertificateListView";
import usePreGtbTcSearchContext, { PreGtbTcSearchContextSelection } from "./usePreGtbTcSearchContext";

export default function PreGtbTransactionCertificateListView({
    searchContext,
}: PreGtbTransactionCertificateListViewProps) {
    const resolvedSearchContext = usePreGtbTcSearchContext(searchContext);
    const scopeCertificateGridOptions = usePreGtbTransactionCertificateListView(resolvedSearchContext);
    useHelpContext("pre-gtb-tc-list");

    return (
        <EntityListView
            heading={resolvedSearchContext.navigationContextLabel}
            gridOptions={scopeCertificateGridOptions}
        />
    );
}

export interface PreGtbTransactionCertificateListViewProps {
    searchContext: PreGtbTcSearchContextSelection;
}
