import { useCallback } from "react";
import useGtbTranslation from "../../../../../../../i18n/useGtbTranslation";
import { TcProductReference } from "../../../../tcProductTypes";
import { PreGtbProductReference } from "../../../../../../../preGtbTransactionCertificate/details/products/preGtbProductTypes";
import { OrganicMaterialPurchaseReference } from "../../../../../../../organicMaterialPurchase/organicMaterialPurchaseTypes";

type InputSource =
    | { type: "TC_PRODUCT" | "BY_PRODUCT"; inputSource?: TcProductReference }
    | { type: "PRE_GTB_TC_PRODUCT"; inputSource?: PreGtbProductReference }
    | { type: "ORGANIC_MATERIAL_PURCHASE"; inputSource?: OrganicMaterialPurchaseReference };

export default function useInputSourceNameProvider() {
    const translation = useGtbTranslation();

    return useCallback(
        ({ type, inputSource }: InputSource): string => {
            if (!inputSource) {
                return "";
            }
            switch (type) {
                case "TC_PRODUCT":
                    return translation({
                        key: "inputQuantity.detail.tcProductInputSource_format",
                        options: {
                            tcProductNumber: inputSource.tcProductNumber,
                            productCategoryName: inputSource.relatedScProductProductCategory,
                            productCategoryCode: inputSource.relatedScProductProductCategoryCode,
                            productDetailName: inputSource.relatedScProductProductDetail,
                            productDetailCode: inputSource.relatedScProductProductDetailCode,
                            materialComposition: inputSource.inputSourceMaterialComposition,
                            availableQuantity: inputSource.availableQuantity,
                            interpolation: { escapeValue: false },
                        },
                    });
                case "PRE_GTB_TC_PRODUCT":
                    return translation({
                        key: "inputQuantity.detail.preGtbProductInputSource_format",
                        options: {
                            tcNumber: inputSource.preGtbTcTcNumber,
                            productNumber: inputSource.productNumber,
                            productCategoryName: inputSource.productCategoryName,
                            productCategoryCode: inputSource.productCategoryCode,
                            productDetailName: inputSource.productDetailName,
                            productDetailCode: inputSource.productDetailCode,
                            materialComposition: inputSource.inputSourceMaterialComposition,
                            availableQuantity: inputSource.availableVolume,
                            interpolation: { escapeValue: false },
                        },
                    });
                case "BY_PRODUCT":
                    return translation({
                        key: "inputQuantity.detail.tcByProductInputSource_format",
                        options: {
                            tcProductNumber: inputSource.tcProductNumber,
                            productCategoryName: inputSource.relatedScProductProductCategory,
                            productCategoryCode: inputSource.relatedScProductProductCategoryCode,
                            productDetailName: inputSource.relatedScProductProductDetail,
                            productDetailCode: inputSource.relatedScProductProductDetailCode,
                            materialComposition: inputSource.inputSourceMaterialComposition,
                            availableByProductQuantity: inputSource.availableByProductQuantity,
                            interpolation: { escapeValue: false },
                        },
                    });
                case "ORGANIC_MATERIAL_PURCHASE":
                    return translation({
                        key: "inputQuantity.detail.organicMaterialPurchaseInputSource_format",
                        options: {
                            systemId: inputSource.systemId,
                            rawMaterialName: inputSource.certifiedOrganicMaterialName,
                            rawMaterialCode: inputSource.certifiedOrganicMaterialCode,
                            availableQuantity: inputSource.availableQuantity,
                            interpolation: { escapeValue: false },
                        },
                    });
            }
        },
        [translation]
    );
}
