import { useMemo } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { ScFacilityDetailViewProps } from "./ScFacilityDetailView";
import useResolvedRoute from "../../../../../../../components/routing/useResolvedRoute";
import { useQuery } from "../../../../../../../hooks/useAxios";
import { Nullable } from "../../../../../../../utils/typeUtils";
import { ScFacilityDetails } from "./scFacilityTypes";
import useDetailsCreate from "../../../../../../../hooks/details/useDetailsCreate";
import { ScProcessCategoryReference } from "../../scProcessCategoryTypes";
import { ScFacilityValidationSchema } from "./scFacilityValidationSchema";

export default function useScFacilityCreate(): ScFacilityDetailViewProps {
    const { getResolvedId } = useResolvedRoute();
    const scopeCertificateId = getResolvedId("scopeCertificate")!;
    const scProductId = getResolvedId("scProduct");
    const scProcessCategoryId = getResolvedId("scProcessCategory");

    const { data: scProcessCategory, isLoading: isParentLoading } = useQuery<ScProcessCategoryReference>({
        url: `/certificate-service/references/sc-process-categories/${scProcessCategoryId}`,
    });

    const initialData = useMemo<Nullable<ScFacilityDetails>>(
        () => ({
            id: null,
            scopeCertificateSupplyChainOperatorBusinessName:
                scProcessCategory?.scopeCertificateSupplyChainOperatorBusinessName ?? null,
            scProductScProductNumber: scProcessCategory?.scProductScProductNumber ?? null,
            scProductProductCategoryName: scProcessCategory?.scProductProductCategoryName ?? null,
            scProductProductDetailName: scProcessCategory?.scProductProductDetailName ?? null,
            scProductLabelGradeLabelGrade: scProcessCategory?.scProductLabelGradeLabelGrade ?? null,
            scProcessCategoryName: scProcessCategory?.processCategoryName ?? null,
            scopeCertificateStatus: null,
            facility: null,
            facilityBusinessName: null,
            scProcessCategoryScProductProductCategoryPrint: scProcessCategory?.scProductProductCategoryPrint ?? null,
            scProcessCategoryScProductProductDetailPrint: scProcessCategory?.scProductProductDetailPrint ?? null,
            scProcessCategoryProcessCategoryPrint: scProcessCategory?.processCategoryPrint ?? null,
        }),
        [scProcessCategory]
    );

    const { detailViewProps, form, title } = useDetailsCreate<ScFacilityDetails>({
        title: "scFacility.detail.newRecord_title",
        frontendRoute: "scFacility",
        initialData,
        resolver: yupResolver(ScFacilityValidationSchema),
        saveUrl: `/certificate-service/scope-certificates/${scopeCertificateId}/sc-products/${scProductId}/sc-process-categories/${scProcessCategoryId}/sc-facilities`,
    });

    return {
        title,
        detailViewProps: {
            ...detailViewProps,
            isLoading: detailViewProps.isLoading || isParentLoading,
        },
        form,
        scopeCertificateId,
        readOnly: false,
        createRoute: "scFacility",
    };
}
