import { useCallback } from "react";
import { useGtbNavigate } from "./GtbRouter";
import { NavigateOptions, useLocation } from "react-router-dom";

/* This hook should be used when you want to navigate to the same view you are already on.
The hook uses the RefreshView for this. refreshNavigate expects a complete route.
refreshUrl returns the given route.*/
export default function useRefreshNavigate() {
    const navigate = useGtbNavigate();
    const { state } = useLocation();

    const refreshNavigate = useCallback(
        (url: string, options?: NavigateOptions) => {
            navigate({ pathname: "/refresh" }, { state: { ...options?.state, refreshUrl: url } });
        },
        [navigate]
    );

    return { refreshNavigate, refreshUrl: (state as { refreshUrl: string })?.refreshUrl, state };
}
