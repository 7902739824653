import { TypedDetailViewProps } from "../../components/detailView/DetailView";
import { UseGtbFormReturn } from "../../hooks/formHandling/useGtbForm";
import "../../components/spinner.css";
import { ProcessCategoryDetails } from "../processCategoryTypes";
import ProcessCategoryBasicData from "../../processCategory/details/ProcessCategoryBasicData";
import EntityDetailView from "../../components/entity/EntityDetailView";
import { useHelpContext } from "../../help/context-sensitivity/useHelpContext";
import Tabs, { TabKey } from "../../components/tabs/Tabs";
import { buildLanguageVersionTab } from "../../i18n/LanguageVersionTab";
import "./processCategoryLanguageVersion.css";

export default function ProcessCategoryDetailView({
    detailViewProps,
    form,
    isReadOnly,
    ...rest
}: ProcessCategoryDetailViewProps) {
    useHelpContext("process-category-details");

    return (
        <EntityDetailView detailViewProps={detailViewProps} form={form} {...rest}>
            <Tabs<
                ProcessCategoryDetails,
                TabKey<"processCategory.detail.basicData_tab" | "translation.languageVersion_tab">
            >
                tabs={[
                    {
                        heading: "processCategory.detail.basicData_tab",
                        element: <ProcessCategoryBasicData form={form} readOnly={isReadOnly} />,
                    },
                    buildLanguageVersionTab({
                        form,
                        readOnly: isReadOnly,
                        baseName: "processCategory",
                        fields: ["name"],
                    }),
                ]}
                errors={form.form.formState.errors}
                errorToTab={{
                    id: "internal",
                    code: "processCategory.detail.basicData_tab",
                    pickListSequence: "processCategory.detail.basicData_tab",
                    name: "translation.languageVersion_tab",
                    startDate: "processCategory.detail.basicData_tab",
                    endDate: "processCategory.detail.basicData_tab",
                }}
            />
        </EntityDetailView>
    );
}

export interface ProcessCategoryDetailViewProps extends TypedDetailViewProps<ProcessCategoryDetails> {
    isReadOnly: boolean;
}

export interface ProcessCategoryDetailViewTabProps {
    form: UseGtbFormReturn<ProcessCategoryDetails>;
    readOnly: boolean;
}
