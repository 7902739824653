import useIsAuthorised from "../../../auth/useIsAuthorised";
import useResolvedRoute from "../../../components/routing/useResolvedRoute";
import { SortOption, useQueryBuilder } from "../../../components/grid/component/useQueryBuilder";
import { useDeleteAction, useShowDetailsAction } from "../../../utils/defaultActions";
import { useMemo } from "react";
import { SubListProps } from "../../../components/grid/SubList";
import { GridColumn } from "../../../components/grid/component/Grid";
import {
    buildBooleanColumn,
    buildDateColumn,
    buildNumberColumn,
    buildTextColumn,
} from "../../../components/grid/ColumnProvider";
import { TcProductDetails, TcProductSummary } from "./tcProductTypes";
import { TransactionCertificateStatusType } from "../../../auth/roleTypes";
import { transactionCertificateReadOnlyByStatus } from "../../transactionCertificateTypes";
import useGridRowContextMenuActions from "../../../components/grid/component/cell/useGridRowContextMenuActions";
import { useShowMapAction } from "./details/mapView/TcProductMapView";

export const defaultTcProductSortOption: SortOption<TcProductSummary | TcProductDetails> = {
    field: "tcProductNumber",
    direction: "ASC",
};

const columns: GridColumn<TcProductSummary>[] = [
    buildTextColumn({ key: "tcProductNumber", title: "tcProduct.list.tcProductNumber_column", minWidth: 180 }),
    buildTextColumn({
        key: "relatedScProductProductCategoryPrint",
        title: "tcProduct.list.productCategoryPrint_column",
        minWidth: 239,
    }),
    buildTextColumn({
        key: "relatedScProductProductDetailPrint",
        title: "tcProduct.list.productDetailPrint_column",
        minWidth: 215,
    }),
    buildTextColumn({
        key: "relatedScProductLabelGradeLabelGrade",
        title: "tcProduct.list.labelGrade_column",
        minWidth: 149,
    }),
    buildNumberColumn({
        key: "numberOfUnits",
        title: "tcProduct.list.numberOfUnits_column",
        minWidth: 180,
    }),
    buildTextColumn({
        key: "unitName",
        title: "tcProduct.list.unitName_column",
        minWidth: 140,
    }),
    buildNumberColumn({
        key: "netShippingWeight",
        title: "tcProduct.list.netShippingWeight_column",
        fixedDecimal: true,
        minWidth: 245,
    }),
    buildNumberColumn({
        key: "availableQuantity",
        title: "tcProduct.list.availableQuantity_column",
        fixedDecimal: true,
        minWidth: 227,
    }),
    buildTextColumn({
        key: "relatedScProductProductCategory",
        title: "tcProduct.list.productCategory_column",
        minWidth: 190,
        visible: false,
    }),
    buildTextColumn({
        key: "relatedScProductProductDetail",
        title: "tcProduct.list.productDetail_column",
        minWidth: 165,
        visible: false,
    }),
    buildTextColumn({
        key: "relatedScProductProcessCategory",
        title: "tcProduct.list.processCategory_column",
        minWidth: 190,
        visible: false,
        disableSorting: true,
    }),
    buildBooleanColumn({
        key: "usdaNopCompliant",
        title: "tcProduct.list.usdaNop_column",
        minWidth: 220,
        visible: false,
    }),
    buildNumberColumn({
        key: "supplementaryWeight",
        title: "tcProduct.list.supplementaryWeight_column",
        fixedDecimal: true,
        minWidth: 265,
        visible: false,
    }),
    buildNumberColumn({
        key: "certifiedWeight",
        title: "tcProduct.list.certifiedWeight_column",
        fixedDecimal: true,
        minWidth: 210,
        visible: false,
    }),
    buildNumberColumn({
        key: "nonCertifiedWeight",
        title: "tcProduct.list.nonCertifiedWeight_column",
        fixedDecimal: true,
        minWidth: 245,
        visible: false,
    }),
    buildNumberColumn({
        key: "byProductWeight",
        title: "tcProduct.list.byProductWeight_column",
        fixedDecimal: true,
        minWidth: 235,
        visible: false,
    }),
    buildNumberColumn({
        key: "availableByProductQuantity",
        title: "tcProduct.list.availableByProductQuantity_column",
        fixedDecimal: true,
        minWidth: 315,
        visible: false,
    }),
    buildTextColumn({
        key: "orderNumber",
        title: "tcProduct.list.orderNumber_column",
        minWidth: 165,
        visible: false,
    }),
    buildTextColumn({
        key: "articleNumber",
        title: "tcProduct.list.articleNumber_column",
        minWidth: 169,
        visible: false,
    }),
    buildDateColumn({
        key: "productionDate",
        title: "tcProduct.list.productionDate_column",
        minWidth: 180,
        visible: false,
    }),
    buildTextColumn({
        key: "lastProcessorSummary",
        title: "tcProduct.list.lastProcessor_column",
        minWidth: 175,
        visible: false,
        disableSorting: true,
    }),
    buildTextColumn({
        key: "additionalInformation",
        title: "tcProduct.list.additionalInformation_column",
        minWidth: 230,
        visible: false,
    }),
    buildTextColumn({
        key: "cbProductReferenceNumber",
        title: "tcProduct.list.cbProductReferenceNumber_column",
        minWidth: 227,
        visible: false,
    }),
];

export default function useTcProductSubList(transactionCertificateStatus?: TransactionCertificateStatusType) {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedEditUrl, getResolvedCreateUrl, getResolvedId } = useResolvedRoute();
    const transactionCertificateId = getResolvedId("transactionCertificate");

    const { query, columnOptions, forceReload } = useQueryBuilder<TcProductSummary>(
        `/certificate-service/transaction-certificates/${transactionCertificateId}/tc-products`,
        {
            sort: defaultTcProductSortOption,
            filterActive: false,
        }
    );

    const isReadOnlyByTransactionCertificateStatus = useMemo<boolean>(
        () => transactionCertificateReadOnlyByStatus(transactionCertificateStatus),
        [transactionCertificateStatus]
    );

    const showDetailsAction = useShowDetailsAction(
        (item: TcProductSummary) => getResolvedEditUrl("tcProduct", item.id),
        query.parameter
    );

    const showMapAction = useShowMapAction();

    const deleteAction = useDeleteAction(
        (item: TcProductSummary) =>
            `/certificate-service/transaction-certificates/${transactionCertificateId}/tc-products/${item.id}`,
        () => forceReload(),
        "updateTransactionCertificate",
        transactionCertificateStatus ? [transactionCertificateStatus] : []
    );
    const contextMenuActions = useGridRowContextMenuActions(
        showDetailsAction,
        showMapAction,
        !isReadOnlyByTransactionCertificateStatus ? deleteAction : undefined
    );

    return useMemo<SubListProps<TcProductSummary>>(
        () => ({
            query,
            columns,
            uniqueDataKey: "id",
            onItemDoubleClick: showDetailsAction,
            header: "tcProduct.list.header",
            itemActions: contextMenuActions,
            columnOptions,
            createUrl:
                transactionCertificateStatus &&
                hasAccessRight("updateTransactionCertificate", [transactionCertificateStatus]) &&
                !isReadOnlyByTransactionCertificateStatus
                    ? getResolvedCreateUrl("tcProduct")
                    : undefined,
            tableName: "tcProducts",
        }),
        [
            columnOptions,
            contextMenuActions,
            getResolvedCreateUrl,
            hasAccessRight,
            isReadOnlyByTransactionCertificateStatus,
            query,
            transactionCertificateStatus,
            showDetailsAction,
        ]
    );
}
