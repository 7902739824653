import useGtbForm from "../hooks/formHandling/useGtbForm";
import { useMutation, useQuery } from "../hooks/useAxios";
import { useCallback, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import yup, { validateString } from "../utils/yupExtension";
import useResolvedRoute from "../components/routing/useResolvedRoute";
import useGtbTranslation from "../i18n/useGtbTranslation";

export type ResetPasswordInputTypes = {
    password: string;
    passwordConfirmation: string;
    key: string;
};

function useResetPassword() {
    const translation = useGtbTranslation();

    /* Check UUID */
    const { getResolvedId } = useResolvedRoute();
    const resetPasswordId = getResolvedId("resetPassword");

    const [resetPasswordLinkExpired, setResetPasswordLinkExpired] = useState(false);
    const [resetPasswordResultType, setResetPasswordResultType] = useState<ResetPasswordResultType | null>(null);

    const { runQuery: runCheckUUID, isLoading: isCheckUUIDLoading } = useQuery({
        url: "/certificate-service/password-token-check/" + resetPasswordId,
        enabled: false,
    });

    useEffect(() => {
        runCheckUUID()
            .then(/*empty call because of lint error otherwise*/)
            .catch(() => setResetPasswordLinkExpired(true));
    }, [runCheckUUID]);

    /* Reset Password */
    const {
        registerWithErrors: register,
        form: {
            handleSubmit,
            setError,
            trigger,
            formState: { isSubmitted },
        },
    } = useGtbForm<ResetPasswordInputTypes>({
        defaultValues: {
            password: "",
            passwordConfirmation: "",
            key: resetPasswordId,
        },
        resolver: yupResolver(
            yup.object().shape({
                password: validateString().isRequired().hasMinLength(8).matchesPasswordPolicy(),
                passwordConfirmation: validateString().oneOf(
                    [yup.ref("password")],
                    translation("error.password.mismatching_passwords")
                ),
            })
        ),
    });

    const { isLoading: isResetPasswordLoading, runQuery: runSetPassword } = useMutation({
        method: "post",
        url: "/certificate-service/password-set",
    });

    const [resetPasswordSuccessful, setResetPasswordSuccessful] = useState(false);

    const handleSetPasswordSuccess = useCallback((body: any) => {
        setResetPasswordSuccessful(true);
        setResetPasswordResultType(body.passwordResetOperationType);
    }, []);

    const handleSetPasswordError = useCallback(
        (error: any) => {
            if (error?.statusCode === 400) {
                if (error?.data?.validationErrors?.password === "PasswordsNotEqual") {
                    setError("password", { message: translation("error.password.mismatching_passwords") });
                } else if (error?.data?.validationErrors?.password === "PasswordEqualToCurrent") {
                    setError(
                        "password",
                        { message: translation("error.password.same_password") },
                        { shouldFocus: true }
                    );
                }
            } else {
                setError("password", { message: translation("error.misc.generic_error") });
            }
        },
        [setError, translation]
    );

    const onResetPassword = useCallback(
        (e: any) => {
            const onSubmit = handleSubmit((body) => {
                runSetPassword({ body }).then(handleSetPasswordSuccess).catch(handleSetPasswordError);
            });
            onSubmit(e).then(/*empty call because of lint error otherwise*/);
        },
        [handleSubmit, runSetPassword, handleSetPasswordSuccess, handleSetPasswordError]
    );

    const onPasswordChange = useCallback(async () => {
        if (isSubmitted) {
            await trigger("passwordConfirmation");
        }
    }, [isSubmitted, trigger]);

    return {
        register,
        onPasswordChange,
        isResetPasswordLoading,
        onResetPassword,
        resetPasswordSuccessful,
        resetPasswordLinkExpired,
        isCheckUUIDLoading,
        resetPasswordResultType,
    };
}

export default useResetPassword;

export type ResetPasswordResultType = "SET" | "CHANGED_BLOCKED" | "CHANGED_NOT_BLOCKED";
