import CountryAreaDetailView from "./CountryAreaDetailView";
import useCountryAreaCreate from "./useCountryAreaCreate";

function CountryAreaCreateView() {
    const countryAreaDetailProps = useCountryAreaCreate();

    return <CountryAreaDetailView {...countryAreaDetailProps} />;
}

export default CountryAreaCreateView;
