import { joinClassNames } from "../../utils/StringUtils";
import GtbTextField from "../../components/Input/GtbTextField";
import { StandardDetailViewTabProps } from "./StandardDetailView";
import "./standardBasicData.css";
import { LabeledCheckbox } from "../../components/Input/Checkbox";
import useVersionSubList from "../version/useVersionSublist";
import SubList from "../../components/grid/SubList";
import EnumEntityRadiobuttonGroup from "../../components/Input/radiobutton/EnumEntityRadiobuttonGroup";
import StandardBodyPickList from "../../components/Input/pickList/typedPicklist/StandardBodyPickList";

function StandardBasicData({ form, readOnly, currentTypeOfStandard, subListVisible }: StandardDetailViewTabProps) {
    const standardVersionGridOptions = useVersionSubList();

    return (
        <div className={joinClassNames("standardBasicData", "formGrid")}>
            <GtbTextField
                {...form.registerWithErrors("acronym")}
                readOnly={readOnly}
                label="standard.detail.acronym_input"
                className="acronym"
            />
            <GtbTextField
                {...form.registerWithErrors("officialName")}
                readOnly={readOnly}
                label="standard.detail.officialName_input"
                className="officialName"
            />
            <EnumEntityRadiobuttonGroup
                label="standard.detail.standardType_input"
                {...form.form.register("typeOfStandard")}
                readOnly={readOnly}
                className="standardType"
                referenceUrl="/certificate-service/references/type-of-standards"
            />
            <StandardBodyPickList
                control={form.form.control}
                name="standardBody"
                label="standard.detail.standardBody_input"
                className="standardBody"
                readOnly={readOnly}
            />
            {currentTypeOfStandard === "ORGANIC_MATERIAL_PRODUCTION" && (
                <LabeledCheckbox
                    {...form.form.register("transactionCertificatesMandatory")}
                    id="transactionCertificateRequired"
                    label="standard.detail.transactionCertificatesMandatory_input"
                    readOnly={readOnly}
                    className="transactionCertificateRequired"
                />
            )}
            {subListVisible && <SubList {...standardVersionGridOptions} className="standardVersions" />}
        </div>
    );
}

export default StandardBasicData;
