import { useEffect, useMemo, useRef } from "react";
import "./dropdown.css";
import useClickOutside from "../../hooks/useClickOutside";
import { useDropdownStore, useSetDropdownStore } from "./dropdownStore";
import { findFirstFocusableChild, useBuildArrowNavigationListener } from "../../utils/a11yUtils";

export interface DropdownMenuProps {
    children?: React.ReactNode;
}

function DropdownMenu({ children }: DropdownMenuProps) {
    const menuRef = useRef<HTMLDivElement>(null);

    const isDropdownOpen = useDropdownStore();
    const setDropdownOpen = useSetDropdownStore();
    const hidden = useMemo(() => (isDropdownOpen ? {} : { hidden: true }), [isDropdownOpen]);
    const arrowNavigationListener = useBuildArrowNavigationListener("li", "vertical");

    useClickOutside(
        menuRef,
        (e) => {
            e.preventDefault();
            // A click on the parentNode (for example a containing DIV) just closes the dropdown
            // and does not trigger the clickListener on the parent
            if (menuRef?.current?.parentElement?.contains(e.target as Node)) {
                e.stopPropagation();
            }
            setDropdownOpen(false);
        },
        isDropdownOpen
    );

    useEffect(() => {
        if (isDropdownOpen) {
            // focus on open
            findFirstFocusableChild(menuRef.current?.querySelector(".dropdownMenu *:first-child"))?.focus();
            const listener = (e: KeyboardEvent) => {
                if (e.key === "Escape") {
                    (menuRef?.current?.previousElementSibling as HTMLElement)?.focus();
                    setDropdownOpen(false);
                }
            };

            document.addEventListener("keydown", listener);
            return () => document.removeEventListener("keydown", listener);
        }
    }, [isDropdownOpen, setDropdownOpen]);

    return (
        <div className="dropdown" ref={menuRef}>
            <ul {...hidden} className="dropdownMenu" onKeyDown={arrowNavigationListener}>
                {children}
            </ul>
        </div>
    );
}

export default DropdownMenu;
