import { FieldValues } from "react-hook-form/dist/types";
import { useCallback } from "react";
import useConformanceCheckDialog from "./useConformanceCheckDialog";
import { UseFormErrorResolver } from "../hooks/formHandling/useFormSubmit";

export default function useConformanceCheck<ItemType extends FieldValues>(
    conformanceCheckConfiguration?: ConformanceCheckConfiguration<ItemType>
) {
    const showDialog = useConformanceCheckDialog<ItemType>();

    return useCallback<UseFormErrorResolver<ItemType>>(
        (error, itemToCreate, retrySaveAction) => {
            if (error?.data?.conformanceCheckIssues) {
                showDialog(
                    error.data.conformanceCheckIssues,
                    itemToCreate,
                    retrySaveAction,
                    conformanceCheckConfiguration?.showWarningsOnCondition
                );
                return true;
            }
            return false;
        },
        [conformanceCheckConfiguration?.showWarningsOnCondition, showDialog]
    );
}

export interface ConformanceCheckConfiguration<ItemType> {
    showWarningsOnCondition: (item: ItemType) => boolean;
}
