import { useCallback } from "react";
import useWarningDialog from "../components/dialog/UseWarningDialog";
import { DialogOptions } from "../components/dialog/DialogOptions";
import { useMutation } from "../hooks/useAxios";
import { ScopeCertificateDetails } from "./scopeCertificateTypes";
import { error, info } from "../utils/notification/notification";
import useGtbTranslation from "../i18n/useGtbTranslation";
import { useGtbNavigate } from "../components/routing/GtbRouter";

export default function useReviseScopeCertificate() {
    const translation = useGtbTranslation();
    const navigate = useGtbNavigate(true);
    const { showDialog, closeDialog } = useWarningDialog();

    const { runQuery: runCreateRevisionQuery, isLoading } = useMutation({ method: "post", url: "", enabled: false });

    const createRevision = useCallback(
        (scopeCertificate?: ScopeCertificateDetails) => {
            if (scopeCertificate) {
                runCreateRevisionQuery({
                    url: "/certificate-service/scope-certificates/revisions/" + scopeCertificate.id,
                })
                    .then((response: ScopeCertificateDetails) => {
                        navigate("/scopeCertificate/" + response.id);
                        info(translation("components.detailView.savedSuccessfully_toast"));
                    })
                    .catch((err) => {
                        if (err?.statusCode === 412) {
                            error(translation("scopeCertificate.detail.recertifyPreconditionError_message"));
                        } else {
                            error(translation("error.misc.generic_error"));
                        }
                    })
                    .finally(closeDialog);
            }
        },
        [closeDialog, navigate, runCreateRevisionQuery, translation]
    );

    return useCallback(
        (scopeCertificate?: ScopeCertificateDetails) => {
            showDialog({
                title: "scopeCertificate.detail.revision_dialog_title",
                message: {
                    key: "scopeCertificate.detail.revision_dialog_message",
                    options: {
                        newRevisionNumber: scopeCertificate ? scopeCertificate.revisionNumber + 1 : "",
                        scNumber: scopeCertificate ? scopeCertificate.scNumber : "",
                    },
                },
                footer: (
                    <DialogOptions
                        options={[
                            {
                                label: "scopeCertificate.detail.revision_dialog_cancel_button",
                                onClick: closeDialog,
                                disabled: isLoading,
                            },
                            {
                                label: "scopeCertificate.detail.revision_dialog_proceed_button",
                                onClick: () => createRevision(scopeCertificate),
                                disabled: isLoading,
                            },
                        ]}
                    />
                ),
            });
        },
        [closeDialog, createRevision, isLoading, showDialog]
    );
}
