import { TypedPicklist } from "./typedPicklistType";
import DataPickList from "../DataPickList";
import { joinClassNames } from "../../../../utils/StringUtils";

import "./typedPickList.css";
import { RawMaterialReference, TypeOfMaterialEnum } from "../../../../rawMaterial/rawMaterialTypes";
import { FieldValues } from "react-hook-form/dist/types";

export interface RawMaterialPickListProps<FormItemType extends FieldValues>
    extends TypedPicklist<FormItemType, RawMaterialReference> {
    flags?: { scopeCertificateId?: string; preGtbTcId?: string; typeOfMaterial?: TypeOfMaterialEnum[] };
}

export default function RawMaterialPickList<FormItemType extends FieldValues>({
    className,
    ...rest
}: RawMaterialPickListProps<FormItemType>) {
    return (
        <DataPickList
            className={joinClassNames("rawMaterialPickList", className)}
            itemId="id"
            itemLabel={(option) => `${option.name} (${option.code})`}
            queryUrl="/certificate-service/references/raw-materials"
            {...rest}
        />
    );
}
