import DropdownItem from "../../../components/dropdown/DropdownItem";
import MainMenuItem from "../MainMenuItem";
import useIsAuthorised from "../../../auth/useIsAuthorised";
import useGtbTranslation from "../../../i18n/useGtbTranslation";
import DropdownGroup from "../../../components/dropdown/DropdownGroup";
import BulkReportDownloadMenuItem from "./BulkReportDownloadMenuItem";

export default function BulkReportMenu() {
    const { hasAccessRight } = useIsAuthorised();
    const translation = useGtbTranslation();

    return (
        <MainMenuItem name="mainLayout.mainMenu.reports_menuTitle">
            <DropdownGroup>
                {hasAccessRight("useBulkReport", ["SUPPLY_CHAIN_OPERATORS"]) && (
                    <BulkReportDownloadMenuItem
                        label={"mainLayout.mainMenu.reports_menuEntries.supplyChainOperators_button"}
                        fileUrl={"/report-service/supply-chain-operators/bulk-report"}
                        baseFileName={"bulkReport.supply_chain_operator_file_name"}
                    />
                )}
                {hasAccessRight("useBulkReport", ["FACILITIES"]) && (
                    <BulkReportDownloadMenuItem
                        label={"mainLayout.mainMenu.reports_menuEntries.facilities_button"}
                        fileUrl={"/report-service/facilities/bulk-report"}
                        baseFileName={"bulkReport.facilities_file_name"}
                    />
                )}
            </DropdownGroup>
            <DropdownGroup>
                {hasAccessRight("useBulkReport", ["SCOPE_CERTIFICATES"]) && (
                    <BulkReportDownloadMenuItem
                        label={"mainLayout.mainMenu.reports_menuEntries.scopeCertificates_button"}
                        fileUrl={"/report-service/scope-certificates/bulk-report"}
                        baseFileName={"bulkReport.scope_certificate_file_name"}
                    />
                )}
                {hasAccessRight("useBulkReport", ["SC_PRODUCTS"]) && (
                    <BulkReportDownloadMenuItem
                        label={"mainLayout.mainMenu.reports_menuEntries.scProducts_button"}
                        fileUrl={"/report-service/sc-products/bulk-report"}
                        baseFileName={"bulkReport.sc_product_file_name"}
                    />
                )}
                {hasAccessRight("useBulkReport", ["SC_MATERIALS"]) && (
                    <BulkReportDownloadMenuItem
                        label={"mainLayout.mainMenu.reports_menuEntries.scMaterials_button"}
                        fileUrl={"/report-service/scope-certificates/sc-products/sc-materials/bulk-report"}
                        baseFileName={"bulkReport.sc_materials_file_name"}
                    />
                )}
                {hasAccessRight("useBulkReport", ["SC_PROCESS_CATEGORIES"]) && (
                    <BulkReportDownloadMenuItem
                        label={"mainLayout.mainMenu.reports_menuEntries.scProcessCategories_button"}
                        fileUrl={"/report-service/sc-process-categories/bulk-report"}
                        baseFileName={"bulkReport.sc_process_categories_file_name"}
                    />
                )}
                {hasAccessRight("useBulkReport", ["SC_FACILITIES"]) && (
                    <BulkReportDownloadMenuItem
                        label={"mainLayout.mainMenu.reports_menuEntries.scFacilities_button"}
                        fileUrl={
                            "/report-service/scope-certificates/sc-products/sc-process-categories/sc-facilities/bulk-report"
                        }
                        baseFileName={"bulkReport.sc_facilities_file_name"}
                    />
                )}
            </DropdownGroup>
            <DropdownGroup>
                {hasAccessRight("useBulkReport", ["TRANSACTION_CERTIFICATES"]) && (
                    <BulkReportDownloadMenuItem
                        label={"mainLayout.mainMenu.reports_menuEntries.transactionCertificates_button"}
                        fileUrl={"/report-service/transaction-certificates/bulk-report"}
                        baseFileName={"bulkReport.transaction_certificate_file_name"}
                    />
                )}
                {hasAccessRight("useBulkReport", ["TC_SHIPMENTS"]) && (
                    <BulkReportDownloadMenuItem
                        label={"mainLayout.mainMenu.reports_menuEntries.tcShipments_button"}
                        fileUrl={"/report-service/transaction-certificates/shipments/bulk-report"}
                        baseFileName={"bulkReport.shipment_file_name"}
                    />
                )}
                {hasAccessRight("useBulkReport", ["TC_PRODUCTS"]) && (
                    <BulkReportDownloadMenuItem
                        label={"mainLayout.mainMenu.reports_menuEntries.tcProducts_button"}
                        fileUrl={"/report-service/tc-products/bulk-report"}
                        baseFileName={"bulkReport.tc_product_file_name"}
                    />
                )}
                {hasAccessRight("useBulkReport", ["TC_MATERIALS"]) && (
                    <BulkReportDownloadMenuItem
                        label={"mainLayout.mainMenu.reports_menuEntries.tcMaterials_button"}
                        fileUrl={"/report-service/tc-materials/bulk-report"}
                        baseFileName={"bulkReport.tc_material_file_name"}
                    />
                )}
                {hasAccessRight("useBulkReport", ["TC_INPUT_QUANTITIES"]) && (
                    <BulkReportDownloadMenuItem
                        label={"mainLayout.mainMenu.reports_menuEntries.tcInputQuantities_button"}
                        fileUrl={"/report-service/input-quantities/bulk-report"}
                        baseFileName={"bulkReport.tc_input_quantity_file_name"}
                    />
                )}
            </DropdownGroup>
            <DropdownGroup>
                {hasAccessRight("useBulkReport", ["PRE_GTB_TCS"]) && (
                    <BulkReportDownloadMenuItem
                        label={"mainLayout.mainMenu.reports_menuEntries.preGtbTcs_button"}
                        fileUrl={"/report-service/pre-gtb-tcs/bulk-report"}
                        baseFileName={"bulkReport.pre_gtb_tc_file_name"}
                    />
                )}
                {hasAccessRight("useBulkReport", ["PRE_GTB_PRODUCTS"]) && (
                    <BulkReportDownloadMenuItem
                        label={"mainLayout.mainMenu.reports_menuEntries.preGtbProducts_button"}
                        fileUrl={"/report-service/pre-gtb-products/bulk-report"}
                        baseFileName={"bulkReport.pre_gtb_tc_product_file_name"}
                    />
                )}
                {hasAccessRight("useBulkReport", ["PRE_GTB_MATERIALS"]) && (
                    <BulkReportDownloadMenuItem
                        label={"mainLayout.mainMenu.reports_menuEntries.preGtbMaterials_button"}
                        fileUrl={"/report-service/pre-gtb-tc-materials/bulk-report"}
                        baseFileName={"bulkReport.pre_gtb_tc_material_file_name"}
                    />
                )}
                {hasAccessRight("useBulkReport", ["PRE_GTB_MATERIAL_ORIGINS"]) && (
                    <BulkReportDownloadMenuItem
                        label={"mainLayout.mainMenu.reports_menuEntries.preGtbMaterialOrigins_button"}
                        fileUrl={"/report-service/pre-gtb-tc-material-origins/bulk-report"}
                        baseFileName={"bulkReport.pre_gtb_tc_material_origin_file_name"}
                    />
                )}
            </DropdownGroup>
            <DropdownGroup>
                {hasAccessRight("useBulkReport", ["ORGANIC_MATERIAL_PRODUCTION_SCS"]) && (
                    <BulkReportDownloadMenuItem
                        label={"mainLayout.mainMenu.reports_menuEntries.organicMaterialProductionSCs_button"}
                        fileUrl={"/report-service/organic-material-production-scs/bulk-report"}
                        baseFileName={"bulkReport.organic_material_production_sc_file_name"}
                    />
                )}
                {hasAccessRight("useBulkReport", ["ORGANIC_MATERIAL_PRODUCTIONS"]) && (
                    <BulkReportDownloadMenuItem
                        label={"mainLayout.mainMenu.reports_menuEntries.organicMaterialProductions_button"}
                        fileUrl={"/report-service/organic-material-productions/bulk-report"}
                        baseFileName={"bulkReport.organic_material_production_file_name"}
                    />
                )}
                {hasAccessRight("useBulkReport", ["ORGANIC_MATERIAL_CERTIFICATIONS"]) && (
                    <DropdownItem>
                        {translation("mainLayout.mainMenu.reports_menuEntries.organicMaterialCertifications_button")}
                    </DropdownItem>
                )}
                {hasAccessRight("useBulkReport", ["ORGANIC_MATERIAL_PURCHASES"]) && (
                    <BulkReportDownloadMenuItem
                        label={"mainLayout.mainMenu.reports_menuEntries.organicMaterialPurchases_button"}
                        fileUrl={"/report-service/organic-material-purchases/bulk-report"}
                        baseFileName={"bulkReport.organic_material_purchase_file_name"}
                    />
                )}
                {hasAccessRight("useBulkReport", ["ORGANIC_MATERIAL_ORIGINS"]) && (
                    <BulkReportDownloadMenuItem
                        label={"mainLayout.mainMenu.reports_menuEntries.organicMaterialOrigins_button"}
                        fileUrl={"/report-service/organic-material-origins/bulk-report"}
                        baseFileName={"bulkReport.organic_material_origin_file_name"}
                    />
                )}
            </DropdownGroup>
            <DropdownGroup>
                {hasAccessRight("useBulkReport", ["ACCREDITATION_RELATIONS"]) && (
                    <BulkReportDownloadMenuItem
                        label={"mainLayout.mainMenu.reports_menuEntries.accreditationRelations_button"}
                        fileUrl={"/report-service/accreditation-relations/bulk-report"}
                        baseFileName={"bulkReport.accreditation_relation_file_name"}
                    />
                )}
                {hasAccessRight("useBulkReport", ["SCO_LICENCE_NUMBERS"]) && (
                    <BulkReportDownloadMenuItem
                        label={"mainLayout.mainMenu.reports_menuEntries.scoLicenceNumbers_button"}
                        fileUrl={"/report-service/sco-licences/bulk-report"}
                        baseFileName={"bulkReport.sco_licence_file_name"}
                    />
                )}
                {hasAccessRight("useBulkReport", ["CB_LICENCING_CODES"]) && (
                    <BulkReportDownloadMenuItem
                        label={"mainLayout.mainMenu.reports_menuEntries.cbLicencingCodes_button"}
                        fileUrl={"/report-service/certification-bodies/certification-licences/bulk-report"}
                        baseFileName={"bulkReport.certification_licences_file_name"}
                    />
                )}
            </DropdownGroup>
        </MainMenuItem>
    );
}
