import { CertificationBodyDetailViewTabProps } from "./CertificationBodyDetailView";
import CertificationBodyPickList from "../../components/Input/pickList/typedPicklist/CertificationBodyPickList";
import useGtbTranslation from "../../i18n/useGtbTranslation";
import "./certificationBodyPriorConsentData.css";
import { joinClassNames } from "../../utils/StringUtils";

export default function CertificationBodyPriorConsentData({
    form,
    readOnlyProps,
}: CertificationBodyDetailViewTabProps) {
    const translation = useGtbTranslation();

    return (
        <div className={joinClassNames("certificationBodyPriorConsentData", "formGrid")}>
            <span id="priorConsentDescription">{translation("certificationBody.detail.priorConsent_description")}</span>
            <CertificationBodyPickList
                control={form.form.control}
                label="certificationBody.detail.priorConsent_input"
                name="cbsWithGrantedPriorConsent"
                readOnly={readOnlyProps.isPriorConsentReadOnly}
                className="priorConsent"
                flags={{ allActive: true }}
                multiple
            />
        </div>
    );
}
