import useIsAuthorised from "../../../auth/useIsAuthorised";
import useResolvedRoute from "../../../components/routing/useResolvedRoute";
import { SortOption, useQueryBuilder } from "../../../components/grid/component/useQueryBuilder";
import { useDeleteAction, useShowDetailsAction } from "../../../utils/defaultActions";
import { useMemo } from "react";
import { SubListProps } from "../../../components/grid/SubList";
import { GridColumn } from "../../../components/grid/component/Grid";
import { buildTextColumn } from "../../../components/grid/ColumnProvider";
import { ScProductDetails, ScProductSummary } from "./scProductTypes";
import { scopeCertificateReadOnlyByStatus } from "../../scopeCertificateTypes";
import { scopeCertificateStatusType } from "../../../auth/roleTypes";
import useGridRowContextMenuActions from "../../../components/grid/component/cell/useGridRowContextMenuActions";
import { insertIf } from "../../../utils/arrayUtils";

export const defaultScProductSortOption: SortOption<ScProductSummary | ScProductDetails> = {
    field: "scProductNumber",
    direction: "ASC",
};

const columns: GridColumn<ScProductSummary>[] = [
    buildTextColumn({ key: "scProductNumber", title: "scProduct.list.scProductNumber_column", minWidth: 180 }),
    buildTextColumn({ key: "productCategoryName", title: "scProduct.list.productCategory_column", minWidth: 188 }),
    buildTextColumn({ key: "productDetailName", title: "scProduct.list.productDetail_column", minWidth: 163 }),
    buildTextColumn({ key: "labelGradeLabelGrade", title: "scProduct.list.labelGrade_column", minWidth: 146 }),
    buildTextColumn({
        key: "materialComposition",
        title: "scProduct.list.materialComposition_column",
        minWidth: 217,
        disableSorting: true,
    }),
    buildTextColumn({
        key: "processCategories",
        title: "scProduct.list.processCategories_column",
        minWidth: 203,
        disableSorting: true,
    }),
    buildTextColumn({
        key: "productCategoryPrint",
        title: "scProduct.list.productCategoryPrint_column",
        minWidth: 239,
        visible: false,
    }),
    buildTextColumn({
        key: "productDetailPrint",
        title: "scProduct.list.productDetailPrint_column",
        minWidth: 214,
        visible: false,
    }),
    buildTextColumn({
        key: "cbScProductReferenceNumber",
        title: "scProduct.list.cbScProductReferenceNumber_column",
        minWidth: 227,
        visible: false,
    }),
];

export default function useScProductSubList(scopeCertificateStatus?: scopeCertificateStatusType) {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedEditUrl, getResolvedCreateUrl, getResolvedId } = useResolvedRoute();
    const scopeCertificateId = getResolvedId("scopeCertificate");

    const { query, columnOptions, forceReload } = useQueryBuilder<ScProductSummary>(
        `/certificate-service/scope-certificates/${scopeCertificateId}/sc-products`,
        {
            sort: defaultScProductSortOption,
            filterActive: false,
        }
    );

    const showDetailsAction = useShowDetailsAction(
        (item: ScProductSummary) => getResolvedEditUrl("scProduct", item.id),
        query.parameter
    );

    const deleteAction = useDeleteAction(
        (item: ScProductSummary) =>
            `/certificate-service/scope-certificates/${scopeCertificateId}/sc-products/${item.id}`,
        () => forceReload(),
        "updateScopeCertificate",
        scopeCertificateStatus ? [scopeCertificateStatus] : []
    );

    const isReadOnlyByScopeCertificateStatus = useMemo<boolean>(
        () => scopeCertificateReadOnlyByStatus(scopeCertificateStatus),
        [scopeCertificateStatus]
    );

    const contextMenuActions = useGridRowContextMenuActions(
        showDetailsAction,
        ...insertIf(!isReadOnlyByScopeCertificateStatus, deleteAction)
    );

    return useMemo<SubListProps<ScProductSummary>>(
        () => ({
            query,
            columns,
            uniqueDataKey: "id",
            onItemDoubleClick: showDetailsAction,
            header: "scProduct.list.header",
            itemActions: contextMenuActions,
            columnOptions,
            createUrl:
                scopeCertificateStatus &&
                hasAccessRight("updateScopeCertificate", [scopeCertificateStatus]) &&
                !isReadOnlyByScopeCertificateStatus
                    ? getResolvedCreateUrl("scProduct")
                    : undefined,
            tableName: "scProducts",
        }),
        [
            columnOptions,
            contextMenuActions,
            getResolvedCreateUrl,
            hasAccessRight,
            isReadOnlyByScopeCertificateStatus,
            query,
            scopeCertificateStatus,
            showDetailsAction,
        ]
    );
}
