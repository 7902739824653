import useIsAuthorised from "../auth/useIsAuthorised";
import { SortOption } from "../components/grid/component/useQueryBuilder";
import { useMemo } from "react";
import { GridColumn } from "../components/grid/component/Grid";
import useListView from "../hooks/useListView";
import { useDeleteAction, useShowDetailsAction } from "../utils/defaultActions";
import { buildBooleanColumn, buildDataColumn, buildTextColumn } from "../components/grid/ColumnProvider";
import { StandardDetails, StandardSummary } from "./standardTypes";
import { EntityListViewGridProps } from "../components/entity/EntityListView";
import useResolvedRoute, { getCreateUrl } from "../components/routing/useResolvedRoute";
import { EnumEntityType } from "../components/Input/pickList/typedEnumPicklist/enumEntityType";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";

export const defaultStandardSortOption: SortOption<StandardSummary | StandardDetails> = {
    field: "acronym",
    direction: "ASC",
};
const columns: GridColumn<StandardSummary>[] = [
    buildTextColumn({ key: "acronym", title: "standard.list.acronym_column", minWidth: 126 }),
    buildTextColumn({ key: "officialName", title: "standard.list.officialName_column", minWidth: 123 }),
    buildDataColumn<StandardSummary, EnumEntityType>({
        key: "typeOfStandard",
        title: "standard.list.standardType_column",
        minWidth: 305,
        url: "/certificate-service/references/type-of-standards",
        itemId: "id",
        itemLabel: "name",
    }),
    buildTextColumn({ key: "standardBodyBusinessName", title: "standard.list.standardBody_column", minWidth: 168 }),
    buildBooleanColumn({
        key: "transactionCertificatesMandatory",
        title: "standard.list.transactionCertificatesMandatory_column",
        minWidth: 120,
        visible: false,
    }),
];

function useStandardListView() {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedEditUrl } = useResolvedRoute();

    const {
        queryBuilder: { forceReload, columnOptions, query, queryCommands },
    } = useListView<StandardSummary>({
        navigationContextLabel: "standard.breadcrumb",
        baseQuery: "/certificate-service/standards",
        defaultSortOption: defaultStandardSortOption,
    });

    const showDetailsAction = useShowDetailsAction((item) => getResolvedEditUrl("standard", item.id), query.parameter);

    const deleteAction = useDeleteAction(
        (item: StandardSummary) => `/certificate-service/standards/${item.id}`,
        () => forceReload(),
        "deleteMasterData"
    );

    const actions = useGridRowContextMenuActions(showDetailsAction, deleteAction);

    return useMemo<EntityListViewGridProps<StandardSummary>>(() => {
        return {
            query,
            columns: columns,
            uniqueDataKey: "id",
            columnOptions,
            onItemDoubleClick: showDetailsAction,
            createUrl: hasAccessRight("createMasterData") ? getCreateUrl("standard") : undefined,
            tableName: "standard",
            itemActions: actions,
            queryCommands,
            bulkDeleteAccessRight: "deleteMasterData",
        };
    }, [query, columnOptions, showDetailsAction, hasAccessRight, actions, queryCommands]);
}

export default useStandardListView;
