import { useCallback, useEffect, useState } from "react";
import { UserDetails } from "../userTypes";
import { useMutation, useQuery } from "../../hooks/useAxios";
import useResetTotpDialog from "../../components/dialog/useResetTotpDialog";
import useActivateTotpDialog, { ConfirmTotpType } from "../../components/dialog/useActivateTotpDialog";
import useGtbTranslation from "../../i18n/useGtbTranslation";
import useAuth from "../../auth/useAuth";

export default function useUserTotp(initialTotpReady?: boolean) {
    const showResetTotpDialog = useResetTotpDialog();
    const showActivateTotpDialog = useActivateTotpDialog();
    const translation = useGtbTranslation();
    const [totpReady, setTotpReady] = useState(initialTotpReady);

    useEffect(() => {
        setTotpReady(initialTotpReady);
    }, [initialTotpReady]);

    const { runQuery: runResetTotp } = useMutation({
        method: "delete",
        url: "",
    });

    const { runQuery: runRequestTotp } = useMutation({
        method: "post",
        url: "/certificate-service/users/me/totp-secret-request",
    });

    const { runQuery: runConfirmTotp } = useMutation({
        method: "post",
        url: "/certificate-service/users/me/totp-secret-confirmation",
    });

    const { runQuery: runTotpReadyQuery } = useQuery<UserDetails>({ url: "", enabled: false });

    const {
        currentUser: { id: currentUserId },
    } = useAuth();

    // Check if user is the same, then use /me else use /UUID
    const refreshTotpReady = useCallback(
        (userId: string) => {
            runTotpReadyQuery({
                url: "/certificate-service/users/" + (currentUserId === userId ? "me" : userId),
            }).then((user: UserDetails) => setTotpReady(user.totpReady));
        },
        [currentUserId, runTotpReadyQuery]
    );

    const handleTotpConfirmError = useCallback(
        (error, setConfirmTotpError) => {
            if (error?.statusCode === 401) {
                setConfirmTotpError(
                    "totp",
                    { message: translation("validation.noValidTotp_message") },
                    { shouldFocus: true }
                );
            } else {
                setConfirmTotpError("totp", { message: translation("validation.cannotConnect_message") });
            }
        },
        [translation]
    );

    const onConfirmTotp = useCallback(
        (userId: string, { afterConfirm, onConfirmError, totp }: ConfirmTotpType) => {
            return runConfirmTotp({ body: { totp } })
                .then(() => {
                    refreshTotpReady(userId);
                    afterConfirm();
                })
                .catch((e) => {
                    handleTotpConfirmError(e, onConfirmError);
                });
        },
        [handleTotpConfirmError, refreshTotpReady, runConfirmTotp]
    );

    const activateTotpDialog = useCallback(
        (userData: UserDetails) => {
            runRequestTotp().then((secretToken) => {
                return showActivateTotpDialog(
                    userData.username,
                    secretToken.secret,
                    ({ afterConfirm, onConfirmError, totp }: ConfirmTotpType) => {
                        return onConfirmTotp(userData.id, {
                            afterConfirm,
                            onConfirmError,
                            totp,
                        });
                    }
                );
            });
        },
        [onConfirmTotp, runRequestTotp, showActivateTotpDialog]
    );

    const resetTotpDialog = useCallback(
        (userData: UserDetails) => {
            runResetTotp({ url: `/certificate-service/users/${userData.id}/totp-secret` }).then(() => {
                refreshTotpReady(userData.id);
                return showResetTotpDialog();
            });
        },
        [refreshTotpReady, runResetTotp, showResetTotpDialog]
    );

    return {
        activateTotp: activateTotpDialog,
        resetTotp: resetTotpDialog,
        totpReady,
    };
}
