import { Reducer, useCallback, useEffect, useReducer, useRef } from "react";
import { useInView } from "react-intersection-observer";
import { useQuery } from "./useAxios";
import { handleResponseError } from "../utils/errorHandler";
import { buildQuery, Query } from "../components/grid/component/useQueryBuilder";

/**
 * Hook for paginated querying of a resource.
 *
 * Use the returned nextPageRef for the element that should trigger the querying of the next page,
 * when the element is scrolled into view.
 *
 * @param query query to be used for building the query sent to the backend.
 * @param limit number of items to return in each request.
 */
function useInfiniteQuery<T>(query: Query<T>, limit: number) {
    const [state, dispatch] = useReducer<Reducer<UseInfiniteQueryState<T>, UseInfiniteQueryAction<T>>>(
        reducer,
        buildInitialInfinityQueryPage(limit)
    );

    const currentQuery = useRef(query);

    useEffect(() => {
        currentQuery.current = query;
        dispatch({});
    }, [query]);

    const { ref, inView } = useInView();

    const numberOfLoadedItems = useRef(0);

    useEffect(() => {
        numberOfLoadedItems.current = state.items.length;
    }, [state.items.length]);

    const { isLoading, runQuery, isError, isIdle } = useQuery<InfiniteQueryPage<T>>({
        url: "",
        enabled: false,
    });

    useEffect(() => {
        if (isError) {
            dispatch("error");
        }
    }, [isError]);

    const queryNextPage = useCallback(() => {
        const initialQuery = currentQuery.current;
        runQuery({
            url: buildQuery({
                ...initialQuery,
                parameter: {
                    ...initialQuery.parameter,
                    limit: state.limit,
                    offset: numberOfLoadedItems.current,
                },
            }),
        })
            .then((page) => {
                if (currentQuery.current.changeCount !== initialQuery.changeCount) {
                    queryNextPage();
                } else {
                    dispatch({ payload: page });
                }
            })
            .catch(handleResponseError);
    }, [runQuery, state.limit]);

    useEffect(() => {
        if (isIdle || (inView && state.hasMore)) {
            queryNextPage();
        }
    }, [inView, state.hasMore, queryNextPage, isIdle]);

    return {
        ...state,
        isLoading: isLoading || isIdle,
        isError,
        nextPageRef: ref,
    };
}

export interface InfiniteQueryPage<T> {
    items: T[];
    limit: number;
    total: number;
    offset: number;
}

interface UseInfiniteQueryState<T> {
    items: T[];
    total: number | null;
    offset: number;
    limit: number;
    hasMore: boolean;
}

type UseInfiniteQueryAction<T> =
    | "error"
    | {
          payload?: InfiniteQueryPage<T>;
      };

function buildInitialInfinityQueryPage(limit: number) {
    return {
        items: [],
        total: null,
        offset: 0,
        limit,
        hasMore: true,
    };
}

const reducer = <T>(state: UseInfiniteQueryState<T>, action: UseInfiniteQueryAction<T>): UseInfiniteQueryState<T> => {
    if ("error" === action) {
        return {
            ...buildInitialInfinityQueryPage(state.limit),
            hasMore: false,
        };
    } else if (action.payload) {
        const { payload: page } = action;
        const newItems = [...state.items, ...page.items];
        return {
            ...state,
            total: page.total,
            items: newItems,
            offset: page.offset,
            hasMore: newItems.length < page.total,
        };
    } else {
        return buildInitialInfinityQueryPage(state.limit);
    }
};

export default useInfiniteQuery;
