import { joinClassNames } from "../../utils/StringUtils";
import ControlledDatePicker from "../../components/Input/datepicker/ControlledDatePicker";
import { UseGtbFormReturn } from "../../hooks/formHandling/useGtbForm";
import { OrganicMaterialProductionScDetails } from "../organicMaterialProductionScTypes";
import GtbTextField from "../../components/Input/GtbTextField";
import { GtbNumberFieldOneFixedDecimal } from "../../components/Input/GtbNumberField";
import { ReadOnlyProps } from "../../utils/typeUtils";
import StandardPickList from "../../components/Input/pickList/typedPicklist/StandardPickList";
import OrganicMaterialProducerPickList from "../../components/Input/pickList/typedPicklist/OrganicMaterialProducerPickList";
import ControlledFileUploadComponent from "../../components/Input/UploadComponent/ControlledFileUploadComponent";
import { PDF_TYPE } from "../../components/Input/UploadComponent/FileUploadComponent";
import { backendUrlType } from "../../hooks/useAxios";
import "./organicMaterialProductionScBasicData.css";
import useOrganicMaterialProductionSubList from "../../organicMaterialProductions/useOrganicMaterialProductionSubList";
import InfinityScrollSubList from "../../components/grid/InfinityScrollSubList";
import GtbButton from "../../components/GtbButton";
import Icon from "../../components/Icon";
import useGtbTranslation from "../../i18n/useGtbTranslation";

export interface OrganicMaterialProductionScBasicDataProps {
    form: UseGtbFormReturn<OrganicMaterialProductionScDetails>;
    readOnly: Omit<
        ReadOnlyProps<OrganicMaterialProductionScDetails>,
        "id" | "warningCanonicalEqualScNumberAndStandard"
    >;
    scCopyUrl: backendUrlType;
    subListVisible?: boolean;
    organicMaterialProducerId?: string;
}

export default function OrganicMaterialProductionScBasicData({
    form,
    readOnly,
    scCopyUrl,
    subListVisible,
    organicMaterialProducerId,
}: OrganicMaterialProductionScBasicDataProps) {
    const translation = useGtbTranslation();
    const organicMaterialProductionListOptions = useOrganicMaterialProductionSubList(organicMaterialProducerId);

    return (
        <div className={joinClassNames("organicMaterialProductionScBasicData", "formGrid")}>
            <GtbTextField
                label="organicMaterialProductionSc.detail.scNumber_input"
                {...form.registerWithErrors("scNumber")}
                readOnly={readOnly.scNumber}
                className="scNumber"
            />
            <GtbTextField
                label="organicMaterialProductionSc.detail.issuingBody_input"
                {...form.registerWithErrors("issuingBody")}
                readOnly={readOnly.issuingBody}
                className="issuingBody"
            />
            <StandardPickList
                label="organicMaterialProductionSc.detail.standard_input"
                control={form.form.control}
                name="standard"
                readOnly={readOnly.standard}
                flags={{ typeOfStandard: "ORGANIC_MATERIAL_PRODUCTION" }}
                className="standard"
            />
            <OrganicMaterialProducerPickList
                label="organicMaterialProductionSc.detail.producer_input"
                control={form.form.control}
                name="organicMaterialProducer"
                readOnly={readOnly.organicMaterialProducer}
                className="producer"
            />
            <GtbNumberFieldOneFixedDecimal
                label="organicMaterialProductionSc.detail.totalFarmAreaUnderSc_input"
                control={form.form.control}
                name="totalFarmAreaUnderSc"
                readOnly={readOnly.totalFarmAreaUnderSc}
                className="totalFarmArea"
                decimalPlaces={1}
                unit="ha"
            />
            <ControlledDatePicker
                control={form.form.control}
                name="validFrom"
                label="organicMaterialProductionSc.detail.validFrom_input"
                className="validFrom"
                readOnly={readOnly.validFrom}
            />
            <ControlledDatePicker
                control={form.form.control}
                name="validUntil"
                label="organicMaterialProductionSc.detail.validUntil_input"
                className="validUntil"
                readOnly={readOnly.validUntil}
            />
            <ControlledFileUploadComponent
                label="organicMaterialProductionSc.detail.scCopy_input"
                control={form.form.control}
                name="scCopy"
                acceptedTypes={PDF_TYPE}
                fileUrl={scCopyUrl}
                readOnly={readOnly.scCopy}
                className="scCopy"
            />
            {subListVisible && (
                <InfinityScrollSubList
                    {...organicMaterialProductionListOptions}
                    className="organicMaterialProduction"
                    gridControlsLeft={
                        organicMaterialProductionListOptions.onAddListItem
                            ? [
                                  <GtbButton
                                      aria-describedby={translation("components.grid.addListItem_button")}
                                      className={"iconOnRight"}
                                      variant={"secondary"}
                                      size={"medium"}
                                      onClick={organicMaterialProductionListOptions.onAddListItem}
                                      key={"addOrganicMaterialProduction"}
                                  >
                                      {translation("components.grid.addListItem_button")}
                                      <Icon name={"plus"} size={12} />
                                  </GtbButton>,
                              ]
                            : []
                    }
                />
            )}
        </div>
    );
}
