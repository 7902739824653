import useExternalSupplyChainOperatorIdListView from "./useExternalSupplyChainOperatorIdListView";
import EntityListView from "../../components/entity/EntityListView";
import { useHelpContext } from "../../help/context-sensitivity/useHelpContext";

export default function ExternalSupplyChainOperatorIdListView() {
    const externalSupplyChainOperatorIdGridOptions = useExternalSupplyChainOperatorIdListView();
    useHelpContext("external-supply-chain-operator-id-list");

    return (
        <EntityListView
            heading="externalSupplyChainOperatorId.list.header"
            gridOptions={externalSupplyChainOperatorIdGridOptions}
        />
    );
}
