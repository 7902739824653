import useResolvedRoute, { getEditUrl, getIdName, routeEnum } from "../../components/routing/useResolvedRoute";
import * as React from "react";
import { useCallback } from "react";
import { useGtbNavigate } from "../../components/routing/GtbRouter";
import UseWarningDialog from "../../components/dialog/UseWarningDialog";
import { useQuery } from "../../hooks/useAxios";
import { FacilityReference } from "../facilityTypes";
import GtbButton from "../../components/GtbButton";
import useGtbTranslation from "../../i18n/useGtbTranslation";

export default function useAcceptFacilityDuplicate() {
    const navigate = useGtbNavigate(true);
    const { getResolvedId } = useResolvedRoute();
    const routeEnumKey: routeEnum = "facility";
    const { showDialog, closeDialog } = UseWarningDialog();
    const currentSupplyChainOperator = getResolvedId("supplyChainOperator");
    const translation = useGtbTranslation();

    const { runQuery } = useQuery<FacilityReference>({
        url: "",
        enabled: false,
    });

    const navigateToSelectedFacility = useCallback(
        (facilityId: string, supplyChainOperatorId) => {
            const url = getEditUrl(routeEnumKey)
                .replace(":" + getIdName("supplyChainOperator"), supplyChainOperatorId)
                .replace(":" + getIdName("facility"), facilityId);

            navigate(url);
        },
        [navigate]
    );

    return useCallback(
        (itemId: string) => {
            runQuery({ url: "/certificate-service/references/facilities/" + itemId }).then(
                (value: FacilityReference) => {
                    const selectedSupplyChainOperatorId = value.supplyChainOperator;
                    if (selectedSupplyChainOperatorId !== currentSupplyChainOperator) {
                        showDialog({
                            title: "facility.detail.duplicateNotBelongToSupplyChainOperator_dialogTitle",
                            message: "facility.detail.duplicateNotBelongToSupplyChainOperator_dialogText",
                            footer: (
                                <>
                                    <GtbButton
                                        variant="secondary"
                                        onClick={() => {
                                            closeDialog();
                                            navigateToSelectedFacility(itemId, selectedSupplyChainOperatorId);
                                        }}
                                    >
                                        {translation(
                                            "facility.detail.duplicateNotBelongToSupplyChainOperator_dialogConfirmButton"
                                        )}
                                    </GtbButton>
                                    <GtbButton onClick={closeDialog}>
                                        {translation("components.dialog.cancel_button")}
                                    </GtbButton>
                                </>
                            ),
                        });
                    } else {
                        navigateToSelectedFacility(itemId, selectedSupplyChainOperatorId);
                    }
                }
            );
        },
        [closeDialog, currentSupplyChainOperator, navigateToSelectedFacility, runQuery, showDialog, translation]
    );
}
