import { SortOption } from "../components/grid/component/useQueryBuilder";
import { useMemo } from "react";
import { GridColumn } from "../components/grid/component/Grid";
import useListView from "../hooks/useListView";
import { useDeleteAction, useShowDetailsAction } from "../utils/defaultActions";
import { buildDataColumn, buildDateColumn, buildTextColumn } from "../components/grid/ColumnProvider";
import useResolvedRoute, { getCreateUrl } from "../components/routing/useResolvedRoute";
import { EntityListViewGridProps } from "../components/entity/EntityListView";
import { BuyingRelationDetails, BuyingRelationSummary } from "./buyingRelationTypes";
import { EnumEntityType } from "../components/Input/pickList/typedEnumPicklist/enumEntityType";
import useIsAuthorised from "../auth/useIsAuthorised";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";

export const defaultBuyingRelationSortOption: SortOption<BuyingRelationSummary | BuyingRelationDetails> = {
    field: "sellerSupplyChainOperatorBusinessName",
    direction: "ASC",
};

const columns: GridColumn<BuyingRelationSummary>[] = [
    buildTextColumn({
        key: "sellerSupplyChainOperatorBusinessName",
        title: "buyingRelation.list.seller_column",
        minWidth: 124,
    }),
    buildTextColumn({
        key: "buyerSupplyChainOperatorBusinessName",
        title: "buyingRelation.list.buyer_column",
        minWidth: 166,
    }),
    buildTextColumn({
        key: "standardAcronym",
        title: "buyingRelation.list.standard_column",
        minWidth: 123,
    }),
    buildDataColumn<BuyingRelationSummary, EnumEntityType>({
        key: "accessLevel",
        title: "buyingRelation.list.accessLevel_column",
        minWidth: 137,
        url: "/certificate-service/references/buying-relation-access-levels",
        itemId: "id",
        itemLabel: "name",
    }),
    buildDataColumn<BuyingRelationSummary, EnumEntityType>({
        key: "confirmationMethod",
        title: "buyingRelation.list.confirmationMethod_column",
        minWidth: 202,
        itemId: "id",
        itemLabel: "name",
        url: "/certificate-service/references/buying-relation-confirmation-methods",
    }),
    buildDateColumn({
        key: "startDate",
        title: "buyingRelation.list.startDate_column",
        minWidth: 130,
        visible: false,
    }),
    buildDateColumn({
        key: "endDate",
        title: "buyingRelation.list.endDate_column",
        minWidth: 124,
        visible: false,
    }),
];

export default function useBuyingRelationListView() {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedEditUrl } = useResolvedRoute();

    const {
        queryBuilder: { forceReload, columnOptions, query, queryCommands },
    } = useListView<BuyingRelationSummary>({
        navigationContextLabel: "buyingRelation.breadcrumb",
        baseQuery: "/certificate-service/buying-relations",
        defaultSortOption: defaultBuyingRelationSortOption,
    });

    const showDetailsAction = useShowDetailsAction(
        (item: BuyingRelationSummary) => getResolvedEditUrl("buyingRelation", item.id),
        query.parameter
    );

    const deleteAction = useDeleteAction(
        (item: BuyingRelationSummary) => `/certificate-service/buying-relations/${item.id}`,
        () => forceReload(),
        "deleteOperationLevelOrganisation"
    );

    const actions = useGridRowContextMenuActions(showDetailsAction, deleteAction);

    return useMemo<EntityListViewGridProps<BuyingRelationSummary>>(() => {
        return {
            query,
            columns,
            uniqueDataKey: "id",
            onItemDoubleClick: showDetailsAction,
            createUrl: hasAccessRight("createOperationLevelOrganisation") ? getCreateUrl("buyingRelation") : undefined,
            tableName: "buyingRelation",
            columnOptions,
            itemActions: actions,
            queryCommands,
            bulkDeleteAccessRight: () => hasAccessRight("deleteOperationLevelOrganisation"),
        };
    }, [query, showDetailsAction, hasAccessRight, columnOptions, actions, queryCommands]);
}
