import StandardBodyDetailView from "./StandardBodyDetailView";
import useStandardBodyCreate from "./useStandardBodyCreate";

function StandardBodyCreateView() {
    const standardBodyDetailProps = useStandardBodyCreate();

    return <StandardBodyDetailView {...standardBodyDetailProps} />;
}

export default StandardBodyCreateView;
