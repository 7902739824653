import { TypedPicklist } from "./typedPicklistType";
import DataPickList from "../DataPickList";
import { joinClassNames } from "../../../../utils/StringUtils";

import "./typedPickList.css";
import { RawMaterialReference } from "../../../../rawMaterial/rawMaterialTypes";
import { FieldValues } from "react-hook-form/dist/types";

export default function CertifiedMaterialPickList<FormItemType extends FieldValues>({
    className,
    standardId,
    ...rest
}: TypedPicklist<FormItemType, RawMaterialReference> & {
    standardId?: string;
}) {
    return (
        <DataPickList
            className={joinClassNames("allowedMaterialPickList", className)}
            itemId="id"
            itemLabel={(option) => `${option.name} (${option.code})`}
            queryUrl={`/certificate-service/references/standards/${standardId}/allowed-materials`}
            {...rest}
        />
    );
}
