import AccreditationBodyDetailView from "./AccreditationBodyDetailView";
import useAccreditationBodyCreate from "./useAccreditationBodyCreate";

function AccreditationBodyCreateView() {
    const accreditationBodyDetailProps = useAccreditationBodyCreate();

    return <AccreditationBodyDetailView {...accreditationBodyDetailProps} />;
}

export default AccreditationBodyCreateView;
