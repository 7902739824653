import "./tabs.css";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import useGtbTranslation, { I18nKey } from "../../i18n/useGtbTranslation";
import { Nullable } from "../../utils/typeUtils";
import { FieldValues } from "react-hook-form/dist/types";
import { error } from "../../utils/notification/notification";
import DropdownLinkItem from "../dropdown/DropdownLinkItem";
import DropdownComponent from "../DropdownComponent";
import { useBuildArrowNavigationListener } from "../../utils/a11yUtils";
import GtbLink from "../routing/GtbLink";

export type TabKey<keys extends I18nKey & string> = { [key in keys]: never };

export type TabProps<TabKeys> = SingleTabProps<TabKeys> | MultiTabProps<TabKeys>;

export type SingleTabProps<TabKeys> = {
    heading: I18nKey & keyof TabKeys;
    element: JSX.Element;
};

export type MultiTabProps<TabKeys> = {
    heading: I18nKey & keyof TabKeys;
    childTabs: SingleTabProps<TabKeys>[];
};

type EnrichedSingleTabProps<TabKeys> = SingleTabProps<TabKeys> & { index: number };
type EnrichedMultiTabProps<TabKeys> = Pick<MultiTabProps<TabKeys>, "heading"> & {
    startIndex: number;
    childTabs: EnrichedSingleTabProps<TabKeys>[];
};

type EnrichedTabProps<TabKeys> = EnrichedSingleTabProps<TabKeys> | EnrichedMultiTabProps<TabKeys>;

export interface TabsProps<ItemType extends FieldValues, TabKeys> {
    tabs: TabProps<TabKeys>[];
    errorToTab: { [key in keyof Nullable<ItemType>]: keyof TabKeys | "internal" };
    errors: any;
}

function safelyParseInt(value?: string | null) {
    let parseableValue = value ?? "0";
    let tabIndex = parseInt(parseableValue);
    return isNaN(tabIndex) ? 0 : tabIndex;
}

const TAB_INDEX = "tabIndex";

export default function Tabs<ItemType extends FieldValues, TabKeys>({
    errors,
    errorToTab,
    tabs,
}: TabsProps<ItemType, TabKeys>) {
    const [tabErrors, setTabErrors] = useState<(keyof TabKeys | "internal")[]>();
    const { pathname, state } = useLocation();
    const [searchParams] = useSearchParams();
    const translation = useGtbTranslation();
    const arrowNavigationListener = useBuildArrowNavigationListener("li", "horizontal");

    useEffect(() => {
        const _tabErrors = Object.keys(errors).map((_error) => errorToTab[_error]);
        if (_tabErrors.includes("internal")) {
            error(translation("error.detailView.internalApplicationError_toast"));
        }
        setTabErrors(_tabErrors);
    }, [errorToTab, errors, translation]);

    const enrichedTabs: EnrichedTabProps<TabKeys>[] = useMemo(() => {
        let tabCounter = 0;

        return tabs.map((tab) => {
            if ("childTabs" in tab) {
                return {
                    ...tab,
                    startIndex: tabCounter,
                    childTabs: tab.childTabs.map((t) => ({ ...t, index: tabCounter++ })),
                };
            } else {
                return { ...tab, index: tabCounter++ };
            }
        });
    }, [tabs]);

    const flattenedTabs = useMemo(() => tabs.flatMap((tab) => ("childTabs" in tab ? tab.childTabs : tab)), [tabs]);

    const currentTabIndex = useMemo(() => {
        const parsedIndex = safelyParseInt(searchParams.get(TAB_INDEX));
        if (parsedIndex >= flattenedTabs.length) {
            return flattenedTabs.length - 1;
        }
        return parsedIndex;
    }, [searchParams, flattenedTabs.length]);

    const getSearchParams = useCallback(
        (index: number) => {
            const params = new URLSearchParams(searchParams);
            params.set(TAB_INDEX, index.toString());
            return params.toString();
        },
        [searchParams]
    );

    const tabLinkProps = useCallback(
        (tab: EnrichedSingleTabProps<TabKeys>) => ({
            key: tab.heading as string,
            id: `tab__${tab.index}`,
            role: "tab",
            forceNavigation: true,
            to: { pathname: pathname, search: getSearchParams(tab.index) },
            ...(currentTabIndex === tab.index ? { "aria-selected": true } : { "aria-selected": false, tabIndex: -1 }),
            ...(tabErrors?.includes(tab.heading) ? { "aria-invalid": true } : {}),
            state: state,
        }),
        [currentTabIndex, getSearchParams, pathname, state, tabErrors]
    );

    const renderTab = useCallback(
        (tab: EnrichedSingleTabProps<TabKeys>) => <GtbLink {...tabLinkProps(tab)}>{translation(tab.heading)}</GtbLink>,
        [tabLinkProps, translation]
    );

    const renderDropdownTab = useCallback(
        (tab: EnrichedMultiTabProps<TabKeys>) => {
            return (
                <DropdownComponent
                    key={tab.heading as string}
                    label={tab.heading}
                    {...(currentTabIndex >= tab.startIndex && currentTabIndex < tab.startIndex + tab.childTabs.length
                        ? { "aria-current": true }
                        : { tabIndex: -1 })}
                >
                    {tab.childTabs.map((childTab) => (
                        <DropdownLinkItem
                            {...tabLinkProps(childTab)}
                            label={childTab.heading}
                            resetNavigationContext={false}
                        />
                    ))}
                </DropdownComponent>
            );
        },
        [currentTabIndex, tabLinkProps]
    );

    return (
        <div className="tabs">
            <ul role="tablist" onKeyDown={arrowNavigationListener}>
                {enrichedTabs.map((tab) => (
                    <li key={tab.heading as string} role="presentation">
                        {"childTabs" in tab ? renderDropdownTab(tab) : renderTab(tab)}
                    </li>
                ))}
            </ul>
            {enrichedTabs
                .flatMap((tab) => ("childTabs" in tab ? tab.childTabs : tab))
                .map((tab: SingleTabProps<TabKeys> & { index?: number }) => (
                    <div
                        key={`tab__${tab.index}`}
                        role="tabpanel"
                        aria-labelledby={`tab__${tab.index}`}
                        {...(currentTabIndex === tab.index ? {} : { hidden: true })}
                    >
                        {currentTabIndex === tab.index ? tab.element : null}
                    </div>
                ))}
        </div>
    );
}
