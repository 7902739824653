import { joinClassNames } from "../../utils/StringUtils";
import "./toggle.css";
import { ChangeEventHandler, forwardRef } from "react";
import useGtbTranslation, { I18nKey } from "../../i18n/useGtbTranslation";

export default forwardRef<HTMLInputElement, ToggleProps>(
    ({ label, id, checked, onChange, className, disabled, ...rest }: ToggleProps, ref) => {
        const translation = useGtbTranslation();

        return (
            <div className={joinClassNames("toggle-container", className)} {...rest}>
                <div className="relativeContentWrapper">
                    <input
                        id={id}
                        type="checkbox"
                        role="switch"
                        checked={checked}
                        onChange={onChange}
                        ref={ref}
                        disabled={disabled}
                    />
                    <label htmlFor={id}>{translation(label)}</label>
                </div>
            </div>
        );
    }
);

interface ToggleProps {
    id: string;
    label: I18nKey;
    disabled?: boolean;
    checked?: boolean;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    className?: string;
}
