import { CountryAreaDetails } from "../countryAreaTypes";
import { yupResolver } from "@hookform/resolvers/yup";
import { countryAreaValidationSchema } from "./countryAreaValidationSchema";
import useIsAuthorised from "../../auth/useIsAuthorised";
import { defaultCountryAreaSortOption } from "../useCountryAreaListView";
import useResolvedRoute, { routeEnum } from "../../components/routing/useResolvedRoute";
import { useMemo } from "react";
import useGtbTranslation from "../../i18n/useGtbTranslation";
import useDomainEntityDetailsEdit from "../../hooks/details/useDomainEntityDetailsEdit";
import { buildTranslatedHistoryColumns } from "../../i18n/languageUtils";
import useLanguage from "../../i18n/LanguageContextProvider";

function useCountryAreaEdit() {
    const translation = useGtbTranslation();
    const currentLanguage = useLanguage();
    const { getResolvedId } = useResolvedRoute();
    const { hasAccessRight } = useIsAuthorised();
    const countryAreaId = getResolvedId("countryArea");

    const { detailViewProps, form, initialData, isReadOnly, title, recordNavigationProps } =
        useDomainEntityDetailsEdit<CountryAreaDetails>({
            baseUrl: "/certificate-service/countries/" + countryAreaId,
            frontendRoute: "countryArea",
            recordNavigationDefaultSort: defaultCountryAreaSortOption,
            titleBuilder: (item) => item.name[currentLanguage],
            resolver: yupResolver(countryAreaValidationSchema),
            canSave: hasAccessRight("updateMasterData"),
            historyColumns: [
                ...buildTranslatedHistoryColumns<CountryAreaDetails>("countryArea.history.name_column", "name"),
                {
                    identifier: "subdivisionsMandatory",
                    title: "countryArea.history.subdivisionsMandatory_column",
                },
                { identifier: "isoCode", title: "countryArea.history.isoCode_column" },
                { identifier: "callingCode", title: "countryArea.history.callingCode_column" },
                { identifier: "addressPattern", title: "countryArea.history.addressPattern_column" },
                {
                    identifier: "registrationIdType",
                    title: "countryArea.history.registrationIdType_column",
                },
                {
                    identifier: "registrationIdTypeOmp",
                    title: "countryArea.history.registrationIdTypeOmp_column",
                },
                { identifier: "welcomeMessage", title: "countryArea.history.welcomeMessage_column" },
                { identifier: "startDate", title: "countryArea.history.startDate_column" },
                { identifier: "endDate", title: "countryArea.history.endDate_column" },
                { identifier: "nameTranscriptions", title: "countryArea.history.name_transcriptions_column" },
            ],
        });

    const createRoute = useMemo<routeEnum | undefined>(() => {
        return hasAccessRight("createMasterData") ? "countryArea" : undefined;
    }, [hasAccessRight]);

    return {
        detailViewProps,
        form,
        isReadOnly,
        subListVisible: true,
        title,
        subtitle: translation({
            key: "countryArea.detail.subtitle",
            options: { countryCode: initialData?.["isoCode"] },
        }),
        recordNavigationProps,
        createRoute,
    };
}

export default useCountryAreaEdit;
