import useIsAuthorised from "../../../auth/useIsAuthorised";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { defaultAdditionalMaterialSortOption } from "../useAdditionalMaterialSubList";
import { additionalMaterialValidationSchema } from "./additionalMaterialValidationSchema";
import { useQuery } from "../../../hooks/useAxios";
import { MaterialContentRuleDetails } from "../../materialContentRuleTypes";
import { AdditionalMaterialDetails } from "../additionalMaterialTypes";
import { useMemo } from "react";
import useResolvedRoute, { routeEnum } from "../../../components/routing/useResolvedRoute";
import useDomainEntityDetailsEdit from "../../../hooks/details/useDomainEntityDetailsEdit";
import { LabelGradeReference } from "../../../labelGrade/labelGradeTypes";
import { VersionReference } from "../../../standard/version/versionTypes";

export default function useAdditionalMaterialEdit() {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedId } = useResolvedRoute();
    const materialContentRuleId = getResolvedId("materialContentRule");
    const labelGradeId = getResolvedId("labelGrade");
    const additionalMaterialId = getResolvedId("additionalMaterial");
    const standardId = getResolvedId("standard");
    const standardVersionId = getResolvedId("standardVersion");

    const { data: standardVersion, isLoading: isVersionLoading } = useQuery<VersionReference>({
        url: "/certificate-service/references/standard-versions/" + standardVersionId,
    });

    const { data: materialContentRule, isLoading: isMaterialContentRuleLoading } = useQuery<MaterialContentRuleDetails>(
        {
            url: "/certificate-service/material-content-rules/" + materialContentRuleId,
        }
    );

    const { data: labelGrade, isLoading: isLabelGradeLoading } = useQuery<LabelGradeReference>({
        url: "/certificate-service/references/label-grades/" + labelGradeId,
    });

    const { detailViewProps, form, initialData, isReadOnly, title, recordNavigationProps } =
        useDomainEntityDetailsEdit<AdditionalMaterialDetails>({
            baseUrl: `/certificate-service/material-content-rules/${materialContentRuleId}/additional-materials/${additionalMaterialId}`,
            resolver: yupResolver(additionalMaterialValidationSchema),
            frontendRoute: "additionalMaterial",
            recordNavigationDefaultSort: defaultAdditionalMaterialSortOption,
            titleBuilder: (item) =>
                `${item.materialContentRuleCertifiedMaterialCode} ${item.materialContentRuleCertifiedMaterialName} & ${item.rawMaterialCode} ${item.rawMaterialName}`,
            canSave: hasAccessRight("updateMasterData"),
            historyColumns: [
                {
                    identifier: "materialContentRule",
                    title: "additionalMaterial.history.materialContentRule_column",
                },
                { identifier: "rawMaterial", title: "additionalMaterial.history.rawMaterial_column" },
                {
                    identifier: "maximumPercentage",
                    title: "additionalMaterial.history.maximumPercentage_column",
                },
            ],
        });

    const createRoute = useMemo<routeEnum | undefined>(() => {
        return hasAccessRight("createMasterData") ? "additionalMaterial" : undefined;
    }, [hasAccessRight]);

    return {
        detailViewProps: {
            ...detailViewProps,
            isLoading:
                detailViewProps.isLoading || isMaterialContentRuleLoading || isVersionLoading || isLabelGradeLoading,
        },
        form,
        title,
        initialData,
        readOnly: isReadOnly,
        materialContentRule,
        recordNavigationProps,
        createRoute,
        labelGrade,
        standard: standardId,
        standardVersion: standardVersion
            ? `${standardVersion.standardAcronym} ${standardVersion.versionNumber}`
            : undefined,
    };
}
