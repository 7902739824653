import useExternalIdSubList from "../useExternalIdSubList";
import useResolvedRoute from "../../components/routing/useResolvedRoute";

export default function useExternalFacilityIdSubList() {
    const { getResolvedId } = useResolvedRoute();
    const facilityId = getResolvedId("facility");

    return useExternalIdSubList({
        tableName: "facilityExternalFacilityIds",
        i18nKey: "externalFacilityId",
        routeEnumKey: facilityId ? "facilityExternalFacilityId" : "externalFacilityId",
        parentIdParamValue: facilityId,
    });
}
