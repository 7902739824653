import useIsAuthorised, { AccessRightKey, StateSpecificAccessRightKey } from "../auth/useIsAuthorised";
import { useCallback } from "react";
import useConfirmDeletionDialog from "../components/dialog/useConfirmDeletionDialog";
import { QueryParameters } from "../components/grid/component/useQueryBuilder";
import useAuth from "../auth/useAuth";
import useReactiveDeduplicationDialog, { reactiveDeduplicationItemType } from "../hooks/details/useReactiveDeduplicationDialog";
import { AccessRights } from "../auth/roleTypes";
import { GridRowContextMenuAction } from "../components/grid/component/cell/GridRowContextMenu";
import useGtbTranslation from "../i18n/useGtbTranslation";

export function useShowDetailsAction<T>(
    navigationTargetProducer: (item: T) => string,
    parameter: QueryParameters<T>,
    accessRight?: AccessRightKey,
): GridRowContextMenuAction<T> | undefined {
    const translation = useGtbTranslation();
    const { hasAccessRight } = useIsAuthorised();

    if (accessRight && !hasAccessRight(accessRight)) {
        return undefined;
    } else {
        return {
            label: translation("components.grid.showDetails_entryContextMenu"),
            navigationTargetProducer,
            state: {
                recordNavigationQueryParams: parameter,
            },
        };
    }
}

export function useDeleteAction<T, R extends AccessRightKey | StateSpecificAccessRightKey>(
    deleteUrlProducer: (item: T) => string,
    afterDeleteAction: () => void,
    accessRight?: R,
    state?: R extends StateSpecificAccessRightKey ? AccessRights[R]["grantedStates"] : never,
): GridRowContextMenuAction<T> | undefined {
    const translation = useGtbTranslation();
    const { hasAccessRight } = useIsAuthorised();
    const showDeleteDialog = useConfirmDeletionDialog();
    const deleteAction = useCallback(
        (item: T) => showDeleteDialog(deleteUrlProducer(item), afterDeleteAction),
        [afterDeleteAction, deleteUrlProducer, showDeleteDialog],
    );

    if (accessRight && !hasAccessRight(accessRight, state)) {
        return undefined;
    } else {
        return {
            label: translation("components.grid.delete_entryContextMenu"),
            onClick: deleteAction,
            variant: "warning",
        };
    }
}

export function useDeduplicateAction<T>(
    initialValueProducer: (item: T) => string,
    url: string,
    itemType: reactiveDeduplicationItemType,
    afterDeduplicationAction: () => void,
    roleLevel: number,
): GridRowContextMenuAction<T> | undefined {
    const translation = useGtbTranslation();
    const {
        currentUser: { authorisationRole },
    } = useAuth();

    const showDeduplicateDialog = useReactiveDeduplicationDialog();

    const deduplicateAction = useCallback(
        (item: T) => showDeduplicateDialog(initialValueProducer(item), url, itemType, afterDeduplicationAction),
        [afterDeduplicationAction, initialValueProducer, itemType, showDeduplicateDialog, url],
    );

    if (authorisationRole.level !== roleLevel) {
        return undefined;
    }
    return {
        label: translation("components.grid.deduplicate_entryContextMenu"),
        onClick: deduplicateAction,
    };
}

export function useDetachAction<T, R extends AccessRightKey | StateSpecificAccessRightKey>(
    detachUrlProducer: (item: T) => string,
    afterDetachAction: () => void,
    accessRight: R,
    state?: R extends StateSpecificAccessRightKey ? AccessRights[R]["grantedStates"] : never,
): GridRowContextMenuAction<T> | undefined {
    const translation = useGtbTranslation();
    const { hasAccessRight } = useIsAuthorised();
    const showDetachDialog = useConfirmDeletionDialog();
    const detachAction = useCallback(
        (item: T) =>
            showDetachDialog(
                detachUrlProducer(item),
                afterDetachAction,
                "components.dialog.detachRecord_dialogTitle",
                "components.dialog.detachRecord_dialogText",
                "components.dialog.detachedSuccessfully_toast",
            ),
        [afterDetachAction, detachUrlProducer, showDetachDialog],
    );

    if (!hasAccessRight(accessRight, state)) {
        return undefined;
    } else {
        return {
            label: translation("components.grid.detach_entryContextMenu"),
            onClick: detachAction,
            variant: "warning",
        };
    }
}
