import { yupResolver } from "@hookform/resolvers/yup";
import { AllowedMaterialDetails } from "../allowedMaterialTypes";
import { allowedMaterialValidationSchema } from "./allowedMaterialValidationSchema";
import { defaultStandardAllowedMaterialsSortOption } from "../useStandardAllowedMaterialSubList";
import { AllowedMaterialReadOnlyProps } from "./AllowedMaterialDetailView";
import useResolvedRoute from "../../components/routing/useResolvedRoute";
import useDomainEntityDetailsEdit from "../../hooks/details/useDomainEntityDetailsEdit";

export default function useAllowedMaterialEdit() {
    const { getResolvedId } = useResolvedRoute();
    const allowedMaterialId = getResolvedId("allowedMaterial");

    const { detailViewProps, form, initialData, isReadOnly, title, recordNavigationProps } =
        useDomainEntityDetailsEdit<AllowedMaterialDetails>({
            baseUrl: "/certificate-service/allowed-materials/" + allowedMaterialId,
            resolver: yupResolver(allowedMaterialValidationSchema),
            frontendRoute: "allowedMaterial",
            recordNavigationDefaultSort: defaultStandardAllowedMaterialsSortOption,
            titleBuilder: (item) => `${item.standardOfficialName} & ${item.rawMaterialName}`,
            canSave: false,
            historyColumns: [
                { identifier: "standard", title: "allowedMaterial.history.standard_column" },
                { identifier: "rawMaterial", title: "allowedMaterial.history.rawMaterial_column" },
            ],
        });

    const readOnlyProps: AllowedMaterialReadOnlyProps = {
        isRawMaterialReadOnly: isReadOnly,
        isStandardReadOnly: isReadOnly,
    };

    return {
        detailViewProps,
        form,
        title,
        initialData,
        readOnlyProps,
        recordNavigationProps,
    };
}
