import React, { ReactNode, useEffect } from "react";
import "./backdrop.css";

export type focusedElementType = "last" | "first";

let windowFocusListener: (e: FocusEvent) => any;
let tabKeyListener: (e: KeyboardEvent) => any;

function focusTrap(focusedElement: focusedElementType, setFocus: boolean) {
    const { firstFocusableElement, lastFocusableElement } = getElementsToFocus();

    if (lastFocusableElement) {
        windowFocusListener = (e) => focusElementOnWindowFocus(e, lastFocusableElement);
        tabKeyListener = (e) => cycleFocusOnEdgeFocusableElement(e, firstFocusableElement, lastFocusableElement);
    } else {
        // prevent tabbing
        windowFocusListener = blurOnFocus;
        tabKeyListener = blurOnTab;
        blur();
    }

    window.addEventListener("focus", windowFocusListener);
    document.addEventListener("keydown", tabKeyListener);

    const targetNode = getBackdrop()!;

    // Options for the observer (which mutations to observe)
    const config = { childList: true, subtree: true };

    // Callback function to execute when mutations are observed => reset focusTrap
    const callback: MutationCallback = (mutationList, _observer) => {
        _observer.disconnect();
        window.removeEventListener("focus", windowFocusListener);
        document.removeEventListener("keydown", tabKeyListener);
        // Retrap, if backdrop is still visible
        if (getBackdrop()) {
            focusTrap(
                focusedElement,
                // Reset focus, if the currently focused element is outside the backdrop scope
                !(getFocusableElements() as HTMLElement[]).includes(document.activeElement as HTMLElement)
            );
        }
    };
    const observer = new MutationObserver(callback);
    observer.observe(targetNode, config);
    if (setFocus) {
        if (focusedElement === "last") {
            lastFocusableElement?.focus();
        } else {
            firstFocusableElement?.focus();
        }
    }

    return () => {
        observer.disconnect();
        window.removeEventListener("focus", windowFocusListener);
        document.removeEventListener("keydown", tabKeyListener);
    };
}

/**
 * Backdrop for Elements that should trap focus
 * @param children the Elements
 * @param focusedElement which element should be focused per default
 */
export default function Backdrop({
    children,
    focusedElement,
}: {
    children: ReactNode;
    focusedElement: focusedElementType;
}) {
    useEffect(() => {
        return focusTrap(focusedElement, true);
    }, [focusedElement]);

    return (
        <div
            onMouseDown={(e) => {
                if ((e.target as HTMLElement).classList.contains("backdrop")) {
                    e.preventDefault();
                    e.stopPropagation();
                }
            }}
            className="backdrop"
        >
            {children}
        </div>
    );
}

const focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

const getBackdrop = () => document.querySelector(".backdrop");

const getElementsToFocus = () => {
    const focusableContent = getFocusableElements();

    const firstFocusableElement = focusableContent[0];
    const lastFocusableElement = focusableContent[focusableContent.length - 1];

    return { firstFocusableElement, lastFocusableElement };
};

const getFocusableElements = (): HTMLElement[] | undefined[] => {
    const modal = getBackdrop();
    return modal ? (Array.from(modal.querySelectorAll(focusableElements).values()) as HTMLElement[]) : [undefined];
};

const focusElementOnWindowFocus = (e: FocusEvent, focusableElement?: HTMLElement) => {
    focusableElement?.focus();
};

const cycleFocusOnEdgeFocusableElement = (
    e: KeyboardEvent,
    firstFocusableElement?: HTMLElement,
    lastFocusableElement?: HTMLElement
) => {
    if (firstFocusableElement && lastFocusableElement && e.key === "Tab") {
        if (e.shiftKey) {
            if (document.activeElement === firstFocusableElement) {
                lastFocusableElement.focus();
                e.preventDefault();
            }
        } else {
            if (document.activeElement === lastFocusableElement) {
                firstFocusableElement.focus();
                e.preventDefault();
            }
        }
    }
};

const blur = () => {
    (document.activeElement as HTMLElement)?.blur();
};

const blurOnTab = (e: KeyboardEvent) => {
    if (e.key === "Tab") {
        blur();
        e.preventDefault();
    }
};

const blurOnFocus = (e: FocusEvent) => {
    blur();
    e.preventDefault();
};
