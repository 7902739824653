import {
    buildSchema,
    validateDate,
    validateNumber,
    validateString,
    validateTranslation,
} from "../../utils/yupExtension";
import { ProductDetailDetails } from "../productDetailTypes";

export const productDetailValidationSchema = buildSchema<ProductDetailDetails>({
    code: validateString().isRequired().hasMinLength(6).hasMaxLength(6),
    pickListSequence: validateNumber().isRequired(),
    name: validateTranslation(40),
    startDate: validateDate(),
    endDate: validateDate(),
});
