import InputSourceSelectionList, {
    InputSourceSelectionListProps,
} from "../../../../../../../components/grid/InputSourceSelectionList";
import { TcProductReference } from "../../../../tcProductTypes";
import { tcProductInputSourceColumns } from "./TcProductInputSourceSelectionList";
import { GridColumn } from "../../../../../../../components/grid/component/Grid";
import { buildNumberColumn } from "../../../../../../../components/grid/ColumnProvider";

export default function TcByProductInputSourceSelectionList({
    ...rest
}: Pick<InputSourceSelectionListProps<TcProductReference>, "className" | "onItemClick" | "selectedItemId">) {
    return (
        <InputSourceSelectionList<TcProductReference>
            tableName="tcByProductInputSources"
            url="/certificate-service/references/input-sources/tc-by-products"
            columns={tcByProductInputSourceColumns}
            sortField="tcProductNumber"
            {...rest}
        />
    );
}

const tcByProductInputSourceColumns: GridColumn<TcProductReference>[] = [
    ...tcProductInputSourceColumns.filter((col) => col.key !== "availableQuantity"),
    buildNumberColumn({
        key: "availableByProductQuantity",
        title: "tcProduct.list.availableByProductQuantity_column",
        fixedDecimal: true,
        minWidth: 316,
    }),
];
