import { ComponentPropsWithoutRef } from "react";
import "./card.css";
import { joinClassNames } from "../../utils/StringUtils";

interface CardProps extends ComponentPropsWithoutRef<"section"> {}

function Card({ children, className, ...props }: CardProps) {
    return (
        <section className={joinClassNames("card", className)} {...props}>
            {children}
        </section>
    );
}

export default Card;
