import useStateProvinceEdit from "./useStateProvinceEdit";
import StateProvinceDetailView from "./StateProvinceDetailView";

function StateProvinceEditView() {
    const stateProvinceDetailsProperties = useStateProvinceEdit();

    return <StateProvinceDetailView {...stateProvinceDetailsProperties} />;
}

export default StateProvinceEditView;
