import ViewContainer from "../components/ViewContainer";
import useGtbTranslation, { I18nKey } from "../i18n/useGtbTranslation";
import { useChangeNavigationContext } from "../components/breadcrumb/useNavigationContext";
import { useEffect, useMemo } from "react";
import GoBackButton from "../components/GoBackButton";

export default function ErrorPage({ errorCode, errorMessage }: { errorCode: string; errorMessage: I18nKey }) {
    const translation = useGtbTranslation();
    const { pushNavigationContext } = useChangeNavigationContext();

    const title = useMemo(
        () => ({ key: "error.errorPage.errorCode_title", options: { errorCode: errorCode } } as I18nKey),
        [errorCode]
    );

    useEffect(() => {
        pushNavigationContext(title);
    }, [errorCode, pushNavigationContext, title]);

    return (
        <ViewContainer>
            <div style={{ display: "flex", fontFamily: "Arial, sans-serif", color: "var(--primary-text-color)" }}>
                <div>
                    <h1>{translation(title)}</h1>
                    <p>{translation(errorMessage)}</p>
                    <GoBackButton style={{ color: "var(--list-column-header-color)" }} />
                </div>
            </div>
        </ViewContainer>
    );
}
