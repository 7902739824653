import { useHelpContext } from "../help/context-sensitivity/useHelpContext";
import useDynamicUserGroupListView from "./useDynamicUserGroupListView";
import EntityListView from "../components/entity/EntityListView";

export default function DynamicUserGroupListView() {
    const dynamicUserGroupGridOptions = useDynamicUserGroupListView();
    useHelpContext("dynamic-user-group-list");
    return (
        <EntityListView
            heading="dynamicUserGroup.list.header"
            gridOptions={dynamicUserGroupGridOptions}
        />
    );
}
