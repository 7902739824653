import { useMemo } from "react";
import useIsAuthorised from "../auth/useIsAuthorised";
import { useDeleteAction, useShowDetailsAction } from "../utils/defaultActions";
import { useQueryBuilder } from "../components/grid/component/useQueryBuilder";
import useResolvedRoute from "../components/routing/useResolvedRoute";
import { SubListProps } from "../components/grid/SubList";
import { ScopeCertificateSummary } from "./scopeCertificateTypes";
import { defaultScopeCertificateSortOption } from "./useScopeCertificateListView";
import { GridColumn } from "../components/grid/component/Grid";
import {
    buildBooleanColumn,
    buildDataColumn,
    buildDateColumn,
    buildTextColumn,
} from "../components/grid/ColumnProvider";
import { EnumEntityType } from "../components/Input/pickList/typedEnumPicklist/enumEntityType";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";

const columns: GridColumn<ScopeCertificateSummary>[] = [
    buildTextColumn({ key: "scRevisionNumber", title: "scopeCertificate.list.scNumber_column", minWidth: 142 }),
    buildTextColumn({
        key: "standardAcronymVersionNumber",
        title: "scopeCertificate.list.standardVersion_column",
        minWidth: 187,
    }),
    buildDataColumn<ScopeCertificateSummary, EnumEntityType>({
        key: "status",
        title: "scopeCertificate.list.status_column",
        url: "/certificate-service/references/scope-certificate-statuses",
        itemId: "id",
        itemLabel: "name",
        minWidth: 232,
    }),
    buildDateColumn({ key: "validFrom", title: "scopeCertificate.list.validFrom_column", minWidth: 134 }),
    buildDateColumn({ key: "validUntil", title: "scopeCertificate.list.validUntil_column", minWidth: 132 }),
    buildTextColumn({
        key: "additionalDeclaration",
        title: "scopeCertificate.list.additionalDeclaration_column",
        minWidth: 225,
        visible: false,
    }),
    buildDateColumn({
        key: "dateOfIssue",
        title: "scopeCertificate.list.dateOfIssue_column",
        minWidth: 156,
        visible: false,
    }),
    buildDataColumn<ScopeCertificateSummary, EnumEntityType>({
        key: "certificateOrigin",
        title: "scopeCertificate.list.originOfCertificate_column",
        minWidth: 203,
        url: "/certificate-service/references/origination-of-certificates",
        itemId: "id",
        itemLabel: "name",
        visible: false,
    }),
    buildTextColumn({
        key: "placeOfIssue",
        title: "scopeCertificate.list.placeOfIssue_column",
        minWidth: 163,
        visible: false,
    }),
    buildBooleanColumn({
        key: "recertificationExpected",
        title: "scopeCertificate.list.recertificationExpected_column",
        minWidth: 241,
        visible: false,
    }),
    buildDateColumn({
        key: "recertificationExpiryDate",
        title: "scopeCertificate.list.recertificationExpiryDate_column",
        minWidth: 257,
        visible: false,
    }),
    buildTextColumn({
        key: "cbScReferenceNumber",
        title: "scopeCertificate.list.cbScReferenceNumber_column",
        minWidth: 189,
        visible: false,
    }),
    buildTextColumn({
        key: "certificationBodyBusinessName",
        title: "scopeCertificate.list.certificationBody_column",
        minWidth: 192,
        visible: false,
    }),
];

export default function useScopeCertificateSubList() {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedId, getResolvedEditUrl, getResolvedCreateUrl } = useResolvedRoute();

    const { query, columnOptions, forceReload } = useQueryBuilder<ScopeCertificateSummary>(
        `/certificate-service/scope-certificates`,
        {
            sort: defaultScopeCertificateSortOption,
            filterActive: false,
            supplyChainOperatorId: getResolvedId("supplyChainOperator"),
        }
    );

    const showDetailsAction = useShowDetailsAction(
        (item: ScopeCertificateSummary) => getResolvedEditUrl("scopeCertificate", item.id),
        query.parameter
    );

    const deleteAction = useDeleteAction(
        (item: ScopeCertificateSummary) => `/certificate-service/scope-certificates/${item.id}`,
        () => forceReload(),
        "deleteScopeCertificate"
    );

    const contextMenuActions = useGridRowContextMenuActions(showDetailsAction, deleteAction);

    return useMemo<SubListProps<ScopeCertificateSummary>>(
        () => ({
            query,
            columns: columns,
            uniqueDataKey: "id",
            onItemDoubleClick: showDetailsAction,
            header: "scopeCertificate.list.default_header",
            itemActions: contextMenuActions,
            columnOptions,
            createUrl: hasAccessRight("createScopeCertificate")
                ? getResolvedCreateUrl("supplyChainOperatorScopeCertificate")
                : undefined,
            tableName: "supplyChainOperatorScopeCertificate",
        }),
        [columnOptions, contextMenuActions, getResolvedCreateUrl, hasAccessRight, query, showDetailsAction]
    );
}
