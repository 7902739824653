import { yupResolver } from "@hookform/resolvers/yup";
import useIsAuthorised from "../../auth/useIsAuthorised";
import { useCallback, useMemo } from "react";
import useResolvedRoute from "../../components/routing/useResolvedRoute";
import useDomainEntityDetailsEdit from "../../hooks/details/useDomainEntityDetailsEdit";
import useAuth from "../../auth/useAuth";
import {
    revisionButtonVisibilityType,
    ScopeCertificateDetails,
    scopeCertificateReadOnlyByStatus,
} from "../scopeCertificateTypes";
import { defaultScopeCertificateSortOption } from "../useScopeCertificateListView";
import { scopeCertificateValidationSchema } from "./scopeCertificateValidationSchema";
import { ScopeCertificateDetailViewProps } from "./ScopeCertificateDetailView";
import { humanReadableFileSize } from "../../utils/formatter";
import useNoActiveCertRelHandler from "./useNoActiveCertRelHandler";
import { scopeCertificateStatusType } from "../../auth/roleTypes";
import useRecertifyScopeCertificate from "./useRecertifyScopeCertificate";
import useConformanceCheck from "../../conformance/useConformanceCheck";
import useReviseScopeCertificate from "../useReviseScopeCertificate";
import useErrorDialog from "../../components/dialog/useErrorDialog";

export default function useScopeCertificateEdit(): ScopeCertificateDetailViewProps {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedId } = useResolvedRoute();
    const reviseHandler = useReviseScopeCertificate();
    const scopeCertificateId = getResolvedId("scopeCertificate");
    const {
        currentUser: {
            authorisationRole: { level },
        },
    } = useAuth();

    const handleNoActiveCertRelForSCOAndStandardVersionError = useNoActiveCertRelHandler();
    const handleConformanceCheck = useConformanceCheck<ScopeCertificateDetails>({
        showWarningsOnCondition: (item) => item.status === "VALID" || item.status === "PENDING",
    });
    const { showDialog: showErrorDialog } = useErrorDialog();

    const canSave = useCallback(
        (item: ScopeCertificateDetails) => {
            if (item) {
                return hasAccessRight("updateScopeCertificate", [item.status]);
            }
            return false;
        },
        [hasAccessRight]
    );

    const { detailViewProps, form, initialData, isReadOnly, title, recordNavigationProps } =
        useDomainEntityDetailsEdit<ScopeCertificateDetails>({
            baseUrl: "/certificate-service/scope-certificates/" + scopeCertificateId,
            frontendRoute: "scopeCertificate",
            recordNavigationDefaultSort: defaultScopeCertificateSortOption,
            titleBuilder: (item) => `${item.scNumber}-${item.revisionNumber}`,
            resolver: yupResolver(scopeCertificateValidationSchema),
            canSave,
            resolveErrors: (error, item, retrySaveAction) => {
                if (error.statusCode === 409 && error.data && error.data === "signing_routine_internal_problem") {
                    showErrorDialog({
                        title: "documentCertification.signingRoutineInternalProblemDialogTitle",
                        message: "documentCertification.signingRoutineInternalProblem",
                    });
                    return true;
                }

                const noActiveCert = handleNoActiveCertRelForSCOAndStandardVersionError(error, form);
                if (noActiveCert) {
                    return noActiveCert;
                }

                return handleConformanceCheck(error, item, retrySaveAction);
            },
            historyColumns: [
                { identifier: "supplyChainOperator", title: "scopeCertificate.history.supplyChainOperator_column" },
                { identifier: "scNumber", title: "scopeCertificate.history.scNumber_column" },
                { identifier: "revisionNumber", title: "scopeCertificate.history.revisionNumber_column" },
                { identifier: "standardVersion", title: "scopeCertificate.history.standardVersion_column" },
                { identifier: "certificationBody", title: "scopeCertificate.history.certificationBody_column" },
                { identifier: "cbScReferenceNumber", title: "scopeCertificate.history.cbScReferenceNumber_column" },
                { identifier: "validFrom", title: "scopeCertificate.history.validFrom_column" },
                { identifier: "validUntil", title: "scopeCertificate.history.validUntil_column" },
                { identifier: "status", title: "scopeCertificate.history.status_column" },
                { identifier: "dateOfIssue", title: "scopeCertificate.history.dateOfIssue_column" },
                { identifier: "placeOfIssue", title: "scopeCertificate.history.placeOfIssue_column" },
                { identifier: "approvingPerson", title: "scopeCertificate.history.approvingPerson_column" },
                {
                    identifier: "recertificationExpected",
                    title: "scopeCertificate.history.recertificationExpected_column",
                },
                {
                    identifier: "recertificationExpiryDate",
                    title: "scopeCertificate.history.recertificationExpiryDate_column",
                },
                { identifier: "additionalDeclaration", title: "scopeCertificate.history.additionalDeclaration_column" },
                { identifier: "certificateOrigin", title: "scopeCertificate.history.originOfCertificate_column" },
                { identifier: "certificateDocument", title: "scopeCertificate.history.certificateDocument_column" },
                { identifier: "cbCertificate", title: "scopeCertificate.history.cbCertificate_column" },
                { identifier: "documentHash", title: "scopeCertificate.history.documentHash_column" },
                { identifier: "hashAlgorithm", title: "scopeCertificate.history.hashAlgorithm_column" },
                { identifier: "documentSize", title: "scopeCertificate.history.documentSize_column" },
            ],
        });

    const scopeCertificateStatus = useMemo<scopeCertificateStatusType | undefined>(
        () => initialData?.status,
        [initialData?.status]
    );

    const isReadOnlyByScopeCertificateStatus = useMemo<boolean>(
        () => scopeCertificateReadOnlyByStatus(scopeCertificateStatus),
        [scopeCertificateStatus]
    );

    const readOnly = useMemo<ScopeCertificateDetailViewProps["readOnly"]>(
        () => ({
            supplyChainOperator: true,
            certificationBody: true,
            standardVersion: isReadOnly || isReadOnlyByScopeCertificateStatus || initialData?.revisionNumber !== 0,
            cbScReferenceNumber: isReadOnly || isReadOnlyByScopeCertificateStatus,
            approvingPerson: isReadOnly || isReadOnlyByScopeCertificateStatus,
            recertificationExpected: isReadOnly || isReadOnlyByScopeCertificateStatus,
            status: isReadOnly,
            recertificationExpiryDate: isReadOnly || level > 2 || isReadOnlyByScopeCertificateStatus,
            additionalDeclaration: isReadOnly || isReadOnlyByScopeCertificateStatus,
        }),
        [initialData?.revisionNumber, isReadOnly, isReadOnlyByScopeCertificateStatus, level]
    );

    const recertification = useRecertifyScopeCertificate();

    const handleRecertification = useCallback(() => {
        if (detailViewProps.onSubmit && scopeCertificateId) {
            recertification(form, detailViewProps.reloadData, detailViewProps.onSubmit, scopeCertificateId);
        }
    }, [detailViewProps.onSubmit, detailViewProps.reloadData, form, recertification, scopeCertificateId]);

    const revisionButtonVisibility = useMemo<revisionButtonVisibilityType>(() => {
        const isVisible = initialData?.status === "VALID" || initialData?.status === "PENDING";
        const isNewestRevision = initialData?.revisionId === null;

        if (isVisible && isNewestRevision) {
            return "enabled";
        } else if (isVisible) {
            return "disabled";
        } else {
            return "hidden";
        }
    }, [initialData?.revisionId, initialData?.status]);

    const revisionButtonOnClickHandler = useCallback(() => {
        if (revisionButtonVisibility === "enabled") {
            reviseHandler(initialData);
        }
    }, [initialData, reviseHandler, revisionButtonVisibility]);

    return {
        detailViewProps,
        form,
        readOnly,
        title,
        recordNavigationProps,
        scopeCertificateId,
        createRoute: hasAccessRight("createScopeCertificate") ? "scopeCertificate" : undefined,
        isRecertificationExpiryVisible: initialData?.status === "IN_RECERTIFICATION",
        documentTabVisible: !!initialData?.documentHash,
        humanReadableDocumentSize: initialData?.documentSize ? humanReadableFileSize(initialData?.documentSize) : null,
        subListVisible: true,
        scopeCertificateStatus,
        handleRecertification: !isReadOnly && initialData?.recertifyable ? handleRecertification : undefined,
        revisionButtonVisibility,
        revisionButtonOnClickHandler,
    };
}
