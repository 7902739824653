import { AuthorisationRoleDetails } from "../authorisationRoleTypes";
import { AuthorisationRoleDetailViewProps } from "./AuthorisationRoleDetailView";
import useDetailsCreate from "../../hooks/details/useDetailsCreate";
import { yupResolver } from "@hookform/resolvers/yup";
import { authorisationRoleValidationSchema } from "./authorisationRoleValidationSchema";
import { Nullable } from "../../utils/typeUtils";

const initialRole = { granted: false };
const initialStateSpecificRole = { granted: false, grantedStates: [] };

const initialData: Nullable<AuthorisationRoleDetails> = {
    id: null,
    name: null,
    type: "STANDARD_BODY",
    level: null,
    canReduceOtpPrompting: false,
    createAuthorisationRole: initialRole,
    createCertificationLevelOrganisation: initialStateSpecificRole,
    createExternalId: initialStateSpecificRole,
    createMasterData: initialRole,
    createRawMaterialProduction: initialRole,
    createProductConversion: initialRole,
    createScopeCertificate: initialStateSpecificRole,
    createStandardBody: initialStateSpecificRole,
    createOperationLevelOrganisation: initialStateSpecificRole,
    createTransactionCertificate: initialStateSpecificRole,
    createUserAccount: initialRole,
    createPreGtbTc: initialStateSpecificRole,
    deleteAuthorisationRole: initialRole,
    deleteCertificationLevelOrganisation: initialStateSpecificRole,
    deleteExternalId: initialStateSpecificRole,
    deleteMasterData: initialRole,
    deleteRawMaterialProduction: initialRole,
    deleteProductConversion: initialRole,
    deleteScopeCertificate: initialStateSpecificRole,
    deleteStandardBody: initialStateSpecificRole,
    deleteOperationLevelOrganisation: initialStateSpecificRole,
    deleteTransactionCertificate: initialStateSpecificRole,
    deleteUserAccount: initialRole,
    deletePreGtbTc: initialStateSpecificRole,
    readAuthorisationRole: initialRole,
    readCertificationLevelOrganisation: initialStateSpecificRole,
    readExternalId: initialStateSpecificRole,
    readMasterData: initialRole,
    readRawMaterialProduction: initialRole,
    readProductConversion: initialRole,
    readScopeCertificate: initialStateSpecificRole,
    readStandardBody: initialStateSpecificRole,
    readOperationLevelOrganisation: initialStateSpecificRole,
    readTransactionCertificate: initialStateSpecificRole,
    readUserAccount: initialRole,
    readPreGtbTc: initialStateSpecificRole,
    updateAuthorisationRole: initialRole,
    updateCertificationLevelOrganisation: initialStateSpecificRole,
    updateExternalId: initialStateSpecificRole,
    updateMasterData: initialRole,
    updateRawMaterialProduction: initialRole,
    dataListsInMenuSystem: initialRole,
    updateProductConversion: initialRole,
    updateScopeCertificate: initialStateSpecificRole,
    updateStandardBody: initialStateSpecificRole,
    updateOperationLevelOrganisation: initialStateSpecificRole,
    updateTransactionCertificate: initialStateSpecificRole,
    updateUserAccount: initialRole,
    updatePreGtbTc: initialStateSpecificRole,
    manageEventDetection: initialRole,
    manageSystemParameter: initialRole,
    useApplicationLog: initialRole,
    useUploadLog: initialRole,
    useBulkReport: initialStateSpecificRole,
    viewTiles: initialStateSpecificRole,
    relationTypesInMenuSystem: initialRole,
    useMailTemplate: initialRole,
    createTimeBasedMail: initialRole,
    deleteTimeBasedMail: initialRole,
    readTimeBasedMail: initialRole,
    updateTimeBasedMail: initialRole,
    grantPriorConsent: initialRole,
};

function useAuthorisationRoleCreate(): AuthorisationRoleDetailViewProps {
    const { detailViewProps, form, viewType, title } = useDetailsCreate<AuthorisationRoleDetails>({
        frontendRoute: "authorisationRole",
        initialData,
        title: "authorisationRole.detail.newRecord_title",
        resolver: yupResolver(authorisationRoleValidationSchema),
        saveUrl: "/certificate-service/roles",
    });

    return {
        detailViewProps,
        form,
        title,
        isReadOnly: false,
        viewType,
        createRoute: "authorisationRole",
        enableOrganisationTypeSelection: true,
    };
}

export default useAuthorisationRoleCreate;
