import { ComponentPropsWithoutRef } from "react";
import "./button.css";
import { joinClassNames } from "../utils/StringUtils";

export interface GtbButtonProps extends ComponentPropsWithoutRef<"button"> {
    variant?: "primary" | "secondary" | "tertiary";
    size?: "large" | "medium" | "small";
    "aria-describedby"?: string;
}

function GtbButton({
    type = "button",
    variant = "primary",
    size = "large",
    className,
    children,
    "aria-describedby": ariaDescribedBy,
    ...props
}: GtbButtonProps) {
    return (
        <button
            type={type}
            className={joinClassNames(variant + "-button", size + "-button", className)}
            aria-describedby={ariaDescribedBy}
            {...props}
        >
            {children}
        </button>
    );
}

export default GtbButton;
