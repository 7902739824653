import { UseGtbFormReturn } from "../../../../hooks/formHandling/useGtbForm";
import { joinClassNames } from "../../../../utils/StringUtils";
import GtbTextField from "../../../../components/Input/GtbTextField";
import { ScProductDetails } from "../scProductTypes";
import ProductCategoryPickList from "../../../../components/Input/pickList/typedPicklist/ProductCategoryPickList";
import ControlledTextField from "../../../../components/Input/ControlledTextField";
import ProductDetailPickList from "../../../../components/Input/pickList/typedPicklist/ProductDetailPickList";
import LabelGradePickList from "../../../../components/Input/pickList/typedPicklist/LabelGradePickList";
import "./scProductBasicData.css";
import useSetFormValue from "../../../../hooks/formHandling/useSetFormValue";

export interface ScopeCertificateBasicDataProps {
    form: UseGtbFormReturn<ScProductDetails>;
    scopeCertificateId: string;
    labelGradesVisible: boolean;
    readOnly: boolean;
}

export default function ScProductBasicData({
    form,
    scopeCertificateId,
    labelGradesVisible,
    readOnly,
}: ScopeCertificateBasicDataProps) {
    const setFormValue = useSetFormValue(form.form);

    return (
        <div className={joinClassNames("scopeCertificateProductBasicData", "formGrid")}>
            <GtbTextField
                label="scProduct.detail.supplyChainOperator_input"
                {...form.registerWithErrors("supplyChainOperatorBusinessName")}
                readOnly
                className="supplyChainOperator"
            />
            <GtbTextField
                label="scProduct.detail.scProductNumber_input"
                {...form.registerWithErrors("scProductNumber")}
                readOnly
                className="scProductNumber"
            />
            <GtbTextField
                label="scProduct.detail.cbScProductReferenceNumber_input"
                {...form.registerWithErrors("cbScProductReferenceNumber")}
                readOnly={readOnly}
                className="cbScProductReferenceNumber"
            />
            <ProductCategoryPickList
                label="scProduct.detail.productCategory_input"
                control={form.form.control}
                name="productCategory"
                readOnly={readOnly}
                className="productCategory"
                onChangeListener={(_event, _value, selectedOption) =>
                    setFormValue("productCategoryPrint", selectedOption?.name)
                }
            />
            <ControlledTextField
                label="scProduct.detail.productCategoryPrint_input"
                control={form.form.control}
                name="productCategoryPrint"
                readOnly={readOnly}
                className="productCategoryPrint"
            />
            <ProductDetailPickList
                label="scProduct.detail.productDetail_input"
                control={form.form.control}
                name="productDetail"
                readOnly={readOnly}
                className="productDetail"
                onChangeListener={(_event, _value, selectedOption) =>
                    setFormValue("productDetailPrint", selectedOption?.name)
                }
            />
            <ControlledTextField
                label="scProduct.detail.productDetailPrint_input"
                control={form.form.control}
                name="productDetailPrint"
                readOnly={readOnly}
                className="productDetailPrint"
            />
            {labelGradesVisible && (
                <LabelGradePickList
                    label="scProduct.detail.labelGrade_input"
                    control={form.form.control}
                    name="labelGrade"
                    className="labelGrade"
                    flags={{ scopeCertificateId: scopeCertificateId }}
                    readOnly={readOnly}
                />
            )}
        </div>
    );
}
