import { env } from "../env";
import "./sessionTimeoutDialog.css";
import React, { useCallback, useEffect, useState } from "react";
import { useGtbNavigate } from "../components/routing/GtbRouter";
import useGtbTranslation from "../i18n/useGtbTranslation";
import { error, info } from "../utils/notification/notification";
import useDialog from "../components/dialog/useDialog";
import DialogHeader from "../components/dialog/DialogHeader";
import DialogFooter from "../components/dialog/DialogFooter";
import GtbButton from "../components/GtbButton";
import { useInterval, useStopwatch, useUpdatableTimeout } from "../utils/useTimer";
import { buildBroadcastChannel } from "./BroadcastChannelProvider";
import { LogoutReasonType } from "../login/LogoutView";
import DialogContent from "../components/dialog/DialogContent";

const TIMEOUT_QUALIFICATION_MS = 2000;
const SESSION_TIMEOUT_LOCAL_STORAGE_KEY = "gtb_session_expiration_time";

const sessionTimeoutChannel = buildBroadcastChannel("session_timeout_channel");

function calcExpirationTime() {
    return Date.now() + env.REACT_APP_TIMEOUT_PERIOD_SECONDS * 1000;
}

function calcWarningTime(expirationTime: number = calcExpirationTime()) {
    return expirationTime - env.REACT_APP_TIMEOUT_WARNING_PERIOD_SECONDS * 1000;
}

export default function useSessionTimeout() {
    const [isInformed, setIsInformed] = useState(false);
    const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false);
    const [warningTime, setWarningTime] = useState(calcWarningTime(calcExpirationTime()));
    const [expirationTime, setExpirationTime] = useState(calcExpirationTime());

    const registerUserInputStopwatch = useStopwatch(TIMEOUT_QUALIFICATION_MS);
    const navigate = useGtbNavigate(true);
    const translation = useGtbTranslation();
    const { showDialog: showWarningDialog, closeDialog: closeWarningDialog } = useDialog();

    const forceTimerUpdate = useCallback((includeLocalStorageUpdate: boolean) => {
        const newExpirationTime = calcExpirationTime();
        setWarningTime(calcWarningTime(newExpirationTime));
        setExpirationTime(newExpirationTime);

        if (includeLocalStorageUpdate) {
            localStorage.setItem(SESSION_TIMEOUT_LOCAL_STORAGE_KEY, newExpirationTime.toString());
        }
    }, []);

    const updateExpirationTimeViaUserInput = useCallback(() => {
        if (!registerUserInputStopwatch.isRunning && !isWarningDialogOpen) {
            registerUserInputStopwatch.start();
            forceTimerUpdate(true);
        }
    }, [forceTimerUpdate, isWarningDialogOpen, registerUserInputStopwatch]);

    const updateExpirationTimeViaStorageEvent = useCallback(
        (e: StorageEvent) => {
            if (e.key === SESSION_TIMEOUT_LOCAL_STORAGE_KEY) {
                forceTimerUpdate(false);
                closeWarningDialog();
                setIsWarningDialogOpen(false);
            }
        },
        [closeWarningDialog, forceTimerUpdate]
    );

    const informSessionTimeout = useCallback(() => {
        if (!isInformed) {
            setIsInformed(true);
            error(
                translation({
                    key: "sessionTimeout.timeoutOccurredAlertMessage",
                    options: { inactivityMinutes: Math.floor(env.REACT_APP_TIMEOUT_PERIOD_SECONDS / 60) },
                })
            );
            info("Logged out successfully");
        }

        closeWarningDialog();
    }, [closeWarningDialog, isInformed, translation]);

    useEffect(() => {
        window.addEventListener("mousemove", updateExpirationTimeViaUserInput);
        window.addEventListener("keydown", updateExpirationTimeViaUserInput);
        window.addEventListener("load", updateExpirationTimeViaUserInput);
        window.addEventListener("mousedown", updateExpirationTimeViaUserInput);
        window.addEventListener("click", updateExpirationTimeViaUserInput);
        window.addEventListener("storage", updateExpirationTimeViaStorageEvent);

        // Setting the informSessionTimeout callback as a property prevents
        // double-assigning when opening new tabs
        sessionTimeoutChannel.onmessage = informSessionTimeout;

        return () => {
            window.removeEventListener("mousemove", updateExpirationTimeViaUserInput);
            window.removeEventListener("keydown", updateExpirationTimeViaUserInput);
            window.removeEventListener("load", updateExpirationTimeViaUserInput);
            window.removeEventListener("mousedown", updateExpirationTimeViaUserInput);
            window.removeEventListener("click", updateExpirationTimeViaUserInput);
            window.removeEventListener("storage", updateExpirationTimeViaStorageEvent);

            sessionTimeoutChannel.onmessage = null;
        };
    });

    useUpdatableTimeout(() => {
        setIsWarningDialogOpen(true);

        const handleWarningDialogInteraction = () => {
            forceTimerUpdate(true);
            closeWarningDialog();
            setIsWarningDialogOpen(false);
        };

        showWarningDialog({
            header: <DialogHeader variant={"warning"} title={"sessionTimeout.timeoutWarningDialogTitle"} />,
            content: <TimeoutDialogContent closeDialog={handleWarningDialogInteraction} />,
            footer: (
                <DialogFooter>
                    <GtbButton
                        aria-describedby={translation("sessionTimeout.timeoutWarningDialogButton_tooltip")}
                        onClick={handleWarningDialogInteraction}
                    >
                        {translation("sessionTimeout.timeoutWarningDialogButton")}
                    </GtbButton>
                </DialogFooter>
            ),
        });
    }, warningTime);

    useUpdatableTimeout(() => {
        if (!isInformed) {
            sessionTimeoutChannel.postMessage("session timeout occurred");

            const logoutReason: LogoutReasonType = { logoutReason: "timeOut" };

            setIsInformed(true);
            navigate({ pathname: "/logout" }, { state: logoutReason });
            closeWarningDialog();
        }
    }, expirationTime);
}

function TimeoutDialogContent({ closeDialog }: { closeDialog: () => void }) {
    const [remainingSeconds, setRemainingSeconds] = useState(env.REACT_APP_TIMEOUT_WARNING_PERIOD_SECONDS - 1);
    const translation = useGtbTranslation();

    useInterval(() => {
        const sessionExpirationTime = parseInt(
            localStorage.getItem(SESSION_TIMEOUT_LOCAL_STORAGE_KEY) || Number.MAX_SAFE_INTEGER.toString(),
            10
        );

        const calculatedRemainingSeconds = Math.floor((sessionExpirationTime - Date.now()) / 1000);
        if (calculatedRemainingSeconds < 0) {
            closeDialog();
        }

        setRemainingSeconds(calculatedRemainingSeconds);
    }, 1000);

    return (
        <DialogContent className="timeout-dialog">
            <div>{translation("sessionTimeout.timeoutWarningDialogUpperText")}</div>
            <div className="remaining-time">
                {translation({
                    key: "sessionTimeout.timeoutWarningDialogLowerText",
                    options: { remainingSeconds: remainingSeconds },
                })}
            </div>
        </DialogContent>
    );
}
