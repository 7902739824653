import { TypedPicklist } from "./typedPicklistType";
import DataPickList, { DataPickListProps } from "../DataPickList";
import { joinClassNames } from "../../../../utils/StringUtils";
import "./typedPickList.css";
import { FieldValues } from "react-hook-form/dist/types";
import { LabelGradeReference } from "../../../../labelGrade/labelGradeTypes";

interface LabelGradePickListProps<FormItemType extends FieldValues>
    extends TypedPicklist<FormItemType, LabelGradeReference> {
    flags?: LabelGradePickListFlags;
    itemLabel?: DataPickListProps<FormItemType, LabelGradeReference>["itemLabel"];
}

export default function LabelGradePickList<FormItemType extends FieldValues>({
    className,
    itemLabel = "labelGrade",
    ...rest
}: LabelGradePickListProps<FormItemType>) {
    return (
        <DataPickList
            className={joinClassNames("labelGradePickList", className)}
            itemId="id"
            itemLabel={itemLabel}
            queryUrl={`/certificate-service/references/label-grades`}
            {...rest}
        />
    );
}

export interface LabelGradePickListFlags {
    scopeCertificateId?: string;
    preGtbTcId?: string;
}
