import { joinClassNames } from "../../utils/StringUtils";
import GtbTextField from "../../components/Input/GtbTextField";
import OrganisationBasicData from "../../organisation/details/OrganisationBasicData";
import { OrganicMaterialProducerDetailViewTabProps } from "./OrganicMaterialProducerDetailView";
import "./organicMaterialProducerBasicData.css";
import { OrganicMaterialProducerDetails } from "../organicMaterialProducerTypes";
import { useMemo } from "react";

export default function OrganicMaterialProducerBasicData({
    form,
    readOnlyProps,
    requiresLegalNameInEnglish,
}: OrganicMaterialProducerDetailViewTabProps<OrganicMaterialProducerDetails>) {
    const displayLegalNameInEnglishInputClassName = useMemo(
        () => (requiresLegalNameInEnglish ? "gridIncludingLegalNameInEnglish" : "gridExcludingLegalNameInEnglish"),
        [requiresLegalNameInEnglish]
    );

    return (
        <div
            className={joinClassNames(
                "organicMaterialProducerBasicData",
                "organisationBasicData",
                "formGrid",
                displayLegalNameInEnglishInputClassName
            )}
        >
            <GtbTextField
                {...form.registerWithErrors("legalName")}
                readOnly={readOnlyProps.isLegalNameReadOnly}
                label="organicMaterialProducer.detail.legalName_input"
                className="legalName"
            />
            {requiresLegalNameInEnglish && (
                <GtbTextField
                    {...form.registerWithErrors("legalNameInEnglish")}
                    readOnly={readOnlyProps.isLegalNameInEnglishReadOnly}
                    label={"organicMaterialProducer.detail.legalNameInEnglish_input"}
                    className={"legalNameInEnglish"}
                />
            )}
            <OrganisationBasicData
                form={form}
                readOnlyProps={readOnlyProps}
                organisationI18nKey="organicMaterialProducer"
            />
        </div>
    );
}
