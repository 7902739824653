import DetailView, { TypedDetailViewProps } from "../detailView/DetailView";
import Card from "../card/Card";
import CardHeader from "../card/CardHeader";
import { ReactNode } from "react";
import { FieldValues } from "react-hook-form/dist/types";
import RecordNavigation from "../card/navigation/RecordNavigation";
import BreadcrumbContainer from "../breadcrumb/BreadcrumbContainer";

export default function EntityDetailView<MasterData extends FieldValues>({
    detailViewProps,
    title,
    subtitle,
    recordNavigationProps,
    children,
    createRoute,
}: MasterDataDetailViewProps<MasterData>) {
    return (
        <div className="view">
            <DetailView detailViewProps={detailViewProps} createRoute={createRoute}>
                <BreadcrumbContainer />
                <Card className="noPadding">
                    <CardHeader title={title} subtitle={subtitle}>
                        {recordNavigationProps && <RecordNavigation {...recordNavigationProps} />}
                    </CardHeader>
                    {children}
                </Card>
            </DetailView>
        </div>
    );
}

export interface MasterDataDetailViewProps<MasterData extends FieldValues> extends TypedDetailViewProps<MasterData> {
    children: ReactNode;
}
