import ScopeDetailView from "./ScopeDetailView";
import useScopeEdit from "./useScopeEdit";

function ScopeEditView() {
    const scopeDetailOptions = useScopeEdit();

    return <ScopeDetailView {...scopeDetailOptions} />;
}

export default ScopeEditView;
