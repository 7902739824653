import { OrganicMaterialTransactionCertificateDetails } from "../organicMaterialTransactionCertificateTypes";
import "./organicMaterialTransactionCertificateBasicData.css";
import { joinClassNames } from "../../../../../../utils/StringUtils";
import GtbTextField from "../../../../../../components/Input/GtbTextField";
import { UseGtbFormReturn } from "../../../../../../hooks/formHandling/useGtbForm";
import { ReadOnlyProps } from "../../../../../../utils/typeUtils";
import EnumEntityRadiobuttonGroup from "../../../../../../components/Input/radiobutton/EnumEntityRadiobuttonGroup";
import StandardPickList from "../../../../../../components/Input/pickList/typedPicklist/StandardPickList";
import ControlledFileUploadComponent from "../../../../../../components/Input/UploadComponent/ControlledFileUploadComponent";
import {
    GIF_TYPE,
    JPG_TYPE,
    PDF_TYPE,
    PNG_TYPE,
} from "../../../../../../components/Input/UploadComponent/FileUploadComponent";

export interface OrganicMaterialTransactionCertificateBasicDataProps {
    form: UseGtbFormReturn<OrganicMaterialTransactionCertificateDetails>;
    readOnly: Omit<
        ReadOnlyProps<OrganicMaterialTransactionCertificateDetails>,
        | "id"
        | "buyerLegalName"
        | "certifiedOrganicMaterialName"
        | "organicMaterialProducerName"
        | "organicMaterialProductionName"
    >;
    tcFileUrl?: string;
}

export default function OrganicMaterialTransactionCertificateBasicData({
    form,
    readOnly,
    tcFileUrl,
}: OrganicMaterialTransactionCertificateBasicDataProps) {
    return (
        <div className={joinClassNames("organicMaterialTransactionCertificateBasicData", "formGrid")}>
            <GtbTextField
                label="organicMaterialTransactionCertificate.detail.buyer_input"
                {...form.form.register("buyerLegalName")}
                className="buyer"
                readOnly
            />
            <GtbTextField
                label="organicMaterialTransactionCertificate.detail.certifiedOrganicMaterial_input"
                {...form.form.register("certifiedOrganicMaterialName")}
                className="certifiedOrganicMaterial"
                readOnly
            />
            <GtbTextField
                label="organicMaterialTransactionCertificate.detail.organicMaterialProducer_input"
                {...form.form.register("organicMaterialProducerName")}
                className="organicMaterialProducer"
                readOnly
            />
            <GtbTextField
                label="organicMaterialTransactionCertificate.detail.organicMaterialProduction_input"
                {...form.form.register("organicMaterialProductionName")}
                className="organicMaterialProduction"
                readOnly
            />
            <GtbTextField
                label="organicMaterialTransactionCertificate.detail.tcNumber_input"
                {...form.registerWithErrors("tcNumber")}
                className="tcNumber"
                readOnly={readOnly.tcNumber}
            />
            <EnumEntityRadiobuttonGroup
                label="organicMaterialTransactionCertificate.detail.type_input"
                {...form.registerWithErrors("tcType")}
                readOnly={readOnly.tcType}
                className="type"
                referenceUrl="/certificate-service/references/type-of-om-tc"
            />
            <StandardPickList
                label="organicMaterialTransactionCertificate.detail.standard_input"
                control={form.form.control}
                name="standard"
                className="standard"
                flags={{ typeOfStandard: "ORGANIC_MATERIAL_PRODUCTION" }}
                readOnly={readOnly.standard}
            />
            <ControlledFileUploadComponent
                label="organicMaterialTransactionCertificate.detail.tcFile_input"
                acceptedTypes={PDF_TYPE + "," + PNG_TYPE + "," + JPG_TYPE + "," + GIF_TYPE}
                fileUrl={tcFileUrl ?? ""}
                readOnly={readOnly.tcCopy}
                control={form.form.control}
                name="tcCopy"
                className="tcFile"
            />
        </div>
    );
}
