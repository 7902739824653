import { joinClassNames } from "../../utils/StringUtils";
import GtbTextField from "../../components/Input/GtbTextField";
import "./productDetailBasicData.css";
import ControlledDatePicker from "../../components/Input/datepicker/ControlledDatePicker";
import { ProductDetailDetailViewTabProps } from "./ProductDetailDetailView";
import GtbNumberField from "../../components/Input/GtbNumberField";
import CurrentLanguageWatchField from "../../i18n/CurrentLanguageWatchField";

function ProductDetailBasicData({ form, readOnly }: ProductDetailDetailViewTabProps) {
    return (
        <div className={joinClassNames("productDetailBasicData", "formGrid")}>
            <GtbTextField
                {...form.registerWithErrors("code")}
                readOnly={readOnly}
                label="productDetail.detail.code_input"
                className="code"
            />
            <GtbNumberField
                {...form.registerNumberWithErrors("pickListSequence")}
                readOnly={readOnly}
                label="productDetail.detail.pickListSequence_input"
                className="pickListSequence"
            />
            <CurrentLanguageWatchField
                form={form}
                field="name"
                label="productDetail.detail.name_input"
                className="name"
            />
            <ControlledDatePicker
                control={form.form.control}
                readOnly={readOnly}
                name="startDate"
                label="productDetail.detail.startDate_input"
                className="startDate"
            />
            <ControlledDatePicker
                control={form.form.control}
                readOnly={readOnly}
                name="endDate"
                label="productDetail.detail.endDate_input"
                className="endDate"
            />
        </div>
    );
}

export default ProductDetailBasicData;
