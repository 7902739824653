import DataPickList, { DataPickListProps } from "../DataPickList";

import "./typedPickList.css";
import { FacilityReference } from "../../../../facility/facilityTypes";
import { joinClassNames } from "../../../../utils/StringUtils";
import { TypedPicklist } from "./typedPicklistType";
import { FieldValues } from "react-hook-form/dist/types";
import { PickListItemPresentationProps } from "../GtbPickList";

interface FacilityPicklistProps<FormItemType extends FieldValues>
    extends TypedPicklist<FormItemType, FacilityReference> {
    supplyChainOperatorId?: string;
    itemLabel?: DataPickListProps<FormItemType, FacilityReference>["itemLabel"];
    flags?: FacilityPickListFlags;
}

export interface FacilityPickListFlags {
    scopeCertificateId?: string;
    independentLegalEntities?: boolean;
    scProductId?: string;
}

export const facilityPickListCombinedClassName = (className?: string) => joinClassNames("facilityPickList", className);
export const facilityPickListItemPresentation: PickListItemPresentationProps<FacilityReference> = {
    itemId: "id",
    selectedItemLabel: ({ businessName, legalNameInEnglish }) =>
        `${businessName}${legalNameInEnglish ? " (" + legalNameInEnglish + ")" : ""}`,
    itemLabel: ({ businessName, legalNameInEnglish, town, stateProvinceIsoCode, stateProvinceCountryAreaName }) =>
        [businessName, legalNameInEnglish, `${town}, ${stateProvinceIsoCode}, ${stateProvinceCountryAreaName}`]
            .filter((s) => s)
            .join("\n"),
};
export const facilityPickListScoReferenceUrlBuilder = (supplyChainOperatorId: string) =>
    `/certificate-service/references/supply-chain-operators/${supplyChainOperatorId}/facilities`;
export const facilityPickListReferenceUrl = "/certificate-service/references/facilities";

export default function FacilityPickList<FormItemType extends FieldValues>({
    supplyChainOperatorId,
    className,
    ...rest
}: FacilityPicklistProps<FormItemType>) {
    return (
        <DataPickList
            {...facilityPickListItemPresentation}
            queryUrl={
                supplyChainOperatorId
                    ? facilityPickListScoReferenceUrlBuilder(supplyChainOperatorId)
                    : facilityPickListReferenceUrl
            }
            className={facilityPickListCombinedClassName(className)}
            {...rest}
        />
    );
}
