import { useHelpContext } from "../help/context-sensitivity/useHelpContext";
import EntityListView, { EntityListViewGridProps } from "../components/entity/EntityListView";
import useAllocatedTcNumberList from "./useAllocatedTcNumberList";
import { AllocatedTcNumberSummary } from "./allocatedTcNumberTypes";

export default function AllocatedTcNumberList() {
    const listProps = useAllocatedTcNumberList();
    useHelpContext("allocated-tc-number-list");
    return <EntityListView heading={"allocatedTcNumber.list.header"} gridOptions={listProps} />;
}

export interface AllocatedTcNumberListViewProps extends EntityListViewGridProps<AllocatedTcNumberSummary> {}
