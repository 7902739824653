import React, { ReactNode, useCallback } from "react";
import "./dialog.css";
import { useModal } from "../modal/ModalProvider";
import { I18nKey } from "../../i18n/useGtbTranslation";
import { joinClassNames } from "../../utils/StringUtils";

export interface DialogType {
    header: ReactNode;
    content: ReactNode;
    footer: ReactNode;
    className?: string;
}

export const Dialog = ({ dialog }: { dialog: DialogType }) => {
    return (
        <div
            role="dialog"
            aria-modal
            aria-labelledby="dialogTitle"
            className={joinClassNames("dialog", dialog.className)}
        >
            {dialog.header}
            {dialog.content}
            {dialog.footer}
        </div>
    );
};

const useDialog = () => {
    const { closeModal: closeDialog, showModal } = useModal();

    const showDialog = useCallback(
        (dialog: DialogType) => {
            showModal(<Dialog dialog={dialog} />);
        },
        [showModal]
    );

    return {
        closeDialog,
        showDialog,
    };
};

export interface DialogProps {
    title: I18nKey;
    message: I18nKey;
    footer?: any;
}

export default useDialog;
