import { ReactNode } from "react";
import "./viewContainer.css";
import BreadcrumbContainer from "./breadcrumb/BreadcrumbContainer";
import { joinClassNames } from "../utils/StringUtils";

interface ViewContainerProps {
    children: ReactNode;
    className?: string;
}

function ViewContainer({ children, className }: ViewContainerProps) {
    return (
        <div className={joinClassNames("view", className)}>
            <BreadcrumbContainer />
            {children}
        </div>
    );
}

export default ViewContainer;
