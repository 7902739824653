import { buildSchema, validateArray, validateNumber, validateString } from "../../utils/yupExtension";
import { organisationValidationSchema } from "../../organisation/details/organisationValidationSchema";
import { CertificationBodyDetails } from "../certificationBodyTypes";

export const certificationBodyValidationSchema = buildSchema<CertificationBodyDetails>({
    ...organisationValidationSchema,
    legalName: validateString().isRequired().hasMaxLength(80),
    businessName: validateString().isRequired().hasMaxLength(40),
    authorisedPerson: validateString().hasMaxLength(150),
    tcNumberBlockSize: validateNumber().isRequired().hasMinValue(1),
    cbsWithGrantedPriorConsent: validateArray(),
});
