import useErrorDialog from "../components/dialog/useErrorDialog";
import useLoadingDialog from "../components/dialog/useLoadingDialog";
import { useQuery } from "./useAxios";

const useLockchainExportDownload = (url: string) => {
    const { runQuery } = useQuery<Blob>({
        url,
        enabled: false,
    });

    const { showDialog: showLoadingIndicator, closeDialog: closeLoadingIndicator } = useLoadingDialog();
    const { showDialog: showError } = useErrorDialog();

    function formatUTCTimestamp(timestamp: number) {
        const date = new Date(timestamp);
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, "0");
        const day = String(date.getUTCDate()).padStart(2, "0");
        const hours = String(date.getUTCHours()).padStart(2, "0");
        const minutes = String(date.getUTCMinutes()).padStart(2, "0");
        const seconds = String(date.getUTCSeconds()).padStart(2, "0");
        return `${year}-${month}-${day} ${hours}${minutes}${seconds}`;
    }

    return () => {
        showLoadingIndicator("lockchainExport.loading_indicator_text");
        runQuery()
            .then((jsonData) => {
                let jsonAsString = JSON.stringify(jsonData, null, 2);
                let blob = new Blob([jsonAsString], {
                    type: "application/json",
                });
                let a = document.createElement("a");
                a.href = window.URL.createObjectURL(blob);
                a.download = `Lockchain export ${formatUTCTimestamp(Date.now())}.json`;
                a.type = "application/json";
                a.click();
                closeLoadingIndicator();
            })
            .catch((error) => {
                showError({
                    title: "lockchainExport.error_title",
                    message: {
                        key: "lockchainExport.error_message",
                        options: { code: error?.statusCode, message: error?.data },
                    },
                });
            });
    };
};

export default useLockchainExportDownload;
