import DataPickList from "../DataPickList";
import { ProductCategoryReference } from "../../../../productCategory/productCategoryTypes";
import { joinClassNames } from "../../../../utils/StringUtils";

import "./typedPickList.css";
import { TypedPicklist } from "./typedPicklistType";
import { FieldValues } from "react-hook-form/dist/types";

export default function ProductCategoryPickList<FormItemType extends FieldValues>({
    className,
    ...rest
}: TypedPicklist<FormItemType, ProductCategoryReference>) {
    return (
        <DataPickList
            className={joinClassNames("productCategoryPickList", className)}
            itemId="id"
            itemLabel={(option) => `${option.name} (${option.code})`}
            queryUrl="/certificate-service/references/product-categories"
            {...rest}
        />
    );
}
