import React, { ReactNode } from "react";
import { GridHeaderCellProps } from "./GridHeaderCell";
import { GridBodyCellProps } from "./GridBodyCell";

export interface GridColumn<T> {
    key: string;
    header: GridHeaderCellProps;
    bodyItem: GridBodyCellProps<T>;
    visible: boolean;
    className?: string;
}

function Grid({
    id,
    "aria-controls": ariaControls,
    children,
}: {
    id?: string;
    "aria-controls"?: string;
    children: ReactNode;
}) {
    function handleKeyPress(event: React.KeyboardEvent<HTMLDivElement>) {
        const gridElement = event.currentTarget;
        const headerElement = gridElement.querySelector("thead");

        const gridHeight = gridElement.clientHeight;
        const headerHeight = headerElement!.clientHeight;
        const up = -(gridHeight - headerHeight);
        const down = gridHeight - headerHeight;

        switch (event.code) {
            case "PageDown":
                scrollPage(down);
                break;

            case "PageUp":
                scrollPage(up);
                break;
        }

        function scrollPage(top: number) {
            event.preventDefault();
            gridElement.scrollBy({
                top,
                behavior: "smooth",
            });
        }
    }

    return (
        <div className="gridWrapper" tabIndex={0} onKeyDown={(e) => handleKeyPress(e)}>
            <table id={id} aria-controls={ariaControls}>
                {children}
            </table>
        </div>
    );
}

export default Grid;
