import { Nullable } from "../../utils/typeUtils";
import { AccreditationRelationDetails } from "../accreditationRelationTypes";
import useDetailsCreate from "../../hooks/details/useDetailsCreate";
import useResolvedRoute from "../../components/routing/useResolvedRoute";
import { yupResolver } from "@hookform/resolvers/yup";
import { accreditationRelationValidationSchema } from "./accreditationRelationValidationSchema";
import {
    AccreditationRelationDetailViewProps,
    AccreditationRelationReadOnlyProps,
} from "./AccreditationRelationDetailView";
import { useMemo } from "react";

export default function useAccreditationRelationCreate(): AccreditationRelationDetailViewProps {
    const { getResolvedId } = useResolvedRoute();
    const certificationBodyId = getResolvedId("certificationBody");
    const accreditationRelationRoute = useMemo(
        () => (certificationBodyId ? "certificationBodyAccreditationRelation" : "accreditationRelation"),
        [certificationBodyId]
    );

    const initialData = useMemo<Nullable<AccreditationRelationDetails>>(() => {
        return {
            id: null,
            certificationBody: certificationBodyId ?? null,
            accreditationBody: null,
            standardVersion: null,
            accreditationNumber: null,
            accreditedScopes: null,
            geographicScopes: null,
            startDate: new Date(),
            endDate: null,
        };
    }, [certificationBodyId]);

    const { detailViewProps, form, title } = useDetailsCreate<AccreditationRelationDetails>({
        title: "accreditationRelation.detail.newRecord_title",
        frontendRoute: accreditationRelationRoute,
        initialData,
        resolver: yupResolver(accreditationRelationValidationSchema),
        saveUrl: "/certificate-service/accreditation-relations",
    });

    const readOnlyProps = useMemo<AccreditationRelationReadOnlyProps>(
        () => ({
            readOnly: false,
            certificationBodyReadOnly: !!certificationBodyId,
        }),
        [certificationBodyId]
    );

    return {
        title,
        detailViewProps,
        form,
        createRoute: accreditationRelationRoute,
        readOnlyProps,
    };
}
