import Grid from "./Grid";
import GridRow from "./GridRow";
import * as React from "react";
import { Fragment, ReactElement } from "react";
import useGtbTranslation, { I18nKey } from "../../../i18n/useGtbTranslation";

export default function SimpleGrid<DataType>({ data, columns, contentProvider }: SimpleGridProps<DataType>) {
    return (
        <Grid>
            <SimpleGridHeader columns={columns} />
            <SimpleGridBody data={data} columns={columns} contentProvider={contentProvider} />
        </Grid>
    );
}

export interface SimpleGridProps<DataType> {
    columns: SimpleGridColumn[];
    data: DataType[];
    contentProvider: (entry: DataType, columnKey: string) => ReactElement;
}

function SimpleGridHeader({ columns }: SimpleGridHeaderProps) {
    const translation = useGtbTranslation();

    return (
        <thead>
            <GridRow>
                {columns.map((column, index) => {
                    return (
                        <th scope="col" key={index}>
                            <div className="headerCell">{translation(column.title)}</div>
                        </th>
                    );
                })}
            </GridRow>
        </thead>
    );
}

interface SimpleGridHeaderProps {
    columns: SimpleGridColumn[];
}

export interface SimpleGridColumn {
    identifier: string;
    title: I18nKey;
}

function SimpleGridBody<DataType>({ data, columns, contentProvider }: SimpleGridProps<DataType>) {
    return (
        <tbody>
            {data &&
                data.map((item, index) => {
                    return (
                        <Fragment key={index}>
                            <GridRow>
                                {columns.map((column, columnIndex) => {
                                    return (
                                        <td key={"" + index + "-" + columnIndex}>
                                            {contentProvider(item, column.identifier)}
                                        </td>
                                    );
                                })}
                            </GridRow>
                        </Fragment>
                    );
                })}
        </tbody>
    );
}
