import { joinClassNames } from "../../utils/StringUtils";
import GtbTextField from "../../components/Input/GtbTextField";
import "./accreditationBodyBasicData.css";
import { AccreditationBodyDetailViewTabProps } from "./AccreditationBodyDetailView";
import OrganisationBasicData from "../../organisation/details/OrganisationBasicData";

function AccreditationBodyBasicData({ form, readOnlyProps }: AccreditationBodyDetailViewTabProps) {
    return (
        <div className={joinClassNames("accreditationBodyBasicData", "organisationBasicData", "formGrid")}>
            <GtbTextField
                {...form.registerWithErrors("legalName")}
                readOnly={readOnlyProps.isLegalNameReadOnly}
                label="accreditationBody.detail.legalName_input"
                className="legalName"
            />
            <OrganisationBasicData form={form} readOnlyProps={readOnlyProps} organisationI18nKey="accreditationBody" />
        </div>
    );
}

export default AccreditationBodyBasicData;
