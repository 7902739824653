import { preGtbTcStatusType } from "../auth/roleTypes";

export interface PreGtbTransactionCertificateSummary {
    id: string;
    tcNumber: string;
    standardAcronym: string;
    sellersLicenceNumber: string;
    buyerBusinessName: string;
    dateOfIssue: Date;
    status: preGtbTcStatusType;
}

export interface PreGtbTransactionCertificateDetails {
    id: string;
    tcNumber: string;
    standard: string;
    certificationBody: string;
    buyer: string;
    sellersLicenceNumber: string;
    dateOfIssue: Date;
    status: preGtbTcStatusType;
}

export interface PreGtbTransactionCertificateReference
    extends Pick<
        PreGtbTransactionCertificateDetails & PreGtbTransactionCertificateSummary,
        "id" | "tcNumber" | "status" | "dateOfIssue"
    > {
    certificationBodyBusinessName: string;
}

export const preGtbTransactionCertificateReadOnlyByStatus = (status?: PreGtbTransactionCertificateDetails["status"]) =>
    status === "FINAL" || status === "WITHDRAWN";
