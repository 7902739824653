import useScMaterialSubList from "./useScMaterialSubList";
import InfinityScrollSublistTab from "../../../../../components/tabs/InfinityScrollSublistTab";
import { scopeCertificateStatusType } from "../../../../../auth/roleTypes";

export default function ScMaterials({
    scopeCertificateStatus,
}: {
    scopeCertificateStatus?: scopeCertificateStatusType;
}) {
    const scMaterialSubList = useScMaterialSubList(scopeCertificateStatus);
    return <InfinityScrollSublistTab {...scMaterialSubList} />;
}
