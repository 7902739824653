import useStandardBodyListView from "./useStandardBodyListView";
import EntityListViewWithoutInfinityScroll from "../components/entity/EntityListViewExcludingInfinityScroll";
import { useHelpContext } from "../help/context-sensitivity/useHelpContext";

export default function StandardBodyListView() {
    const standardBodyGridOptions = useStandardBodyListView();
    useHelpContext("standard-body-list");

    return (
        <EntityListViewWithoutInfinityScroll heading="standardBody.list.header" gridOptions={standardBodyGridOptions} />
    );
}
