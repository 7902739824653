import { useCurrentHelpContextAffiliation } from "./HelpContextStore";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import { useMemo } from "react";
import HeaderLinkCaption from "../../mainLayout/header/HeaderLinkCaption";
import { getBaseUrl } from "../../components/routing/useResolvedRoute";

function ContextSensitiveHelpLink() {
    const { pathname: currentHelpPage } = useLocation();
    const affiliation = useCurrentHelpContextAffiliation();

    const target = useMemo(() => {
        const helpPagesAreOpen = currentHelpPage.startsWith(`${getBaseUrl("help")}/`);
        const sensitiveContextIsOpen = !_.isEmpty(affiliation);

        if (helpPagesAreOpen) return currentHelpPage;
        if (sensitiveContextIsOpen) return getBaseUrl("help") + `/${affiliation}`;
        return getBaseUrl("help");
    }, [affiliation, currentHelpPage]);

    return (
        <li className="headerLink">
            <a href={target} target="_blank" rel="noopener noreferrer">
                <HeaderLinkCaption caption={"mainLayout.headerMenu.help_button"} icon="help" />
            </a>
        </li>
    );
}

export default ContextSensitiveHelpLink;
