import { useGtbNavigate } from "../../components/routing/GtbRouter";
import { useCallback, useEffect } from "react";
import useDetails, { UseDetailsProps, viewTypeType } from "./useDetails";
import { FieldValues } from "react-hook-form/dist/types";
import { useChangeNavigationContext } from "../../components/breadcrumb/useNavigationContext";
import useResolvedRoute, { getEditUrl, routeEnum } from "../../components/routing/useResolvedRoute";
import { I18nKey } from "../../i18n/useGtbTranslation";

function useDetailsCreate<ItemType extends FieldValues>({
    initialData,
    saveUrl,
    title,
    frontendRoute,
    resolver,
    afterCreation,
    resolveErrors,
}: {
    initialData: UseDetailsProps<ItemType>["initialData"];
    saveUrl: string;
    title: I18nKey;
    frontendRoute: routeEnum;
    resolver: UseDetailsProps<ItemType>["resolver"];
    afterCreation?: UseDetailsProps<ItemType>["afterSubmitAction"];
    resolveErrors?: UseDetailsProps<ItemType>["resolveErrors"];
}) {
    const { pushNavigationContext } = useChangeNavigationContext();
    const { getResolvedBaseUrl } = useResolvedRoute();

    useEffect(() => {
        pushNavigationContext(title, getEditUrl(frontendRoute));
    }, [frontendRoute, pushNavigationContext, title]);

    const navigate = useGtbNavigate(true);
    const afterSubmitAction = useCallback(
        (item: ItemType) => {
            navigate(`${getResolvedBaseUrl(frontendRoute)}/${item.id}`);
            afterCreation?.(item);
        },
        [navigate, getResolvedBaseUrl, frontendRoute, afterCreation]
    );

    const { detailViewProps, form } = useDetails<ItemType>({
        initialData,
        isInitialDataLoading: !initialData,
        resolver,
        saveUrl,
        canSave: true,
        saveMethod: "post",
        afterSubmitAction,
        resolveErrors,
    });

    return {
        title,
        detailViewProps,
        form,
        viewType: "create" as viewTypeType,
        subtitle: undefined,
    };
}

export default useDetailsCreate;
