import React, { ChangeEvent, FocusEvent, KeyboardEvent, useCallback, useState } from "react";
import InputWrapper from "../inputWrapper/InputWrapper";
import { Chip } from "@mui/material";
import GtbTextField from "../GtbTextField";
import "./multivaluetextfield.css";
import { joinClassNames } from "../../../utils/StringUtils";
import { I18nKey } from "../../../i18n/useGtbTranslation";
import useGtbTooltip from "../../tooltip/useGtbTooltip";

export interface GtbMultiValueTextFieldProps {
    value: string[] | null;
    onChange: (value: string[] | null, keyboardInputValue?: string | undefined) => void;
    label: I18nKey;
    className?: string;
    error?: boolean;
    helperText?: string;
    readOnly?: boolean;
    validator?: (item: string) => string | null;
}

const GtbMultiValueTextField = ({
    readOnly,
    className,
    error,
    helperText,
    onChange,
    value,
    label,
    validator,
    ...rest
}: GtbMultiValueTextFieldProps) => {
    const [currValue, setCurrValue] = useState("");
    const [validationError, setValidationError] = useState<string>();
    const [textFieldFocused, setTextFieldFocused] = useState(false);
    const { tooltipProps, tooltip } = useGtbTooltip(label);

    const addValueToListOrSetValidationError = useCallback(
        (newValue: string) => {
            if (newValue !== "") {
                let validatorMessage = validator?.(newValue);

                if (validatorMessage) {
                    setValidationError(validatorMessage);
                } else {
                    onChange((value ? [...value, newValue] : [newValue]).filter((v, i, a) => a.indexOf(v) === i));
                    setCurrValue("");
                    setValidationError(undefined);
                }
            }
        },
        [onChange, value, setCurrValue, validator]
    );

    const handleEnterKey = useCallback(
        (e: KeyboardEvent) => {
            if (e.key === "Enter" || e.key === "," || e.key === " ") {
                e.preventDefault();

                let newValue = (e.target as HTMLInputElement).value;

                addValueToListOrSetValidationError(newValue);
            }
        },
        [addValueToListOrSetValidationError]
    );

    const handleBlur = useCallback(
        (e: FocusEvent) => {
            let newValue = (e.target as HTMLInputElement).value;

            addValueToListOrSetValidationError(newValue);
            setTextFieldFocused(false);
        },
        [addValueToListOrSetValidationError]
    );

    const handleChange = useCallback(
        (e: ChangeEvent) => {
            let newValue = (e.target as HTMLInputElement).value;

            setCurrValue(newValue);

            if (validationError) {
                let validatorMessage = validator?.(newValue);
                if (!validatorMessage) {
                    setValidationError(undefined);
                }
            }
        },
        [setCurrValue, validationError, validator]
    );

    const handleDelete = useCallback(
        (item: string, index: number) => {
            if (value) {
                let arr = [...value];
                arr.splice(index, 1);
                onChange(arr);
            }
        },
        [onChange, value]
    );

    return (
        <InputWrapper
            label={label}
            labelFor="multiValueTextField"
            hasValue={!!currValue || textFieldFocused}
            ignoreFocus={true}
            error={error}
            helperText={helperText}
            className={joinClassNames(className, "multiValueField")}
            readOnly={readOnly}
        >
            <GtbTextField
                {...tooltipProps}
                value={currValue}
                onChange={handleChange}
                onKeyDown={handleEnterKey}
                id="multiValueTextField"
                className={joinClassNames("multiValueTextField", !!value?.length ? "withValue" : null)}
                error={validationError !== undefined}
                helperText={validationError}
                onFocus={() => setTextFieldFocused(true)}
                onBlur={handleBlur}
                readOnly={readOnly}
                {...rest}
            />
            {!!value?.length && (
                <div className="multiValueContainer">
                    {value.map((item, index) => (
                        <Chip size="small" onDelete={() => handleDelete(item, index)} label={item} key={index} />
                    ))}
                </div>
            )}
            {tooltip}
        </InputWrapper>
    );
};

export default GtbMultiValueTextField;
