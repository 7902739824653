import { useCallback } from "react";
import DialogHeader from "./DialogHeader";
import GtbButton from "../GtbButton";
import DialogTextContent from "./DialogTextContent";
import DialogFooter from "./DialogFooter";
import useDialog, { DialogProps } from "./useDialog";
import useGtbTranslation from "../../i18n/useGtbTranslation";

function useErrorDialog() {
    const translate = useGtbTranslation();
    const { showDialog: _showDialog, closeDialog } = useDialog();
    const showDialog = useCallback(
        ({
            title,
            message,
            footer = <GtbButton onClick={closeDialog}>{translate("components.dialog.ok_button")}</GtbButton>,
        }: DialogProps) => {
            _showDialog({
                header: <DialogHeader title={title} variant="error" onClick={closeDialog} />,
                content: <DialogTextContent message={message} />,
                footer: <DialogFooter>{footer}</DialogFooter>,
            });
        },
        [closeDialog, translate, _showDialog]
    );

    return {
        showDialog,
        closeDialog,
    };
}

export default useErrorDialog;
