import "./radiobuttonGroup.css";
import { EnumEntityType } from "../pickList/typedEnumPicklist/enumEntityType";
import { useQuery } from "../../../hooks/useAxios";
import LoadingSpinner from "../../LoadingSpinner";
import RadiobuttonGroup, { RadiobuttonGroupProps } from "./RadiobuttonGroup";
import { joinClassNames } from "../../../utils/StringUtils";
import React from "react";

interface TranslatedEntityRadiobuttonGroupProps
    extends Omit<RadiobuttonGroupProps<EnumEntityType>, "options" | "itemId" | "itemLabel"> {
    referenceUrl: string;
}

const EnumEntityRadiobuttonGroup = React.forwardRef<HTMLInputElement, TranslatedEntityRadiobuttonGroupProps>(
    ({ referenceUrl, className, ...rest }, ref) => {
        const { data, isLoading } = useQuery<EnumEntityType[]>({
            url: referenceUrl + "?sortOrdinal",
            enabled: true,
        });
        return (
            <div className={joinClassNames(className, "radiobuttonGroupWrapper")}>
                <RadiobuttonGroup
                    options={data}
                    itemId="id"
                    itemLabel={(item) => item.name}
                    className={className}
                    ref={ref}
                    {...rest}
                />
                {isLoading && <LoadingSpinner size={15} />}
            </div>
        );
    }
);

export default EnumEntityRadiobuttonGroup;
