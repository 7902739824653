import { useHelpContext } from "../help/context-sensitivity/useHelpContext";
import EntityListView from "../components/entity/EntityListView";
import useAccreditationRelationListView from "./useAccreditationRelationListView";

export default function AccreditationRelationListView() {
    const accreditationRelationGridOptions = useAccreditationRelationListView();
    useHelpContext("accreditation-relation-list");

    return (
        <EntityListView heading="accreditationRelation.list.header" gridOptions={accreditationRelationGridOptions} />
    );
}
