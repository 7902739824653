import { Nullable } from "../../../../../../utils/typeUtils";
import useDetailsCreate from "../../../../../../hooks/details/useDetailsCreate";
import { useMemo, useState } from "react";
import { useQuery } from "../../../../../../hooks/useAxios";
import { ScopeCertificateReference } from "../../../../../scopeCertificateTypes";
import { ScMaterialDetailViewProps } from "./ScMaterialDetailView";
import { ScProductReference } from "../../../scProductTypes";
import { ScMaterialDetails } from "../scMaterialTypes";
import useResolvedRoute from "../../../../../../components/routing/useResolvedRoute";
import { yupResolver } from "@hookform/resolvers/yup";
import { scMaterialValidationSchema } from "./scMaterialValidationSchema";
import { validateString } from "../../../../../../utils/yupExtension";
import useScMaterialMaterialPrintVisibility from "./useScMaterialPrintVisibility";

export default function useScMaterialCreate(): ScMaterialDetailViewProps {
    const { getResolvedId } = useResolvedRoute();
    const scopeCertificateId = getResolvedId("scopeCertificate")!;
    const scProductId = getResolvedId("scProduct")!;
    const [isMaterialPrintVisible, setIsMaterialPrintVisible] = useState(false);

    const { data: scopeCertificate, isLoading: isScopeCertificateLoading } = useQuery<ScopeCertificateReference>({
        url: `/certificate-service/references/scope-certificates/${scopeCertificateId}`,
    });

    const { data: scProduct, isLoading: isScProductLoading } = useQuery<ScProductReference>({
        url: `/certificate-service/references/sc-products/${scProductId}`,
    });

    const initialData = useMemo<Nullable<ScMaterialDetails>>(
        () => ({
            id: null,
            scProductScopeCertificateSupplyChainOperatorBusinessName:
                scopeCertificate?.supplyChainOperatorBusinessName ?? null,
            scProductScProductNumber: scProduct?.scProductNumber ?? null,
            scProductProductCategoryName: scProduct?.productCategoryName ?? null,
            scProductProductCategoryPrint: scProduct?.productCategoryPrint ?? null,
            scProductProductDetailName: scProduct?.productDetailName ?? null,
            scProductProductDetailPrint: scProduct?.productDetailPrint ?? null,
            scProductLabelGradeLabelGrade: scProduct?.labelGradeLabelGrade ?? null,
            material: null,
            materialPrint: null,
            materialName: null,
            minPercentage: null,
            maxPercentage: null,
            scProductScopeCertificateStatus: null,
        }),
        [
            scProduct?.labelGradeLabelGrade,
            scProduct?.productCategoryName,
            scProduct?.productCategoryPrint,
            scProduct?.productDetailName,
            scProduct?.productDetailPrint,
            scProduct?.scProductNumber,
            scopeCertificate?.supplyChainOperatorBusinessName,
        ]
    );

    const { detailViewProps, form, title } = useDetailsCreate<ScMaterialDetails>({
        title: "scMaterial.detail.newRecord_title",
        frontendRoute: "scMaterial",
        initialData,
        resolver: yupResolver(
            scMaterialValidationSchema.shape({
                materialPrint: validateString().when([], {
                    is: () => isMaterialPrintVisible,
                    then: validateString().isRequired().hasMaxLength(50),
                }),
            })
        ),
        saveUrl: `/certificate-service/scope-certificates/${scopeCertificateId}/sc-products/${scProductId}/sc-materials`,
    });

    const isMaterialLoading = useScMaterialMaterialPrintVisibility(form, setIsMaterialPrintVisible);

    return {
        title,
        detailViewProps: {
            ...detailViewProps,
            isLoading: detailViewProps.isLoading || isScopeCertificateLoading || isScProductLoading,
        },
        form,
        readOnly: false,
        materialPrintVisible: isMaterialPrintVisible && !isMaterialLoading,
        createRoute: "scMaterial",
        scopeCertificateId,
    };
}
