import { TypedDetailViewProps } from "../../components/detailView/DetailView";
import "../../components/spinner.css";
import { useHelpContext } from "../../help/context-sensitivity/useHelpContext";
import EntityDetailView from "../../components/entity/EntityDetailView";
import Tabs, { TabKey } from "../../components/tabs/Tabs";
import React from "react";
import OrganicMaterialProductionScBasicData, {
    OrganicMaterialProductionScBasicDataProps,
} from "./OrganicMaterialProductionScBasicData";
import { OrganicMaterialProductionScDetails } from "../organicMaterialProductionScTypes";

export default function OrganicMaterialProductionScDetailView({
    detailViewProps,
    form,
    readOnly,
    scCopyUrl,
    subListVisible,
    organicMaterialProducerId,
    ...rest
}: OrganicMaterialProductionScDetailViewProps) {
    useHelpContext("organic-material-production-sc-details");

    return (
        <EntityDetailView {...rest} detailViewProps={detailViewProps} form={form}>
            <Tabs<OrganicMaterialProductionScDetails, OrganicMaterialProductionScTabs>
                tabs={[
                    {
                        heading: "organicMaterialProductionSc.detail.basicData_tab",
                        element: (
                            <OrganicMaterialProductionScBasicData
                                form={form}
                                readOnly={readOnly}
                                scCopyUrl={scCopyUrl}
                                subListVisible={subListVisible}
                                organicMaterialProducerId={organicMaterialProducerId}
                            />
                        ),
                    },
                ]}
                errors={form.form.formState.errors}
                errorToTab={{
                    id: "internal",
                    scNumber: "organicMaterialProductionSc.detail.basicData_tab",
                    organicMaterialProducer: "organicMaterialProductionSc.detail.basicData_tab",
                    validUntil: "organicMaterialProductionSc.detail.basicData_tab",
                    validFrom: "organicMaterialProductionSc.detail.basicData_tab",
                    standard: "organicMaterialProductionSc.detail.basicData_tab",
                    issuingBody: "organicMaterialProductionSc.detail.basicData_tab",
                    scCopy: "organicMaterialProductionSc.detail.basicData_tab",
                    totalFarmAreaUnderSc: "organicMaterialProductionSc.detail.basicData_tab",
                    warningCanonicalEqualScNumberAndStandard: "internal",
                }}
            />
        </EntityDetailView>
    );
}

type OrganicMaterialProductionScTabs = TabKey<"organicMaterialProductionSc.detail.basicData_tab">;

export interface OrganicMaterialProductionScDetailViewProps
    extends TypedDetailViewProps<OrganicMaterialProductionScDetails>,
        OrganicMaterialProductionScBasicDataProps {}
