import React from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import GtbRouter from "./components/routing/GtbRouter";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import NavigationContextProvider from "./components/breadcrumb/useNavigationContext";
import "./i18n/i18n";
import DowntimeChecker from "./components/downtimeNotification/DowntimeChecker";
import { AuthProvider } from "./auth/useAuth";
import { ModalProvider } from "./components/modal/ModalProvider";
import { HelpContextProvider } from "./help/context-sensitivity/HelpContextStore";
import GtbRoutes from "./GtbRoutes";
import ThemeSetter from "./components/ThemeSetter";
import { LanguageContextProvider } from "./i18n/LanguageContextProvider";
import LanguageBackendUpdater from "./i18n/LanguageBackendUpdater";

function App() {
    return (
        <GtbRouter>
            <LanguageContextProvider>
                <NavigationContextProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <ToastContainer position="bottom-left" />
                        <DowntimeChecker>
                            <AuthProvider initialState={null}>
                                <LanguageBackendUpdater>
                                    <ModalProvider>
                                        <ThemeSetter />
                                        <HelpContextProvider>
                                            <GtbRoutes />
                                        </HelpContextProvider>
                                    </ModalProvider>
                                </LanguageBackendUpdater>
                            </AuthProvider>
                        </DowntimeChecker>
                    </LocalizationProvider>
                </NavigationContextProvider>
            </LanguageContextProvider>
        </GtbRouter>
    );
}

export default App;
