import GtbTextField from "../../../../components/Input/GtbTextField";
import { PreGtbTcMaterialDetails } from "../preGtbTcMaterialsTypes";
import { UseGtbFormReturn } from "../../../../hooks/formHandling/useGtbForm";
import RawMaterialPickList from "../../../../components/Input/pickList/typedPicklist/RawMaterialPickList";
import { LabeledCheckbox } from "../../../../components/Input/Checkbox";
import GtbNumberField, { GtbNumberFieldOneFixedDecimal } from "../../../../components/Input/GtbNumberField";
import { joinClassNames } from "../../../../utils/StringUtils";
import useSetFormValue from "../../../../hooks/formHandling/useSetFormValue";
import { MultiselectStateProvincePickList } from "../../../../components/Input/pickList/typedPicklist/MultiselectStateProvincePickList";
import ControlledTextField from "../../../../components/Input/ControlledTextField";
import { useMemo } from "react";
import "./preGtbTcMaterialBasicData.css";

export default function PreGtbTcMaterialBasicData({
    form,
    labelGradeTypeOfMaterialId,
    isCertified,
    rawMaterialPrintVisible,
    readOnly,
    preGtbTcId,
}: PreGtbTcMaterialBasicDataProps) {
    const setFormValue = useSetFormValue(form.form);

    const gridAreaClassName = useMemo(() => {
        if (rawMaterialPrintVisible && isCertified) {
            return "withRawMaterialPrintAndOrigin";
        } else if (rawMaterialPrintVisible) {
            return "withRawMaterialPrint";
        } else if (isCertified) {
            return "withOrigin";
        } else {
            return "withoutDynamicFields";
        }
    }, [isCertified, rawMaterialPrintVisible]);

    return (
        <div className={joinClassNames("formGrid", "preGtbTcMaterialBasicData", gridAreaClassName)}>
            <GtbTextField
                label={"preGtbTcMaterial.detail.preGtbTcNumber_input"}
                className={"preGtbTcNumber"}
                readOnly
                {...form.registerWithErrors("preGtbTcNumber")}
            />
            <GtbTextField
                label={"preGtbTcMaterial.detail.preGtbTcProductNumber_input"}
                className={"preGtbTcProductNumber"}
                readOnly
                {...form.registerWithErrors("preGtbTcProductNumber")}
            />
            <GtbTextField
                label={"preGtbTcMaterial.detail.preGtbTcProductCategory_input"}
                className={"preGtbTcProductCategory"}
                readOnly
                {...form.registerWithErrors("preGtbTcProductCategoryPrint")}
            />
            <GtbTextField
                label={"preGtbTcMaterial.detail.preGtbTcProductDetail_input"}
                className={"preGtbTcProductDetail"}
                readOnly
                {...form.registerWithErrors("preGtbTcProductDetailPrint")}
            />
            <GtbTextField
                label={"preGtbTcMaterial.detail.labelGradeLabelGrade_input"}
                className={"labelGrade"}
                readOnly
                {...form.registerWithErrors("labelGradeLabelGrade")}
            />
            <RawMaterialPickList
                label={"preGtbTcMaterial.detail.rawMaterial_input"}
                control={form.form.control}
                className={"rawMaterial"}
                name={"rawMaterial"}
                readOnly={readOnly.rawMaterial}
                flags={{ preGtbTcId }}
                onChangeListener={(_event, _value, selectedOption) => {
                    setFormValue("rawMaterialPrint", selectedOption?.name);
                    setFormValue("rawMaterialPrintRequired", selectedOption?.printNameMandatory);

                    const calculatedCertified = calculateIsCertified(
                        selectedOption?.typeOfMaterial,
                        labelGradeTypeOfMaterialId
                    );
                    setFormValue("certified", calculatedCertified);
                }}
            />
            {rawMaterialPrintVisible && (
                <ControlledTextField
                    label={"preGtbTcMaterial.detail.rawMaterialPrint_input"}
                    control={form.form.control}
                    className={"rawMaterialPrint"}
                    readOnly={readOnly.rawMaterialPrint}
                    name={"rawMaterialPrint"}
                />
            )}
            <LabeledCheckbox
                id={"certified"}
                label={"preGtbTcMaterial.detail.certified_input"}
                className={"certified"}
                checked={isCertified}
                readOnly
            />
            <GtbNumberField
                label={"preGtbTcMaterial.detail.percentage_input"}
                className={"percentage"}
                readOnly={readOnly.percentage}
                unit="%"
                decimalPlaces={2}
                {...form.registerNumberWithErrors("percentage")}
            />
            <GtbNumberFieldOneFixedDecimal
                label={"preGtbTcMaterial.detail.certifiedWeight_input"}
                name={"certifiedWeight"}
                className={"certifiedWeight"}
                control={form.form.control}
                decimalPlaces={1}
                unit="kg"
                readOnly
            />
            {isCertified && (
                <MultiselectStateProvincePickList
                    label={"preGtbTcMaterial.detail.origin_input"}
                    control={form.form.control}
                    name={"origin"}
                    className={"origin"}
                    readOnly={readOnly.origin}
                />
            )}
        </div>
    );
}

function calculateIsCertified(
    rawMaterialTypeOfMaterial: string | null | undefined,
    labelGradeTypeOfMaterial: string | null | undefined
) {
    if (!labelGradeTypeOfMaterial || !rawMaterialTypeOfMaterial) {
        return false;
    } else {
        return rawMaterialTypeOfMaterial === labelGradeTypeOfMaterial;
    }
}

export interface PreGtbTcMaterialBasicDataProps {
    form: UseGtbFormReturn<PreGtbTcMaterialDetails>;
    rawMaterialPrintVisible: boolean;
    labelGradeTypeOfMaterialId: string | null;
    isCertified: boolean;
    preGtbTcId?: string;
    readOnly: {
        rawMaterial: boolean;
        rawMaterialPrint: boolean;
        percentage: boolean;
        origin: boolean;
    };
}
