import { UseGtbFormReturn } from "../../../../../../hooks/formHandling/useGtbForm";
import { InputQuantityDetails } from "../inputQuantityTypes";
import { ReadOnlyProps } from "../../../../../../utils/typeUtils";
import { joinClassNames } from "../../../../../../utils/StringUtils";
import GtbTextField from "../../../../../../components/Input/GtbTextField";
import EnumEntityRadiobuttonGroup from "../../../../../../components/Input/radiobutton/EnumEntityRadiobuttonGroup";
import "./inputQuantityBasicData.css";
import { GtbNumberFieldOneFixedDecimal } from "../../../../../../components/Input/GtbNumberField";
import InputSourceSelectionComponent from "./inputSource/InputSourceSelectionComponent";
import GtbTextArea from "../../../../../../components/Input/GtbTextArea";
import { useMemo } from "react";

export interface InputQuantityBasicDataProps {
    form: UseGtbFormReturn<InputQuantityDetails>;
    readOnly: Pick<ReadOnlyProps<InputQuantityDetails>, "typeOfInputSource" | "quantity">;
    currentSourceType: InputQuantityDetails["typeOfInputSource"];
    inputSourceSelectionVisible?: boolean;
}

export default function InputQuantityBasicData({
    form,
    readOnly,
    currentSourceType,
    inputSourceSelectionVisible,
}: InputQuantityBasicDataProps) {
    const currentSourceTypeId = useMemo(
        () => (inputSourceSelectionVisible && currentSourceType ? `sourceType-${currentSourceType}` : undefined),
        [currentSourceType, inputSourceSelectionVisible]
    );

    return (
        <div className={joinClassNames("inputQuantityBasicData", "formGrid")}>
            <GtbTextField
                label="inputQuantity.detail.inputNumber_input"
                {...form.registerWithErrors("inputNumber")}
                readOnly
                className="inputNumber"
            />
            <EnumEntityRadiobuttonGroup
                label="inputQuantity.detail.typeOfInputSource_input"
                {...form.registerWithErrors("typeOfInputSource")}
                referenceUrl="/certificate-service/references/input-quantity-source-types"
                readOnly={readOnly.typeOfInputSource}
                optionProps={(option) => ({
                    readOnly: readOnly.typeOfInputSource,
                    "aria-controls": currentSourceType === option.id ? currentSourceTypeId : undefined,
                })}
                className="sourceType"
            />
            <GtbNumberFieldOneFixedDecimal
                label="inputQuantity.detail.quantityToBeUsed_input"
                control={form.form.control}
                name="quantity"
                unit="kg"
                decimalPlaces={1}
                className="quantityToBeUsed"
                readOnly={readOnly.quantity}
            />
            <GtbTextArea
                readOnly
                id="inputSource"
                label="inputQuantity.detail.inputSource_input"
                className="inputSource"
                minRows={5}
                maxRows={5}
                aria-owns={currentSourceTypeId}
                {...form.registerWithErrors("currentInputSource")}
            />
            {inputSourceSelectionVisible && (
                <InputSourceSelectionComponent currentSourceType={currentSourceType} form={form} />
            )}
        </div>
    );
}
