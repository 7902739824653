import useDetailsCreate from "../../hooks/details/useDetailsCreate";
import { yupResolver } from "@hookform/resolvers/yup";
import { Nullable } from "../../utils/typeUtils";
import { ProcessCategoryDetails } from "../processCategoryTypes";
import { processCategoryValidationSchema } from "./processCategoryValidationSchema";
import { routeEnum } from "../../components/routing/useResolvedRoute";
import { getInitialTranslatableValue } from "../../i18n/languageTypes";

const initialData: Nullable<ProcessCategoryDetails> = {
    code: null,
    id: null,
    name: getInitialTranslatableValue(),
    pickListSequence: null,
    startDate: null,
    endDate: null,
};

function useProcessCategoryCreate() {
    const { detailViewProps, form, title } = useDetailsCreate<ProcessCategoryDetails>({
        title: "processCategory.detail.newRecord_title",
        frontendRoute: "processCategory",
        initialData,
        resolver: yupResolver(processCategoryValidationSchema),
        saveUrl: "/certificate-service/process-categories",
    });

    return {
        title,
        detailViewProps,
        form,
        navigationEnabled: false,
        isReadOnly: false,
        createRoute: "processCategory" as routeEnum,
    };
}

export default useProcessCategoryCreate;
