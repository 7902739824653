import { buildSchema, validateDate, validateString } from "../../utils/yupExtension";
import { SubcontractingRelationDetails } from "../subcontractingRelationTypes";

export const subcontractingRelationValidationSchema = buildSchema<SubcontractingRelationDetails>({
    principalSupplyChainOperator: validateString().isRequired(),
    subcontractorSupplyChainOperator: validateString().isRequired(),
    standard: validateString().isRequired(),
    startDate: validateDate().isRequired(),
    endDate: validateDate(),
});
