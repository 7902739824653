import Card from "../../components/card/Card";
import { IconName } from "../../components/Icon";
import { Fragment, HTMLAttributes, ReactNode, useMemo } from "react";
import { useQuery } from "../../hooks/useAxios";
import LoadingSpinner from "../../components/LoadingSpinner";
import DashboardIcon from "./DashboardIcon";
import useGtbTranslation, { I18nFunction, I18nKey } from "../../i18n/useGtbTranslation";
import { joinClassNames, sanitizeLabelText } from "../../utils/StringUtils";

export default function Tile({
    title,
    icon,
    query,
    renderActions,
    col,
    row,
    className,
    queryDataResolver,
    ...rest
}: TileProps & Omit<HTMLAttributes<HTMLDivElement>, "title">) {
    const translation = useGtbTranslation();

    const {
        data: content,
        runQuery: reloadTileContent,
        isLoading,
    } = useQuery<string>({
        url: query ?? "",
        enabled: !!query,
    });

    const contentToDisplay = useMemo(() => {
        if (!query) {
            return translation("dashboard.tiles.tbd_message");
        } else if (isLoading) {
            return <LoadingSpinner size={25} />;
        } else if (queryDataResolver) {
            const resolvedContent = queryDataResolver(content, translation);
            if (!Array.isArray(resolvedContent)) {
                return translation(resolvedContent);
            }
            return (
                <>
                    {resolvedContent.map(([value, label]) => (
                        <Fragment key={label as string}>
                            <span>{value}</span>
                            <span>{translation(label)}</span>
                        </Fragment>
                    ))}
                </>
            );
        } else {
            return content;
        }
    }, [content, queryDataResolver, isLoading, query, translation]);

    return (
        <Card
            {...rest}
            className={joinClassNames("tile", className)}
            aria-labelledby={`${sanitizeLabelText(title as string)}-label`}
            style={{ gridColumn: col, gridRow: row }}
        >
            <DashboardIcon icon={icon} />
            <span className="title-wrapper" id={`${sanitizeLabelText(title as string)}-label`}>
                {translation(title)}
            </span>
            <div className="content-wrapper">{contentToDisplay}</div>
            <div className="action-wrapper">{renderActions?.(reloadTileContent)}</div>
        </Card>
    );
}

export type dashboardIcon = { name: IconName; className: string };

export interface TileProps {
    title: I18nKey;
    icon: dashboardIcon;
    col: number;
    row: number;
    query?: string;
    queryDataResolver?: (queryData: any, translation: I18nFunction) => [any, I18nKey][] | I18nKey;
    renderActions?: (reloadTileContent: Function) => ReactNode;
}
