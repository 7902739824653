import { joinClassNames } from "../../utils/StringUtils";
import "./standardScopes.css";
import SubList from "../../components/grid/SubList";
import useScopeSublist from "../../scope/useScopeSublist";

function StandardScopes() {
    const scopeSublistOptions = useScopeSublist();
    return (
        <div className={joinClassNames("standardScopes", "formGrid")}>
            <SubList {...scopeSublistOptions} className="scopes" />
        </div>
    );
}

export default StandardScopes;
