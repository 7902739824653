import GtbTextField from "../../components/Input/GtbTextField";
import CountryAreaPickList from "../../components/Input/pickList/typedPicklist/CountryAreaPickList";
import StateProvincePickList from "../../components/Input/pickList/typedPicklist/StateProvincePickList";
import { OrganicMaterialProductionDetailViewTabProps } from "./OrganicMaterialProductionDetailView";
import "./organicMaterialProductionAddressData.css";

export default function OrganicMaterialProductionAddressData({
    form,
    readOnlyProps,
}: OrganicMaterialProductionDetailViewTabProps) {
    return (
        <div className={"formGrid organicMaterialProductionAddressData"}>
            <GtbTextField
                {...form.registerWithErrors("town")}
                readOnly={readOnlyProps.isTownReadOnly}
                label={"organicMaterialProduction.detail.town_input"}
                className="town"
            />
            <GtbTextField
                {...form.registerWithErrors("postCode")}
                readOnly={readOnlyProps.isPostCodeReadOnly}
                label={"organicMaterialProduction.detail.postCode_input"}
                className="postCode"
            />
            <CountryAreaPickList
                control={form.form.control}
                readOnly={readOnlyProps.isCountryAreaReadOnly}
                name="countryArea"
                className="countryArea"
                label={"organicMaterialProduction.detail.countryArea_input"}
            />
            <StateProvincePickList
                form={form.form}
                readOnly={readOnlyProps.isCountryAreaReadOnly}
                name="stateProvince"
                parentField={"countryArea"}
                className="stateProvince"
                label={"organicMaterialProduction.detail.stateProvince_input"}
            />
            <GtbTextField
                {...form.registerWithErrors("addressDetails1")}
                readOnly={readOnlyProps.isAddressDetails1ReadOnly}
                label={"organicMaterialProduction.detail.addressDetails1_input"}
                className="addressDetails1"
            />
            <GtbTextField
                {...form.registerWithErrors("addressDetails2")}
                readOnly={readOnlyProps.isAddressDetails2ReadOnly}
                label={"organicMaterialProduction.detail.addressDetails2_input"}
                className="addressDetails2"
            />
            <GtbTextField
                {...form.registerWithErrors("addressDetails3")}
                readOnly={readOnlyProps.isAddressDetails3ReadOnly}
                label={"organicMaterialProduction.detail.addressDetails3_input"}
                className="addressDetails3"
            />
        </div>
    );
}
