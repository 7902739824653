import { useMemo } from "react";
import { useShowDetailsAction } from "../utils/defaultActions";
import { Query } from "../components/grid/component/useQueryBuilder";
import useResolvedRoute from "../components/routing/useResolvedRoute";
import { SubListProps } from "../components/grid/SubList";
import { OrganicMaterialProductionScSummary } from "./organicMaterialProductionScTypes";
import { GridColumn } from "../components/grid/component/Grid";
import { buildDateColumn, buildNumberColumn, buildTextColumn, ColumnOptions } from "../components/grid/ColumnProvider";
import { GridRowContextMenuAction } from "../components/grid/component/cell/GridRowContextMenu";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";

const columns: GridColumn<OrganicMaterialProductionScSummary>[] = [
    buildTextColumn({
        key: "scNumber",
        title: "organicMaterialProductionSc.list.scNumber_column",
        minWidth: 142,
    }),
    buildTextColumn({
        key: "standardAcronym",
        title: "organicMaterialProductionSc.list.standard_column",
        minWidth: 126,
    }),
    buildDateColumn({ key: "validUntil", title: "organicMaterialProductionSc.list.validUntil_column", minWidth: 132 }),
    buildNumberColumn({
        key: "totalFarmAreaUnderSc",
        title: "organicMaterialProductionSc.list.farmArea_column",
        minWidth: 167,
    }),
    buildTextColumn({
        key: "issuingBody",
        title: "organicMaterialProductionSc.list.issuingBody_column",
        minWidth: 155,
        visible: false,
    }),
    buildDateColumn({
        key: "validFrom",
        title: "organicMaterialProductionSc.list.validFrom_column",
        minWidth: 134,
        visible: false,
    }),
];

export default function useOrganicMaterialProductionScSubList({
    query,
    columnOptions,
    deleteDetachContextMenuAction,
    createUrl,
    tableName,
}: {
    query: Query<OrganicMaterialProductionScSummary>;
    columnOptions: ColumnOptions<OrganicMaterialProductionScSummary>;
    deleteDetachContextMenuAction?: GridRowContextMenuAction<OrganicMaterialProductionScSummary>;
    createUrl?: string;
    tableName: string;
}) {
    const { getResolvedEditUrl } = useResolvedRoute();

    const showDetailsAction = useShowDetailsAction(
        (item: OrganicMaterialProductionScSummary) => getResolvedEditUrl("organicMaterialProductionSc", item.id),
        query.parameter
    );

    const itemActions = useGridRowContextMenuActions(showDetailsAction, deleteDetachContextMenuAction);

    return useMemo<SubListProps<OrganicMaterialProductionScSummary>>(
        () => ({
            query,
            columns: columns,
            uniqueDataKey: "id",
            onItemDoubleClick: showDetailsAction,
            header: "organicMaterialProductionSc.list.header",
            itemActions,
            columnOptions,
            createUrl,
            tableName,
        }),
        [columnOptions, createUrl, itemActions, query, showDetailsAction, tableName]
    );
}
