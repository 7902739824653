import useGtbTranslation from "../../i18n/useGtbTranslation";
import useDialog from "../../components/dialog/useDialog";
import DialogHeader from "../../components/dialog/DialogHeader";
import DialogContent from "../../components/dialog/DialogContent";
import DialogFooter from "../../components/dialog/DialogFooter";
import { DialogOptions } from "../../components/dialog/DialogOptions";
import { useCallback } from "react";
import { UseFormAfterSubmitAction } from "../../hooks/formHandling/useFormSubmit";
import { ScoLicenceDetails } from "../scoLicenceTypes";

export default function useBanConfirmationDialog() {
    const translation = useGtbTranslation();
    const { showDialog: showConfirmationDialog, closeDialog } = useDialog();

    return useCallback(
        (
            retrySaveAction: UseFormAfterSubmitAction<ScoLicenceDetails & { banConfirmation?: boolean }>,
            item: ScoLicenceDetails,
            acronym: string
        ) =>
            showConfirmationDialog({
                header: <DialogHeader title={"scoLicence.detail.banSco_dialogTitle"} variant={"warning"} />,
                content: (
                    <DialogContent>
                        <p>
                            {translation({
                                key: "scoLicence.detail.banSco_dialogText",
                                options: { acronym },
                            })}
                        </p>
                    </DialogContent>
                ),
                footer: (
                    <DialogFooter>
                        <DialogOptions
                            options={[
                                { label: "components.dialog.cancel_button", onClick: closeDialog },
                                {
                                    label: "components.dialog.ok_button",
                                    onClick: () => {
                                        retrySaveAction({
                                            ...item,
                                            banConfirmation: true,
                                        });
                                        closeDialog();
                                    },
                                },
                            ]}
                        />
                    </DialogFooter>
                ),
            }),
        [closeDialog, showConfirmationDialog, translation]
    );
}
