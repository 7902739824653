import { useHelpContext } from "../help/context-sensitivity/useHelpContext";
import EntityListView from "../components/entity/EntityListView";
import useSubcontractingRelationListView from "./useSubcontractingRelationListView";

export default function SubcontractingRelationListView() {
    const subcontractingRelationGridOptions = useSubcontractingRelationListView();
    useHelpContext("subcontracting-relation-list");

    return (
        <EntityListView heading="subcontractingRelation.list.header" gridOptions={subcontractingRelationGridOptions} />
    );
}
