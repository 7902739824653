import { Dialog } from "../../components/dialog/useDialog";
import "./acceptTermsOfUseDialog.css";
import DialogHeader from "../../components/dialog/DialogHeader";
import DialogContent from "../../components/dialog/DialogContent";
import LoadingSpinner from "../../components/LoadingSpinner";
import MarkdownContainer from "../../help/markdown/MarkdownContainer";
import useLanguage from "../../i18n/LanguageContextProvider";
import useGtbTranslation, { I18nKey } from "../../i18n/useGtbTranslation";
import { useCallback, useEffect, useState } from "react";
import { backendUrlType, useMutation, useQuery } from "../../hooks/useAxios";
import { LabeledCheckbox } from "../../components/Input/Checkbox";
import GtbButton from "../../components/GtbButton";
import DialogFooter from "../../components/dialog/DialogFooter";
import useAuth from "../../auth/useAuth";
import { error } from "../../utils/notification/notification";

export default function AcceptTermsOfUseDialog({
    title,
    closeDialog,
    acceptTouUrl,
    touTextUrl,
    onDecline,
}: {
    title: I18nKey;
    closeDialog: Function;
    acceptTouUrl: backendUrlType;
    touTextUrl: backendUrlType;
    onDecline: Function;
}) {
    const currentLanguage = useLanguage();
    const { reloadCurrentUser } = useAuth();
    const translation = useGtbTranslation();
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

    const { isLoading: isUpdatingToU, runQuery: updateToU } = useMutation({
        method: "put",
        url: acceptTouUrl,
    });

    const {
        data,
        isLoading: isToULoading,
        runQuery: fetchToU,
    } = useQuery<string>({ url: `${touTextUrl}?lang=${currentLanguage}`, enabled: false });

    const onLoadingError = useCallback(() => {
        onDecline();
        error(translation("error.misc.generic_error"));
    }, [onDecline, translation]);

    const handleAccept = useCallback(() => {
        updateToU({ body: { accepted: true } })
            .then(() => {
                closeDialog();
                reloadCurrentUser();
            })
            .catch(onLoadingError);
    }, [closeDialog, onLoadingError, reloadCurrentUser, updateToU]);

    useEffect(() => {
        fetchToU().catch(onLoadingError);
    }, [fetchToU, onLoadingError]);

    return (
        <Dialog
            dialog={{
                className: "terms-of-use-dialog",
                header: <DialogHeader title={title} variant={"info"} />,
                content: (
                    <DialogContent className={"accept-terms-of-use-dialog-content"}>
                        {isUpdatingToU || isToULoading ? (
                            <LoadingSpinner />
                        ) : (
                            <MarkdownContainer content={data ?? ""} />
                        )}
                    </DialogContent>
                ),
                footer: (
                    <DialogFooter>
                        <LabeledCheckbox
                            disabled={isUpdatingToU || isToULoading}
                            id={"terms-of-use-accepted-checkbox"}
                            className={"terms-of-use-accepted-checkbox"}
                            checked={isCheckboxChecked}
                            label={"components.acceptedToUDialog.accept_checkbox"}
                            onChange={(event) => setIsCheckboxChecked(event.target.checked)}
                        />
                        <GtbButton
                            disabled={!isCheckboxChecked || isUpdatingToU || isToULoading}
                            className={"terms-of-use-ok"}
                            variant={"secondary"}
                            onClick={handleAccept}
                        >
                            {translation("components.acceptedToUDialog.accept_button")}
                        </GtbButton>
                        <GtbButton
                            disabled={isUpdatingToU || isToULoading}
                            className={"terms-of-use-cancel"}
                            variant={"secondary"}
                            onClick={() => onDecline()}
                        >
                            {translation("components.acceptedToUDialog.decline_button")}
                        </GtbButton>
                    </DialogFooter>
                ),
            }}
        />
    );
}
