import { useMemo } from "react";
import { EventMonitorSummary } from "./eventMonitorTypes";
import { buildBooleanColumn, buildNumberColumn, buildTextColumn } from "../components/grid/ColumnProvider";
import useListView from "../hooks/useListView";
import { useShowDetailsAction } from "../utils/defaultActions";
import { GridColumn } from "../components/grid/component/Grid";
import useResolvedRoute from "../components/routing/useResolvedRoute";
import { EventMonitorGridProps } from "./EventMonitorListView";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";

const columns: GridColumn<EventMonitorSummary>[] = [
    buildTextColumn({ key: "eventType", title: "eventMonitor.list.eventType_column", minWidth: 137 }),
    buildTextColumn({ key: "name", title: "eventMonitor.list.name_column", minWidth: 123 }),
    buildTextColumn({ key: "description", title: "eventMonitor.list.description_column", minWidth: 145 }),
    buildNumberColumn({
        key: "signallingThreshold",
        title: "eventMonitor.list.signallingThreshold_column",
        minWidth: 210,
    }),
    buildNumberColumn({
        key: "observationFrameInSeconds",
        title: "eventMonitor.list.observationFrame_column",
        minWidth: 222,
    }),
    buildBooleanColumn({ key: "active", title: "eventMonitor.list.active_column", minWidth: 105 }),
];

function useEventMonitorListView() {
    const { getResolvedEditUrl } = useResolvedRoute();
    const {
        queryBuilder: { query, columnOptions, queryCommands },
    } = useListView<EventMonitorSummary>({
        navigationContextLabel: "eventMonitor.breadcrumb",
        baseQuery: "/log-service/event-monitors",
        defaultSortOption: {
            field: "name",
            direction: "ASC",
        },
    });

    const showDetailsAction = useShowDetailsAction(
        (item: EventMonitorSummary) => getResolvedEditUrl("eventMonitor", item.id),
        query.parameter
    );

    const contextMenuActions = useGridRowContextMenuActions(showDetailsAction);

    return useMemo<EventMonitorGridProps>(() => {
        return {
            query,
            columns,
            columnOptions,
            onItemDoubleClick: showDetailsAction,
            createUrl: undefined,
            tableName: "eventMonitor",
            itemActions: contextMenuActions,
            queryCommands,
        };
    }, [query, columnOptions, showDetailsAction, contextMenuActions, queryCommands]);
}

export default useEventMonitorListView;
