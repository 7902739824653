import { ReactNode } from "react";
import useComponentHasChildren from "../../hooks/useComponentHasChildren";

export default function DropdownGroup({ children }: { children: ReactNode }) {
    const hasChildren = useComponentHasChildren(children);

    return hasChildren ? (
        <>
            {children}
            <hr />
        </>
    ) : null;
}
