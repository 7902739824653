import InputSourceSelectionList, {
    InputSourceSelectionListProps,
} from "../../../../../../../components/grid/InputSourceSelectionList";
import { TcProductReference } from "../../../../tcProductTypes";
import { GridColumn } from "../../../../../../../components/grid/component/Grid";
import { buildNumberColumn, buildTextColumn } from "../../../../../../../components/grid/ColumnProvider";

export default function TcProductInputSourceSelectionList({
    ...rest
}: Pick<InputSourceSelectionListProps<TcProductReference>, "className" | "onItemClick" | "selectedItemId">) {
    return (
        <InputSourceSelectionList<TcProductReference>
            tableName="tcProductInputSources"
            url="/certificate-service/references/input-sources/tc-products"
            columns={tcProductInputSourceColumns}
            sortField="tcProductNumber"
            {...rest}
        />
    );
}

export const tcProductInputSourceColumns: GridColumn<TcProductReference>[] = [
    buildTextColumn({ key: "tcProductNumber", title: "tcProduct.list.tcProductNumber_column", minWidth: 180 }),
    buildTextColumn({
        key: "relatedScProductProductCategory",
        title: "tcProduct.list.productCategory_column",
        minWidth: 188,
    }),
    buildTextColumn({
        key: "relatedScProductProductCategoryCode",
        title: "tcProduct.list.productCategoryCode_column",
        minWidth: 123,
    }),
    buildTextColumn({
        key: "relatedScProductProductDetail",
        title: "tcProduct.list.productDetail_column",
        minWidth: 163,
    }),
    buildTextColumn({
        key: "relatedScProductProductDetailCode",
        title: "tcProduct.list.productDetailCode_column",
        minWidth: 123,
    }),
    buildTextColumn({
        key: "relatedScProductLabelGradeLabelGrade",
        title: "tcProduct.list.labelGrade_column",
        minWidth: 146,
    }),
    buildTextColumn({
        key: "inputSourceMaterialComposition",
        title: "tcProduct.list.materialComposition_column",
        minWidth: 217,
        disableSorting: true,
        disableFiltering: true,
    }),
    buildNumberColumn({
        key: "availableQuantity",
        title: "tcProduct.list.availableQuantity_column",
        fixedDecimal: true,
        minWidth: 227,
    }),
];
