import { ChangeEventHandler, forwardRef } from "react";
import { joinClassNames } from "../../../utils/StringUtils";
import "./radiobutton.css";

export interface GtbRadiobuttonProps {
    id: string;
    label: string;
    name: string;
    className?: string;
    readOnly?: boolean;
    value: any;
    onChange?: ChangeEventHandler<HTMLInputElement>;
}

const Radiobutton = forwardRef<HTMLInputElement, GtbRadiobuttonProps>(
    ({ id, label, readOnly, className, ...rest }, ref) => {
        return (
            <label htmlFor={id} className={joinClassNames("radio-label-container", className)}>
                <fieldset disabled={readOnly} className="radio-container" role="presentation">
                    <input id={id} type={"radio"} ref={ref} {...rest} />
                    <svg aria-hidden="true" className="radio-styled" width="12" height="12" viewBox="0 0 12 12">
                        <circle cx="6" cy="6" r="6" />
                    </svg>
                </fieldset>
                {label}
            </label>
        );
    }
);

export default Radiobutton;
