import Drawer, { DrawerProps } from "../../components/drawer/Drawer";
import { changeDashboardSettingsFunc, DashboardSettings, TilePreferenceProps } from "./useDashboard";
import { useCallback, useState } from "react";
import Toggle from "../../components/Input/Toggle";
import DashboardIcon from "./DashboardIcon";
import DrawerCard from "../../components/drawer/DrawerCard";
import { LabeledCheckbox } from "../../components/Input/Checkbox";
import "./dashboardSettingsDrawer.css";
import { I18nKey } from "../../i18n/useGtbTranslation";

export interface DashboardSettingsDrawerProps {
    closeDrawer: DrawerProps["closeDrawer"];
    dashboardSettings: DashboardSettings;
    changeDashboardSettings: changeDashboardSettingsFunc;
}

export default function DashboardSettingsDrawer({
    closeDrawer,
    dashboardSettings,
    changeDashboardSettings,
}: DashboardSettingsDrawerProps) {
    const [changedDashboardSettings, setChangedDashboardSettings] = useState<{
        on: boolean;
        tilesWithChangedVisibility: I18nKey[];
    }>({
        on: dashboardSettings.on,
        tilesWithChangedVisibility: [],
    });

    const changeVisibility = useCallback(
        (item: TilePreferenceProps) =>
            setChangedDashboardSettings((prevState) => {
                const oldTiles = [...prevState.tilesWithChangedVisibility];
                const itemIndex = oldTiles.indexOf(item.title);
                if (itemIndex >= 0) {
                    oldTiles.splice(itemIndex, 1);
                    return {
                        on: true,
                        tilesWithChangedVisibility: oldTiles,
                    };
                }
                return {
                    ...prevState,
                    tilesWithChangedVisibility: [...oldTiles, item.title],
                };
            }),
        []
    );

    return (
        <Drawer
            title="dashboard.dashboardSettings_header"
            closeDrawer={() => {
                changeDashboardSettings(changedDashboardSettings);
                closeDrawer();
            }}
            className="dashboardDrawer"
        >
            <LabeledCheckbox
                id="dashboardOn"
                label="dashboard.dashboardActive_input"
                checked={changedDashboardSettings.on}
                onChange={(event) =>
                    setChangedDashboardSettings((prevState) => {
                        return {
                            ...prevState,
                            on: event.target.checked,
                        };
                    })
                }
            />
            {dashboardSettings.tiles.map((tile) => (
                <DrawerCard
                    key={tile.title as string}
                    className={changedDashboardSettings.on ? undefined : "disabledDashboardSettings"}
                >
                    <DashboardIcon icon={tile.icon} />
                    <Toggle
                        label={tile.title}
                        id={`tile-${tile.title}`}
                        disabled={!changedDashboardSettings.on}
                        checked={
                            tile.hidden === changedDashboardSettings.tilesWithChangedVisibility?.includes(tile.title)
                        }
                        onChange={() => changeVisibility(tile)}
                    />
                </DrawerCard>
            ))}
        </Drawer>
    );
}
