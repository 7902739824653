import UserDetailView from "./UserDetailView";
import useUserCreate from "./useUserCreate";
import { useHelpContext } from "../../help/context-sensitivity/useHelpContext";

function UserCreateView() {
    const userDetailViewProps = useUserCreate();
    useHelpContext("user-details");
    return <UserDetailView {...userDetailViewProps} />;
}

export default UserCreateView;
