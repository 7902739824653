import { TypedPicklist } from "./typedPicklistType";
import DataPickList from "../DataPickList";
import { joinClassNames } from "../../../../utils/StringUtils";
import "./typedPickList.css";
import { getStandardVersionName, VersionReference } from "../../../../standard/version/versionTypes";
import { FieldValues } from "react-hook-form/dist/types";

interface StandardVersionPickListProps<FormItemType extends FieldValues>
    extends TypedPicklist<FormItemType, VersionReference> {
    standardId?: string;
}

export default function StandardVersionPickList<FormItemType extends FieldValues>({
    className,
    standardId,
    ...rest
}: StandardVersionPickListProps<FormItemType>) {
    return (
        <DataPickList
            className={joinClassNames("standardVersionPickList", className)}
            itemId="id"
            itemLabel={(item) => getStandardVersionName(item)}
            queryUrl={
                standardId
                    ? `/certificate-service/references/standards/${standardId}/standard-versions`
                    : "/certificate-service/references/standard-versions"
            }
            {...rest}
        />
    );
}
