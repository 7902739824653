import ExternalView from "../external/ExternalView";
import ResetPasswordForm from "./ResetPasswordForm";

function ResetPasswordView() {
    return (
        <ExternalView header="loginPage.reset_password_header">
            <ResetPasswordForm />
        </ExternalView>
    );
}

export default ResetPasswordView;
