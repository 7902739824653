import useIsAuthorised from "../../../../../auth/useIsAuthorised";
import useResolvedRoute from "../../../../../components/routing/useResolvedRoute";
import { SortOption, useQueryBuilder } from "../../../../../components/grid/component/useQueryBuilder";
import { useDeleteAction, useShowDetailsAction } from "../../../../../utils/defaultActions";
import { useMemo } from "react";
import { SubListProps } from "../../../../../components/grid/SubList";
import { GridColumn } from "../../../../../components/grid/component/Grid";
import { buildNumberColumn, buildTextColumn } from "../../../../../components/grid/ColumnProvider";
import { ScMaterialDetails, ScMaterialSummary } from "./scMaterialTypes";
import { scopeCertificateReadOnlyByStatus } from "../../../../scopeCertificateTypes";
import { scopeCertificateStatusType } from "../../../../../auth/roleTypes";
import useGridRowContextMenuActions from "../../../../../components/grid/component/cell/useGridRowContextMenuActions";
import { insertIf } from "../../../../../utils/arrayUtils";

export const defaultScMaterialSortOption: SortOption<ScMaterialSummary | ScMaterialDetails> = {
    field: "materialName",
    direction: "ASC",
};

const columns: GridColumn<ScMaterialSummary>[] = [
    buildTextColumn({ key: "materialName", title: "scMaterial.list.material_column", minWidth: 155 }),
    buildNumberColumn({
        key: "minPercentage",
        title: "scMaterial.list.minPercentage_column",
        minWidth: 129,
    }),
    buildNumberColumn({
        key: "maxPercentage",
        title: "scMaterial.list.maxPercentage_column",
        minWidth: 133,
    }),
    buildTextColumn({
        key: "materialPrint",
        title: "scMaterial.list.materialPrint_column",
        minWidth: 190,
        visible: false,
    }),
];

export default function useScMaterialSubList(scopeCertificateStatus?: scopeCertificateStatusType) {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedEditUrl, getResolvedCreateUrl, getResolvedId } = useResolvedRoute();
    const scopeCertificateId = getResolvedId("scopeCertificate");
    const scProductId = getResolvedId("scProduct");

    const { query, columnOptions, forceReload } = useQueryBuilder<ScMaterialSummary>(
        `/certificate-service/scope-certificates/${scopeCertificateId}/sc-products/${scProductId}/sc-materials`,
        {
            sort: defaultScMaterialSortOption,
            filterActive: false,
        },
    );

    const showDetailsAction = useShowDetailsAction(
        (item: ScMaterialSummary) => getResolvedEditUrl("scMaterial", item.id),
        query.parameter,
    );

    const deleteAction = useDeleteAction(
        (item: ScMaterialSummary) =>
            `/certificate-service/scope-certificates/${scopeCertificateId}/sc-products/${scProductId}/sc-materials/${item.id}`,
        () => forceReload(),
        "updateScopeCertificate",
        scopeCertificateStatus ? [scopeCertificateStatus] : [],
    );

    const isReadOnlyByScopeCertificateStatus = useMemo<boolean>(
        () => scopeCertificateReadOnlyByStatus(scopeCertificateStatus),
        [scopeCertificateStatus],
    );

    const contextMenuActions = useGridRowContextMenuActions(
        showDetailsAction,
        ...insertIf(!isReadOnlyByScopeCertificateStatus, deleteAction),
    );

    return useMemo<SubListProps<ScMaterialSummary>>(
        () => ({
            query,
            columns,
            uniqueDataKey: "id",
            onItemDoubleClick: showDetailsAction,
            header: "scMaterial.list.header",
            itemActions: contextMenuActions,
            columnOptions,
            createUrl:
                scopeCertificateStatus &&
                hasAccessRight("updateScopeCertificate", [scopeCertificateStatus]) &&
                !isReadOnlyByScopeCertificateStatus
                    ? getResolvedCreateUrl("scMaterial")
                    : undefined,
            tableName: "scMaterials",
        }),
        [
            columnOptions,
            contextMenuActions,
            getResolvedCreateUrl,
            hasAccessRight,
            isReadOnlyByScopeCertificateStatus,
            query,
            scopeCertificateStatus,
            showDetailsAction,
        ],
    );
}
