/**
 * <strong>IMPORTANT: Changes to this file have to be replicated to
 * </strong>
 * <ul>
 * <li>/global-trace-base/.docker/nginx/error.html - Only for local development tests with nginx</li>
 * <li>https://appserver.volavis.de/git/gots/gtb-operations/-/tree/master/environments/gtb.volavis.de/error.html</li>
 * <li>https://appserver.volavis.de/git/gots/gtb-operations/-/tree/master/environments/staging/api-gateway/error.html</li>
 * </ul>
 */
export default function ServiceUnavailableErrorPage() {
    return (
        <div style={{ display: "flex", justifyContent: "center", fontFamily: "Arial, sans-serif" }}>
            <div>
                <h1>Error 503</h1>
                <p>Global Trace-Base is currently unavailable. Please try again later.</p>
            </div>
        </div>
    );
}
