import useIsAuthorised from "../../../../../../auth/useIsAuthorised";
import { useCallback, useMemo, useState } from "react";
import useDomainEntityDetailsEdit from "../../../../../../hooks/details/useDomainEntityDetailsEdit";
import useResolvedRoute from "../../../../../../components/routing/useResolvedRoute";
import { ScMaterialDetails } from "../scMaterialTypes";
import { defaultScMaterialSortOption } from "../useScMaterialSubList";
import { ScMaterialDetailViewProps } from "./ScMaterialDetailView";
import { yupResolver } from "@hookform/resolvers/yup";
import { scMaterialValidationSchema } from "./scMaterialValidationSchema";
import { validateString } from "../../../../../../utils/yupExtension";
import useScMaterialMaterialPrintVisibility from "./useScMaterialPrintVisibility";
import { scopeCertificateReadOnlyByStatus } from "../../../../../scopeCertificateTypes";
import { scopeCertificateStatusType } from "../../../../../../auth/roleTypes";

export default function useScMaterialEdit(): ScMaterialDetailViewProps {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedId } = useResolvedRoute();
    const scopeCertificateId = getResolvedId("scopeCertificate")!;
    const scProductId = getResolvedId("scProduct")!;
    const scMaterialId = getResolvedId("scMaterial");
    const [isMaterialPrintVisible, setIsMaterialPrintVisible] = useState(false);

    const canSave = useCallback(
        (item: ScMaterialDetails) => {
            if (item) {
                return (
                    hasAccessRight("updateScopeCertificate", [item.scProductScopeCertificateStatus]) &&
                    !scopeCertificateReadOnlyByStatus(item.scProductScopeCertificateStatus)
                );
            }
            return false;
        },
        [hasAccessRight]
    );

    const { detailViewProps, form, isReadOnly, title, recordNavigationProps, initialData } =
        useDomainEntityDetailsEdit<ScMaterialDetails>({
            baseUrl: `/certificate-service/scope-certificates/${scopeCertificateId}/sc-products/${scProductId}/sc-materials/${scMaterialId}`,
            frontendRoute: "scMaterial",
            recordNavigationDefaultSort: defaultScMaterialSortOption,
            titleBuilder: (item) => item.materialName,
            resolver: yupResolver(
                scMaterialValidationSchema.shape({
                    materialPrint: validateString().when([], {
                        is: () => isMaterialPrintVisible,
                        then: validateString().isRequired().hasMaxLength(50),
                    }),
                })
            ),
            canSave,
            historyColumns: [
                { identifier: "material", title: "scMaterial.history.material_column" },
                { identifier: "materialPrint", title: "scMaterial.history.materialPrint_column" },
                { identifier: "minPercentage", title: "scMaterial.history.minPercentage_column" },
                { identifier: "maxPercentage", title: "scMaterial.history.maxPercentage_column" },
            ],
        });

    const isMaterialLoading = useScMaterialMaterialPrintVisibility(form, setIsMaterialPrintVisible);

    const scopeCertificateStatus = useMemo<scopeCertificateStatusType | undefined>(
        () => initialData?.scProductScopeCertificateStatus,
        [initialData?.scProductScopeCertificateStatus]
    );

    const isReadOnlyByScopeCertificateStatus = useMemo<boolean>(
        () => scopeCertificateReadOnlyByStatus(scopeCertificateStatus),
        [scopeCertificateStatus]
    );

    return {
        detailViewProps: { ...detailViewProps, isLoading: detailViewProps.isLoading },
        form,
        readOnly: isReadOnly || isReadOnlyByScopeCertificateStatus,
        title,
        recordNavigationProps,
        createRoute:
            scopeCertificateStatus && hasAccessRight("updateScopeCertificate", [scopeCertificateStatus])
                ? "scMaterial"
                : undefined,
        materialPrintVisible: isMaterialPrintVisible && !isMaterialLoading,
        scopeCertificateId,
    };
}
