import useIsAuthorised from "../../../auth/useIsAuthorised";
import MainMenuItem from "../MainMenuItem";
import DropdownLinkItem from "../../../components/dropdown/DropdownLinkItem";
import { getBaseUrl } from "../../../components/routing/useResolvedRoute";

export default function TransactionCertificateMenu() {
    const { hasAccessRight } = useIsAuthorised();

    return (
        <MainMenuItem name="mainLayout.mainMenu.transactionCertificates_menuTitle">
            {hasAccessRight("readTransactionCertificate") && (
                <>
                    <DropdownLinkItem
                        label="mainLayout.mainMenu.transactionCertificates_menuEntries.all_button"
                        to={getBaseUrl("transactionCertificate")}
                    />
                    {hasAccessRight("readTransactionCertificate", ["DRAFT", "REJECTED"]) && (
                        <DropdownLinkItem
                            label="mainLayout.mainMenu.transactionCertificates_menuEntries.inPreparation_button"
                            to={getBaseUrl("searchInPreparationTransactionCertificate")}
                        />
                    )}
                    {hasAccessRight("readTransactionCertificate", ["AWAITING_APPROVAL"]) && (
                        <DropdownLinkItem
                            label="mainLayout.mainMenu.transactionCertificates_menuEntries.awaitingApproval_button"
                            to={getBaseUrl("searchAwaitingApprovalTransactionCertificate")}
                        />
                    )}
                    {hasAccessRight("readTransactionCertificate", ["VALID"]) && (
                        <DropdownLinkItem
                            label="mainLayout.mainMenu.transactionCertificates_menuEntries.valid_button"
                            to={getBaseUrl("searchValidTransactionCertificate")}
                        />
                    )}
                    {hasAccessRight("readTransactionCertificate", ["WITHDRAWN"]) && (
                        <DropdownLinkItem
                            label="mainLayout.mainMenu.transactionCertificates_menuEntries.withdrawn_button"
                            to={getBaseUrl("searchWithdrawnTransactionCertificate")}
                        />
                    )}
                </>
            )}
        </MainMenuItem>
    );
}
