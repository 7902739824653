import SupplyChainOperatorDetailView from "./SupplyChainOperatorDetailView";
import useSupplyChainOperatorCreate from "./useSupplyChainOperatorCreate";

function SupplyChainOperatorCreateView() {
    const supplyChainOperatorDetailProps = useSupplyChainOperatorCreate();

    return <SupplyChainOperatorDetailView {...supplyChainOperatorDetailProps} />;
}

export default SupplyChainOperatorCreateView;
