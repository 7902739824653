import { I18nKey } from "../i18n/useGtbTranslation";

type conformanceCheckIssueSeverityEnum = "ERROR" | "WARNING";
type conformanceCheckIssueTypeEnum =
    | "CB_REFERENCE_NUMBER_NOT_UNIQUE"
    | "AT_LEAST_ONE_FACILITY_PER_CATEGORY"
    | "OVERLAPPING_VALIDITY_PERIODS"
    | "AT_LEAST_ONE_PRODUCT"
    | "NO_VALID_ACCREDITATION"
    | "NO_VALID_ACCREDITATION_IN_TC_CONTEXT"
    | "NO_VALID_CERTIFICATION"
    | "MATERIAL_PERCENTILES_NOT_ONE_HUNDRED"
    | "ANNIVERSARY_RULE_BROKEN"
    | "HISTORIC_SC"
    | "MATERIAL_PRINT_NOT_ALLOWED"
    | "DATES_INCONSISTENT"
    | "MAXIMUM_DURATION_EXCEEDED"
    | "AT_LEAST_ONE_PROCESS_CATEGORY"
    | "LABEL_GRADES_NOT_SAME_STANDARD_VERSION"
    | "MATERIAL_COMPOSITION_NOT_COMPLIANT_WITH_LABEL_GRADE"
    | "AT_LEAST_ONE_CERTIFIED_MATERIAL_PER_PRODUCT"
    | "REVISION_AND_GEOGRAPHIC_SCOPE"
    | "MAXIMUM_DATE"
    | "SYSTEM_ID_IS_NOT_UNIQUE"
    | "PRE_GTB_MINIMAL_STRUCTURE"
    | "PRE_GTB_TC_MATERIAL_PERCENTILES_NOT_ONE_HUNDRED"
    | "PRE_GTB_TC_PRODUCT_IDENTIFIER_IS_NOT_UNIQUE"
    | "ORGANIC_MATERIAL_PRODUCTION_DUPLICATE"
    | "ORGANIC_MATERIAL_ORIGIN_NO_EXCESS_OF_PRODUCTION_QUANTITY"
    | "ORGANIC_MATERIAL_PRODUCTION_SC_OVERLAPPING_PERIOD_OF_VALIDITY"
    | "TC_UPPER_LIMIT_FOR_SHIPMENTS_EXCEEDED"
    | "NO_OPERATIONALLY_VALID_SC"
    | "TC_PRODUCTS_REFERENCE_UNRELATED_SC_PRODUCTS"
    | "TC_UPPER_LIMIT_FOR_PRODUCTS_EXCEEDED"
    | "TC_PRODUCTS_NET_SHIPPING_WEIGHT_ZERO"
    | "TC_PERCENTAGE_OF_RAW_MATERIALS_IS_NOT_100"
    | "TC_NO_MATCHING_MATERIAL_COMPOSITION"
    | "TC_MATERIAL_COMPOSITION_NOT_COMPLIANT_WITH_LABEL_GRADE"
    | "TC_MAX_SHIPMENT_PERIOD"
    | "TC_BY_PRODUCT_QUANTITY_MISMATCH"
    | "TC_AT_LEAST_ONE_SHIPMENT"
    | "TC_AT_LEAST_ONE_PRODUCT"
    | "TC_AT_LEAST_ONE_CERTIFIED_MATERIAL_PER_PRODUCT"
    | "TC_AT_LEAST_ONE_INPUT_QUANTITY_PER_PRODUCT"
    | "TC_PRODUCTS_CERTIFIED_WEIGHT"
    | "OM_PURCHASE_MISSING_OM_ORIGIN"
    | "OM_FARM_TC_MISSING"
    | "TC_INPUT_QUANTITY_RESOURCE_AVAILABLE_QUANTITY_EXCEEDED"
    | "TC_PRODUCTS_PARTIAL_WEIGHT"
    | "TC_AT_LEAST_ONE_ORGANIC_MATERIAL_PRODUCTION_SC"
    | "STANDARD_VERSION_INACTIVE"
    | "MINIMUM_PERCENTAGE_GREATER_100"
    | "MAXIMUM_PERCENTAGE_LESS_100";

export interface ConformanceCheckIssue {
    issueType: conformanceCheckIssueTypeEnum;
    severity: conformanceCheckIssueSeverityEnum;
    detail?: string[];
}

interface ConformanceCheckIssueMessage {
    severity: conformanceCheckIssueSeverityEnum;
    message: I18nKey;
}

export interface ConformanceCheckIssueMessages {
    messages: ConformanceCheckIssueMessage[];
    containsErrors: boolean;
}

export const getConformanceCheckIssueMessages = (issues: ConformanceCheckIssue[]): ConformanceCheckIssueMessages => {
    let containsErrors = false;
    const messages = issues.map((item) => {
        if (item.severity === "ERROR") {
            containsErrors = true;
        }
        return convertToConformanceCheckIssueMessage(item);
    });

    return { messages, containsErrors };
};

const convertToConformanceCheckIssueMessage = (issue: ConformanceCheckIssue): ConformanceCheckIssueMessage => {
    let options = {};
    if (issue.detail) {
        issue.detail.forEach((singleDetail, index) => {
            const optionName = "detail" + index;
            options = { ...options, [optionName]: singleDetail };
        });
    }
    return {
        severity: issue.severity,
        message: {
            key: conformanceCheckIssues[issue.issueType],
            options: options,
        } as I18nKey,
    };
};

const conformanceCheckIssues: Record<conformanceCheckIssueTypeEnum, I18nKey> = {
    CB_REFERENCE_NUMBER_NOT_UNIQUE:
        "components.detailView.conformanceCheckDialog.messages.cbReferenceNumberNotUnique_message",
    AT_LEAST_ONE_FACILITY_PER_CATEGORY:
        "components.detailView.conformanceCheckDialog.messages.atLeastOneFacility_message",
    OVERLAPPING_VALIDITY_PERIODS:
        "components.detailView.conformanceCheckDialog.messages.overlappingValidityPeriods_message",
    AT_LEAST_ONE_PRODUCT: "components.detailView.conformanceCheckDialog.messages.atLeastOneProduct_message",
    NO_VALID_ACCREDITATION: "components.detailView.conformanceCheckDialog.messages.noValidAccreditation_message",
    NO_VALID_ACCREDITATION_IN_TC_CONTEXT:
        "components.detailView.conformanceCheckDialog.messages.noValidAccreditationForTc_message",
    NO_VALID_CERTIFICATION: "components.detailView.conformanceCheckDialog.messages.noValidCertification_message",
    MATERIAL_PERCENTILES_NOT_ONE_HUNDRED:
        "components.detailView.conformanceCheckDialog.messages.materialPercentilesNotOneHundred_message",
    ANNIVERSARY_RULE_BROKEN: "components.detailView.conformanceCheckDialog.messages.anniversaryRuleBroken_message",
    HISTORIC_SC: "components.detailView.conformanceCheckDialog.messages.historicSc_message",
    MATERIAL_PRINT_NOT_ALLOWED: "components.detailView.conformanceCheckDialog.messages.materialPrintNotAllowed_message",
    DATES_INCONSISTENT: "components.detailView.conformanceCheckDialog.messages.datesInconsistent_message",
    MAXIMUM_DURATION_EXCEEDED: "components.detailView.conformanceCheckDialog.messages.maximumDurationExceeded_message",
    AT_LEAST_ONE_PROCESS_CATEGORY:
        "components.detailView.conformanceCheckDialog.messages.atLeastOneProcessCategory_message",
    LABEL_GRADES_NOT_SAME_STANDARD_VERSION:
        "components.detailView.conformanceCheckDialog.messages.labelGradesNotSameStandardVersion_message",
    MATERIAL_COMPOSITION_NOT_COMPLIANT_WITH_LABEL_GRADE:
        "components.detailView.conformanceCheckDialog.messages.materialCompositionNotCompliantWithLabelGrade_message",
    AT_LEAST_ONE_CERTIFIED_MATERIAL_PER_PRODUCT:
        "components.detailView.conformanceCheckDialog.messages.atLeastOneCertifiedMaterialPerProduct_message",
    REVISION_AND_GEOGRAPHIC_SCOPE:
        "components.detailView.conformanceCheckDialog.messages.revisionAndGeographicScope_message",
    MAXIMUM_DATE: "components.detailView.conformanceCheckDialog.messages.maximumDate_message",
    SYSTEM_ID_IS_NOT_UNIQUE: "components.detailView.conformanceCheckDialog.messages.systemIdIsNotUnique_message",
    PRE_GTB_MINIMAL_STRUCTURE:
        "components.detailView.conformanceCheckDialog.messages.pre_gtb_minimal_structure_message",
    PRE_GTB_TC_MATERIAL_PERCENTILES_NOT_ONE_HUNDRED:
        "components.detailView.conformanceCheckDialog.messages.preGtbTcMaterialPercentilesNotOneHundred_message",
    PRE_GTB_TC_PRODUCT_IDENTIFIER_IS_NOT_UNIQUE:
        "components.detailView.conformanceCheckDialog.messages.preGtbTcProductIdentifierIsNotUnique_message",
    ORGANIC_MATERIAL_PRODUCTION_DUPLICATE:
        "components.detailView.conformanceCheckDialog.messages.organicMaterialProductionDuplicate_message",
    ORGANIC_MATERIAL_PRODUCTION_SC_OVERLAPPING_PERIOD_OF_VALIDITY:
        "components.detailView.conformanceCheckDialog.messages.organicMaterialProductionScOverlappingValidity_message",
    ORGANIC_MATERIAL_ORIGIN_NO_EXCESS_OF_PRODUCTION_QUANTITY:
        "components.detailView.conformanceCheckDialog.messages.organicMaterialOriginNoExcessOfProductionQuantity_message",
    TC_UPPER_LIMIT_FOR_SHIPMENTS_EXCEEDED:
        "components.detailView.conformanceCheckDialog.messages.tcUpperLimitForShipments_message",
    TC_PRODUCTS_REFERENCE_UNRELATED_SC_PRODUCTS:
        "components.detailView.conformanceCheckDialog.messages.tcProductReferenceUnrelatedScProducts_message",
    TC_UPPER_LIMIT_FOR_PRODUCTS_EXCEEDED:
        "components.detailView.conformanceCheckDialog.messages.tcUpperLimitForProducts_message",
    TC_PERCENTAGE_OF_RAW_MATERIALS_IS_NOT_100:
        "components.detailView.conformanceCheckDialog.messages.tcPercentageOfRawMaterialsIsNot100_message",
    TC_PRODUCTS_NET_SHIPPING_WEIGHT_ZERO:
        "components.detailView.conformanceCheckDialog.messages.tcProductNetShippingWeightZero_message",
    NO_OPERATIONALLY_VALID_SC: "components.detailView.conformanceCheckDialog.messages.tcNoOperationallyValidSc_message",
    TC_NO_MATCHING_MATERIAL_COMPOSITION:
        "components.detailView.conformanceCheckDialog.messages.tcNoMatchingMaterialComposition_message",
    TC_MATERIAL_COMPOSITION_NOT_COMPLIANT_WITH_LABEL_GRADE:
        "components.detailView.conformanceCheckDialog.messages.tcMaterialCompositionNotCompliant_message",
    TC_MAX_SHIPMENT_PERIOD: "components.detailView.conformanceCheckDialog.messages.tcMaxShipmentPeriod_message",
    TC_BY_PRODUCT_QUANTITY_MISMATCH:
        "components.detailView.conformanceCheckDialog.messages.tcByProductQuantityMismatch_message",
    TC_AT_LEAST_ONE_SHIPMENT: "components.detailView.conformanceCheckDialog.messages.tcAtLeastOneShipment_message",
    TC_AT_LEAST_ONE_PRODUCT: "components.detailView.conformanceCheckDialog.messages.tcAtLeastOneProduct_message",
    TC_AT_LEAST_ONE_CERTIFIED_MATERIAL_PER_PRODUCT:
        "components.detailView.conformanceCheckDialog.messages.tcAtLeastOneCertifiedMaterialPerProduct",
    TC_AT_LEAST_ONE_INPUT_QUANTITY_PER_PRODUCT:
        "components.detailView.conformanceCheckDialog.messages.tcAtLeastOneInputQuantityPerProduct",
    TC_PRODUCTS_CERTIFIED_WEIGHT: "components.detailView.conformanceCheckDialog.messages.tcProductsCertifiedWeight",
    OM_PURCHASE_MISSING_OM_ORIGIN:
        "components.detailView.conformanceCheckDialog.messages.omPurchaseMissingOmOrigin_message",
    OM_FARM_TC_MISSING: "components.detailView.conformanceCheckDialog.messages.omFarmTcMissing_message",
    TC_INPUT_QUANTITY_RESOURCE_AVAILABLE_QUANTITY_EXCEEDED:
        "components.detailView.conformanceCheckDialog.messages.tcInputQuantityExceedsAvailableQuantity_message",
    TC_PRODUCTS_PARTIAL_WEIGHT: "components.detailView.conformanceCheckDialog.messages.tcPartialWeightExceeded_message",
    TC_AT_LEAST_ONE_ORGANIC_MATERIAL_PRODUCTION_SC:
        "components.detailView.conformanceCheckDialog.messages.tcAtLeastOneOrganicMaterialProductionSc_message",
    STANDARD_VERSION_INACTIVE: "components.detailView.conformanceCheckDialog.messages.standardVersionInactive_message",
    MINIMUM_PERCENTAGE_GREATER_100: "components.detailView.conformanceCheckDialog.messages.minimumPercentage_message",
    MAXIMUM_PERCENTAGE_LESS_100: "components.detailView.conformanceCheckDialog.messages.maximumPercentage_message",
};
