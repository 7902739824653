import useDashboard from "./dashboard/useDashboard";
import LoadingSpinner from "../components/LoadingSpinner";
import { useMemo } from "react";
import Dashboard from "./dashboard/Dashboard";
import HomePage from "./HomePage";
import "./homeview.css";

export default function HomeView() {
    const { dashboardSettings, setTilePosition, maxPosition, showDashboardSettingsDrawer, isLoading } = useDashboard();

    const viewStatus = useMemo(() => {
        if (isLoading) {
            return "loading";
        }
        return dashboardSettings.on ? "dashboard" : "homepage";
    }, [dashboardSettings.on, isLoading]);

    if (viewStatus === "loading") {
        return <LoadingSpinner />;
    }
    if (viewStatus === "dashboard") {
        return (
            <Dashboard
                maxPosition={maxPosition}
                tiles={dashboardSettings.tiles}
                setTilePosition={setTilePosition}
                showDashboardSettingsDrawer={showDashboardSettingsDrawer}
            />
        );
    }
    return <HomePage showDashboardSettingsDrawer={showDashboardSettingsDrawer} />;
}
