import { buildSchema, requiredObject, validateDate, validateString } from "../../utils/yupExtension";
import { OrganicMaterialProductionScDetails } from "../organicMaterialProductionScTypes";

export const organicMaterialProductionScValidationSchema = buildSchema<OrganicMaterialProductionScDetails>({
    scNumber: validateString().isRequired().hasMaxLength(150),
    issuingBody: validateString().isRequired().hasMaxLength(80),
    standard: validateString().isRequired(),
    organicMaterialProducer: validateString().isRequired(),
    validFrom: validateDate().isRequired(),
    validUntil: validateDate()
        .isRequired()
        .isAfterInclusive("validFrom", "organicMaterialProductionSc.detail.validFrom_input"),
    scCopy: requiredObject(),
});
