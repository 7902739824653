import { useHelpContext } from "../help/context-sensitivity/useHelpContext";
import EntityListView from "../components/entity/EntityListView";
import useOrganicMaterialProductionScListView from "./useOrganicMaterialProductionScListView";

export default function OrganicMaterialProductionScListView() {
    const organicMaterialProductionScGridOptions = useOrganicMaterialProductionScListView();

    useHelpContext("organic-material-production-sc-list");

    return (
        <EntityListView
            heading="organicMaterialProductionSc.list.header"
            gridOptions={organicMaterialProductionScGridOptions}
        />
    );
}
