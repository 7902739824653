import DropdownItem, { DropdownItemProps } from "./DropdownItem";
import useGtbTranslation, { I18nKey } from "../../i18n/useGtbTranslation";

export interface I18nDropdownItemProps extends Omit<DropdownItemProps, "children"> {
    label: I18nKey;
}

export default function I18nDropdownItem({ label, ...rest }: I18nDropdownItemProps) {
    const translation = useGtbTranslation();

    return <DropdownItem {...rest}>{translation(label)}</DropdownItem>;
}
