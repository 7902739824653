import { Control, Controller } from "react-hook-form";
import GtbDatePicker, { GtbDatePickerProps } from "./GtbDatePicker";
import useGtbTranslation, { I18nKey } from "../../../i18n/useGtbTranslation";

interface ControlledDatePickerProps extends Omit<GtbDatePickerProps, "value" | "onChange"> {
    control: Control<any>;
    name: string;
}

function ControlledDatePicker({ control, name, ...rest }: ControlledDatePickerProps) {
    const translation = useGtbTranslation();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <GtbDatePicker
                    value={value}
                    onChange={(newValue) => {
                        onChange({
                            target: {
                                name,
                                value: newValue,
                            },
                        });
                    }}
                    error={!!error}
                    helperText={error?.message && translation(error?.message as I18nKey)}
                    {...rest}
                />
            )}
        />
    );
}

export default ControlledDatePicker;
