import useCountryAreaListView from "./useCountryAreaListView";
import EntityListViewWithoutInfinityScroll from "../components/entity/EntityListViewExcludingInfinityScroll";
import { useHelpContext } from "../help/context-sensitivity/useHelpContext";

export default function CountryAreaListView() {
    const countryAreaGridOptions = useCountryAreaListView();
    useHelpContext("country-area-list");
    return (
        <EntityListViewWithoutInfinityScroll heading="countryArea.list.header" gridOptions={countryAreaGridOptions} />
    );
}
