import useDetailsCreate from "../../hooks/details/useDetailsCreate";
import { yupResolver } from "@hookform/resolvers/yup";
import { Nullable } from "../../utils/typeUtils";
import { useMemo } from "react";
import { TransactionCertificateDetails } from "../transactionCertificateTypes";
import { TransactionCertificateDetailViewProps } from "./TransactionCertificateDetailView";
import { transactionCertificateValidationSchema } from "./transactionCertificateValidationSchema";
import useAuth from "../../auth/useAuth";
import useResolvedRoute from "../../components/routing/useResolvedRoute";

export default function useTransactionCertificateCreate(): TransactionCertificateDetailViewProps {
    const {
        currentUser: { authorisationRole, organisation },
    } = useAuth();
    const { getResolvedId } = useResolvedRoute();

    const currentCb = useMemo(
        () => (authorisationRole.type === "CERTIFICATION_BODY" ? organisation : null),
        [authorisationRole, organisation]
    );

    const currentSco = useMemo(
        () =>
            getResolvedId("supplyChainOperator") ??
            (authorisationRole.type === "SUPPLY_CHAIN_OPERATOR" ? organisation : null),
        [authorisationRole.type, getResolvedId, organisation]
    );

    const readOnly = useMemo<TransactionCertificateDetailViewProps["readOnly"]>(
        () => ({
            certificationBody: !!currentCb,
            cbTcReferenceNumber: false,
            seller: !!currentSco,
            facilityAsSeller: false,
            buyer: false,
            facilityAsBuyer: false,
            scopeCertificate: false,
            approvingPerson: false,
            doNotDisplayLastProcessorOnPrintedTc: false,
            declarationsBySeller: false,
            status: true,
        }),
        [currentCb, currentSco]
    );

    const initialData = useMemo<Nullable<TransactionCertificateDetails>>(
        () => ({
            id: null,
            certificationBody: currentCb,
            cbTcReferenceNumber: null,
            seller: currentSco,
            facilityAsSeller: null,
            buyer: null,
            facilityAsBuyer: null,
            tcNumber: null,
            status: "DRAFT",
            scopeCertificate: null,
            dateOfIssue: null,
            placeOfIssue: null,
            approvingPerson: null,
            doNotDisplayLastProcessorOnPrintedTc: false,
            declarationsBySeller: null,
            certificateOrigin: null,
            certificateDocument: null,
            cbCertificate: null,
            documentHash: null,
            documentSize: null,
            hashAlgorithm: null,
        }),
        [currentCb, currentSco]
    );

    const { detailViewProps, form, title } = useDetailsCreate<TransactionCertificateDetails>({
        title: "transactionCertificate.detail.newRecord_title",
        frontendRoute: "transactionCertificate",
        initialData,
        resolver: yupResolver(transactionCertificateValidationSchema),
        saveUrl: "/certificate-service/transaction-certificates",
    });

    return {
        title,
        detailViewProps,
        form,
        readOnly,
        createRoute: "transactionCertificate",
        subListVisible: false,
        documentTabVisible: false,
        humanReadableDocumentSize: null,
    };
}
