import { buildTextColumn } from "../components/grid/ColumnProvider";
import { useMemo } from "react";
import useIsAuthorised from "../auth/useIsAuthorised";
import { useDeleteAction, useShowDetailsAction } from "../utils/defaultActions";
import { SortOption, useQueryBuilder } from "../components/grid/component/useQueryBuilder";
import { MaterialContentRuleDetails, MaterialContentRuleSummary } from "./materialContentRuleTypes";
import { GridColumn } from "../components/grid/component/Grid";
import useResolvedRoute from "../components/routing/useResolvedRoute";
import { SubListProps } from "../components/grid/SubList";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";

export const defaultMaterialContentRulesSortOption: SortOption<
    MaterialContentRuleSummary | MaterialContentRuleDetails
> = {
    field: "certifiedMaterial",
    direction: "ASC",
};

const columns: GridColumn<MaterialContentRuleSummary>[] = [
    buildTextColumn({ key: "certifiedMaterialCode", title: "materialContentRule.list.code_column", minWidth: 96 }),
    buildTextColumn({
        key: "certifiedMaterialName",
        title: "materialContentRule.list.certifiedMaterial_column",
        minWidth: 187,
    }),
    buildTextColumn({
        key: "additionalMaterialsSummary",
        title: "materialContentRule.list.additionalMaterials_column",
        minWidth: 209,
        disableSorting: true,
    }),
];

export default function useMaterialContentRuleSubList() {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedEditUrl, getResolvedId, getResolvedCreateUrl } = useResolvedRoute();
    const labelGradeId = getResolvedId("labelGrade");

    const { query, columnOptions, forceReload } = useQueryBuilder<MaterialContentRuleSummary>(
        `/certificate-service/material-content-rules`,
        {
            sort: defaultMaterialContentRulesSortOption,
            filterActive: false,
            labelGradeId: labelGradeId,
        }
    );

    const showDetailsAction = useShowDetailsAction(
        (item: MaterialContentRuleSummary) => getResolvedEditUrl("materialContentRule", item.id),
        query.parameter
    );

    const deleteAction = useDeleteAction(
        (item: MaterialContentRuleSummary) => `/certificate-service/material-content-rules/${item.id}`,
        () => forceReload(),
        "deleteMasterData"
    );

    const contextMenuActions = useGridRowContextMenuActions(showDetailsAction, deleteAction);

    return useMemo<SubListProps<MaterialContentRuleSummary>>(
        () => ({
            query,
            columns,
            columnOptions,
            uniqueDataKey: "id" as keyof MaterialContentRuleSummary,
            onItemDoubleClick: showDetailsAction,
            header: "materialContentRule.list.header",
            itemActions: contextMenuActions,
            createUrl: hasAccessRight("createMasterData") ? getResolvedCreateUrl("materialContentRule") : undefined,
            tableName: "materialContentRules",
        }),
        [query, columnOptions, showDetailsAction, contextMenuActions, hasAccessRight, getResolvedCreateUrl]
    );
}
