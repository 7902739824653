import TextField from "@mui/material/TextField";

import { ChangeEventHandler, forwardRef, KeyboardEventHandler, useMemo } from "react";
import "./textfield.css";
import { InputBaseProps } from "@mui/material/InputBase";
import useGtbTranslation, { I18nKey } from "../../i18n/useGtbTranslation";
import { joinClassNames } from "../../utils/StringUtils";
import { InputAdornment } from "@mui/material";
import useGtbTooltip from "../tooltip/useGtbTooltip";

export interface GtbTextFieldProps {
    id?: string;
    label?: I18nKey;
    className?: string;
    type?: string;
    endAdornment?: JSX.Element | string | null;
    startAdornment?: JSX.Element | string | null;
    error?: boolean;
    helperText?: string;
    autoFocus?: any;
    readOnly?: boolean;
    multiline?: boolean;
    rows?: number;
    minRows?: number;
    maxRows?: number;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    placeholder?: string;
    value?: string | unknown;
    onFocus?: InputBaseProps["onFocus"];
    onBlur?: InputBaseProps["onBlur"];
    "aria-describedby"?: string;
    "aria-labelledby"?: string;
    "aria-label"?: I18nKey;
    "aria-owns"?: string;
    disabled?: boolean;
    onKeyDown?: KeyboardEventHandler;
    title?: string;
    InputLabelProps?: { shrink?: boolean };
    inputProps?: { placeholder?: string };
    borderless?: boolean;
}

const GtbTextField = forwardRef<HTMLDivElement, GtbTextFieldProps>(
    (
        {
            type = "text",
            className,
            startAdornment,
            endAdornment,
            readOnly,
            "aria-describedby": ariaDescribedBy,
            "aria-labelledby": ariaLabelledby,
            "aria-label": ariaLabel,
            "aria-owns": ariaOwns,
            disabled,
            label,
            title,
            InputLabelProps,
            inputProps,
            placeholder,
            borderless = false,
            ...rest
        }: GtbTextFieldProps,
        ref
    ) => {
        const translation = useGtbTranslation();
        const { tooltipProps, tooltip } = useGtbTooltip(label ?? ariaLabel);
        const labelProps = useMemo(
            () => (readOnly ? { ...InputLabelProps, shrink: true } : InputLabelProps),
            [InputLabelProps, readOnly]
        );
        return (
            <>
                <TextField
                    type={type}
                    inputRef={ref}
                    className={joinClassNames(
                        "gtb-textfield",
                        readOnly || disabled ? "textfield-readOnly" : null,
                        className
                    )}
                    InputProps={{
                        startAdornment: createAdornment("start", startAdornment),
                        endAdornment: createAdornment("end", endAdornment),
                        readOnly,
                        disabled,
                    }}
                    InputLabelProps={labelProps}
                    inputProps={{
                        ...inputProps,
                        placeholder: readOnly ? undefined : placeholder ?? inputProps?.placeholder,
                        "aria-label": ariaLabel && translation(ariaLabel),
                        "aria-describedby": ariaDescribedBy ?? tooltipProps?.["aria-describedby"],
                        "aria-labelledby": ariaLabelledby,
                        "aria-owns": ariaOwns,
                    }}
                    label={label && translation(label)}
                    title={title ?? tooltipProps?.title}
                    sx={borderless ? { border: "none", "& fieldset": { border: "none" } } : null}
                    {...rest}
                />
                {tooltip}
            </>
        );
    }
);

export default GtbTextField;

function createAdornment(position: "end" | "start", adornment?: JSX.Element | string | null | undefined) {
    if (adornment) {
        return <InputAdornment position={position}>{adornment}</InputAdornment>;
    }
    return null;
}
