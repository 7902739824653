import { yupResolver } from "@hookform/resolvers/yup";
import { subcontractingRelationValidationSchema } from "./subcontractingRelationValidationSchema";
import useResolvedRoute, { routeEnum } from "../../components/routing/useResolvedRoute";
import { useMemo } from "react";
import useDomainEntityDetailsEdit from "../../hooks/details/useDomainEntityDetailsEdit";
import { SubcontractingRelationDetails } from "../subcontractingRelationTypes";
import { defaultSubcontractingRelationSortOption } from "../useSubcontractingRelationListView";
import { SubcontractingRelationReadOnlyProps } from "./SubcontractingRelationDetailView";
import useIsAuthorised from "../../auth/useIsAuthorised";
import useAuth from "../../auth/useAuth";

export default function useSubcontractingRelationEdit() {
    const { hasAccessRight } = useIsAuthorised();
    const {
        currentUser: { authorisationRole },
    } = useAuth();
    const { getResolvedId } = useResolvedRoute();
    const subcontractingRelationId = getResolvedId("subcontractingRelation");

    const { detailViewProps, form, initialData, isReadOnly, title, recordNavigationProps } =
        useDomainEntityDetailsEdit<SubcontractingRelationDetails>({
            baseUrl: "/certificate-service/subcontracting-relations/" + subcontractingRelationId,
            resolver: yupResolver(subcontractingRelationValidationSchema),
            frontendRoute: "subcontractingRelation",
            recordNavigationDefaultSort: defaultSubcontractingRelationSortOption,
            titleBuilder: (item) =>
                `${item.principalSupplyChainOperatorBusinessName} ${item.subcontractorSupplyChainOperatorBusinessName}`,
            canSave: hasAccessRight("updateOperationLevelOrganisation"),
            historyColumns: [
                {
                    identifier: "principalSupplyChainOperator",
                    title: "subcontractingRelation.history.principal_column",
                },
                {
                    identifier: "subcontractorSupplyChainOperator",
                    title: "subcontractingRelation.history.subcontractor_column",
                },
                {
                    identifier: "standardAcronym" as keyof SubcontractingRelationDetails,
                    title: "subcontractingRelation.history.standard_column",
                },
                {
                    identifier: "accessLevel",
                    title: "subcontractingRelation.history.accessLevel_column",
                },
                {
                    identifier: "confirmationMethod",
                    title: "subcontractingRelation.history.confirmationMethod_column",
                },
                {
                    identifier: "startDate",
                    title: "subcontractingRelation.history.startDate_column",
                },
                {
                    identifier: "endDate",
                    title: "subcontractingRelation.history.endDate_column",
                },
            ],
        });

    const readOnlyProps: SubcontractingRelationReadOnlyProps = {
        isAccessLevelReadOnly:
            isReadOnly || !(authorisationRole.level === 2 && authorisationRole.type === "STANDARD_BODY"),
        isConfirmationMethodReadOnly:
            isReadOnly || authorisationRole.level > 3 || authorisationRole.type === "SUPPLY_CHAIN_OPERATOR",
        isEndDateReadOnly:
            isReadOnly || authorisationRole.level > 3 || authorisationRole.type === "SUPPLY_CHAIN_OPERATOR",
        isPrincipalReadOnly: true,
        isStandardReadOnly: true,
        isStartDateReadOnly:
            isReadOnly ||
            (authorisationRole && authorisationRole.level > 3) ||
            authorisationRole?.type === "SUPPLY_CHAIN_OPERATOR",
        isSubcontractorReadOnly: isReadOnly || authorisationRole?.type !== "STANDARD_BODY",
    };

    const createRoute = useMemo<routeEnum | undefined>(() => {
        return hasAccessRight("createOperationLevelOrganisation") ? "subcontractingRelation" : undefined;
    }, [hasAccessRight]);

    return {
        detailViewProps,
        form,
        title,
        initialData,
        readOnlyProps,
        recordNavigationProps,
        createRoute,
    };
}
