import { useDropdownStore, useSetDropdownStore } from "./dropdownStore";
import { GtbButtonProps } from "../GtbButton";
import { useCallback } from "react";
import { findFirstFocusableChild } from "../../utils/a11yUtils";

function OpenDropdownButton({ children, ...rest }: Omit<GtbButtonProps, "onClick">) {
    const isDropdownOpen = useDropdownStore();
    const setDropdownOpen = useSetDropdownStore();

    const focusDropdownMenu = useCallback((event) => {
        const dropdownMenu = (event.target as HTMLElement).parentElement?.querySelector(".dropdownMenu *:first-child");

        findFirstFocusableChild(dropdownMenu)?.focus();
    }, []);

    const keyDownListener = useCallback(
        (event) => {
            if (event.key !== "ArrowDown" || event.target !== event.currentTarget) {
                return;
            }
            event.preventDefault();
            if (event.target.getAttribute("aria-expanded") === "false") {
                event.currentTarget.click();
            } else {
                focusDropdownMenu(event);
            }
        },
        [focusDropdownMenu]
    );

    return (
        <button
            onClick={(e) => {
                e.preventDefault();
                setDropdownOpen(true);
            }}
            onKeyDown={keyDownListener}
            aria-expanded={isDropdownOpen}
            type="button"
            {...rest}
        >
            {children}
        </button>
    );
}

export default OpenDropdownButton;
