import GtbButton from "../../components/GtbButton";
import useGtbTranslation from "../../i18n/useGtbTranslation";
import Icon from "../../components/Icon";
import useFormDialog from "../../components/dialog/useFormDialog";
import { buildSchema, validateString } from "../../utils/yupExtension";
import DialogContent from "../../components/dialog/DialogContent";
import GtbTextArea from "../../components/Input/GtbTextArea";
import { UseGtbFormReturn } from "../../hooks/formHandling/useGtbForm";
import "./dashboardNotepad.css";
import { useQuery } from "../../hooks/useAxios";
import { info } from "../../utils/notification/notification";

export default function DashboardNotepadTileAction({ reloadTileContent }: DashboardNotepadTileActionProps) {
    const translation = useGtbTranslation();
    const showNotepad = useNotepad(reloadTileContent);

    return (
        <GtbButton
            aria-describedby={translation("dashboard.tiles.notepad_button")}
            className={"iconOnLeft"}
            variant={"secondary"}
            size={"small"}
            onClick={showNotepad}
        >
            <Icon name={"pencil"} size={13} />
            {translation("dashboard.tiles.notepad_button")}
        </GtbButton>
    );
}

function useNotepad(reloadTileContent: Function) {
    const translation = useGtbTranslation();
    const { runQuery } = useQuery<PersonalNote>({ url: "certificate-service/users/me/note", enabled: false });

    const show = useFormDialog({
        title: "dashboard.tiles.notepad_dialog_title",
        icon: "comment",
        method: "put",
        url: "certificate-service/users/me/note",
        schema: noteSchema,
        renderContent: renderNoteDialogContent,
        className: "notepad-dialog",
        afterSubmit: () => {
            info(translation("dashboard.tiles.notepad_dialog_save_successful"));
            reloadTileContent();
        },
    });

    return () => show(Promise.resolve(runQuery()));
}

const renderNoteDialogContent = (form: UseGtbFormReturn<PersonalNote>) => {
    return (
        <DialogContent>
            <GtbTextArea
                label="dashboard.tiles.notepad_dialog_content_input"
                className="content"
                {...form.registerWithErrors("content")}
                maxRows={20}
                minRows={20}
                autoFocus
            />
        </DialogContent>
    );
};

const noteSchema = buildSchema<PersonalNote>({ content: validateString().hasMaxLength(10000) });

interface PersonalNote {
    content: string;
}

interface DashboardNotepadTileActionProps {
    reloadTileContent: Function;
}
