import { PreGtbProductDetailViewProps } from "./PreGtbProductDetailView";
import { Nullable } from "../../../../utils/typeUtils";
import useDetailsCreate from "../../../../hooks/details/useDetailsCreate";
import { PreGtbProductDetails } from "../preGtbProductTypes";
import { useMemo } from "react";
import { useQuery } from "../../../../hooks/useAxios";
import { PreGtbTransactionCertificateReference } from "../../../preGtbTransactionCertificateTypes";
import usePreGtbProductDetails from "./usePreGtbProductDetails";

const readOnly: PreGtbProductDetailViewProps["readOnly"] = {
    productNumber: false,
    supplementaryWeight: false,
    availableVolume: true,
    labelGrade: false,
    netShippingWeight: false,
    initialAvailableQuantity: false,
    productCategoryPrint: false,
    productDetailPrint: false,
    productDetail: false,
    productCategory: false,
    usdaNopCompliant: false,
};

export default function usePreGtbProductCreate(): PreGtbProductDetailViewProps {
    const { resolver, preGtbTcId, labelGradesVisible, labelGradesLoading } = usePreGtbProductDetails();

    const { data: preGtbTc, isLoading: isParentLoading } = useQuery<PreGtbTransactionCertificateReference>({
        url: `/certificate-service/references/pre-gtb-tcs/${preGtbTcId}`,
    });

    const initialData = useMemo<Nullable<PreGtbProductDetails>>(
        () => ({
            id: null,
            preGtbTcTcNumber: preGtbTc?.tcNumber ?? null,
            productCategoryPrint: null,
            productDetailPrint: null,
            productCategory: null,
            labelGrade: null,
            labelGradeLabelGrade: null,
            productDetail: null,
            productNumber: null,
            usdaNopCompliant: false,
            supplementaryWeight: null,
            netShippingWeight: null,
            certifiedWeight: null,
            availableVolume: null,
            initialAvailableQuantity: null,
            preGtbTcStatus: null,
        }),
        [preGtbTc?.tcNumber]
    );

    const { detailViewProps, form, title } = useDetailsCreate<PreGtbProductDetails>({
        title: "preGtbProduct.detail.newRecord_title",
        frontendRoute: "preGtbProduct",
        initialData,
        resolver,
        saveUrl: `/certificate-service/pre-gtb-tcs/${preGtbTcId}/pre-gtb-products`,
    });

    return {
        title,
        detailViewProps: {
            ...detailViewProps,
            isLoading: detailViewProps.isLoading || labelGradesLoading || isParentLoading,
        },
        subListVisible: false,
        form,
        preGtbTcId,
        readOnly,
        labelGradesVisible,
        preGtbTcStatus: preGtbTc?.status,
        createRoute: "preGtbProduct",
    };
}
