import useExternalIdCreate from "../../details/useExternalIdCreate";
import { useMemo } from "react";
import { Nullable } from "../../../utils/typeUtils";
import useResolvedRoute, { routeEnum } from "../../../components/routing/useResolvedRoute";
import { ExternalIdDetails } from "../../externalIdTypes";

export default function useExternalFacilityIdCreate() {
    const { getResolvedId } = useResolvedRoute();
    const facilityId = getResolvedId("facility");
    const externalFacilityIdRoute = useMemo<routeEnum>(
        () => (facilityId ? "facilityExternalFacilityId" : "externalFacilityId"),
        [facilityId]
    );

    const partiallyInitialData = useMemo<Partial<Nullable<ExternalIdDetails>>>(() => {
        return {
            parent: facilityId ?? null,
            parentBusinessName: null,
            parentEntityType: "FACILITY",
        };
    }, [facilityId]);

    return useExternalIdCreate({
        i18nKey: "externalFacilityId",
        routeEnumKey: externalFacilityIdRoute,
        partiallyInitialData,
    });
}
