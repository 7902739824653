import { joinClassNames } from "../../utils/StringUtils";
import GtbTextField from "../../components/Input/GtbTextField";
import "./standardBodyBasicData.css";
import { StandardBodyBasicDataProps } from "./StandardBodyDetailView";
import OrganisationBasicData from "../../organisation/details/OrganisationBasicData";
import ControlledImageUploadComponent from "../../components/Input/UploadComponent/ControlledImageUploadComponent";

function StandardBodyBasicData({ form, logoUrl, readOnlyProps }: StandardBodyBasicDataProps) {
    return (
        <div className={joinClassNames("standardBodyBasicData", "organisationBasicData", "formGrid")}>
            <GtbTextField
                {...form.registerWithErrors("legalName")}
                readOnly={readOnlyProps.isLegalNameReadOnly}
                label="standardBody.detail.legalName_input"
                className="legalName"
            />
            <ControlledImageUploadComponent
                label="standardBody.detail.logo_input"
                imageUrl={logoUrl}
                readOnly={readOnlyProps.isLogoReadOnly}
                control={form.form.control}
                name="logo"
                className="logo"
            />
            <OrganisationBasicData form={form} readOnlyProps={readOnlyProps} organisationI18nKey="standardBody" />
        </div>
    );
}

export default StandardBodyBasicData;
