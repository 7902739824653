import { useHelpContext } from "../help/context-sensitivity/useHelpContext";
import EntityListView from "../components/entity/EntityListView";
import useOrganicMaterialProducerListView from "./useOrganicMaterialProducerListView";

export default function OrganicMaterialProducerListView() {
    const organicMaterialProducerGridOptions = useOrganicMaterialProducerListView();
    useHelpContext("organic-material-producer-list");

    return (
        <EntityListView
            heading="organicMaterialProducer.list.header"
            gridOptions={organicMaterialProducerGridOptions}
        />
    );
}
