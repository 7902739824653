import "./downtimeNotification.css";
import { ReactNode, useEffect, useState } from "react";
import { useQuery } from "../../hooks/useAxios";
import LoadingSpinner from "../LoadingSpinner";
import { formatDate, pad } from "../../utils/formatter";
import ServiceUnavailableErrorPage from "../../errorPage/ServiceUnavailableErrorPage";

export interface Downtime {
    startTime: Date;
    announcementPeriod: number;
    estimatedDuration: number;
}

export const buildDowntimeMessage = (startTime: Date, estimatedDuration: number) =>
    `This application is scheduled for a downtime of about ${estimatedDuration} ${
        estimatedDuration === 1 ? "hour" : "hours"
    }, starting on ${formatDate(startTime)} at ${pad(startTime.getHours())}:${pad(startTime.getMinutes())}.`;

type DowntimeStatus =
    | {
          status: "pending" | "noDowntime" | "downtime";
      }
    | { status: "announcedDowntime"; text: string };

export default function DowntimeChecker({ children }: { children: ReactNode }) {
    const [downtimeStatus, setDowntimeStatus] = useState<DowntimeStatus>({ status: "pending" });
    const { runQuery } = useQuery<Downtime>({
        url: "/certificate-service/runtime-parameters/downtime",
        enabled: false,
    });

    useEffect(() => {
        runQuery({ defaultServiceUnavailableHandler: false })
            .then((downtime: Downtime) => {
                if (!downtime) {
                    setDowntimeStatus({ status: "noDowntime" });
                    return;
                }
                const startTime = new Date(downtime.startTime + "Z");
                const msToDowntime = startTime.valueOf() - new Date().valueOf();
                if (msToDowntime <= 0) {
                    setDowntimeStatus({ status: "downtime" });
                } else if (msToDowntime <= downtime.announcementPeriod * 60 * 1000) {
                    const estimatedDowntimeHours = downtime.estimatedDuration / 60;
                    const estimatedDuration = Math.ceil(estimatedDowntimeHours * 2) / 2;

                    setDowntimeStatus({
                        status: "announcedDowntime",
                        text: buildDowntimeMessage(startTime, estimatedDuration),
                    });

                    const timer = setTimeout(() => setDowntimeStatus({ status: "downtime" }), msToDowntime);
                    return () => clearTimeout(timer);
                } else {
                    setDowntimeStatus({ status: "noDowntime" });
                }
            })
            .catch(() => setDowntimeStatus({ status: "downtime" }));
    }, [runQuery]);

    switch (downtimeStatus.status) {
        case "pending":
            return <LoadingSpinner asFullScreen />;
        case "downtime":
            return <ServiceUnavailableErrorPage />;
        case "announcedDowntime":
            return (
                <>
                    <div role="alert" className="downtimeNotification">
                        {downtimeStatus.text}
                    </div>
                    {children}
                </>
            );
        default: {
            return <>{children}</>;
        }
    }
}
