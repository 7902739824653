import useScProductSubList from "./useScProductSubList";
import InfinityScrollSublistTab from "../../../components/tabs/InfinityScrollSublistTab";
import { scopeCertificateStatusType } from "../../../auth/roleTypes";

export default function ScProducts({
    scopeCertificateStatus,
}: {
    scopeCertificateStatus?: scopeCertificateStatusType;
}) {
    const scProductSubList = useScProductSubList(scopeCertificateStatus);
    return <InfinityScrollSublistTab {...scProductSubList} />;
}
