import React, { createContext, ReactNode, useCallback, useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
    calculateRestrictedLanguages,
    DEFAULT_LANGUAGE,
    LANGUAGE_LOCAL_STORAGE_KEY,
    languageEnum,
    LanguageKey,
} from "./languageTypes";
import useStickyState from "../hooks/useStickyState";
import { setLanguageHeader } from "../hooks/useAxios";
import { determineInitialLanguage, isLanguageValid } from "./languageUtils";

function _useLanguage() {
    const { i18n } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useStickyState<languageEnum>(
        LANGUAGE_LOCAL_STORAGE_KEY,
        determineInitialLanguage()
    );

    const currentI18n = useRef<typeof i18n>();
    useEffect(() => {
        currentI18n.current = i18n;
    }, [i18n]);

    const changeLanguage = useCallback(
        (language: languageEnum) => {
            if (calculateRestrictedLanguages().includes(language)) {
                language = DEFAULT_LANGUAGE;
            }

            setCurrentLanguage(language);
        },
        [setCurrentLanguage]
    );

    useEffect(() => {
        if (isLanguageValid(currentLanguage)) {
            setLanguageHeader(currentLanguage);
            currentI18n.current?.changeLanguage(currentLanguage);
        } else {
            setCurrentLanguage(determineInitialLanguage());
        }
    }, [currentLanguage, setCurrentLanguage]);

    /**
     * Changes the language mode of the i18next instance to "cimode",
     * which displays the translation keys in the UI instead of the translated values.
     *
     * This function can be called in the browser's console by entering the following:
     * > displayTranslationKeys();
     */
    (window as typeof window & { displayTranslationKeys: () => void }).displayTranslationKeys = () => {
        currentI18n.current?.changeLanguage("cimode").then();
    };

    return { changeLanguage, currentLanguage };
}

const setLanguageContext = createContext<ReturnType<typeof _useLanguage>["changeLanguage"]>(
    () => new Promise(() => DEFAULT_LANGUAGE)
);
const languageContext = createContext<LanguageKey>(DEFAULT_LANGUAGE);

export function LanguageContextProvider({ children }: { children: ReactNode }) {
    const { changeLanguage, currentLanguage } = _useLanguage();

    return (
        <setLanguageContext.Provider value={changeLanguage}>
            <languageContext.Provider value={currentLanguage}>{children}</languageContext.Provider>
        </setLanguageContext.Provider>
    );
}

export default function useLanguage() {
    return useContext(languageContext);
}

export function useChangeLanguage() {
    return useContext(setLanguageContext);
}
