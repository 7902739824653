import { useEffect } from "react";
import { UseGtbFormReturn } from "./useGtbForm";
import { FieldValues } from "react-hook-form/dist/types";
import { Path, useWatch } from "react-hook-form";

export default function useValidateOnRelatedValueChange<FormType extends FieldValues>(
    form: UseGtbFormReturn<FormType>["form"],
    fieldToValidate: Path<FormType>,
    relatedField: Path<FormType>
) {
    const _relatedField = useWatch({ control: form.control, name: relatedField });

    useEffect(() => {
        // Only validate if the form was submitted at least once
        if (!form.formState.isSubmitted) {
            return;
        }
        form.trigger(fieldToValidate, { shouldFocus: false }).finally();
    }, [
        // trigger useEffect on change of related field value, despite not using it inside the effect
        _relatedField,
        fieldToValidate,
        form,
    ]);
}
