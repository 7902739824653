import { yupResolver } from "@hookform/resolvers/yup";
import { mailTemplateValidationSchema } from "./mailTemplateValidationSchema";
import { MailTemplateDetails } from "../mailTemplateTypes";
import { MailTemplateDetailViewProps } from "./MailTemplateDetailView";
import useResolvedRoute from "../../components/routing/useResolvedRoute";
import { buildTranslatedHistoryColumns } from "../../i18n/languageUtils";
import useDomainEntityDetailsEdit from "../../hooks/details/useDomainEntityDetailsEdit";
import { defaultMailTemplateSortOption } from "../useMailTemplateListView";

export default function useMailTemplateEdit(): MailTemplateDetailViewProps {
    const { getResolvedId } = useResolvedRoute();
    const mailTemplateId = getResolvedId("mailTemplate");

    const { detailViewProps, form, title, recordNavigationProps } = useDomainEntityDetailsEdit<MailTemplateDetails>({
        baseUrl: "/certificate-service/mail-templates/" + mailTemplateId,
        resolver: yupResolver(mailTemplateValidationSchema),
        titleBuilder: (item) => item.title,
        canSave: true,
        frontendRoute: "mailTemplate",
        historyColumns: [
            ...buildTranslatedHistoryColumns<MailTemplateDetails>("mailTemplate.history.subject_column", "subject"),
            ...buildTranslatedHistoryColumns<MailTemplateDetails>("mailTemplate.history.body_column", "body"),
        ],
        recordNavigationDefaultSort: defaultMailTemplateSortOption,
    });

    return {
        detailViewProps,
        form,
        title,
        recordNavigationProps,
    };
}
