import { UseGtbFormReturn } from "../../../hooks/formHandling/useGtbForm";
import { LabeledCheckbox } from "../../../components/Input/Checkbox";
import { AuthorisationRoleDetails } from "../../authorisationRoleTypes";
import { AccessRights } from "../../../auth/roleTypes";
import ControlledPickList from "../../../components/Input/pickList/ControlledPickList";
import { joinClassNames } from "../../../utils/StringUtils";
import useGtbTranslation, { I18nKey } from "../../../i18n/useGtbTranslation";

function StateSpecificAccessRightComponent({
    label,
    name,
    form,
    readOnly,
    options,
}: {
    label?: I18nKey;
    name: keyof AccessRights;
    form: UseGtbFormReturn<AuthorisationRoleDetails>;
    readOnly: boolean;
    options: string[];
}) {
    const translation = useGtbTranslation();

    return (
        <div className={joinClassNames("stateSpecificAccessRight", "innerGrid")}>
            <LabeledCheckbox
                aria-label={translation({
                    key: "authorisationRole.detail.accessRight_accessibleLabel",
                    options: { name: `${name.split(/(?=[A-Z])/).join(" ")}` },
                })}
                id={name}
                readOnly={readOnly}
                label={label}
                {...form.form.register(`${name}.granted`)}
            />
            <ControlledPickList
                multiple={true}
                readOnly={readOnly}
                control={form.form.control}
                label={"authorisationRole.detail.allowedStates_input"}
                name={`${name}.grantedStates` as keyof AuthorisationRoleDetails}
                itemId="id"
                itemLabel="label"
                data={options.map((opt) => {
                    return { id: opt, label: opt };
                })}
            />
        </div>
    );
}

export default StateSpecificAccessRightComponent;
