import * as React from "react";
import { ReactNode } from "react";
import Icon, { IconName } from "../../components/Icon";
import { I18nKey } from "../../i18n/useGtbTranslation";
import DropdownContainer from "../../components/dropdown/DropdownContainer";
import HeaderLinkCaption from "./HeaderLinkCaption";
import OpenDropdownButton from "../../components/dropdown/OpenDropdownButton";
import DropdownMenu from "../../components/dropdown/DropdownMenu";

function HeaderLinkDropdown({ caption, icon, children }: { caption: I18nKey; icon: IconName; children: ReactNode }) {
    return (
        <li className="headerLink">
            <DropdownContainer>
                <OpenDropdownButton>
                    <HeaderLinkCaption caption={caption} icon={icon} />
                    <Icon name="caret-down" size={11} />
                </OpenDropdownButton>
                <DropdownMenu>{children}</DropdownMenu>
            </DropdownContainer>
        </li>
    );
}

export default HeaderLinkDropdown;
