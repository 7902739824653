import { Theme } from "./theme";

const gotsTheme: Theme = {
    "backdrop-background-color": "rgba(41, 52, 63, 0.35)",
    "dashboard-background-color": "#EDEDED",
    "dashboard-icon-1-background-color": "#F2FAE8",
    "dashboard-icon-1-color": "#9DD85B",
    "dashboard-icon-2-background-color": "#F8F2E2",
    "dashboard-icon-2-color": "#CF9C27",
    "dashboard-icon-3-background-color": "#F8E9E5",
    "dashboard-icon-3-color": "#D76952",
    "dashboard-icon-4-background-color": "#E1F5F4",
    "dashboard-icon-4-color": "#3F9A96",
    "downtime-notification-background-color": "#F8E9E5",
    "downtime-notification-color": "#CF5F4C",
    "dropdown-text-highlight-color": "#0C655F",
    "error-background-color": "#F8E9E5",
    "error-color": "#CF5F4C",
    "footer-background-color": "#29343F",
    "footer-text-color": "#D4D9DD",
    "header-border-color": "#D4D9DD",
    "highlight-color": "#D76952",
    "info-background-color": "#EAF5EF",
    "info-color": "#3F9A96",
    "list-column-header-color": "#7E8A91",
    "list-column-header-highlight-color": "#43505C",
    "list-item-checkbox-color": "#7E8A91",
    "list-item-menu-color": "#0C655F",
    "main-menu-background-color": "#F7F7F7",
    "main-menu-text-color": "#0C655F",
    "primary-background-color": "#FFFFFF",
    "primary-color": "#3F9C35",
    "primary-neutral-color": "#7E8A91",
    "primary-text-color": "#29343F",
    "secondary-background-color": "#F4F4F4",
    "secondary-text-color": "#43505C",
    "tertiary-text-color": "#7E8A91",
    "warning-background-color": "#F8F2E2",
    "warning-color": "#CF9C27",
    "world-map-land": "#EDECEC",
    "world-map-sea": "#CED4D8",
    "product-traceability-color": "#3F9C35",
    "raw-material-traceability-color": "#285AB9",
};

export default gotsTheme;
