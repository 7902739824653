import useDetailsCreate from "../../hooks/details/useDetailsCreate";
import { yupResolver } from "@hookform/resolvers/yup";
import { Nullable } from "../../utils/typeUtils";
import { DynamicUserGroupDetails } from "../dynamicUserGroupTypes";
import { dynamicUserGroupValidationSchema } from "./dynamicUserGroupValidationSchema";
import { DynamicUserGroupDetailViewProps } from "./DynamicUserGroupDetailView";

const initialData: Nullable<DynamicUserGroupDetails> = {
    id: null,
    name: null,
    description: null,
    definition: null,
};

export default function useDynamicUserGroupCreate(): DynamicUserGroupDetailViewProps {
    const { detailViewProps, form, title } = useDetailsCreate<DynamicUserGroupDetails>({
        title: "dynamicUserGroup.detail.newRecord_title",
        frontendRoute: "dynamicUserGroup",
        initialData,
        resolver: yupResolver(dynamicUserGroupValidationSchema),
        saveUrl: "/certificate-service/dynamic-user-groups",
    });

    return {
        title,
        detailViewProps,
        form,
        createRoute: "dynamicUserGroup",
    };
}
