import { buildSchema, requiredObject, validateString } from "../../utils/yupExtension";
import { organisationValidationSchema } from "../../organisation/details/organisationValidationSchema";
import { StandardBodyDetails } from "../standardBodyTypes";

export const standardBodyValidationSchema = buildSchema<StandardBodyDetails>({
    legalName: validateString().isRequired().hasMaxLength(80),
    logo: requiredObject(),
    businessName: validateString().isRequired().hasMaxLength(40),
    ...organisationValidationSchema,
});
