import { TypedDetailViewProps } from "../../components/detailView/DetailView";
import StateProvinceBasicData from "./StateProvinceBasicData";
import { StateProvinceDetails } from "../stateProvinceTypes";
import { UseGtbFormReturn } from "../../hooks/formHandling/useGtbForm";
import EntityDetailView from "../../components/entity/EntityDetailView";
import { useHelpContext } from "../../help/context-sensitivity/useHelpContext";
import { buildLanguageVersionTab } from "../../i18n/LanguageVersionTab";
import Tabs, { TabKey } from "../../components/tabs/Tabs";
import "./stateProvinceLanguageVersion.css";

export default function StateProvinceDetailView({
    form,
    isReadOnly,
    countryAreaName,
    ...rest
}: StateProvinceDetailViewProps) {
    useHelpContext("state-province-details");
    return (
        <EntityDetailView {...rest} form={form}>
            <Tabs<
                StateProvinceDetails,
                TabKey<"stateProvince.detail.basicData_tab" | "translation.languageVersion_tab">
            >
                tabs={[
                    {
                        heading: "stateProvince.detail.basicData_tab",
                        element: (
                            <StateProvinceBasicData
                                form={form}
                                readOnly={isReadOnly}
                                countryAreaName={countryAreaName}
                            />
                        ),
                    },
                    buildLanguageVersionTab({
                        form,
                        fields: ["name"],
                        baseName: "stateProvince",
                        readOnly: isReadOnly,
                    }),
                ]}
                errors={form.form.formState.errors}
                errorToTab={{
                    id: "internal",
                    name: "translation.languageVersion_tab",
                    isoCode: "stateProvince.detail.basicData_tab",
                    latitude: "stateProvince.detail.basicData_tab",
                    longitude: "stateProvince.detail.basicData_tab",
                    countryArea: "stateProvince.detail.basicData_tab",
                    startDate: "stateProvince.detail.basicData_tab",
                    endDate: "stateProvince.detail.basicData_tab",
                    extendedDescription: "internal",
                }}
            />
        </EntityDetailView>
    );
}

export interface StateProvinceDetailViewProps extends TypedDetailViewProps<StateProvinceDetails> {
    isReadOnly: boolean;
    countryAreaName: string;
}

export interface StateProvinceDetailViewTabProps {
    form: UseGtbFormReturn<StateProvinceDetails>;
    readOnly: boolean;
    countryAreaName: string;
}
