import { UseGtbFormReturn } from "../../../hooks/formHandling/useGtbForm";
import { LabeledCheckbox } from "../../../components/Input/Checkbox";
import { AuthorisationRoleDetails } from "../../authorisationRoleTypes";
import { AccessRights } from "../../../auth/roleTypes";
import useGtbTranslation, { I18nKey } from "../../../i18n/useGtbTranslation";

function AccessRightComponent({
    label,
    name,
    form,
    readOnly,
}: {
    label?: I18nKey;
    name: keyof AccessRights;
    readOnly: boolean;
    form: UseGtbFormReturn<AuthorisationRoleDetails>;
}) {
    const translation = useGtbTranslation();

    return (
        <div className="accessRight">
            <LabeledCheckbox
                aria-label={translation({
                    key: "authorisationRole.detail.accessRight_accessibleLabel",
                    options: { name: `${name.split(/(?=[A-Z])/).join(" ")}` },
                })}
                readOnly={readOnly}
                id={name}
                label={label}
                {...form.form.register(`${name}.granted`)}
            />
        </div>
    );
}

export default AccessRightComponent;
