import { joinClassNames } from "../../utils/StringUtils";

import "./readonlydatetimefield.css";
import { formatTimestamp } from "../../utils/formatter";
import GtbTextField from "./GtbTextField";
import { Control, Controller } from "react-hook-form";
import { I18nKey } from "../../i18n/useGtbTranslation";

export interface GtbReadOnlyDateTimeFieldProps {
    label?: I18nKey;
    className?: string;
    helperText?: string;
    control: Control<any>;
    name: string;
}

const GtbReadOnlyDateTimeField = ({ className, name, control, ...rest }: GtbReadOnlyDateTimeFieldProps) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { value } }) => (
                <GtbTextField
                    className={joinClassNames("read-only-date-time", className)}
                    readOnly={true}
                    value={value ? formatTimestamp(value) : ""}
                    {...rest}
                />
            )}
        />
    );
};

export default GtbReadOnlyDateTimeField;
