import { SortOption, useQueryBuilder } from "../../../../../components/grid/component/useQueryBuilder";
import { TcMaterialDetails, TcMaterialSummary } from "./tcMaterialTypes";
import { GridColumn } from "../../../../../components/grid/component/Grid";
import { buildBooleanColumn, buildNumberColumn, buildTextColumn } from "../../../../../components/grid/ColumnProvider";
import useResolvedRoute from "../../../../../components/routing/useResolvedRoute";
import { useShowDetailsAction } from "../../../../../utils/defaultActions";
import useGridRowContextMenuActions from "../../../../../components/grid/component/cell/useGridRowContextMenuActions";
import { useMemo } from "react";
import { SubListProps } from "../../../../../components/grid/InfinityScrollSubList";

export const defaultTcMaterialSortOption: SortOption<TcMaterialSummary | TcMaterialDetails> = {
    field: "materialName",
    direction: "ASC",
};

const columns: GridColumn<TcMaterialSummary>[] = [
    buildTextColumn({
        key: "materialName",
        title: "tcMaterial.list.rawMaterialName_column",
        minWidth: 206,
    }),
    buildTextColumn({
        key: "materialPrint",
        title: "tcMaterial.list.rawMaterialPrint_column",
        visible: false,
        minWidth: 206,
    }),
    buildNumberColumn({
        key: "percentage",
        title: "tcMaterial.list.percentage_column",
        minWidth: 143,
    }),
    buildBooleanColumn({
        key: "certified",
        title: "tcMaterial.list.certified_column",
        minWidth: 187,
    }),
    buildTextColumn({
        key: "origin",
        title: "tcMaterial.list.origin_column",
        disableSorting: true,
        minWidth: 104,
    }),
    buildNumberColumn({
        key: "certifiedWeight",
        title: "tcMaterial.list.certifiedWeight_column",
        visible: false,
        fixedDecimal: true,
        allowDecimals: true,
        minWidth: 210,
    }),
];

export default function useTcMaterialSubList() {
    const { getResolvedEditUrl, getResolvedId } = useResolvedRoute();
    const transactionCertificateId = getResolvedId("transactionCertificate");
    const tcProductId = getResolvedId("tcProduct");

    const { query, columnOptions } = useQueryBuilder<TcMaterialSummary>(
        `/certificate-service/transaction-certificates/${transactionCertificateId}/tc-products/${tcProductId}/tc-materials`,
        {
            sort: defaultTcMaterialSortOption,
            filterActive: false,
        },
    );

    const showDetailsAction = useShowDetailsAction(
        (item: TcMaterialSummary) => getResolvedEditUrl("tcMaterial", item.id),
        query.parameter,
    );

    const contextMenuActions = useGridRowContextMenuActions(showDetailsAction);

    return useMemo<SubListProps<TcMaterialSummary>>(
        () => ({
            query,
            columns,
            uniqueDataKey: "id",
            onItemDoubleClick: showDetailsAction,
            header: "tcMaterial.list.header",
            itemActions: contextMenuActions,
            columnOptions,
            tableName: "TcMaterials",
        }),
        [columnOptions, contextMenuActions, query, showDetailsAction],
    );
}
