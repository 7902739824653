import yup, { validateNumber, validateString } from "../../utils/yupExtension";

export const productConversionValidationSchema = yup.object().shape({
    processCategory: validateString().isRequired(),
    rawMaterial: validateString().isRequired(),
    inputProductCategory: validateString().isRequired(),
    inputProductDetail: validateString().isRequired(),
    outputProductCategory: validateString().isRequired(),
    outputProductDetail: validateString().isRequired(),
    minConversion: validateNumber().isRequired().hasMinValue(0).hasMaxValue(100),
    maxConversion: validateNumber().isRequired().hasMinValue(0).hasMaxValue(100),
});
