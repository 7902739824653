import { joinClassNames } from "../../utils/StringUtils";
import { SupplyChainOperatorDetailViewTabProps } from "./SupplyChainOperatorDetailView";
import "./supplyChainOperatorNewRelation.css";
import StandardPickList from "../../components/Input/pickList/typedPicklist/StandardPickList";
import { supplyChainOperatorRelationTypeEnum } from "../supplyChainOperatorTypes";
import RadiobuttonGroup, {
    useTranslatedRadiobuttonOptionsFromObject,
} from "../../components/Input/radiobutton/RadiobuttonGroup";
import SupplyChainOperatorPickList from "../../components/Input/pickList/typedPicklist/SupplyChainOperatorPickList";
import CertificationBodyPickList from "../../components/Input/pickList/typedPicklist/CertificationBodyPickList";
import { I18nKey } from "../../i18n/useGtbTranslation";

export default function SupplyChainOperatorNewRelation({
    form,
    newSupplyChainOperatorRelationProps,
    standardPickListFlags,
    certificationBodyPickListFlags,
    supplyChainOperatorPickListFlags,
}: SupplyChainOperatorDetailViewTabProps) {
    const buildRadiobuttonGroupOptions = useTranslatedRadiobuttonOptionsFromObject();

    if (newSupplyChainOperatorRelationProps) {
        return (
            <div className={joinClassNames("supplyChainOperatorNewRelation", "formGrid")}>
                <RadiobuttonGroup
                    label="supplyChainOperator.detail.relationType_input"
                    {...buildRadiobuttonGroupOptions(newSupplyChainOperatorRelationProps.newRelationType)}
                    {...form.form.register("relationType")}
                    className="relationType"
                />

                <StandardPickList
                    control={form.form.control}
                    name="standard"
                    label="supplyChainOperator.detail.standard_input"
                    className="standard"
                    flags={standardPickListFlags}
                />

                {newSupplyChainOperatorRelationProps.isCertificationBodyVisible && (
                    <CertificationBodyPickList
                        control={form.form.control}
                        name="certificationBody"
                        label="supplyChainOperator.detail.certificationBody_input"
                        className="organisation"
                        flags={certificationBodyPickListFlags}
                    />
                )}

                {newSupplyChainOperatorRelationProps.isPrincipalVisible && (
                    <SupplyChainOperatorPickList
                        control={form.form.control}
                        name="principalSupplyChainOperator"
                        label="supplyChainOperator.detail.principal_input"
                        className="organisation"
                        flags={supplyChainOperatorPickListFlags}
                    />
                )}

                {newSupplyChainOperatorRelationProps.isSellerVisible && (
                    <SupplyChainOperatorPickList
                        control={form.form.control}
                        name="sellerSupplyChainOperator"
                        label="supplyChainOperator.detail.seller_input"
                        className="organisation"
                        flags={supplyChainOperatorPickListFlags}
                    />
                )}
            </div>
        );
    }

    return null;
}

export interface NewSupplyChainOperatorRelationProps {
    newRelationType:
        | Record<supplyChainOperatorRelationTypeEnum, I18nKey>
        | Partial<Record<supplyChainOperatorRelationTypeEnum, I18nKey>>;
    isCertificationBodyVisible: boolean;
    isPrincipalVisible: boolean;
    isSellerVisible: boolean;
}
