import {
    OrganicMaterialTransactionCertificateDetails,
    organicMaterialTransactionCertificateTypeEnum,
} from "../organicMaterialTransactionCertificateTypes";
import { buildSchema, requiredObject, validateString } from "../../../../../../utils/yupExtension";
import { BaseSchema } from "yup";

export const organicMaterialTransactionCertificateValidationSchema =
    buildSchema<OrganicMaterialTransactionCertificateDetails>({
        tcNumber: validateString().isRequired().hasMaxLength(30),
        tcType: validateString().isRequired() as BaseSchema<organicMaterialTransactionCertificateTypeEnum>,
        standard: validateString().isRequired(),
        tcCopy: requiredObject(),
    });
