import { I18nKey } from "../i18n/useGtbTranslation";
import { useMemo } from "react";

export default function useScopeCertificateSearchContext(context: ScopeCertificateSearchContextSelection) {
    const scopeCertificateSearchContextMap = useMemo(() => {
        const map = new Map<string, ScopeCertificateSearchContext>();

        map.set("all", {
            id: "ALL",
            navigationContextLabel: "scopeCertificate.list.all_header",
        });
        map.set("in_preparation", {
            id: "IN_PREPARATION",
            navigationContextLabel: "scopeCertificate.list.in_preparation_header",
        });
        map.set("awaiting_approval", {
            id: "AWAITING_APPROVAL",
            navigationContextLabel: "scopeCertificate.list.awaiting_approval_header",
        });
        map.set("active", {
            id: "ACTIVE",
            navigationContextLabel: "scopeCertificate.list.active_header",
        });
        map.set("pending", {
            id: "PENDING",
            navigationContextLabel: "scopeCertificate.list.pending_header",
        });
        map.set("expired", {
            id: "EXPIRED",
            navigationContextLabel: "scopeCertificate.list.expired_header",
        });
        map.set("obsolete", {
            id: "OBSOLETE",
            navigationContextLabel: "scopeCertificate.list.obsolete_header",
        });
        map.set("suspended", {
            id: "SUSPENDED",
            navigationContextLabel: "scopeCertificate.list.suspended_header",
        });
        map.set("withdrawn", {
            id: "WITHDRAWN",
            navigationContextLabel: "scopeCertificate.list.withdrawn_header",
        });
        map.set("client_active", {
            id: "CLIENT_ACTIVE",
            navigationContextLabel: "scopeCertificate.list.client_active_header",
        });
        map.set("client_pending", {
            id: "CLIENT_PENDING",
            navigationContextLabel: "scopeCertificate.list.client_pending_header",
        });
        map.set("client_in_preparation", {
            id: "CLIENT_IN_PREPARATION",
            navigationContextLabel: "scopeCertificate.list.client_in_preparation_header",
        });
        map.set("client_awaiting_approval", {
            id: "CLIENT_AWAITING_APPROVAL",
            navigationContextLabel: "scopeCertificate.list.client_awaiting_approval_header",
        });
        map.set("client_suspended", {
            id: "CLIENT_SUSPENDED",
            navigationContextLabel: "scopeCertificate.list.client_suspended_header",
        });
        map.set("client_withdrawn", {
            id: "CLIENT_WITHDRAWN",
            navigationContextLabel: "scopeCertificate.list.client_withdrawn_header",
        });

        return map;
    }, []);

    return scopeCertificateSearchContextMap.get(context)!;
}

export interface ScopeCertificateSearchContext {
    id: string;
    navigationContextLabel: I18nKey;
}

export type ScopeCertificateSearchContextSelection =
    | "all"
    | "in_preparation"
    | "awaiting_approval"
    | "active"
    | "pending"
    | "expired"
    | "obsolete"
    | "suspended"
    | "withdrawn"
    | "client_active"
    | "client_pending"
    | "client_in_preparation"
    | "client_awaiting_approval"
    | "client_suspended"
    | "client_withdrawn";
