import { joinClassNames } from "../../utils/StringUtils";
import "./byproductInputCombinationBasicData.css";
import { ByproductInputCombinationDetailViewTabProps } from "./ByproductInputCombinationDetailView";
import ProductDetailPickList from "../../components/Input/pickList/typedPicklist/ProductDetailPickList";
import ProcessCategoryPickList from "../../components/Input/pickList/typedPicklist/ProcessCategoryPickList";

function ByproductInputCombinationBasicData({ form, readOnly }: ByproductInputCombinationDetailViewTabProps) {
    return (
        <div className={joinClassNames("byproductInputCombinationBasicData", "formGrid")}>
            <ProductDetailPickList
                control={form.form.control}
                name="productDetail"
                readOnly={readOnly}
                className="productDetail"
                label="byProductInputCombination.detail.productDetail_input"
            />
            <ProcessCategoryPickList
                control={form.form.control}
                name="processCategory"
                readOnly={readOnly}
                className="processCategory"
                label="byProductInputCombination.detail.processCategory_input"
            />
        </div>
    );
}

export default ByproductInputCombinationBasicData;
