import { CountryAreaDetails } from "../countryAreaTypes";
import useDetailsCreate from "../../hooks/details/useDetailsCreate";
import { yupResolver } from "@hookform/resolvers/yup";
import { countryAreaValidationSchema } from "./countryAreaValidationSchema";
import { Nullable } from "../../utils/typeUtils";
import { routeEnum } from "../../components/routing/useResolvedRoute";
import { getInitialTranslatableValue } from "../../i18n/languageTypes";

const initialData: Nullable<CountryAreaDetails> = {
    addressPattern: null,
    callingCode: null,
    endDate: null,
    id: null,
    isoCode: null,
    name: getInitialTranslatableValue(),
    registrationIdType: null,
    registrationIdTypeOmp: null,
    startDate: null,
    subdivisionsMandatory: false,
    welcomeMessage: null,
    nameTranscriptions: false,
};

function useCountryAreaCreate() {
    const { detailViewProps, form, title } = useDetailsCreate<CountryAreaDetails>({
        title: "countryArea.detail.newRecord_title",
        frontendRoute: "countryArea",
        initialData,
        resolver: yupResolver(countryAreaValidationSchema),
        saveUrl: "/certificate-service/countries",
    });

    return {
        title,
        detailViewProps,
        form,
        navigationEnabled: false,
        isReadOnly: false,
        createRoute: "countryArea" as routeEnum,
    };
}

export default useCountryAreaCreate;
