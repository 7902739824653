import useExternalIdCreate from "../../details/useExternalIdCreate";
import { useMemo } from "react";
import { Nullable } from "../../../utils/typeUtils";
import useResolvedRoute, { routeEnum } from "../../../components/routing/useResolvedRoute";
import { ExternalIdDetails } from "../../externalIdTypes";

export default function useExternalSupplyChainOperatorIdCreate() {
    const { getResolvedId } = useResolvedRoute();
    const supplyChainOperatorId = getResolvedId("supplyChainOperator");
    const externalSupplyChainOperatorIdRoute = useMemo<routeEnum>(
        () =>
            supplyChainOperatorId
                ? "supplyChainOperatorExternalSupplyChainOperatorId"
                : "externalSupplyChainOperatorId",
        [supplyChainOperatorId]
    );

    const partiallyInitialData = useMemo<Partial<Nullable<ExternalIdDetails>>>(() => {
        return {
            parent: supplyChainOperatorId ?? null,
            parentBusinessName: null,
            parentEntityType: "SUPPLY_CHAIN_OPERATOR",
        };
    }, [supplyChainOperatorId]);

    return useExternalIdCreate({
        i18nKey: "externalSupplyChainOperatorId",
        routeEnumKey: externalSupplyChainOperatorIdRoute,
        partiallyInitialData,
    });
}
