import useIsAuthorised from "../../auth/useIsAuthorised";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { StandardDetails } from "../standardTypes";
import { defaultStandardSortOption } from "../useStandardListView";
import { standardValidationSchema } from "./standardValidationSchema";
import { useMemo } from "react";
import useResolvedRoute, { routeEnum } from "../../components/routing/useResolvedRoute";
import useDomainEntityDetailsEdit from "../../hooks/details/useDomainEntityDetailsEdit";

function useStandardEdit() {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedId } = useResolvedRoute();
    const standardId = getResolvedId("standard");

    const { detailViewProps, form, initialData, isReadOnly, title, recordNavigationProps } =
        useDomainEntityDetailsEdit<StandardDetails>({
            baseUrl: "/certificate-service/standards/" + standardId,
            frontendRoute: "standard",
            recordNavigationDefaultSort: defaultStandardSortOption,
            titleBuilder: (item) => item.acronym,
            resolver: yupResolver(standardValidationSchema),
            canSave: hasAccessRight("updateMasterData"),
            historyColumns: [
                { identifier: "officialName", title: "standard.history.officialName_column" },
                { identifier: "acronym", title: "standard.history.acronym_column" },
                { identifier: "typeOfStandard", title: "standard.history.standardType_column" },
                { identifier: "standardBody", title: "standard.history.standardBody_column" },
                {
                    identifier: "transactionCertificatesMandatory",
                    title: "standard.history.transactionCertificatesMandatory_column",
                },
            ],
        });

    const currentTypeOfStandard = form.form.watch("typeOfStandard");

    const createRoute = useMemo<routeEnum | undefined>(() => {
        return hasAccessRight("createMasterData") ? "standard" : undefined;
    }, [hasAccessRight]);

    return useMemo(() => {
        return {
            detailViewProps: { ...detailViewProps, isLoading: detailViewProps.isLoading || !currentTypeOfStandard },
            form,
            isReadOnly,
            subListVisible: true,
            title,
            subtitle: initialData?.officialName,
            recordNavigationProps,
            currentTypeOfStandard,
            createRoute,
        };
    }, [
        createRoute,
        currentTypeOfStandard,
        detailViewProps,
        form,
        initialData,
        isReadOnly,
        recordNavigationProps,
        title,
    ]);
}

export default useStandardEdit;
