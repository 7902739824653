import { OrganicMaterialProducerDetailViewTabProps } from "./OrganicMaterialProducerDetailView";
import OrganisationAddressData from "../../organisation/details/OrganisationAddressData";
import GtbTextField from "../../components/Input/GtbTextField";
import FixedRegistrationIdTextField from "../../components/Input/FixedRegistrationIdTextField";
import { OrganicMaterialProducerDetails } from "../organicMaterialProducerTypes";

export default function OrganicMaterialProducerAddressData({
    form,
    readOnlyProps,
    typeOfRegistrationId,
}: OrganicMaterialProducerDetailViewTabProps<OrganicMaterialProducerDetails>) {
    return (
        <OrganisationAddressData
            form={form}
            readOnlyProps={readOnlyProps}
            organisationI18nKey="organicMaterialProducer"
        >
            <GtbTextField
                readOnly
                label="organicMaterialProducer.detail.typeOfRegistrationId_input"
                className="typeOfRegistrationId"
                value={typeOfRegistrationId}
            />
            <FixedRegistrationIdTextField
                control={form.form.control}
                name="fixedRegistrationId"
                label="organicMaterialProducer.detail.fixedRegistrationId_input"
                className="fixedRegistrationId"
                readOnly={readOnlyProps.isFixedRegistrationIdReadOnly}
            />
        </OrganisationAddressData>
    );
}
