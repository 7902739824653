import { OrganicMaterialOriginDetails } from "../organicMaterialOriginTypes";
import "./organicMaterialOriginBasicData.css";
import GtbTextField from "../../../../components/Input/GtbTextField";
import OrganicMaterialProducerPickList from "../../../../components/Input/pickList/typedPicklist/OrganicMaterialProducerPickList";
import { UseGtbFormReturn } from "../../../../hooks/formHandling/useGtbForm";
import { ReadOnlyProps } from "../../../../utils/typeUtils";
import { joinClassNames } from "../../../../utils/StringUtils";
import { GtbNumberFieldOneFixedDecimal } from "../../../../components/Input/GtbNumberField";
import DependentAvailableOrganicMaterialProductionPickList from "../../../../components/Input/pickList/typedPicklist/DependentAvailableOrganicMaterialProductionPickList";
import OrganicMaterialTransactionCertificates from "./OrganicMaterialTransactionCertificates";

export interface OrganicMaterialOriginBasicDataProps {
    form: UseGtbFormReturn<OrganicMaterialOriginDetails>;
    readOnly: Omit<
        ReadOnlyProps<OrganicMaterialOriginDetails>,
        | "id"
        | "buyerLegalName"
        | "certifiedOrganicMaterialName"
        | "organicMaterialPurchaseSystemId"
        | "organicMaterialProductionSystemId"
        | "organicMaterialProductionDisplayName"
        | "organicMaterialPurchaseId"
        | "frozen"
        | "transferredQuantityFrozen"
    >;
    organicMaterialPurchaseId?: string;
    subListVisible?: boolean;
}

export default function OrganicMaterialOriginBasicData({
    form,
    readOnly,
    organicMaterialPurchaseId,
    subListVisible,
}: OrganicMaterialOriginBasicDataProps) {
    return (
        <div className={joinClassNames("organicMaterialOriginBasicData", "formGrid")}>
            <GtbTextField
                label="organicMaterialOrigin.detail.buyer_input"
                {...form.form.register("buyerLegalName")}
                className="buyer"
                readOnly
            />
            <GtbTextField
                label="organicMaterialOrigin.detail.certifiedOrganicMaterial_input"
                {...form.form.register("certifiedOrganicMaterialName")}
                className="certifiedOrganicMaterial"
                readOnly
            />
            <OrganicMaterialProducerPickList
                label="organicMaterialOrigin.detail.organicMaterialProducer_input"
                control={form.form.control}
                name="organicMaterialProducerId"
                className="organicMaterialProducer"
                readOnly={readOnly.organicMaterialProducerId}
            />
            <DependentAvailableOrganicMaterialProductionPickList
                label="organicMaterialOrigin.detail.organicMaterialProduction_input"
                form={form.form}
                name="organicMaterialProduction"
                readOnly={readOnly.organicMaterialProduction}
                className="organicMaterialProduction"
                parentField={"organicMaterialProducerId"}
                organicMaterialPurchaseId={organicMaterialPurchaseId}
            />
            <GtbNumberFieldOneFixedDecimal
                label="organicMaterialOrigin.detail.transferredQuantity_input"
                control={form.form.control}
                name="transferredQuantity"
                readOnly={readOnly.transferredQuantity}
                className="transferredQuantity"
                decimalPlaces={1}
                unit="kg"
            />
            {subListVisible && <OrganicMaterialTransactionCertificates />}
        </div>
    );
}
