import { yupResolver } from "@hookform/resolvers/yup";
import { I18nKey } from "../../i18n/useGtbTranslation";
import { routeEnum } from "../../components/routing/useResolvedRoute";
import { ExternalIdDetails } from "../externalIdTypes";
import useDetailsCreate from "../../hooks/details/useDetailsCreate";
import { Nullable } from "../../utils/typeUtils";
import useAuth from "../../auth/useAuth";
import { useEffect, useMemo } from "react";
import { Path, PathValue } from "react-hook-form";
import { ExternalIdReadOnlyProps } from "./ExternalIdDetailView";
import { externalIdValidationSchema } from "../externalIdValidationSchema";

export default function useExternalIdCreate({ partiallyInitialData, i18nKey, routeEnumKey }: UseExternalIdCreateProps) {
    const {
        currentUser: { authorisationRole, organisation },
    } = useAuth();

    const currentCb = useMemo(
        () => (authorisationRole.type === "CERTIFICATION_BODY" ? organisation : null),
        [authorisationRole, organisation]
    );

    const initialData = useMemo<Nullable<ExternalIdDetails>>(() => {
        return {
            ...partiallyInitialData,
            id: null,
            type: "CB",
            certificationBody: currentCb,
            externalId: null,
        } as Nullable<ExternalIdDetails>;
    }, [currentCb, partiallyInitialData]);

    const { detailViewProps, form, title } = useDetailsCreate<ExternalIdDetails>({
        title: `${i18nKey}.detail.newRecord_title` as I18nKey,
        frontendRoute: routeEnumKey,
        initialData,
        resolver: yupResolver(externalIdValidationSchema),
        saveUrl: "/certificate-service/external-ids",
    });

    const externalIdType = form.form.watch("type");

    useEffect(() => {
        if (externalIdType === "ITC") {
            form.form.setValue("certificationBody", null as PathValue<ExternalIdDetails, Path<ExternalIdDetails>>, {
                shouldDirty: true,
            });
        }
    }, [form.form, externalIdType]);

    const readOnlyProps = useMemo<ExternalIdReadOnlyProps>(() => {
        return {
            isParentEntityReadOnly: initialData.parent !== null,
            isTypeReadOnly: false,
            isCertificationBodyReadOnly: authorisationRole.type === "CERTIFICATION_BODY" || externalIdType === "ITC",
            isExternalIdReadOnly: false,
        };
    }, [authorisationRole.type, externalIdType, initialData.parent]);

    return {
        title,
        detailViewProps,
        form,
        createRoute: routeEnumKey,
        readOnlyProps,
    };
}

export interface UseExternalIdCreateProps {
    i18nKey: I18nKey;
    routeEnumKey: routeEnum;
    partiallyInitialData: Partial<Nullable<ExternalIdDetails>>;
}
