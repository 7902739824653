import { TypedDetailViewProps } from "../../components/detailView/DetailView";
import EntityDetailView from "../../components/entity/EntityDetailView";
import { useHelpContext } from "../../help/context-sensitivity/useHelpContext";
import { joinClassNames } from "../../utils/StringUtils";
import GtbTextField from "../../components/Input/GtbTextField";
import StandardPickList from "../../components/Input/pickList/typedPicklist/StandardPickList";
import ControlledDatePicker from "../../components/Input/datepicker/ControlledDatePicker";
import { ScoLicenceDetails } from "../scoLicenceTypes";
import SupplyChainOperatorPickList from "../../components/Input/pickList/typedPicklist/SupplyChainOperatorPickList";
import "./scoLicenceBasicData.css";
import useDefaultStandardPickListFlagsForRelations from "../../hooks/details/useDefaultStandardPickListFlagsForRelations";

export default function ScoLicenceDetailView({
    form,
    readOnly: { isReadOnly, isStandardReadOnly, isEndDateReadOnly, isBannedUntilReadOnly },
    ...rest
}: ScoLicenceDetailViewProps) {
    useHelpContext("sco-licence-details");

    const standardPickListFlags = useDefaultStandardPickListFlagsForRelations();

    return (
        <EntityDetailView form={form} {...rest}>
            <div className={joinClassNames("scoLicenceBasicData", "formGrid")}>
                <SupplyChainOperatorPickList
                    label="scoLicence.detail.supplyChainOperator_input"
                    control={form.form.control}
                    name="supplyChainOperator"
                    className="supplyChainOperator"
                    readOnly
                />
                <StandardPickList
                    control={form.form.control}
                    name="standard"
                    className="standard"
                    readOnly={isReadOnly || isStandardReadOnly}
                    label="scoLicence.detail.standard_input"
                    flags={standardPickListFlags}
                />
                <GtbTextField
                    {...form.form.register("licenceNumber")}
                    readOnly
                    label="scoLicence.detail.licenceNumber_input"
                    className="licenceNumber"
                />
                <ControlledDatePicker
                    control={form.form.control}
                    readOnly
                    name="startDate"
                    label="scoLicence.detail.startDate_input"
                    className="startDate"
                />
                <ControlledDatePicker
                    control={form.form.control}
                    readOnly={isReadOnly || isEndDateReadOnly}
                    name="endDate"
                    label="scoLicence.detail.endDate_input"
                    className="endDate"
                />
                <ControlledDatePicker
                    control={form.form.control}
                    readOnly={isReadOnly || isBannedUntilReadOnly}
                    name="bannedUntil"
                    label="scoLicence.detail.bannedUntil_input"
                    className="bannedUntil"
                />
            </div>
        </EntityDetailView>
    );
}

export interface ScoLicenceDetailViewProps extends TypedDetailViewProps<ScoLicenceDetails> {
    readOnly: ScoLicenceReadOnly;
}

export interface ScoLicenceReadOnly {
    isReadOnly: boolean;
    isStandardReadOnly: boolean;
    isEndDateReadOnly: boolean;
    isBannedUntilReadOnly: boolean;
}
