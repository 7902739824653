import useSupplyChainOperatorEdit from "./useSupplyChainOperatorEdit";
import SupplyChainOperatorDetailView from "./SupplyChainOperatorDetailView";

function SupplyChainOperatorEditView() {
    const supplyChainOperatorDetailProps = useSupplyChainOperatorEdit();

    return <SupplyChainOperatorDetailView {...supplyChainOperatorDetailProps} />;
}

export default SupplyChainOperatorEditView;
