import useDetailsCreate from "../../hooks/details/useDetailsCreate";
import { yupResolver } from "@hookform/resolvers/yup";
import { Nullable } from "../../utils/typeUtils";
import { StandardBodyDetails } from "../standardBodyTypes";
import { standardBodyValidationSchema } from "./standardBodyValidationSchema";
import { StandardBodyDetailViewProps, StandardBodyReadOnlyProps } from "./StandardBodyDetailView";
import useResolvedRoute from "../../components/routing/useResolvedRoute";
import {
    organisationDefaultInitialData,
    useOrganisationDefaultReadOnlyProps,
} from "../../organisation/details/organisationDetailViewProps";
import { useMemo } from "react";

const initialData: Nullable<StandardBodyDetails> = {
    ...organisationDefaultInitialData,
    legalName: null,
    businessName: null,
    logo: null,
};

function useStandardBodyCreate(): StandardBodyDetailViewProps {
    const { getResolvedId } = useResolvedRoute();

    const { detailViewProps, form, title } = useDetailsCreate<StandardBodyDetails>({
        title: "standardBody.detail.newRecord_title",
        frontendRoute: "standardBody",
        initialData,
        resolver: yupResolver(standardBodyValidationSchema),
        saveUrl: "/certificate-service/standard-bodies",
    });

    const defaultReadOnlyProps = useOrganisationDefaultReadOnlyProps(false);

    const readOnlyProps = useMemo<StandardBodyReadOnlyProps>(
        () => ({
            ...defaultReadOnlyProps,
            isLogoReadOnly: false,
        }),
        [defaultReadOnlyProps]
    );

    return {
        title,
        detailViewProps,
        form,
        logoUrl: `/certificate-service/standard-bodies/${getResolvedId("standardBody")}/logo`,
        readOnlyProps,
        createRoute: "standardBody",
        tlsTabVisible: false,
    };
}

export default useStandardBodyCreate;
