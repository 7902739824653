import { ReactNode } from "react";
import DropdownContainer from "./dropdown/DropdownContainer";
import OpenDropdownButton from "./dropdown/OpenDropdownButton";
import DropdownMenu from "./dropdown/DropdownMenu";
import Icon from "./Icon";
import useGtbTranslation, { I18nKey } from "../i18n/useGtbTranslation";

function DropdownComponent({
    label,
    children,
    iconSize = 11,
    ...rest
}: {
    label: I18nKey;
    children?: ReactNode;
    iconSize?: number;
    className?: string;
}) {
    const translation = useGtbTranslation();

    return (
        <div className="dropdownWrapper">
            <DropdownContainer>
                <OpenDropdownButton {...rest}>
                    {translation(label)}
                    <Icon name="caret-down" size={iconSize} />
                </OpenDropdownButton>
                <DropdownMenu>{children}</DropdownMenu>
            </DropdownContainer>
        </div>
    );
}

export default DropdownComponent;
