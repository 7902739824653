import { useCallback } from "react";
import { Dialog } from "../../components/dialog/useDialog";
import DialogHeader from "../../components/dialog/DialogHeader";
import DialogContent from "../../components/dialog/DialogContent";
import DialogFooter from "../../components/dialog/DialogFooter";
import { DialogOptions } from "../../components/dialog/DialogOptions";
import useGtbForm from "../../hooks/formHandling/useGtbForm";
import { useMutation } from "../../hooks/useAxios";
import "./changePasswordDialog.css";
import { info } from "../../utils/notification/notification";
import useGtbTranslation from "../../i18n/useGtbTranslation";
import { useModal } from "../../components/modal/ModalProvider";
import GtbPasswordField from "../../components/Input/GtbPasswordField";
import { validateString } from "../../utils/yupExtension";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export default function useChangePasswordDialog() {
    const { showModal: _showDialog, closeModal: closeDialog } = useModal();

    return useCallback(() => {
        _showDialog(<ChangePasswordDialog closeDialog={closeDialog} />);
    }, [_showDialog, closeDialog]);
}

function ChangePasswordDialog({ closeDialog }: ChangePasswordDialogProps) {
    const translation = useGtbTranslation();
    const { registerWithErrors: register, form } = useGtbForm({
        defaultValues: {
            currentPassword: "",
            newPassword: "",
            newPasswordConfirm: "",
        },
        resolver: yupResolver(
            yup.object().shape({
                currentPassword: validateString().isRequired(),
                newPassword: validateString().isRequired().hasMinLength(8).matchesPasswordPolicy(),
                newPasswordConfirm: validateString()
                    .isRequired()
                    .oneOf([yup.ref("newPassword"), null], translation("error.password.mismatching_passwords")),
            })
        ),
    });
    const { isLoading: passwordChangeIsLoading, runQuery: requestPasswordChange } = useMutation<ChangePasswordRequest>({
        method: "post",
        url: "/certificate-service/password-change",
    });

    const postPasswordChange = useCallback(() => {
        form.handleSubmit((body) => {
            return requestPasswordChange({ body })
                .then(() => {
                    form.reset();
                    closeDialog();
                    info(translation("user.detail.changePassword_success_toast"));
                })
                .catch((error) => {
                    if (error.statusCode === 400 && error.data.validationErrors.password) {
                        const errorKey = error.data.validationErrors.password;
                        // There are more errors than just the current password being wrong,
                        // but they are all handled by the frontend validation
                        if (errorKey === "CurrentPasswordWrong") {
                            form.setError(
                                "currentPassword",
                                { message: translation("error.password.wrong_password") },
                                { shouldFocus: true }
                            );
                        } else if (errorKey === "PasswordEqualToCurrent") {
                            form.setError(
                                "newPassword",
                                { message: translation("error.password.same_password") },
                                { shouldFocus: true }
                            );
                        }
                    } else {
                        error(translation("error.errorHandler.unexpectedSystemError_message"));
                    }
                });
        })();
    }, [closeDialog, form, requestPasswordChange, translation]);

    return (
        <Dialog
            dialog={{
                header: (
                    <DialogHeader
                        title={"user.detail.changePassword_dialogTitle"}
                        variant="info"
                        onClick={closeDialog}
                    />
                ),
                content: (
                    <DialogContent className={"dialog-content change-password-content"}>
                        <GtbPasswordField
                            label={"user.detail.changePassword_current_password_input"}
                            title={"user.detail.changePassword_current_password_tooltip"}
                            {...register("currentPassword")}
                            onKeyDown={(event) => {
                                if (event.key === "Enter") postPasswordChange();
                            }}
                        />
                        <GtbPasswordField
                            label={"user.detail.changePassword_new_password_input"}
                            title={"user.detail.changePassword_new_password_tooltip"}
                            {...register("newPassword")}
                            onKeyDown={(event) => {
                                if (event.key === "Enter") postPasswordChange();
                            }}
                        />
                        <GtbPasswordField
                            label={"user.detail.changePassword_confirm_password_input"}
                            title={"user.detail.changePassword_confirm_password_tooltip"}
                            {...register("newPasswordConfirm")}
                            onKeyDown={(event) => {
                                if (event.key === "Enter") postPasswordChange();
                            }}
                        />
                    </DialogContent>
                ),
                footer: (
                    <DialogFooter>
                        <DialogOptions
                            options={[
                                {
                                    onClick: closeDialog,
                                    label: "user.detail.changePassword_cancel_button",
                                },
                                {
                                    onClick: postPasswordChange,
                                    label: "user.detail.changePassword_confirm_button",
                                    disabled: passwordChangeIsLoading,
                                },
                            ]}
                        />
                    </DialogFooter>
                ),
            }}
        />
    );
}

interface ChangePasswordRequest {
    currentPassword: string;
    newPassword: string;
    newPasswordConfirm: string;
}

interface ChangePasswordDialogProps {
    closeDialog: () => void;
}
