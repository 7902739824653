import gtbLogo from "../../assets/images/global_trace_base_logo_RGB.svg";
import "./header.css";
import Image from "../../components/Image";
import GtbLink from "../../components/routing/GtbLink";
import { ReactNode } from "react";
import { getBaseUrl } from "../../components/routing/useResolvedRoute";
import useAuth from "../../auth/useAuth";
import useLoadImage from "../../hooks/useLoadImage";

export default function Header({ children }: { children?: ReactNode }) {
    const { currentUser } = useAuth();
    const logo = useLoadImage(
        currentUser ? `/certificate-service/standard-bodies/${currentUser.standardBody}/logo` : ""
    );

    return (
        <header>
            <GtbLink className="gtbLogoWrapper" to={getBaseUrl("homepage")}>
                <Image src={gtbLogo} alt={"mainLayout.headerMenu.gtbLogo_alternativeText"} height={660} width={2593} />
            </GtbLink>
            {children}
            <div className="standardLogoWrapper">
                {logo && (
                    <Image
                        src={logo}
                        alt={"mainLayout.headerMenu.currentStandardBodyLogo_alternativeText"}
                        height={0}
                        width={0}
                    />
                )}
            </div>
        </header>
    );
}
