import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import useStickyState from "../hooks/useStickyState";
import jwtDecode from "jwt-decode";
import { setAuthorizationHeader, useQuery } from "../hooks/useAxios";
import { AuthorisationRoleDetails } from "../authorisationRole/authorisationRoleTypes";
import { UserDetails } from "../user/userTypes";
import LoadingSpinner from "../components/LoadingSpinner";
import useRefetchJwt from "./useRefetchJwt";
import { ThemeKey } from "../themes/theme";

export type CurrentUser = {
    username: string;
    givenName: string;
    surname: string;
    id: string;
    standardBody: string;
    guiTheme: ThemeKey;
    authorisationRole: AuthorisationRoleDetails;
    email: string;
    systemId: string;
    totpReady: boolean;
    organisation: string | null;
    acceptedToU: boolean;
    acceptedGoogleToU: boolean;
};

export type AuthContextType = {
    setAuth: (auth: AuthType) => void;
    logout: () => void;
    currentUser: CurrentUser;
    reloadCurrentUser: Function;
};

export interface AuthType {
    jwt: string;
    refreshToken: string;
}

interface OtpExemptionType {
    token: string;
}

export interface AuthResponse extends AuthType {
    additionalAuthFactorRequired?: boolean;
    additionalAuthFactorType?: string;
    totpIsReset?: boolean;
    additionalAuthFactorSkippable?: boolean;
    otpExemption?: OtpExemptionType;
}

export type authStatusType = "authenticated" | "not_authenticated" | "pending_authentication";

const authContext = createContext<AuthContextType | null>(null);

export const localStorageGtbToken = "gtbToken";

/**
 * Provider for authentication and authorisation purposes
 *
 * @param children the inner elements of the Provider
 * @param initialState the initial auth state - if the authentication is saved in another place and not only in state
 * @constructor
 */
export const AuthProvider = ({ children, initialState }: { children: ReactNode; initialState: AuthType | null }) => {
    const [auth, setAuth] = useStickyState<AuthType>(localStorageGtbToken, initialState);

    const logout = useCallback(() => {
        setAuth(null);
    }, [setAuth]);

    const jwtStatus = useRefetchJwt(setAuth, logout, auth?.jwt, auth?.refreshToken);

    useEffect(() => {
        // Set the authorization header for backend calls to the new JWT on every change of auth
        setAuthorizationHeader(auth?.jwt);
    }, [auth?.jwt]);

    const { currentUser, reloadCurrentUser } = _useInitializeCurrentUserWithJwt(
        jwtStatus === "pending" ? undefined : auth?.jwt
    );

    const authStatus = useMemo<authStatusType>(() => {
        if (!auth?.jwt && !currentUser) {
            return "not_authenticated";
        } else if (auth?.jwt && currentUser) {
            return "authenticated";
        } else {
            return "pending_authentication";
        }
    }, [auth?.jwt, currentUser]);

    return (
        <authContext.Provider value={{ currentUser: currentUser!, setAuth, logout, reloadCurrentUser }}>
            {authStatus === "pending_authentication" && <LoadingSpinner asFullScreen />}
            {authStatus !== "pending_authentication" && children}
        </authContext.Provider>
    );
};

function useAuth() {
    return useContext(authContext) as AuthContextType;
}

export type JwtUser = {
    sub: string;
    givenName: string;
    surname: string;
    id: string;
    authorisationRole: string;
    email: string;
    systemId: string;
    totpReady: boolean;
};

function _useInitializeCurrentUserWithJwt(jwt?: string): {
    currentUser: CurrentUser | undefined;
    reloadCurrentUser: Function;
} {
    const [jwtUser, setJwtUser] = useState<JwtUser>();
    const [userRole, setUserRole] = useState<AuthorisationRoleDetails>();
    const [fetchedUser, setFetchedUser] = useState<UserDetails>();

    const { runQuery: fetchUser } = useQuery<UserDetails>({
        url: "/certificate-service/users/me",
        enabled: false,
    });

    const { runQuery: fetchAuthorisationRole } = useQuery<AuthorisationRoleDetails>({
        url: "/certificate-service/roles/me",
        enabled: false,
    });

    const reloadCurrentUser = useCallback(() => {
        fetchAuthorisationRole().then(setUserRole);
        fetchUser().then(setFetchedUser);
    }, [fetchAuthorisationRole, fetchUser]);

    useEffect(() => {
        if (!jwt) {
            //reset currentUser to default state
            setJwtUser(undefined);
            setUserRole(undefined);
            setFetchedUser(undefined);
        } else if (jwt && !jwtUser) {
            setJwtUser(jwtDecode<JwtUser>(jwt));
            reloadCurrentUser();
        }
    }, [fetchAuthorisationRole, fetchUser, jwt, jwtUser, reloadCurrentUser]);

    const currentUser = useMemo<CurrentUser | undefined>(() => {
        if (!jwtUser || !userRole || !fetchedUser) {
            return undefined;
        }
        return {
            username: fetchedUser.username,
            email: fetchedUser.emailAddress,
            systemId: fetchedUser.systemId,
            id: fetchedUser.id,
            surname: fetchedUser.surname,
            givenName: fetchedUser.givenName,
            totpReady: fetchedUser.totpReady,
            organisation: fetchedUser.organisation,
            standardBody: fetchedUser.standardBody,
            guiTheme: fetchedUser.guiTheme,
            authorisationRole: userRole,
            acceptedToU: fetchedUser.acceptedToU,
            acceptedGoogleToU: fetchedUser.acceptedGoogleToU,
        };
    }, [fetchedUser, jwtUser, userRole]);

    return { currentUser, reloadCurrentUser };
}

export default useAuth;
