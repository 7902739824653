import * as React from "react";
import useGtbTranslation, { I18nKey } from "../i18n/useGtbTranslation";

/**
 * Wrapper for img tag. the Width and height are only relevant for aspect-ratio calculation so that there is no layout shifting
 * while the image is being loaded
 *
 * @param src The source path of the image
 * @param alt a describing text for the image
 * @param width The width of the image for calculation of aspect ratio
 * @param height The height of the image for calculation of aspect ratio
 * @constructor
 */
function Image({ src, alt, width, height }: { src: string; alt: I18nKey; width: number; height: number }) {
    const translation = useGtbTranslation();

    return <img src={src} alt={translation(alt)} width={width} height={height} />;
}

export default Image;
