import { TypedDetailViewProps } from "../../components/detailView/DetailView";
import { UseGtbFormReturn } from "../../hooks/formHandling/useGtbForm";
import { TimeBasedMailDetails } from "../timeBasedMailTypes";
import EntityDetailView from "../../components/entity/EntityDetailView";
import { useHelpContext } from "../../help/context-sensitivity/useHelpContext";
import React from "react";
import Tabs from "../../components/tabs/Tabs";
import TimeBasedMailData from "./TimeBasedMailData";

export default function TimeBasedMailDetailView({
    detailViewProps,
    form,
    readOnly,
    ...rest
}: TimeBasedMailDetailViewProps) {
    useHelpContext("time-based-mail-details");

    return (
        <EntityDetailView detailViewProps={detailViewProps} form={form} {...rest}>
            <Tabs
                tabs={[
                    {
                        heading: "timeBasedMail.detail.basicData_tab",
                        element: <TimeBasedMailData form={form} readOnly={readOnly} />,
                    },
                ]}
                errors={form.form.formState.errors}
                errorToTab={{
                    id: "internal",
                    subject: "timeBasedMail.detail.basicData_tab",
                    targetDate: "timeBasedMail.detail.basicData_tab",
                    targetTime: "timeBasedMail.detail.basicData_tab",
                    sent: "timeBasedMail.detail.basicData_tab",
                    body: "timeBasedMail.detail.basicData_tab",
                    dynamicUserGroup: "timeBasedMail.detail.basicData_tab",
                    targetDateTime: "internal",
                }}
            />
        </EntityDetailView>
    );
}

export interface TimeBasedMailDetailViewProps extends TypedDetailViewProps<TimeBasedMailDetails> {
    readOnly: boolean;
}

export interface TimeBasedMailDetailViewTabProps {
    form: UseGtbFormReturn<TimeBasedMailDetails>;
    readOnly: boolean;
}
