import { joinClassNames } from "../../utils/StringUtils";
import { UseGtbFormReturn } from "../../hooks/formHandling/useGtbForm";
import { TransactionCertificateDetails } from "../transactionCertificateTypes";
import ControlledFileUploadComponent from "../../components/Input/UploadComponent/ControlledFileUploadComponent";
import EnumEntityRadiobuttonGroup from "../../components/Input/radiobutton/EnumEntityRadiobuttonGroup";
import "./transactionCertificateDocument.css";
import GtbTextField from "../../components/Input/GtbTextField";

export interface TransactionCertificateDocumentProps {
    form: UseGtbFormReturn<TransactionCertificateDetails>;
    transactionCertificateId?: string;
    humanReadableDocumentSize: string | null;
}

export default function TransactionCertificateDocument({
    form,
    transactionCertificateId,
    humanReadableDocumentSize,
}: TransactionCertificateDocumentProps) {
    return (
        <div className={joinClassNames("transactionCertificateDocument", "formGrid")}>
            <EnumEntityRadiobuttonGroup
                label={"transactionCertificate.detail.originOfCertificate_input"}
                readOnly
                {...form.form.register("certificateOrigin")}
                className="certificateOrigin"
                referenceUrl="/certificate-service/references/origination-of-certificates"
            />
            <ControlledFileUploadComponent
                control={form.form.control}
                name="certificateDocument"
                label="transactionCertificate.detail.certificateDocument_input"
                acceptedTypes={"application/pdf"}
                fileUrl={`/certificate-service/transaction-certificates/${transactionCertificateId}/certificate-document`}
                className="certificateDocument"
                maxFileSize={0}
                readOnly
            />
            <ControlledFileUploadComponent
                control={form.form.control}
                name="cbCertificate"
                label="transactionCertificate.detail.cbCertificate_input"
                acceptedTypes={"application/pdf"}
                fileUrl={`/certificate-service/transaction-certificates/${transactionCertificateId}/cb-certificate`}
                className="cbCertificate"
                readOnly
            />
            <GtbTextField
                label="transactionCertificate.detail.documentHash_input"
                {...form.registerWithErrors("documentHash")}
                readOnly
                className="documentHash"
            />
            <GtbTextField
                label="transactionCertificate.detail.hashAlgorithm_input"
                {...form.registerWithErrors("hashAlgorithm")}
                readOnly
                className="hashAlgorithm"
            />
            <GtbTextField
                label="transactionCertificate.detail.documentSize_input"
                value={humanReadableDocumentSize}
                readOnly
                className="documentSize"
            />
        </div>
    );
}
