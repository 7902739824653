import useGtbTranslation from "../../../i18n/useGtbTranslation";
import { useCallback, useMemo } from "react";
import { enUS } from "date-fns/locale";
import { ClockPickerView } from "@mui/x-date-pickers";

export default function useTimePickerLocalization() {
    const translation = useGtbTranslation();

    const locale = useMemo<Locale>(() => {
        return {
            ...enUS,
            localize: {
                ...enUS.localize!,
            },
        };
    }, []);

    const getClockLabelText = useCallback(
        (view: ClockPickerView, time: unknown | null) => {
            if (time) {
                return translation({
                    key: "components.timePicker.clockLabelTimeSelected_accessibleLabel",
                    options: {
                        minutesHours: view,
                        time: `${(time as Date).getHours().toString().padStart(2, "0")}:${(time as Date)
                            .getMinutes()
                            .toString()
                            .padStart(2, "0")}`,
                    },
                });
            } else {
                return translation({
                    key: "components.timePicker.clockLabelNoTimeSelected_accessibleLabel",
                    options: { minutesHours: view },
                });
            }
        },
        [translation]
    );

    const localeText = useMemo(() => {
        return {
            openNextView: translation("components.timePicker.openNextView_button"),
            openPreviousView: translation("components.timePicker.openPreviousView_button"),
            hoursClockNumberText: (hours: string) =>
                translation({ key: "components.timePicker.hours_accessibleLabel", options: { hours } }),
            minutesClockNumberText: (minutes: string) =>
                translation({ key: "components.timePicker.minutes_accessibleLabel", options: { minutes } }),
            openTimePickerDialogue: () => translation("components.timePicker.openTimePicker_accessibleLabel"),
            clockLabelText: (view: ClockPickerView, time: unknown | null) => getClockLabelText(view, time),
        };
    }, [getClockLabelText, translation]);

    return { locale, localeText };
}
