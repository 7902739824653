import useLabelGradeEdit from "./useLabelGradeEdit";
import LabelGradeDetailView from "./LabelGradeDetailView";

function LabelGradeEditView() {
    const labelGradeDetailsProperties = useLabelGradeEdit();

    return <LabelGradeDetailView {...labelGradeDetailsProperties} />;
}

export default LabelGradeEditView;
