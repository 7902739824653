import { SortOption } from "../components/grid/component/useQueryBuilder";
import { AllowedMaterialDetails, AllowedMaterialSummary } from "./allowedMaterialTypes";
import useAllowedMaterialSubList from "./useAllowedMaterialSubList";
import useResolvedRoute from "../components/routing/useResolvedRoute";

export const defaultStandardAllowedMaterialsSortOption: SortOption<AllowedMaterialSummary | AllowedMaterialDetails> = {
    field: "rawMaterialName",
    direction: "ASC",
};

export default function useStandardAllowedMaterialSubList() {
    const { getResolvedCreateUrl, getResolvedId } = useResolvedRoute();
    const standardId = getResolvedId("standard");

    const { ...allowedMaterialListOptions } = useAllowedMaterialSubList({
        baseQueryParamKey: "standardId",
        baseQueryParamValue: standardId,
        sortOption: defaultStandardAllowedMaterialsSortOption,
        column: {
            key: "rawMaterialName",
            title: "allowedMaterial.list.rawMaterial_column",
            minWidth: 155,
        },
        createUrl: getResolvedCreateUrl("standardAllowedMaterial"),
    });

    return allowedMaterialListOptions;
}
