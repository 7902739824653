import { useChangeNavigationContext } from "../../components/breadcrumb/useNavigationContext";
import { useHelpContext } from "../../help/context-sensitivity/useHelpContext";
import { useCallback, useEffect, useMemo, useState } from "react";
import DashboardSettingsDrawer from "./DashboardSettingsDrawer";
import { dashboardIcon, TileProps } from "./Tile";
import { handleResponseError } from "../../utils/errorHandler";
import { useMutation, useQuery } from "../../hooks/useAxios";
import { viewTileState } from "../../auth/roleTypes";
import useIsAuthorised from "../../auth/useIsAuthorised";
import { sortByProperty } from "../../utils/StringUtils";
import NavigatingTileAction from "./NavigatingTileAction";
import { getBaseUrl } from "../../components/routing/useResolvedRoute";
import { useDrawer } from "../../components/drawer/useDrawer";
import { I18nKey } from "../../i18n/useGtbTranslation";
import DashboardNotepadTileAction from "./DashboardNotepadTileAction";

const DASHBOARD_GRID_COLS = 4;

export interface TilePreferenceProps {
    title: I18nKey;
    position: number;
    hidden: boolean;
    className?: string;
}

export interface TilePropsWithAuthorisation extends TilePreferenceProps, Omit<TileProps, "col" | "row"> {
    accessRightState: viewTileState;
}

export interface TileSettings extends TilePreferenceProps, TileProps {}

export interface DashboardSettings {
    on: boolean;
    tiles: TileSettings[];
}

export interface DashboardPreferences {
    on: boolean;
    tiles: TilePreferenceProps[];
}

export type setTilePositionFunc = (tile: TileSettings, newPosition: number) => void;

export type changeDashboardSettingsFunc = ({
    on,
    tilesWithChangedVisibility,
}: {
    on: boolean;
    tilesWithChangedVisibility: I18nKey[];
}) => void;

const unconfirmedRelationsTileQueryDataResolver: TileProps["queryDataResolver"] = (
    { certificationRelations, subcontractingRelations, buyingRelations, supplyingRelations },
    translation
) => {
    const notAvailableString = translation("dashboard.tiles.unconfirmedRelations_message_not_available");
    const certRelsResolved = certificationRelations ?? notAvailableString;
    const subConRelsResolved = subcontractingRelations ?? notAvailableString;
    const buyRelsResolved = buyingRelations ?? notAvailableString;
    const suppRelsResolved = supplyingRelations ?? notAvailableString;

    return [
        [certRelsResolved, "dashboard.tiles.unconfirmedRelations_message_certification_relations"],
        [subConRelsResolved, "dashboard.tiles.unconfirmedRelations_message_subcontracting_relations"],
        [buyRelsResolved, "dashboard.tiles.unconfirmedRelations_message_buying_relations"],
        [suppRelsResolved, "dashboard.tiles.unconfirmedRelations_message_supplying_relations"],
    ];
};

export default function useDashboard() {
    const { hasAccessRight } = useIsAuthorised();
    const { resetNavigationContext } = useChangeNavigationContext();
    const { closeDrawer, showDrawer } = useDrawer();
    const [preferences, setPreferences] = useState<DashboardPreferences | undefined>(undefined);

    useHelpContext("home");
    useEffect(() => {
        resetNavigationContext();
    }, [resetNavigationContext]);

    const setPreferencesIfPresent = useCallback((preferencesOfUser, statusCode) => {
        let hasPreferences = statusCode === 200;
        if (hasPreferences) {
            setPreferences(preferencesOfUser);
        }
    }, []);

    const { runQuery: loadPreferences, isLoading: isLoadingPreferences } = useQuery<DashboardPreferences>({
        url: "/certificate-service/preferences/dashboard",
        onSuccess: setPreferencesIfPresent,
    });
    const { runQuery: savePreferences, isLoading: isSavingPreferences } = useMutation<DashboardPreferences>({
        method: "put",
        url: "/certificate-service/preferences/dashboard",
    });

    const dashboardSettings = useMemo<DashboardSettings>(() => {
        const configuredTiles = [...allAvailableTiles]
            .map((availableTile) =>
                withTileProps(
                    availableTile,
                    preferences?.tiles.find((pref) => pref.title === availableTile.title)
                )
            )
            .filter((tile) => hasAccessRight("viewTiles", [tile.accessRightState]))
            .map((t) => {
                const { accessRightState: _, ...tileWithoutRight } = t;
                return tileWithoutRight;
            });

        //if no preferences are set, set all tiles as visible
        if (!preferences) {
            configuredTiles.forEach((t) => (t.hidden = false));
        }

        return {
            on: !!preferences?.on,
            tiles: configuredTiles,
        };
    }, [hasAccessRight, preferences]);

    const maxPosition = useMemo(() => calcRow(allAvailableTiles.length) * DASHBOARD_GRID_COLS, []);

    const saveDashboardSettings = useCallback(
        (settings: DashboardSettings) => {
            const unauthorizedTiles =
                preferences?.tiles.filter((pref) => !settings.tiles.some((t) => t.title === pref.title)) ?? [];
            savePreferences({
                body: {
                    on: settings.on,
                    tiles: [...settings.tiles, ...unauthorizedTiles].map(({ title, position, hidden }) => {
                        return { title, position, hidden };
                    }),
                },
            })
                .then(() => loadPreferences())
                .catch(handleResponseError);
        },
        [loadPreferences, preferences, savePreferences]
    );

    const setTilePosition: setTilePositionFunc = useCallback(
        (tile: TileSettings, newPosition: number) => {
            saveDashboardSettings({
                ...dashboardSettings,
                tiles: dashboardSettings.tiles.map((oldTile) =>
                    tile.title === oldTile.title
                        ? {
                              ...oldTile,
                              position: newPosition,
                          }
                        : oldTile
                ),
            });
        },
        [saveDashboardSettings, dashboardSettings]
    );

    const changeDashboardSettings: changeDashboardSettingsFunc = useCallback(
        ({ on, tilesWithChangedVisibility }) => {
            if (on === dashboardSettings.on && !tilesWithChangedVisibility.length) {
                return;
            }
            const newTiles = [...dashboardSettings.tiles];
            const toHide: TileSettings[] = [];
            const toShow: TileSettings[] = [];
            tilesWithChangedVisibility.forEach((toChange) => {
                const tile = newTiles.find((t) => t.title === toChange)!;
                if (tile.hidden) {
                    toShow.push(tile);
                } else {
                    toHide.push(tile);
                }
            });
            //hide first, so that the new empty positions can be used for the new tiles
            toHide.forEach((toChange) => (toChange.hidden = true));

            //dashboard is made visible for the first time. Set position for each visible tile
            if (!preferences) {
                newTiles.filter((t) => !t.hidden).forEach((toSetPositon, index) => (toSetPositon.position = index));
            }

            toShow.forEach((toChange) => {
                const isAnotherTileBlocking = newTiles.some(
                    (otherTile) => !otherTile.hidden && otherTile.position === toChange.position
                );
                if (isAnotherTileBlocking) {
                    toChange.position = findFirstEmptyPosition(
                        [...newTiles]
                            .filter((t) => !t.hidden)
                            .map((t) => t.position)
                            .sort((a, b) => a - b)
                    );
                }
                toChange.hidden = false;
            });
            saveDashboardSettings({ on, tiles: newTiles });
        },
        [dashboardSettings.on, dashboardSettings.tiles, preferences, saveDashboardSettings]
    );

    const showDashboardSettingsDrawer = useCallback(() => {
        showDrawer(
            <DashboardSettingsDrawer
                closeDrawer={closeDrawer}
                dashboardSettings={dashboardSettings}
                changeDashboardSettings={changeDashboardSettings}
            />
        );
    }, [showDrawer, closeDrawer, dashboardSettings, changeDashboardSettings]);

    return useMemo(() => {
        return {
            isLoading: isLoadingPreferences || isSavingPreferences,
            showDashboardSettingsDrawer,
            dashboardSettings,
            maxPosition,
            setTilePosition,
        };
    }, [
        isLoadingPreferences,
        isSavingPreferences,
        maxPosition,
        setTilePosition,
        showDashboardSettingsDrawer,
        dashboardSettings,
    ]);
}

const buildTileProps = (
    title: TileProps["title"],
    icon: TileProps["icon"],
    accessRightState: viewTileState,
    options?: {
        query?: string;
        renderActions?: TileProps["renderActions"];
        className?: string;
        queryDataResolver?: TileProps["queryDataResolver"];
    }
): TilePropsWithAuthorisation => {
    return {
        title,
        icon,
        query: options?.query,
        accessRightState,
        className: options?.className,
        renderActions: options?.renderActions,
        queryDataResolver: options?.queryDataResolver,
        hidden: true,
        position: 0,
    };
};

const notepadIcon: dashboardIcon = { name: "notepad", className: "notepadIcon" };
const infoIcon: dashboardIcon = { name: "info", className: "infoIcon" };
const warningIcon: dashboardIcon = { name: "warning", className: "warningIcon" };
const checkmarkIcon: dashboardIcon = { name: "checkmark", className: "checkmarkIcon" };
const clockIcon: dashboardIcon = { name: "clock", className: "clockIcon" };
const crossIcon: dashboardIcon = { name: "cross", className: "crossIcon" };
const percentIcon: dashboardIcon = { name: "percent", className: "percentIcon" };

const allAvailableTiles: TilePropsWithAuthorisation[] = sortByProperty(
    [
        buildTileProps("dashboard.tiles.notepad_title", notepadIcon, "NOTEPAD", {
            query: "certificate-service/users/me/note-short",
            renderActions: (reloadTileContent) => <DashboardNotepadTileAction reloadTileContent={reloadTileContent} />,
            className: "previewTile",
        }),
        buildTileProps("dashboard.tiles.newFeatures_title", infoIcon, "NEW_FEATURES", {
            query: "/certificate-service/system-information/new-features",
            renderActions: () => (
                <NavigatingTileAction
                    url={getBaseUrl("help") + "/a9n-new-features"}
                    buttonText="dashboard.tiles.readMore_button"
                    target="_blank"
                />
            ),
            className: "previewTile",
        }),
        buildTileProps("dashboard.tiles.maintenance_title", warningIcon, "MAINTENANCE", {
            query: "/certificate-service/system-information/maintenance",
            renderActions: () => (
                <NavigatingTileAction
                    url={getBaseUrl("help") + "/a9n-maintenance"}
                    buttonText="dashboard.tiles.readMore_button"
                    target="_blank"
                />
            ),
            className: "previewTile",
        }),
        buildTileProps("dashboard.tiles.system_information_title", infoIcon, "SYSTEM_INFORMATION", {
            query: "/certificate-service/system-information",
            renderActions: () => (
                <NavigatingTileAction
                    url={getBaseUrl("help") + "/a9n-system-information"}
                    buttonText="dashboard.tiles.readMore_button"
                    target="_blank"
                />
            ),
            className: "previewTile",
        }),
        buildTileProps("dashboard.tiles.activeUserAccounts_title", checkmarkIcon, "ACTIVE_USER_ACCOUNTS", {
            query: "/certificate-service/metrics/active-users",
            renderActions: () => <NavigatingTileAction url={getBaseUrl("searchActiveUser")} />,
        }),
        buildTileProps("dashboard.tiles.activeCbs_title", checkmarkIcon, "ACTIVE_CBS", {
            query: "certificate-service/metrics/active-certification-bodies",
        }),
        buildTileProps("dashboard.tiles.activeAndTotalScs_title", checkmarkIcon, "ACTIVE_AND_TOTAL_SCS"),
        buildTileProps("dashboard.tiles.activeScs_title", checkmarkIcon, "SC_ACTIVE", {
            query: "certificate-service/metrics/scope-certificate-active",
            queryDataResolver: ({ validSCs, inRecertificationSCs }) => [
                [validSCs, "dashboard.tiles.activeSCs_valid_message"],
                [inRecertificationSCs, "dashboard.tiles.activeSCs_inRecertification_message"],
            ],
            renderActions: () => <NavigatingTileAction url={getBaseUrl("searchClientActiveScopeCertificate")} />,
        }),
        buildTileProps("dashboard.tiles.pendingScs_title", checkmarkIcon, "SC_PENDING", {
            query: "/certificate-service/metrics/scope-certificate-pending",
            renderActions: () => <NavigatingTileAction url={getBaseUrl("searchClientPendingScopeCertificate")} />,
        }),
        buildTileProps("dashboard.tiles.scsAwaitingApproval_title", clockIcon, "SC_AWAITING_APPROVAL", {
            query: "/certificate-service/metrics/scope-certificate-awaiting-approval",
            renderActions: () => (
                <NavigatingTileAction url={getBaseUrl("searchClientAwaitingApprovalScopeCertificate")} />
            ),
        }),
        buildTileProps("dashboard.tiles.scsInPreparation_title", clockIcon, "SC_IN_PREPARATION", {
            query: "/certificate-service/metrics/scope-certificate-in-preparation",
            renderActions: () => <NavigatingTileAction url={getBaseUrl("searchClientInPreparationScopeCertificate")} />,
        }),
        buildTileProps("dashboard.tiles.withdrawnScs_title", crossIcon, "SC_WITHDRAWN", {
            query: "/certificate-service/metrics/scope-certificate-withdrawn",
            renderActions: () => <NavigatingTileAction url={getBaseUrl("searchClientWithdrawnScopeCertificate")} />,
        }),
        buildTileProps("dashboard.tiles.suspendedScs_title", clockIcon, "SC_SUSPENDED", {
            query: "/certificate-service/metrics/scope-certificate-suspended",
            renderActions: () => <NavigatingTileAction url={getBaseUrl("searchClientSuspendedScopeCertificate")} />,
        }),
        buildTileProps(
            "dashboard.tiles.tcsApprovedLast7Days_title",
            checkmarkIcon,
            "TC_APPROVED_WITHIN_7_DAYS_AND_ACTIVE"
        ),
        buildTileProps(
            "dashboard.tiles.tcsApprovedLast12Months_title",
            checkmarkIcon,
            "TC_APPROVED_WITHIN_12_MONTH_AND_ACTIVE"
        ),
        buildTileProps("dashboard.tiles.validClientTCs_title", checkmarkIcon, "TC_VALID_CLIENT", {
            query: "certificate-service/metrics/transaction-certificate-valid-client",
            renderActions: () => <NavigatingTileAction url={getBaseUrl("searchValidClientTransactionCertificate")} />,
        }),
        buildTileProps("dashboard.tiles.inPreparationClientTCs_title", clockIcon, "TC_IN_PREPARATION_CLIENT", {
            query: "certificate-service/metrics/transaction-certificate-in-preparation-client",
            queryDataResolver: ({ draft, rejected }) => [
                [draft, "dashboard.tiles.inPreparationClientTCs_draft_message"],
                [rejected, "dashboard.tiles.inPreparationClientTCs_rejected_message"],
            ],
            renderActions: () => (
                <NavigatingTileAction url={getBaseUrl("searchInPreparationClientTransactionCertificate")} />
            ),
        }),
        buildTileProps("dashboard.tiles.awaitingApprovalClientTCs_title", clockIcon, "TC_AWAITING_APPROVAL_CLIENT", {
            query: "certificate-service/metrics/transaction-certificate-awaiting-approval-client",
            renderActions: () => (
                <NavigatingTileAction url={getBaseUrl("searchAwaitingApprovalClientTransactionCertificate")} />
            ),
        }),
        buildTileProps("dashboard.tiles.withdrawnClientTCs_title", crossIcon, "TC_WITHDRAWN_CLIENT", {
            query: "certificate-service/metrics/transaction-certificate-withdrawn-client",
            renderActions: () => (
                <NavigatingTileAction url={getBaseUrl("searchWithdrawnClientTransactionCertificate")} />
            ),
        }),

        buildTileProps("dashboard.tiles.availableScos_title", checkmarkIcon, "SCO_AVAILABLE"),
        buildTileProps("dashboard.tiles.inactiveScos_title", percentIcon, "SCO_INACTIVE"),
        buildTileProps("dashboard.tiles.clientSCOs_title", infoIcon, "CLIENT_SCOS", {
            query: "certificate-service/metrics/client-scos",
            queryDataResolver: ({ clientSCOs, certifiedSCOs, isAvailable }) =>
                isAvailable
                    ? [
                          [clientSCOs, "dashboard.tiles.clientSCOs_message"],
                          [certifiedSCOs, "dashboard.tiles.clientSCOs_certifiedSCos_message"],
                      ]
                    : "dashboard.tiles.clientSCOs_not_available_message",
        }),
        buildTileProps("dashboard.tiles.unconfirmedRelations_title", clockIcon, "UNCONFIRMED_RELATIONS", {
            query: "certificate-service/metrics/unconfirmed-relations",
            queryDataResolver: unconfirmedRelationsTileQueryDataResolver,
            className: "noBottomPadding",
        }),
        buildTileProps(
            "dashboard.tiles.securityEventsLast24Hours_title",
            warningIcon,
            "SECURITY_EVENTS_LAST_24_HOURS",
            { query: "/log-service/metrics/security-events" }
        ),
    ],
    "title"
);
const withTileProps = (
    availableTile: TilePropsWithAuthorisation,
    preference?: TilePreferenceProps
): TileSettings & TilePropsWithAuthorisation => {
    const position = preference?.position ?? availableTile.position;
    const gridPosition = getGridPosition(position);
    return { ...availableTile, ...preference, ...gridPosition };
};

const getGridPosition = (position: number) => {
    return { col: calcCol(position), row: calcRow(position) };
};

const calcRow = (position: number): number => ((position / DASHBOARD_GRID_COLS) >> 0) + 1;
const calcCol = (position: number): number => (position % DASHBOARD_GRID_COLS) + 1;

const findFirstEmptyPosition = (source: number[]): number => {
    for (let i = 0; i <= source.length - 1; i++) {
        if (source[i] !== i) {
            return i;
        }
    }
    return source.length;
};
