import useAddOrganicMaterialProductionDialog from "./useAddOrganicMaterialProductionDialog";
import { useMutation, useQuery } from "../hooks/useAxios";
import { OrganicMaterialProductionSummary } from "./organicMaterialProductionTypes";
import { useCallback } from "react";
import useResolvedRoute from "../components/routing/useResolvedRoute";
import { useGtbNavigate } from "../components/routing/GtbRouter";
import { handleResponseError } from "../utils/errorHandler";
import { info } from "../utils/notification/notification";
import useGtbTranslation from "../i18n/useGtbTranslation";

export default function useAddOrganicMaterialProduction() {
    const { getResolvedCreateUrl, getResolvedId } = useResolvedRoute();
    const navigate = useGtbNavigate();
    const organicMaterialProducerScId = getResolvedId("organicMaterialProductionSc");
    const translation = useGtbTranslation();

    const showAddOrganicMaterialProductionDialog = useAddOrganicMaterialProductionDialog();

    const { runQuery: fetchPotentialOrganicMaterialProductions } = useQuery<OrganicMaterialProductionSummary[]>({
        url: "",
        enabled: false,
    });

    const { runQuery: runAddOrganicMaterialProductions } = useMutation({
        method: "post",
        url: "/certificate-service/om-certification-relations",
    });

    const addSelectedOrganicMaterialProductions = useCallback(
        (selectedOrganicMaterialProductions: string[], afterAddSelectedAction: () => void) => {
            runAddOrganicMaterialProductions({
                body: {
                    organicMaterialProductions: selectedOrganicMaterialProductions,
                    organicMaterialProductionSc: organicMaterialProducerScId,
                },
            })
                .then(() => {
                    afterAddSelectedAction();
                    info(
                        translation(
                            "organicMaterialProductionSc.addOrganicMaterialProductionDialog.addedSuccessfully_toast"
                        )
                    );
                })
                .catch(handleResponseError);
        },
        [organicMaterialProducerScId, runAddOrganicMaterialProductions, translation]
    );

    const navigateToOrganicMaterialProductionCreate = useCallback(() => {
        navigate(getResolvedCreateUrl("organicMaterialProductionScOrganicMaterialProduction"));
    }, [getResolvedCreateUrl, navigate]);

    return useCallback(
        (organicMaterialProducerId: string, afterAcceptAction: () => void) => {
            fetchPotentialOrganicMaterialProductions({
                url: `/certificate-service/organic-material-productions/eligibleForOmpSc/${organicMaterialProducerScId}`,
            }).then((potentialOrganicMaterialProductions: OrganicMaterialProductionSummary[]) => {
                if (potentialOrganicMaterialProductions?.length > 0) {
                    showAddOrganicMaterialProductionDialog(
                        potentialOrganicMaterialProductions,
                        (itemId: string[]) => addSelectedOrganicMaterialProductions(itemId, afterAcceptAction),
                        navigateToOrganicMaterialProductionCreate
                    );
                } else {
                    navigateToOrganicMaterialProductionCreate();
                }
            });
        },
        [
            addSelectedOrganicMaterialProductions,
            fetchPotentialOrganicMaterialProductions,
            navigateToOrganicMaterialProductionCreate,
            organicMaterialProducerScId,
            showAddOrganicMaterialProductionDialog,
        ]
    );
}
