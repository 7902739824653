import useStandardBodyEdit from "./useStandardBodyEdit";
import StandardBodyDetailView from "./StandardBodyDetailView";

function StandardBodyEditView() {
    const standardBodyDetailProps = useStandardBodyEdit();

    return <StandardBodyDetailView {...standardBodyDetailProps} />;
}

export default StandardBodyEditView;
