import * as React from 'react';

export interface DropdownMenuItemProps {
    children?: React.ReactNode;
}

function DropdownMenuItem({children}: DropdownMenuItemProps) {
    return (
        <li className="dropdownMenuItem">
            {children}
        </li>
    );
}

export default DropdownMenuItem;
