import { OrganicMaterialProductionDetails } from "../organicMaterialProductionTypes";
import { buildSchema, validateNumber, validateString } from "../../utils/yupExtension";

export const organicMaterialProductionValidationSchema = buildSchema<OrganicMaterialProductionDetails>({
    organicMaterialProducer: validateString().isRequired(),
    certifiedOrganicMaterial: validateString().isRequired(),
    town: validateString().isRequired().hasMaxLength(40),
    postCode: validateString().hasMaxLength(10),
    countryArea: validateString().isRequired(),
    stateProvince: validateString().isRequired(),
    addressDetails1: validateString().isRequired().hasMaxLength(40),
    addressDetails2: validateString().hasMaxLength(40),
    addressDetails3: validateString().hasMaxLength(40),
    startOfHarvestYear: validateNumber().isRequired(),
});
