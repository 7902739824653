import useDetailsCreate from "../../hooks/details/useDetailsCreate";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "../../hooks/useAxios";
import { Nullable } from "../../utils/typeUtils";
import { ScopeDetails } from "../scopeTypes";
import { ScopeDetailViewProps } from "./ScopeDetailView";
import { StandardDetails } from "../../standard/standardTypes";
import { scopeValidationSchema } from "./scopeValidationSchema";
import useResolvedRoute from "../../components/routing/useResolvedRoute";

const initialData: Nullable<ScopeDetails> = {
    id: null,
    name: null,
    description: null,
    standard: null,
    startDate: null,
    endDate: null,
};

function useScopeCreate(): ScopeDetailViewProps {
    const { getResolvedId } = useResolvedRoute();
    const standardId = getResolvedId("standard");

    const { data: standard } = useQuery<StandardDetails>({
        url: "/certificate-service/standards/" + standardId + "?view=MINIMISED",
    });

    const { detailViewProps, form, title } = useDetailsCreate<ScopeDetails>({
        title: "scope.detail.newRecord_title",
        frontendRoute: "scope",
        initialData,
        resolver: yupResolver(scopeValidationSchema),
        saveUrl: "/certificate-service/standards/" + standardId + "/scopes",
    });

    return {
        detailViewProps,
        form,
        title,
        standard: `${standard?.acronym}`,
        isReadOnly: false,
        createRoute: "scope",
    };
}

export default useScopeCreate;
