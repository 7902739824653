import useGtbForm from "../hooks/formHandling/useGtbForm";
import { useMutation } from "../hooks/useAxios";
import { FormEvent, useCallback } from "react";
import UseUnclosableDialog from "../components/dialog/UseUnclosableDialog";
import { pageUrl } from "../utils/windowFunctions";

export type ForgotPasswordInputTypes = {
    username: string;
};

function useForgotPassword() {
    const {
        registerWithErrors: register,
        form: { handleSubmit },
    } = useGtbForm<ForgotPasswordInputTypes>({
        defaultValues: {
            username: "",
        },
    });

    const { isLoading: isForgotPasswordLoading, runQuery } = useMutation({
        method: "post",
        url: "/certificate-service/password-reset",
    });

    const showDialog = UseUnclosableDialog();

    const handleSuccess = useCallback(() => {
        showDialog({
            title: "loginPage.forgot_password_success_dialog_title",
            message: "loginPage.forgot_password_success_dialog_message",
        });
    }, [showDialog]);

    const onForgotPassword = useCallback(
        (e: FormEvent<any>) => {
            const onSubmit = handleSubmit(({ username }) =>
                runQuery({
                    body: {
                        username: username.toLocaleLowerCase(),
                        url: pageUrl() + "/resetPassword/",
                    },
                }).then(handleSuccess)
            );
            onSubmit(e).then(/*empty call because of lint error otherwise*/);
        },
        [handleSubmit, runQuery, handleSuccess]
    );

    return { register, isForgotPasswordLoading, onForgotPassword };
}

export default useForgotPassword;
