import { useMemo } from "react";
import { VersionDetails, VersionSummary } from "./versionTypes";
import { SortOption, useQueryBuilder } from "../../components/grid/component/useQueryBuilder";
import useIsAuthorised from "../../auth/useIsAuthorised";
import { useDeleteAction, useShowDetailsAction } from "../../utils/defaultActions";
import { GridColumn } from "../../components/grid/component/Grid";
import {
    buildBooleanColumn,
    buildDateColumn,
    buildNumberColumn,
    buildTextColumn,
} from "../../components/grid/ColumnProvider";
import useResolvedRoute from "../../components/routing/useResolvedRoute";
import { SubListProps } from "../../components/grid/SubList";
import useGridRowContextMenuActions from "../../components/grid/component/cell/useGridRowContextMenuActions";

export const defaultVersionSortOption: SortOption<VersionSummary | VersionDetails> = {
    field: "systemId",
    direction: "ASC",
};

const columns: GridColumn<VersionSummary>[] = [
    buildTextColumn({ key: "systemId", title: "standardVersion.list.systemId_column", minWidth: 134 }),
    buildTextColumn({ key: "versionNumber", title: "standardVersion.list.versionNumber_column", minWidth: 177 }),
    buildNumberColumn({
        key: "defaultValidityPeriod",
        title: "standardVersion.list.defaultValidityPeriod_column",
        minWidth: 252,
        visible: false,
    }),
    buildTextColumn({ key: "cugsText", title: "standardVersion.list.cugs_column", minWidth: 103, visible: false }),
    buildDateColumn({ key: "endDate", title: "standardVersion.list.endDate_column", minWidth: 124, visible: false }),
    buildTextColumn({
        key: "processingText",
        title: "standardVersion.list.processing_column",
        minWidth: 143,
        visible: false,
    }),
    buildTextColumn({
        key: "rawMaterialsText",
        title: "standardVersion.list.rawMaterials_column",
        minWidth: 164,
        visible: false,
    }),
    buildNumberColumn({
        key: "recertificationBindingPeriod",
        title: "standardVersion.list.recertificationBindingPeriod_column",
        minWidth: 308,
        visible: false,
    }),
    buildNumberColumn({
        key: "recertificationPeriod",
        title: "standardVersion.list.recertificationPeriod_column",
        minWidth: 246,
        visible: false,
    }),
    buildDateColumn({
        key: "startDate",
        title: "standardVersion.list.startDate_column",
        minWidth: 130,
        visible: false,
    }),
    buildBooleanColumn({
        key: "hideUsdaNopCompliance",
        title: "standardVersion.list.usdaNop_column",
        minWidth: 141,
        visible: false,
    }),
];

function useVersionSubList() {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedEditUrl, getResolvedCreateUrl, getResolvedId } = useResolvedRoute();
    const standardId = getResolvedId("standard");

    const { query, columnOptions, forceReload } = useQueryBuilder<VersionSummary>(
        `/certificate-service/standards/${standardId}/standard-versions`,
        {
            sort: defaultVersionSortOption,
            filterActive: false,
        }
    );

    const showDetailsAction = useShowDetailsAction(
        (item) => getResolvedEditUrl("standardVersion", item.id),
        query.parameter
    );

    const deleteAction = useDeleteAction(
        (item: VersionSummary) => `/certificate-service/standards/${standardId}/standard-versions/${item.id}`,
        forceReload,
        "deleteMasterData"
    );

    const contextMenuActions = useGridRowContextMenuActions(showDetailsAction, deleteAction);

    return useMemo<SubListProps<VersionSummary>>(
        () => ({
            query,
            columns,
            columnOptions,
            uniqueDataKey: "id",
            onItemDoubleClick: showDetailsAction,
            header: "standardVersion.list.header",
            itemActions: contextMenuActions,
            createUrl: hasAccessRight("createMasterData") ? getResolvedCreateUrl("standardVersion") : undefined,
            tableName: "versions",
        }),
        [contextMenuActions, getResolvedCreateUrl, hasAccessRight, query, columnOptions, showDetailsAction]
    );
}

export default useVersionSubList;
