import { TypedDetailViewProps } from "../../components/detailView/DetailView";
import CertificationLicenceBasicData from "./CertificationLicenceBasicData";
import { CertificationLicenceDetails } from "../certificationLicenceTypes";
import { UseGtbFormReturn } from "../../hooks/formHandling/useGtbForm";
import EntityDetailView from "../../components/entity/EntityDetailView";
import { useHelpContext } from "../../help/context-sensitivity/useHelpContext";

export default function CertificationLicenceDetailView({
    form,
    readOnlyProps,
    certificationBodyName,
    ...rest
}: CertificationLicenceDetailViewProps) {
    useHelpContext("certification-licence-details");
    return (
        <EntityDetailView form={form} {...rest}>
            <CertificationLicenceBasicData
                form={form}
                readOnlyProps={readOnlyProps}
                certificationBodyName={certificationBodyName}
            />
        </EntityDetailView>
    );
}

export interface CertificationLicenceDetailViewProps extends TypedDetailViewProps<CertificationLicenceDetails> {
    readOnlyProps: CertificationLicenceReadOnlyProps;
    certificationBodyName: string;
}

export interface CertificationLicenceDetailViewTabProps {
    form: UseGtbFormReturn<CertificationLicenceDetails>;
    readOnlyProps: CertificationLicenceReadOnlyProps;
    certificationBodyName: string;
}

export interface CertificationLicenceReadOnlyProps {
    isStandardReadOnly: boolean;
    isLicencingCodeReadOnly: boolean;
    isStartDateReadOnly: boolean;
    isEndDateReadOnly: boolean;
}
