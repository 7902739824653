import React from "react";
import { useHelpContext } from "../../../../../../help/context-sensitivity/useHelpContext";
import EntityDetailView from "../../../../../../components/entity/EntityDetailView";
import Tabs, { TabKey } from "../../../../../../components/tabs/Tabs";
import { TypedDetailViewProps } from "../../../../../../components/detailView/DetailView";
import { ScMaterialDetails } from "../scMaterialTypes";
import ScMaterialBasicData from "./ScMaterialBasicData";

export default function ScMaterialDetailView({
    detailViewProps,
    form,
    readOnly,
    materialPrintVisible,
    scopeCertificateId,
    ...rest
}: ScMaterialDetailViewProps) {
    useHelpContext("sc-material-details");

    return (
        <EntityDetailView {...rest} detailViewProps={detailViewProps} form={form}>
            <Tabs<ScMaterialDetails, TabKey<"scMaterial.detail.basicData_tab">>
                tabs={[
                    {
                        heading: "scMaterial.detail.basicData_tab",
                        element: (
                            <ScMaterialBasicData
                                form={form}
                                readOnly={readOnly}
                                materialPrintVisible={materialPrintVisible}
                                scopeCertificateId={scopeCertificateId}
                            />
                        ),
                    },
                ]}
                errors={form.form.formState.errors}
                errorToTab={{
                    id: "internal",
                    scProductScopeCertificateSupplyChainOperatorBusinessName: "scMaterial.detail.basicData_tab",
                    scProductScProductNumber: "scMaterial.detail.basicData_tab",
                    scProductProductDetailName: "scMaterial.detail.basicData_tab",
                    scProductProductDetailPrint: "scMaterial.detail.basicData_tab",
                    scProductProductCategoryName: "scMaterial.detail.basicData_tab",
                    scProductProductCategoryPrint: "scMaterial.detail.basicData_tab",
                    scProductLabelGradeLabelGrade: "scMaterial.detail.basicData_tab",
                    material: "scMaterial.detail.basicData_tab",
                    materialPrint: "scMaterial.detail.basicData_tab",
                    minPercentage: "scMaterial.detail.basicData_tab",
                    maxPercentage: "scMaterial.detail.basicData_tab",
                    materialName: "internal",
                    scProductScopeCertificateStatus: "internal",
                }}
            />
        </EntityDetailView>
    );
}

export interface ScMaterialDetailViewProps extends TypedDetailViewProps<ScMaterialDetails> {
    materialPrintVisible: boolean;
    readOnly: boolean;
    scopeCertificateId: string;
}
