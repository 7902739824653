import { useMemo } from "react";
import { buildDataColumn, buildNumberColumn, buildTextColumn } from "../components/grid/ColumnProvider";
import { AuthorisationRoleDetails, AuthorisationRoleSummary } from "./authorisationRoleTypes";
import useIsAuthorised from "../auth/useIsAuthorised";
import { useDeleteAction, useShowDetailsAction } from "../utils/defaultActions";
import useListView from "../hooks/useListView";
import { SortOption } from "../components/grid/component/useQueryBuilder";
import { EntityListViewWithoutInfinityScrollGridOptions } from "../components/entity/EntityListViewExcludingInfinityScroll";
import { GridColumn } from "../components/grid/component/Grid";
import useResolvedRoute, { getCreateUrl } from "../components/routing/useResolvedRoute";
import { EnumEntityType } from "../components/Input/pickList/typedEnumPicklist/enumEntityType";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";

export const defaultAuthorisationRoleSortOption: SortOption<AuthorisationRoleSummary | AuthorisationRoleDetails> = {
    field: "name",
    direction: "ASC",
};

const columns: GridColumn<AuthorisationRoleSummary>[] = [
    buildTextColumn({ key: "name", title: "authorisationRole.list.roleName_column", minWidth: 137 }),
    buildDataColumn<AuthorisationRoleSummary, EnumEntityType>({
        key: "type",
        title: "authorisationRole.list.organisationType_column",
        minWidth: 262,
        url: "/certificate-service/references/organisation-types",
        itemId: "id",
        itemLabel: "name",
    }),
    buildNumberColumn({ key: "level", title: "authorisationRole.list.roleLevel_column", minWidth: 131 }),
];

export default function useAuthorisationRoleListView() {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedEditUrl } = useResolvedRoute();

    const {
        queryBuilder: { query, columnOptions, forceReload, queryCommands },
    } = useListView<AuthorisationRoleSummary>({
        navigationContextLabel: "authorisationRole.breadcrumb",
        baseQuery: "/certificate-service/roles",
        defaultSortOption: defaultAuthorisationRoleSortOption,
    });

    const showDetailsAction = useShowDetailsAction(
        (item: AuthorisationRoleSummary) => getResolvedEditUrl("authorisationRole", item.id),
        query.parameter
    );

    const deleteAction = useDeleteAction(
        (item: AuthorisationRoleSummary) => `/certificate-service/roles/${item.id}`,
        () => forceReload(),
        "deleteAuthorisationRole"
    );

    const actions = useGridRowContextMenuActions(showDetailsAction, deleteAction);

    return useMemo<EntityListViewWithoutInfinityScrollGridOptions<AuthorisationRoleSummary>>(() => {
        return {
            query,
            columns,
            uniqueDataKey: "id",
            onItemDoubleClick: showDetailsAction,
            createUrl: hasAccessRight("createAuthorisationRole") ? getCreateUrl("authorisationRole") : undefined,
            itemActions: actions,
            tableName: "authorisationRole",
            columnOptions,
            queryCommands,
            bulkDeleteAccessRight: "deleteAuthorisationRole",
        };
    }, [actions, columnOptions, hasAccessRight, query, queryCommands, showDetailsAction]);
}
