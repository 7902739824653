import useIsAuthorised from "../../../auth/useIsAuthorised";
import useResolvedRoute from "../../../components/routing/useResolvedRoute";
import { SortOption, useQueryBuilder } from "../../../components/grid/component/useQueryBuilder";
import { useDeleteAction, useShowDetailsAction } from "../../../utils/defaultActions";
import { useMemo } from "react";
import { SubListProps } from "../../../components/grid/SubList";
import { GridColumn } from "../../../components/grid/component/Grid";
import { buildDateColumn, buildNumberColumn, buildTextColumn } from "../../../components/grid/ColumnProvider";
import { ShipmentDetails, ShipmentSummary } from "./shipmentTypes";
import { TransactionCertificateStatusType } from "../../../auth/roleTypes";
import { transactionCertificateReadOnlyByStatus } from "../../transactionCertificateTypes";
import useGridRowContextMenuActions from "../../../components/grid/component/cell/useGridRowContextMenuActions";

export const defaultShipmentSortOption: SortOption<ShipmentSummary | ShipmentDetails> = {
    field: "shipmentNumber",
    direction: "ASC",
};

const columns: GridColumn<ShipmentSummary>[] = [
    buildTextColumn({ key: "shipmentNumber", title: "shipment.list.shipmentNumber_column", minWidth: 192 }),
    buildDateColumn({ key: "shipmentDate", title: "shipment.list.shipmentDate_column", minWidth: 167 }),
    buildTextColumn({ key: "consignee", title: "shipment.list.consignee_column", minWidth: 138 }),
    buildNumberColumn({
        key: "grossShippingWeight",
        title: "shipment.list.grossShippingWeight_column",
        allowDecimals: true,
        fixedDecimal: true,
        minWidth: 263,
    }),
    buildTextColumn({
        key: "countryOfDestinationName",
        title: "shipment.list.countryAreaOfDestination_column",
        minWidth: 265,
        visible: false,
    }),
    buildTextColumn({
        key: "shipmentDocumentNumber",
        title: "shipment.list.shipmentDocumentNumber_column",
        minWidth: 304,
        visible: false,
    }),
    buildTextColumn({
        key: "invoiceNumbersAndDates",
        title: "shipment.list.invoiceNumbersAndDates_column",
        minWidth: 263,
        visible: false,
    }),
];

export default function useShipmentSubList(transactionCertificateStatus: TransactionCertificateStatusType) {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedEditUrl, getResolvedCreateUrl, getResolvedId } = useResolvedRoute();
    const transactionCertificateId = getResolvedId("transactionCertificate");

    const { query, columnOptions, forceReload } = useQueryBuilder<ShipmentSummary>(
        `/certificate-service/transaction-certificates/${transactionCertificateId}/shipments`,
        {
            sort: defaultShipmentSortOption,
            filterActive: false,
        }
    );

    const showDetailsAction = useShowDetailsAction(
        (item: ShipmentSummary) => getResolvedEditUrl("shipment", item.id),
        query.parameter
    );

    const deleteAction = useDeleteAction(
        (item: ShipmentSummary) =>
            `/certificate-service/transaction-certificates/${transactionCertificateId}/shipments/${item.id}`,
        () => forceReload(),
        "updateTransactionCertificate",
        transactionCertificateStatus ? [transactionCertificateStatus] : []
    );

    const contextMenuActions = useGridRowContextMenuActions(
        showDetailsAction,
        !transactionCertificateReadOnlyByStatus(transactionCertificateStatus) ? deleteAction : undefined
    );

    return useMemo<SubListProps<ShipmentSummary>>(
        () => ({
            query,
            columns,
            uniqueDataKey: "id",
            onItemDoubleClick: showDetailsAction,
            header: "shipment.list.header",
            itemActions: contextMenuActions,
            columnOptions,
            createUrl:
                !transactionCertificateReadOnlyByStatus(transactionCertificateStatus) &&
                hasAccessRight("updateTransactionCertificate", [transactionCertificateStatus])
                    ? getResolvedCreateUrl("shipment")
                    : undefined,
            tableName: "shipments",
        }),
        [
            columnOptions,
            contextMenuActions,
            getResolvedCreateUrl,
            hasAccessRight,
            query,
            transactionCertificateStatus,
            showDetailsAction,
        ]
    );
}
