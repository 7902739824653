import TcMaterialBasicData, { TcMaterialBasicDataProps } from "./TcMaterialBasicData";
import { useHelpContext } from "../../../../../../help/context-sensitivity/useHelpContext";
import { TypedDetailViewProps } from "../../../../../../components/detailView/DetailView";
import { TcMaterialDetails } from "../tcMaterialTypes";
import Tabs, { TabKey } from "../../../../../../components/tabs/Tabs";
import EntityDetailView from "../../../../../../components/entity/EntityDetailView";

export default function TcMaterialDetailView({
    form,
    readOnly,
    rawMaterialPrintVisible,
    onPercentageChange,
    ...rest
}: TcMaterialDetailViewProps) {
    useHelpContext("tc-material-details");

    return (
        <EntityDetailView {...rest} form={form}>
            <Tabs<TcMaterialDetails, TabKey<"tcMaterial.detail.basicData_tab">>
                tabs={[
                    {
                        heading: "tcMaterial.detail.basicData_tab",
                        element: (
                            <TcMaterialBasicData
                                form={form}
                                rawMaterialPrintVisible={rawMaterialPrintVisible}
                                onPercentageChange={onPercentageChange}
                                readOnly={readOnly}
                            />
                        ),
                    },
                ]}
                errors={form.form.formState.errors}
                errorToTab={{
                    id: "internal",
                    transactionCertificateStatus: "internal",
                    scMaterialMinPercentage: "internal",
                    scMaterialMaxPercentage: "internal",
                    tcProductNetShippingWeight: "internal",
                    tcProductSupplementaryWeight: "internal",
                    tcProductTcProductNumber: "tcMaterial.detail.basicData_tab",
                    tcProductProductCategoryPrint: "tcMaterial.detail.basicData_tab",
                    tcProductProductDetailPrint: "tcMaterial.detail.basicData_tab",
                    tcProductLabelGradeLabelGrade: "tcMaterial.detail.basicData_tab",
                    material: "tcMaterial.detail.basicData_tab",
                    materialPrint: "tcMaterial.detail.basicData_tab",
                    materialName: "tcMaterial.detail.basicData_tab",
                    certified: "tcMaterial.detail.basicData_tab",
                    percentage: "tcMaterial.detail.basicData_tab",
                    certifiedWeight: "tcMaterial.detail.basicData_tab",
                    origin: "tcMaterial.detail.basicData_tab",
                }}
            />
        </EntityDetailView>
    );
}

export interface TcMaterialDetailViewProps extends TypedDetailViewProps<TcMaterialDetails>, TcMaterialBasicDataProps {}
