import { joinClassNames } from "../../utils/StringUtils";
import GtbTextField from "../../components/Input/GtbTextField";
import "./facilityBasicData.css";
import { FacilityDetailViewTabProps } from "./FacilityDetailView";
import ControlledDatePicker from "../../components/Input/datepicker/ControlledDatePicker";
import { LabeledCheckbox } from "../../components/Input/Checkbox";
import { useMemo } from "react";

export default function FacilityBasicData({
    form,
    readOnlyProps,
    supplyChainOperatorLegalName,
    supplyChainOperator,
    requiresLegalNameInEnglish,
}: FacilityDetailViewTabProps) {
    const independentLegalEntity = form.form.watch("independentLegalEntity");

    const displayLegalNameInEnglishInputClassName = useMemo(
        () =>
            requiresLegalNameInEnglish && independentLegalEntity
                ? "gridIncludingLegalNameInEnglish"
                : "gridExcludingLegalNameInEnglish",
        [requiresLegalNameInEnglish, independentLegalEntity]
    );

    return (
        <div
            className={joinClassNames(
                "facilityBasicData",
                "organisationBasicData",
                "formGrid",
                displayLegalNameInEnglishInputClassName
            )}
        >
            <GtbTextField
                readOnly
                label="facility.detail.scoBusinessName_input"
                className="scoBusinessName"
                value={supplyChainOperator ?? ""}
            />
            <GtbTextField
                readOnly
                label="facility.detail.scoLegalName_input"
                className="scoLegalName"
                value={supplyChainOperatorLegalName ?? ""}
            />
            <LabeledCheckbox
                {...form.form.register("independentLegalEntity")}
                readOnly={readOnlyProps.isIndependentLegalEntityReadOnly}
                id={"independentLegalEntity"}
                label="facility.detail.independentLegalEntity_input"
                className="independentLegalEntity"
            />
            <GtbTextField
                {...form.registerWithErrors("businessName")}
                readOnly={readOnlyProps.isBusinessNameReadOnly}
                label="facility.detail.businessName_input"
                className="businessName"
            />
            {independentLegalEntity && (
                <GtbTextField
                    {...form.registerWithErrors("legalName")}
                    readOnly={readOnlyProps.isLegalNameReadOnly}
                    label="facility.detail.legalName_input"
                    className="legalName"
                />
            )}
            {independentLegalEntity && requiresLegalNameInEnglish && (
                <GtbTextField
                    {...form.registerWithErrors("legalNameInEnglish")}
                    readOnly={readOnlyProps.isLegalNameInEnglishReadOnly}
                    label={"facility.detail.legalNameInEnglish_input"}
                    className={"legalNameInEnglish"}
                />
            )}
            <GtbTextField
                {...form.registerWithErrors("website")}
                readOnly={readOnlyProps.isWebsiteReadOnly}
                label="facility.detail.website_input"
                className="website"
            />
            <GtbTextField
                {...form.registerWithErrors("organisationalEmail")}
                readOnly={readOnlyProps.isOrganisationalEmailReadOnly}
                label="facility.detail.organisationalEmailAddress_input"
                className="organisationalEmail"
            />
            <GtbTextField
                {...form.registerWithErrors("organisationalPhoneNumber")}
                readOnly={readOnlyProps.isOrganisationalPhoneNumberReadOnly}
                label="facility.detail.organisationalPhoneNumber_input"
                className="organisationalPhoneNumber"
            />
            <ControlledDatePicker
                control={form.form.control}
                readOnly={readOnlyProps.isStartDateReadOnly}
                name="startDate"
                label="facility.detail.startDate_input"
                className="startDate"
            />
            <ControlledDatePicker
                control={form.form.control}
                readOnly={readOnlyProps.isEndDateReadOnly}
                name="endDate"
                label="facility.detail.endDate_input"
                className="endDate"
            />
            <GtbTextField
                {...form.registerWithErrors("organisationStatus")}
                readOnly
                label="facility.detail.status_input"
                className="status"
            />
        </div>
    );
}
