import InfinityScrollSubList from "../../../../components/grid/InfinityScrollSubList";
import useOrganicMaterialTransactionCertificateSubList from "./organicMaterialTransactionCertificate/useOrganicMaterialTransactionCertificateSubList";

export default function OrganicMaterialTransactionCertificates() {
    const organicMaterialTransactionCertificatesSubList = useOrganicMaterialTransactionCertificateSubList();
    return (
        <InfinityScrollSubList
            {...organicMaterialTransactionCertificatesSubList}
            className="organicMaterialTransactionCertificate"
        />
    );
}
