import useGtbTranslation from "../../../i18n/useGtbTranslation";
import { TFuncKey, TOptions } from "i18next";

export default function GridItemCount({
    itemCountTranslation,
}: {
    itemCountTranslation: { key: TFuncKey<"en">; options: TOptions };
}) {
    const translation = useGtbTranslation();
    return (
        <span className="gridItemCount" aria-live="polite" role="region">
            {itemCountTranslation?.options?.count !== undefined ? translation(itemCountTranslation) : ""}
        </span>
    );
}
