import {HTMLInputTypeAttribute, useCallback, useState} from 'react';
import {IconName} from "../Icon";

interface UsePasswordFieldType {
    passwordFieldType: HTMLInputTypeAttribute;
    passwordVisibilityToggleIcon: IconName;
    toggleShowPassword: () => void;
}

function usePasswordField(): UsePasswordFieldType {
    const [passwordVisible, setShowPassword] = useState(false);

    const toggleShowPassword = useCallback(
        () => {
            setShowPassword(isVisible => !isVisible);
        },
        [setShowPassword],
    );

    return {
        passwordFieldType: passwordVisible ? "text" : "password",
        passwordVisibilityToggleIcon: passwordVisible ? "eye-slash" : "eye",
        toggleShowPassword
    }
}

export default usePasswordField;
