import { TypedPicklist } from "./typedPicklistType";
import DataPickList from "../DataPickList";
import "./typedPickList.css";
import { OrganicMaterialProducerReference } from "../../../../organicMaterialProducers/organicMaterialProducerTypes";
import { joinClassNames } from "../../../../utils/StringUtils";
import { FieldValues } from "react-hook-form/dist/types";
import { PickListItemPresentationProps } from "../GtbPickList";

interface OrganicMaterialProducerPickListProps<FormItemType extends FieldValues>
    extends TypedPicklist<FormItemType, OrganicMaterialProducerReference> {
    flags?: { allActive?: boolean };
    itemLabel?: PickListItemPresentationProps<OrganicMaterialProducerReference>["itemLabel"];
}

export default function OrganicMaterialProducerPickList<FormItemType extends FieldValues>({
    className,
    flags,
    ...rest
}: OrganicMaterialProducerPickListProps<FormItemType>) {
    return (
        <DataPickList
            className={joinClassNames("organicMaterialProducerPickList", className)}
            itemId="id"
            selectedItemLabel={({ legalName, legalNameInEnglish }) =>
                `${legalName}${legalNameInEnglish ? " (" + legalNameInEnglish + ")" : ""}`
            }
            itemLabel={({ legalName, legalNameInEnglish, town, stateProvinceCountryAreaName, stateProvinceIsoCode }) =>
                [legalName, legalNameInEnglish, `${town}, ${stateProvinceCountryAreaName}, (${stateProvinceIsoCode})`]
                    .filter((s) => s)
                    .join("\n")
            }
            queryUrl="/certificate-service/references/organic-material-producers"
            flags={{ ...flags }}
            {...rest}
        />
    );
}
