import GridRow from "./GridRow";
import GridBodyCell from "./GridBodyCell";
import { GridColumn } from "./Grid";
import GridRowContextMenu, { GridRowContextMenuAction } from "./cell/GridRowContextMenu";
import { Fragment, HTMLAttributes, useCallback } from "react";
import { useGtbNavigate } from "../../routing/GtbRouter";
import { Entity } from "../../../utils/typeUtils";

export type onItemDoubleClickType<ItemType> = GridRowContextMenuAction<ItemType>;

export interface GridBodyProps<ItemType extends Entity> {
    columns: GridColumn<ItemType>[];
    data: ItemType[] | undefined;
    onItemDoubleClick?: onItemDoubleClickType<ItemType>;
    itemActions?: GridRowContextMenuAction<ItemType>[];
    rowAttributeProvider?: (item: ItemType) => Omit<HTMLAttributes<"tr">, "onDoubleClick">;
}

export default function GridBody<ItemType extends Entity>({
    columns,
    data,
    onItemDoubleClick,
    itemActions,
    rowAttributeProvider,
}: GridBodyProps<ItemType>) {
    const navigate = useGtbNavigate();

    const doubleClickHandler = useCallback(
        (item: ItemType) => {
            if (!onItemDoubleClick) {
                return;
            }
            if ("navigationTargetProducer" in onItemDoubleClick) {
                navigate(onItemDoubleClick.navigationTargetProducer(item), { state: onItemDoubleClick.state });
            } else {
                onItemDoubleClick.onClick(item);
            }
        },
        [navigate, onItemDoubleClick]
    );

    return (
        <tbody>
            {data &&
                data.map((item) => {
                    let actionsPlaced = !itemActions;
                    return (
                        <Fragment key={item.id}>
                            <GridRow
                                {...rowAttributeProvider?.(item)}
                                onDoubleClick={onItemDoubleClick ? () => doubleClickHandler(item) : undefined}
                            >
                                {columns.map((column) => {
                                    let _contentProvider = column.bodyItem.contentProvider;
                                    // find first column that has a title
                                    if (!actionsPlaced && column.header.title) {
                                        actionsPlaced = true;
                                        _contentProvider = buildActionContentProvider(column, itemActions);
                                    }
                                    return (
                                        <GridBodyCell
                                            key={column.key}
                                            item={item}
                                            contentProvider={_contentProvider}
                                            className={column.className}
                                        />
                                    );
                                })}
                            </GridRow>
                        </Fragment>
                    );
                })}
        </tbody>
    );
}

function buildActionContentProvider<ItemType>(
    column: GridColumn<ItemType>,
    itemActions: GridRowContextMenuAction<ItemType>[] | undefined
) {
    return (item: ItemType) => (
        <>
            {column.bodyItem.contentProvider(item)}
            <GridRowContextMenu item={item} actions={itemActions as GridRowContextMenuAction<ItemType>[]} />
        </>
    );
}
