import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "../../hooks/useAxios";
import useIsAuthorised from "../../auth/useIsAuthorised";
import { StandardDetails } from "../../standard/standardTypes";
import { ScopeDetails } from "../scopeTypes";
import { scopeValidationSchema } from "./scopeValidationSchema";
import { defaultScopeSortOption } from "../useScopeSublist";
import { ScopeDetailViewProps } from "./ScopeDetailView";
import useResolvedRoute, { routeEnum } from "../../components/routing/useResolvedRoute";
import { useMemo } from "react";
import useDomainEntityDetailsEdit from "../../hooks/details/useDomainEntityDetailsEdit";

function useScopeEdit(): ScopeDetailViewProps {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedId } = useResolvedRoute();
    const standardId = getResolvedId("standard");
    const scopeId = getResolvedId("scope");

    const { data: standard } = useQuery<StandardDetails>({
        url: "/certificate-service/standards/" + standardId + "?view=MINIMISED",
    });

    const { detailViewProps, form, isReadOnly, title, recordNavigationProps } =
        useDomainEntityDetailsEdit<ScopeDetails>({
            baseUrl: "/certificate-service/standards/" + standardId + "/scopes/" + scopeId,
            resolver: yupResolver(scopeValidationSchema),
            frontendRoute: "scope",
            recordNavigationDefaultSort: defaultScopeSortOption,
            titleBuilder: (item) => item.name,
            canSave: hasAccessRight("updateMasterData"),
            historyColumns: [
                { identifier: "standard", title: "scope.history.standard_column" },
                { identifier: "name", title: "scope.history.name_column" },
                { identifier: "description", title: "scope.history.description_column" },
                { identifier: "startDate", title: "scope.history.startDate_column" },
                { identifier: "endDate", title: "scope.history.endDate_column" },
            ],
        });

    const createRoute = useMemo<routeEnum | undefined>(() => {
        return hasAccessRight("createMasterData") ? "scope" : undefined;
    }, [hasAccessRight]);

    return {
        detailViewProps,
        form,
        isReadOnly,
        title,
        standard: `${standard?.acronym}`,
        recordNavigationProps,
        createRoute,
    };
}

export default useScopeEdit;
