import { joinClassNames } from "../../utils/StringUtils";
import { UseGtbFormReturn } from "../../hooks/formHandling/useGtbForm";
import { DynamicUserGroupDetails } from "../dynamicUserGroupTypes";
import GtbTextField from "../../components/Input/GtbTextField";
import GtbTextArea from "../../components/Input/GtbTextArea";
import "./dynamicUserGroupBasicData.css";

export interface DynamicUserGroupBasicDataProps {
    form: UseGtbFormReturn<DynamicUserGroupDetails>;
}

export default function DynamicUserGroupBasicData({ form }: DynamicUserGroupBasicDataProps) {
    return (
        <div className={joinClassNames("dynamicUserGroupBasicData", "formGrid")}>
            <GtbTextField
                {...form.registerWithErrors("name")}
                label={("dynamicUserGroup.detail.name_input")}
                className="name"
            />
            <GtbTextArea
                {...form.registerWithErrors("description")}
                label={("dynamicUserGroup.detail.description_input")}
                className="description"
            />
            <GtbTextArea
                {...form.registerWithErrors("definition")}
                label={("dynamicUserGroup.detail.queryDefinition_input")}
                className="definition"
            />
        </div>
    );
}
