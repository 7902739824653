import React from "react";
import Header from "./header/Header";
import MainMenu from "./mainMenu/MainMenu";
import Footer from "./footer/Footer";
import "./mainLayout.css";
import { Outlet } from "react-router-dom";
import HeaderMenus from "./header/HeaderMenus";
import useSessionTimeout from "../auth/useSessionTimeout";
import useAcceptTermsOfUse from "../user/termsOfUse/useAcceptTermsOfUse";

function MainLayout() {
    useSessionTimeout();
    useAcceptTermsOfUse();

    return (
        <div className="mainLayout">
            <Header>
                <HeaderMenus />
            </Header>

            <MainMenu />
            <main>
                <Outlet />
            </main>
            <Footer />
        </div>
    );
}

export default MainLayout;
