import { useWatch } from "react-hook-form";
import useSetFormValue from "../../hooks/formHandling/useSetFormValue";
import { useEffect } from "react";
import { UseGtbFormReturn } from "../../hooks/formHandling/useGtbForm";
import { TimeBasedMailDetails } from "../timeBasedMailTypes";

export default function useTargetDateTime(
    form: UseGtbFormReturn<TimeBasedMailDetails>,
    initialData?: TimeBasedMailDetails
) {
    useEffect(() => {
        if (initialData) {
            const targetDate = new Date(initialData.targetDateTime + "Z");
            const targetTime = new Date(initialData.targetDateTime + "Z");

            form.form.reset({
                ...initialData,
                targetDate,
                targetTime,
                targetDateTime: getDateTimeFromDateAndTime(targetDate, targetTime),
            });
        }
    }, [form.form, initialData]);

    const [targetDate, targetTime] = useWatch({
        control: form.form.control,
        name: ["targetDate", "targetTime"],
    });

    const setFormValue = useSetFormValue(form.form);

    useEffect(() => {
        if (targetDate && targetTime) {
            setFormValue("targetDateTime", getDateTimeFromDateAndTime(targetDate, targetTime), false);
        }
    }, [setFormValue, targetDate, targetTime]);
}

const getDateTimeFromDateAndTime = (date: Date, time: Date) => {
    const localeDateTime = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        time.getHours(),
        time.getMinutes()
    );

    return new Date(
        localeDateTime.getUTCFullYear(),
        localeDateTime.getUTCMonth(),
        localeDateTime.getUTCDate(),
        localeDateTime.getUTCHours(),
        localeDateTime.getUTCMinutes()
    );
};
