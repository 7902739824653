import {
    buildBooleanColumn,
    buildDataColumn,
    buildDateColumn,
    buildNumberColumn,
    buildTextColumn,
} from "../components/grid/ColumnProvider";
import { FacilityDetails, FacilitySummary } from "./facilityTypes";
import { useMemo } from "react";
import useIsAuthorised from "../auth/useIsAuthorised";
import { useDeleteAction, useShowDetailsAction } from "../utils/defaultActions";
import { SortOption, useQueryBuilder } from "../components/grid/component/useQueryBuilder";
import { GridColumn } from "../components/grid/component/Grid";
import useResolvedRoute from "../components/routing/useResolvedRoute";
import { SubListProps } from "../components/grid/SubList";
import { EnumEntityType } from "../components/Input/pickList/typedEnumPicklist/enumEntityType";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";

export const defaultFacilitySortOption: SortOption<FacilitySummary | FacilityDetails> = {
    field: "systemId",
    direction: "ASC",
};

const columns: GridColumn<FacilitySummary>[] = [
    buildTextColumn({ key: "systemId", title: "facility.list.systemId_column", minWidth: 134 }),
    buildTextColumn({ key: "businessName", title: "facility.list.businessName_column", minWidth: 174 }),
    buildTextColumn({ key: "town", title: "facility.list.town_column", minWidth: 123 }),
    buildTextColumn({
        key: "stateProvinceCountryAreaName",
        title: "facility.list.countryArea_column",
        minWidth: 155,
    }),
    buildTextColumn({
        key: "stateProvinceName",
        title: "facility.list.stateProvince_column",
        minWidth: 151,
    }),
    buildDataColumn<FacilitySummary, EnumEntityType>({
        key: "organisationStatus",
        title: "facility.list.status_column",
        minWidth: 190,
        visible: false,
        itemId: "id",
        itemLabel: "name",
        url: "/certificate-service/references/organisation-statuses",
    }),
    buildTextColumn({
        key: "addressDetails1",
        title: "facility.list.addressDetail1_column",
        minWidth: 180,
        visible: false,
    }),
    buildTextColumn({
        key: "addressDetails2",
        title: "facility.list.addressDetail2_column",
        minWidth: 180,
        visible: false,
    }),
    buildTextColumn({
        key: "addressDetails3",
        title: "facility.list.addressDetail3_column",
        minWidth: 180,
        visible: false,
    }),
    buildDateColumn({
        key: "endDate",
        title: "facility.list.endDate_column",
        minWidth: 124,
        visible: false,
    }),
    buildTextColumn({
        key: "fixedRegistrationId",
        title: "facility.list.fixedRegistrationId_column",
        minWidth: 195,
        visible: false,
    }),
    buildTextColumn({
        key: "legalName",
        title: "facility.list.legalName_column",
        minWidth: 145,
        visible: false,
    }),
    buildTextColumn({
        key: "legalNameInEnglish",
        title: "facility.list.legalNameInEnglish_column",
        minWidth: 145,
        visible: false,
    }),
    buildTextColumn({
        key: "organisationalEmail",
        title: "facility.list.organisationalEmailAddress_column",
        minWidth: 280,
        visible: false,
    }),
    buildTextColumn({
        key: "organisationalPhoneNumber",
        title: "facility.list.organisationalPhoneNumber_column",
        minWidth: 284,
        visible: false,
    }),
    buildTextColumn({
        key: "postCode",
        title: "facility.list.postcode_column",
        minWidth: 128,
        visible: false,
    }),
    buildDateColumn({
        key: "startDate",
        title: "facility.list.startDate_column",
        minWidth: 130,
        visible: false,
    }),
    buildBooleanColumn({
        key: "independentLegalEntity",
        title: "facility.list.independentLegalEntity_column",
        minWidth: 130,
        visible: false,
    }),
    buildTextColumn({
        key: "website",
        title: "facility.list.website_column",
        minWidth: 123,
        visible: false,
    }),
    buildNumberColumn({
        key: "numberOfEmployees",
        title: "facility.list.numberOfEmployees_column",
        minWidth: 208,
        visible: false,
    }),
    buildNumberColumn({
        key: "latitude",
        title: "facility.list.latitude_column",
        allowDecimals: true,
        allowNegative: true,
        minWidth: 119,
        visible: false,
    }),
    buildNumberColumn({
        key: "longitude",
        title: "facility.list.longitude_column",
        allowDecimals: true,
        allowNegative: true,
        minWidth: 134,
        visible: false,
    }),
];

export default function useFacilitySubList() {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedId, getResolvedEditUrl, getResolvedCreateUrl } = useResolvedRoute();
    const supplyChainOperatorId = getResolvedId("supplyChainOperator");

    const { query, columnOptions, forceReload } = useQueryBuilder<FacilitySummary>(
        `/certificate-service/supply-chain-operators/${supplyChainOperatorId}/facilities`,
        {
            sort: defaultFacilitySortOption,
            filterActive: false,
        }
    );

    const showDetailsAction = useShowDetailsAction(
        (item: FacilitySummary) => getResolvedEditUrl("facility", item.id),
        query.parameter
    );

    const deleteAction = useDeleteAction(
        (item: FacilitySummary) =>
            `/certificate-service/supply-chain-operators/${supplyChainOperatorId}/facilities/${item.id}`,
        () => forceReload(),
        "deleteOperationLevelOrganisation"
    );

    const contextMenuActions = useGridRowContextMenuActions(showDetailsAction, deleteAction);

    return useMemo<SubListProps<FacilitySummary>>(
        () => ({
            query,
            columns,
            uniqueDataKey: "id" as keyof FacilitySummary,
            onItemDoubleClick: showDetailsAction,
            header: "facility.list.header",
            itemActions: contextMenuActions,
            columnOptions,
            createUrl: hasAccessRight("createOperationLevelOrganisation")
                ? getResolvedCreateUrl("facility")
                : undefined,
            tableName: "facilities",
        }),
        [columnOptions, contextMenuActions, getResolvedCreateUrl, hasAccessRight, query, showDetailsAction]
    );
}
