import { Fragment } from "react";
import GtbLink, { GtbLinkProps } from "../../../routing/GtbLink";
import GtbButton from "../../../GtbButton";
import "./gridRowContextMenu.css";

export type GridRowContextMenuAction<ItemType> = {
    label: string;
    variant?: "warning";
} & (
    | ({ navigationTargetProducer: (item: ItemType) => string } & Pick<GtbLinkProps, "state">)
    | { onClick: (item: ItemType) => void }
);

export default function GridRowContextMenu<ItemType>({
    item,
    actions,
}: {
    item: ItemType;
    actions: GridRowContextMenuAction<ItemType>[];
}) {
    return (
        <div className="gridRow-contextMenu">
            {actions.length > 0 && (
                <>
                    {buildAction(actions[0], item)}
                    {actions.slice(1).map((action) => (
                        <Fragment key={action.label}>
                            <div className="separator" />
                            {buildAction(action, item)}
                        </Fragment>
                    ))}
                </>
            )}
        </div>
    );
}

export const buildAction = <ItemType,>(action: GridRowContextMenuAction<ItemType>, item: ItemType) => {
    if ("navigationTargetProducer" in action) {
        return (
            <GtbLink to={action.navigationTargetProducer(item)} state={action.state} className={action.variant}>
                {action.label}
            </GtbLink>
        );
    }
    return (
        <GtbButton
            variant="tertiary"
            onClick={() => (action.onClick as (item: ItemType) => void)(item)}
            className={action.variant}
        >
            {action.label}
        </GtbButton>
    );
};
