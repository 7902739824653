import useIsAuthorised from "../auth/useIsAuthorised";
import { SortOption } from "../components/grid/component/useQueryBuilder";
import { useMemo } from "react";
import { GridColumn } from "../components/grid/component/Grid";
import useListView from "../hooks/useListView";
import { useDeleteAction, useShowDetailsAction } from "../utils/defaultActions";
import { ProductDetailDetails, ProductDetailSummary } from "./productDetailTypes";
import { buildDateColumn, buildNumberColumn, buildTextColumn } from "../components/grid/ColumnProvider";
import { EntityListViewGridProps } from "../components/entity/EntityListView";
import useResolvedRoute, { getCreateUrl } from "../components/routing/useResolvedRoute";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";

export const defaultProductDetailSortOption: SortOption<ProductDetailSummary | ProductDetailDetails> = {
    field: "code",
    direction: "ASC",
};

const columns: GridColumn<ProductDetailSummary>[] = [
    buildTextColumn({ key: "code", title: "productDetail.list.code_column", minWidth: 123 }),
    buildTextColumn({ key: "name", title: "productDetail.list.name_column", minWidth: 123 }),
    buildNumberColumn({ key: "pickListSequence", title: "productDetail.list.pickListSequence_column", minWidth: 195 }),
    buildDateColumn({ key: "endDate", title: "productDetail.list.endDate_column", minWidth: 124, visible: false }),
    buildDateColumn({ key: "startDate", title: "productDetail.list.startDate_column", minWidth: 130, visible: false }),
];

function useProductDetailListView() {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedEditUrl } = useResolvedRoute();

    const {
        queryBuilder: { forceReload, columnOptions, query, queryCommands },
    } = useListView<ProductDetailSummary>({
        navigationContextLabel: "productDetail.breadcrumb",
        baseQuery: "/certificate-service/product-details",
        defaultSortOption: defaultProductDetailSortOption,
    });

    const showDetailsAction = useShowDetailsAction(
        (item: ProductDetailSummary) => getResolvedEditUrl("productDetail", item.id),
        query.parameter
    );

    const deleteAction = useDeleteAction(
        (item: ProductDetailSummary) => `/certificate-service/product-details/${item.id}`,
        () => forceReload(),
        "deleteMasterData"
    );

    const actions = useGridRowContextMenuActions(showDetailsAction, deleteAction);

    return useMemo<EntityListViewGridProps<ProductDetailSummary>>(() => {
        return {
            query,
            columns: columns,
            uniqueDataKey: "id" as keyof ProductDetailSummary,
            onItemDoubleClick: showDetailsAction,
            createUrl: hasAccessRight("createMasterData") ? getCreateUrl("productDetail") : undefined,
            tableName: "productDetail",
            itemActions: actions,
            queryCommands,
            columnOptions,
            bulkDeleteAccessRight: "deleteMasterData",
        };
    }, [query, showDetailsAction, hasAccessRight, actions, queryCommands, columnOptions]);
}

export default useProductDetailListView;
