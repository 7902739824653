import { TypedPicklist } from "./typedPicklistType";
import DataPickList from "../DataPickList";
import { joinClassNames } from "../../../../utils/StringUtils";
import { EnumEntityType } from "../typedEnumPicklist/enumEntityType";
import { FieldValues } from "react-hook-form/dist/types";
import "./typedPickList.css";

export default function TypeOfMaterialPicklist<FormItemType extends FieldValues>({
    className,
    ...rest
}: TypedPicklist<FormItemType, EnumEntityType>) {
    return (
        <DataPickList
            className={joinClassNames("typeOfMaterialPickList", className)}
            itemId="id"
            itemLabel="name"
            queryUrl="/certificate-service/references/type-of-materials"
            {...rest}
        />
    );
}
