import React, { useMemo } from "react";
import { useHelpContext } from "../../../../help/context-sensitivity/useHelpContext";
import EntityDetailView from "../../../../components/entity/EntityDetailView";
import Tabs, { TabKey, TabProps } from "../../../../components/tabs/Tabs";
import { ScProductDetails } from "../scProductTypes";
import { TypedDetailViewProps } from "../../../../components/detailView/DetailView";
import ScProductBasicData from "./ScProductBasicData";
import ScProcessCategories from "./processCategories/ScProcessCategories";
import ScMaterials from "./scMaterial/ScMaterials";
import { scopeCertificateStatusType } from "../../../../auth/roleTypes";

export default function ScProductDetailView({
    detailViewProps,
    form,
    scopeCertificateId,
    readOnly,
    labelGradesVisible,
    subListVisible,
    scopeCertificateStatus,
    ...rest
}: ScProductDetailViewProps) {
    useHelpContext("sc-product-details");

    const sublistTabs = useMemo<TabProps<ScProductTabs>[]>(
        () => [
            {
                heading: "scProduct.detail.scProcessCategory_tab",
                element: <ScProcessCategories scopeCertificateStatus={scopeCertificateStatus} />,
            },
            {
                heading: "scProduct.detail.scMaterial_tab",
                element: <ScMaterials scopeCertificateStatus={scopeCertificateStatus} />,
            },
        ],
        [scopeCertificateStatus]
    );

    return (
        <EntityDetailView {...rest} detailViewProps={detailViewProps} form={form}>
            <Tabs<ScProductDetails, ScProductTabs>
                tabs={[
                    {
                        heading: "scProduct.detail.basicData_tab",
                        element: (
                            <ScProductBasicData
                                form={form}
                                scopeCertificateId={scopeCertificateId}
                                readOnly={readOnly}
                                labelGradesVisible={labelGradesVisible}
                            />
                        ),
                    },
                    ...(subListVisible ? sublistTabs : []),
                ]}
                errors={form.form.formState.errors}
                errorToTab={{
                    id: "internal",
                    supplyChainOperatorBusinessName: "scProduct.detail.basicData_tab",
                    scProductNumber: "scProduct.detail.basicData_tab",
                    cbScProductReferenceNumber: "scProduct.detail.basicData_tab",
                    productDetail: "scProduct.detail.basicData_tab",
                    productCategory: "scProduct.detail.basicData_tab",
                    productDetailPrint: "scProduct.detail.basicData_tab",
                    labelGrade: "scProduct.detail.basicData_tab",
                    productCategoryPrint: "scProduct.detail.basicData_tab",
                    scopeCertificateStatus: "internal",
                }}
            />
        </EntityDetailView>
    );
}

type ScProductTabs = TabKey<
    "scProduct.detail.basicData_tab" | "scProduct.detail.scProcessCategory_tab" | "scProduct.detail.scMaterial_tab"
>;

export interface ScProductDetailViewProps extends TypedDetailViewProps<ScProductDetails> {
    scopeCertificateId: string;
    labelGradesVisible: boolean;
    readOnly: boolean;
    subListVisible: boolean;
    scopeCertificateStatus?: scopeCertificateStatusType;
}
