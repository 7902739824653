import { createContext, Dispatch, FC, ReactNode, Reducer, useContext, useReducer } from "react";

export interface ActionType<Type, Payload> {
    type: Type;
    payload: Payload;
}

export default function makeReducerStore<StateType, Action extends ActionType<any, any>>(
    reducer: Reducer<StateType, Action>,
    initialState: StateType
): [FC<{ children: ReactNode }>, () => Dispatch<Action>, () => StateType] {
    const dispatchContext = createContext<Dispatch<Action>>(() => {
        /**/
    });
    const storeContext = createContext<StateType>(initialState);

    const StoreProvider = ({ children }: { children: ReactNode }) => {
        const [store, dispatch] = useReducer(reducer, initialState);

        return (
            <dispatchContext.Provider value={dispatch}>
                <storeContext.Provider value={store}>{children}</storeContext.Provider>
            </dispatchContext.Provider>
        );
    };

    function useDispatch() {
        return useContext(dispatchContext);
    }

    function useStore() {
        return useContext(storeContext);
    }

    return [StoreProvider, useDispatch, useStore];
}
