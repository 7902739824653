import { useMemo } from "react";
import { ApplicationLogDetails, ApplicationLogSummary, logLevel, logLevelEnum } from "./applicationLogTypes";
import { buildDateTimeColumn, buildSystemEnumColumn, buildTextColumn } from "../components/grid/ColumnProvider";
import { useShowDetailsAction } from "../utils/defaultActions";
import useListView from "../hooks/useListView";
import { SortOption } from "../components/grid/component/useQueryBuilder";
import { GridColumn } from "../components/grid/component/Grid";
import useResolvedRoute from "../components/routing/useResolvedRoute";
import useGtbTranslation from "../i18n/useGtbTranslation";
import useIsAuthorised from "../auth/useIsAuthorised";
import { useMutation, useQuery } from "../hooks/useAxios";
import useAuth from "../auth/useAuth";
import { buildEnumOptionsFromObject } from "../utils/enumUtils";
import { ApplicationLogListViewProps } from "./ApplicationLogListView";
import { info } from "../utils/notification/notification";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";

export const defaultApplicationLogSortOption: SortOption<ApplicationLogSummary | ApplicationLogDetails> = {
    field: "timestamp",
    direction: "DESC",
};

const columns: GridColumn<ApplicationLogSummary>[] = [
    buildDateTimeColumn({ key: "timestamp", title: "applicationLog.list.timestamp_column", minWidth: 141 }),
    buildSystemEnumColumn({
        key: "logLevel",
        title: "applicationLog.list.logLevel_column",
        minWidth: 151,
        enumRecord: logLevel,
    }),
    buildTextColumn({ key: "message", title: "applicationLog.list.message_column", minWidth: 124 }),
    buildTextColumn({ key: "className", title: "applicationLog.list.className_column", minWidth: 145 }),
    buildTextColumn({ key: "sessionId", title: "applicationLog.list.sessionId_column", minWidth: 138 }),
    buildTextColumn({ key: "remarks", title: "applicationLog.list.remarks_column", minWidth: 124 }),
];

export default function useApplicationLogList() {
    const { hasAccessRight } = useIsAuthorised();
    const translation = useGtbTranslation();
    const { getResolvedEditUrl } = useResolvedRoute();

    const {
        data: currentLogLevel,
        isLoading,
        runQuery: reloadCurrentLogLevel,
    } = useQuery<logLevelEnum>({ url: "/certificate-service/runtime-parameters/log-level" });

    const { runQuery: setLogLevel } = useMutation({
        method: "put",
        url: "/certificate-service/runtime-parameters/log-level",
    });

    const { currentUser } = useAuth();

    const {
        queryBuilder: { query, columnOptions, queryCommands },
    } = useListView<ApplicationLogSummary>({
        navigationContextLabel: "applicationLog.breadcrumb",
        baseQuery: "/log-service/logs",
        defaultSortOption: {
            field: "timestamp",
            direction: "DESC",
        },
    });

    const showDetailsAction = useShowDetailsAction(
        (item: ApplicationLogSummary) => getResolvedEditUrl("applicationLog", item.id),
        query.parameter
    );

    const contextMenuActions = useGridRowContextMenuActions(showDetailsAction);

    return useMemo<ApplicationLogListViewProps>(() => {
        return {
            listProps: {
                query,
                columns,
                uniqueDataKey: "id",
                limit: 100,
                onItemDoubleClick: hasAccessRight("useApplicationLog") ? showDetailsAction : undefined,
                createUrl: undefined,
                tableName: "applicationLog",
                columnOptions,
                itemActions: contextMenuActions,
                queryCommands,
            },
            currentLogLevelProps: {
                isLoading,
                value: currentLogLevel ?? null,
                data: buildEnumOptionsFromObject(logLevel),
                label: "applicationLog.list.currentLogLevel_input",
                itemLabel: "label",
                itemId: "id",
                onChange: (_event, value) => {
                    if (value) {
                        setLogLevel({ body: { level: value } }).then(() => {
                            reloadCurrentLogLevel().finally(() =>
                                info(
                                    translation({
                                        key: "applicationLog.list.currentLogLevel_changed_toast",
                                        options: { newLogLevel: logLevel[value as keyof typeof logLevel] },
                                    })
                                )
                            );
                        });
                    }
                },
                readOnly:
                    isLoading || currentUser.authorisationRole.level !== 1 || !hasAccessRight("useApplicationLog"),
                disableClearable: true,
            },
        };
    }, [
        columnOptions,
        contextMenuActions,
        currentLogLevel,
        currentUser.authorisationRole.level,
        hasAccessRight,
        isLoading,
        query,
        queryCommands,
        reloadCurrentLogLevel,
        setLogLevel,
        showDetailsAction,
        translation,
    ]);
}
