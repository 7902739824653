import { useNavigationContext } from "./useNavigationContext";
import "./breadcrumbContainer.css";
import Icon from "../Icon";
import GtbLink from "../routing/GtbLink";
import useGtbTranslation, { I18nKey } from "../../i18n/useGtbTranslation";
import GtbNav from "../routing/GtbNav";
import { useBuildArrowNavigationListener } from "../../utils/a11yUtils";
import useRemoveTabindex from "../../hooks/useRemoveTabindex";

function BreadcrumbContainer() {
    const navigationContext = useNavigationContext();
    const translation = useGtbTranslation();
    const arrowNavigationListener = useBuildArrowNavigationListener("li", "horizontal");
    const removeTabindex = useRemoveTabindex<HTMLOListElement>();

    return (
        <GtbNav aria-label={"components.breadcrumbs.accessibleLabel"} className="breadcrumbContainer">
            <ol onKeyDown={arrowNavigationListener} {...removeTabindex}>
                {navigationContext.length > 1 &&
                    navigationContext
                        .slice(0, navigationContext.length - 1)
                        .map(({ to, navigationContextCleanup, label, state }, index) => (
                            <li key={index}>
                                <GtbLink
                                    to={to}
                                    state={state}
                                    beforeNavigate={navigationContextCleanup}
                                    className="breadcrumb"
                                >
                                    {translation(label as I18nKey)}
                                </GtbLink>
                                <Icon name={"chevron-right"} size={10} />
                            </li>
                        ))}
                <li
                    className="breadcrumb"
                    aria-current="page"
                    aria-label={translation(navigationContext[navigationContext.length - 1].label as I18nKey)}
                >
                    {translation(navigationContext[navigationContext.length - 1].label as I18nKey)}
                </li>
            </ol>
        </GtbNav>
    );
}

export default BreadcrumbContainer;
