import { useHelpContext } from "../help/context-sensitivity/useHelpContext";
import EntityListView from "../components/entity/EntityListView";
import useOrganicMaterialPurchaseListView from "./useOrganicMaterialPurchaseListView";

export default function OrganicMaterialPurchaseListView() {
    const organicMaterialPurchaseGridOptions = useOrganicMaterialPurchaseListView();

    useHelpContext("organic-material-purchase-list");

    return (
        <EntityListView
            heading="organicMaterialPurchase.list.header"
            gridOptions={organicMaterialPurchaseGridOptions}
        />
    );
}
