import { useMemo } from "react";
import { buildDateTimeColumn, buildTextColumn } from "../components/grid/ColumnProvider";
import useListView from "../hooks/useListView";
import { SortOption } from "../components/grid/component/useQueryBuilder";
import { GridColumn } from "../components/grid/component/Grid";
import { DeduplicationLogListViewProps } from "./DeduplicationLogListView";
import { DeduplicationLogSummary } from "./deduplicationLogTypes";
import { useShowDetailsAction } from "../utils/defaultActions";
import useResolvedRoute from "../components/routing/useResolvedRoute";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";

export const defaultDeduplicationLogSortOption: SortOption<DeduplicationLogSummary> = {
    field: "timestamp",
    direction: "DESC",
};

const columns: GridColumn<DeduplicationLogSummary>[] = [
    buildDateTimeColumn({ key: "timestamp", title: "deduplicationLog.list.timestamp_column", minWidth: 145 }),
    buildTextColumn({ key: "sourceId", title: "deduplicationLog.list.sourceId_column", minWidth: 115 }),
    buildTextColumn({
        key: "sourceBusinessName",
        title: "deduplicationLog.list.sourceBusinessName_column",
        minWidth: 200,
    }),
    buildTextColumn({
        key: "destinationId",
        title: "deduplicationLog.list.destinationId_column",
        minWidth: 148,
    }),
    buildTextColumn({
        key: "destinationBusinessName",
        title: "deduplicationLog.list.destinationBusinessName_column",
        minWidth: 230,
    }),
    buildTextColumn({ key: "comment", title: "deduplicationLog.list.comment_column", minWidth: 115, visible: false }),
    buildTextColumn({
        key: "transferredAttributes",
        title: "deduplicationLog.list.transferredAttributes_column",
        minWidth: 260,
        visible: false,
    }),
    buildTextColumn({
        key: "transferredEntities",
        title: "deduplicationLog.list.transferredEntities_column",
        minWidth: 260,
        visible: false,
    }),
    buildTextColumn({
        key: "transferredRelations",
        title: "deduplicationLog.list.transferredRelations_column",
        minWidth: 320,
        visible: false,
    }),
];

export default function useDeduplicationLogList() {
    const { getResolvedEditUrl } = useResolvedRoute();

    const {
        queryBuilder: { query, columnOptions, queryCommands },
    } = useListView<DeduplicationLogSummary>({
        navigationContextLabel: "deduplicationLog.breadcrumb",
        baseQuery: "/log-service/deduplicationlogs",
        defaultSortOption: defaultDeduplicationLogSortOption,
    });

    const showDetailsAction = useShowDetailsAction(
        (item: DeduplicationLogSummary) => getResolvedEditUrl("deduplicationLog", item.id),
        query.parameter
    );

    const contextMenuActions = useGridRowContextMenuActions(showDetailsAction);

    return useMemo<DeduplicationLogListViewProps>(() => {
        return {
            listProps: {
                query,
                columns,
                uniqueDataKey: "id",
                limit: 100,
                onItemDoubleClick: showDetailsAction,
                createUrl: undefined,
                tableName: "deduplicationLog",
                columnOptions,
                queryCommands,
                itemActions: contextMenuActions,
            },
        };
    }, [columnOptions, contextMenuActions, query, queryCommands, showDetailsAction]);
}
