import useGtbTranslation, { I18nKey } from "../../../../../i18n/useGtbTranslation";
import { InputMaterialProduction } from "../../tcProductTypes";
import { groupByProp } from "../../../../../utils/arrayUtils";
import { Fragment } from "react";
import { TypedTrackingNode } from "./TcProductMapView";

export default function TrackingInfo({
    node,
    subtitle,
    materials,
    materialPercentageProvider,
    stateTranslationKey = "tcProduct.mapInfo.stateProvinceOfSeller_title",
}: {
    node: TypedTrackingNode;
    subtitle: I18nKey;
    materials: InputMaterialProduction[];
    materialPercentageProvider: (material: InputMaterialProduction) => number | null;
    stateTranslationKey?: I18nKey;
}) {
    const translation = useGtbTranslation();

    return (
        <>
            {node.type !== "ROOT_PRODUCT" && <h2>{translation(subtitle)}</h2>}
            <dl id="productInfo">
                {node.type !== "INPUT_PRODUCTION" && (
                    <>
                        <dt>{translation("tcProduct.mapInfo.productCategory_title")}</dt>
                        <dd>{node.productCategory}</dd>
                        <dt>{translation("tcProduct.mapInfo.productDetail_title")}</dt>
                        <dd>{node.productDetail}</dd>
                        <dt>{translation("tcProduct.mapInfo.labelGrade_title")}</dt>
                        <dd>{node.labelGrade}</dd>
                    </>
                )}
                <dt>
                    {translation(
                        "isByProduct" in node && node.isByProduct
                            ? "tcProduct.mapInfo.byProductWeight_title"
                            : "tcProduct.mapInfo.netShippingWeight_title"
                    )}
                </dt>
                <dd>{`${node.netShippingWeight} ${translation("units.kilogramm")}`}</dd>
                {node.type !== "ROOT_PRODUCT" && (
                    <>
                        <dt>{translation("tcProduct.mapInfo.usedForNextProduct_title")}</dt>
                        <dd>{`${node.quantityForParent} ${translation("units.kilogramm")}`}</dd>
                        <dt>{translation("tcProduct.mapInfo.usedForTargetProduct_title")}</dt>
                        <dd>{`${node.quantityForRoot} ${translation("units.kilogramm")}`}</dd>
                    </>
                )}
                {(node.type === "ROOT_PRODUCT" || node.type === "INPUT_PRODUCT") && (
                    <>
                        <dt>{translation("tcProduct.mapInfo.numberOfUnits_title")}</dt>
                        <dd>{`${node.numberOfUnits} ${node.unitName}`}</dd>
                    </>
                )}
                {node.type !== "INPUT_PRODUCTION" && (
                    <>
                        <dt>{translation(stateTranslationKey)}</dt>
                        <dd>{`${node.state}, ${node.country}`}</dd>
                    </>
                )}
                {materials && (
                    <>
                        <dt>
                            <span className="visuallyHidden">
                                {translation("tcProduct.mapInfo.materials_accessibleLabel")}
                            </span>
                            <hr />
                        </dt>
                        <dd>
                            <dl>
                                {groupByProp(materials, "materialId").map((groupedMaterial) => (
                                    <Fragment key={groupedMaterial[0].materialId}>
                                        <dt className="materialName">{groupedMaterial[0].materialName}</dt>
                                        <dd className="materialPercentage">
                                            {materialPercentageProvider(groupedMaterial[0]) !== null
                                                ? `${materialPercentageProvider(groupedMaterial[0])}%`
                                                : ""}
                                        </dd>
                                        <dt className="visuallyHidden">
                                            {translation("tcProduct.mapInfo.materialOrigin_accessibleLabel")}
                                        </dt>
                                        <dd className="materialOrigin">
                                            {groupedMaterial
                                                .filter((groupedMaterial) => !!groupedMaterial.state)
                                                .map((material) => `${material.state}, ${material.country}`)
                                                .filter((value, index, arr) => arr.indexOf(value) === index)
                                                .join(";\n")}
                                        </dd>
                                    </Fragment>
                                ))}
                            </dl>
                        </dd>
                    </>
                )}
            </dl>
        </>
    );
}
