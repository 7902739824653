import GtbLink, { GtbLinkProps } from "../routing/GtbLink";
import { CommonTrackingInfo } from "../../transactionCertificate/details/tcProduct/tcProductTypes";
import { joinClassNames } from "../../utils/StringUtils";
import { productMarkerLegendId } from "./WorldMap";
import { CSSProperties } from "react";

export type SvgCoordinate = { x: number; y: number };

export type ActiveMarker = { openState?: CommonTrackingInfo["state"]; uniqueIndex?: string };

type TargetState = (CommonTrackingInfo & ActiveMarker) | ActiveMarker;

type SvgMarkerProps = {
    coordinate: SvgCoordinate;
    targetState: TargetState;
} & Omit<GtbLinkProps, "to" | "state">;

export type LocationMarkerProps = {
    number?: number;
} & SvgMarkerProps;

export type GroupMarkerProps = {
    id: string;
    "aria-expanded": boolean;
    marker: { disabled: boolean; className: string; targetState: SvgMarkerProps["targetState"] }[];
} & Omit<LocationMarkerProps, "disabled" | "number">;

export function RootMarker({ coordinate, targetState, disabled, className, ...rest }: SvgMarkerProps) {
    return (
        <g transform={`translate(${coordinate.x} ${coordinate.y})`}>
            <GtbLink
                to={""}
                state={targetState}
                className="marker root-marker"
                aria-describedby={productMarkerLegendId}
                {...buildDisabledProps(disabled)}
                {...rest}
            >
                <path
                    d={`m0,-27.934a9.77,9.77 0 0 0 -9.77,9.77c0,7.33 9.77,18.16 9.77,18.16s9.77,-10.82 9.77,-18.15a9.77,9.77 0 0 0 -9.77,-9.78l-0.01,0.01l0.01,-0.01z`}
                    className={className}
                />
            </GtbLink>
        </g>
    );
}

export function LocationMarker({ className, targetState, coordinate, disabled, number, ...rest }: LocationMarkerProps) {
    return (
        <GtbLink
            to={""}
            state={targetState}
            className={joinClassNames("marker", className)}
            {...buildDisabledProps(disabled)}
            {...rest}
        >
            <g>
                <circle cx={coordinate.x} cy={coordinate.y} role="presentation" />
                <circle cx={coordinate.x} cy={coordinate.y} role="presentation" />
                {number && (
                    <text textAnchor="middle" x={coordinate.x} y={coordinate.y}>
                        {number}
                    </text>
                )}
            </g>
        </GtbLink>
    );
}

export function GroupMarker({
    id,
    marker,
    className,
    coordinate,
    "aria-expanded": ariaExpanded,
    ...rest
}: GroupMarkerProps) {
    return (
        <g className="markerGroupContainer">
            <LocationMarker
                aria-controls={id}
                aria-expanded={ariaExpanded}
                coordinate={coordinate}
                className={joinClassNames("groupMarker", className)}
                number={marker.length}
                {...rest}
            />
            <g
                className="markerGroup"
                id={id}
                style={
                    {
                        "--_markerCount": marker.length,
                        "--_groupMarkerX": coordinate.x,
                        "--_groupMarkerY": coordinate.y,
                    } as CSSProperties
                }
            >
                {ariaExpanded &&
                    marker.map(({ targetState, ...mark }, index) => (
                        <LocationMarker
                            key={targetState.uniqueIndex}
                            number={index + 1}
                            style={{ "--_index": index } as CSSProperties}
                            coordinate={coordinate}
                            targetState={targetState}
                            {...mark}
                        />
                    ))}
            </g>
        </g>
    );
}

const buildDisabledProps = (disabled?: boolean) => (disabled ? { disabled: true } : { tabIndex: 0 });
