import { joinClassNames } from "../../utils/StringUtils";
import GtbTextField from "../../components/Input/GtbTextField";
import ControlledDatePicker from "../../components/Input/datepicker/ControlledDatePicker";
import "./stateProvinceBasicData.css";
import { StateProvinceDetailViewTabProps } from "./StateProvinceDetailView";
import CurrentLanguageWatchField from "../../i18n/CurrentLanguageWatchField";
import GtbNumberField from "../../components/Input/GtbNumberField";

function StateProvinceBasicData({ form, readOnly, countryAreaName }: StateProvinceDetailViewTabProps) {
    return (
        <div className={joinClassNames("stateProvinceBasicData", "formGrid")}>
            <CurrentLanguageWatchField
                form={form}
                field="name"
                label="stateProvince.detail.name_input"
                className="name"
            />
            <GtbTextField
                label="stateProvince.detail.countryArea_input"
                className="countryArea"
                readOnly={true}
                value={countryAreaName}
            />
            <GtbTextField
                {...form.registerWithErrors("isoCode")}
                readOnly={readOnly}
                label="stateProvince.detail.isoCode_input"
                className="isoCode"
            />
            <GtbNumberField
                label={"stateProvince.detail.latitude_input"}
                {...form.registerNumberWithErrors("latitude")}
                className="latitude"
                readOnly={readOnly}
                decimalPlaces={8}
                allowNegative
            />
            <GtbNumberField
                label={"stateProvince.detail.longitude_input"}
                {...form.registerNumberWithErrors("longitude")}
                className="longitude"
                readOnly={readOnly}
                decimalPlaces={8}
                allowNegative
            />
            <ControlledDatePicker
                control={form.form.control}
                readOnly={readOnly}
                name="startDate"
                label="stateProvince.detail.startDate_input"
                className="startDate"
            />
            <ControlledDatePicker
                control={form.form.control}
                readOnly={readOnly}
                name="endDate"
                label="stateProvince.detail.endDate_input"
                className="endDate"
            />
        </div>
    );
}

export default StateProvinceBasicData;
