import { TypedPicklist } from "./typedPicklistType";
import DataPickList from "../DataPickList";
import { joinClassNames } from "../../../../utils/StringUtils";
import "./typedPickList.css";
import { CertificationBodyReference } from "../../../../certificationBody/certificationBodyTypes";
import { FieldValues } from "react-hook-form/dist/types";

export default function CertificationBodyPickList<FormItemType extends FieldValues>({
    className,
    ...rest
}: CertificationBodyPickListProps<FormItemType>) {
    return (
        <DataPickList
            className={joinClassNames("certificationBodyPickList", className)}
            itemId="id"
            selectedItemLabel="legalName"
            itemLabel={(option) =>
                `${option.legalName}\n${option.town}, ${option.stateProvinceCountryAreaName}, (${option.stateProvinceIsoCode})`
            }
            queryUrl="/certificate-service/references/certification-bodies"
            {...rest}
        />
    );
}

export interface CertificationBodyPickListFlags {
    sbfilter?: boolean;
    withActiveCertificationRelation?: boolean;
    withValidCertificationRelation?: boolean;
    all?: boolean;
    allActive?: boolean;
}

interface CertificationBodyPickListProps<FormItemType extends FieldValues>
    extends TypedPicklist<FormItemType, CertificationBodyReference> {
    flags?: CertificationBodyPickListFlags;
}
