import { CertificationBodyDetailViewTabProps } from "./CertificationBodyDetailView";
import { joinClassNames } from "../../utils/StringUtils";
import GtbTextArea from "../../components/Input/GtbTextArea";
import "./certificationBodyCertificationData.css";
import ControlledFileUploadComponent from "../../components/Input/UploadComponent/ControlledFileUploadComponent";
import { DOCX_TYPE } from "../../components/Input/UploadComponent/FileUploadComponent";
import GtbNumberField from "../../components/Input/GtbNumberField";

export default function CertificationBodyCertificationData({
    form,
    readOnlyProps,
    isTcNumberBlockSizeVisible,
    urlProps,
}: CertificationBodyDetailViewTabProps & {
    isTcNumberBlockSizeVisible: boolean;
}) {
    return (
        <>
            <div className={joinClassNames("certificationBodyCertificationData", "formGrid")}>
                <GtbTextArea
                    {...form.registerWithErrors("authorisedPerson")}
                    readOnly={readOnlyProps.isAuthorisedPersonReadOnly}
                    label={"certificationBody.detail.authorisedPerson_input"}
                    className="authorisedPerson"
                />
                <ControlledFileUploadComponent
                    label="certificationBody.detail.scTemplate_input"
                    acceptedTypes={DOCX_TYPE}
                    fileUrl={urlProps?.scTemplateUrl ?? ""}
                    readOnly={readOnlyProps.isScTemplateReadOnly}
                    control={form.form.control}
                    name="scTemplate"
                    className="scTemplate"
                />
                <ControlledFileUploadComponent
                    label="certificationBody.detail.tcTemplate_input"
                    acceptedTypes={DOCX_TYPE}
                    fileUrl={urlProps?.tcTemplateUrl ?? ""}
                    readOnly={readOnlyProps.isTcTemplateReadOnly}
                    control={form.form.control}
                    name="tcTemplate"
                    className="tcTemplate"
                />
                {isTcNumberBlockSizeVisible && (
                    <GtbNumberField
                        label="certificationBody.detail.tcNumberBlockSize_input"
                        readOnly={readOnlyProps.isTcNumberBlockSizeReadOnly}
                        className="blockSize"
                        {...form.registerNumberWithErrors("tcNumberBlockSize")}
                    />
                )}
            </div>
        </>
    );
}
