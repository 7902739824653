import { useEffect, useRef } from "react";
import { findFirstFocusableChild } from "../utils/a11yUtils";

export default function useRemoveTabindex<T extends HTMLElement>() {
    const listRef = useRef<T>(null);

    useEffect(() => {
        for (let i = 1; i < (listRef.current?.children.length ?? 0); i++) {
            findFirstFocusableChild(listRef.current!.children[i])?.setAttribute("tabindex", "-1");
        }
    }, []);

    return { ref: listRef };
}
