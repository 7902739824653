import ViewContainer from "../components/ViewContainer";
import MarkdownContainer from "../help/markdown/MarkdownContainer";
import Icon from "../components/Icon";
import DashboardSettingsButton from "./dashboard/DashboardSettingsButton";
import dashboardPreview from "../assets/images/Dashboard-preview.png";
import Image from "../components/Image";
import "./homepage.css";
import useGtbTranslation from "../i18n/useGtbTranslation";

export default function HomePage({ showDashboardSettingsDrawer }: { showDashboardSettingsDrawer: () => void }) {
    const translation = useGtbTranslation();

    return (
        <ViewContainer className="homepage">
            <MarkdownContainer content={translation("dashboard.welcomeMessage_text")} />
            <div className="dashboardSettings">
                <Icon className="arrowIcon" name="dashboardArrow" size={65} />
                <DashboardSettingsButton showDashboardSettingsDrawer={showDashboardSettingsDrawer} />
                <strong>{translation("dashboard.dashboardSettingsHint_label")}</strong>
            </div>
            <div aria-hidden={true} className="dashboardSettings hidden">
                {/*This div is just for styling purposes, because the original div is placed absolute and does not obtain any room in the grid*/}
                <Icon className="arrowIcon" name="dashboardArrow" size={65} />
                <div />
                <strong>Placeholder for alignment</strong>
            </div>
            <Image
                src={dashboardPreview}
                alt={"dashboard.dashboardPreview_alternativeText"}
                width={1189}
                height={748}
            />
        </ViewContainer>
    );
}
