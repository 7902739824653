import { FilterComponentProps } from "../../ColumnProvider";
import "./gridfilter.css";
import { GtbTextFieldProps } from "../../../Input/GtbTextField";
import GtbPickList from "../../../Input/pickList/GtbPickList";
import useGtbTranslation, { I18nKey } from "../../../../i18n/useGtbTranslation";
import { backendUrlType, useQuery } from "../../../../hooks/useAxios";

interface DataPicklistFilterProps<ItemType, FilterDataType>
    extends FilterComponentProps<ItemType>,
        Omit<GtbTextFieldProps, "className" | "startAdornment" | "placeholder" | "title"> {
    itemKey: string;
    itemId: keyof FilterDataType;
    itemLabel: keyof FilterDataType;
    title: I18nKey;
    url: backendUrlType;
}

export default function DataPicklistFilter<ItemType, FilterDataType>({
    itemKey,
    itemId,
    itemLabel,
    title,
    filter,
    setFilter,
    filterKey,
    url,
}: DataPicklistFilterProps<ItemType, FilterDataType>) {
    const translation = useGtbTranslation();
    const { data, isLoading } = useQuery<FilterDataType[]>({ url });

    return (
        <GtbPickList
            id={itemKey + "Filter"}
            className="gridFilter"
            aria-label={translation({
                key: "components.grid.filterBy_accessibleLabel",
                options: { title: translation(title) },
            })}
            placeholder={translation("components.grid.filterPicklistPlaceholder")}
            value={filter ?? null}
            onChange={(_event, newValue) => {
                setFilter(filterKey, newValue);
            }}
            isLoading={isLoading}
            itemId={itemId}
            itemLabel={itemLabel}
            data={data}
        />
    );
}
