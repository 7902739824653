import useNavigatableDetailsEdit from "../../hooks/details/useNavigatableDetailsEdit";
import { yupResolver } from "@hookform/resolvers/yup";
import { ApplicationLogEditViewProps } from "./ApplicationLogEditView";
import { ApplicationLogDetails } from "../applicationLogTypes";
import { applicationLogValidationSchema } from "./applicationLogValidationSchema";
import { formatTimestamp } from "../../utils/formatter";
import { defaultApplicationLogSortOption } from "../useApplicationLogList";
import useResolvedRoute from "../../components/routing/useResolvedRoute";

function useApplicationLogEdit(): ApplicationLogEditViewProps {
    const { getResolvedId } = useResolvedRoute();
    const applicationLogId = getResolvedId("applicationLog");

    const { detailViewProps, form, initialData, title, recordNavigationProps } =
        useNavigatableDetailsEdit<ApplicationLogDetails>({
            baseUrl: "/log-service/logs/" + applicationLogId,
            resolver: yupResolver(applicationLogValidationSchema),
            titleBuilder: (item) => `${formatTimestamp(item.timestamp)}`,
            canSave: true,
            recordNavigationDefaultSort: defaultApplicationLogSortOption,
            frontendRoute: "applicationLog",
        });

    return {
        detailViewProps,
        form,
        title,
        subtitle: initialData?.logLevel,
        recordNavigationProps,
    };
}

export default useApplicationLogEdit;
