import { TypedPicklist } from "./typedPicklistType";
import { FieldValues } from "react-hook-form/dist/types";
import { EnumEntityType } from "../typedEnumPicklist/enumEntityType";
import DataPickList from "../DataPickList";
import { joinClassNames } from "../../../../utils/StringUtils";
import "./typedPickList.css";

export default function PreGtbTransactionCertificateStatusPickList<FormItemType extends FieldValues>({
    className,
    flags,
    ...rest
}: PreGtbTransactionCertificateStatusPickListProps<FormItemType>) {
    return (
        <DataPickList
            className={joinClassNames("preGtbTransactionCertificateStatusPickList", className)}
            itemId="id"
            itemLabel="name"
            queryUrl="/certificate-service/references/pre-gtb-tc-statuses"
            flags={{ sortOrdinal: true, ...flags }}
            {...rest}
        />
    );
}

interface PreGtbTransactionCertificateStatusPickListProps<FormItemType extends FieldValues>
    extends TypedPicklist<FormItemType, EnumEntityType> {
    flags?: { onlyAvailable?: boolean; preGtbTcId?: string };
}
