import { useEffect } from "react";
import useAuth from "../auth/useAuth";
import useStickyState from "../hooks/useStickyState";
import { ThemeKey, themes } from "../themes/theme";

const defaultTheme: ThemeKey = "gots";

export default function ThemeSetter() {
    const { currentUser } = useAuth();
    const [currentTheme, setCurrentTheme] = useStickyState<ThemeKey>("gtbTheme", defaultTheme);

    useEffect(() => {
        const styleSheet = document.styleSheets[0];
        if (!styleSheet) {
            return;
        }
        const index = styleSheet.insertRule(
            ":root{" +
                Object.entries(themes[currentTheme] ?? themes[defaultTheme])
                    .map(([variable, value]) => `--${variable}: ${value};`)
                    .join("") +
                "}"
        );

        return () => styleSheet.deleteRule(index);
    }, [currentTheme]);

    useEffect(() => {
        if (currentUser) {
            setCurrentTheme(currentUser.guiTheme);
        }
    }, [currentUser, setCurrentTheme]);

    return null;
}
