import { useHelpContext } from "../../../../help/context-sensitivity/useHelpContext";
import React from "react";
import EntityDetailView from "../../../../components/entity/EntityDetailView";
import Tabs, { TabKey } from "../../../../components/tabs/Tabs";
import { PreGtbTcMaterialDetails } from "../preGtbTcMaterialsTypes";
import PreGtbTcMaterialBasicData, { PreGtbTcMaterialBasicDataProps } from "./PreGtbTcMaterialBasicData";
import { TypedDetailViewProps } from "../../../../components/detailView/DetailView";

export default function PreGtbTcMaterialDetailView({
    form,
    readOnly,
    isCertified,
    labelGradeTypeOfMaterialId,
    rawMaterialPrintVisible,
    preGtbTcId,
    ...rest
}: PreGtbTcMaterialDetailViewProps) {
    useHelpContext("pre-gtb-tc-material-details");

    return (
        <EntityDetailView {...rest} form={form}>
            <Tabs<PreGtbTcMaterialDetails, PreGtbTcMaterialTabs>
                tabs={[
                    {
                        heading: "preGtbTcMaterial.detail.basicData_tab",
                        element: (
                            <PreGtbTcMaterialBasicData
                                form={form}
                                isCertified={isCertified}
                                labelGradeTypeOfMaterialId={labelGradeTypeOfMaterialId}
                                rawMaterialPrintVisible={rawMaterialPrintVisible}
                                readOnly={readOnly}
                                preGtbTcId={preGtbTcId}
                            />
                        ),
                    },
                ]}
                errors={form.form.formState.errors}
                errorToTab={{
                    id: "internal",
                    rawMaterial: "preGtbTcMaterial.detail.basicData_tab",
                    rawMaterialPrint: "preGtbTcMaterial.detail.basicData_tab",
                    rawMaterialName: "internal",
                    rawMaterialPrintRequired: "internal",
                    percentage: "preGtbTcMaterial.detail.basicData_tab",
                    certified: "preGtbTcMaterial.detail.basicData_tab",
                    certifiedWeight: "preGtbTcMaterial.detail.basicData_tab",
                    origin: "preGtbTcMaterial.detail.basicData_tab",
                    joinedOriginNames: "internal",
                    labelGradeId: "internal",
                    labelGradeLabelGrade: "preGtbTcMaterial.detail.basicData_tab",
                    preGtbTcProductDetailPrint: "preGtbTcMaterial.detail.basicData_tab",
                    preGtbTcProductCategoryPrint: "preGtbTcMaterial.detail.basicData_tab",
                    preGtbTcProductNumber: "preGtbTcMaterial.detail.basicData_tab",
                    preGtbTcNumber: "preGtbTcMaterial.detail.basicData_tab",
                    preGtbTcStatus: "internal",
                }}
            />
        </EntityDetailView>
    );
}

export interface PreGtbTcMaterialDetailViewProps extends TypedDetailViewProps<PreGtbTcMaterialDetails> {
    isCertified: boolean;
    labelGradeTypeOfMaterialId: string | null;
    rawMaterialPrintVisible: boolean;
    readOnly: PreGtbTcMaterialBasicDataProps["readOnly"];
    preGtbTcId?: string;
}

type PreGtbTcMaterialTabs = TabKey<"preGtbTcMaterial.detail.basicData_tab">;
