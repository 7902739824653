export interface EventMonitorSummary {
    id: string;
    eventType: string;
    name: string;
    description: string;
    signallingThreshold: number;
    observationFrameInSeconds: number;
    active: boolean;
}

export interface EventMonitorDetails {
    id: string;
    eventType: string;
    name: string;
    description: string;
    signallingThreshold: number;
    observationFrameInSeconds: number;
    active: boolean;
    additionalText: string;
    notifySystemAdmins: boolean;
    notifyStandardBodyAdminsOptionsEnum: notifyStandardBodyAdminsOptionsEnumType;
    notifySpecificStandardBodyAdmin: string | null;
    additionalRecipients: string[];
}

export type notifyStandardBodyAdminsOptionsEnumType = "ALL" | "NONE" | "SPECIFIC";

export const notifyStandardBodyAdminsOptions: Record<notifyStandardBodyAdminsOptionsEnumType, string> = {
    ALL: "All",
    NONE: "None",
    SPECIFIC: "Particular",
};
