import { yupResolver } from "@hookform/resolvers/yup";
import useIsAuthorised from "../../auth/useIsAuthorised";
import { useCallback, useMemo } from "react";
import useResolvedRoute from "../../components/routing/useResolvedRoute";
import useDomainEntityDetailsEdit from "../../hooks/details/useDomainEntityDetailsEdit";
import { OrganicMaterialProductionScDetails } from "../organicMaterialProductionScTypes";
import { defaultOrganicMaterialProductionScSortOption } from "../useOrganicMaterialProductionScListView";
import { organicMaterialProductionScValidationSchema } from "./organicMaterialProductionScValidationSchema";
import { OrganicMaterialProductionScDetailViewProps } from "./OrganicMaterialProductionScDetailView";
import useValidateOnRelatedValueChange from "../../hooks/formHandling/useValidateOnRelatedValueChange";
import useConformanceCheck from "../../conformance/useConformanceCheck";

export default function useOrganicMaterialProductionScEdit(): OrganicMaterialProductionScDetailViewProps {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedId } = useResolvedRoute();
    const organicMaterialProductionScId = getResolvedId("organicMaterialProductionSc");
    const handleConformanceCheck = useConformanceCheck<OrganicMaterialProductionScDetails>();

    const canSave = useCallback(
        (item: OrganicMaterialProductionScDetails) => {
            if (item) {
                return hasAccessRight("updateRawMaterialProduction");
            }
            return false;
        },
        [hasAccessRight]
    );

    const { initialData, detailViewProps, form, isReadOnly, title, recordNavigationProps } =
        useDomainEntityDetailsEdit<OrganicMaterialProductionScDetails>({
            baseUrl: `/certificate-service/organic-material-production-scs/${organicMaterialProductionScId}`,
            frontendRoute: "organicMaterialProductionSc",
            recordNavigationDefaultSort: defaultOrganicMaterialProductionScSortOption,
            titleBuilder: (item) => item.scNumber,
            resolver: yupResolver(organicMaterialProductionScValidationSchema),
            canSave,
            historyColumns: [
                { identifier: "scNumber", title: "organicMaterialProductionSc.history.scNumber_column" },
                { identifier: "issuingBody", title: "organicMaterialProductionSc.history.issuingBody_column" },
                { identifier: "standard", title: "organicMaterialProductionSc.history.standard_column" },
                {
                    identifier: "totalFarmAreaUnderSc",
                    title: "organicMaterialProductionSc.history.totalFarmAreaUnderSc_column",
                },
                { identifier: "validFrom", title: "organicMaterialProductionSc.history.validFrom_column" },
                { identifier: "validUntil", title: "organicMaterialProductionSc.history.validUntil_column" },
            ],
            resolveErrors: (error, item, retrySaveAction) => handleConformanceCheck(error, item, retrySaveAction),
        });

    const readOnly = useMemo<OrganicMaterialProductionScDetailViewProps["readOnly"]>(
        () => ({
            scNumber: true,
            issuingBody: isReadOnly,
            standard: true,
            organicMaterialProducer: true,
            totalFarmAreaUnderSc: isReadOnly,
            validFrom: isReadOnly,
            validUntil: isReadOnly,
            scCopy: isReadOnly,
        }),
        [isReadOnly]
    );

    useValidateOnRelatedValueChange(form.form, "validUntil", "validFrom");

    return useMemo(
        () => ({
            detailViewProps,
            form,
            readOnly,
            title,
            recordNavigationProps,
            scCopyUrl: `/certificate-service/organic-material-production-scs/${organicMaterialProductionScId}/sc-copy`,
            createRoute: hasAccessRight("createRawMaterialProduction") ? "organicMaterialProductionSc" : undefined,
            subListVisible: true,
            organicMaterialProducerId: initialData?.organicMaterialProducer,
        }),
        [
            detailViewProps,
            form,
            hasAccessRight,
            initialData?.organicMaterialProducer,
            organicMaterialProductionScId,
            readOnly,
            recordNavigationProps,
            title,
        ]
    );
}
