import { forwardRef } from "react";
import GtbTextField, { GtbTextFieldProps } from "./GtbTextField";
import GtbIconButton from "../GtbIconButton";
import usePasswordField from "./usePasswordField";
import "./passwordfield.css";
import { IconName } from "../Icon";

interface GtbPasswordFieldType extends Omit<GtbTextFieldProps, "type"> {}

function createIconAdornment(passwordVisibilityToggleIcon: IconName, toggleShowPassword: () => void) {
    return (
        <GtbIconButton
            label="components.passwordField.togglePasswordVisibility_tooltip"
            iconName={passwordVisibilityToggleIcon}
            className="toggle-password-visibility-button"
            onClick={toggleShowPassword}
        />
    );
}

const GtbPasswordField = forwardRef<HTMLDivElement, GtbPasswordFieldType>(
    ({ id, ...rest }: GtbPasswordFieldType, ref) => {
        const { passwordFieldType, passwordVisibilityToggleIcon, toggleShowPassword } = usePasswordField();

        return (
            <GtbTextField
                id={id}
                type={passwordFieldType}
                endAdornment={createIconAdornment(passwordVisibilityToggleIcon, toggleShowPassword)}
                ref={ref}
                {...rest}
            />
        );
    }
);

export default GtbPasswordField;
