import useIsAuthorised from "../../../auth/useIsAuthorised";
import useResolvedRoute from "../../../components/routing/useResolvedRoute";
import { SortOption, useQueryBuilder } from "../../../components/grid/component/useQueryBuilder";
import { useDeleteAction, useShowDetailsAction } from "../../../utils/defaultActions";
import { useMemo } from "react";
import { SubListProps } from "../../../components/grid/SubList";
import { GridColumn } from "../../../components/grid/component/Grid";
import { buildBooleanColumn, buildNumberColumn, buildTextColumn } from "../../../components/grid/ColumnProvider";
import { PreGtbProductDetails, PreGtbProductSummary } from "./preGtbProductTypes";
import {
    PreGtbTransactionCertificateDetails,
    preGtbTransactionCertificateReadOnlyByStatus,
} from "../../preGtbTransactionCertificateTypes";
import useGridRowContextMenuActions from "../../../components/grid/component/cell/useGridRowContextMenuActions";

export const defaultPreGtbProductSortOption: SortOption<PreGtbProductSummary | PreGtbProductDetails> = {
    field: "productNumber",
    direction: "ASC",
};

const columns: GridColumn<PreGtbProductSummary>[] = [
    buildTextColumn({ key: "productNumber", title: "preGtbProduct.list.productNumber_column", minWidth: 180 }),
    buildTextColumn({
        key: "productCategoryPrint",
        title: "preGtbProduct.list.productCategoryPrint_column",
        minWidth: 239,
    }),
    buildTextColumn({
        key: "productDetailPrint",
        title: "preGtbProduct.list.productDetailPrint_column",
        minWidth: 214,
    }),
    buildTextColumn({ key: "labelGradeLabelGrade", title: "preGtbProduct.list.labelGrade_column", minWidth: 146 }),
    buildTextColumn({
        key: "materialComposition",
        title: "preGtbProduct.list.materialComposition_column",
        minWidth: 217,
        disableSorting: true,
    }),
    buildNumberColumn({
        key: "availableVolume",
        title: "preGtbProduct.list.availableVolume_column",
        allowDecimals: true,
        fixedDecimal: true,
        minWidth: 230,
    }),
    buildNumberColumn({
        key: "certifiedWeight",
        title: "preGtbProduct.list.certifiedWeight_column",
        allowDecimals: true,
        fixedDecimal: true,
        minWidth: 210,
        visible: false,
    }),
    buildNumberColumn({
        key: "netShippingWeight",
        title: "preGtbProduct.list.netShippingWeight_column",
        allowDecimals: true,
        fixedDecimal: true,
        minWidth: 242,
        visible: false,
    }),
    buildNumberColumn({
        key: "supplementaryWeight",
        title: "preGtbProduct.list.supplementaryWeight_column",
        allowDecimals: true,
        fixedDecimal: true,
        minWidth: 261,
        visible: false,
    }),
    buildNumberColumn({
        key: "initialAvailableQuantity",
        title: "preGtbProduct.list.initialAvailableQuantity_column",
        allowDecimals: true,
        fixedDecimal: true,
        minWidth: 375,
        visible: false,
    }),
    buildBooleanColumn({
        key: "usdaNopCompliant",
        title: "preGtbProduct.list.usdaNopCompliant_column",
        minWidth: 221,
        visible: false,
    }),
];

export default function usePreGtbProductSubList(preGtbTCStatus?: PreGtbTransactionCertificateDetails["status"]) {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedEditUrl, getResolvedCreateUrl, getResolvedId } = useResolvedRoute();
    const preGtbTCId = getResolvedId("preGtbTransactionCertificate");

    const { query, columnOptions, forceReload } = useQueryBuilder<PreGtbProductSummary>(
        `/certificate-service/pre-gtb-tcs/${preGtbTCId}/pre-gtb-products`,
        {
            sort: defaultPreGtbProductSortOption,
            filterActive: false,
        }
    );

    const showDetailsAction = useShowDetailsAction(
        (item: PreGtbProductSummary) => getResolvedEditUrl("preGtbProduct", item.id),
        query.parameter
    );

    const deleteAction = useDeleteAction(
        (item: PreGtbProductSummary) => `/certificate-service/pre-gtb-tcs/${preGtbTCId}/pre-gtb-products/${item.id}`,
        () => forceReload(),
        "updatePreGtbTc",
        preGtbTCStatus ? [preGtbTCStatus] : []
    );

    const contextMenuActions = useGridRowContextMenuActions(
        showDetailsAction,
        preGtbTransactionCertificateReadOnlyByStatus(preGtbTCStatus) ? undefined : deleteAction
    );

    return useMemo<SubListProps<PreGtbProductSummary>>(
        () => ({
            query,
            columns,
            uniqueDataKey: "id",
            onItemDoubleClick: showDetailsAction,
            header: "preGtbProduct.list.header",
            itemActions: contextMenuActions,
            columnOptions,
            createUrl:
                !preGtbTransactionCertificateReadOnlyByStatus(preGtbTCStatus) &&
                preGtbTCStatus &&
                hasAccessRight("updatePreGtbTc", [preGtbTCStatus])
                    ? getResolvedCreateUrl("preGtbProduct")
                    : undefined,
            tableName: "preGtbProducts",
        }),
        [
            columnOptions,
            contextMenuActions,
            getResolvedCreateUrl,
            hasAccessRight,
            query,
            preGtbTCStatus,
            showDetailsAction,
        ]
    );
}
