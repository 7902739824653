import { buildSchema, validateString } from "../../utils/yupExtension";
import { ScopeCertificateDetails } from "../scopeCertificateTypes";
import { BaseSchema } from "yup";
import { scopeCertificateStatusType } from "../../auth/roleTypes";

export const scopeCertificateValidationSchema = buildSchema<ScopeCertificateDetails>({
    supplyChainOperator: validateString().isRequired(),
    standardVersion: validateString().isRequired(),
    certificationBody: validateString().isRequired(),
    cbScReferenceNumber: validateString().hasMaxLength(30),
    status: validateString().isRequired() as BaseSchema<scopeCertificateStatusType>,
    approvingPerson: validateString(),
    additionalDeclaration: validateString().hasMaxLength(1000),
});
