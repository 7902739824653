import useErrorDialog from "../components/dialog/useErrorDialog";
import useLoadingDialog from "../components/dialog/useLoadingDialog";
import { useQuery } from "./useAxios";

const useBulkReportDownload = (url: string, fileName: string) => {
    const { runQuery } = useQuery<Blob>({
        url,
        enabled: false,
    });

    const { showDialog: showLoadingIndicator, closeDialog: closeLoadingIndicator } = useLoadingDialog();
    const { showDialog: showError } = useErrorDialog();

    return () => {
        showLoadingIndicator("bulkReport.loading_indicator_text");
        runQuery()
            .then((data) => {
                let blob = new Blob([data], {
                    type: "text/csv",
                });
                let a = document.createElement("a");
                a.href = window.URL.createObjectURL(blob);
                a.download = fileName;
                a.type = "text/csv";
                a.click();
                closeLoadingIndicator();
            })
            .catch((error) => {
                showError({
                    title: "bulkReport.error_title",
                    message: {
                        key: "bulkReport.error_message",
                        options: { code: error?.statusCode, message: error?.data },
                    },
                });
            });
    };
};

export default useBulkReportDownload;
