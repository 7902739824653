import useExternalIdListView from "../useExternalIdListView";

export default function useExternalFacilityIdListViewList() {
    return useExternalIdListView({
        tableName: "externalFacilityIds",
        i18nKey: "externalFacilityId",
        routeEnumKey: "externalFacilityId",
        parentEntityType: "FACILITY",
    });
}
