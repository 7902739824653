import GtbTextField from "../components/Input/GtbTextField";
import GtbPasswordField from "../components/Input/GtbPasswordField";
import GtbButton from "../components/GtbButton";
import Form from "../components/Form";
import { LoginInputTypes } from "./useLogin";
import GtbLink from "../components/routing/GtbLink";
import "./login.css";

import { RegisterWithErrors } from "../hooks/formHandling/useGtbForm";
import { getBaseUrl } from "../components/routing/useResolvedRoute";
import useGtbTranslation, { I18nKey } from "../i18n/useGtbTranslation";
import { env } from "../env";

function LoginForm({
    register,
    isLoading,
    onSubmit,
    infoMessage,
}: {
    register: RegisterWithErrors<LoginInputTypes>;
    isLoading: boolean;
    onSubmit: (e: any) => void;
    infoMessage?: I18nKey;
}) {
    const translation = useGtbTranslation();
    const environmentInfo = env.REACT_APP_ENVIRONMENT_NAME;

    return (
        <Form onSubmit={onSubmit} className="login">
            {infoMessage && (
                <div className="login-form-info">
                    <span>{translation(infoMessage)}</span>
                </div>
            )}
            <GtbTextField
                label="loginPage.login_username_input"
                autoFocus
                {...register("username", { required: true })}
            />
            <GtbPasswordField
                label="loginPage.login_password_input"
                className="login-form-password"
                {...register("password", { required: true })}
            />
            <GtbLink className="login-form-forgot-password" to={getBaseUrl("resetPassword")}>
                {translation("loginPage.login_forgot_password_link")}
            </GtbLink>
            <GtbButton className="login-form-submit" type="submit" disabled={isLoading}>
                {translation("loginPage.login_submit_button")}
            </GtbButton>
            {environmentInfo && <span className="environment-info">{environmentInfo}</span>}
            <div className="login-form-register">
                <span>{translation("loginPage.login_register_title")}</span>
                <GtbLink to={"/how-to-apply"} target={"_blank"}>
                    {translation("loginPage.login_register_link")}
                </GtbLink>
            </div>
        </Form>
    );
}

export default LoginForm;
