import { useQuery } from "../../hooks/useAxios";
import { HelpPageSummary } from "../helpPageTypes";

export const useHelpPages = () => {
    const { data: helpPages, isLoading: navigationIsLoading } = useQuery<HelpPageSummary[]>({
        url: "/certificate-service/help-pages",
        enabled: true,
    });

    return {
        navigationIsLoading,
        helpPages,
    };
};
