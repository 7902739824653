import { useModal } from "../modal/ModalProvider";
import { ReactElement, useCallback } from "react";

export function useDrawer() {
    const { closeModal: closeDrawer, showModal } = useModal();

    const showDrawer = useCallback(
        (Modal: ReactElement) => {
            showModal(Modal, "first");
        },
        [showModal]
    );

    return { closeDrawer, showDrawer };
}
