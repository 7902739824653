import { joinClassNames } from "../../utils/StringUtils";
import Icon from "../../components/Icon";
import { dashboardIcon } from "./Tile";
import "./dashboardIcon.css";

export default function DashboardIcon({ icon }: { icon: dashboardIcon }) {
    return (
        <div className={joinClassNames("dashboard-icon-wrapper", icon.className)}>
            <Icon size={45} name={icon.name} className="dashboardIcon" />
        </div>
    );
}
