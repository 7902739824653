import Icon, { IconName } from "./Icon";
import GtbButton, { GtbButtonProps } from "./GtbButton";
import "./iconButton.css";
import { joinClassNames } from "../utils/StringUtils";
import useGtbTranslation, { I18nKey } from "../i18n/useGtbTranslation";

interface GtbIconButtonProps extends Omit<GtbButtonProps, "size" | "variant" | "children" | "aria-label"> {
    label: I18nKey;
    iconName: IconName;
    size?: number;
}

function GtbIconButton({ label, iconName, size, ...rest }: GtbIconButtonProps) {
    const translation = useGtbTranslation();

    return (
        <GtbButton
            variant="tertiary"
            aria-label={translation(label)}
            title={translation(label)}
            className={joinClassNames("icon-button", rest.className)}
            {...rest}
        >
            <Icon name={iconName} size={size} />
        </GtbButton>
    );
}

export default GtbIconButton;
