import { useHelpContext } from "../help/context-sensitivity/useHelpContext";
import useTimeBasedMailListView from "./useTimeBasedMailListView";
import EntityListView from "../components/entity/EntityListView";

export default function TimeBasedMailListView() {
    useHelpContext("time-based-mail-list");
    const timeBasedMailGridOptions = useTimeBasedMailListView();

    return <EntityListView heading="timeBasedMail.list.header" gridOptions={timeBasedMailGridOptions} />;
}
