import { yupResolver } from "@hookform/resolvers/yup";
import { useMemo } from "react";
import { OrganicMaterialTransactionCertificateDetails } from "../organicMaterialTransactionCertificateTypes";
import { OrganicMaterialTransactionCertificateDetailViewProps } from "./OrganicMaterialTransactionCertificateDetailView";
import { organicMaterialTransactionCertificateValidationSchema } from "./organicMaterialTransactionCertificateValidationSchema";
import useResolvedRoute from "../../../../../../components/routing/useResolvedRoute";
import { useQuery } from "../../../../../../hooks/useAxios";
import { OrganicMaterialPurchaseReference } from "../../../../../organicMaterialPurchaseTypes";
import { Nullable } from "../../../../../../utils/typeUtils";
import useDetailsCreate from "../../../../../../hooks/details/useDetailsCreate";
import { OrganicMaterialOriginReference } from "../../../organicMaterialOriginTypes";
import { StandardReference } from "../../../../../../standard/standardTypes";

const readOnly: OrganicMaterialTransactionCertificateDetailViewProps["readOnly"] = {
    tcNumber: false,
    tcType: false,
    standard: false,
    tcCopy: false,
};

export default function useOrganicMaterialTransactionCertificateCreate(): OrganicMaterialTransactionCertificateDetailViewProps {
    const { getResolvedId } = useResolvedRoute();
    const organicMaterialPurchaseId = getResolvedId("organicMaterialPurchase");
    const organicMaterialOriginId = getResolvedId("organicMaterialOrigin");

    const { data: organicMaterialPurchase } = useQuery<OrganicMaterialPurchaseReference>({
        url: "/certificate-service/references/organic-material-purchases/" + organicMaterialPurchaseId,
    });

    const { data: organicMaterialOrigin } = useQuery<OrganicMaterialOriginReference>({
        url: "/certificate-service/references/organic-material-origins/" + organicMaterialOriginId,
    });

    const { data: standard } = useQuery<StandardReference>({
        url: "/certificate-service/references/standards/of-om-origin/" + organicMaterialOriginId,
    });

    const initialData = useMemo<Nullable<OrganicMaterialTransactionCertificateDetails>>(
        () => ({
            id: null,
            buyerLegalName: organicMaterialPurchase?.buyerLegalName ?? null,
            certifiedOrganicMaterialName: organicMaterialPurchase?.certifiedOrganicMaterialName ?? null,
            organicMaterialProducerName: organicMaterialOrigin?.organicMaterialProducerDisplayName ?? null,
            organicMaterialProductionName: organicMaterialOrigin?.organicMaterialProductionDisplayName ?? null,
            tcNumber: null,
            tcType: null,
            standard: standard?.id ?? null,
            tcCopy: null,
        }),
        [
            organicMaterialOrigin?.organicMaterialProducerDisplayName,
            organicMaterialOrigin?.organicMaterialProductionDisplayName,
            organicMaterialPurchase?.buyerLegalName,
            organicMaterialPurchase?.certifiedOrganicMaterialName,
            standard,
        ]
    );

    const { detailViewProps, form, title } = useDetailsCreate<OrganicMaterialTransactionCertificateDetails>({
        title: "organicMaterialTransactionCertificate.detail.newRecord_title",
        frontendRoute: "organicMaterialTransactionCertificate",
        initialData,
        resolver: yupResolver(organicMaterialTransactionCertificateValidationSchema),
        saveUrl: `/certificate-service/organic-material-purchases/${organicMaterialPurchaseId}/organic-material-origins/${organicMaterialOriginId}/organic-material-tcs`,
    });

    return {
        title,
        detailViewProps,
        form,
        readOnly,
        createRoute: "organicMaterialTransactionCertificate",
    };
}
