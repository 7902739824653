import { TypedDetailViewProps } from "../../components/detailView/DetailView";
import { UseGtbFormReturn } from "../../hooks/formHandling/useGtbForm";
import MailTemplateData from "./MailTemplateData";
import { MailTemplateDetails } from "../mailTemplateTypes";
import EntityDetailView from "../../components/entity/EntityDetailView";
import { useHelpContext } from "../../help/context-sensitivity/useHelpContext";
import { useCallback } from "react";
import { getLanguageKeys, LanguageKey } from "../../i18n/languageTypes";
import Tabs, { TabProps } from "../../components/tabs/Tabs";
import { I18nKey } from "../../i18n/useGtbTranslation";

export default function MailTemplateDetailView({ detailViewProps, form, ...rest }: MailTemplateDetailViewProps) {
    useHelpContext("mail-template-details");

    const buildTabs = useCallback(() => {
        return getLanguageKeys().map(
            (lng): TabProps<MailTemplateDetails> => ({
                heading: `translation.language.${lng}` as I18nKey & keyof MailTemplateDetails,
                element: <MailTemplateData form={form} lng={lng} />,
            })
        );
    }, [form]);

    return (
        <EntityDetailView detailViewProps={detailViewProps} form={form} {...rest}>
            <Tabs
                tabs={buildTabs()}
                errors={form.form.formState.errors}
                errorToTab={{
                    id: "internal",
                    title: "internal",
                    subject: "internal",
                    body: "internal",
                }}
            />
        </EntityDetailView>
    );
}

export interface MailTemplateDetailViewProps extends TypedDetailViewProps<MailTemplateDetails> {}

export interface MailTemplateDetailViewTabProps {
    form: UseGtbFormReturn<MailTemplateDetails>;
    lng: LanguageKey;
}
