import { yupResolver } from "@hookform/resolvers/yup";
import useIsAuthorised from "../../auth/useIsAuthorised";
import { accreditationBodyValidationSchema } from "./accreditationBodyValidationSchema";
import { useCallback, useMemo } from "react";
import useResolvedRoute, { routeEnum } from "../../components/routing/useResolvedRoute";
import useGtbTranslation from "../../i18n/useGtbTranslation";
import useDomainEntityDetailsEdit from "../../hooks/details/useDomainEntityDetailsEdit";
import { AccreditationBodyDetails } from "../accreditationBodyTypes";
import { defaultAccreditationBodySortOption } from "../useAccreditationBodyListView";
import { AccreditationBodyReadOnlyProps } from "./AccreditationBodyDetailView";
import { useOrganisationDefaultReadOnlyProps } from "../../organisation/details/organisationDetailViewProps";

export default function useAccreditationBodyEdit() {
    const translation = useGtbTranslation();
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedId } = useResolvedRoute();
    const accreditationBodyId = getResolvedId("accreditationBody");

    const canSave = useCallback(
        (item: AccreditationBodyDetails) => {
            if (item) {
                return hasAccessRight("updateCertificationLevelOrganisation", [item.organisationStatus]);
            }
            return false;
        },
        [hasAccessRight]
    );

    const { detailViewProps, form, initialData, isReadOnly, title, recordNavigationProps } =
        useDomainEntityDetailsEdit<AccreditationBodyDetails>({
            baseUrl: "/certificate-service/accreditation-bodies/" + accreditationBodyId,
            frontendRoute: "accreditationBody",
            recordNavigationDefaultSort: defaultAccreditationBodySortOption,
            titleBuilder: (item) => item.businessName,
            resolver: yupResolver(accreditationBodyValidationSchema),
            canSave,
            historyColumns: [
                { identifier: "systemId", title: "accreditationBody.history.systemId_column" },
                { identifier: "legalName", title: "accreditationBody.history.legalName_column" },
                { identifier: "businessName", title: "accreditationBody.history.businessName_column" },
                { identifier: "addressDetails1", title: "accreditationBody.history.addressDetail1_column" },
                { identifier: "addressDetails2", title: "accreditationBody.history.addressDetail2_column" },
                { identifier: "addressDetails3", title: "accreditationBody.history.addressDetail3_column" },
                { identifier: "latitude", title: "accreditationBody.history.latitude_column" },
                { identifier: "longitude", title: "accreditationBody.history.longitude_column" },
                { identifier: "postCode", title: "accreditationBody.history.postcode_column" },
                { identifier: "town", title: "accreditationBody.history.town_column" },
                { identifier: "stateProvince", title: "accreditationBody.history.stateProvince_column" },
                {
                    identifier: "organisationalEmail",
                    title: "accreditationBody.history.organisationalEmailAddress_column",
                },
                { identifier: "website", title: "accreditationBody.history.website_column" },
                {
                    identifier: "organisationalPhoneNumber",
                    title: "accreditationBody.history.organisationalPhoneNumber_column",
                },
                { identifier: "organisationStatus", title: "accreditationBody.history.status_column" },
                { identifier: "startDate", title: "accreditationBody.history.startDate_column" },
                { identifier: "endDate", title: "accreditationBody.history.endDate_column" },
            ],
        });

    const defaultReadOnlyProps = useOrganisationDefaultReadOnlyProps(isReadOnly);

    const readOnlyProps = useMemo<AccreditationBodyReadOnlyProps>(
        () => ({
            ...defaultReadOnlyProps,
        }),
        [defaultReadOnlyProps]
    );

    const createRoute = useMemo<routeEnum | undefined>(() => {
        return hasAccessRight("createCertificationLevelOrganisation") ? "accreditationBody" : undefined;
    }, [hasAccessRight]);

    return {
        detailViewProps,
        form,
        readOnlyProps,
        title,
        subtitle: translation({
            key: "accreditationBody.detail.subtitle",
            options: { systemId: initialData?.["systemId"] },
        }),
        recordNavigationProps,
        createRoute,
    };
}
