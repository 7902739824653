import Icon from "../../../Icon";
import GtbButton from "../../../GtbButton";
import { useCallback } from "react";
import { applyColumnSettingsFunc } from "./withColumnSettings";
import { useDrawer } from "../../../drawer/useDrawer";
import ColumnSettingsDrawer from "./ColumnSettingsDrawer";
import useGtbTranslation from "../../../../i18n/useGtbTranslation";

export default function ColumnSettingsComponent({
    columns,
    applyColumnSettings,
    resetColumnSettings,
}: ColumnSettingsProps) {
    const { closeDrawer, showDrawer } = useDrawer();
    const translation = useGtbTranslation();

    const showColumnDrawer = useCallback(() => {
        showDrawer(
            <ColumnSettingsDrawer
                columns={columns!}
                applyColumnSettings={applyColumnSettings}
                resetColumnSettings={resetColumnSettings}
                closeDrawer={closeDrawer}
            />
        );
    }, [applyColumnSettings, closeDrawer, columns, resetColumnSettings, showDrawer]);

    return (
        <GtbButton
            className="secondary-color-scheme iconOnLeft"
            disabled={!columns}
            variant="secondary"
            size="medium"
            onClick={showColumnDrawer}
        >
            <Icon name="pencil" size={13} />
            {translation("components.grid.columnSettings_button")}
        </GtbButton>
    );
}

export interface ColumnSettingsProps {
    columns?: ColumnSettings[];
    applyColumnSettings: applyColumnSettingsFunc;
    resetColumnSettings: () => void;
}

export interface ColumnSettings {
    title: string;
    width?: number;
    visible: boolean;
}
