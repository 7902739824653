import DataPickList from "../DataPickList";
import { ProductDetailReference } from "../../../../productDetail/productDetailTypes";
import { joinClassNames } from "../../../../utils/StringUtils";

import "./typedPickList.css";
import { TypedPicklist } from "./typedPicklistType";
import { FieldValues } from "react-hook-form/dist/types";

function ProductDetailPickList<FormItemType extends FieldValues>({
    className,
    ...rest
}: TypedPicklist<FormItemType, ProductDetailReference>) {
    return (
        <DataPickList
            className={joinClassNames("productDetailPickList", className)}
            itemId="id"
            itemLabel={(option) => `${option.name} (${option.code})`}
            queryUrl="/certificate-service/references/product-details"
            {...rest}
        />
    );
}

export default ProductDetailPickList;
