import DropdownMenuItem from "./DropdownMenuItem";
import { useSetDropdownStore } from "./dropdownStore";
import GtbLink, { GtbLinkProps } from "../routing/GtbLink";
import { useChangeNavigationContext } from "../breadcrumb/useNavigationContext";
import useGtbTranslation, { I18nKey } from "../../i18n/useGtbTranslation";

export interface DropdownLinkItemProps extends Omit<GtbLinkProps, "target"> {
    label: I18nKey;
    resetNavigationContext?: boolean;
}

function DropdownLinkItem({
    label,
    onClick,
    beforeNavigate,
    resetNavigationContext = true,
    ...rest
}: DropdownLinkItemProps) {
    const setDropdownStore = useSetDropdownStore();
    const { resetNavigationContext: _resetNavigationContext } = useChangeNavigationContext();
    const translation = useGtbTranslation();
    return (
        <DropdownMenuItem>
            <GtbLink
                onClick={(e) => {
                    setDropdownStore(false);
                    onClick?.(e);
                }}
                beforeNavigate={() => {
                    if (resetNavigationContext) {
                        _resetNavigationContext();
                    }
                    beforeNavigate?.();
                }}
                tabIndex={-1}
                {...rest}
            >
                {translation(label)}
            </GtbLink>
        </DropdownMenuItem>
    );
}

export default DropdownLinkItem;
