import GridBody, { GridBodyProps, onItemDoubleClickType } from "./component/GridBody";
import Icon from "../Icon";
import Grid, { GridColumn } from "./component/Grid";
import GridHeader from "./component/GridHeader";

import { ReactNode, useMemo } from "react";
import GridLoadingFooter from "./component/GridLoadingFooter";
import GtbLink from "../routing/GtbLink";
import "./component/grid.css";
import { Query, QueryCommands } from "./component/useQueryBuilder";
import { GridRowContextMenuAction } from "./component/cell/GridRowContextMenu";
import { ColumnOptions } from "./ColumnProvider";
import { joinClassNames } from "../../utils/StringUtils";
import useGtbTranslation from "../../i18n/useGtbTranslation";
import GridErrorFooter from "./component/GridErrorFooter";
import { Entity } from "../../utils/typeUtils";

export interface GridProps<ItemType extends Entity> {
    id?: string;
    additionalBodyComponent?: ReactNode;
    columnOptions: ColumnOptions<ItemType>;
    queryCommands?: QueryCommands;
    total?: number;
    query?: Query<any>;
    isLoading?: boolean;
    isError?: boolean;
    data?: ItemType[];
    columns: GridColumn<ItemType>[];
    onItemDoubleClick?: onItemDoubleClickType<ItemType>;
    itemActions?: GridRowContextMenuAction<ItemType>[];
    createUrl?: string;
    className?: string;
    gridControlsLeft?: ReactNode[];
    gridControlsRight?: ReactNode[];
    rowAttributeProvider?: GridBodyProps<ItemType>["rowAttributeProvider"];
}

function DataGrid<ItemType extends Entity>({
    data,
    isLoading,
    isError,
    columns,
    onItemDoubleClick,
    itemActions,
    additionalBodyComponent,
    createUrl,
    gridControlsLeft,
    columnOptions,
    gridControlsRight,
    className,
    rowAttributeProvider,
    ...rest
}: GridProps<ItemType>) {
    const translation = useGtbTranslation();
    const visibleColumns = useMemo(() => columns.filter((col) => col.visible), [columns]);

    return (
        <div className={joinClassNames("dataGridContainer", className)}>
            <div className="gridActionContainer">
                {gridControlsLeft}
                {createUrl && (
                    <GtbLink className="secondaryLinkItem" to={createUrl}>
                        {translation("components.grid.addListItem_button")} <Icon name="plus" size={12} />
                    </GtbLink>
                )}
                <div className="gridControlSpacer" />
                {gridControlsRight}
            </div>
            <Grid {...rest}>
                <GridHeader columns={visibleColumns} columnOptions={columnOptions} />
                <GridBody
                    columns={visibleColumns}
                    data={data}
                    onItemDoubleClick={onItemDoubleClick}
                    itemActions={itemActions}
                    rowAttributeProvider={rowAttributeProvider}
                />
                {additionalBodyComponent}
                <GridErrorFooter isError={!!isError} columnCount={visibleColumns.length} />
                <GridLoadingFooter isLoading={!!isLoading} columnCount={visibleColumns.length} />
            </Grid>
        </div>
    );
}

export default DataGrid;
