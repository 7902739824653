import { InputQuantityDetails, InputQuantitySourceTypeEnum } from "../inputQuantityTypes";
import { buildSchema, validateNumber, validateString } from "../../../../../../utils/yupExtension";
import { BaseSchema } from "yup";

export const inputQuantityValidationSchema = buildSchema<InputQuantityDetails>({
    typeOfInputSource: validateString().isRequired() as BaseSchema,
    quantity: validateNumber().isRequired(),
    inputProduct: validateString().when("typeOfInputSource", {
        is: (type: InputQuantitySourceTypeEnum) => type === "TC_PRODUCT",
        then: (schema) => schema.isRequired("inputQuantity.detail.inputSourceRequired_message"),
    }),
    inputByProduct: validateString().when("typeOfInputSource", {
        is: (type: InputQuantitySourceTypeEnum) => type === "BY_PRODUCT",
        then: (schema) => schema.isRequired("inputQuantity.detail.inputSourceRequired_message"),
    }),
    inputPreGtbTcProduct: validateString().when("typeOfInputSource", {
        is: (type: InputQuantitySourceTypeEnum) => type === "PRE_GTB_TC_PRODUCT",
        then: (schema) => schema.isRequired("inputQuantity.detail.inputSourceRequired_message"),
    }),
    inputOrganicMaterialPurchase: validateString().when("typeOfInputSource", {
        is: (type: InputQuantitySourceTypeEnum) => type === "ORGANIC_MATERIAL_PURCHASE",
        then: (schema) => schema.isRequired("inputQuantity.detail.inputSourceRequired_message"),
    }),
    currentInputSource: validateString().isRequired("inputQuantity.detail.inputSourceRequired_message"),
});
