import useByproductInputCombinationCreate from "./useByproductInputCombinationCreate";
import ByproductInputCombinationDetailView from "./ByproductInputCombinationDetailView";

function ByproductInputCombinationCreateView() {
    const byproductInputCombinationDetailProps = useByproductInputCombinationCreate();

    return <ByproductInputCombinationDetailView {...byproductInputCombinationDetailProps} />;
}

export default ByproductInputCombinationCreateView;
