import { KeyboardEventHandler, useCallback } from "react";

const focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

export const findFirstFocusableChild = (element?: Element | null) => {
    if (!element) {
        return null;
    }
    if (element.matches(focusableElements)) {
        return element as HTMLElement;
    }
    return (Array.from(element.querySelectorAll(focusableElements).values()) as HTMLElement[])[0];
};

export const useBuildArrowNavigationListener = (
    navigableTagName: keyof HTMLElementTagNameMap,
    direction: "horizontal" | "vertical"
): KeyboardEventHandler => {
    return useCallback(
        (e) => {
            const previousButton = direction === "horizontal" ? "ArrowLeft" : "ArrowUp";
            const nextButton = direction === "horizontal" ? "ArrowRight" : "ArrowDown";

            if (![previousButton, nextButton, "Home", "End"].includes(e.key)) {
                return;
            }
            e.preventDefault();

            let target: HTMLElement | null | undefined = e.target as HTMLElement;

            while (target && e.currentTarget !== target.closest(e.currentTarget.tagName)) {
                target = target.parentElement?.closest(navigableTagName);
            }

            if (!target) {
                return;
            }

            const navigableElement = target.closest(navigableTagName)!;
            const parent = e.currentTarget as HTMLElement;
            const nextElement = findFirstFocusableChild(
                findNextSiblingOfType(navigableElement.nextElementSibling, navigableTagName)
            );
            const previousElement = findFirstFocusableChild(
                findPreviousSiblingOfType(navigableElement.previousElementSibling, navigableTagName)
            );
            const firstElement = findFirstFocusableChild(
                findNextSiblingOfType(parent.firstElementChild, navigableTagName)
            )!;
            const lastElement = findFirstFocusableChild(
                findPreviousSiblingOfType(parent.lastElementChild, navigableTagName)
            )!;
            switch (e.key) {
                case previousButton:
                    if (previousElement) {
                        previousElement.focus();
                    } else {
                        lastElement.focus();
                    }
                    break;
                case nextButton:
                    if (nextElement) {
                        nextElement.focus();
                    } else {
                        firstElement.focus();
                    }
                    break;
                case "Home":
                    e.stopPropagation();
                    firstElement.focus();
                    break;
                case "End":
                    e.stopPropagation();
                    lastElement.focus();
                    break;
            }
        },
        [direction, navigableTagName]
    );
};

const findNextSiblingOfType = (firstSibling: Element | null, tagName: keyof HTMLElementTagNameMap) => {
    let nextSibling = firstSibling;
    while (nextSibling && nextSibling.tagName.toLowerCase() !== tagName) {
        nextSibling = nextSibling.nextElementSibling;
    }
    return nextSibling;
};

const findPreviousSiblingOfType = (firstSibling: Element | null, tagName: keyof HTMLElementTagNameMap) => {
    let previousSibling = firstSibling;
    while (previousSibling && previousSibling.tagName.toLowerCase() !== tagName) {
        previousSibling = previousSibling.previousElementSibling;
    }
    return previousSibling;
};
