import useAccreditationBodyEdit from "./useAccreditationBodyEdit";
import AccreditationBodyDetailView from "./AccreditationBodyDetailView";

function AccreditationBodyEditView() {
    const accreditationBodyDetailProps = useAccreditationBodyEdit();

    return <AccreditationBodyDetailView {...accreditationBodyDetailProps} />;
}

export default AccreditationBodyEditView;
