import Form from "../components/Form";
import GtbTextField from "../components/Input/GtbTextField";
import GtbButton from "../components/GtbButton";
import { UseGtbFormReturn } from "../hooks/formHandling/useGtbForm";
import { additionalAuthFactorTypeEnum, OneTimePasswordInputTypes } from "./useLogin";
import "./oneTimePassword.css";
import { useEffect } from "react";
import { joinClassNames } from "../utils/StringUtils";
import { LabeledCheckbox } from "../components/Input/Checkbox";
import useGtbTranslation from "../i18n/useGtbTranslation";

export default function OneTimePasswordForm({
    form,
    isLoading,
    onSubmit,
    isOneTimePasswordExpired,
    backToLogin,
    additionalAuthFactorType,
    isAdditionalAuthFactorSkippable,
}: {
    form: UseGtbFormReturn<OneTimePasswordInputTypes>;
    isLoading: boolean;
    onSubmit: (e: any) => void;
    isOneTimePasswordExpired: boolean;
    backToLogin: () => void;
    additionalAuthFactorType: additionalAuthFactorTypeEnum;
    isAdditionalAuthFactorSkippable: boolean;
}) {
    const translation = useGtbTranslation();

    if (isOneTimePasswordExpired) {
        return <ExpiredComponent backToLogin={backToLogin} />;
    } else {
        return (
            <Form onSubmit={onSubmit} className="oneTimePassword">
                <GtbTextField
                    className="oneTimePassword-form-oneTimePassword"
                    label="loginPage.one_time_password_input"
                    autoFocus
                    {...form.registerWithErrors("otp", {
                        required: true,
                        setValueAs: (value) => value?.replace(/\s/g, ""),
                    })}
                />
                {isAdditionalAuthFactorSkippable && (
                    <LabeledCheckbox
                        id="skipAdditionalAuthFactor"
                        label={"loginPage.one_time_password_reduce_prompting_checkbox"}
                        className="skipAdditionalAuthFactor"
                        {...form.form.register("skipAdditionalAuthFactor")}
                    />
                )}
                <div className="oneTimePassword-form-info">
                    {additionalAuthFactorType === "MAIL_OTP" ? (
                        <span>{translation("loginPage.one_time_password_otp_explanation")}</span>
                    ) : (
                        <span>{translation("loginPage.one_time_password_totp_explanation")}</span>
                    )}
                </div>
                <GtbButton className="oneTimePassword-form-submit" type="submit" disabled={isLoading}>
                    {translation("loginPage.one_time_password_submit_button")}
                </GtbButton>
            </Form>
        );
    }
}

function ExpiredComponent({ backToLogin }: { backToLogin: () => void }) {
    const translation = useGtbTranslation();

    // As for this effect, the success component must be treated as a separate component
    // otherwise the keyboard shortcut will always be in effect and bypass any validation
    useEffect(() => {
        const eventHandler = (event: KeyboardEvent) => {
            if (event.key === "Enter") {
                event.preventDefault();
                backToLogin();
            }
        };

        document.addEventListener("keydown", eventHandler);

        return () => document.removeEventListener("keydown", eventHandler);
    });

    return (
        <div className="oneTimePassword">
            <div className="oneTimePassword-form-info">
                <span id="expiredDescription">{translation("loginPage.one_time_password_expiration_explanation")}</span>
            </div>
            <GtbButton
                className={joinClassNames("oneTimePassword-form-backToLogin")}
                onClick={backToLogin}
                aria-describedby="expiredDescription"
            >
                {translation("loginPage.one_time_password_expiration_button")}
            </GtbButton>
        </div>
    );
}
