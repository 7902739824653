import { TransactionCertificateStatusType } from "../auth/roleTypes";
import { originationOfCertificatesTypeEnum } from "../scopeCertificate/scopeCertificateTypes";

export interface TransactionCertificateSummary {
    id: string;
    tcNumber: string;
    scopeCertificateStandardVersion: string;
    sellerBusinessName: string;
    buyerBusinessName: string;
    status: TransactionCertificateStatusType;
    approvingPersonUsername: string;
    cbTcReferenceNumber: string;
    certificationBodyBusinessName: string;
    dateOfIssue: Date;
    declarationsBySeller: string;
    doNotDisplayLastProcessorOnPrintedTc: boolean;
    facilityAsSellerBusinessName: string;
    facilityAsBuyerBusinessName: string;
    placeOfIssue: string;
    referencedSc: string;
}

export interface TransactionCertificateDetails {
    id: string;
    certificationBody: string;
    cbTcReferenceNumber: string;
    seller: string;
    facilityAsSeller: string;
    buyer: string;
    facilityAsBuyer: string;
    tcNumber: string;
    status: TransactionCertificateStatusType;
    scopeCertificate: string;
    dateOfIssue: Date;
    placeOfIssue: string;
    approvingPerson: string;
    doNotDisplayLastProcessorOnPrintedTc: boolean;
    declarationsBySeller: string;
    certificateOrigin: originationOfCertificatesTypeEnum;
    certificateDocument: string | null;
    cbCertificate: string | null;
    documentHash: string;
    hashAlgorithm: string;
    documentSize: number;
}

export interface TransactionCertificateReference
    extends Pick<
        TransactionCertificateDetails & TransactionCertificateSummary,
        | "id"
        | "facilityAsBuyerBusinessName"
        | "facilityAsSellerBusinessName"
        | "status"
        | "scopeCertificate"
        | "tcNumber"
        | "dateOfIssue"
    > {
    sellerLegalName: string;
    buyerLegalName: string;
}

export const transactionCertificateReadOnlyByStatus = (status?: TransactionCertificateDetails["status"]) =>
    status === "VALID" || status === "WITHDRAWN";
