import { NavigateProps, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { getBaseUrl, routeEnum, routes } from "./useResolvedRoute";
import useAuth from "../../auth/useAuth";

export type requireConditionType = "logged_in" | "not_logged_in" | "all" | boolean;

interface UseRequireAuthState {
    isAuthorised: "pending" | true | false;
    newTargetProperties: NavigateProps;
}

const validAuthState = (): UseRequireAuthState => {
    return { isAuthorised: true, newTargetProperties: { to: "" } };
};

function useRequireAuth(condition: requireConditionType) {
    const [authState, setAuthState] = useState<UseRequireAuthState>({
        isAuthorised: "pending",
        newTargetProperties: { to: "" },
    });
    const { currentUser } = useAuth();
    const { state, pathname, search } = useLocation();

    useEffect(() => {
        const pathnameIncludingSearchParams = pathname + search ?? "";

        if (
            condition === "all" ||
            (condition === "logged_in" && currentUser) ||
            (condition === "not_logged_in" && !currentUser) ||
            condition === true
        ) {
            setAuthState(validAuthState());
        } else if (!currentUser && (condition === "logged_in" || condition === false)) {
            setAuthState({
                isAuthorised: false,
                newTargetProperties: {
                    to: getBaseUrl("login"),
                    state: { from: { pathname: pathnameIncludingSearchParams } },
                    replace: true,
                },
            });
        } else if (condition === false) {
            setAuthState({
                isAuthorised: false,
                newTargetProperties: {
                    to: getBaseUrl("errorForbidden"),
                    replace: true,
                },
            });
        } else {
            setAuthState({
                isAuthorised: false,
                newTargetProperties: {
                    to: getNewTargetPath(pathnameIncludingSearchParams, state),
                    replace: true,
                },
            });
        }
    }, [pathname, state, setAuthState, condition, search, currentUser]);

    return authState;
}

export default useRequireAuth;

function getNewTargetPath(currentPath: string, state: any) {
    if (currentPath === getBaseUrl("login")) {
        return state?.from || getBaseUrl("homepage");
    }

    for (const key in routes) {
        if (key !== "homepage" && currentPath.startsWith(getBaseUrl(key as routeEnum))) {
            return getBaseUrl("homepage");
        }
    }

    return getBaseUrl("errorNotFound");
}
