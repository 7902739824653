import { FieldValues } from "react-hook-form/dist/types";
import { TypedPicklist } from "./typedPicklistType";
import DataPickList from "../DataPickList";
import { joinClassNames } from "../../../../utils/StringUtils";
import { PickListItemPresentationProps } from "../GtbPickList";
import { ScProductReference } from "../../../../scopeCertificate/details/products/scProductTypes";

interface ScProductPicklistProps<FormItemType extends FieldValues>
    extends TypedPicklist<FormItemType, ScProductReference> {
    flags?: ScProductPickListFlags;
}

export interface ScProductPickListFlags {
    scopeCertificateId?: string;
}

export const scProductPickListCombinedClassName = (className?: string) =>
    joinClassNames("scProductPickList", className);
export const scProductPickListItemPresentation: PickListItemPresentationProps<ScProductReference> = {
    itemId: "id",
    selectedItemLabel: "scProductNumber",
    itemLabel: (option) =>
        `${option.scProductNumber}:\n${option.productCategoryPrint},\n${option.productDetailPrint},\n${option.labelGradeLabelGrade}`,
};

export default function ScProductPickList<FormItemType extends FieldValues>({
    className,
    ...rest
}: ScProductPicklistProps<FormItemType>) {
    return (
        <DataPickList
            {...scProductPickListItemPresentation}
            queryUrl="/certificate-service/references/sc-products"
            className={scProductPickListCombinedClassName(className)}
            {...rest}
        />
    );
}
