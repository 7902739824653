import { FilterComponentProps } from "../../ColumnProvider";
import "./gridfilter.css";
import { GtbTextFieldProps } from "../../../Input/GtbTextField";
import GtbPickList from "../../../Input/pickList/GtbPickList";
import useGtbTranslation, { I18nKey } from "../../../../i18n/useGtbTranslation";
import { buildEnumOptionsFromObject } from "../../../../utils/enumUtils";

interface OptionFilterProps<ItemType>
    extends FilterComponentProps<ItemType>,
        Omit<GtbTextFieldProps, "className" | "startAdornment" | "placeholder" | "title"> {
    itemKey: string;
    title: I18nKey;
    options: {};
}

function OptionFilter<ItemType>({
    itemKey,
    title,
    filter,
    setFilter,
    filterKey,
    options,
}: OptionFilterProps<ItemType>) {
    const translation = useGtbTranslation();

    return (
        <GtbPickList
            id={itemKey + "Filter"}
            className="gridFilter"
            aria-label={translation({
                key: "components.grid.filterBy_accessibleLabel",
                options: { title: translation(title) },
            })}
            placeholder={translation("components.grid.filterPicklistPlaceholder")}
            value={filter ?? null}
            onChange={(_event, newValue) => {
                setFilter(filterKey, newValue);
            }}
            itemId="id"
            itemLabel="label"
            data={buildEnumOptionsFromObject(options)}
        />
    );
}

export default OptionFilter;
