import GtbTextField, { GtbTextFieldProps } from "./GtbTextField";
import { forwardRef } from "react";
import { joinClassNames } from "../../utils/StringUtils";

const GtbTextArea = forwardRef<HTMLDivElement, GtbTextFieldProps>(
    ({ minRows = 3, maxRows = 10, className, ...rest }: GtbTextFieldProps, ref) => {
        return (
            <GtbTextField
                ref={ref}
                multiline={true}
                minRows={minRows}
                maxRows={maxRows}
                className={joinClassNames("gtb-textarea", className)}
                {...rest}
            />
        );
    }
);

export default GtbTextArea;
