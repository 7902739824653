import useUserListView from "./useUserListView";
import EntityListView from "../components/entity/EntityListView";
import useUserSearchContext, { UserSearchContextSelection } from "./useUserSearchContext";
import { useHelpContext } from "../help/context-sensitivity/useHelpContext";

export default function UserListView({ searchContext }: UserlistViewProps) {
    const resolvedSearchContext = useUserSearchContext(searchContext);
    const userGridOptions = useUserListView(resolvedSearchContext);
    useHelpContext("user-list");

    return <EntityListView heading={resolvedSearchContext.navigationContextLabel} gridOptions={userGridOptions} />;
}

export interface UserlistViewProps {
    searchContext: UserSearchContextSelection;
}
