import useWarningDialog from "../../components/dialog/UseWarningDialog";
import { useCallback } from "react";

export default function useCheckForEqualCanonicalTcNumber() {
    const { showDialog } = useWarningDialog();

    return useCallback(
        (savedItem) => {
            if (savedItem.warningCanonicalEqualTcNumber) {
                showDialog({
                    title: "preGtbTransactionCertificate.detail.duplicateCanonicalTcNumberDialog_title",
                    message: {
                        key: "preGtbTransactionCertificate.detail.duplicateCanonicalTcNumberDialog_message",
                        options: { duplicateCanonicalTcNumber: savedItem.warningCanonicalEqualTcNumber },
                    },
                });
            }
        },
        [showDialog]
    );
}
