import { FieldPath, RegisterOptions, useForm, UseFormRegisterReturn } from "react-hook-form";
import { FieldValues, UseFormProps, UseFormReturn } from "react-hook-form/dist/types";
import { useCallback, useMemo } from "react";
import { getValidationErrorMessage, validationErrorCode } from "../../utils/errorHandler";
import useGtbTranslation, { I18nKey } from "../../i18n/useGtbTranslation";
import _ from "lodash";

function useGtbForm<TFieldValues extends FieldValues = FieldValues, TContext = any>(
    props?: UseFormProps<TFieldValues, TContext>
): UseGtbFormReturn<TFieldValues, TContext> {
    const form = useForm(props);
    const translation = useGtbTranslation();

    const setErrors = useCallback(
        (errorObj: ValidationError<TFieldValues>) => {
            Object.entries(errorObj)
                .flatMap((entry) => entry[0].split(", ").map((key) => [key, entry[1]]))
                .forEach((err) => {
                    // @ts-ignore
                    form.setError(err[0], { message: getValidationErrorMessage(err[1]) });
                });
        },
        [form]
    );

    const setValueAsString = (value: any) => (!value || value === "" ? null : value);

    // @ts-ignore
    const registerWithErrors: RegisterWithErrors<TFieldValues> = useCallback(
        (name, options?) => {
            return {
                ...form.register(name, { setValueAs: setValueAsString, ...options }),
                error: !!_.get(form, "formState.errors." + name),
                helperText: _.get(form, "formState.errors." + name + ".message")
                    ? translation(_.get(form, "formState.errors." + name + ".message") as I18nKey)
                    : undefined,
            };
        },
        [form, translation]
    );

    const setValueAsNumber = (value: any) => (value === "0" || value === 0 ? 0 : +value || null);
    const registerNumberWithErrors: RegisterWithErrors<TFieldValues> = useCallback(
        (name, options?) => {
            return registerWithErrors(name, {
                setValueAs: setValueAsNumber,
                ...options,
            });
        },
        [registerWithErrors]
    );

    return useMemo(() => {
        return {
            form,
            registerWithErrors,
            registerNumberWithErrors,
            setErrors,
        };
    }, [form, registerNumberWithErrors, registerWithErrors, setErrors]);
}

export default useGtbForm;

export interface UseGtbFormReturn<TFieldValues extends FieldValues, TContext = any> {
    form: UseFormReturn<TFieldValues, TContext>;
    registerWithErrors: RegisterWithErrors<TFieldValues>;
    registerNumberWithErrors: RegisterWithErrors<TFieldValues>;
    setErrors: (errorObj: ValidationError<TFieldValues>) => void;
}

export interface RegisterWithErrors<TFieldValues extends FieldValues> {
    (name: FieldPath<TFieldValues>, options?: RegisterOptions<TFieldValues>): UseGtbFormRegisterReturn;
}

interface UseGtbFormRegisterReturn extends UseFormRegisterReturn {
    error: boolean;
    helperText: string | undefined;
}

export type ValidationError<ItemType> = {
    [key in keyof ItemType]: validationErrorCode;
};
