import { joinClassNames } from "../../utils/StringUtils";
import GtbTextField from "../../components/Input/GtbTextField";
import ControlledDatePicker from "../../components/Input/datepicker/ControlledDatePicker";
import "./certificationLicenceBasicData.css";
import { CertificationLicenceDetailViewTabProps } from "./CertificationLicenceDetailView";
import StandardPickList from "../../components/Input/pickList/typedPicklist/StandardPickList";

export default function CertificationLicenceBasicData({
    form,
    readOnlyProps,
    certificationBodyName,
}: CertificationLicenceDetailViewTabProps) {
    return (
        <div className={joinClassNames("certificationLicenceBasicData", "formGrid")}>
            <GtbTextField
                label="certificationLicence.detail.certificationBody_input"
                className="certificationBody"
                readOnly
                value={certificationBodyName}
            />
            <StandardPickList
                control={form.form.control}
                name="standard"
                className="standard"
                readOnly={readOnlyProps.isStandardReadOnly}
                label="certificationLicence.detail.standard_input"
            />
            <GtbTextField
                {...form.registerWithErrors("licencingCode")}
                readOnly={readOnlyProps.isLicencingCodeReadOnly}
                label="certificationLicence.detail.licencingCode_input"
                className="licencingCode"
            />
            <ControlledDatePicker
                control={form.form.control}
                readOnly={readOnlyProps.isStartDateReadOnly}
                name="startDate"
                label="certificationLicence.detail.startDate_input"
                className="startDate"
            />
            <ControlledDatePicker
                control={form.form.control}
                readOnly={readOnlyProps.isEndDateReadOnly}
                name="endDate"
                label="certificationLicence.detail.endDate_input"
                className="endDate"
            />
        </div>
    );
}
