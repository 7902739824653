import useIsAuthorised from "../../auth/useIsAuthorised";
import { SortOption, useQueryBuilder } from "../../components/grid/component/useQueryBuilder";
import { AdditionalMaterialDetails, AdditionalMaterialSummary } from "./additionalMaterialTypes";
import { useMemo } from "react";
import { useDeleteAction, useShowDetailsAction } from "../../utils/defaultActions";
import { buildNumberColumn, buildTextColumn } from "../../components/grid/ColumnProvider";
import { GridColumn } from "../../components/grid/component/Grid";
import useResolvedRoute from "../../components/routing/useResolvedRoute";
import { SubListProps } from "../../components/grid/SubList";
import useGridRowContextMenuActions from "../../components/grid/component/cell/useGridRowContextMenuActions";

export const defaultAdditionalMaterialSortOption: SortOption<AdditionalMaterialSummary | AdditionalMaterialDetails> = {
    field: "rawMaterial",
    direction: "ASC",
};

const columns: GridColumn<AdditionalMaterialSummary>[] = [
    buildTextColumn({ key: "rawMaterialCode", title: "additionalMaterial.list.code_column", minWidth: 96 }),
    buildTextColumn({ key: "rawMaterialName", title: "additionalMaterial.list.rawMaterial_column", minWidth: 155 }),
    buildNumberColumn({
        key: "maximumPercentage",
        title: "additionalMaterial.list.maximumPercentage_column",
        minWidth: 106,
        contentProvider: (item) => `${item.maximumPercentage} %`,
    }),
];

export default function useAdditionalMaterialSubList() {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedEditUrl, getResolvedId, getResolvedCreateUrl } = useResolvedRoute();
    const materialContentRuleId = getResolvedId("materialContentRule");

    const { query, columnOptions, forceReload } = useQueryBuilder<AdditionalMaterialSummary>(
        `/certificate-service/material-content-rules/${materialContentRuleId}/additional-materials`,
        {
            sort: defaultAdditionalMaterialSortOption,
            filterActive: false,
        }
    );
    const showDetailsAction = useShowDetailsAction(
        (item: AdditionalMaterialSummary) => getResolvedEditUrl("additionalMaterial", item.id),
        query.parameter
    );

    const deleteAction = useDeleteAction(
        (item: AdditionalMaterialSummary) =>
            `/certificate-service/material-content-rules/${materialContentRuleId}/additional-materials/${item.id}`,
        () => forceReload(),
        "deleteMasterData"
    );

    const contextMenuActions = useGridRowContextMenuActions(showDetailsAction, deleteAction);

    return useMemo<SubListProps<AdditionalMaterialSummary>>(
        () => ({
            query,
            columns,
            columnOptions,
            uniqueDataKey: "id" as keyof AdditionalMaterialSummary,
            onItemDoubleClick: showDetailsAction,
            header: "additionalMaterial.list.header",
            itemActions: contextMenuActions,
            createUrl: hasAccessRight("createMasterData") ? getResolvedCreateUrl("additionalMaterial") : undefined,
            tableName: "additionalMaterials",
        }),
        [query, columnOptions, showDetailsAction, contextMenuActions, hasAccessRight, getResolvedCreateUrl]
    );
}
