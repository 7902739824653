import "./helpview.css";
import ViewHeading from "../../components/ViewHeading";
import LoadingSpinner from "../../components/LoadingSpinner";
import { useReadHelpPage } from "./useReadHelpPage";
import MarkdownContainer from "../markdown/MarkdownContainer";
import { useParams } from "react-router-dom";
import HelpViewNavigation from "./HelpViewNavigation";
import { joinClassNames } from "../../utils/StringUtils";
import HelpPageErrorMessage from "./HelpPageErrorMessage";
import Header from "../../mainLayout/header/Header";
import Footer from "../../mainLayout/footer/Footer";
import React from "react";
import { useHelpPages } from "./useHelpPages";
import useGtbTranslation from "../../i18n/useGtbTranslation";
import useSessionTimeout from "../../auth/useSessionTimeout";
import useAcceptTermsOfUse from "../../user/termsOfUse/useAcceptTermsOfUse";
import { env } from "../../env";

function HelpView() {
    const translation = useGtbTranslation();
    const { "*": topic } = useParams();
    const { status, isLoading: contentIsLoading, helpPageData } = useReadHelpPage(topic);
    const { helpPages, navigationIsLoading } = useHelpPages();

    useSessionTimeout();
    useAcceptTermsOfUse();

    const environmentName = env.REACT_APP_ENVIRONMENT_NAME;

    return (
        <div className="help-layout">
            <Header>{environmentName && <span className={"environment-name"}>{environmentName}</span>}</Header>
            {navigationIsLoading ? (
                <LoadingSpinner />
            ) : (
                <div className={joinClassNames("help-grid")}>
                    <ViewHeading className="help-heading">{translation("help.header")}</ViewHeading>
                    <HelpViewNavigation className="help-navigation" helpPages={helpPages!} />
                    <main className="help-content">
                        {(() => {
                            if (contentIsLoading) return <LoadingSpinner />;
                            if (status === "error") return <HelpPageErrorMessage />;
                            if (helpPageData)
                                return <MarkdownContainer content={helpPageData.content}></MarkdownContainer>;
                        })()}
                    </main>
                </div>
            )}
            <Footer />
        </div>
    );
}

export default HelpView;
