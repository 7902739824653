import DropdownMenuItem from "../../../components/dropdown/DropdownMenuItem";
import useGtbTranslation, { I18nKey } from "../../../i18n/useGtbTranslation";
import { backendUrlType } from "../../../hooks/useAxios";
import { useMemo } from "react";
import useBulkReportDownload from "../../../hooks/useBulkReportDownload";
import { useSetDropdownStore } from "../../../components/dropdown/dropdownStore";

interface BulkReportDownloadMenuItemProps {
    label: I18nKey;
    fileUrl: backendUrlType;
    baseFileName: I18nKey;
}

export default function BulkReportDownloadMenuItem({ label, fileUrl, baseFileName }: BulkReportDownloadMenuItemProps) {
    const translation = useGtbTranslation();
    const setDropdownStore = useSetDropdownStore();
    const fileName = useMemo(
        () => `${translation(baseFileName)}_${new Date().toISOString().slice(0, 10)}.csv`,
        [baseFileName, translation]
    );
    const download = useBulkReportDownload(fileUrl, fileName);

    return (
        <DropdownMenuItem>
            <a
                href={"/api" + fileUrl}
                download={fileName}
                onClick={(e) => {
                    setDropdownStore(false);
                    e.preventDefault();
                    download();
                }}
            >
                {translation(label)}
            </a>
        </DropdownMenuItem>
    );
}
