import * as React from 'react';
import {ReactNode} from 'react';

interface DialogFooterProps {
    children?: ReactNode,
}

function DialogFooter({children}: DialogFooterProps) {
    return (
        <div className={"dialog-footer"}>
            {children}
        </div>
    );
}

export default DialogFooter;