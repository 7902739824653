import { useCallback } from "react";
import DialogHeader from "./DialogHeader";
import DialogTextContent from "./DialogTextContent";
import DialogFooter from "./DialogFooter";
import useDialog, { DialogProps } from "./useDialog";

function UseUnclosableDialog() {
    const { showDialog } = useDialog();

    return useCallback(
        ({ title, message }: DialogProps) => {
            showDialog({
                header: <DialogHeader title={title} variant="info" />,
                content: <DialogTextContent message={message} />,
                footer: <DialogFooter />,
            });
        },
        [showDialog]
    );
}

export default UseUnclosableDialog;
