import SvgArc from "../../../../../components/worldMap/SvgArc";
import { joinClassNames } from "../../../../../utils/StringUtils";
import { ActiveMarker, GroupMarker, LocationMarker, RootMarker } from "../../../../../components/worldMap/SvgMarker";
import { materialMarkerLegendId, productMarkerLegendId } from "../../../../../components/worldMap/WorldMap";
import { TrackingNode, uniqueIndexByState } from "./TcProductMapView";
import { RequireInheritedProps } from "../../../../../utils/typeUtils";

type TrackingPathNode = { uniqueIndex: string } & TrackingNode;

export type TcProductTrackingPathProps = {
    marker?: {
        root: TrackingPathNode;
        uniqueInputs: TrackingPathNode[];
        groups: { uniqueIndex: string; inputs: TrackingPathNode[] }[];
        arcs: TrackingPathNode[];
    };
    mapState?: TrackingNode & ActiveMarker;
};

export default function TcProductTrackingPath({ marker, mapState }: TcProductTrackingPathProps) {
    const currentMarkerIsRoot = !mapState?.type || mapState?.type === "ROOT_PRODUCT";
    if (!marker) {
        return null;
    }
    return (
        <>
            {marker.arcs.map((arc) => (
                <SvgArc
                    key={arc.uniqueIndex}
                    sourceCoordinate={arc.coordinate}
                    targetCoordinate={arc.parent!}
                    className={joinClassNames(
                        classNameByNodeType(arc),
                        currentMarkerIsRoot ||
                            (arc.traceabilityChain.startsWith(mapState.traceabilityChain) &&
                                arc.traceabilityChain !== mapState.traceabilityChain)
                            ? "tracedInput"
                            : null,
                        mapState?.traceabilityChain?.startsWith(arc.traceabilityChain) ? "parentInput" : null
                    )}
                />
            ))}
            <RootMarker
                key="ROOT_MARKER"
                coordinate={marker.root.coordinate}
                className="productMarker"
                aria-describedby={productMarkerLegendId}
                disabled={currentMarkerIsRoot}
                targetState={marker.root}
            />
            {marker.groups.map((group) => {
                const groupMark = group.inputs[0];
                const coordinate = groupMark.coordinate;
                return (
                    <GroupMarker
                        key={group.uniqueIndex}
                        id={group.uniqueIndex}
                        aria-expanded={mapState?.openState === group.uniqueIndex}
                        aria-label={{
                            key: "components.worldMap.groupMarker_label",
                            options: {
                                groupSize: group.inputs.length,
                                stateProvince: groupMark.state,
                                countryArea: groupMark.country,
                            },
                        }}
                        coordinate={coordinate}
                        className={
                            !currentMarkerIsRoot && uniqueIndexByState(mapState) === group.uniqueIndex
                                ? "activeMarkerGroup"
                                : undefined
                        }
                        targetState={{
                            ...mapState,
                            openState: mapState?.openState === group.uniqueIndex ? undefined : group.uniqueIndex,
                        }}
                        marker={group.inputs.map((input) => ({
                            "aria-describedby": describedByIdByNodeType(input),
                            className: classNameByNodeType(input),
                            disabled: input.uniqueIndex === mapState?.uniqueIndex,
                            targetState: input,
                        }))}
                    />
                );
            })}
            {marker.uniqueInputs.map(({ coordinate, ...input }) => (
                <LocationMarker
                    key={input.uniqueIndex}
                    aria-describedby={describedByIdByNodeType(input)}
                    coordinate={coordinate}
                    className={classNameByNodeType(input)}
                    disabled={input.uniqueIndex === mapState?.uniqueIndex}
                    targetState={input}
                />
            ))}
        </>
    );
}

const classNameByNodeType = (node: RequireInheritedProps<TrackingNode, "type">) =>
    node.type === "INPUT_PRODUCTION" ? "materialMarker" : "productMarker";

const describedByIdByNodeType = (node: RequireInheritedProps<TrackingNode, "type">) =>
    node.type === "INPUT_PRODUCTION" ? materialMarkerLegendId : productMarkerLegendId;
