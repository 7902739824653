import useExternalIdListView from "../useExternalIdListView";

export default function useExternalOrganicMaterialProducerIdListViewList() {
    return useExternalIdListView({
        tableName: "externalOrganicMaterialProducerIds",
        i18nKey: "externalOrganicMaterialProducerId",
        routeEnumKey: "externalOrganicMaterialProducerId",
        parentEntityType: "ORGANIC_MATERIAL_PRODUCER",
    });
}
