import { useMemo } from "react";
import useIsAuthorised from "../auth/useIsAuthorised";
import { useDeleteAction, useShowDetailsAction } from "../utils/defaultActions";
import { useQueryBuilder } from "../components/grid/component/useQueryBuilder";
import useResolvedRoute, { routeEnum } from "../components/routing/useResolvedRoute";
import { SubListProps } from "../components/grid/SubList";
import { ExternalIdSummary } from "./externalIdTypes";
import { GridColumn } from "../components/grid/component/Grid";
import { buildDataColumn, buildTextColumn } from "../components/grid/ColumnProvider";
import { I18nKey } from "../i18n/useGtbTranslation";
import { EnumEntityType } from "../components/Input/pickList/typedEnumPicklist/enumEntityType";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";

export default function useExternalIdSubList({
    i18nKey,
    parentIdParamValue,
    routeEnumKey,
    tableName,
}: UseExternalIdsSubListProps) {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedEditUrl, getResolvedCreateUrl } = useResolvedRoute();

    const { query, columnOptions, forceReload } = useQueryBuilder<ExternalIdSummary>(
        "/certificate-service/external-ids",
        {
            sort: {
                field: "externalId",
                direction: "ASC",
            },
            filterActive: false,
            parentId: parentIdParamValue,
        }
    );

    const showDetailsAction = useShowDetailsAction(
        (item: ExternalIdSummary) => getResolvedEditUrl(routeEnumKey, item.id),
        query.parameter
    );

    const deleteAction = useDeleteAction(
        (item: ExternalIdSummary) => `/certificate-service/external-ids/${item.id}`,
        () => forceReload(),
        "deleteExternalId"
    );

    const contextMenuActions = useGridRowContextMenuActions(showDetailsAction, deleteAction);

    const columns = useMemo<GridColumn<ExternalIdSummary>[]>(
        () => [
            buildTextColumn({
                key: "externalId",
                title: `${i18nKey}.list.externalId_column` as I18nKey,
                minWidth: 124,
            }),
            buildDataColumn<ExternalIdSummary, EnumEntityType>({
                key: "type",
                title: `${i18nKey}.list.type_column` as I18nKey,
                minWidth: 50,
                itemId: "id",
                itemLabel: "name",
                url: "/certificate-service/references/type-of-external-ids",
            }),
            buildTextColumn({
                key: "certificationBodyBusinessName",
                title: `${i18nKey}.list.certificationBody_column` as I18nKey,
                minWidth: 176,
            }),
        ],
        [i18nKey]
    );

    return useMemo<SubListProps<ExternalIdSummary>>(
        () => ({
            query,
            columns,
            uniqueDataKey: "id",
            onItemDoubleClick: showDetailsAction,
            header: `${i18nKey}.list.header` as I18nKey,
            itemActions: contextMenuActions,
            columnOptions,
            createUrl: hasAccessRight("createExternalId") ? getResolvedCreateUrl(routeEnumKey) : undefined,
            tableName,
        }),
        [
            columnOptions,
            columns,
            contextMenuActions,
            getResolvedCreateUrl,
            hasAccessRight,
            i18nKey,
            query,
            routeEnumKey,
            showDetailsAction,
            tableName,
        ]
    );
}

export interface UseExternalIdsSubListProps {
    parentIdParamValue?: string;
    tableName: string;
    i18nKey: I18nKey;
    routeEnumKey: routeEnum;
}
