import { joinClassNames } from "../../utils/StringUtils";
import "./allowedMaterialBasicData.css";
import RawMaterialPickList from "../../components/Input/pickList/typedPicklist/RawMaterialPickList";
import { UseGtbFormReturn } from "../../hooks/formHandling/useGtbForm";
import { AllowedMaterialDetails } from "../allowedMaterialTypes";
import { AllowedMaterialReadOnlyProps } from "./AllowedMaterialDetailView";
import StandardPickList from "../../components/Input/pickList/typedPicklist/StandardPickList";

export default function AllowedMaterialBasicData({ form, readOnlyProps }: AllowedMaterialBasicDataProps) {
    return (
        <div className={joinClassNames("allowedMaterialBasicData", "formGrid")}>
            <StandardPickList
                control={form.form.control}
                name="standard"
                className="standard"
                readOnly={readOnlyProps.isStandardReadOnly}
                label="allowedMaterial.detail.standard_input"
            />
            <RawMaterialPickList
                control={form.form.control}
                name="rawMaterial"
                className="rawMaterial"
                readOnly={readOnlyProps.isRawMaterialReadOnly}
                label="allowedMaterial.detail.rawMaterial_input"
            />
        </div>
    );
}

export interface AllowedMaterialBasicDataProps {
    form: UseGtbFormReturn<AllowedMaterialDetails>;
    readOnlyProps: AllowedMaterialReadOnlyProps;
}
