import { ComponentType, useEffect, useMemo } from "react";
import { GridHeaderCellProps } from "../component/GridHeaderCell";
import { ColumnOptions, FilterComponentProps } from "../ColumnProvider";

interface WithFilterProps<ItemType> {
    filterKey: FilterComponentProps<ItemType>["filterKey"];
    filter: ColumnOptions<ItemType>["filter"];
    FilterComponent: ComponentType<FilterComponentProps<ItemType>>;
}

export default function withFilter<ItemType>(GridHeaderCell: ComponentType<GridHeaderCellProps>) {
    return ({filter, filterKey, FilterComponent, ...rest}: GridHeaderCellProps & WithFilterProps<ItemType>) => {
        const setFilter = useMemo(() => filter.setFilter, [filter.setFilter]);
        useEffect(() => {
            return () => {
                setFilter(filterKey, undefined);
            };
        }, [filterKey, setFilter]);

        // @ts-ignore
        const filterProducer = () => filter.value?.[filterKey];

        return (
            <GridHeaderCell
                component={
                    filter.filterActive && FilterComponent ? (
                        <FilterComponent
                            filterKey={filterKey}
                            setFilter={setFilter}
                            filter={filterProducer()}
                        />
                    ) : undefined
                }
                {...rest}
            />
        );
    };
}
