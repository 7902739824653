import { useCallback, useEffect, useMemo } from "react";
import { InputQuantityDetailViewProps } from "./InputQuantityDetailView";
import { InputQuantityDetails } from "../inputQuantityTypes";
import { yupResolver } from "@hookform/resolvers/yup";
import { inputQuantityValidationSchema } from "./inputQuantityValidationSchema";
import useIsAuthorised from "../../../../../../auth/useIsAuthorised";
import useResolvedRoute from "../../../../../../components/routing/useResolvedRoute";
import { transactionCertificateReadOnlyByStatus } from "../../../../../transactionCertificateTypes";
import useDomainEntityDetailsEdit from "../../../../../../hooks/details/useDomainEntityDetailsEdit";
import { defaultInputQuantitySortOption } from "../../useInputQuantitySubList";
import { useQuery } from "../../../../../../hooks/useAxios";
import useSetFormValue from "../../../../../../hooks/formHandling/useSetFormValue";
import useInputSourceNameProvider from "./inputSource/useInputSourceNameProvider";

export default function useInputQuantityEdit(): InputQuantityDetailViewProps {
    const { hasAccessRight } = useIsAuthorised();

    const { getResolvedId } = useResolvedRoute();
    const transactionCertificateId = getResolvedId("transactionCertificate")!;
    const tcProductId = getResolvedId("tcProduct")!;
    const inputQuantityId = getResolvedId("inputQuantity")!;

    const canSave = useCallback(
        (item: InputQuantityDetails) => {
            if (item) {
                return (
                    hasAccessRight("updateTransactionCertificate", [item.transactionCertificateStatus]) &&
                    !transactionCertificateReadOnlyByStatus(item.transactionCertificateStatus)
                );
            }
            return false;
        },
        [hasAccessRight]
    );

    const { detailViewProps, form, isReadOnly, title, recordNavigationProps, initialData } =
        useDomainEntityDetailsEdit<InputQuantityDetails>({
            baseUrl: `/certificate-service/transaction-certificates/${transactionCertificateId}/tc-products/${tcProductId}/input-quantities/${inputQuantityId}`,
            frontendRoute: "inputQuantity",
            recordNavigationDefaultSort: defaultInputQuantitySortOption,
            titleBuilder: (item) => item.inputNumber,
            resolver: yupResolver(inputQuantityValidationSchema),
            canSave,
            historyColumns: [
                {
                    identifier: "quantity" as keyof InputQuantityDetails,
                    title: "inputQuantity.history.quantityToBeUsed_column",
                },
            ],
        });

    const setFormValue = useSetFormValue(form.form);

    const readOnly = useMemo(
        () => ({
            typeOfInputSource: true,
            quantity: isReadOnly,
        }),
        [isReadOnly]
    );

    const { runQuery: fetchInputSource, isLoading: isFetchingInputSource } = useQuery({
        url: "",
        enabled: false,
    });
    const getInputSourceName = useInputSourceNameProvider();

    useEffect(() => {
        if (initialData) {
            let url = "/certificate-service/references/";
            url += initialData.typeOfInputSource === "TC_PRODUCT" ? `tc-products/${initialData.inputProduct}` : "";
            url += initialData.typeOfInputSource === "BY_PRODUCT" ? `tc-products/${initialData.inputByProduct}` : "";
            url +=
                initialData.typeOfInputSource === "PRE_GTB_TC_PRODUCT"
                    ? `pre-gtb-products/${initialData.inputPreGtbTcProduct}`
                    : "";
            url +=
                initialData.typeOfInputSource === "ORGANIC_MATERIAL_PURCHASE"
                    ? `organic-material-purchases/${initialData.inputOrganicMaterialPurchase}`
                    : "";
            fetchInputSource({
                url,
            }).then((input) =>
                form.form.reset({
                    ...initialData,
                    currentInputSource: getInputSourceName({
                        type: initialData.typeOfInputSource,
                        inputSource: input,
                    }),
                })
            );
        }
    }, [fetchInputSource, form.form, getInputSourceName, initialData, setFormValue]);

    return useMemo<InputQuantityDetailViewProps>(
        () => ({
            currentSourceType: initialData?.typeOfInputSource!,
            detailViewProps: { ...detailViewProps, isLoading: detailViewProps.isLoading, isFetchingInputSource },
            form,
            readOnly,
            title,
            recordNavigationProps,
            createRoute: !isReadOnly ? "inputQuantity" : undefined,
            inputSourceSelectionVisible: false,
        }),
        [
            initialData?.typeOfInputSource,
            detailViewProps,
            isFetchingInputSource,
            form,
            readOnly,
            title,
            recordNavigationProps,
            isReadOnly,
        ]
    );
}
