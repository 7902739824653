import { useCallback, useMemo } from "react";
import { ScFacilityDetailViewProps } from "./ScFacilityDetailView";
import { yupResolver } from "@hookform/resolvers/yup";
import { ScFacilityValidationSchema } from "./scFacilityValidationSchema";
import useIsAuthorised from "../../../../../../../auth/useIsAuthorised";
import useResolvedRoute from "../../../../../../../components/routing/useResolvedRoute";
import { ScFacilityDetails } from "./scFacilityTypes";
import useDomainEntityDetailsEdit from "../../../../../../../hooks/details/useDomainEntityDetailsEdit";
import { defaultScFacilitySortOption } from "./useScFacilitySubList";
import { scopeCertificateReadOnlyByStatus } from "../../../../../../scopeCertificateTypes";
import { scopeCertificateStatusType } from "../../../../../../../auth/roleTypes";

export default function useScFacilityEdit(): ScFacilityDetailViewProps {
    const { hasAccessRight } = useIsAuthorised();

    const { getResolvedId } = useResolvedRoute();
    const scopeCertificateId = getResolvedId("scopeCertificate")!;
    const scProductId = getResolvedId("scProduct");
    const scProcessCategoryId = getResolvedId("scProcessCategory");
    const scFacilityId = getResolvedId("scFacility");

    const canSave = useCallback(
        (item: ScFacilityDetails) => {
            if (item) {
                return (
                    hasAccessRight("updateScopeCertificate", [item.scopeCertificateStatus]) &&
                    !scopeCertificateReadOnlyByStatus(item.scopeCertificateStatus)
                );
            }
            return false;
        },
        [hasAccessRight]
    );

    const { detailViewProps, form, isReadOnly, title, recordNavigationProps, initialData } =
        useDomainEntityDetailsEdit<ScFacilityDetails>({
            baseUrl: `/certificate-service/scope-certificates/${scopeCertificateId}/sc-products/${scProductId}/sc-process-categories/${scProcessCategoryId}/sc-facilities/${scFacilityId}`,
            frontendRoute: "scFacility",
            recordNavigationDefaultSort: defaultScFacilitySortOption,
            titleBuilder: (item) => item.facilityBusinessName,
            resolver: yupResolver(ScFacilityValidationSchema),
            canSave,
            historyColumns: [{ identifier: "facility", title: "scFacility.history.facility_column" }],
        });

    const scopeCertificateStatus = useMemo<scopeCertificateStatusType | undefined>(
        () => initialData?.scopeCertificateStatus,
        [initialData?.scopeCertificateStatus]
    );

    const isReadOnlyByScopeCertificateStatus = useMemo<boolean>(
        () => scopeCertificateReadOnlyByStatus(scopeCertificateStatus),
        [scopeCertificateStatus]
    );

    return {
        detailViewProps,
        form,
        scopeCertificateId,
        readOnly: isReadOnly || isReadOnlyByScopeCertificateStatus,
        title,
        recordNavigationProps,
        createRoute:
            scopeCertificateStatus && hasAccessRight("updateScopeCertificate", [scopeCertificateStatus])
                ? "scFacility"
                : undefined,
    };
}
