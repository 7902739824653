import { ProductConversionDetails } from "../productConversionTypes";
import { UseGtbFormReturn } from "../../hooks/formHandling/useGtbForm";
import ProcessCategoryPickList from "../../components/Input/pickList/typedPicklist/ProcessCategoryPickList";
import ProductCategoryPickList from "../../components/Input/pickList/typedPicklist/ProductCategoryPickList";
import ProductDetailPickList from "../../components/Input/pickList/typedPicklist/ProductDetailPickList";
import RawMaterialPickList from "../../components/Input/pickList/typedPicklist/RawMaterialPickList";
import GtbNumberField from "../../components/Input/GtbNumberField";
import { joinClassNames } from "../../utils/StringUtils";

import "./productConversionBasicData.css";

export default function ProductConversionBasicData({ form, readOnly }: ProductConversionBasicDataProps) {
    return (
        <div className={joinClassNames("formGrid", "productConversionBasicData")}>
            <ProcessCategoryPickList
                className="processCategory"
                name="processCategory"
                control={form.form.control}
                readOnly={readOnly}
                label="productConversions.detail.processCategory_input"
            />
            <RawMaterialPickList
                className="rawMaterial"
                name="rawMaterial"
                control={form.form.control}
                readOnly={readOnly}
                label="productConversions.detail.rawMaterial_input"
            />
            <ProductCategoryPickList
                className="inputProductCategory"
                name="inputProductCategory"
                label="productConversions.detail.inputProductCategory_input"
                control={form.form.control}
                readOnly={readOnly}
            />
            <ProductDetailPickList
                className="inputProductDetail"
                name="inputProductDetail"
                label="productConversions.detail.inputProductDetail_input"
                control={form.form.control}
                readOnly={readOnly}
            />
            <ProductCategoryPickList
                className="outputProductCategory"
                name="outputProductCategory"
                label="productConversions.detail.outputProductCategory_input"
                control={form.form.control}
                readOnly={readOnly}
            />
            <ProductDetailPickList
                className="outputProductDetail"
                name="outputProductDetail"
                label="productConversions.detail.outputProductDetail_input"
                control={form.form.control}
                readOnly={readOnly}
            />
            <GtbNumberField
                {...form.registerNumberWithErrors("minConversion")}
                className="minConversion"
                label="productConversions.detail.minimumConversionRate_input"
                unit="%"
                readOnly={readOnly}
            />
            <GtbNumberField
                {...form.registerNumberWithErrors("maxConversion")}
                className="maxConversion"
                label="productConversions.detail.maximumConversionRate_input"
                unit="%"
                readOnly={readOnly}
            />
        </div>
    );
}

export interface ProductConversionBasicDataProps {
    form: UseGtbFormReturn<ProductConversionDetails>;
    readOnly: boolean;
}
