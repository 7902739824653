import useIsAuthorised from "../../../auth/useIsAuthorised";
import DropdownLinkItem from "../../../components/dropdown/DropdownLinkItem";
import { getBaseUrl } from "../../../components/routing/useResolvedRoute";
import MainMenuItem from "../MainMenuItem";

export default function ScopeCertificateMenu() {
    const { hasAccessRight } = useIsAuthorised();

    return (
        <MainMenuItem name="mainLayout.mainMenu.scopeCertificates_menuTitle">
            {hasAccessRight("readScopeCertificate") && (
                <>
                    <DropdownLinkItem
                        to={getBaseUrl("scopeCertificate")}
                        label={"mainLayout.mainMenu.scopeCertificates_menuEntries.all_button"}
                    />
                    {hasAccessRight("readScopeCertificate", ["DRAFT", "REJECTED"]) && (
                        <DropdownLinkItem
                            to={getBaseUrl("searchInPreparationScopeCertificate")}
                            label={"mainLayout.mainMenu.scopeCertificates_menuEntries.inPreparation_button"}
                        />
                    )}
                    {hasAccessRight("readScopeCertificate", ["AWAITING_APPROVAL"]) && (
                        <DropdownLinkItem
                            to={getBaseUrl("searchAwaitingApprovalScopeCertificate")}
                            label={"mainLayout.mainMenu.scopeCertificates_menuEntries.awaitingApproval_button"}
                        />
                    )}
                    {hasAccessRight("readScopeCertificate", ["VALID", "IN_RECERTIFICATION"]) && (
                        <DropdownLinkItem
                            to={getBaseUrl("searchActiveScopeCertificate")}
                            label={"mainLayout.mainMenu.scopeCertificates_menuEntries.active_button"}
                        />
                    )}
                    {hasAccessRight("readScopeCertificate", ["PENDING"]) && (
                        <DropdownLinkItem
                            to={getBaseUrl("searchPendingScopeCertificate")}
                            label={"mainLayout.mainMenu.scopeCertificates_menuEntries.pending_button"}
                        />
                    )}
                    {hasAccessRight("readScopeCertificate", ["EXPIRED"]) && (
                        <DropdownLinkItem
                            to={getBaseUrl("searchExpiredScopeCertificate")}
                            label={"mainLayout.mainMenu.scopeCertificates_menuEntries.expired_button"}
                        />
                    )}
                    {hasAccessRight("readScopeCertificate", ["OBSOLETE"]) && (
                        <DropdownLinkItem
                            to={getBaseUrl("searchObsoleteScopeCertificate")}
                            label={"mainLayout.mainMenu.scopeCertificates_menuEntries.obsolete_button"}
                        />
                    )}
                    {hasAccessRight("readScopeCertificate", ["SUSPENDED"]) && (
                        <DropdownLinkItem
                            to={getBaseUrl("searchSuspendedScopeCertificate")}
                            label={"mainLayout.mainMenu.scopeCertificates_menuEntries.suspended_button"}
                        />
                    )}
                    {hasAccessRight("readScopeCertificate", ["WITHDRAWN"]) && (
                        <DropdownLinkItem
                            to={getBaseUrl("searchWithdrawnScopeCertificate")}
                            label={"mainLayout.mainMenu.scopeCertificates_menuEntries.withdrawn_button"}
                        />
                    )}
                </>
            )}
        </MainMenuItem>
    );
}
