import { buildSchema, validateDate, validateString, validateTranslation } from "../../utils/yupExtension";
import { CountryAreaDetails } from "../countryAreaTypes";

export const countryAreaValidationSchema = buildSchema<CountryAreaDetails>({
    name: validateTranslation(50),
    isoCode: validateString().isRequired().hasMaxLength(2).hasMinLength(2),
    callingCode: validateString().hasMaxLength(5),
    registrationIdType: validateString().hasMaxLength(30),
    registrationIdTypeOmp: validateString().hasMaxLength(30),
    startDate: validateDate(),
    endDate: validateDate(),
    addressPattern: validateString().hasMaxLength(150),
    welcomeMessage: validateString().hasMaxLength(4000),
});
