import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en/i18n_en.json";
import Backend from "i18next-http-backend";

export const defaultNS = "en";
export const resources = {
    en: {
        en,
    },
} as const;

i18n.use(initReactI18next)
    .use(Backend)
    .init({
        debug: false,
        partialBundledLanguages: true,
        fallbackLng: "en",
        lng: "en",
        ns: ["en"],
        defaultNS: "en",
        resources,
        backend: {
            loadPath: "/locales/{{lng}}/i18n_{{lng}}.json",
        },
        interpolation: {
            skipOnVariables: false,
        },
        returnedObjectHandler: function (key) {
            return key;
        },
    })
    .then();

export default i18n;
