import useIsAuthorised from "../auth/useIsAuthorised";
import { SortOption } from "../components/grid/component/useQueryBuilder";
import { useMemo } from "react";
import { GridColumn } from "../components/grid/component/Grid";
import useListView from "../hooks/useListView";
import { useDeleteAction, useShowDetailsAction } from "../utils/defaultActions";
import { AccreditationBodyDetails, AccreditationBodySummary } from "./accreditationBodyTypes";
import {
    buildDataColumn,
    buildDateColumn,
    buildNumberColumn,
    buildTextColumn,
} from "../components/grid/ColumnProvider";
import { EntityListViewWithoutInfinityScrollGridOptions } from "../components/entity/EntityListViewExcludingInfinityScroll";
import useResolvedRoute, { getCreateUrl } from "../components/routing/useResolvedRoute";
import { EnumEntityType } from "../components/Input/pickList/typedEnumPicklist/enumEntityType";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";

export const defaultAccreditationBodySortOption: SortOption<AccreditationBodySummary | AccreditationBodyDetails> = {
    field: "systemId",
    direction: "ASC",
};

const columns: GridColumn<AccreditationBodySummary>[] = [
    buildTextColumn({ key: "systemId", title: "accreditationBody.list.systemId_column", minWidth: 134 }),
    buildTextColumn({ key: "businessName", title: "accreditationBody.list.businessName_column", minWidth: 174 }),
    buildTextColumn({
        key: "stateProvinceCountryAreaName",
        title: "accreditationBody.list.countryArea_column",
        minWidth: 155,
    }),
    buildDataColumn<AccreditationBodySummary, EnumEntityType>({
        key: "organisationStatus",
        title: "accreditationBody.list.status_column",
        minWidth: 190,
        url: "/certificate-service/references/organisation-statuses",
        itemId: "id",
        itemLabel: "name",
    }),
    buildTextColumn({
        key: "addressDetails1",
        title: "accreditationBody.list.addressDetail1_column",
        minWidth: 180,
        visible: false,
    }),
    buildTextColumn({
        key: "addressDetails2",
        title: "accreditationBody.list.addressDetail2_column",
        minWidth: 180,
        visible: false,
    }),
    buildTextColumn({
        key: "addressDetails3",
        title: "accreditationBody.list.addressDetail3_column",
        minWidth: 180,
        visible: false,
    }),
    buildDateColumn({ key: "endDate", title: "accreditationBody.list.endDate_column", minWidth: 124, visible: false }),
    buildTextColumn({
        key: "legalName",
        title: "accreditationBody.list.legalName_column",
        minWidth: 145,
        visible: false,
    }),
    buildTextColumn({
        key: "organisationalEmail",
        title: "accreditationBody.list.organisationalEmailAddress_column",
        minWidth: 280,
        visible: false,
    }),
    buildTextColumn({
        key: "organisationalPhoneNumber",
        title: "accreditationBody.list.organisationalPhoneNumber_column",
        minWidth: 284,
        visible: false,
    }),
    buildTextColumn({
        key: "postCode",
        title: "accreditationBody.list.postcode_column",
        minWidth: 128,
        visible: false,
    }),
    buildDateColumn({
        key: "startDate",
        title: "accreditationBody.list.startDate_column",
        minWidth: 130,
        visible: false,
    }),
    buildTextColumn({ key: "town", title: "accreditationBody.list.town_column", minWidth: 123, visible: false }),
    buildTextColumn({ key: "website", title: "accreditationBody.list.website_column", minWidth: 123, visible: false }),
    buildNumberColumn({
        key: "latitude",
        title: "accreditationBody.list.latitude_column",
        allowDecimals: true,
        allowNegative: true,
        minWidth: 119,
        visible: false,
    }),
    buildNumberColumn({
        key: "longitude",
        title: "accreditationBody.list.longitude_column",
        allowDecimals: true,
        allowNegative: true,
        minWidth: 134,
        visible: false,
    }),
];

function useAccreditationBodyListView() {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedEditUrl } = useResolvedRoute();

    const {
        queryBuilder: { forceReload, columnOptions, query, queryCommands },
    } = useListView<AccreditationBodySummary>({
        navigationContextLabel: "accreditationBody.breadcrumb",
        baseQuery: "/certificate-service/accreditation-bodies",
        defaultSortOption: defaultAccreditationBodySortOption,
    });

    const showDetailsAction = useShowDetailsAction(
        (item: AccreditationBodySummary) => getResolvedEditUrl("accreditationBody", item.id),
        query.parameter
    );

    const deleteAction = useDeleteAction(
        (item: AccreditationBodySummary) => `/certificate-service/accreditation-bodies/${item.id}`,
        () => forceReload(),
        "deleteCertificationLevelOrganisation"
    );

    const actions = useGridRowContextMenuActions(showDetailsAction, deleteAction);

    return useMemo<EntityListViewWithoutInfinityScrollGridOptions<AccreditationBodySummary>>(() => {
        return {
            query,
            columns: columns,
            uniqueDataKey: "id" as keyof AccreditationBodySummary,
            onItemDoubleClick: showDetailsAction,
            createUrl: hasAccessRight("createCertificationLevelOrganisation")
                ? getCreateUrl("accreditationBody")
                : undefined,
            tableName: "accreditationBody",
            columnOptions,
            itemActions: actions,
            queryCommands,
            bulkDeleteAccessRight: () => hasAccessRight("deleteCertificationLevelOrganisation"),
        };
    }, [query, showDetailsAction, hasAccessRight, columnOptions, actions, queryCommands]);
}

export default useAccreditationBodyListView;
