import useLabelGradeCreate from "./useLabelGradeCreate";
import LabelGradeDetailView from "./LabelGradeDetailView";

function LabelGradeCreateView() {
    const labelGradeDetailsProperties = useLabelGradeCreate();

    return <LabelGradeDetailView {...labelGradeDetailsProperties} />;
}

export default LabelGradeCreateView;
