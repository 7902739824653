import "./inputWrapper.css";
import { ReactNode } from "react";
import { joinClassNames } from "../../../utils/StringUtils";
import useGtbTranslation, { I18nKey } from "../../../i18n/useGtbTranslation";
import HelperText, { HelperTextProps } from "../HelperText";

function InputWrapper({
    label,
    labelFor,
    hasValue,
    className,
    children,
    helperText,
    error,
    readOnly,
    ignoreFocus = false,
}: InputWrapperProps) {
    const translation = useGtbTranslation();

    return (
        <div
            className={joinClassNames(
                "inputWrapper",
                className,
                error ? "inputError" : null,
                readOnly ? "inputWrapperReadOnly" : null
            )}
        >
            <div
                className={joinClassNames(
                    "inputComponentWrapper",
                    readOnly ? "labelShrink" : null,
                    hasValue ? "hasValue" : null,
                    ignoreFocus ? "ignoreFocus" : null
                )}
            >
                {children}
                <fieldset className="inputComponentFrame" aria-hidden={true}>
                    <legend>
                        <span>{translation(label)}</span>
                    </legend>
                </fieldset>
            </div>
            <label htmlFor={labelFor} className="inputWrapperLabel">
                {translation(label)}
            </label>
            <HelperText helperText={helperText} />
        </div>
    );
}

export default InputWrapper;

export interface InputWrapperProps {
    label: I18nKey;
    labelFor: string;
    hasValue: boolean;
    children: ReactNode;
    helperText?: HelperTextProps["helperText"];
    error?: boolean;
    readOnly?: boolean;
    className?: string;
    ignoreFocus?: boolean;
}
