import { useQuery } from "../../../../../../hooks/useAxios";
import { useEffect } from "react";
import { UseGtbFormReturn } from "../../../../../../hooks/formHandling/useGtbForm";
import { ScMaterialDetails } from "../scMaterialTypes";
import { RawMaterialReference } from "../../../../../../rawMaterial/rawMaterialTypes";

export default function useScMaterialMaterialPrintVisibility(
    form: UseGtbFormReturn<ScMaterialDetails>,
    setIsMaterialPrintVisible: (value: boolean) => void
) {
    const { isLoading: isMaterialLoading, runQuery: runMaterialQuery } = useQuery<RawMaterialReference>({
        url: ``,
        enabled: false,
    });

    const materialId = form.form.watch("material");

    useEffect(() => {
        if (materialId) {
            runMaterialQuery({ url: `/certificate-service/references/raw-materials/${materialId}` }).then(
                (material: RawMaterialReference) => {
                    if (material?.printNameMandatory) {
                        setIsMaterialPrintVisible(true);
                    } else {
                        setIsMaterialPrintVisible(false);
                        form.form.setValue("materialPrint", null);
                    }
                }
            );
        } else {
            setIsMaterialPrintVisible(false);
            form.form.setValue("materialPrint", null);
        }
    }, [form.form, materialId, runMaterialQuery, setIsMaterialPrintVisible]);

    return isMaterialLoading;
}
