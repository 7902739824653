import { Control, Controller } from "react-hook-form";
import GtbTimePicker, { GtbTimePickerProps } from "./GtbTimePicker";
import useGtbTranslation, { I18nKey } from "../../../i18n/useGtbTranslation";

interface ControlledTimePickerProps extends Omit<GtbTimePickerProps, "value" | "onChange"> {
    control: Control<any>;
    name: string;
}

export default function ControlledTimePicker({ control, name, ...rest }: ControlledTimePickerProps) {
    const translation = useGtbTranslation();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <GtbTimePicker
                    value={value}
                    onChange={(newValue) => {
                        onChange({
                            target: {
                                name,
                                value: newValue,
                            },
                        });
                    }}
                    error={!!error}
                    helperText={error?.message && translation(error?.message as I18nKey)}
                    {...rest}
                />
            )}
        />
    );
}
