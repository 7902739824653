import { joinClassNames } from "../../utils/StringUtils";
import GtbTextField from "../../components/Input/GtbTextField";
import { LabeledCheckbox } from "../../components/Input/Checkbox";

import GtbNumberField from "../../components/Input/GtbNumberField";
import "./eventMonitorData.css";
import { EventMonitorDetailViewTabProps } from "./EventMonitorDetailView";
import GtbTextArea from "../../components/Input/GtbTextArea";
import ControlledMultiValueTextField from "../../components/Input/multiValueTextField/ControlledMultiValueTextField";
import StandardBodyPickList from "../../components/Input/pickList/typedPicklist/StandardBodyPickList";
import { useEffect } from "react";
import yup from "../../utils/yupExtension";
import useGtbTranslation from "../../i18n/useGtbTranslation";
import RadiobuttonGroup, {
    buildRadiobuttonOptionsFromObject,
} from "../../components/Input/radiobutton/RadiobuttonGroup";
import { notifyStandardBodyAdminsOptions } from "../eventMonitorTypes";

function EventMonitorData({ form }: EventMonitorDetailViewTabProps) {
    const translation = useGtbTranslation();
    const notifyStandardBodyAdminsOption = form.form.watch("notifyStandardBodyAdminsOptionsEnum");

    useEffect(() => {
        if (notifyStandardBodyAdminsOption !== "SPECIFIC") {
            form.form.setValue("notifySpecificStandardBodyAdmin", null);
        }
    }, [notifyStandardBodyAdminsOption, form.form]);

    return (
        <div className={joinClassNames("eventMonitorData", "formGrid")}>
            <GtbTextField
                {...form.registerWithErrors("eventType")}
                label="eventMonitor.detail.eventType_input"
                className="eventType"
                readOnly={true}
            />
            <GtbTextField
                {...form.registerWithErrors("name")}
                label="eventMonitor.detail.name_input"
                className="name"
            />
            <GtbTextArea
                minRows={3}
                maxRows={3}
                {...form.registerWithErrors("description")}
                label="eventMonitor.detail.description_input"
                className="description"
            />
            <GtbNumberField
                {...form.registerNumberWithErrors("signallingThreshold")}
                label="eventMonitor.detail.signallingThreshold_input"
                className="signallingThreshold"
            />
            <GtbNumberField
                {...form.registerNumberWithErrors("observationFrameInSeconds")}
                label="eventMonitor.detail.observationFrame_input"
                className="observationFrameInSeconds"
                unit={translation("eventMonitor.detail.seconds_unit")}
            />
            <LabeledCheckbox
                {...form.form.register("active")}
                label="eventMonitor.detail.active_input"
                id="active"
                className="active"
            />

            <GtbTextArea
                minRows={3}
                maxRows={3}
                {...form.registerWithErrors("additionalText")}
                label="eventMonitor.detail.additionalNotificationText_input"
                className="additionalText"
            />

            <LabeledCheckbox
                {...form.form.register("notifySystemAdmins")}
                label="eventMonitor.detail.notifySystemAdmins_input"
                id="notifySystemAdmins"
                className="notifySystemAdmins"
            />
            <RadiobuttonGroup
                label="eventMonitor.detail.notifyStandardBodyAdmins_input"
                {...buildRadiobuttonOptionsFromObject(notifyStandardBodyAdminsOptions)}
                {...form.form.register("notifyStandardBodyAdminsOptionsEnum")}
                className="notifyStandardBodyAdmins"
            />
            {form.form.watch("notifyStandardBodyAdminsOptionsEnum") === "SPECIFIC" && (
                <StandardBodyPickList
                    control={form.form.control}
                    name="notifySpecificStandardBodyAdmin"
                    className="standardBody"
                    label="eventMonitor.detail.notifyStandardBodyAdmins_input"
                />
            )}

            <ControlledMultiValueTextField
                control={form.form.control}
                name="additionalRecipients"
                label="eventMonitor.detail.notifyAdditionalAddressees_input"
                className="additionalRecipients"
                validator={(item) =>
                    yup.string().email().isValidSync(item)
                        ? null
                        : translation("eventMonitor.detail.noValidEmailAddress_message")
                }
            />
        </div>
    );
}

export default EventMonitorData;
