import { StateProvinceDetails } from "../stateProvinceTypes";
import useDetailsCreate from "../../hooks/details/useDetailsCreate";
import { yupResolver } from "@hookform/resolvers/yup";
import { stateProvinceValidationSchema } from "./stateProvinceValidationSchema";
import { useQuery } from "../../hooks/useAxios";
import { CountryAreaDetails } from "../../countryArea/countryAreaTypes";
import { Nullable } from "../../utils/typeUtils";
import { StateProvinceDetailViewProps } from "./StateProvinceDetailView";
import useResolvedRoute from "../../components/routing/useResolvedRoute";
import { getInitialTranslatableValue } from "../../i18n/languageTypes";

const initialData: Nullable<StateProvinceDetails> = {
    id: null,
    name: getInitialTranslatableValue(),
    isoCode: null,
    latitude: null,
    longitude: null,
    countryArea: null,
    startDate: null,
    endDate: null,
    extendedDescription: null,
};

function useStateProvinceCreate(): StateProvinceDetailViewProps {
    const { getResolvedId } = useResolvedRoute();
    const countryAreaId = getResolvedId("countryArea");

    const { data: countryArea } = useQuery<CountryAreaDetails>({
        url: "/certificate-service/references/countries/" + countryAreaId,
    });

    const { detailViewProps, form, title } = useDetailsCreate<StateProvinceDetails>({
        title: "stateProvince.detail.newRecord_title",
        frontendRoute: "stateProvince",
        initialData,
        resolver: yupResolver(stateProvinceValidationSchema),
        saveUrl: "/certificate-service/countries/" + countryAreaId + "/states-provinces",
    });

    return {
        detailViewProps,
        form,
        title,
        countryAreaName: `${countryArea?.name}`,
        isReadOnly: false,
        createRoute: "stateProvince",
    };
}

export default useStateProvinceCreate;
