import { Navigate } from "react-router-dom";
import useRefreshNavigate from "./routing/useRefreshNavigate";
import { getBaseUrl } from "./routing/useResolvedRoute";

/* This view is required for navigation when the current view and the target view are the same.
This is actually not possible with the react navigation used, but is required for the new record-button in the create view, for example. */
export default function RefreshView() {
    const { refreshUrl, state } = useRefreshNavigate();

    if (refreshUrl) {
        return <Navigate to={refreshUrl} replace={true} state={state} />;
    }
    return <Navigate to={getBaseUrl("homepage")} replace={true} />;
}
