import { ChangeEventHandler, forwardRef } from "react";
import { joinClassNames } from "../../utils/StringUtils";
import "./checkbox.css";
import useGtbTranslation, { I18nKey } from "../../i18n/useGtbTranslation";
import useGtbTooltip from "../tooltip/useGtbTooltip";

const Checkbox = forwardRef<HTMLInputElement, GtbCheckboxProps>(
    ({ indeterminate = false, readOnly, className, ...rest }: GtbCheckboxProps, ref) => {
        return (
            <fieldset disabled={readOnly} className={joinClassNames(className, "checkbox-container")}>
                <input type={"checkbox"} ref={ref} readOnly={readOnly} {...rest} />
                <svg
                    aria-hidden="true"
                    className={joinClassNames("checkbox-styled", indeterminate ? "checkbox-indeterminate" : undefined)}
                    viewBox="0 0 24 24"
                >
                    {indeterminate ? <polyline points="4 12 20 12" /> : <polyline points="20 6 9 17 4 12" />}
                </svg>
            </fieldset>
        );
    }
);

export const LabeledCheckbox = forwardRef<HTMLInputElement, LabeledCheckboxProps>(
    ({ id, label, className, ...rest }: LabeledCheckboxProps, ref) => {
        const translation = useGtbTranslation();
        const { tooltipProps, tooltip } = useGtbTooltip(label);

        return (
            <>
                <label {...tooltipProps} htmlFor={id} className={joinClassNames("checkbox-label-container", className)}>
                    <Checkbox id={id} ref={ref} {...rest} />
                    {label && translation(label)}
                </label>
                {tooltip}
            </>
        );
    }
);

export const AccessibleCheckbox = forwardRef<HTMLInputElement, AccessibleCheckboxProps>(
    ({ label, ...rest }: AccessibleCheckboxProps, ref) => {
        const translation = useGtbTranslation();
        const { tooltipProps, tooltip } = useGtbTooltip(label);

        return (
            <>
                <Checkbox {...tooltipProps} aria-label={label && translation(label)} ref={ref} {...rest} />
                {tooltip}
            </>
        );
    }
);

interface GtbCheckboxProps {
    id?: string;
    checked?: boolean;
    indeterminate?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    className?: string;
}

interface LabeledCheckboxProps extends GtbCheckboxProps {
    id: string;
    label?: I18nKey;
}

interface AccessibleCheckboxProps extends GtbCheckboxProps {
    label?: I18nKey;
}
