import { useQuery } from "../../hooks/useAxios";
import LoadingSpinner from "../LoadingSpinner";
import MarkdownContainer from "../../help/markdown/MarkdownContainer";
import React, { useEffect, useMemo } from "react";
import "./staticPageView.css";
import Footer from "../../mainLayout/footer/Footer";
import Header from "../../mainLayout/header/Header";
import { error } from "../../utils/notification/notification";
import useLanguage from "../../i18n/LanguageContextProvider";
import { env } from "../../env";

export default function StaticPageView(props: StaticPageViewProps) {
    const currentLanguage = useLanguage();

    const queryUrl = useMemo(
        () => "" + targets[props.targetPage] + "?lang=" + currentLanguage,
        [currentLanguage, props.targetPage]
    );
    const { data, isLoading, runQuery } = useQuery<string>({ url: queryUrl, enabled: false });

    useEffect(() => {
        runQuery().catch(() => error("misc.generic_error"));
    }, [currentLanguage, runQuery]);

    const environmentName = env.REACT_APP_ENVIRONMENT_NAME;

    return (
        <div className="static-page-view-layout">
            <Header>{environmentName && <span className={"environment-name"}>{environmentName}</span>}</Header>
            <div className="static-page-view-content">
                {isLoading && <LoadingSpinner />}
                {!isLoading && data && <MarkdownContainer content={data} />}
            </div>
            <Footer />
        </div>
    );
}

export interface StaticPageViewProps {
    targetPage: "imprint" | "privacyPolicy" | "termsOfUse" | "howToApply";
}

const targets: { imprint: string; privacyPolicy: string; termsOfUse: string; howToApply: string } = {
    imprint: "/certificate-service/imprint",
    privacyPolicy: "/certificate-service/privacy-policy",
    termsOfUse: "/certificate-service/terms-of-use",
    howToApply: "/certificate-service/how-to-apply",
};
