import useScProcessCategorySubList from "./useScProcessCategorySubList";
import InfinityScrollSublistTab from "../../../../../components/tabs/InfinityScrollSublistTab";
import { scopeCertificateStatusType } from "../../../../../auth/roleTypes";

export default function ScProcessCategories({
    scopeCertificateStatus,
}: {
    scopeCertificateStatus?: scopeCertificateStatusType;
}) {
    const scProcessCategorySubList = useScProcessCategorySubList(scopeCertificateStatus);
    return <InfinityScrollSublistTab {...scProcessCategorySubList} />;
}
