import useWarningDialog from "../../components/dialog/UseWarningDialog";
import useGtbTranslation, { I18nKey } from "../../i18n/useGtbTranslation";
import GtbButton from "../../components/GtbButton";

export default function useDuplicateWarningDialogue() {
    const { showDialog, closeDialog } = useWarningDialog();
    const translation = useGtbTranslation();
    const message = translation({
        key: "components.detailView.conformanceCheckDialog.warning_message",
        options: {
            message: translation(
                "components.detailView.conformanceCheckDialog.messages.organicMaterialProductionSc_possibleDuplicate_message"
            ),
        },
    });

    return () =>
        showDialog({
            title: "components.detailView.conformanceCheckDialog.dialog_title",
            message: message as I18nKey,
            footer: <GtbButton onClick={closeDialog}>{translation("components.dialog.ok_button")}</GtbButton>,
        });
}
