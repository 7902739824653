import * as React from "react";
import "./footer.css";
import ZendeskWidgetButton from "./ZendeskWidgetButton";
import useGtbTranslation, { I18nKey } from "../../i18n/useGtbTranslation";
import useAuth from "../../auth/useAuth";
import LanguageEnumPickList from "../../components/Input/pickList/typedEnumPicklist/LanguageEnumPicklist";
import AccessibleLabel from "../../components/AccessibleLabel";

export default function Footer() {
    const { currentUser } = useAuth();
    const translation = useGtbTranslation();

    return (
        <footer>
            <ul>
                <li className="language-pick-list-container">
                    <AccessibleLabel
                        label={"translation.footer.language_input_label"}
                        id={"language-pick-list-label"}
                    />
                    <LanguageEnumPickList
                        className="language-pick-list"
                        label={"" as I18nKey}
                        aria-labelledby="language-pick-list-label"
                        borderless
                    />
                </li>
                <FooterLink name={"mainLayout.footer.imprint_button"} href={"/imprint"} />
                <FooterLink name={"mainLayout.footer.privacyPolicy_button"} href={"/privacy-policy"} />
                <FooterLink name={"mainLayout.footer.termsOfUse_button"} href={"/terms-of-use"} />
                {currentUser && (
                    <ZendeskWidgetButton className="zendesk-button" name={"mainLayout.footer.reportAProblem_button"} />
                )}
            </ul>
            <p>
                <span>
                    {translation({
                        key: "mainLayout.footer.copyright",
                        options: { currentYear: new Date().getUTCFullYear() },
                    })}
                </span>
                <span className={"footerVersion"}>
                    {translation({
                        key: "mainLayout.footer.version",
                        options: {
                            version: process.env.REACT_APP_GTB_VERSION + "" + process.env.REACT_APP_PRE_RELEASE_INFO,
                        },
                    })}
                </span>
            </p>
        </footer>
    );
}

function FooterLink({ name, href }: { name: I18nKey; href: string }) {
    const translation = useGtbTranslation();

    return (
        <li>
            <a target={"_blank"} href={href} rel="noopener noreferrer">
                {translation(name)}
            </a>
        </li>
    );
}
