import { joinClassNames } from "../../utils/StringUtils";
import { SupplyChainOperatorDetailViewTabProps } from "./SupplyChainOperatorDetailView";
import GtbTextArea from "../../components/Input/GtbTextArea";
import "./supplyChainOperatorOtherData.css";
import EnumEntityRadiobuttonGroup from "../../components/Input/radiobutton/EnumEntityRadiobuttonGroup";
import GtbTextField from "../../components/Input/GtbTextField";
import InfinityScrollSubList from "../../components/grid/InfinityScrollSubList";
import useExternalSupplyChainOperatorIdSubList from "../../externalId/supplyChainOperator/useExternalSupplyChainOperatorIdSubList";
import { I18nKey } from "../../i18n/useGtbTranslation";

export default function SupplyChainOperatorOtherData({
    form,
    readOnlyProps,
    reducedAccess,
    externalIdComponentType,
}: SupplyChainOperatorDetailViewTabProps) {
    const externalSupplyChainOperatorIdsSubListOptions = useExternalSupplyChainOperatorIdSubList();

    return (
        <div className={joinClassNames("supplyChainOperatorOtherData", "formGrid")}>
            {!reducedAccess && (
                <>
                    <GtbTextArea
                        {...form.registerWithErrors("brandNames")}
                        readOnly={readOnlyProps.isBrandNamesReadOnly}
                        minRows={4}
                        maxRows={4}
                        label={"supplyChainOperator.detail.brandNames_input"}
                        className="brandNames"
                    />
                    <EnumEntityRadiobuttonGroup
                        label="supplyChainOperator.detail.membership_input"
                        readOnly={readOnlyProps.isMembershipReadOnly}
                        {...form.form.register("membership")}
                        name="membership"
                        className="membership"
                        referenceUrl="/certificate-service/references/memberships"
                    />
                </>
            )}
            {externalIdComponentType === "FIELD" && (
                <GtbTextField
                    {...form.registerWithErrors("cbInternalId")}
                    label={"supplyChainOperator.detail.cbInternalId_input" as I18nKey}
                    className="cbInternalId"
                    readOnly={readOnlyProps.isCbInternalIdReadOnly}
                />
            )}
            {externalIdComponentType === "SUBLIST" && (
                <InfinityScrollSubList {...externalSupplyChainOperatorIdsSubListOptions} className="externalIds" />
            )}
        </div>
    );
}
