import useDetailsCreate from "../../hooks/details/useDetailsCreate";
import { yupResolver } from "@hookform/resolvers/yup";
import { labelGradeValidationSchema } from "./labelGradeValidationSchema";
import { useQuery } from "../../hooks/useAxios";
import { Nullable } from "../../utils/typeUtils";
import { LabelGradeDetails } from "../labelGradeTypes";
import { LabelGradeDetailViewProps } from "./LabelGradeDetailView";
import { getStandardVersionName, VersionDetails } from "../../standard/version/versionTypes";
import useResolvedRoute from "../../components/routing/useResolvedRoute";

const initialData: Nullable<LabelGradeDetails> = {
    id: null,
    systemId: null,
    labelGrade: null,
    standardVersion: null,
    typeOfMaterial: null,
    minPercentage: null,
};

function useLabelGradeCreate(): LabelGradeDetailViewProps {
    const { getResolvedId } = useResolvedRoute();
    const standardId = getResolvedId("standard");
    const standardVersionId = getResolvedId("standardVersion");

    const { data: standardVersion } = useQuery<VersionDetails>({
        url:
            "/certificate-service/standards/" +
            standardId +
            "/standard-versions/" +
            standardVersionId +
            "?view=MINIMISED",
    });

    const { detailViewProps, form, title } = useDetailsCreate<LabelGradeDetails>({
        title: "labelGrade.detail.newRecord_title",
        frontendRoute: "labelGrade",
        initialData,
        resolver: yupResolver(labelGradeValidationSchema),
        saveUrl:
            "/certificate-service/standards/" +
            standardId +
            "/standard-versions/" +
            standardVersionId +
            "/label-grades",
    });

    return {
        detailViewProps,
        form,
        title,
        standardVersionName: getStandardVersionName(standardVersion),
        isReadOnly: false,
        createRoute: "labelGrade",
    };
}

export default useLabelGradeCreate;
