import "./noteList.css";
import GtbIconButton from "../../GtbIconButton";
import useAuth from "../../../auth/useAuth";
import { formatTimestamp } from "../../../utils/formatter";
import useConfirmDeletionDialog from "../../dialog/useConfirmDeletionDialog";
import * as React from "react";
import Icon from "../../Icon";
import GtbButton from "../../GtbButton";
import { joinClassNames } from "../../../utils/StringUtils";
import { useCreateNoteDialog, useEditNoteDialog } from "./useNoteDialog";
import useGtbTranslation from "../../../i18n/useGtbTranslation";
import { backendUrlType, useQuery } from "../../../hooks/useAxios";
import { Note } from "./noteTypes";
import LoadingSpinner from "../../LoadingSpinner";

interface NoteListProps {
    id?: string;
    noteRootUrl: backendUrlType;
    className?: string;
}

export default function NoteList({ id, noteRootUrl, className }: NoteListProps) {
    const { data: notes, runQuery: reloadNotes, isLoading } = useQuery<Note[]>({ url: noteRootUrl });
    const { currentUser } = useAuth();
    const {
        currentUser: { authorisationRole },
    } = useAuth();
    const translation = useGtbTranslation();
    const showDeleteDialog = useConfirmDeletionDialog({ omitSuccessMessage: true });
    const showCreateNoteDialog = useCreateNoteDialog(noteRootUrl, reloadNotes);
    const showEditNoteDialog = useEditNoteDialog(reloadNotes);

    return (
        <aside id={id} className={joinClassNames("noteList", className)}>
            <GtbButton
                className="addNote"
                onClick={() =>
                    showCreateNoteDialog(
                        Promise.resolve({
                            comment: null,
                            authorId: null,
                            authorFullName: null,
                            modifiedAt: null,
                            id: null,
                            createdAt: null,
                        })
                    )
                }
            >
                <Icon name="plus" size={12} />
                {translation("components.detailView.notes.addNote_button")}
            </GtbButton>
            {isLoading && <LoadingSpinner />}
            {!isLoading && (
                <ol aria-label={translation("components.detailView.notes.noteList_accessibleLabel")}>
                    {notes?.map((note) => (
                        <li key={note.id} className="note">
                            <span className="username">{note.authorFullName}</span>
                            <span className="timestamp">
                                {formatTimestamp(note.createdAt, "m")}
                                {note.modifiedAt !== null && (
                                    <>
                                        <Icon
                                            name="history"
                                            size={12}
                                            className="editedNoteIcon"
                                            accessibility={{
                                                id: note.id,
                                                label: {
                                                    key: "components.detailView.notes.editedNoteIcon_tooltip",
                                                    options: { timestamp: formatTimestamp(note.modifiedAt, "m") },
                                                },
                                            }}
                                        />
                                    </>
                                )}
                            </span>
                            {currentUser.id === note.authorId && (
                                <GtbIconButton
                                    className="editNote"
                                    label="components.detailView.notes.editNote_tooltip"
                                    iconName="pencil"
                                    size={16}
                                    onClick={() =>
                                        showEditNoteDialog(Promise.resolve(note), noteRootUrl + "/" + note.id)
                                    }
                                />
                            )}
                            {(currentUser.id === note.authorId || authorisationRole.level === 1) && (
                                <GtbIconButton
                                    className="deleteNote"
                                    label="components.detailView.notes.deleteNote_tooltip"
                                    iconName="trash"
                                    size={16}
                                    onClick={() =>
                                        showDeleteDialog(
                                            noteRootUrl + "/" + note.id,
                                            reloadNotes,
                                            "components.detailView.notes.dialog.deleteNote_dialogTitle",
                                            "components.detailView.notes.dialog.deleteNote_dialogText"
                                        )
                                    }
                                />
                            )}
                            <p className="noteText">{note.comment}</p>
                        </li>
                    ))}
                </ol>
            )}
        </aside>
    );
}
