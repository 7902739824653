import { joinClassNames } from "../../utils/StringUtils";
import "./organicMaterialProducerOtherData.css";
import { OrganicMaterialProducerDetailViewTabProps } from "./OrganicMaterialProducerDetailView";
import GtbNumberField from "../../components/Input/GtbNumberField";
import GtbTextField from "../../components/Input/GtbTextField";
import { I18nKey } from "../../i18n/useGtbTranslation";
import InfinityScrollSubList from "../../components/grid/InfinityScrollSubList";
import useExternalOrganicMaterialProducerIdSubList from "../../externalId/organicMaterialProducer/useExternalOrganicMaterialProducerIdSubList";
import { OrganicMaterialProducerDetails } from "../organicMaterialProducerTypes";

export default function OrganicMaterialProducerOtherData({
    form,
    readOnlyProps,
    externalIdComponentType,
}: OrganicMaterialProducerDetailViewTabProps<OrganicMaterialProducerDetails>) {
    const externalOrganicMaterialProducerIdsSubListOptions = useExternalOrganicMaterialProducerIdSubList();

    return (
        <div className={joinClassNames("organicMaterialProducerOtherData", "formGrid")}>
            <GtbNumberField
                {...form.registerNumberWithErrors("numberOfFarmers")}
                readOnly={readOnlyProps.isNumberOfFarmersReadOnly}
                label="organicMaterialProducer.detail.numberOfFarmers_input"
                className="numberOfFarmers"
            />
            {externalIdComponentType === "FIELD" && (
                <GtbTextField
                    {...form.registerWithErrors("cbInternalId")}
                    label={`organicMaterialProducer.detail.cbInternalId_input` as I18nKey}
                    className="cbInternalId"
                    readOnly={readOnlyProps.isCbInternalIdReadOnly}
                />
            )}
            {externalIdComponentType === "SUBLIST" && (
                <InfinityScrollSubList {...externalOrganicMaterialProducerIdsSubListOptions} className="externalIds" />
            )}
        </div>
    );
}
