import { TypedPicklist } from "./typedPicklistType";
import DataPickList from "../DataPickList";
import { StandardBodyReference } from "../../../../standardBody/standardBodyTypes";
import "./typedPickList.css";
import { joinClassNames } from "../../../../utils/StringUtils";
import { FieldValues } from "react-hook-form/dist/types";

interface StandardBodyPickListProps<FormItemType extends FieldValues>
    extends TypedPicklist<FormItemType, StandardBodyReference> {
    flags?: { fetch_all?: boolean };
}

export default function StandardBodyPickList<FormItemType extends FieldValues>({
    className,
    ...rest
}: StandardBodyPickListProps<FormItemType>) {
    return (
        <DataPickList
            className={joinClassNames("standardBodyPicklist", className)}
            itemId="id"
            selectedItemLabel="legalName"
            itemLabel={(option) =>
                `${option.legalName}\n${option.town}, ${option.stateProvinceCountryAreaName}, (${option.stateProvinceIsoCode})`
            }
            queryUrl="/certificate-service/references/standard-bodies"
            {...rest}
        />
    );
}
