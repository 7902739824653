import { TypedDetailViewProps } from "../../components/detailView/DetailView";
import { UseGtbFormReturn } from "../../hooks/formHandling/useGtbForm";
import LabelGradeBasicData from "./LabelGradeBasicData";
import { LabelGradeDetails } from "../labelGradeTypes";
import EntityDetailView from "../../components/entity/EntityDetailView";
import { useHelpContext } from "../../help/context-sensitivity/useHelpContext";

export default function LabelGradeDetailView({
    detailViewProps,
    form,
    isReadOnly,
    standardVersionName,
    subListVisible,
    ...rest
}: LabelGradeDetailViewProps) {
    useHelpContext("label-grade-details");
    return (
        <EntityDetailView detailViewProps={detailViewProps} form={form} {...rest}>
            <LabelGradeBasicData
                form={form}
                readOnly={isReadOnly}
                standardVersionName={standardVersionName}
                subListVisible={subListVisible}
            />
        </EntityDetailView>
    );
}

export interface LabelGradeDetailViewProps extends TypedDetailViewProps<LabelGradeDetails> {
    isReadOnly: boolean;
    standardVersionName: string;
    subListVisible?: boolean;
}

export interface LabelGradeDetailViewTabProps {
    form: UseGtbFormReturn<LabelGradeDetails>;
    readOnly: boolean;
    standardVersionName: string;
    subListVisible?: boolean;
}
