import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { productConversionValidationSchema } from "./productConversionValidationSchema";
import useIsAuthorised from "../../auth/useIsAuthorised";
import { defaultProductConversionSortOption } from "../useProductConversionListView";
import { ProductConversionDetails } from "../productConversionTypes";
import useResolvedRoute, { routeEnum } from "../../components/routing/useResolvedRoute";
import { useMemo } from "react";
import useDomainEntityDetailsEdit from "../../hooks/details/useDomainEntityDetailsEdit";

export default function useProductConversionEdit() {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedId } = useResolvedRoute();
    const productConversionId = getResolvedId("productConversion");

    const { detailViewProps, form, isReadOnly, title, recordNavigationProps } =
        useDomainEntityDetailsEdit<ProductConversionDetails>({
            baseUrl: "/certificate-service/product-conversions/" + productConversionId,
            frontendRoute: "productConversion",
            recordNavigationDefaultSort: defaultProductConversionSortOption,
            titleBuilder: productConversionTitleBuilder,
            resolver: yupResolver(productConversionValidationSchema),
            canSave: hasAccessRight("updateProductConversion"),
            historyColumns: [
                {
                    identifier: "processCategory",
                    title: "productConversions.history.processCategory_column",
                },
                { identifier: "rawMaterial", title: "productConversions.history.rawMaterial_column" },
                {
                    identifier: "inputProductCategory",
                    title: "productConversions.history.inputProductCategory_column",
                },
                {
                    identifier: "inputProductDetail",
                    title: "productConversions.history.inputProductDetail_column",
                },
                {
                    identifier: "outputProductCategory",
                    title: "productConversions.history.outputProductCategory_column",
                },
                {
                    identifier: "outputProductDetail",
                    title: "productConversions.history.outputProductDetail_column",
                },
                {
                    identifier: "minConversion",
                    title: "productConversions.history.minimumConversionRate_column",
                },
                {
                    identifier: "maxConversion",
                    title: "productConversions.history.maximumConversionRate_column",
                },
            ],
        });

    const createRoute = useMemo<routeEnum | undefined>(() => {
        return hasAccessRight("createProductConversion") ? "productConversion" : undefined;
    }, [hasAccessRight]);

    return {
        detailViewProps,
        form,
        isReadOnly,
        title,
        recordNavigationProps,
        createRoute,
    };
}

const productConversionTitleBuilder = (item: ProductConversionDetails): string => {
    const separator = " / ";
    return (
        item.processCategoryName +
        separator +
        item.rawMaterialName +
        separator +
        item.inputProductCategoryName +
        separator +
        item.inputProductDetailName +
        separator +
        item.outputProductCategoryName +
        separator +
        item.outputProductDetailName
    );
};
