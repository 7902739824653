import useDetailsCreate from "../../hooks/details/useDetailsCreate";
import { yupResolver } from "@hookform/resolvers/yup";
import { Nullable } from "../../utils/typeUtils";
import { ByproductInputCombinationDetails } from "../byproductInputCombinationTypes";
import { byproductInputCombinationValidationSchema } from "./byproductInputCombinationValidationSchema";
import { routeEnum } from "../../components/routing/useResolvedRoute";

const initialData: Nullable<ByproductInputCombinationDetails> = {
    id: null,
    processCategory: null,
    productDetail: null,
    processCategoryCode: null,
    processCategoryName: null,
    productDetailCode: null,
    productDetailName: null,
};

function useByproductInputCombinationCreate() {
    const { detailViewProps, form, title } = useDetailsCreate<ByproductInputCombinationDetails>({
        title: "byProductInputCombination.detail.newRecord_title",
        frontendRoute: "byproductInputCombination",
        initialData,
        resolver: yupResolver(byproductInputCombinationValidationSchema),
        saveUrl: "/certificate-service/by-product-input-combinations",
    });

    return {
        title,
        detailViewProps,
        form,
        navigationEnabled: false,
        isReadOnly: false,
        createRoute: "byproductInputCombination" as routeEnum,
    };
}

export default useByproductInputCombinationCreate;
