import useNavigatableDetailsEdit, { UseNavigatableDetailsEdit } from "./useNavigatableDetailsEdit";
import { FieldValues } from "react-hook-form/dist/types";
import { useMemo } from "react";
import { SimpleGridColumn } from "../../components/grid/component/SimpleGrid";

export type HistoryColumn<ItemType> = SimpleGridColumn & { identifier: keyof ItemType };

export default function useDomainEntityDetailsEdit<ItemType extends FieldValues>({
    baseUrl,
    historyColumns,
    ...rest
}: UseNavigatableDetailsEdit<ItemType> & {
    historyColumns: HistoryColumn<ItemType>[];
}) {
    const { detailViewProps, ...detailsEditParams } = useNavigatableDetailsEdit({ baseUrl, ...rest });

    return useMemo(() => {
        return {
            detailViewProps: {
                ...detailViewProps,
                noteRootUrl: baseUrl + "/notes",
                historyParams: { url: baseUrl + "/history", columns: historyColumns },
            },
            ...detailsEditParams,
        };
    }, [baseUrl, detailViewProps, detailsEditParams, historyColumns]);
}
