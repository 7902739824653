import { useHelpContext } from "../../help/context-sensitivity/useHelpContext";
import { TypedDetailViewProps } from "../../components/detailView/DetailView";
import EntityDetailView from "../../components/entity/EntityDetailView";
import { AccreditationRelationDetails } from "../accreditationRelationTypes";
import CertificationBodyPickList from "../../components/Input/pickList/typedPicklist/CertificationBodyPickList";
import { joinClassNames } from "../../utils/StringUtils";
import "./accreditationRelationData.css";
import AccreditationBodyPickList from "../../components/Input/pickList/typedPicklist/AccreditationBodyPickList";
import StandardVersionPickList from "../../components/Input/pickList/typedPicklist/StandardVersionPickList";
import GtbTextField from "../../components/Input/GtbTextField";
import AccreditedScopesPickList from "../../components/Input/pickList/typedPicklist/AccreditedScopesPickList";
import ControlledDatePicker from "../../components/Input/datepicker/ControlledDatePicker";
import CountryAreaPickList from "../../components/Input/pickList/typedPicklist/CountryAreaPickList";

export default function AccreditationRelationDetailView({
    detailViewProps,
    form,
    readOnlyProps: { certificationBodyReadOnly, readOnly },
    ...rest
}: AccreditationRelationDetailViewProps) {
    useHelpContext("accreditation-relation-details");
    return (
        <EntityDetailView {...rest} detailViewProps={detailViewProps} form={form}>
            <div className={joinClassNames("accreditationRelationData", "formGrid")}>
                <CertificationBodyPickList
                    control={form.form.control}
                    name="certificationBody"
                    label="accreditationRelation.detail.certificationBody_input"
                    className="certificationBody"
                    readOnly={certificationBodyReadOnly}
                />
                <AccreditationBodyPickList
                    control={form.form.control}
                    name="accreditationBody"
                    label="accreditationRelation.detail.accreditationBody_input"
                    className="accreditationBody"
                    readOnly={readOnly}
                />
                <StandardVersionPickList
                    control={form.form.control}
                    label="accreditationRelation.detail.standardVersion_input"
                    name="standardVersion"
                    className="standardVersion"
                    readOnly={readOnly}
                />
                <GtbTextField
                    {...form.registerWithErrors("accreditationNumber")}
                    label="accreditationRelation.detail.accreditationNumber_input"
                    className="accreditationNumber"
                    readOnly={readOnly}
                />
                <AccreditedScopesPickList
                    form={form.form}
                    name="accreditedScopes"
                    parentField="standardVersion"
                    label="accreditationRelation.detail.accreditedScopes_input"
                    className="accreditedScopes"
                    readOnly={readOnly}
                />
                <CountryAreaPickList
                    label="accreditationRelation.detail.geographicScopes_input"
                    control={form.form.control}
                    name="geographicScopes"
                    multiple
                    className="geographicScopes"
                    readOnly={readOnly}
                />
                <ControlledDatePicker
                    control={form.form.control}
                    name="startDate"
                    label="accreditationRelation.detail.startDate_input"
                    className="startDate"
                    readOnly={readOnly}
                />
                <ControlledDatePicker
                    control={form.form.control}
                    name="endDate"
                    label="accreditationRelation.detail.endDate_input"
                    className="endDate"
                    readOnly={readOnly}
                />
            </div>
        </EntityDetailView>
    );
}

export interface AccreditationRelationDetailViewProps extends TypedDetailViewProps<AccreditationRelationDetails> {
    readOnlyProps: AccreditationRelationReadOnlyProps;
}

export interface AccreditationRelationReadOnlyProps {
    readOnly: boolean;
    certificationBodyReadOnly: boolean;
}
