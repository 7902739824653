import { AccessibleCheckbox } from "../../../Input/Checkbox";
import { I18nKey } from "../../../../i18n/useGtbTranslation";

interface BooleanCellProps {
    title: I18nKey;
    value: boolean;
}

const BooleanCell: React.FC<BooleanCellProps> = ({ title, value }) => {
    return <AccessibleCheckbox label={title} disabled checked={value} />;
};

export default BooleanCell;
