import { UserDetailReadOnlyProps, UserDetailViewProps, UserDetailVisibilityProps } from "./UserDetailView";
import useDetailsEdit from "../../hooks/details/useDetailsEdit";
import { UserDetails } from "../userTypes";
import { yupResolver } from "@hookform/resolvers/yup";
import { userValidationSchema } from "./userValidationSchema";
import useUserTotp from "./useUserTotp";
import { useCallback } from "react";
import useGtbTranslation from "../../i18n/useGtbTranslation";
import useAuth from "../../auth/useAuth";

const readOnlyProps: UserDetailReadOnlyProps = {
    isBlockedReadOnly: true,
    isEmailAddressReadOnly: true,
    isEndDateReadOnly: true,
    isGivenNameReadOnly: true,
    isOrganisationReadOnly: true,
    isStandardBodyReadOnly: true,
    isRoleReadOnly: true,
    isStartDateReadOnly: true,
    isSurnameReadOnly: true,
    isTestDataStampReadOnly: true,
    isUsernameReadOnly: true,
};

function useUserProfile(): UserDetailViewProps {
    const { currentUser, reloadCurrentUser } = useAuth();
    const translation = useGtbTranslation();

    const { detailViewProps, form, initialData } = useDetailsEdit<UserDetails>({
        baseUrl: "/certificate-service/users/me",
        resolver: yupResolver(userValidationSchema),
        titleBuilder: () => translation("user.detail.editAccount_breadcrumb"),
        canSave: true,
        frontendRoute: "profile",
        afterSaveAction: () => reloadCurrentUser(),
    });

    const visibilityProps: UserDetailVisibilityProps = {
        isTestDataStampVisible: currentUser.authorisationRole.level === 1,
        isChangePasswordButtonVisible: true,
        isAcceptedToUVisible: currentUser.authorisationRole.level <= 2,
    };

    const { activateTotp, resetTotp, totpReady } = useUserTotp(initialData?.totpReady);

    const _activateTotp = useCallback(() => {
        activateTotp(initialData!);
    }, [activateTotp, initialData]);

    const _resetTotp = useCallback(() => {
        resetTotp(initialData!);
    }, [initialData, resetTotp]);

    return {
        detailViewProps,
        form,
        title: "user.detail.editAccount_title",
        readOnlyProps,
        visibilityProps,
        activateTotp: !totpReady ? _activateTotp : undefined,
        resetTotp: totpReady ? _resetTotp : undefined,
        totpReady,
    };
}

export default useUserProfile;
