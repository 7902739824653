import useRawMaterialCreate from "./useRawMaterialCreate";
import RawMaterialDetailView from "./RawMaterialDetailView";

function RawMaterialCreateView() {
    const rawMaterialDetailProps = useRawMaterialCreate();

    return <RawMaterialDetailView {...rawMaterialDetailProps} />;
}

export default RawMaterialCreateView;
