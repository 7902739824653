import { useHelpContext } from "../help/context-sensitivity/useHelpContext";
import EntityListView from "../components/entity/EntityListView";
import useSupplyingRelationListView from "./useSupplyingRelationListView";

export default function SupplyingRelationListView() {
    const supplyingRelationGridOptions = useSupplyingRelationListView();
    useHelpContext("supplying-relation-list");

    return <EntityListView heading="supplyingRelation.list.header" gridOptions={supplyingRelationGridOptions} />;
}
