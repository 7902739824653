import useResolvedRoute from "../../../../../components/routing/useResolvedRoute";
import { useQuery } from "../../../../../hooks/useAxios";
import { TcProductTrackingInformation } from "../../tcProductTypes";
import { useLocation } from "react-router-dom";
import { TrackingNode } from "./TcProductMapView";
import { ActiveMarker } from "../../../../../components/worldMap/SvgMarker";

export default function useTcProductMap() {
    const { getResolvedId } = useResolvedRoute();
    const transactionCertificateId = getResolvedId("transactionCertificate");
    const tcProductId = getResolvedId("tcProduct");
    const { state: mapState } = useLocation() as { state?: TrackingNode & ActiveMarker };

    const { data: rootProduct, isLoading } = useQuery<TcProductTrackingInformation>({
        url: `/certificate-service/transaction-certificates/${transactionCertificateId}/tc-products/${tcProductId}/tracking-information`,
    });

    return { rootProduct, isLoading, mapState };
}
