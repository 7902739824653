import * as React from "react";
import { useCallback, useEffect, useMemo } from "react";
import DialogHeader from "../../components/dialog/DialogHeader";
import DialogFooter from "../../components/dialog/DialogFooter";
import DialogContent from "../../components/dialog/DialogContent";
import useGtbTranslation from "../../i18n/useGtbTranslation";
import GtbButton from "../../components/GtbButton";
import { Dialog } from "../../components/dialog/useDialog";
import { useModal } from "../../components/modal/ModalProvider";
import useFormSubmit from "../formHandling/useFormSubmit";
import SupplyChainOperatorPickList from "../../components/Input/pickList/typedPicklist/SupplyChainOperatorPickList";
import useGtbForm from "../formHandling/useGtbForm";
import { useMutation } from "../useAxios";
import GtbTextArea from "../../components/Input/GtbTextArea";
import OrganicMaterialProducerPickList from "../../components/Input/pickList/typedPicklist/OrganicMaterialProducerPickList";
import Form from "../../components/Form";
import LoadingSpinner from "../../components/LoadingSpinner";
import useConfirmationDialog from "../../components/dialog/UseConfirmationDialog";
import useErrorDialog from "../../components/dialog/useErrorDialog";
import { joinClassNames } from "../../utils/StringUtils";
import "./useReactiveDeduplicationDialog.css";

export default function useReactiveDeduplicationDialog() {
    const { showModal: _showDialog, closeModal: closeDialog } = useModal();
    return useCallback(
        (
            initialSource: string,
            url: string,
            itemType: reactiveDeduplicationItemType,
            afterDeduplication: () => void
        ) => {
            _showDialog(
                <ReactiveDeduplicationDialog
                    url={url}
                    closeDialog={closeDialog}
                    afterDeduplication={afterDeduplication}
                    initialSource={initialSource}
                    itemType={itemType}
                />
            );
        },
        [_showDialog, closeDialog]
    );
}

export interface ReactiveDeduplicationDialogProps {
    closeDialog: Function;
    url: string;
    initialSource: string;
    itemType: reactiveDeduplicationItemType;
    afterDeduplication: () => void;
}

export interface ReactiveDeduplicationDetails {
    source: string;
    destination: string;
    comment: string;
}

function ReactiveDeduplicationDialog({
    url,
    closeDialog,
    initialSource,
    itemType,
    afterDeduplication,
}: ReactiveDeduplicationDialogProps) {
    const translation = useGtbTranslation();
    const showSuccessDialog = useConfirmationDialog();
    const { showDialog: showErrorDialog } = useErrorDialog();

    const onSuccess = useCallback(() => {
        afterDeduplication();
        showSuccessDialog({
            title: "components.detailView.reactiveDeduplicationDialog.dialogTitle",
            message: "components.detailView.reactiveDeduplicationDialog.success_message",
        });
    }, [afterDeduplication, showSuccessDialog]);
    const onError = useCallback(() => {
        showErrorDialog({
            title: "components.detailView.reactiveDeduplicationDialog.dialogTitle",
            message: "components.detailView.reactiveDeduplicationDialog.error_message",
        });
    }, [showErrorDialog]);
    const { isLoading, runQuery: save } = useMutation({ method: "post", url: url ?? "", onSuccess, onError });

    const form = useGtbForm<ReactiveDeduplicationDetails>();
    const formSubmit = useFormSubmit<ReactiveDeduplicationDetails>(form, save, {
        omitSuccessMessage: true,
        omitHandleBackendFormError: true,
    });

    const selectedSource = form.form.watch("source");
    const selectedDestination = form.form.watch("destination");
    const mergeButtonDisabled = useMemo<boolean>(
        () => !selectedSource || !selectedDestination || selectedSource === selectedDestination,
        [selectedDestination, selectedSource]
    );

    useEffect(() => form.form.setValue("source", initialSource), [form.form, initialSource]);

    return (
        <Form onSubmit={() => formSubmit()}>
            <Dialog
                dialog={{
                    header: (
                        <DialogHeader
                            title={"components.detailView.reactiveDeduplicationDialog.dialogTitle"}
                            variant="info"
                            onClick={() => closeDialog()}
                            closeButtonDisabled={isLoading}
                        />
                    ),
                    content: (
                        <>
                            {isLoading && (
                                <DialogContent>
                                    <LoadingSpinner />
                                </DialogContent>
                            )}

                            {!isLoading && (
                                <DialogContent className={joinClassNames("reactiveDeduplicationDialog", "formGrid")}>
                                    {itemType === "sco" && (
                                        <>
                                            <SupplyChainOperatorPickList
                                                label="components.detailView.reactiveDeduplicationDialog.source_input"
                                                control={form.form.control}
                                                name="source"
                                                className="source"
                                                itemLabel={(item) => `${item.legalName} (${item.systemId})`}
                                                flags={{ allActive: true }}
                                            />
                                            <SupplyChainOperatorPickList
                                                label="components.detailView.reactiveDeduplicationDialog.destination_input"
                                                control={form.form.control}
                                                name="destination"
                                                className="destination"
                                                itemLabel={(item) => `${item.legalName} (${item.systemId})`}
                                                flags={{ allActive: true }}
                                            />
                                        </>
                                    )}

                                    {itemType === "omp" && (
                                        <>
                                            <OrganicMaterialProducerPickList
                                                label="components.detailView.reactiveDeduplicationDialog.source_input"
                                                control={form.form.control}
                                                name="source"
                                                className="source"
                                                itemLabel={(item) => `${item.legalName} (${item.systemId})`}
                                            />
                                            <OrganicMaterialProducerPickList
                                                label="components.detailView.reactiveDeduplicationDialog.destination_input"
                                                control={form.form.control}
                                                name="destination"
                                                className="destination"
                                                itemLabel={(item) => `${item.legalName} (${item.systemId})`}
                                            />
                                        </>
                                    )}

                                    <GtbTextArea
                                        className="comment"
                                        label="components.detailView.reactiveDeduplicationDialog.comment_input"
                                        {...form.registerWithErrors("comment")}
                                        minRows={5}
                                    />
                                </DialogContent>
                            )}
                        </>
                    ),
                    footer: (
                        <DialogFooter>
                            <GtbButton variant="secondary" disabled={mergeButtonDisabled || isLoading} type="submit">
                                {translation("components.detailView.reactiveDeduplicationDialog.merge_button")}
                            </GtbButton>
                            <GtbButton disabled={isLoading} onClick={() => closeDialog()}>
                                {translation("components.dialog.cancel_button")}
                            </GtbButton>
                        </DialogFooter>
                    ),
                }}
            />
        </Form>
    );
}

export type reactiveDeduplicationItemType = "sco" | "omp";
