import GridRow from "./GridRow";
import useGtbTranslation from "../../../i18n/useGtbTranslation";

interface GridFooterProps {
    columnCount: number;
    isError: boolean;
}

function GridErrorFooter({ isError, columnCount }: GridFooterProps) {
    const translation = useGtbTranslation();
    return (
        <tfoot className={"errorFooter"}>
            <GridRow>
                <td colSpan={columnCount}>
                    <div role="alert">{isError && translation("components.grid.errorOnLoadingRecords")}</div>
                </td>
            </GridRow>
        </tfoot>
    );
}

export default GridErrorFooter;
