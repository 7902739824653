import { useCallback, useMemo, useState } from "react";
import { backendUrlType } from "../../../hooks/useAxios";
import { ColumnOptions } from "../ColumnProvider";
import { setOrUnsetProperty } from "../../../utils/objectUtils";

/**
 * hook to build a query that can be sent to the backend.
 * every call to buildQuery changes the query so that it will cause a rerender of all components using the query.
 *
 * @param baseQuery the non changing base of the query.
 * @param initialParameter the initial parameters for the query
 */
export function useQueryBuilder<QueryType>(baseQuery: string, initialParameter: QueryParameters<QueryType>) {
    const [query, setQuery] = useState<Query<QueryType>>(buildNewQuery(baseQuery, initialParameter));

    const forceReload = useCallback(() => {
        setQuery((oldQuery) => {
            return { ...oldQuery, changeCount: oldQuery.changeCount + 1 };
        });
    }, []);

    const setQueryParameter = useCallback((key: keyof QueryParameters<QueryType>, value?: string) => {
        setQuery((oldQuery) => {
            return {
                ...oldQuery,
                parameter: setOrUnsetProperty<QueryParameters<QueryType>>(
                    oldQuery.parameter,
                    key,
                    value
                ) as QueryParameters<QueryType>,
                changeCount: oldQuery.changeCount + 1,
            };
        });
    }, []);

    const toggleSortOption: toggleSortOptionType<QueryType> = useCallback((columnKey) => {
        setQuery((oldQuery) => {
            return {
                ...oldQuery,
                parameter: {
                    ...oldQuery.parameter,
                    sort:
                        oldQuery.parameter.sort.field === columnKey
                            ? toggleSortDirection(oldQuery.parameter.sort)
                            : buildSortOption(columnKey),
                },
                changeCount: oldQuery.changeCount + 1,
            };
        });
    }, []);

    const setFilter: setFilterType<QueryType> = useCallback((column, filterValue) => {
        setQuery((oldQuery) => {
            const { filter: oldFilter = {}, ...oldParams } = oldQuery.parameter;
            const nFilter = setOrUnsetProperty<QueryType>(oldFilter, column, filterValue);
            const newFilter = Object.keys(nFilter).length ? { filter: nFilter } : {};
            return {
                ...oldQuery,
                parameter: {
                    ...oldParams,
                    ...newFilter,
                },
                changeCount: oldQuery.changeCount + 1,
            };
        });
    }, []);

    const clearFilter = useCallback(() => {
        setQuery((oldQuery) => {
            const { filter: _, ...oldParameter } = oldQuery.parameter;
            return {
                ...oldQuery,
                parameter: { ...oldParameter },
                changeCount: oldQuery.changeCount + 1,
            };
        });
    }, []);

    const toggleFiltering = useCallback(() => {
        setQuery((oldQuery) => {
            return {
                ...oldQuery,
                parameter: { ...oldQuery.parameter, filterActive: !oldQuery.parameter.filterActive },
            };
        });
    }, []);

    const sortOption = useMemo(() => query.parameter.sort, [query.parameter.sort]);
    const filter = useMemo(() => query.parameter.filter ?? {}, [query.parameter.filter]);

    const columnOptions = useMemo<ColumnOptions<QueryType>>(() => {
        return {
            sort: { sortOption, toggleSortOption },
            filter: { value: filter, setFilter, filterActive: query.parameter.filterActive },
        };
    }, [filter, query.parameter.filterActive, setFilter, sortOption, toggleSortOption]);

    const queryCommands = useMemo<QueryCommands>(() => {
        return {
            toggleFiltering,
            clearFilter,
            refresh: forceReload,
            filterActive: query.parameter.filterActive,
        };
    }, [clearFilter, forceReload, query.parameter.filterActive, toggleFiltering]);

    return {
        query,
        setQueryParameter,
        forceReload,
        columnOptions,
        queryCommands,
    };
}

type queryParameter<ValueType> = ValueType;

export type filterType<QueryType> = { [key in Extract<keyof QueryType, string>]?: any };

export interface QueryParameters<QueryType> {
    sort: queryParameter<SortOption<QueryType>>;
    filter?: filterType<QueryType>;
    filterActive: boolean;

    [key: string]: queryParameter<any>;
}

export interface Query<QueryType> {
    url: backendUrlType;
    changeCount: number;
    parameter: QueryParameters<QueryType>;
}

export type sortDirectionType = "ASC" | "DESC";

const toggleSortDirection: <QueryType>(sortOption: SortOption<QueryType>) => SortOption<QueryType> = (sortOption) => {
    return {
        field: sortOption.field,
        direction: sortOption.direction === "ASC" ? "DESC" : "ASC",
    };
};

const buildSortOption: <QueryType>(column: keyof QueryType) => SortOption<QueryType> = (column) => {
    return {
        field: column,
        direction: "ASC",
    };
};

export const buildNewQuery = <QueryType>(baseQuery: string, initialParameter: QueryParameters<QueryType>) => {
    return {
        url: baseQuery,
        parameter: { ...initialParameter },
        changeCount: 0,
    };
};

export function buildURL(url: string, params: { [key: string]: any }) {
    return (
        url +
        "?" +
        Object.entries(params)
            .filter(([_key, value]) => value !== undefined)
            .map(([key, value]) => key + "=" + encodeURIComponent(value.toString()))
            .join("&")
    );
}

export const buildQuery = <QueryType>(query: Query<QueryType>) => {
    const { filter, sort, filterActive, ...params } = query.parameter;
    let actualParams = { ...params, field: sort.field, direction: sort.direction };
    if (filterActive && filter) {
        actualParams = { ...actualParams, ...filter };
    }
    return buildURL(query.url, actualParams);
};

export type toggleSortOptionType<QueryType> = (column: keyof QueryType) => void;

export type setFilterType<QueryType> = (column: keyof QueryType, filterValue: any) => void;

export interface SortOption<QueryType> {
    field: keyof QueryType;
    direction: sortDirectionType;
}

export interface QueryCommands {
    clearFilter: () => void;
    toggleFiltering: () => void;
    refresh: () => void;
    filterActive: boolean;
}
