import { TypedDetailViewProps } from "../../components/detailView/DetailView";
import "../../components/spinner.css";
import { useHelpContext } from "../../help/context-sensitivity/useHelpContext";
import EntityDetailView from "../../components/entity/EntityDetailView";
import Tabs, { TabKey, TabProps } from "../../components/tabs/Tabs";
import React, { useMemo } from "react";
import { PreGtbTransactionCertificateDetails } from "../preGtbTransactionCertificateTypes";
import PreGtbTransactionCertificateBasicData, {
    PreGtbTransactionCertificateBasicDataProps,
} from "./PreGtbTransactionCertificateBasicData";
import PreGtbProducts from "./products/PreGtbProducts";

export default function PreGtbTransactionCertificateDetailView({
    detailViewProps,
    preGtbTcId,
    preGtbTcStatus,
    organisationType,
    form,
    readOnly,
    subListVisible,
    restrictBuyersToSelectedStandard,
    ...rest
}: PreGtbTransactionCertificateDetailViewProps) {
    useHelpContext("pre-gtb-tc-details");

    const sublistTabs = useMemo<TabProps<PreGtbTransactionCertificateTabs>[]>(
        () => [
            {
                heading: "preGtbTransactionCertificate.detail.preGtbProducts_tab",
                element: <PreGtbProducts preGtbTcStatus={preGtbTcStatus} />,
            },
        ],
        [preGtbTcStatus]
    );

    return (
        <EntityDetailView {...rest} detailViewProps={detailViewProps} form={form}>
            <Tabs<PreGtbTransactionCertificateDetails, PreGtbTransactionCertificateTabs>
                tabs={[
                    {
                        heading: "preGtbTransactionCertificate.detail.basicData_tab",
                        element: (
                            <PreGtbTransactionCertificateBasicData
                                form={form}
                                organisationType={organisationType}
                                preGtbTcId={preGtbTcId}
                                readOnly={readOnly}
                                restrictBuyersToSelectedStandard={restrictBuyersToSelectedStandard}
                            />
                        ),
                    },
                    ...(subListVisible ? sublistTabs : []),
                ]}
                errors={form.form.formState.errors}
                errorToTab={{
                    id: "internal",
                    tcNumber: "preGtbTransactionCertificate.detail.basicData_tab",
                    standard: "preGtbTransactionCertificate.detail.basicData_tab",
                    certificationBody: "preGtbTransactionCertificate.detail.basicData_tab",
                    buyer: "preGtbTransactionCertificate.detail.basicData_tab",
                    sellersLicenceNumber: "preGtbTransactionCertificate.detail.basicData_tab",
                    dateOfIssue: "preGtbTransactionCertificate.detail.basicData_tab",
                    status: "preGtbTransactionCertificate.detail.basicData_tab",
                }}
            />
        </EntityDetailView>
    );
}

type PreGtbTransactionCertificateTabs = TabKey<
    "preGtbTransactionCertificate.detail.basicData_tab" | "preGtbTransactionCertificate.detail.preGtbProducts_tab"
>;

export interface PreGtbTransactionCertificateDetailViewProps
    extends TypedDetailViewProps<PreGtbTransactionCertificateDetails>,
        PreGtbTransactionCertificateBasicDataProps {
    subListVisible: boolean;
    preGtbTcStatus?: PreGtbTransactionCertificateDetails["status"];
}
