import DataGrid, { GridProps } from "./DataGrid";
import withColumnSettings, { WithColumnSettingsProps } from "./hoc/columSettings/withColumnSettings";
import useGtbTranslation from "../../i18n/useGtbTranslation";
import hocCompose from "../../utils/hocCompose";
import { withInfinityScrollData } from "./hoc/withInfinityScrollData";
import { withTotalItemCount } from "./hoc/withTotalItemCount";
import withFilterCommands from "./hoc/withFilterCommands";
import "./singleSelectList.css";
import { joinClassNames } from "../../utils/StringUtils";
import { Entity } from "../../utils/typeUtils";
import { useQueryBuilder } from "./component/useQueryBuilder";
import { backendUrlType } from "../../hooks/useAxios";
import useResolvedRoute from "../routing/useResolvedRoute";

export interface InputSourceSelectionListProps<ItemType extends Entity> {
    className: string;
    tableName: WithColumnSettingsProps["tableName"];
    onItemClick: (item: ItemType) => void;
    columns: GridProps<ItemType>["columns"];
    selectedItemId?: string;
    url: backendUrlType;
    sortField: keyof ItemType;
}

const Grid = hocCompose(withInfinityScrollData, withColumnSettings, withTotalItemCount, withFilterCommands)(DataGrid);

export default function InputSourceSelectionList<ItemType extends Entity>({
    tableName,
    onItemClick,
    selectedItemId,
    className,
    url,
    sortField,
    ...rest
}: InputSourceSelectionListProps<ItemType>) {
    const translation = useGtbTranslation();
    const { getResolvedId } = useResolvedRoute();
    const tcProductId = getResolvedId("tcProduct")!;

    const { query, columnOptions, queryCommands } = useQueryBuilder(url, {
        sort: { field: sortField, direction: "ASC" },
        filterActive: true,
        tcProductId,
    });

    return (
        <Grid
            {...rest}
            query={query}
            queryCommands={queryCommands}
            columnOptions={columnOptions}
            aria-controls="inputSource"
            rowAttributeProvider={(item: ItemType) => ({
                onClick: () => onItemClick(item),
                onKeyDown: (e: KeyboardEvent) => {
                    if (e.code === "Enter") {
                        e.preventDefault();
                        onItemClick(item);
                    }
                },
                "aria-selected": selectedItemId === item.id,
                tabIndex: 0,
            })}
            className={joinClassNames("singleSelectList", className)}
            gridControlsLeft={[
                <div key={tableName + "header"} className="subListHeader">
                    {translation("inputQuantity.detail.inputSourceList_header")}
                </div>,
            ]}
            tableName={tableName}
        />
    );
}
