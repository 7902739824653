import Icon from "../../components/Icon";
import GtbButton from "../../components/GtbButton";
import useGtbTranslation from "../../i18n/useGtbTranslation";

export default function DashboardSettingsButton({
    showDashboardSettingsDrawer,
}: {
    showDashboardSettingsDrawer: () => void;
}) {
    const translation = useGtbTranslation();
    return (
        <GtbButton
            className="dashboardSettingsButton iconOnLeft"
            variant="secondary"
            size="medium"
            onClick={showDashboardSettingsDrawer}
        >
            <Icon name="pencil" size={13} />
            {translation("dashboard.dashboardSettings_button")}
        </GtbButton>
    );
}
