import { yupResolver } from "@hookform/resolvers/yup";
import useIsAuthorised from "../../auth/useIsAuthorised";
import { ByproductInputCombinationDetails } from "../byproductInputCombinationTypes";
import { byproductInputCombinationValidationSchema } from "./byproductInputCombinationValidationSchema";
import { defaultByproductInputCombinationSortOption } from "../useByproductInputCombinationListView";
import useResolvedRoute, { routeEnum } from "../../components/routing/useResolvedRoute";
import { useMemo } from "react";
import useGtbTranslation from "../../i18n/useGtbTranslation";
import useDomainEntityDetailsEdit from "../../hooks/details/useDomainEntityDetailsEdit";

function useByproductInputCombinationEdit() {
    const translation = useGtbTranslation();
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedId } = useResolvedRoute();
    const byproductInputCombinationId = getResolvedId("byproductInputCombination");

    const { detailViewProps, form, initialData, isReadOnly, title, recordNavigationProps } =
        useDomainEntityDetailsEdit<ByproductInputCombinationDetails>({
            baseUrl: "/certificate-service/by-product-input-combinations/" + byproductInputCombinationId,
            frontendRoute: "byproductInputCombination",
            recordNavigationDefaultSort: defaultByproductInputCombinationSortOption,
            titleBuilder: (item) => `${item.productDetailName} & ${item.processCategoryName}`,
            resolver: yupResolver(byproductInputCombinationValidationSchema),
            canSave: hasAccessRight("updateProductConversion"),
            historyColumns: [
                {
                    identifier: "productDetail",
                    title: "byProductInputCombination.history.productDetail_column",
                },
                {
                    identifier: "processCategory",
                    title: "byProductInputCombination.history.processCategory_column",
                },
            ],
        });

    const createRoute = useMemo<routeEnum | undefined>(() => {
        return hasAccessRight("createProductConversion") ? "byproductInputCombination" : undefined;
    }, [hasAccessRight]);

    return {
        detailViewProps,
        form,
        isReadOnly,
        subListVisible: true,
        title,
        subtitle: translation({
            key: "byProductInputCombination.detail.subtitle",
            options: {
                productDetailName: initialData?.productDetailName,
                processCategoryName: initialData?.processCategoryName,
            },
        }),
        recordNavigationProps,
        createRoute,
    };
}

export default useByproductInputCombinationEdit;
