import { useCallback } from "react";
import useResolveDuplicateDialog, { DetectDuplicateDialogProps } from "./useResolveDuplicateDialog";
import { UseFormErrorResolver } from "../formHandling/useFormSubmit";
import { FieldValues } from "react-hook-form/dist/types";

export interface Duplicate {
    id: string;
    legalName?: string;
    businessName: string;
    countryArea: string;
    stateProvince: string;
    systemId: string;
}

export default function useResolveDuplicates<ItemType extends FieldValues>(
    acceptDuplicate: DetectDuplicateDialogProps<ItemType>["acceptDuplicate"]
) {
    const showDialog = useResolveDuplicateDialog<ItemType>();

    return useCallback<UseFormErrorResolver<ItemType>>(
        (error, toCreate, retrySaveAction) => {
            if (error?.data?.potentialDuplicates) {
                showDialog(error.data.potentialDuplicates, toCreate, retrySaveAction, acceptDuplicate);
                return true;
            }
            return false;
        },
        [acceptDuplicate, showDialog]
    );
}
