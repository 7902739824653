import { useCallback } from "react";
import { useMutation } from "../../hooks/useAxios";
import useErrorDialog from "./useErrorDialog";
import GtbButton from "../GtbButton";
import { info } from "../../utils/notification/notification";
import { getValidationErrorMessage } from "../../utils/errorHandler";
import useGtbTranslation, { I18nFunction, I18nKey } from "../../i18n/useGtbTranslation";
import { DialogProps } from "./useDialog";
import useWarningDialog from "./UseWarningDialog";

function useConfirmDeletionDialog(options = { omitSuccessMessage: false }) {
    const translation = useGtbTranslation();
    const { showDialog: showErrorDialog, closeDialog } = useErrorDialog();
    const { showDialog: showWarningDialog } = useWarningDialog();

    const { runQuery } = useMutation({ method: "delete", url: "" });

    const callBackend = useCallback(
        (
            deleteUrl: string,
            afterDeletion: () => void,
            title?: I18nKey,
            message?: I18nKey,
            successMessage?: I18nKey
        ) => {
            showErrorDialog(buildConfirmDeleteDialogOptions(translation, closeDialog, undefined, title, message));
            runQuery({ url: deleteUrl, defaultForbiddenHandler: false })
                .then(() => {
                    closeDialog();
                    if (!options?.omitSuccessMessage) {
                        info(translation(successMessage ?? "components.dialog.deletedSuccessfully_toast"));
                    }
                    afterDeletion();
                })
                .catch((e) => {
                    closeDialog();
                    showWarningDialog({
                        title: title ?? "components.dialog.deleteRecord_dialogTitle",
                        message: getValidationErrorMessage(e),
                    });
                });
        },
        [showErrorDialog, translation, closeDialog, runQuery, options?.omitSuccessMessage, showWarningDialog]
    );

    return useCallback(
        async (
            deleteUrl: string,
            afterDeletion: () => void,
            title?: I18nKey,
            message?: I18nKey,
            successMessage?: I18nKey
        ) =>
            showErrorDialog(
                buildConfirmDeleteDialogOptions(
                    translation,
                    closeDialog,
                    () => {
                        callBackend(deleteUrl, afterDeletion, title, message);
                    },
                    title,
                    message
                )
            ),
        [showErrorDialog, translation, closeDialog, callBackend]
    );
}

export default useConfirmDeletionDialog;

const buildConfirmDeleteDialogOptions = (
    translation: I18nFunction,
    closeDialog: () => void,
    onClick?: () => void,
    title: I18nKey = "components.dialog.deleteRecord_dialogTitle",
    message: I18nKey = "components.dialog.deleteRecord_dialogText"
): DialogProps => {
    return {
        title: title,
        message: message,
        footer: (
            <>
                <GtbButton variant="secondary" disabled={!onClick} onClick={onClick}>
                    {translation("components.dialog.yes_button")}
                </GtbButton>
                <GtbButton disabled={!onClick} onClick={closeDialog}>
                    {translation("components.dialog.no_button")}
                </GtbButton>
            </>
        ),
    };
};
