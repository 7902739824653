import Icon, { IconName } from "../../components/Icon";
import useGtbTranslation, { I18nKey } from "../../i18n/useGtbTranslation";

function HeaderLinkCaption({ caption, icon }: { caption: I18nKey; icon: IconName }) {
    const translation = useGtbTranslation();
    return (
        <>
            <Icon name={icon} />
            {translation(caption)}
        </>
    );
}

export default HeaderLinkCaption;
