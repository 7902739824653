import CountryAreaDetailView from "./CountryAreaDetailView";
import useCountryAreaEdit from "./useCountryAreaEdit";

function CountryAreaEditView() {
    const countryAreaDetailProps = useCountryAreaEdit();

    return <CountryAreaDetailView {...countryAreaDetailProps} />;
}

export default CountryAreaEditView;
