import { StateProvinceReference } from "../../../../stateProvince/stateProvinceTypes";

import "./typedPickList.css";
import { DependentPicklist, DependentPicklistProps } from "./DependentPicklist";
import { joinClassNames } from "../../../../utils/StringUtils";
import { FieldValues } from "react-hook-form/dist/types";

function StateProvincePickList<FormItemType extends FieldValues>({
    className,
    ...rest
}: DependentPicklistProps<FormItemType, StateProvinceReference>) {
    return (
        <DependentPicklist
            className={joinClassNames("stateProvincePickList", className)}
            urlBuilder={(countryAreaId) =>
                `/certificate-service/references/countries/${countryAreaId}/states-provinces`
            }
            itemId="id"
            itemLabel="name"
            {...rest}
        />
    );
}

export default StateProvincePickList;
