import { useMemo } from "react";
import { ProductConversionSummary } from "./productConversionTypes";
import useListView from "../hooks/useListView";
import { SortOption } from "../components/grid/component/useQueryBuilder";
import { buildNumberColumn, buildTextColumn } from "../components/grid/ColumnProvider";
import { useDeleteAction, useShowDetailsAction } from "../utils/defaultActions";
import useIsAuthorised from "../auth/useIsAuthorised";
import { EntityListViewGridProps } from "../components/entity/EntityListView";
import { GridColumn } from "../components/grid/component/Grid";
import useResolvedRoute, { getCreateUrl } from "../components/routing/useResolvedRoute";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";

export const defaultProductConversionSortOption: SortOption<ProductConversionSummary> = {
    field: "processCategoryName",
    direction: "ASC",
};

const columns: GridColumn<ProductConversionSummary>[] = [
    buildTextColumn({
        key: "processCategoryName",
        title: "productConversions.list.productConversions_column",
        minWidth: 190,
    }),
    buildTextColumn({ key: "rawMaterialName", title: "productConversions.list.rawMaterial_column", minWidth: 155 }),
    buildTextColumn({
        key: "inputProductCategoryName",
        title: "productConversions.list.inputProductCategory_column",
        minWidth: 231,
    }),
    buildTextColumn({
        key: "inputProductDetailName",
        title: "productConversions.list.inputProductDetail_column",
        minWidth: 206,
    }),
    buildTextColumn({
        key: "outputProductCategoryName",
        title: "productConversions.list.outputProductCategory_column",
        minWidth: 244,
    }),
    buildTextColumn({
        key: "outputProductDetailName",
        title: "productConversions.list.outputProductDetail_column",
        minWidth: 219,
    }),
    buildNumberColumn({
        key: "minConversion",
        title: "productConversions.list.minimumConversionRate_column",
        minWidth: 174,
        contentProvider: (item) => `${item.minConversion} %`,
    }),
    buildNumberColumn({
        key: "maxConversion",
        title: "productConversions.list.maximumConversionRate_column",
        minWidth: 177,
        contentProvider: (item) => `${item.maxConversion} %`,
    }),
];

export default function useProductConversionListView() {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedEditUrl } = useResolvedRoute();

    const {
        queryBuilder: { query, columnOptions, forceReload, queryCommands },
    } = useListView<ProductConversionSummary>({
        navigationContextLabel: "productConversions.breadcrumb",
        baseQuery: "/certificate-service/product-conversions",
        defaultSortOption: defaultProductConversionSortOption,
    });

    const showDetailsAction = useShowDetailsAction(
        (item: ProductConversionSummary) => getResolvedEditUrl("productConversion", item.id),
        query.parameter
    );

    const deleteAction = useDeleteAction(
        (item: ProductConversionSummary) => `/certificate-service/product-conversions/${item.id}`,
        () => forceReload(),
        "deleteProductConversion"
    );

    const actions = useGridRowContextMenuActions(showDetailsAction, deleteAction);

    return useMemo<EntityListViewGridProps<ProductConversionSummary>>(() => {
        return {
            query,
            columns,
            columnOptions,
            uniqueDataKey: "id",
            onItemDoubleClick: showDetailsAction,
            createUrl: hasAccessRight("createProductConversion") ? getCreateUrl("productConversion") : undefined,
            tableName: "productConversion",
            itemActions: actions,
            queryCommands,
            bulkDeleteAccessRight: "deleteProductConversion",
        };
    }, [query, columnOptions, showDetailsAction, hasAccessRight, actions, queryCommands]);
}
