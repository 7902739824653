import { ShipmentDetailViewProps } from "./ShipmentDetailView";
import { Nullable } from "../../../../utils/typeUtils";
import useDetailsCreate from "../../../../hooks/details/useDetailsCreate";
import { ShipmentDetails } from "../shipmentTypes";
import { useMemo } from "react";
import { useQuery } from "../../../../hooks/useAxios";
import { TransactionCertificateReference } from "../../../transactionCertificateTypes";
import useResolvedRoute from "../../../../components/routing/useResolvedRoute";
import { yupResolver } from "@hookform/resolvers/yup";
import { buildShipmentValidationSchema } from "./shipmentValidationSchema";

export default function useShipmentCreate(): ShipmentDetailViewProps {
    const { getResolvedId } = useResolvedRoute();
    const transactionCertificateId = getResolvedId("transactionCertificate")!;

    const { data: transactionCertificate, isLoading: isParentLoading } = useQuery<TransactionCertificateReference>({
        url: `/certificate-service/references/transaction-certificates/${transactionCertificateId}`,
    });

    const initialData = useMemo<Nullable<ShipmentDetails>>(
        () => ({
            id: null,
            shipmentDocumentNumber: null,
            tcSellerLegalName: transactionCertificate?.sellerLegalName ?? null,
            shipmentNumber: null,
            tcFacilityAsSellerBusinessName: transactionCertificate?.facilityAsSellerBusinessName ?? null,
            shipmentDate: null,
            tcFacilityAsBuyerBusinessName: transactionCertificate?.facilityAsBuyerBusinessName ?? null,
            tcBuyerLegalName: transactionCertificate?.buyerLegalName ?? null,
            invoiceNumbersAndDates: null,
            grossShippingWeight: null,
            countryOfDestination: null,
            consignee: null,
            tcStatus: transactionCertificate?.status ?? null,
        }),
        [transactionCertificate]
    );

    const visibility = useMemo(
        () => ({
            tcFacilityAsSellerBusinessName: !!transactionCertificate?.facilityAsSellerBusinessName,
            tcFacilityAsBuyerBusinessName: !!transactionCertificate?.facilityAsBuyerBusinessName,
        }),
        [transactionCertificate?.facilityAsBuyerBusinessName, transactionCertificate?.facilityAsSellerBusinessName]
    );

    const { detailViewProps, form, title } = useDetailsCreate<ShipmentDetails>({
        title: "shipment.detail.newRecord_title",
        frontendRoute: "shipment",
        initialData,
        resolver: yupResolver(buildShipmentValidationSchema()),
        saveUrl: `/certificate-service/transaction-certificates/${transactionCertificateId}/shipments`,
    });

    return {
        title,
        detailViewProps: {
            ...detailViewProps,
            isLoading: detailViewProps.isLoading || isParentLoading,
        },
        transactionCertificateId,
        form,
        readOnly: false,
        visibility,
        createRoute: "shipment",
    };
}
