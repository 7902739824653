import useFormDialog from "../../dialog/useFormDialog";
import DialogContent from "../../dialog/DialogContent";
import GtbTextArea from "../../Input/GtbTextArea";
import * as React from "react";
import { buildSchema, validateString } from "../../../utils/yupExtension";
import { Note } from "./noteTypes";
import { UseGtbFormReturn } from "../../../hooks/formHandling/useGtbForm";
import { backendUrlType } from "../../../hooks/useAxios";

const noteSchema = buildSchema<Note>({ comment: validateString().isRequired().hasMaxLength(1000) });

export function useCreateNoteDialog(noteRootUrl: backendUrlType, reloadNotes: Function) {
    return useFormDialog({
        title: "components.detailView.notes.dialog.newNote_dialogTitle",
        icon: "comment",
        method: "post",
        url: noteRootUrl,
        schema: noteSchema,
        afterSubmit: () => reloadNotes(),
        renderContent: renderNoteDialogContent,
    });
}

export function useEditNoteDialog(reloadNotes: Function) {
    return useFormDialog({
        title: "components.detailView.notes.dialog.editNote_dialogTitle",
        icon: "comment",
        schema: noteSchema,
        afterSubmit: () => reloadNotes(),
        renderContent: renderNoteDialogContent,
    });
}

const renderNoteDialogContent = (form: UseGtbFormReturn<Note>) => {
    return (
        <DialogContent>
            <GtbTextArea
                {...form.registerWithErrors("comment")}
                label="components.detailView.notes.dialog.comment_input"
            />
        </DialogContent>
    );
};
