import useDetailsCreate from "../../hooks/details/useDetailsCreate";
import { yupResolver } from "@hookform/resolvers/yup";
import { Nullable } from "../../utils/typeUtils";
import { CertificationBodyDetails } from "../certificationBodyTypes";
import { certificationBodyValidationSchema } from "./certificationBodyValidationSchema";
import { CertificationBodyDetailViewProps, CertificationBodyReadOnlyProps } from "./CertificationBodyDetailView";
import {
    organisationDefaultInitialData,
    useOrganisationDefaultReadOnlyProps,
} from "../../organisation/details/organisationDetailViewProps";
import useAuth from "../../auth/useAuth";
import { useMemo } from "react";
import useIsAuthorised from "../../auth/useIsAuthorised";

const initialData: Nullable<CertificationBodyDetails> = {
    ...organisationDefaultInitialData,
    legalName: null,
    businessName: null,
    authorisedPerson: null,
    scTemplate: null,
    tcTemplate: null,
    tlsCertificate: null,
    tcNumberBlockSize: 100,
    cbsWithGrantedPriorConsent: null,
};

function useCertificationBodyCreate(): CertificationBodyDetailViewProps {
    const { hasAccessRight } = useIsAuthorised();
    const {
        currentUser: {
            authorisationRole: { level: authorisationRoleLevel },
        },
    } = useAuth();

    const { detailViewProps, form, title } = useDetailsCreate<CertificationBodyDetails>({
        title: "certificationBody.detail.newRecord_title",
        frontendRoute: "certificationBody",
        initialData,
        resolver: yupResolver(certificationBodyValidationSchema),
        saveUrl: "/certificate-service/certification-bodies",
    });

    const defaultReadOnlyProps = useOrganisationDefaultReadOnlyProps(false);

    const readOnlyProps = useMemo<CertificationBodyReadOnlyProps>(() => {
        const isCertificationDataReadOnly = authorisationRoleLevel > 3;
        return {
            ...defaultReadOnlyProps,
            isAuthorisedPersonReadOnly: isCertificationDataReadOnly,
            isScTemplateReadOnly: isCertificationDataReadOnly,
            isTcTemplateReadOnly: isCertificationDataReadOnly,
            isTcNumberBlockSizeReadOnly: false,
            isPriorConsentReadOnly: !hasAccessRight("grantPriorConsent"),
        };
    }, [authorisationRoleLevel, defaultReadOnlyProps, hasAccessRight]);

    return {
        title,
        detailViewProps,
        form,
        readOnlyProps,
        createRoute: "certificationBody",
        tlsTabVisible: false,
        isTcNumberBlockSizeVisible: authorisationRoleLevel === 1,
    };
}

export default useCertificationBodyCreate;
