import useCertificationBodyEdit from "./useCertificationBodyEdit";
import CertificationBodyDetailView from "./CertificationBodyDetailView";

function CertificationBodyEditView() {
    const certificationBodyDetailProps = useCertificationBodyEdit();

    return <CertificationBodyDetailView {...certificationBodyDetailProps} />;
}

export default CertificationBodyEditView;
