import { AdditionalMaterialDetails } from "../additionalMaterialTypes";
import { TypedDetailViewProps } from "../../../components/detailView/DetailView";
import EntityDetailView from "../../../components/entity/EntityDetailView";
import AdditionalMaterialBasicData from "./AdditionalMaterialBasicData";
import { MaterialContentRuleDetails } from "../../materialContentRuleTypes";
import { useHelpContext } from "../../../help/context-sensitivity/useHelpContext";
import { LabelGradeReference } from "../../../labelGrade/labelGradeTypes";

export default function AdditionalMaterialDetailView({
    detailViewProps,
    form,
    readOnly,
    materialContentRule,
    labelGrade,
    standard,
    standardVersion,
    ...rest
}: AdditionalMaterialDetailViewProps) {
    useHelpContext("additional-material-details");
    return (
        <EntityDetailView detailViewProps={detailViewProps} form={form} {...rest}>
            <AdditionalMaterialBasicData
                form={form}
                readOnly={readOnly}
                materialContentRule={materialContentRule}
                labelGrade={labelGrade}
                standard={standard}
                standardVersion={standardVersion}
            />
        </EntityDetailView>
    );
}

export interface AdditionalMaterialDetailViewProps extends TypedDetailViewProps<AdditionalMaterialDetails> {
    readOnly: boolean;
    materialContentRule?: MaterialContentRuleDetails;
    labelGrade?: LabelGradeReference;
    standard?: string;
    standardVersion?: string;
}
