import { Control, Controller } from "react-hook-form";
import FileUploadComponent, { FileUploadComponentProps } from "./FileUploadComponent";

export default function ControlledFileUploadComponent({ control, name, ...rest }: ControlledFileUploadComponentProps) {
    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FileUploadComponent
                    name={name}
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error?.message}
                    {...rest}
                />
            )}
        />
    );
}

export interface ControlledFileUploadComponentProps
    extends Omit<FileUploadComponentProps, "value" | "onChange" | "helperText" | "error"> {
    control: Control<any>;
}
