import { useHelpContext } from "../help/context-sensitivity/useHelpContext";
import EntityListView from "../components/entity/EntityListView";
import useCertificationRelationListView from "./useCertificationRelationListView";

export default function CertificationRelationListView() {
    const certificationRelationGridOptions = useCertificationRelationListView();
    useHelpContext("certification-relation-list");

    return (
        <EntityListView heading="certificationRelation.list.header" gridOptions={certificationRelationGridOptions} />
    );
}
