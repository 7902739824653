import { useCallback, useState } from "react";
import Drawer from "../../../drawer/Drawer";
import SortableDropContainer from "../../../dragDrop/SortableDropContainer";
import Toggle from "../../../Input/Toggle";
import { ColumnSettings, ColumnSettingsProps } from "./ColumnSettingsComponent";
import DrawerCard from "../../../drawer/DrawerCard";
import GtbNumberField from "../../../Input/GtbNumberField";
import "./columnSettingsDrawer.css";
import GtbButton from "../../../GtbButton";
import useWarningDialog from "../../../dialog/UseWarningDialog";
import { DialogYesNoOptions } from "../../../dialog/DialogOptions";
import useGtbTranslation, { I18nKey } from "../../../../i18n/useGtbTranslation";

export default function ColumnSettingsDrawer({
    columns,
    applyColumnSettings,
    resetColumnSettings,
    closeDrawer,
}: ColumnSettingsDrawerProps) {
    const translation = useGtbTranslation();
    const [volatileColumns, setVolatileColumns] = useState(columns);
    const { showDialog, closeDialog } = useWarningDialog();

    const changeVisibility = useCallback(
        (item: ColumnSettings) =>
            setVolatileColumns((prevState) =>
                prevState.map((col) => {
                    if (col.title === item.title) {
                        return { ...col, visible: !col.visible };
                    }
                    return col;
                })
            ),
        []
    );

    const changeWidth = useCallback((item: ColumnSettings, newWidth: string) => {
        setVolatileColumns((prevState) =>
            prevState.map((col) => {
                if (col.title === item.title) {
                    return { ...col, width: newWidth !== "" ? +newWidth : undefined };
                }
                return col;
            })
        );
    }, []);

    return (
        <Drawer
            title={"components.columnSettings.title"}
            closeDrawer={() => {
                applyColumnSettings(volatileColumns);
                closeDrawer();
            }}
        >
            <GtbButton
                onClick={() => {
                    closeDrawer();
                    showDialog({
                        title: "components.columnSettings.restoreDefaultSettings_dialogTitle",
                        message: "components.columnSettings.restoreDefaultSettings_dialogText",
                        footer: (
                            <DialogYesNoOptions
                                onYes={() => {
                                    resetColumnSettings();
                                    closeDialog();
                                }}
                                onNo={closeDialog}
                            />
                        ),
                    });
                }}
                className="secondary-color-scheme"
                variant="secondary"
                size="medium"
            >
                {translation("components.columnSettings.restoreDefaultSettings_button")}
            </GtbButton>
            <SortableDropContainer
                className="columnSettingsDrawer"
                items={volatileColumns}
                setItems={setVolatileColumns}
                renderItem={(item) => (
                    <DrawerCard key={item.title}>
                        <Toggle
                            label={item.title as I18nKey}
                            id={`columnSetting-${item.title}`}
                            checked={item.visible}
                            onChange={() => changeVisibility(item)}
                        />
                        <div className="widthWrapper">
                            <label htmlFor={`column-${item.title}-width`}>
                                {translation("components.columnSettings.width_label")}
                            </label>
                            <GtbNumberField
                                id={`column-${item.title}-width`}
                                placeholder={translation("components.columnSettings.width_placeholder")}
                                value={item.width ?? ""}
                                onChange={(e) => changeWidth(item, e.target.value)}
                            />
                        </div>
                    </DrawerCard>
                )}
            />
        </Drawer>
    );
}

interface ColumnSettingsDrawerProps extends ColumnSettingsProps {
    columns: ColumnSettings[];
    closeDrawer: () => void;
}
