import { setCurrentHelpContextAffiliation } from "./HelpContextStore";
import { useCallback, useEffect } from "react";

const AFFILIATION_PREFIX: string = "a9n-";

export const useHelpContext = (affiliation: string) => {
    const setContext = setCurrentHelpContextAffiliation();

    const setContextOnRender = useCallback(() => {
        setContext(AFFILIATION_PREFIX + affiliation);
    }, [affiliation, setContext]);

    const clearContext = useCallback(() => {
        setContext(null);
    }, [setContext]);

    useEffect(() => {
        setContextOnRender();

        return () => clearContext();
    }, [setContextOnRender, clearContext]);
};
