import ProductCategoryDetailView from "./ProductCategoryDetailView";
import useProductCategoryCreate from "./useProductCategoryCreate";

function ProductCategoryCreateView() {
    const productCategoryDetailProps = useProductCategoryCreate();

    return <ProductCategoryDetailView {...productCategoryDetailProps} />;
}

export default ProductCategoryCreateView;
