import { UseAxiosError } from "../hooks/useAxios";
import { I18nKey } from "../i18n/useGtbTranslation";

export const EMPTY_ERROR_MESSAGE_INDICATOR = "__empty_error_message";

export const handleResponseError = () => {
    /* if backend calls should have centralized error handling, add it here */
};

export const getValidationErrorMessage = (error: validationErrorCode | UseAxiosError): I18nKey => {
    if (!error) {
        return "error.errorHandler.unexpectedSystemError_message";
    } else if (error === EMPTY_ERROR_MESSAGE_INDICATOR) {
        return "" as I18nKey;
    } else if (typeof error === "string") {
        return validationErrors[error] || error;
    } else if (typeof error?.data === "string") {
        return validationErrors[error.data] || error.data;
    } else if (retrieveValidationErrorCode(error)?.startsWith("fk_")) {
        return validationErrors["fk_record_is_referenced"];
    } else if (retrieveValidationErrorCode(error)?.startsWith("frozen_")) {
        return validationErrors["record_is_frozen"];
    } else {
        return validationErrors[retrieveValidationErrorCode(error)] || error.data;
    }
};

export const getValidationErrorMessageViaString = (error: I18nKey & string): I18nKey => {
    if (error.startsWith("fk_")) {
        return validationErrors["fk_record_is_referenced"];
    } else if (Object.values(validationErrors).includes(error)) {
        return validationErrors[error as validationErrorCode] || error;
    } else {
        return getValidationErrorMessage(error);
    }
};

const retrieveValidationErrorCode = (error: UseAxiosError): validationErrorCode => {
    return Object.values(error.data?.validationErrors)?.[0] as validationErrorCode;
};

const unique = (entityName: I18nKey, fieldName: I18nKey) =>
    ({
        key: "error.errorHandler.alreadyExists_message",
        options: { entityName: `$t(${entityName})`, fieldName: `$t(${fieldName})` },
    } as I18nKey);

const validationErrors: { [key: string]: I18nKey } = {
    user_account_uk_username: unique("error.errorHandler.entityName.user", "error.errorHandler.fieldName.username"),
    uk_country_name: unique("error.errorHandler.entityName.country", "error.errorHandler.fieldName.name"),
    uk_country_iso_code: unique("error.errorHandler.entityName.country", "error.errorHandler.fieldName.isoCode"),
    uk_state_province_name: unique("error.errorHandler.entityName.state", "error.errorHandler.fieldName.provinceName"),
    uk_state_province_iso_code: unique("error.errorHandler.entityName.state", "error.errorHandler.fieldName.isoCode"),
    uk_authorisationrole_name: unique(
        "error.errorHandler.entityName.authorisationRole",
        "error.errorHandler.fieldName.roleName"
    ),
    uk_raw_material_code: unique("error.errorHandler.entityName.rawMaterial", "error.errorHandler.fieldName.code"),
    uk_raw_material_pick_list_sequence: unique(
        "error.errorHandler.entityName.rawMaterial",
        "error.errorHandler.fieldName.pickListSequence"
    ),
    uk_product_category_code: unique(
        "error.errorHandler.entityName.productCategory",
        "error.errorHandler.fieldName.code"
    ),
    uk_product_category_pick_list_sequence: unique(
        "error.errorHandler.entityName.productCategory",
        "error.errorHandler.fieldName.pickListSequence"
    ),
    uk_product_detail_code: unique("error.errorHandler.entityName.productDetail", "error.errorHandler.fieldName.code"),
    uk_product_detail_pick_list_sequence: unique(
        "error.errorHandler.entityName.productDetail",
        "error.errorHandler.fieldName.pickListSequence"
    ),
    uk_process_category_code: unique(
        "error.errorHandler.entityName.processCategory",
        "error.errorHandler.fieldName.code"
    ),
    uk_process_category_pick_list_sequence: unique(
        "error.errorHandler.entityName.processCategory",
        "error.errorHandler.fieldName.pickListSequence"
    ),
    fk_record_is_referenced: "error.errorHandler.cannotDeleteReferencedRecord_message",
    uk_allowed_material: unique(
        "error.errorHandler.entityName.allowedMaterial",
        "error.errorHandler.fieldName.standardAndRawMaterial"
    ),
    too_long: "error.errorHandler.tooLong",
    too_short: "error.errorHandler.tooShort",
    uk_certification_licence_certification_standard: unique(
        "error.errorHandler.entityName.certificationLicence",
        "error.errorHandler.fieldName.certificationBody_standard"
    ),
    uk_certification_relation: unique(
        "error.errorHandler.entityName.certificationRelation",
        "error.errorHandler.fieldName.certificationBody_supplyChainOperator_standard"
    ),
    uk_supplying_relation_sco_omp: unique(
        "error.errorHandler.entityName.supplyingRelation",
        "error.errorHandler.fieldName.supplyChainOperator_organicMaterialProducer"
    ),
    must_not_be_null: "error.errorHandler.mustNotBeNull",
    must_be_null: "error.errorHandler.mustBeNull",
    uk_external_id_relation: "error.externalId.uk",
    uk_subcontracting_relation_principal_subcontractor_standard: unique(
        "error.errorHandler.entityName.subcontractingRelation",
        "error.errorHandler.fieldName.principal_subcontractor_standard"
    ),
    uk_sco_licence: unique(
        "error.errorHandler.entityName.scoLicence",
        "error.errorHandler.fieldName.supplyChainOperator_standard"
    ),
    uk_standard_acronym: unique(
        "error.errorHandler.entityName.standard",
        "error.errorHandler.fieldName.standard_acronym"
    ),
    uk_sc_process_category: unique(
        "error.errorHandler.entityName.scProcessCategory",
        "scProcessCategory.detail.processCategory_input"
    ),
    uk_sc_material: unique("error.errorHandler.entityName.scMaterial", "error.errorHandler.fieldName.rawMaterial"),
    uk_sc_facility: unique("error.errorHandler.entityName.scFacility", "scFacility.detail.facility_input"),
    scope_certificate_is_in_non_deletable_status: "scopeCertificate.list.scope_certificate_is_in_non_deletable_status",
    pre_gtb_tc_is_in_non_deletable_status: "preGtbTransactionCertificate.list.pre_gtb_tc_is_in_non_deletable_status",
    scope_certificate_template_missing: "scopeCertificate.detail.sc_template_missing_message",
    uk_buying_relation_seller_buyer_standard: unique(
        "error.errorHandler.entityName.buyingRelation",
        "error.errorHandler.fieldName.seller_buyer_standard"
    ),
    uk_pre_gtb_tc_material: unique(
        "error.errorHandler.entityName.preGtbTcMaterial",
        "error.errorHandler.fieldName.rawMaterial"
    ),
    uk_organic_material_production_sc_sc_number: unique(
        "error.errorHandler.entityName.organicMaterialProductionSc",
        "error.errorHandler.fieldName.scNumber"
    ),
    file_is_not_word_document: "validation.fileIsNotDocx_message",
    file_is_not_image: "validation.fileIsNotImage_message",
    "file_is_not_PDF/A": "validation.fileIsNotPdf_message",
    cannot_detach_last_remaining: "error.errorHandler.cannotDetachLastRemaining_message",
    record_is_frozen: "error.errorHandler.cannotDeleteFrozenRecord_message",
    transaction_certificate_is_in_non_deletable_status:
        "transactionCertificate.list.transaction_certificate_is_in_non_deletable_status",
    transaction_certificate_template_missing: "transactionCertificate.detail.tc_template_missing_message",
    scoLicenceBanned: "error.errorHandler.scoLicenceBanned",
    preGtbTcProductNumberAlreadyExists: "error.errorHandler.preGtbTcProductNumberAlreadyExists",
};

export type validationErrorCode = keyof typeof validationErrors & string;
