import yup, { buildSchema, validateNumber, validateString } from "../../../../../../utils/yupExtension";
import { ScMaterialDetails } from "../scMaterialTypes";

export const scMaterialValidationSchema = buildSchema<ScMaterialDetails>(
    {
        material: validateString().isRequired(),
        minPercentage: validateNumber()
            .hasMinValue(0)
            .hasMaxValue(100)
            .when("maxPercentage", {
                is: (maxPercentage: number) => maxPercentage,
                then: (schema) =>
                    schema
                        .isRequired()
                        .isLessEqual(
                            yup.ref("maxPercentage"),
                            "scMaterial.detail.minPercentage_input",
                            "scMaterial.detail.maxPercentage_input"
                        ),
            }),
        maxPercentage: validateNumber()
            .hasMinValue(0)
            .hasMaxValue(100)
            .when("minPercentage", {
                is: (minPercentage: number) => minPercentage,
                then: (schema) =>
                    schema
                        .isRequired()
                        .isGreaterEqual(
                            yup.ref("minPercentage"),
                            "scMaterial.detail.maxPercentage_input",
                            "scMaterial.detail.minPercentage_input"
                        ),
            }),
    },
    /*
     * The excluded parameter is necessary here to prevent a cyclic dependency error between minPercentage and maxPercentage.
     * It is an array of pairs of fields that have a cyclic dependency, e.g. field x, y, z are required when one of them is filled.
     */
    [["minPercentage", "maxPercentage"]]
);
