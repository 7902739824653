import useAuth from "../../auth/useAuth";

function useUserAccountButton() {
    const { logout, currentUser } = useAuth();

    return {
        fullUsername: `${currentUser.givenName} ${currentUser.surname}`,
        roleName: currentUser.authorisationRole.name,
        logout,
    };
}

export default useUserAccountButton;
