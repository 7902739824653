import { useHelpContext } from "../help/context-sensitivity/useHelpContext";
import EntityListView from "../components/entity/EntityListView";
import useScopeCertificateListView from "./useScopeCertificateListView";
import useScopeCertificateSearchContext, {
    ScopeCertificateSearchContextSelection,
} from "./useScopeCertificateSearchContext";

export default function ScopeCertificateListView({ searchContext }: ScopeCertificateListViewProps) {
    const resolvedSearchContext = useScopeCertificateSearchContext(searchContext);
    const scopeCertificateGridOptions = useScopeCertificateListView(resolvedSearchContext);

    useHelpContext("scope-certificate-list");

    return (
        <EntityListView
            heading={resolvedSearchContext.navigationContextLabel}
            gridOptions={scopeCertificateGridOptions}
        />
    );
}

interface ScopeCertificateListViewProps {
    searchContext: ScopeCertificateSearchContextSelection;
}
