import { joinClassNames } from "../../utils/StringUtils";
import GtbTextField from "../../components/Input/GtbTextField";
import "./mailTemplateData.css";
import { MailTemplateDetailViewTabProps } from "./MailTemplateDetailView";
import GtbTextArea from "../../components/Input/GtbTextArea";

export default function MailTemplateData({ form, lng }: MailTemplateDetailViewTabProps) {
    return (
        <div className={joinClassNames("mailTemplateData", "formGrid")}>
            <GtbTextField
                {...form.registerWithErrors(`subject.${lng}`)}
                id={`subject.${lng}`}
                label="mailTemplate.detail.subject_input"
                className="subject"
            />
            <GtbTextArea
                minRows={25}
                maxRows={25}
                {...form.registerWithErrors(`body.${lng}`)}
                id={`body.${lng}`}
                label="mailTemplate.detail.body_input"
                className="body"
            />
        </div>
    );
}
