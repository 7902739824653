import useDetailsCreate from "../../hooks/details/useDetailsCreate";
import { yupResolver } from "@hookform/resolvers/yup";
import { Nullable } from "../../utils/typeUtils";
import { useMemo } from "react";
import { OrganicMaterialProductionScDetails } from "../organicMaterialProductionScTypes";
import { OrganicMaterialProductionScDetailViewProps } from "./OrganicMaterialProductionScDetailView";
import { organicMaterialProductionScValidationSchema } from "./organicMaterialProductionScValidationSchema";
import useResolvedRoute from "../../components/routing/useResolvedRoute";
import useValidateOnRelatedValueChange from "../../hooks/formHandling/useValidateOnRelatedValueChange";
import useDuplicateWarningDialogue from "./useDuplicateWarningDialogue";
import useConformanceCheck from "../../conformance/useConformanceCheck";

export default function useOrganicMaterialProductionScCreate(): OrganicMaterialProductionScDetailViewProps {
    const { getResolvedId } = useResolvedRoute();
    const showDuplicateWarningDialog = useDuplicateWarningDialogue();
    const handleConformanceCheck = useConformanceCheck<OrganicMaterialProductionScDetails>();

    const parentOmp = getResolvedId("organicMaterialProducer") ?? null;

    const initialData = useMemo<Nullable<OrganicMaterialProductionScDetails>>(
        () => ({
            id: null,
            scNumber: null,
            issuingBody: null,
            standard: null,
            organicMaterialProducer: parentOmp,
            totalFarmAreaUnderSc: null,
            validFrom: null,
            validUntil: null,
            scCopy: null,
            warningCanonicalEqualScNumberAndStandard: null,
        }),
        [parentOmp]
    );

    const readOnly: OrganicMaterialProductionScDetailViewProps["readOnly"] = useMemo(
        () => ({
            scNumber: false,
            issuingBody: false,
            standard: false,
            organicMaterialProducer: !!parentOmp,
            totalFarmAreaUnderSc: false,
            validFrom: false,
            validUntil: false,
            scCopy: false,
        }),
        [parentOmp]
    );

    const { detailViewProps, form, title } = useDetailsCreate<OrganicMaterialProductionScDetails>({
        title: "organicMaterialProductionSc.detail.newRecord_title",
        frontendRoute: "organicMaterialProductionSc",
        initialData,
        resolver: yupResolver(organicMaterialProductionScValidationSchema),
        saveUrl: "/certificate-service/organic-material-production-scs",
        afterCreation: (item) => {
            if (item.warningCanonicalEqualScNumberAndStandard) {
                showDuplicateWarningDialog();
            }
        },
        resolveErrors: (error, item, retrySaveAction) => handleConformanceCheck(error, item, retrySaveAction),
    });

    useValidateOnRelatedValueChange(form.form, "validUntil", "validFrom");

    return {
        title,
        detailViewProps,
        form,
        readOnly,
        createRoute: "organicMaterialProductionSc",
        scCopyUrl: "",
    };
}
