import { useCallback } from "react";
import { FileResource } from "../components/Input/UploadComponent/FileUploadComponent";

export default function useFileDownload() {
    return useCallback(
        (file: FileResource) =>
            fetch(`data:${file.type};base64,${file.base64Content}`)
                .then((response) => response.blob())
                .then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement("a");
                    a.href = url;
                    a.download = file.name;
                    a.type = file.type;
                    a.click();
                }),
        []
    );
}
