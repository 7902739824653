import { useCallback } from "react";
import useWarningDialog from "../../components/dialog/UseWarningDialog";
import useUnsavedChangesDialog from "../../components/detailView/useUnsavedChangesDialog";
import { UseGtbFormReturn } from "../../hooks/formHandling/useGtbForm";
import { ScopeCertificateDetails } from "../scopeCertificateTypes";
import { UseFormSubmitCallback } from "../../hooks/formHandling/useFormSubmit";
import { DialogOptions } from "../../components/dialog/DialogOptions";
import { useMutation } from "../../hooks/useAxios";
import { useGtbNavigate } from "../../components/routing/GtbRouter";
import useResolvedRoute from "../../components/routing/useResolvedRoute";
import { error } from "../../utils/notification/notification";
import useGtbTranslation from "../../i18n/useGtbTranslation";
import useLoadingDialog from "../../components/dialog/useLoadingDialog";

export default function useRecertifyScopeCertificate() {
    const { showDialog: _showDialog, closeDialog: _closeDialog } = useWarningDialog();
    const { showDialog: showUnsavedChangesDialog, closeDialog: closeUnsavedChangesDialog } = useUnsavedChangesDialog();
    const { showDialog: showLoadingDialog, closeDialog: closeLoadingDialog } = useLoadingDialog();
    const { runQuery: runRecertificationQuery } = useMutation({ method: "post", url: "" });
    const navigate = useGtbNavigate();
    const { getResolvedEditUrl } = useResolvedRoute();
    const translation = useGtbTranslation();

    const recertify = useCallback(
        (scopeCertificateId: string) => {
            showLoadingDialog("scopeCertificate.detail.recertifyDialog_title");
            runRecertificationQuery({
                url: `/certificate-service/scope-certificates/${scopeCertificateId}/recertification`,
            })
                .then((response: ScopeCertificateDetails) => {
                    closeLoadingDialog();
                    navigate(getResolvedEditUrl("scopeCertificate", response.id));
                })
                .catch((err) => {
                    closeLoadingDialog();
                    if (err?.statusCode === 412) {
                        error(translation("scopeCertificate.detail.recertifyPreconditionError_message"));
                    } else {
                        error(translation("error.misc.generic_error"));
                    }
                });
        },
        [closeLoadingDialog, getResolvedEditUrl, navigate, runRecertificationQuery, showLoadingDialog, translation]
    );

    const showRecertificationDialog = useCallback(
        (scopeCertificateId: string) => {
            _showDialog({
                title: "scopeCertificate.detail.recertifyDialog_title",
                message: "scopeCertificate.detail.recertifyDialog_message",
                footer: (
                    <DialogOptions
                        options={[
                            {
                                onClick: _closeDialog,
                                label: "components.dialog.cancel_button",
                            },
                            {
                                onClick: () => recertify(scopeCertificateId),
                                label: "components.dialog.ok_button",
                            },
                        ]}
                    />
                ),
            });
        },
        [_closeDialog, _showDialog, recertify]
    );

    return useCallback(
        (
            form: UseGtbFormReturn<ScopeCertificateDetails>,
            reloadData: () => void,
            onSubmit: UseFormSubmitCallback<ScopeCertificateDetails>,
            scopeCertificateId: string
        ) => {
            if (form.form.formState.isDirty) {
                showUnsavedChangesDialog(
                    () => {
                        closeUnsavedChangesDialog();
                        reloadData();
                        showRecertificationDialog(scopeCertificateId);
                    },
                    () => {
                        closeUnsavedChangesDialog();
                        onSubmit(() => showRecertificationDialog(scopeCertificateId));
                    }
                );
            } else {
                showRecertificationDialog(scopeCertificateId);
            }
        },
        [closeUnsavedChangesDialog, showRecertificationDialog, showUnsavedChangesDialog]
    );
}
