import { ComponentType, useMemo } from "react";
import { GridProps } from "../DataGrid";
import useInfiniteQuery from "../../../hooks/useInfiniteQuery";
import { Entity, RequireInheritedProps } from "../../../utils/typeUtils";

export interface WithInfinityScrollDataProps<ItemType extends Entity>
    extends RequireInheritedProps<GridProps<ItemType>, "query"> {
    limit?: number;
}

export function withInfinityScrollData<ItemType extends Entity>(
    Grid: ComponentType<GridProps<ItemType> & WithInfinityScrollDataProps<ItemType>>
) {
    return ({ query, limit = 300, ...rest }: GridProps<ItemType> & WithInfinityScrollDataProps<ItemType>) => {
        const { items: data, total, nextPageRef, isError, isLoading } = useInfiniteQuery<ItemType>(query, limit);

        const additionalBodyComponent = useMemo(() => {
            if (isLoading) {
                return null;
            }

            return <tbody className="invisibleGridBody" ref={nextPageRef} />;
        }, [isLoading, nextPageRef]);

        return (
            <Grid
                {...rest}
                query={query}
                data={data}
                isLoading={rest.isLoading || isLoading}
                isError={isError}
                total={total === null ? undefined : total}
                additionalBodyComponent={additionalBodyComponent}
            />
        );
    };
}
