import useIsAuthorised from "../auth/useIsAuthorised";
import { SortOption } from "../components/grid/component/useQueryBuilder";
import { useMemo } from "react";
import { GridColumn } from "../components/grid/component/Grid";
import useListView from "../hooks/useListView";
import { useDeleteAction, useShowDetailsAction } from "../utils/defaultActions";
import { ByproductInputCombinationDetails, ByproductInputCombinationSummary } from "./byproductInputCombinationTypes";
import { buildTextColumn } from "../components/grid/ColumnProvider";
import { EntityListViewGridProps } from "../components/entity/EntityListView";
import useResolvedRoute, { getCreateUrl } from "../components/routing/useResolvedRoute";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";

export const defaultByproductInputCombinationSortOption: SortOption<
    ByproductInputCombinationSummary | ByproductInputCombinationDetails
> = {
    field: "productDetailCode",
    direction: "ASC",
};

const columns: GridColumn<ByproductInputCombinationSummary>[] = [
    buildTextColumn({
        key: "productDetailCode",
        title: "byProductInputCombination.list.productDetailCode_column",
        minWidth: 205,
    }),
    buildTextColumn({
        key: "productDetailName",
        title: "byProductInputCombination.list.productDetailName_column",
        minWidth: 209,
    }),
    buildTextColumn({
        key: "processCategoryCode",
        title: "byProductInputCombination.list.processCategoryCode_column",
        minWidth: 232,
    }),
    buildTextColumn({
        key: "processCategoryName",
        title: "byProductInputCombination.list.processCategoryName_column",
        minWidth: 236,
    }),
];

function useByproductInputCombinationListView() {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedEditUrl } = useResolvedRoute();

    const {
        queryBuilder: { forceReload, columnOptions, query, queryCommands },
    } = useListView<ByproductInputCombinationSummary>({
        navigationContextLabel: "byProductInputCombination.breadcrumb",
        baseQuery: "/certificate-service/by-product-input-combinations",
        defaultSortOption: defaultByproductInputCombinationSortOption,
    });

    const showDetailsAction = useShowDetailsAction(
        (item: ByproductInputCombinationSummary) => getResolvedEditUrl("byproductInputCombination", item.id),
        query.parameter
    );

    const deleteAction = useDeleteAction(
        (item: ByproductInputCombinationSummary) => `/certificate-service/by-product-input-combinations/${item.id}`,
        () => forceReload(),
        "deleteMasterData"
    );

    const actions = useGridRowContextMenuActions(showDetailsAction, deleteAction);

    return useMemo<EntityListViewGridProps<ByproductInputCombinationSummary>>(() => {
        return {
            query,
            columns: columns,
            uniqueDataKey: "id" as keyof ByproductInputCombinationSummary,
            onItemDoubleClick: showDetailsAction,
            createUrl: hasAccessRight("createProductConversion")
                ? getCreateUrl("byproductInputCombination")
                : undefined,
            tableName: "byproductInputCombination",
            columnOptions,
            itemActions: actions,
            queryCommands,
            bulkDeleteAccessRight: "deleteMasterData",
        };
    }, [query, showDetailsAction, hasAccessRight, columnOptions, actions, queryCommands]);
}

export default useByproductInputCombinationListView;
