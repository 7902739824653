import { joinClassNames } from "../utils/StringUtils";
import GtbTextField from "../components/Input/GtbTextField";
import { UseGtbFormReturn } from "../hooks/formHandling/useGtbForm";
import { FieldValues } from "react-hook-form/dist/types";
import { FieldPath } from "react-hook-form";
import "./languageVersionTab.css";
import { getLanguageKeys, TranslatableValue } from "./languageTypes";
import { I18nKey } from "./useGtbTranslation";
import { TabKey, TabProps } from "../components/tabs/Tabs";

/**
 * Builds a component for displaying text fields for all defined languages for the given translatable fields.
 * @param form the form for registering the fields
 * @param readOnly if the fields are readonly
 * @param baseName this is uses as a className (${baseName}LanguageVersion) for the component and as the basename for the translation(${baseName}.detail.${field}_input)
 * @param fields all fields that should be translated
 * @constructor
 */
export default function LanguageVersionTab<ItemType extends FieldValues>({
    form,
    readOnly,
    baseName,
    fields,
}: LanguageVersionTabProps<ItemType>) {
    return (
        <div className={joinClassNames("languageVersion", `${baseName}LanguageVersion`, "formGrid")}>
            {fields.map((field) =>
                getLanguageKeys().map((lng) => (
                    <GtbTextField
                        key={lng}
                        {...form.registerWithErrors(`${field}.${lng}` as FieldPath<ItemType>)}
                        className={field}
                        readOnly={readOnly}
                        label={{
                            key: "translation.languageVersionFor_input",
                            options: {
                                languageKey: "translation.language." + lng,
                                inputKey: `${baseName}.detail.${field}_input`,
                            },
                        }}
                    />
                ))
            )}
        </div>
    );
}

type TranslatableValueField<ItemType> = keyof {
    [R in keyof ItemType as ItemType[R] extends TranslatableValue ? R : never]: any;
};

export interface LanguageVersionTabProps<ItemType extends FieldValues> {
    form: UseGtbFormReturn<ItemType>;
    fields: (TranslatableValueField<ItemType> & string)[];
    baseName: I18nKey;
    readOnly: boolean;
}

export const buildLanguageVersionTab = <ItemType extends FieldValues>(
    tabProps: LanguageVersionTabProps<ItemType>
): TabProps<TabKey<"translation.languageVersion_tab">> => ({
    heading: "translation.languageVersion_tab",
    element: <LanguageVersionTab {...tabProps} />,
});
