import useTcProductSubList from "./useTcProductSubList";
import { joinClassNames } from "../../../utils/StringUtils";
import CertificationBodyPickList from "../../../components/Input/pickList/typedPicklist/CertificationBodyPickList";
import SupplyChainOperatorPickList from "../../../components/Input/pickList/typedPicklist/SupplyChainOperatorPickList";
import InfinityScrollSubList from "../../../components/grid/InfinityScrollSubList";
import { UseGtbFormReturn } from "../../../hooks/formHandling/useGtbForm";
import { TransactionCertificateDetails } from "../../transactionCertificateTypes";
import "./tcProducts.css";
import { TransactionCertificateStatusType } from "../../../auth/roleTypes";

export default function TcProducts({
    form,
    transactionCertificateStatus,
}: {
    form: UseGtbFormReturn<TransactionCertificateDetails>;
    transactionCertificateStatus?: TransactionCertificateStatusType;
}) {
    const tcProductSubList = useTcProductSubList(transactionCertificateStatus);

    return (
        <div className={joinClassNames("tcProducts", "formGrid")}>
            <CertificationBodyPickList
                label="transactionCertificate.detail.certificationBody_input"
                control={form.form.control}
                name="certificationBody"
                className="certificationBody"
                readOnly
            />
            <SupplyChainOperatorPickList
                label="transactionCertificate.detail.seller_input"
                control={form.form.control}
                name="seller"
                className="seller"
                readOnly
            />
            <SupplyChainOperatorPickList
                label="transactionCertificate.detail.buyer_input"
                control={form.form.control}
                name="buyer"
                className="buyer"
                readOnly
            />
            <InfinityScrollSubList {...tcProductSubList} className="tcProductSubList" />
        </div>
    );
}
