import { joinClassNames } from "../../utils/StringUtils";
import GtbTextField from "../../components/Input/GtbTextField";
import "./supplyChainOperatorBasicData.css";
import { SupplyChainOperatorDetailViewTabProps } from "./SupplyChainOperatorDetailView";
import ControlledDatePicker from "../../components/Input/datepicker/ControlledDatePicker";

function SupplyChainOperatorBasicData({
    form,
    readOnlyProps,
    requiresLegalNameInEnglish,
    reducedAccess,
}: SupplyChainOperatorDetailViewTabProps) {
    return (
        <div
            className={joinClassNames(
                "supplyChainOperatorBasicData",
                "organisationBasicData",
                "formGrid",
                reducedAccess ? "reducedAccess" : undefined
            )}
        >
            <GtbTextField
                {...form.registerWithErrors("legalName")}
                readOnly={readOnlyProps.isLegalNameReadOnly}
                label="supplyChainOperator.detail.legalName_input"
                className="legalName"
            />
            {requiresLegalNameInEnglish && (
                <GtbTextField
                    {...form.registerWithErrors("legalNameInEnglish")}
                    readOnly={readOnlyProps.isLegalNameInEnglishReadOnly}
                    label={"supplyChainOperator.detail.legalNameInEnglish_input"}
                    className={"legalNameInEnglish"}
                />
            )}
            <GtbTextField
                {...form.registerWithErrors("businessName")}
                readOnly={readOnlyProps.isBusinessNameReadOnly}
                label="supplyChainOperator.detail.businessName_input"
                className="businessName"
            />
            {!reducedAccess && (
                <GtbTextField
                    {...form.registerWithErrors("website")}
                    readOnly={readOnlyProps.isWebsiteReadOnly}
                    label="supplyChainOperator.detail.website_input"
                    className="website"
                />
            )}
            <GtbTextField
                {...form.registerWithErrors("organisationalEmail")}
                readOnly={readOnlyProps.isOrganisationalEmailReadOnly}
                label="supplyChainOperator.detail.organisationalEmailAddress_input"
                className="organisationalEmail"
            />
            {!reducedAccess && (
                <>
                    <GtbTextField
                        {...form.registerWithErrors("organisationalPhoneNumber")}
                        readOnly={readOnlyProps.isOrganisationalPhoneNumberReadOnly}
                        label="supplyChainOperator.detail.organisationalPhoneNumber_input"
                        className="organisationalPhoneNumber"
                    />
                    <GtbTextField
                        {...form.registerWithErrors("publicContactPersonName")}
                        readOnly={readOnlyProps.isPublicContactPersonNameReadOnly}
                        label="supplyChainOperator.detail.publicContactPersonName_input"
                        className="publicContactPersonName"
                    />
                    <GtbTextField
                        {...form.registerWithErrors("publicContactPersonEmail")}
                        readOnly={readOnlyProps.isPublicContactPersonEmailReadOnly}
                        label="supplyChainOperator.detail.publicContactPersonEmail_input"
                        className="publicContactPersonEmail"
                    />
                    <GtbTextField
                        {...form.registerWithErrors("organisationalContactPersonName")}
                        readOnly={readOnlyProps.isOrganisationalContactPersonNameReadOnly}
                        label="supplyChainOperator.detail.organisationalContactPersonName_input"
                        className="organisationalContactPersonName"
                    />
                    <GtbTextField
                        {...form.registerWithErrors("organisationalContactPersonEmail")}
                        readOnly={readOnlyProps.isOrganisationalContactPersonEmailReadOnly}
                        label="supplyChainOperator.detail.organisationalContactPersonEmail_input"
                        className="organisationalContactPersonEmail"
                    />
                    <ControlledDatePicker
                        control={form.form.control}
                        readOnly={readOnlyProps.isStartDateReadOnly}
                        name="startDate"
                        label="supplyChainOperator.detail.startDate_input"
                        className="startDate"
                    />
                    <ControlledDatePicker
                        control={form.form.control}
                        readOnly={readOnlyProps.isEndDateReadOnly}
                        name="endDate"
                        label="supplyChainOperator.detail.endDate_input"
                        className="endDate"
                    />
                    <GtbTextField
                        {...form.registerWithErrors("organisationStatus")}
                        readOnly
                        label="supplyChainOperator.detail.status_input"
                        className="status"
                    />
                </>
            )}
        </div>
    );
}

export default SupplyChainOperatorBasicData;
