import { CertificationLicenceDetails } from "../certificationLicenceTypes";
import useDetailsCreate from "../../hooks/details/useDetailsCreate";
import { yupResolver } from "@hookform/resolvers/yup";
import { certificationLicenceValidationSchema } from "./certificationLicenceValidationSchema";
import { useQuery } from "../../hooks/useAxios";
import { Nullable } from "../../utils/typeUtils";
import {
    CertificationLicenceDetailViewProps,
    CertificationLicenceReadOnlyProps,
} from "./CertificationLicenceDetailView";
import useResolvedRoute from "../../components/routing/useResolvedRoute";
import { CertificationBodyDetails } from "../../certificationBody/certificationBodyTypes";

const initialData: Nullable<CertificationLicenceDetails> = {
    id: null,
    standard: null,
    licencingCode: null,
    startDate: new Date(),
    endDate: null,
};

export default function useCertificationLicenceCreate(): CertificationLicenceDetailViewProps {
    const { getResolvedId } = useResolvedRoute();
    const certificationBodyId = getResolvedId("certificationBody");

    const { data: certificationBody } = useQuery<CertificationBodyDetails>({
        url: "/certificate-service/references/certification-bodies/" + certificationBodyId,
    });

    const { detailViewProps, form, title } = useDetailsCreate<CertificationLicenceDetails>({
        title: "certificationLicence.detail.newRecord_title",
        frontendRoute: "certificationLicence",
        initialData,
        resolver: yupResolver(certificationLicenceValidationSchema),
        saveUrl: "/certificate-service/certification-bodies/" + certificationBodyId + "/certification-licences",
    });

    const readOnlyProps: CertificationLicenceReadOnlyProps = {
        isStandardReadOnly: false,
        isLicencingCodeReadOnly: false,
        isStartDateReadOnly: false,
        isEndDateReadOnly: false,
    };

    return {
        detailViewProps,
        form,
        title,
        certificationBodyName: `${certificationBody?.businessName}`,
        readOnlyProps,
        createRoute: "certificationLicence",
    };
}
