import useFormSubmit, { UseFormAfterSubmitAction, UseFormErrorResolver } from "../formHandling/useFormSubmit";
import useGtbForm from "../formHandling/useGtbForm";
import { useCallback, useEffect, useMemo, useState } from "react";
import { UseDetailViewProps } from "../../components/detailView/useDetailView";
import { FieldValues, Resolver } from "react-hook-form/dist/types";
import { axiosMutationType, backendUrlType, useMutation } from "../useAxios";
import { Nullable } from "../../utils/typeUtils";

export interface UseDetailsProps<ItemType extends FieldValues> {
    initialData?: Nullable<ItemType>;
    afterSubmitAction: UseFormAfterSubmitAction<ItemType>;
    resolveErrors?: UseFormErrorResolver<ItemType>;
    resolver?: Resolver<ItemType>;
    saveUrl: backendUrlType;
    canSave: boolean;
    saveMethod: axiosMutationType;
    isInitialDataLoading: boolean;
    reloadDetails?: () => void;
}

function useDetails<ItemType extends FieldValues>({
    initialData,
    afterSubmitAction,
    resolver,
    saveUrl,
    canSave,
    saveMethod,
    isInitialDataLoading,
    reloadDetails,
    resolveErrors,
}: UseDetailsProps<ItemType>) {
    const { runQuery: saveDetails, isLoading: isSubmitting } = useMutation<ItemType>({
        method: saveMethod,
        url: canSave ? saveUrl : "",
    });

    const [isFormLoaded, setIsFormLoaded] = useState(false);

    const form = useGtbForm<ItemType>({ resolver });

    useEffect(() => {
        // If initialData is reloaded (using recordNavigation)
        if (isInitialDataLoading) {
            setIsFormLoaded(false);
        }
    }, [isInitialDataLoading]);

    const resetForm = useCallback(() => {
        if (initialData) {
            form.form.reset(initialData as ItemType);
            setIsFormLoaded(true);
        }
    }, [form.form, initialData]);

    useEffect(() => {
        // This useEffect in combination with the additional boolean isFormLoaded has to be in place because of the tickets
        // GTB-2054 and
        // GTB-2130
        // other solutions lead to race conditions
        resetForm();
    }, [resetForm]);

    const isLoading = useMemo(() => {
        return isSubmitting || isInitialDataLoading || !isFormLoaded;
    }, [isFormLoaded, isInitialDataLoading, isSubmitting]);

    const onSubmit = useFormSubmit<ItemType>(form, saveDetails);

    const detailViewProps = useMemo<UseDetailViewProps<ItemType>>(() => {
        return {
            onSubmit: canSave ? onSubmit : undefined,
            isLoading,
            afterSubmitAction,
            resolveErrors,
            isDirty: form.form.formState.isDirty,
            reloadData: reloadDetails ? reloadDetails : resetForm,
        };
    }, [
        afterSubmitAction,
        canSave,
        form.form.formState.isDirty,
        isLoading,
        onSubmit,
        reloadDetails,
        resetForm,
        resolveErrors,
    ]);

    return { detailViewProps, form };
}

export default useDetails;

export type viewTypeType = "edit" | "create";
