import { SupplyChainOperatorSummary } from "./supplyChainOperatorTypes";
import { useDeduplicateAction } from "../utils/defaultActions";

export default function useSupplyChainOperatorDeduplicateAction(afterDeduplicationAction: () => void) {
    return useDeduplicateAction(
        (item: SupplyChainOperatorSummary) => item.id,
        "/certificate-service/supply-chain-operators/reactive-deduplication",
        "sco",
        afterDeduplicationAction,
        1
    );
}
