import useExternalIdEdit from "../../details/useExternalIdEdit";
import useResolvedRoute, { routeEnum } from "../../../components/routing/useResolvedRoute";

export default function useExternalSupplyChainOperatorIdEdit() {
    const { getResolvedId } = useResolvedRoute();

    const routeEnumKey: routeEnum = getResolvedId("externalSupplyChainOperatorId")
        ? "externalSupplyChainOperatorId"
        : "supplyChainOperatorExternalSupplyChainOperatorId";

    return useExternalIdEdit({
        i18nKey: "externalSupplyChainOperatorId",
        routeEnumKey,
        recordNavigationParam:
            routeEnumKey === "externalSupplyChainOperatorId" ? "typeOfExternalIdParentEntity" : "parentId",
        recordNavigationParamValue:
            routeEnumKey === "externalSupplyChainOperatorId"
                ? "SUPPLY_CHAIN_OPERATOR"
                : getResolvedId("supplyChainOperator")!,
    });
}
