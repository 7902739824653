import DataPickList from "../DataPickList";
import { TypedPicklist } from "./typedPicklistType";
import { joinClassNames } from "../../../../utils/StringUtils";

import "./typedPickList.css";
import { AuthorisationRoleReference } from "../../../../authorisationRole/authorisationRoleTypes";
import { FieldValues } from "react-hook-form/dist/types";

function AuthorisationRolePickList<FormItemType extends FieldValues>({
    className,
    ...rest
}: TypedPicklist<FormItemType, AuthorisationRoleReference>) {
    return (
        <DataPickList
            className={joinClassNames("authorisationRolePickList", className)}
            itemId="id"
            itemLabel="name"
            queryUrl="/certificate-service/references/roles"
            {...rest}
        />
    );
}

export default AuthorisationRolePickList;
