import { UseGtbFormReturn } from "../../hooks/formHandling/useGtbForm";
import { OrganisationDetails } from "../organisationTypes";
import { Path, useWatch } from "react-hook-form";
import { useMemo } from "react";
import useAuth from "../../auth/useAuth";
import GtbButton from "../../components/GtbButton";
import useGtbTranslation from "../../i18n/useGtbTranslation";
import useAcceptGoogleTermsOfUse from "../../user/termsOfUse/useAcceptGoogleTermsOfUse";
import GoogleMap from "../../components/GoogleMap";

export default function OrganisationMap<T extends OrganisationDetails>({
    control,
}: {
    control: UseGtbFormReturn<T>["form"]["control"];
}) {
    const translation = useGtbTranslation();
    const {
        currentUser: { acceptedGoogleToU },
    } = useAuth();

    const [latitude, longitude] = useWatch<T>({
        control,
        name: ["latitude", "longitude"] as Path<T>[],
    });

    const content = useMemo(() => {
        if (
            typeof latitude !== "number" ||
            typeof longitude !== "number" ||
            Math.abs(latitude) > 90 ||
            Math.abs(longitude) > 180
        ) {
            return <span role="status">{translation("components.locationMap.noValidCoordinates_message")}</span>;
        }
        if (!acceptedGoogleToU) {
            return <AcceptGoogleToUButton />;
        }
        return <GoogleMap latitude={latitude} longitude={longitude} />;
    }, [acceptedGoogleToU, latitude, longitude, translation]);

    return <div className="mapWrapper">{content}</div>;
}

function AcceptGoogleToUButton() {
    const translation = useGtbTranslation();

    const showAcceptGoogleTouDialog = useAcceptGoogleTermsOfUse();
    return (
        <GtbButton onClick={showAcceptGoogleTouDialog}>
            {translation("components.locationMap.showGoogleUserConsent_button")}
        </GtbButton>
    );
}
