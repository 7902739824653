import { TypedPicklist } from "./typedPicklistType";
import DataPickList from "../DataPickList";
import { ProcessCategoryReference } from "../../../../processCategory/processCategoryTypes";
import { joinClassNames } from "../../../../utils/StringUtils";

import "./typedPickList.css";
import { FieldValues } from "react-hook-form/dist/types";

function ProcessCategoryPickList<FormItemType extends FieldValues>({
    className,
    ...rest
}: TypedPicklist<FormItemType, ProcessCategoryReference>) {
    return (
        <DataPickList
            className={joinClassNames("processCategoryPickList", className)}
            itemId="id"
            itemLabel={(option) => `${option.name} (${option.code})`}
            queryUrl="/certificate-service/references/process-categories"
            {...rest}
        />
    );
}

export default ProcessCategoryPickList;
