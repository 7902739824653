import Tile from "./Tile";
import {
    onDragLeave,
    onDragOver,
    removeStyleOnDragEnd,
    setStyleOnDragStart,
} from "../../components/dragDrop/SortableDropContainer";
import { setTilePositionFunc, TileSettings } from "./useDashboard";
import { DragEvent, useCallback, useRef } from "react";

export default function DashboardGrid({
    maxPosition,
    tiles,
    setTilePosition,
}: {
    maxPosition: number;
    tiles: TileSettings[];
    setTilePosition: setTilePositionFunc;
}) {
    const draggedTile = useRef<TileSettings>();

    const onDragStart = useCallback((e: DragEvent, tile: TileSettings) => {
        setStyleOnDragStart(e);
        e.currentTarget.addEventListener("dragover", onDragOver as unknown as EventListener);
        e.currentTarget.addEventListener("dragleave", onDragLeave as unknown as EventListener);
        draggedTile.current = tile;
    }, []);

    const onDrop = useCallback(
        (e: DragEvent, position: number) => {
            e.preventDefault();
            setTilePosition(draggedTile.current!, position);
        },
        [setTilePosition]
    );

    return (
        <div className="dashboardGrid">
            {new Array(maxPosition).fill(undefined).map((_v, index) => {
                const tile = tiles.filter((t) => !t.hidden).find((t) => t.position === index);
                if (tile) {
                    return (
                        <Tile
                            key={index}
                            {...tile}
                            draggable={true}
                            onDragStart={(e) => onDragStart(e, tile)}
                            onDragEnd={onDragEnd}
                        />
                    );
                }
                return (
                    <div
                        key={index}
                        className="dropSpot"
                        onDragOver={onDragOver}
                        onDragLeave={onDragLeave}
                        onDrop={(e) => onDrop(e, index)}
                    />
                );
            })}
        </div>
    );
}

const onDragEnd = (e: DragEvent) => {
    removeStyleOnDragEnd(e);
    e.currentTarget.removeEventListener("dragover", onDragOver as unknown as EventListener);
    e.currentTarget.removeEventListener("dragleave", onDragLeave as unknown as EventListener);
};
