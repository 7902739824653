import useDeduplicationLogList from "./useDeduplicationLogList";
import { useHelpContext } from "../help/context-sensitivity/useHelpContext";
import EntityListView, { EntityListViewGridProps } from "../components/entity/EntityListView";
import { DeduplicationLogSummary } from "./deduplicationLogTypes";

function DeduplicationLogListView() {
    const { listProps } = useDeduplicationLogList();
    useHelpContext("deduplication-log-list");
    return <EntityListView heading={"deduplicationLog.list.header"} gridOptions={listProps} />;
}

export default DeduplicationLogListView;

export interface DeduplicationLogListViewProps {
    listProps: EntityListViewGridProps<DeduplicationLogSummary>;
}
