import { joinClassNames } from "../../utils/StringUtils";
import GtbTextField from "../../components/Input/GtbTextField";
import "./timeBasedMailData.css";
import { TimeBasedMailDetailViewTabProps } from "./TimeBasedMailDetailView";
import GtbTextArea from "../../components/Input/GtbTextArea";
import ControlledDatePicker from "../../components/Input/datepicker/ControlledDatePicker";
import ControlledTimePicker from "../../components/Input/timepicker/ControlledTimePicker";
import { LabeledCheckbox } from "../../components/Input/Checkbox";
import DynamicUserGroupPickList from "../../components/Input/pickList/typedPicklist/DynamicUserGroupPicklist";

export default function TimeBasedMailData({ form, readOnly }: TimeBasedMailDetailViewTabProps) {
    return (
        <div className={joinClassNames("timeBasedMailData", "formGrid")}>
            <GtbTextField
                {...form.registerWithErrors("subject")}
                readOnly={readOnly}
                id={"subject"}
                label="timeBasedMail.detail.subject_input"
                className="subject"
            />
            <ControlledDatePicker
                control={form.form.control}
                readOnly={readOnly}
                name="targetDate"
                label="timeBasedMail.detail.targetDate_input"
                className="targetDate"
            />
            <ControlledTimePicker
                control={form.form.control}
                readOnly={readOnly}
                name="targetTime"
                label="timeBasedMail.detail.targetTime_input"
                className="targetTime"
            />
            <LabeledCheckbox
                {...form.form.register("sent")}
                readOnly
                id={"sent"}
                label={"timeBasedMail.detail.sent_input"}
                className={"sent"}
            />
            <GtbTextArea
                {...form.registerWithErrors("body")}
                readOnly={readOnly}
                minRows={25}
                maxRows={25}
                id={"body"}
                label="timeBasedMail.detail.body_input"
                className="body"
            />
            <DynamicUserGroupPickList
                label="timeBasedMail.detail.userGroup_input"
                control={form.form.control}
                name="dynamicUserGroup"
                className="userGroup"
                readOnly={readOnly}
            />
        </div>
    );
}
