export const asUTC = (date: Date | null | string | number | undefined): Date | null => {
    if (!date) {
        return null;
    }

    if (!/^\d{4}-\d{2}-\d{2}$/.test(date.toString())) {
        return new Date(date);
    }
    const utc = new Date(date);
    return new Date(utc.getUTCFullYear(), utc.getUTCMonth(), utc.getUTCDate());
};
