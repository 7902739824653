import { useHelpContext } from "../help/context-sensitivity/useHelpContext";
import EntityListView from "../components/entity/EntityListView";
import useScoLicenceListView from "./useScoLicenceListView";

export default function ScoLicenceListView() {
    const scoLicenceGridOptions = useScoLicenceListView();
    useHelpContext("sco-licence-list");

    return <EntityListView heading="scoLicence.list.header" gridOptions={scoLicenceGridOptions} />;
}
