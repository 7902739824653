import StringFilter, { FilterValidator, StringFilterProps } from "./StringFilter";
import { useCallback } from "react";
import { splitFilterByOr } from "./filterUtils";
import { asUTC } from "../../../../utils/dateUtils";

export default function DateFilter<ItemType>({ ...rest }: Omit<StringFilterProps<ItemType>, "filterValidator">) {
    const validator = useCallback<FilterValidator>((value) => {
        const month = "((0[1-9])|1[0-2])";
        const date = `\\d{4}(-${month}(-\\d{2})?)?`;
        const operators = "(before|after|[<>]?=?|<>)";
        if (
            !splitFilterByOr(value).every((part) =>
                // HAS TO MATCH REGEX IN DateFilterValue.java
                new RegExp(`^\\s*((NULL!)|(${operators}\\s*${date})|(between\\s+${date}\\s+and\\s+${date}))\\s*$`).test(
                    part
                )
            )
        ) {
            return "error.grid.noValidDateFilter_message";
        }
        const firstInvalidDate = (value?.match(new RegExp(date, "g")) ?? [])
            .filter((part) => part?.length === 10)
            .find(
                (dateToParse) =>
                    isNaN(new Date(dateToParse).getTime()) ||
                    !dateToParse.endsWith(asUTC(dateToParse)!.getDate().toString())
            );
        if (firstInvalidDate) {
            return { key: "error.grid.noValidDateValueInFilter_message", options: { date: firstInvalidDate } };
        }
        return undefined;
    }, []);

    return <StringFilter filterValidator={validator} {...rest} />;
}
