import { MouseEventHandler } from "react";
import GtbButton from "../GtbButton";
import UseConfirmationDialog, { workInProgressDialogOptions } from "../dialog/UseConfirmationDialog";
import { useSetDropdownStore } from "./dropdownStore";
import DropdownMenuItem, { DropdownMenuItemProps } from "./DropdownMenuItem";

export interface DropdownItemProps extends DropdownMenuItemProps {
    onClick?: MouseEventHandler<HTMLButtonElement>;
    className?: string;
}

function DropdownItem({ className, onClick, children }: DropdownItemProps) {
    const showDialog = UseConfirmationDialog();
    const setDropdownStore = useSetDropdownStore();

    return (
        <DropdownMenuItem>
            <GtbButton
                className={className}
                variant="tertiary"
                onClick={(event) => {
                    setDropdownStore(false);
                    if (onClick) {
                        onClick(event);
                    } else {
                        showDialog(workInProgressDialogOptions);
                    }
                }}
                tabIndex={-1}
            >
                {children}
            </GtbButton>
        </DropdownMenuItem>
    );
}

export default DropdownItem;
