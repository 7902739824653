import useNavigatableDetailsEdit from "../../hooks/details/useNavigatableDetailsEdit";
import { DeduplicationLogEditViewProps } from "./DeduplicationLogEditView";
import { formatTimestamp } from "../../utils/formatter";
import useResolvedRoute from "../../components/routing/useResolvedRoute";
import { DeduplicationLogDetails } from "../deduplicationLogTypes";
import { defaultDeduplicationLogSortOption } from "../useDeduplicationLogList";

function useDeduplicationLogEdit(): DeduplicationLogEditViewProps {
    const { getResolvedId } = useResolvedRoute();
    const deduplicationLogId = getResolvedId("deduplicationLog");

    const { detailViewProps, form, title, recordNavigationProps } = useNavigatableDetailsEdit<DeduplicationLogDetails>({
        baseUrl: "/log-service/deduplicationlogs/" + deduplicationLogId,
        resolver: undefined,
        titleBuilder: (item) => `${formatTimestamp(item.timestamp)}`,
        canSave: false,
        recordNavigationDefaultSort: defaultDeduplicationLogSortOption,
        frontendRoute: "deduplicationLog",
    });

    return {
        detailViewProps,
        form,
        title,
        recordNavigationProps,
    };
}

export default useDeduplicationLogEdit;
