import { scopeCertificateStatusType } from "../auth/roleTypes";

export interface ScopeCertificateSummary {
    id: string;
    scNumber: string;
    scRevisionNumber: string;
    supplyChainOperatorBusinessName: string;
    standardAcronymVersionNumber: string;
    revisionNumber: number;
    status: scopeCertificateStatusType;
    validFrom: Date;
    validUntil: Date;
    cbScReferenceNumber: string;
    dateOfIssue: Date;
    placeOfIssue: string;
    recertificationExpected: boolean;
    recertificationExpiryDate: Date;
    additionalDeclaration: string;
    certificateOrigin: originationOfCertificatesTypeEnum;
    certificationBodyBusinessName: string;
}

export interface ScopeCertificateDetails {
    id: string;
    scRevisionNumber: string;
    supplyChainOperator: string;
    scNumber: string;
    revisionNumber: number;
    revisionId: string | null;
    standardVersion: string;
    certificationBody: string;
    cbScReferenceNumber: string;
    validFrom: Date;
    validUntil: Date;
    status: scopeCertificateStatusType;
    dateOfIssue: Date;
    placeOfIssue: string;
    approvingPerson: string;
    recertificationExpected: boolean;
    recertificationExpiryDate: Date;
    additionalDeclaration: string;
    certificateOrigin: originationOfCertificatesTypeEnum;
    certificateDocument: string | null;
    cbCertificate: string | null;
    documentHash: string;
    hashAlgorithm: string;
    documentSize: number;
    recertifyable: boolean;
}

export interface ScopeCertificateReference
    extends Pick<
        ScopeCertificateDetails & ScopeCertificateSummary,
        "id" | "standardAcronymVersionNumber" | "scNumber" | "scRevisionNumber"
    > {
    supplyChainOperatorBusinessName: string;
}

export type revisionButtonVisibilityType = "hidden" | "disabled" | "enabled";

export const scopeCertificateReadOnlyByStatus = (status?: scopeCertificateStatusType): boolean => {
    return status !== "DRAFT" && status !== "REJECTED" && status !== "AWAITING_APPROVAL";
};

export type originationOfCertificatesTypeEnum = "THIS_DATABASE" | "CBS_DATABASE";
