import _ from "lodash";
import { useQuery } from "../../hooks/useAxios";

export const useReadHelpPage = (topic: string | undefined) => {
    const topicURI = topic ? encodeURIComponent(topic) : topic;

    const {
        status,
        isLoading,
        data: helpPageData,
    } = useQuery<HelpPageContent>({
        url: `/certificate-service/help-pages?topic=${topicURI}`,
        enabled: !_.isEmpty(topic),
    });

    return {
        status,
        isLoading,
        helpPageData,
    };
};

export interface HelpPageContent {
    title: string;
    content: string;
}
