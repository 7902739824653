import { FieldValues } from "react-hook-form/dist/types";
import { useChangeNavigationContext } from "../../components/breadcrumb/useNavigationContext";
import { useLocation } from "react-router-dom";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { backendUrlType, useQuery } from "../useAxios";
import useDetails, { UseDetailsProps, viewTypeType } from "./useDetails";
import { getBaseUrl, getEditUrl, routeEnum } from "../../components/routing/useResolvedRoute";
import { useGtbNavigate } from "../../components/routing/GtbRouter";
import { I18nKey } from "../../i18n/useGtbTranslation";

export interface UseDetailEditProps<ItemType extends FieldValues> {
    baseUrl: backendUrlType;
    frontendRoute: routeEnum;
    titleBuilder: (item: ItemType) => string;
    canSave: boolean | ((item: ItemType) => boolean);
    resolver: UseDetailsProps<ItemType>["resolver"];
    afterSaveAction?: UseDetailsProps<ItemType>["afterSubmitAction"];
    resolveErrors?: UseDetailsProps<ItemType>["resolveErrors"];
}

export default function useDetailsEdit<ItemType extends FieldValues>({
    baseUrl,
    titleBuilder,
    frontendRoute,
    canSave: _canSave,
    resolver,
    afterSaveAction,
    resolveErrors,
}: UseDetailEditProps<ItemType>) {
    const { pushNavigationContext } = useChangeNavigationContext();
    const { state } = useLocation();
    const _titleBuilder = useRef(titleBuilder);
    const navigate = useGtbNavigate(true);

    const onError = useCallback(() => navigate(getBaseUrl("errorNotFound")), [navigate]);

    const {
        data: initialData,
        isLoading,
        runQuery: reloadDetails,
    } = useQuery<ItemType>({
        url: baseUrl,
        onError,
    });

    const canSave = useMemo(() => {
        if (typeof _canSave === "function") {
            if (initialData) {
                return _canSave(initialData);
            }
            return false;
        }
        return _canSave;
    }, [_canSave, initialData]);

    useEffect(() => {
        if (!initialData) {
            return;
        }
        pushNavigationContext(_titleBuilder.current(initialData) as I18nKey, getEditUrl(frontendRoute), state);
    }, [frontendRoute, initialData, pushNavigationContext, state]);

    const afterSubmitAction = useCallback(
        (item: ItemType) => {
            reloadDetails()
                .then(() => afterSaveAction?.(item))
                .catch(console.error);
        },
        [afterSaveAction, reloadDetails]
    );

    const { detailViewProps, form } = useDetails<ItemType>({
        resolver,
        saveUrl: baseUrl,
        canSave,
        saveMethod: "put",
        initialData,
        afterSubmitAction,
        isInitialDataLoading: isLoading,
        reloadDetails,
        resolveErrors,
    });

    return useMemo(() => {
        return {
            detailViewProps,
            form,
            initialData,
            title: initialData ? (_titleBuilder.current(initialData) as I18nKey) : undefined,
            isReadOnly: !canSave,
            viewType: "edit" as viewTypeType,
        };
    }, [canSave, detailViewProps, form, initialData]);
}
