import Icon from "./Icon";
import "./spinner.css";
import useGtbTranslation, { I18nKey } from "../i18n/useGtbTranslation";
import { joinClassNames } from "../utils/StringUtils";

function LoadingSpinner({
    label = "components.spinner.accessibleLabel",
    size = 80,
    asFullScreen = false,
}: {
    label?: I18nKey;
    size?: number;
    asFullScreen?: boolean;
}) {
    const translation = useGtbTranslation();

    return (
        <div
            className={joinClassNames(asFullScreen ? "fullscreenSpinner" : null, "spinner")}
            role="progressbar"
            aria-label={translation(label)}
        >
            <Icon name="logo" size={size} />
        </div>
    );
}

export default LoadingSpinner;
