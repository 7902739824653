import useResolvedRoute, { getIdName } from "../../../../../components/routing/useResolvedRoute";
import "./tcProductMapView.css";
import LoadingSpinner from "../../../../../components/LoadingSpinner";
import useGtbTranslation from "../../../../../i18n/useGtbTranslation";
import GoBackButton from "../../../../../components/GoBackButton";
import { GridRowContextMenuAction } from "../../../../../components/grid/component/cell/GridRowContextMenu";
import useTcProductMap from "./useTcProductMap";
import { useHelpContext } from "../../../../../help/context-sensitivity/useHelpContext";
import { ReactNode, useMemo } from "react";
import WorldMap, { latLonToEquirectangular } from "../../../../../components/worldMap/WorldMap";
import { InputMaterialProduction, InputPreGtbProduct, TcProductTrackingInformation } from "../../tcProductTypes";
import { SvgCoordinate } from "../../../../../components/worldMap/SvgMarker";
import TrackingInfo from "./TrackingInfo";
import { groupByProp } from "../../../../../utils/arrayUtils";
import TcProductTrackingPath, { TcProductTrackingPathProps } from "./TcProductTrackingPath";

type MarkerType = "ROOT_PRODUCT" | "INPUT_PRODUCT" | "INPUT_PRE_GTB_PRODUCT" | "INPUT_PRODUCTION";

export type TrackingNode = {
    type: MarkerType;
    coordinate: SvgCoordinate;
    parent?: SvgCoordinate;
} & TypedTrackingNode;

export type TypedTrackingNode =
    | ({ type: "ROOT_PRODUCT" | "INPUT_PRODUCT" } & TcProductTrackingInformation)
    | ({ type: "INPUT_PRE_GTB_PRODUCT" } & InputPreGtbProduct)
    | ({ type: "INPUT_PRODUCTION" } & InputMaterialProduction);

function buildTrackingNode(info: TypedTrackingNode, parent?: TrackingNode): TrackingNode {
    return {
        ...info,
        coordinate: latLonToEquirectangular(info.latitude, info.longitude),
        parent: parent?.coordinate,
    };
}

const traversePreGtbProduct = (product: InputPreGtbProduct, parent?: TrackingNode): TrackingNode[] => {
    const node = buildTrackingNode({ type: "INPUT_PRE_GTB_PRODUCT", ...product }, parent);
    return [
        node,
        ...product.materialProductions.map((p) => buildTrackingNode({ type: "INPUT_PRODUCTION", ...p }, node)),
    ];
};
const traverseTcProduct = (product: TcProductTrackingInformation, parent?: TrackingNode): TrackingNode[] => {
    const node = buildTrackingNode({ type: parent ? "INPUT_PRODUCT" : "ROOT_PRODUCT", ...product }, parent);
    return [
        node,
        ...product.materialProductions.map((p) => buildTrackingNode({ type: "INPUT_PRODUCTION", ...p }, node)),
        ...product.inputPreGtbProducts.flatMap((p) => traversePreGtbProduct(p, node)),
        ...product.inputProducts.flatMap((p) => traverseTcProduct(p, node)),
    ];
};

export const uniqueIndexByState = (node: TrackingNode) => `${node.country}_${node.state}`;

export default function TcProductMapView() {
    const translation = useGtbTranslation();
    const { rootProduct, isLoading, mapState } = useTcProductMap();

    useHelpContext("tc-product-map");

    const marker = useMemo<TcProductTrackingPathProps["marker"] | undefined>(() => {
        if (!rootProduct) {
            return undefined;
        }
        const nodes = traverseTcProduct(rootProduct)
            // Ignore root materials that do not have descendants
            .filter((node) => !!node.state);

        const root = nodes.splice(
            nodes.findIndex((mark) => mark.type === "ROOT_PRODUCT"),
            1
        )[0];

        const groupedNodes = groupByProp(nodes, "state");

        return {
            root: { uniqueIndex: "ROOT_MARKER", ...root },
            uniqueInputs: groupedNodes
                .filter((group) => group.length === 1)
                .map((group) => {
                    const node = group[0];
                    return { uniqueIndex: uniqueIndexByState(node), ...node };
                }),
            groups: groupedNodes
                .filter((group) => group.length > 1)
                .map((group) => ({
                    uniqueIndex: uniqueIndexByState(group[0]),
                    inputs: group.map((node, index) => ({
                        uniqueIndex: `${uniqueIndexByState(node)}_${index}`,
                        ...node,
                    })),
                })),
            arcs: nodes.map((node, index) => ({ uniqueIndex: `arc_${index}`, ...node })),
        };
    }, [rootProduct]);

    return (
        <div className="tcProductMapView">
            <section className="info">
                {isLoading || !rootProduct ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        <h1 aria-describedby="productInfo">
                            {translation({
                                key: "tcProduct.mapInfo.product_title",
                                options: {
                                    productNumber: rootProduct.productNumber,
                                    interpolation: { escapeValue: false },
                                },
                            })}
                        </h1>
                        {renderTrackingInfo(mapState?.type ? mapState : { type: "ROOT_PRODUCT", ...rootProduct })}
                    </>
                )}
            </section>
            <WorldMap>
                <TcProductTrackingPath marker={marker} mapState={mapState} />
            </WorldMap>
            <GoBackButton className="goBackButton" steps={1} />
        </div>
    );
}

function renderTrackingInfo(mapState: TypedTrackingNode): ReactNode {
    switch (mapState.type) {
        case "ROOT_PRODUCT":
        case "INPUT_PRODUCT":
            return (
                <TrackingInfo
                    node={mapState}
                    subtitle={{
                        key: mapState.isByProduct
                            ? "tcProduct.mapInfo.predecessorByProduct_subtitle"
                            : "tcProduct.mapInfo.predecessorProduct_subtitle",
                        options: {
                            productNumber: mapState.productNumber,
                            interpolation: { escapeValue: false },
                        },
                    }}
                    materials={
                        traverseTcProduct(mapState).filter(
                            (input) => input.type === "INPUT_PRODUCTION"
                        ) as InputMaterialProduction[]
                    }
                    materialPercentageProvider={(material) => material.materialPercentage}
                />
            );
        case "INPUT_PRE_GTB_PRODUCT":
            return (
                <TrackingInfo
                    node={mapState}
                    subtitle={{
                        key: "tcProduct.mapInfo.predecessorPreGtbProduct_subtitle",
                        options: {
                            tcNumber: mapState.tcNumber,
                            productNumber: mapState.productNumber,
                            interpolation: { escapeValue: false },
                        },
                    }}
                    materials={
                        traversePreGtbProduct(mapState).filter(
                            (input) => input.type === "INPUT_PRODUCTION"
                        ) as InputMaterialProduction[]
                    }
                    materialPercentageProvider={(material) => material.preGtbMaterialPercentage}
                    stateTranslationKey="tcProduct.mapInfo.stateProvinceOfRelevantSco_title"
                />
            );
        case "INPUT_PRODUCTION":
            return (
                <TrackingInfo
                    node={mapState}
                    subtitle={{
                        key: "tcProduct.mapInfo.organicMaterialProduction_subtitle",
                        options: {
                            systemId: mapState.systemId,
                            interpolation: { escapeValue: false },
                        },
                    }}
                    materials={[buildTrackingNode(mapState) as InputMaterialProduction]}
                    materialPercentageProvider={() => null}
                />
            );
    }
}

export function useTcProductMapUrl() {
    const { getResolvedBaseUrl } = useResolvedRoute();

    return (tcProductId: string) =>
        getResolvedBaseUrl("tcProductMap").replace(`:${getIdName("tcProduct")}`, tcProductId);
}

export function useShowMapAction<T extends { id: string }>(): GridRowContextMenuAction<T> {
    const translation = useGtbTranslation();
    const getGoToMapUrl = useTcProductMapUrl();

    return {
        label: translation("components.grid.showOnMap_entryContextMenu"),
        navigationTargetProducer: (item) => getGoToMapUrl(item.id),
    };
}
