import useProcessCategoryEdit from "./useProcessCategoryEdit";
import ProcessCategoryDetailView from "./ProcessCategoryDetailView";

function ProcessCategoryEditView() {
    const processCategoryDetailProps = useProcessCategoryEdit();

    return <ProcessCategoryDetailView {...processCategoryDetailProps} />;
}

export default ProcessCategoryEditView;
