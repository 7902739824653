import UseConfirmationDialog from "./UseConfirmationDialog";
import { useCallback } from "react";

export default function useResetTotpDialog() {
    const showDialog = UseConfirmationDialog();

    return useCallback(() => {
        showDialog({
            title: "totp.reset_dialogTitle",
            message: "totp.reset_dialogText",
        });
    }, [showDialog]);
}
