import { I18nKey } from "./useGtbTranslation";
import { keysWithValueType } from "../utils/typeUtils";
import {
    calculateRestrictedLanguages,
    DEFAULT_LANGUAGE,
    getLanguageKeys,
    language,
    LANGUAGE_LOCAL_STORAGE_KEY,
    languageEnum,
    LanguageKey,
    TranslatableValue,
} from "./languageTypes";
import { FieldValues } from "react-hook-form/dist/types";
import { FieldPath } from "react-hook-form";
import { HistoryColumn } from "../hooks/details/useDomainEntityDetailsEdit";

export const buildTranslatedHistoryColumns = <ItemType extends FieldValues>(
    columnTitleKey: I18nKey,
    identifier: keyof keysWithValueType<ItemType, TranslatableValue> & string
) => {
    return getLanguageKeys().map(
        (key): HistoryColumn<ItemType> => ({
            identifier: `${identifier}.${key}` as FieldPath<ItemType>,
            title: {
                key: "translation.languageVersionFor_input",
                options: {
                    languageKey: `translation.language.${key}`,
                    inputKey: columnTitleKey,
                },
            },
        })
    );
};

export function determineInitialLanguage(): languageEnum {
    const localStorageLang = localStorage.getItem(LANGUAGE_LOCAL_STORAGE_KEY);
    const parsedLocalStorageLang = localStorageLang ? JSON.parse(localStorageLang) : "";
    if (isLanguageValid(parsedLocalStorageLang)) {
        return parsedLocalStorageLang as LanguageKey;
    }

    const browserLang = navigator.language.split("-")[0];
    if (isLanguageValid(browserLang)) {
        return browserLang as LanguageKey;
    }

    return DEFAULT_LANGUAGE;
}

export const isLanguageValid = (lang: string) => {
    return language[lang as LanguageKey] && !calculateRestrictedLanguages().includes(lang);
};
