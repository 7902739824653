import useResolvedRoute from "../components/routing/useResolvedRoute";
import { CertificationRelationSummary } from "./certificationRelationTypes";
import { GridColumn } from "../components/grid/component/Grid";
import { buildTextColumn } from "../components/grid/ColumnProvider";
import useCertificationRelationSubList, {
    certificationRelationSubListColumns,
} from "./useCertificationRelationSubList";
import useIsAuthorised from "../auth/useIsAuthorised";

const columns: GridColumn<CertificationRelationSummary>[] = [
    buildTextColumn({
        key: "certificationBodyBusinessName",
        title: "certificationRelation.list.certificationBody_column",
        minWidth: 201,
    }),
    ...certificationRelationSubListColumns,
];
export default function useSupplyChainOperatorCertificationRelationSubList() {
    const { getResolvedId, getResolvedCreateUrl } = useResolvedRoute();
    const { hasAccessRight } = useIsAuthorised();

    return useCertificationRelationSubList({
        columns,
        createUrl: hasAccessRight("createOperationLevelOrganisation")
            ? getResolvedCreateUrl("supplyChainOperatorCertificationRelation")
            : undefined,
        baseQueryParamKey: "supplyChainOperatorId",
        baseQueryParamValue: getResolvedId("supplyChainOperator"),
        tableName: "supplyChainOperatorCertificationRelations",
    });
}
