import { Params, useParams } from "react-router-dom";
import { useCallback } from "react";

export type routeEnum =
    | "homepage"
    | "login"
    | "logout"
    | "help"
    | "helpPage"
    | "user"
    | "searchActiveUser"
    | "searchInactiveUser"
    | "profile"
    | "countryArea"
    | "stateProvince"
    | "authorisationRole"
    | "eventMonitor"
    | "resetPassword"
    | "applicationLog"
    | "rawMaterial"
    | "productCategory"
    | "productDetail"
    | "processCategory"
    | "byproductInputCombination"
    | "labelGrade"
    | "standard"
    | "standardBody"
    | "standardVersion"
    | "scope"
    | "productConversion"
    | "allowedMaterial"
    | "standardAllowedMaterial"
    | "rawMaterialAllowedMaterial"
    | "materialContentRule"
    | "additionalMaterial"
    | "accreditationBody"
    | "accreditationRelation"
    | "scoLicence"
    | "certificationBody"
    | "supplyChainOperator"
    | "supplyChainOperatorScopeCertificate"
    | "supplyChainOperatorTransactionCertificate"
    | "scoLicenceNumber"
    | "supplyingRelation"
    | "supplyChainOperatorSupplyingRelation"
    | "organicMaterialProducer"
    | "organicMaterialProduction"
    | "facility"
    | "certificationLicence"
    | "certificationBodyAccreditationRelation"
    | "certificationRelation"
    | "dynamicUserGroup"
    | "supplyChainOperatorCertificationRelation"
    | "certificationRelationConfirmation"
    | "subcontractingRelation"
    | "supplyChainOperatorSubcontractingRelation"
    | "subcontractingRelationConfirmation"
    | "externalFacilityId"
    | "externalOrganicMaterialProducerId"
    | "externalSupplyChainOperatorId"
    | "facilityExternalFacilityId"
    | "organicMaterialProducerExternalOrganicMaterialProducerId"
    | "supplyChainOperatorExternalSupplyChainOperatorId"
    | "errorForbidden"
    | "errorNotFound"
    | "searchInPreparationScopeCertificate"
    | "searchAwaitingApprovalScopeCertificate"
    | "searchActiveScopeCertificate"
    | "searchPendingScopeCertificate"
    | "searchExpiredScopeCertificate"
    | "searchObsoleteScopeCertificate"
    | "searchSuspendedScopeCertificate"
    | "searchWithdrawnScopeCertificate"
    | "searchClientActiveScopeCertificate"
    | "searchClientPendingScopeCertificate"
    | "searchClientSuspendedScopeCertificate"
    | "searchClientWithdrawnScopeCertificate"
    | "searchClientInPreparationScopeCertificate"
    | "searchClientAwaitingApprovalScopeCertificate"
    | "scopeCertificate"
    | "scProduct"
    | "scProcessCategory"
    | "scMaterial"
    | "scFacility"
    | "preGtbTransactionCertificate"
    | "supplyChainOperatorPreGtbTransactionCertificate"
    | "searchPreliminaryPreGtbTcs"
    | "searchFinalPreGtbTcs"
    | "searchWithdrawnPreGtbTcs"
    | "preGtbProduct"
    | "preGtbTcMaterial"
    | "transactionCertificate"
    | "searchInPreparationTransactionCertificate"
    | "searchAwaitingApprovalTransactionCertificate"
    | "searchValidTransactionCertificate"
    | "searchWithdrawnTransactionCertificate"
    | "searchValidClientTransactionCertificate"
    | "searchWithdrawnClientTransactionCertificate"
    | "searchInPreparationClientTransactionCertificate"
    | "searchAwaitingApprovalClientTransactionCertificate"
    | "buyingRelation"
    | "supplyChainOperatorBuyingRelation"
    | "buyingRelationConfirmation"
    | "uploadLog"
    | "deduplicationLog"
    | "organicMaterialProductionSc"
    | "organicMaterialProducerScopeCertificate"
    | "organicMaterialPurchase"
    | "supplyChainOperatorOrganicMaterialPurchase"
    | "shipment"
    | "systemParameters"
    | "tcProduct"
    | "tcProductMap"
    | "inputQuantity"
    | "organicMaterialOrigin"
    | "tcMaterial"
    | "organicMaterialProductionScOrganicMaterialProduction"
    | "organicMaterialTransactionCertificate"
    | "allocatedTcNumber"
    | "mailTemplate"
    | "timeBasedMail";

export interface Route {
    routeBase: string;
    routeParent?: routeEnum;
}

export const routes: Record<routeEnum, Route> = {
    accreditationBody: { routeBase: "accreditationBody" },
    accreditationRelation: { routeBase: "accreditationRelation" },
    additionalMaterial: { routeBase: "additionalMaterial", routeParent: "materialContentRule" },
    allowedMaterial: { routeBase: "allowedMaterial" },
    applicationLog: { routeBase: "applicationLog" },
    authorisationRole: { routeBase: "authorisationRole" },
    buyingRelation: { routeBase: "buyingRelation" },
    supplyChainOperatorBuyingRelation: {
        routeBase: "supplyChainOperatorBuyingRelation",
        routeParent: "supplyChainOperator",
    },
    buyingRelationConfirmation: { routeParent: "buyingRelation", routeBase: "confirmation" },
    byproductInputCombination: { routeBase: "byproductInputCombination" },
    countryArea: { routeBase: "countryArea" },
    certificationBody: { routeBase: "certificationBody" },
    certificationBodyAccreditationRelation: {
        routeBase: "certificationBodyAccreditationRelation",
        routeParent: "certificationBody",
    },
    certificationLicence: { routeBase: "certificationLicence", routeParent: "certificationBody" },
    certificationRelation: {
        routeBase: "certificationRelation",
    },
    dynamicUserGroup: { routeBase: "dynamicUserGroup" },
    supplyChainOperatorCertificationRelation: {
        routeParent: "supplyChainOperator",
        routeBase: "supplyChainOperatorCertificationRelation",
    },
    certificationRelationConfirmation: { routeParent: "certificationRelation", routeBase: "confirmation" },
    deduplicationLog: { routeBase: "deduplicationLog" },
    errorForbidden: { routeBase: "403" },
    errorNotFound: { routeBase: "404" },
    systemParameters: { routeBase: "systemParameters" },
    eventMonitor: { routeBase: "eventMonitor" },
    externalFacilityId: { routeBase: "externalFacilityId" },
    externalOrganicMaterialProducerId: { routeBase: "externalOrganicMaterialProducerId" },
    externalSupplyChainOperatorId: { routeBase: "externalSupplyChainOperatorId" },
    facility: { routeBase: "facility", routeParent: "supplyChainOperator" },
    facilityExternalFacilityId: { routeParent: "facility", routeBase: "facilityExternalFacilityId" },
    help: { routeBase: "help" },
    helpPage: { routeBase: "helpPage" },
    homepage: { routeBase: "" },
    labelGrade: { routeBase: "labelGrade", routeParent: "standardVersion" },
    login: { routeBase: "login" },
    logout: { routeBase: "logout" },
    mailTemplate: { routeBase: "mailTemplate" },
    materialContentRule: { routeBase: "materialContentRule", routeParent: "labelGrade" },
    organicMaterialOrigin: { routeBase: "organicMaterialOrigin", routeParent: "organicMaterialPurchase" },
    organicMaterialProducer: { routeBase: "organicMaterialProducer" },
    organicMaterialProducerExternalOrganicMaterialProducerId: {
        routeParent: "organicMaterialProducer",
        routeBase: "organicMaterialProducerExternalOrganicMaterialProducerId",
    },
    organicMaterialProduction: { routeBase: "organicMaterialProduction" },
    organicMaterialProductionSc: { routeBase: "organicMaterialProductionSc" },
    organicMaterialProductionScOrganicMaterialProduction: {
        routeParent: "organicMaterialProductionSc",
        routeBase: "organicMaterialProductionScOrganicMaterialProduction",
    },
    organicMaterialProducerScopeCertificate: {
        routeParent: "organicMaterialProducer",
        routeBase: "organicMaterialProducerScopeCertificate",
    },
    organicMaterialPurchase: { routeBase: "organicMaterialPurchase" },
    organicMaterialTransactionCertificate: {
        routeBase: "organicMaterialTransactionCertificate",
        routeParent: "organicMaterialOrigin",
    },
    supplyChainOperatorOrganicMaterialPurchase: {
        routeBase: "supplyChainOperatorOrganicMaterialPurchase",
        routeParent: "supplyChainOperator",
    },
    processCategory: { routeBase: "processCategory" },
    productCategory: { routeBase: "productCategory" },
    productConversion: { routeBase: "productConversion" },
    productDetail: { routeBase: "productDetail" },
    profile: { routeBase: "profile" },
    rawMaterial: { routeBase: "rawMaterial" },
    rawMaterialAllowedMaterial: { routeBase: "rawMaterialAllowedMaterial", routeParent: "rawMaterial" },
    resetPassword: { routeBase: "resetPassword" },
    scope: { routeBase: "scope", routeParent: "standard" },
    searchActiveUser: { routeBase: "searchActiveUser" },
    searchInactiveUser: { routeBase: "searchInactiveUser" },
    standard: { routeBase: "standard" },
    standardAllowedMaterial: { routeBase: "standardAllowedMaterial", routeParent: "standard" },
    standardBody: { routeBase: "standardBody" },
    standardVersion: { routeBase: "standardVersion", routeParent: "standard" },
    stateProvince: { routeBase: "stateProvince", routeParent: "countryArea" },
    subcontractingRelation: { routeBase: "subcontractingRelation" },
    supplyChainOperatorSubcontractingRelation: {
        routeBase: "supplyChainOperatorSubcontractingRelation",
        routeParent: "supplyChainOperator",
    },
    scoLicenceNumber: { routeParent: "supplyChainOperator", routeBase: "scoLicenceNumber" },
    scoLicence: { routeBase: "scoLicence" },
    subcontractingRelationConfirmation: { routeParent: "subcontractingRelation", routeBase: "confirmation" },

    supplyChainOperator: { routeBase: "supplyChainOperator" },
    supplyChainOperatorExternalSupplyChainOperatorId: {
        routeParent: "supplyChainOperator",
        routeBase: "supplyChainOperatorExternalSupplyChainOperatorId",
    },
    supplyChainOperatorScopeCertificate: {
        routeBase: "supplyChainOperatorScopeCertificate",
        routeParent: "supplyChainOperator",
    },
    supplyChainOperatorTransactionCertificate: {
        routeBase: "supplyChainOperatorTransactionCertificate",
        routeParent: "supplyChainOperator",
    },
    supplyingRelation: { routeBase: "supplyingRelation" },
    supplyChainOperatorSupplyingRelation: {
        routeBase: "supplyChainOperatorSupplyingRelation",
        routeParent: "supplyChainOperator",
    },
    user: { routeBase: "user" },
    scopeCertificate: { routeBase: "scopeCertificate" },
    searchInPreparationScopeCertificate: { routeBase: "searchInPreparationScopeCertificate" },
    searchAwaitingApprovalScopeCertificate: { routeBase: "searchAwaitingApprovalScopeCertificate" },
    searchActiveScopeCertificate: { routeBase: "searchActiveScopeCertificate" },
    searchPendingScopeCertificate: { routeBase: "searchPendingScopeCertificate" },
    searchExpiredScopeCertificate: { routeBase: "searchExpiredScopeCertificate" },
    searchObsoleteScopeCertificate: { routeBase: "searchObsoleteScopeCertificate" },
    searchSuspendedScopeCertificate: { routeBase: "searchSuspendedScopeCertificate" },
    searchWithdrawnScopeCertificate: { routeBase: "searchWithdrawnScopeCertificate" },
    searchClientActiveScopeCertificate: { routeBase: "searchClientActiveScopeCertificate" },
    searchClientPendingScopeCertificate: { routeBase: "searchClientPendingScopeCertificate" },
    searchClientInPreparationScopeCertificate: { routeBase: "searchClientInPreparationScopeCertificate" },
    searchClientAwaitingApprovalScopeCertificate: { routeBase: "searchClientAwaitingApprovalScopeCertificate" },
    searchClientSuspendedScopeCertificate: { routeBase: "searchClientSuspendedScopeCertificate" },
    searchClientWithdrawnScopeCertificate: { routeBase: "searchClientWithdrawnScopeCertificate" },
    scProduct: { routeParent: "scopeCertificate", routeBase: "scProduct" },
    scProcessCategory: { routeParent: "scProduct", routeBase: "scProcessCategory" },
    scMaterial: { routeParent: "scProduct", routeBase: "scMaterial" },
    scFacility: { routeParent: "scProcessCategory", routeBase: "scFacility" },
    preGtbTransactionCertificate: { routeBase: "preGtbTransactionCertificate" },
    supplyChainOperatorPreGtbTransactionCertificate: {
        routeBase: "supplyChainOperatorPreGtbTransactionCertificate",
        routeParent: "supplyChainOperator",
    },
    searchPreliminaryPreGtbTcs: { routeBase: "searchPreliminaryPreGtbTransactionCertificate" },
    searchFinalPreGtbTcs: { routeBase: "searchFinalPreGtbTransactionCertificate" },
    searchWithdrawnPreGtbTcs: { routeBase: "searchWithdrawnPreGtbTransactionCertificate" },
    preGtbProduct: { routeParent: "preGtbTransactionCertificate", routeBase: "preGtbProduct" },
    preGtbTcMaterial: { routeParent: "preGtbProduct", routeBase: "preGtbTcMaterial" },
    transactionCertificate: { routeBase: "transactionCertificate" },
    tcProduct: { routeParent: "transactionCertificate", routeBase: "tcProduct" },
    tcProductMap: { routeParent: "tcProduct", routeBase: "map" },
    inputQuantity: { routeParent: "tcProduct", routeBase: "inputQuantity" },
    tcMaterial: { routeParent: "tcProduct", routeBase: "tcMaterial" },
    searchInPreparationTransactionCertificate: { routeBase: "searchInPreparationTransactionCertificate" },
    searchAwaitingApprovalTransactionCertificate: { routeBase: "searchAwaitingApprovalTransactionCertificate" },
    searchValidTransactionCertificate: { routeBase: "searchValidTransactionCertificate" },
    searchWithdrawnTransactionCertificate: { routeBase: "searchWithdrawnTransactionCertificate" },
    searchValidClientTransactionCertificate: { routeBase: "searchValidClientTransactionCertificate" },
    searchWithdrawnClientTransactionCertificate: { routeBase: "searchWithdrawnClientTransactionCertificate" },
    searchInPreparationClientTransactionCertificate: { routeBase: "searchInPreparationClientTransactionCertificate" },
    searchAwaitingApprovalClientTransactionCertificate: {
        routeBase: "searchAwaitingApprovalClientTransactionCertificate",
    },
    shipment: { routeParent: "transactionCertificate", routeBase: "shipment" },
    uploadLog: { routeBase: "uploadLog" },
    allocatedTcNumber: { routeBase: "allocatedTcNumber" },
    timeBasedMail: { routeBase: "timeBasedMail" },
};

export default function useResolvedRoute() {
    const params = useParams();

    const getResolvedBaseUrl = useCallback((key: routeEnum) => resolveUrl(key, getBaseUrl(key), params), [params]);
    const getResolvedEditUrl = useCallback(
        (key: routeEnum, id?: string) => resolveUrl(key, getEditUrl(key), params, id),
        [params]
    );
    const getResolvedCreateUrl = useCallback((key: routeEnum) => resolveUrl(key, getCreateUrl(key), params), [params]);
    const getResolvedId = useCallback((key: routeEnum) => params[getIdName(key)], [params]);

    return {
        getResolvedBaseUrl,
        getResolvedEditUrl,
        getResolvedCreateUrl,
        getResolvedId,
    };
}

export function getBaseUrl(key: routeEnum) {
    const route = routes[key];
    let baseUrl = "";

    if (route.routeParent) {
        baseUrl += getEditUrl(route.routeParent);
    }

    return baseUrl + "/" + route.routeBase;
}

export function getEditUrl(key: routeEnum) {
    const route = routes[key];
    let editUrl = "";

    if (route.routeParent) {
        editUrl += getEditUrl(route.routeParent);
    }

    return editUrl + "/" + route.routeBase + "/:" + getIdName(key);
}

export function getCreateUrl(key: routeEnum) {
    return getBaseUrl(key) + "/create";
}

export function getWildcardUrl(key: routeEnum) {
    return getBaseUrl(key) + "/*";
}

export function getIdName(key: routeEnum) {
    const route = routes[key];
    return route.routeBase + "Id";
}

function resolveUrl(key: routeEnum, url: string, params: Params, id?: string) {
    const route = routes[key];

    if (route.routeParent) {
        url = resolveUrl(route.routeParent, url, params);
    }

    const idName = getIdName(key);
    const actualId = id ?? params[idName];

    if (actualId) {
        url = url.replace(":" + idName, actualId);
    }

    return url;
}
