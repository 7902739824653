import { useCallback } from "react";
import DialogHeader from "./DialogHeader";
import GtbButton from "../GtbButton";
import DialogTextContent from "./DialogTextContent";
import DialogFooter from "./DialogFooter";
import useDialog, { DialogProps } from "./useDialog";
import useGtbTranslation from "../../i18n/useGtbTranslation";

interface ConfirmationDialogProps extends Omit<DialogProps, "footer"> {
    onConfirm?: () => void;
}

function UseConfirmationDialog() {
    const translation = useGtbTranslation();
    const { showDialog, closeDialog } = useDialog();

    return useCallback(
        ({ title, message, onConfirm }: ConfirmationDialogProps) => {
            showDialog({
                header: <DialogHeader title={title} variant="info" onClick={closeDialog} />,
                content: <DialogTextContent message={message} />,
                footer: (
                    <DialogFooter>
                        <GtbButton
                            onClick={() => {
                                onConfirm?.();
                                closeDialog();
                            }}
                        >
                            {translation("components.dialog.ok_button")}
                        </GtbButton>
                    </DialogFooter>
                ),
            });
        },
        [closeDialog, showDialog, translation]
    );
}

export default UseConfirmationDialog;

export const workInProgressDialogOptions: DialogProps = {
    title: "components.dialog.workInProgress",
    message: "components.dialog.workInProgressMessage",
};
