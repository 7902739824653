import useGtbTranslation, { I18nKey } from "../../i18n/useGtbTranslation";
import { ReactNode, useMemo } from "react";
import GtbTooltip from "./GtbTooltip";

export interface GtbTooltipProps {
    title: string;
    "aria-describedby": string;
    //TODO With ARIA 1.3 aria-describedby should be replaced with aria-description
}

export interface UseGtbTooltipProps {
    tooltipProps?: GtbTooltipProps;
    tooltip?: ReactNode;
    //TODO With ARIA 1.3 tooltip can be removed from UseGtbTooltipProps because its not needed anymore with the usage of aria-description
}

export default function useGtbTooltip(titleKey?: I18nKey) {
    const translation = useGtbTranslation();

    const tooltipProps = useMemo<GtbTooltipProps | undefined>(() => {
        if (titleKey) {
            const tooltipTitle = titleKey + "_tooltip";
            const translatedTitle = translation(tooltipTitle as I18nKey);
            const tooltipId = titleKey + "_tooltip_" + Math.floor(Math.random() * 1000000);

            if (translatedTitle !== tooltipTitle) {
                return { title: translatedTitle, "aria-describedby": tooltipId };
            }
        }
    }, [titleKey, translation]);

    return useMemo<UseGtbTooltipProps>(() => {
        if (tooltipProps) {
            const tooltip = <GtbTooltip {...tooltipProps} />;
            return { tooltipProps, tooltip };
        }
        return {};
    }, [tooltipProps]);
}
