import { TypedDetailViewProps } from "../../components/detailView/DetailView";
import "../../components/spinner.css";
import { useHelpContext } from "../../help/context-sensitivity/useHelpContext";
import EntityDetailView from "../../components/entity/EntityDetailView";
import Tabs, { TabKey, TabProps } from "../../components/tabs/Tabs";
import React, { useMemo } from "react";
import { TransactionCertificateDetails } from "../transactionCertificateTypes";
import TransactionCertificateBasicData, {
    TransactionCertificateBasicDataProps,
} from "./TransactionCertificateBasicData";
import TransactionCertificateAdditionalData, {
    TransactionCertificateAdditionalDataProps,
} from "./TransactionCertificateAdditionalData";
import Shipments from "./shipments/Shipments";
import { TransactionCertificateStatusType } from "../../auth/roleTypes";
import TcProducts from "./tcProduct/TcProducts";
import TransactionCertificateDocument, { TransactionCertificateDocumentProps } from "./TransactionCertificateDocument";

export default function TransactionCertificateDetailView({
    detailViewProps,
    transactionCertificateId,
    transactionCertificateStatus,
    form,
    readOnly,
    subListVisible,
    humanReadableDocumentSize,
    documentTabVisible,
    ...rest
}: TransactionCertificateDetailViewProps) {
    useHelpContext("transaction-certificate-details");

    const documentTab = useMemo<TabProps<TransactionCertificateTabs>[]>(
        () => [
            {
                heading: "transactionCertificate.detail.tcDocument_tab",
                element: (
                    <TransactionCertificateDocument
                        form={form}
                        humanReadableDocumentSize={humanReadableDocumentSize}
                        transactionCertificateId={transactionCertificateId}
                    />
                ),
            },
        ],
        [form, humanReadableDocumentSize, transactionCertificateId]
    );

    const sublistTabs = useMemo<TabProps<TransactionCertificateTabs>[]>(
        () => [
            {
                heading: "transactionCertificate.detail.tcProducts_tab",
                element: <TcProducts form={form} transactionCertificateStatus={transactionCertificateStatus} />,
            },
            {
                heading: "transactionCertificate.detail.shipments_tab",
                element: <Shipments form={form} transactionCertificateStatus={transactionCertificateStatus!} />,
            },
        ],
        [form, transactionCertificateStatus]
    );

    return (
        <EntityDetailView {...rest} detailViewProps={detailViewProps} form={form}>
            <Tabs<TransactionCertificateDetails, TransactionCertificateTabs>
                tabs={[
                    {
                        heading: "transactionCertificate.detail.basicData_tab",
                        element: (
                            <TransactionCertificateBasicData
                                form={form}
                                readOnly={readOnly}
                                tcId={transactionCertificateId}
                            />
                        ),
                    },
                    ...(subListVisible ? sublistTabs : []),
                    {
                        heading: "transactionCertificate.detail.additionalData_tab",
                        element: <TransactionCertificateAdditionalData form={form} readOnly={readOnly} />,
                    },
                    ...(documentTabVisible ? documentTab : []),
                ]}
                errors={form.form.formState.errors}
                errorToTab={{
                    id: "internal",
                    certificationBody: "transactionCertificate.detail.basicData_tab",
                    scopeCertificate: "transactionCertificate.detail.basicData_tab",
                    seller: "transactionCertificate.detail.basicData_tab",
                    cbTcReferenceNumber: "transactionCertificate.detail.basicData_tab",
                    buyer: "transactionCertificate.detail.basicData_tab",
                    facilityAsBuyer: "transactionCertificate.detail.basicData_tab",
                    facilityAsSeller: "transactionCertificate.detail.basicData_tab",
                    tcNumber: "transactionCertificate.detail.basicData_tab",
                    status: "transactionCertificate.detail.basicData_tab",
                    dateOfIssue: "transactionCertificate.detail.basicData_tab",
                    placeOfIssue: "transactionCertificate.detail.basicData_tab",
                    approvingPerson: "transactionCertificate.detail.basicData_tab",
                    doNotDisplayLastProcessorOnPrintedTc: "transactionCertificate.detail.additionalData_tab",
                    declarationsBySeller: "transactionCertificate.detail.additionalData_tab",
                    certificateOrigin: "transactionCertificate.detail.tcDocument_tab",
                    certificateDocument: "transactionCertificate.detail.tcDocument_tab",
                    cbCertificate: "transactionCertificate.detail.tcDocument_tab",
                    documentSize: "transactionCertificate.detail.tcDocument_tab",
                    documentHash: "transactionCertificate.detail.tcDocument_tab",
                    hashAlgorithm: "transactionCertificate.detail.tcDocument_tab",
                }}
            />
        </EntityDetailView>
    );
}

type TransactionCertificateTabs = TabKey<
    | "transactionCertificate.detail.basicData_tab"
    | "transactionCertificate.detail.shipments_tab"
    | "transactionCertificate.detail.additionalData_tab"
    | "transactionCertificate.detail.tcProducts_tab"
    | "transactionCertificate.detail.tcDocument_tab"
>;

export interface TransactionCertificateDetailViewProps extends TypedDetailViewProps<TransactionCertificateDetails> {
    transactionCertificateId?: string;
    transactionCertificateStatus?: TransactionCertificateStatusType;
    subListVisible?: boolean;
    documentTabVisible: boolean;
    humanReadableDocumentSize: TransactionCertificateDocumentProps["humanReadableDocumentSize"];
    readOnly: TransactionCertificateBasicDataProps["readOnly"] & TransactionCertificateAdditionalDataProps["readOnly"];
}
