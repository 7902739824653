import { ScProcessCategoryDetails } from "../scProcessCategoryTypes";
import { UseGtbFormReturn } from "../../../../../../hooks/formHandling/useGtbForm";
import { joinClassNames } from "../../../../../../utils/StringUtils";
import GtbTextField from "../../../../../../components/Input/GtbTextField";
import ControlledTextField from "../../../../../../components/Input/ControlledTextField";
import ProcessCategoryPickList from "../../../../../../components/Input/pickList/typedPicklist/ProcessCategoryPickList";
import "./scProcessCategoryBasicData.css";
import useSetFormValue from "../../../../../../hooks/formHandling/useSetFormValue";
import useScFacilitySubList from "./facilities/useScFacilitySubList";
import InfinityScrollSubList from "../../../../../../components/grid/InfinityScrollSubList";
import { scopeCertificateStatusType } from "../../../../../../auth/roleTypes";

export interface ScProcessCategoryBasicDataProps {
    form: UseGtbFormReturn<ScProcessCategoryDetails>;
    subListVisible: boolean;
    readOnly: boolean;
    scopeCertificateStatus?: scopeCertificateStatusType;
}

export default function ScProcessCategoryBasicData({
    form,
    subListVisible,
    readOnly,
    scopeCertificateStatus,
}: ScProcessCategoryBasicDataProps) {
    const scFacilityListOptions = useScFacilitySubList(scopeCertificateStatus);
    const setFormValue = useSetFormValue(form.form);

    return (
        <div className={joinClassNames("scopeCertificateProcessCategoryBasicData", "formGrid")}>
            <GtbTextField
                label="scProcessCategory.detail.supplyChainOperator_input"
                {...form.registerWithErrors("scopeCertificateSupplyChainOperatorBusinessName")}
                className="supplyChainOperator"
                readOnly
            />
            <GtbTextField
                label="scProcessCategory.detail.scProductNumber_input"
                {...form.registerWithErrors("scProductScProductNumber")}
                className="scProductNumber"
                readOnly
            />
            <GtbTextField
                label="scProcessCategory.detail.productCategoryPrint_input"
                {...form.registerWithErrors("scProductProductCategoryPrint")}
                className="productCategory"
                readOnly
            />
            <GtbTextField
                label="scProcessCategory.detail.productDetailPrint_input"
                {...form.registerWithErrors("scProductProductDetailPrint")}
                className="productDetail"
                readOnly
            />
            <ProcessCategoryPickList
                label="scProcessCategory.detail.processCategory_input"
                control={form.form.control}
                name="processCategory"
                readOnly={readOnly}
                className="processCategory"
                onChangeListener={(_event, _value, newItem) => setFormValue("processCategoryPrint", newItem?.name)}
            />
            <ControlledTextField
                label="scProcessCategory.detail.processCategoryPrint_input"
                control={form.form.control}
                name="processCategoryPrint"
                readOnly={readOnly}
                className="processCategoryPrint"
            />
            <GtbTextField
                label="scProcessCategory.detail.labelGrade_input"
                {...form.registerWithErrors("scProductLabelGradeLabelGrade")}
                className="labelGrade"
                readOnly
            />
            {subListVisible && <InfinityScrollSubList {...scFacilityListOptions} className="scFacilities" />}
        </div>
    );
}
