import { UseGtbFormReturn } from "../../../../../../hooks/formHandling/useGtbForm";
import { joinClassNames } from "../../../../../../utils/StringUtils";
import GtbTextField from "../../../../../../components/Input/GtbTextField";
import "./scMaterialBasicData.css";
import { ScMaterialDetails } from "../scMaterialTypes";
import RawMaterialPickList from "../../../../../../components/Input/pickList/typedPicklist/RawMaterialPickList";
import GtbNumberField from "../../../../../../components/Input/GtbNumberField";
import useSetFormValue from "../../../../../../hooks/formHandling/useSetFormValue";
import { FocusEvent, useCallback } from "react";

export interface ScMaterialBasicDataProps {
    form: UseGtbFormReturn<ScMaterialDetails>;
    materialPrintVisible: boolean;
    readOnly: boolean;
    scopeCertificateId: string;
}

export default function ScMaterialBasicData({
    form,
    materialPrintVisible,
    readOnly,
    scopeCertificateId,
}: ScMaterialBasicDataProps) {
    const setFormValue = useSetFormValue(form.form);

    const handlePercentageFieldBlur = useCallback(
        (e: FocusEvent, fieldName: keyof ScMaterialDetails) => {
            const value = (e.target as HTMLInputElement).value;
            const triggerFieldName = (e.target as HTMLInputElement).name as keyof ScMaterialDetails;

            if (value && !form.form.getValues(fieldName) && form.form.getValues(fieldName) !== 0) {
                setFormValue(fieldName, value);
            }

            if (form.form.formState.isSubmitted) {
                form.form.trigger([triggerFieldName, fieldName]).then();
            }
        },
        [form.form, setFormValue]
    );

    return (
        <div className={joinClassNames("scMaterialBasicData", "formGrid")}>
            <GtbTextField
                label="scMaterial.detail.supplyChainOperator_input"
                {...form.registerWithErrors("scProductScopeCertificateSupplyChainOperatorBusinessName")}
                readOnly
                className="supplyChainOperator"
            />
            <GtbTextField
                label="scMaterial.detail.scProductNumber_input"
                {...form.registerWithErrors("scProductScProductNumber")}
                readOnly
                className="scProductNumber"
            />
            <GtbTextField
                label="scMaterial.detail.productCategoryPrint_input"
                {...form.registerWithErrors("scProductProductCategoryPrint")}
                readOnly
                className="productCategory"
            />
            <GtbTextField
                label="scMaterial.detail.productDetailPrint_input"
                {...form.registerWithErrors("scProductProductDetailPrint")}
                readOnly
                className="productDetail"
            />
            <GtbTextField
                label="scMaterial.detail.labelGrade_input"
                {...form.registerWithErrors("scProductLabelGradeLabelGrade")}
                readOnly
                className="labelGrade"
            />
            <RawMaterialPickList
                label="scMaterial.detail.material_input"
                control={form.form.control}
                name="material"
                readOnly={readOnly}
                className="material"
                flags={{ scopeCertificateId }}
            />
            {materialPrintVisible && (
                <GtbTextField
                    label="scMaterial.detail.materialPrint_input"
                    {...form.registerWithErrors("materialPrint")}
                    readOnly={readOnly}
                    className="materialPrint"
                />
            )}
            <GtbNumberField
                readOnly={readOnly}
                label="scMaterial.detail.minPercentage_input"
                className="minPercentage"
                unit="%"
                decimalPlaces={2}
                {...form.registerNumberWithErrors("minPercentage")}
                onBlur={(event) => handlePercentageFieldBlur(event, "maxPercentage")}
            />
            <GtbNumberField
                readOnly={readOnly}
                label="scMaterial.detail.maxPercentage_input"
                className="maxPercentage"
                unit="%"
                decimalPlaces={2}
                {...form.registerNumberWithErrors("maxPercentage")}
                onBlur={(event) => handlePercentageFieldBlur(event, "minPercentage")}
            />
        </div>
    );
}
