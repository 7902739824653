import { InputQuantityDetails, InputQuantitySummary } from "./inputQuantity/inputQuantityTypes";
import { SortOption, useQueryBuilder } from "../../../../components/grid/component/useQueryBuilder";
import { GridColumn } from "../../../../components/grid/component/Grid";
import { buildDataColumn, buildNumberColumn, buildTextColumn } from "../../../../components/grid/ColumnProvider";
import { EnumEntityType } from "../../../../components/Input/pickList/typedEnumPicklist/enumEntityType";
import useResolvedRoute from "../../../../components/routing/useResolvedRoute";
import { useDeleteAction, useShowDetailsAction } from "../../../../utils/defaultActions";
import useGridRowContextMenuActions from "../../../../components/grid/component/cell/useGridRowContextMenuActions";
import { useMemo } from "react";
import { SubListProps } from "../../../../components/grid/SubList";

export const defaultInputQuantitySortOption: SortOption<InputQuantitySummary | InputQuantityDetails> = {
    field: "inputNumber",
    direction: "ASC",
};

const columns: GridColumn<InputQuantitySummary>[] = [
    buildTextColumn({
        key: "inputNumber",
        title: "inputQuantity.list.inputNumber_column",
        minWidth: 159,
    }),
    buildNumberColumn({
        key: "quantity",
        title: "inputQuantity.list.quantity_column",
        minWidth: 121,
        fixedDecimal: true,
    }),
    buildDataColumn<InputQuantitySummary, EnumEntityType>({
        key: "typeOfInputSource",
        title: "inputQuantity.list.sourceType_column",
        minWidth: 148,
        url: "/certificate-service/references/input-quantity-source-types",
        itemId: "id",
        itemLabel: "name",
    }),
    buildTextColumn({
        key: "sourceReference",
        title: "inputQuantity.list.sourceReference_column",
        minWidth: 187,
    }),
];

export default function useInputQuantitySubList({
    readOnly,
    afterDeletion,
}: {
    readOnly: boolean;
    afterDeletion: Function;
}) {
    const { getResolvedEditUrl, getResolvedCreateUrl, getResolvedId } = useResolvedRoute();
    const transactionCertificateId = getResolvedId("transactionCertificate");
    const tcProductId = getResolvedId("tcProduct");

    const { query, columnOptions, forceReload } = useQueryBuilder<InputQuantitySummary>(
        `/certificate-service/transaction-certificates/${transactionCertificateId}/tc-products/${tcProductId}/input-quantities`,
        {
            sort: defaultInputQuantitySortOption,
            filterActive: false,
        }
    );

    const showDetailsAction = useShowDetailsAction(
        (item: InputQuantitySummary) => getResolvedEditUrl("inputQuantity", item.id),
        query.parameter
    );

    const deleteAction = useDeleteAction(
        (item: InputQuantitySummary) =>
            `/certificate-service/transaction-certificates/${transactionCertificateId}/tc-products/${tcProductId}/input-quantities/${item.id}`,
        () => {
            afterDeletion();
            forceReload();
        },
        "updateTransactionCertificate"
    );
    const contextMenuActions = useGridRowContextMenuActions(showDetailsAction, !readOnly ? deleteAction : undefined);

    return useMemo<SubListProps<InputQuantitySummary>>(
        () => ({
            query,
            columns,
            uniqueDataKey: "id",
            onItemDoubleClick: showDetailsAction,
            header: "inputQuantity.list.header",
            itemActions: contextMenuActions,
            columnOptions,
            createUrl: !readOnly ? getResolvedCreateUrl("inputQuantity") : undefined,
            tableName: "inputQuantities",
        }),
        [columnOptions, contextMenuActions, getResolvedCreateUrl, query, readOnly, showDetailsAction]
    );
}
