import makeStore from "../../hooks/makeStore";
import { useCallback, useRef } from "react";
import { To, useLocation } from "react-router-dom";
import { getBaseUrl } from "../routing/useResolvedRoute";
import { I18nKey } from "../../i18n/useGtbTranslation";

const getInitialNavigationContext = () => {
    return [
        {
            to: getBaseUrl("homepage"),
            label: "components.breadcrumbs.root",
            route: getBaseUrl("homepage"),
            navigationContextCleanup: () => {
                /*Nothing to do here. The context will be cleared on navigation to Homepage*/
            },
        },
    ];
};

export type navigationContextStateType = any;

interface NavigationContextParam {
    to: To;
    label: I18nKey | string;
    route: string;
    state?: navigationContextStateType;
}

interface NavigationContextType extends NavigationContextParam {
    navigationContextCleanup: () => void;
}

function _NavigationContext() {
    const [NavigationContextProvider, _setNavigationContext, _useNavigationContext] = makeStore<
        NavigationContextType[]
    >(getInitialNavigationContext());

    return {
        NavigationContextProvider,
        _setNavigationContext,
        _useNavigationContext,
    };
}

const _navigationContext = _NavigationContext();
export default _navigationContext.NavigationContextProvider;

export function useNavigationContext() {
    return _navigationContext._useNavigationContext();
}

export function useChangeNavigationContext() {
    const setter = _navigationContext._setNavigationContext();
    const navigationContext = _navigationContext._useNavigationContext();
    const currentLengthRef = useRef(navigationContext.length);
    const { pathname, search } = useLocation();

    const pushNavigationContext = useCallback(
        (
            navigationContextLabel?: I18nKey,
            navigationContextRoute?: string,
            navigationContextState?: navigationContextStateType
        ) => {
            if (!navigationContextLabel || (navigationContextLabel as string) === "undefined") {
                return;
            }

            const navContext: NavigationContextType = {
                label: navigationContextLabel,
                to: { pathname, search },
                route: navigationContextRoute ?? (navigationContextLabel as string),
                state: navigationContextState,
                navigationContextCleanup: () => {
                    setter((oldValue) => oldValue.slice(0, currentLengthRef.current));
                },
            };
            setter((oldValue) => {
                if (oldValue.length && oldValue[oldValue.length - 1].route === navContext.route) {
                    return [...oldValue.slice(0, oldValue.length - 1), navContext];
                }
                return [...oldValue, navContext];
            });
        },
        [pathname, search, setter]
    );

    const resetNavigationContext = useCallback(() => {
        setter(getInitialNavigationContext);
    }, [setter]);

    return { pushNavigationContext, resetNavigationContext };
}
