import { buildSchema, validateDate, validateString } from "../../utils/yupExtension";
import { ScoLicenceDetails } from "../scoLicenceTypes";

export const scoLicenceValidationSchema = buildSchema<ScoLicenceDetails>({
    supplyChainOperator: validateString().isRequired(),
    standard: validateString().isRequired(),
    licenceNumber: validateString().hasMaxLength(20),
    startDate: validateDate(),
    endDate: validateDate(),
    bannedUntil: validateDate().hasMinValue(new Date(Date.now() + 1), "validation.dateMustBeInFuture"), //tomorrow (must be in the future)
});
