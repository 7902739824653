import * as React from "react";
import { ReactNode } from "react";

export interface GridBodyCellProps<ItemType> {
    contentProvider: (item: ItemType) => ReactNode;
    className?: string;
}

function GridBodyCell<ItemType>({
    item,
    contentProvider,
    className,
}: GridBodyCellProps<ItemType> & { item: ItemType }) {
    return <td className={className}>{contentProvider(item)}</td>;
}

export default GridBodyCell;
