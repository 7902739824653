import { yupResolver } from "@hookform/resolvers/yup";
import useResolvedRoute from "../../components/routing/useResolvedRoute";
import useDomainEntityDetailsEdit from "../../hooks/details/useDomainEntityDetailsEdit";
import { DynamicUserGroupDetails } from "../dynamicUserGroupTypes";
import { defaultDynamicUserGroupSortOption } from "../useDynamicUserGroupListView";
import { DynamicUserGroupDetailViewProps } from "./DynamicUserGroupDetailView";
import { dynamicUserGroupValidationSchema } from "./dynamicUserGroupValidationSchema";

export default function useDynamicUserGroupEdit(): DynamicUserGroupDetailViewProps {
    const { getResolvedId } = useResolvedRoute();
    const dynamicUserGroupId = getResolvedId("dynamicUserGroup");

    const { detailViewProps, form, title, recordNavigationProps } =
        useDomainEntityDetailsEdit<DynamicUserGroupDetails>({
            baseUrl: `/certificate-service/dynamic-user-groups/${dynamicUserGroupId}`,
            frontendRoute: "dynamicUserGroup",
            recordNavigationDefaultSort: defaultDynamicUserGroupSortOption,
            titleBuilder: (item) => item.name,
            resolver: yupResolver(dynamicUserGroupValidationSchema),
            canSave: true,
            historyColumns: [
                { identifier: "name", title: "dynamicUserGroup.history.name_column" },
                { identifier: "description", title: "dynamicUserGroup.history.description_column" },
                { identifier: "definition", title: "dynamicUserGroup.history.queryDefinition_column" },
            ],
        });

    return {
        detailViewProps,
        form,
        title,
        recordNavigationProps,
    };
}
