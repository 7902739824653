import { useCallback, useMemo } from "react";
import { ScProcessCategoryDetailViewProps } from "./ScProcessCategoryDetailView";
import useIsAuthorised from "../../../../../../auth/useIsAuthorised";
import { ScProcessCategoryDetails } from "../scProcessCategoryTypes";
import useResolvedRoute from "../../../../../../components/routing/useResolvedRoute";
import useDomainEntityDetailsEdit from "../../../../../../hooks/details/useDomainEntityDetailsEdit";
import { defaultScProcessCategorySortOption } from "../useScProcessCategorySubList";
import { yupResolver } from "@hookform/resolvers/yup";
import { ScProcessCategoryValidationSchema } from "./scProcessCategoryValidationSchema";
import { scopeCertificateReadOnlyByStatus } from "../../../../../scopeCertificateTypes";
import { scopeCertificateStatusType } from "../../../../../../auth/roleTypes";

export default function useScProcessCategoryEdit(): ScProcessCategoryDetailViewProps {
    const { hasAccessRight } = useIsAuthorised();

    const { getResolvedId } = useResolvedRoute();
    const scopeCertificateId = getResolvedId("scopeCertificate");
    const scProductId = getResolvedId("scProduct");
    const scProcessCategoryId = getResolvedId("scProcessCategory");

    const canSave = useCallback(
        (item: ScProcessCategoryDetails) => {
            if (item) {
                return (
                    hasAccessRight("updateScopeCertificate", [item.scopeCertificateStatus]) &&
                    !scopeCertificateReadOnlyByStatus(item.scopeCertificateStatus)
                );
            }
            return false;
        },
        [hasAccessRight]
    );

    const { detailViewProps, form, isReadOnly, title, recordNavigationProps, initialData } =
        useDomainEntityDetailsEdit<ScProcessCategoryDetails>({
            baseUrl: `/certificate-service/scope-certificates/${scopeCertificateId}/sc-products/${scProductId}/sc-process-categories/${scProcessCategoryId}`,
            frontendRoute: "scProcessCategory",
            recordNavigationDefaultSort: defaultScProcessCategorySortOption,
            titleBuilder: (item) => item.processCategoryPrint!,
            resolver: yupResolver(ScProcessCategoryValidationSchema),
            canSave,
            historyColumns: [
                { identifier: "processCategory", title: "scProcessCategory.history.processCategory_column" },
                { identifier: "processCategoryPrint", title: "scProcessCategory.history.processCategoryPrint_column" },
            ],
        });

    const scopeCertificateStatus = useMemo<scopeCertificateStatusType | undefined>(
        () => initialData?.scopeCertificateStatus,
        [initialData?.scopeCertificateStatus]
    );

    const isReadOnlyByScopeCertificateStatus = useMemo<boolean>(
        () => scopeCertificateReadOnlyByStatus(scopeCertificateStatus),
        [scopeCertificateStatus]
    );

    return {
        detailViewProps,
        form,
        readOnly: isReadOnly || isReadOnlyByScopeCertificateStatus,
        title,
        recordNavigationProps,
        createRoute:
            scopeCertificateStatus && hasAccessRight("updateScopeCertificate", [scopeCertificateStatus])
                ? "scProcessCategory"
                : undefined,
        subListVisible: true,
        scopeCertificateStatus,
    };
}
