import { Dispatch, SetStateAction, useEffect } from "react";
import { useQuery } from "../hooks/useAxios";
import { UseGtbFormReturn } from "../hooks/formHandling/useGtbForm";
import { useWatch } from "react-hook-form";

export default function useRequiresLegalNameInEnglish(
    form: UseGtbFormReturn<any>,
    setRequiresLegalNameInEnglish: Dispatch<SetStateAction<boolean>>
) {
    const { runQuery: fetchCountry } = useQuery({ url: "", enabled: false });
    const countryFormValue = useWatch({ control: form.form.control, name: "countryArea" });

    useEffect(() => {
        if (!countryFormValue) {
            setRequiresLegalNameInEnglish(false);
            return;
        }

        fetchCountry({ url: "/certificate-service/references/countries/" + countryFormValue })
            .then((response) => setRequiresLegalNameInEnglish(response?.nameTranscriptions))
            .catch(console.error);
    }, [countryFormValue, fetchCountry, setRequiresLegalNameInEnglish]);
}
