import { TypedPicklist } from "./typedPicklistType";
import DataPickList, { DataPickListProps } from "../DataPickList";
import { StandardReference, standardTypeEnum } from "../../../../standard/standardTypes";
import { FieldValues } from "react-hook-form/dist/types";
import "./typedPickList.css";
import { joinClassNames } from "../../../../utils/StringUtils";
import { organisationTypeEnum } from "../../../../authorisationRole/authorisationRoleTypes";

export default function StandardPickList<FormItemType extends FieldValues>({
    itemLabel = "officialName",
    className,
    flags,
    ...rest
}: StandardPickListProps<FormItemType> & {
    itemLabel?: DataPickListProps<FormItemType, StandardReference>["itemLabel"];
}) {
    return (
        <DataPickList
            itemId="id"
            itemLabel={itemLabel}
            className={joinClassNames("standardPickList", className)}
            queryUrl="/certificate-service/references/standards"
            flags={flags}
            {...rest}
        />
    );
}

export interface StandardPickListFlags {
    organisation_type?: organisationTypeEnum;
    typeOfStandard?: standardTypeEnum;
}

interface StandardPickListProps<FormItemType extends FieldValues>
    extends TypedPicklist<FormItemType, StandardReference> {
    flags?: StandardPickListFlags;
}
