import ProductCategoryDetailView from "./ProductCategoryDetailView";
import useProductCategoryEdit from "./useProductCategoryEdit";

function ProductCategoryEditView() {
    const productCategoryDetailProps = useProductCategoryEdit();

    return <ProductCategoryDetailView {...productCategoryDetailProps} />;
}

export default ProductCategoryEditView;
