import React, { useEffect, useState } from "react";

function useStickyState<T>(key: string, defaultValue: T | null) {
    const [value, setValue] = useState<T>(() => {
        const stickyValue = window.localStorage.getItem(key);
        return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
    });

    useEffect(() => {
        if (!value) {
            window.localStorage.removeItem(key);
        } else {
            window.localStorage.setItem(key, JSON.stringify(value));
        }
    }, [key, value]);

    useEffect(() => {
        function updateOnStorageEvent(e: StorageEvent) {
            if (e.key === key) {
                const stickyValue = e.newValue;
                setValue(stickyValue !== null ? JSON.parse(stickyValue) : defaultValue);
            }
        }

        window.addEventListener("storage", updateOnStorageEvent);
        return () => window.removeEventListener("storage", updateOnStorageEvent);
    });

    return [value, setValue] as [T, React.Dispatch<React.SetStateAction<T | null>>];
}

export default useStickyState;
