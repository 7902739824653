import GtbTextField from "../../components/Input/GtbTextField";
import { joinClassNames } from "../../utils/StringUtils";
import "./loginData.css";
import ControlledDatePicker from "../../components/Input/datepicker/ControlledDatePicker";
import { UserDetailViewTabProps } from "./UserDetailView";
import { LabeledCheckbox } from "../../components/Input/Checkbox";
import GtbButton from "../../components/GtbButton";
import UseWarningDialog from "../../components/dialog/UseWarningDialog";
import { DialogYesNoOptions } from "../../components/dialog/DialogOptions";
import useGtbTranslation from "../../i18n/useGtbTranslation";
import useChangePasswordDialog from "./useChangePasswordDialog";

function LoginData({
    form,
    readOnlyProps,
    visibilityProps,
    resetTotp,
    activateTotp,
    totpReady,
}: UserDetailViewTabProps) {
    const translation = useGtbTranslation();
    const { showDialog: showResetTotpDialog, closeDialog: closeResetTotpDialog } = UseWarningDialog();
    const showChangePasswordDialog = useChangePasswordDialog();

    return (
        <div
            className={joinClassNames(
                "loginData",
                "formGrid",
                visibilityProps.isAcceptedToUVisible ? "withTou" : null,
                visibilityProps.isTestDataStampVisible ? "withTestDataStamp" : null
            )}
        >
            <ControlledDatePicker
                readOnly
                control={form.form.control}
                name="lastSuccessfulLogin"
                label="user.detail.lastSuccessfulLogin_input"
                className="successfulLogin"
            />
            <ControlledDatePicker
                readOnly
                control={form.form.control}
                name="lastFailedLogin"
                label="user.detail.lastFailedLogin_input"
                className="failedLogin"
            />
            {visibilityProps.isAcceptedToUVisible && (
                <>
                    <LabeledCheckbox
                        {...form.form.register("acceptedToU")}
                        id={"acceptedToU"}
                        label="user.detail.acceptedToU_input"
                        className={"acceptedToU"}
                        readOnly
                    />
                    <LabeledCheckbox
                        {...form.form.register("acceptedGoogleToU")}
                        id={"acceptedGoogleToU"}
                        label="user.detail.acceptedGoogleToU_input"
                        className={"acceptedGoogleToU"}
                        readOnly
                    />
                </>
            )}
            {visibilityProps.isTestDataStampVisible && (
                <GtbTextField
                    {...form.registerWithErrors("testDataStamp")}
                    readOnly={readOnlyProps.isTestDataStampReadOnly}
                    label="user.detail.testDataStamp_input"
                    className="testDataStamp"
                />
            )}
            <div className="totp">
                <LabeledCheckbox
                    id="totpReady"
                    label="user.detail.totpReady_input"
                    readOnly={true}
                    className="totpReady"
                    checked={totpReady}
                />
                {activateTotp && (
                    <GtbButton className="activateTotpButton" variant="secondary" onClick={activateTotp}>
                        {translation("user.detail.activateTotp_button")}
                    </GtbButton>
                )}
                {resetTotp && (
                    <GtbButton
                        className="resetTotpButton"
                        variant="secondary"
                        onClick={() =>
                            showResetTotpDialog({
                                title: "user.detail.resetSecretTotpToken_dialogTitle",
                                message: "user.detail.resetSecretTotpToken_dialogMessage",
                                footer: <DialogYesNoOptions onYes={resetTotp} onNo={closeResetTotpDialog} />,
                            })
                        }
                    >
                        {translation("user.detail.resetSecretTotpToken_button")}
                    </GtbButton>
                )}
            </div>
            {visibilityProps.isChangePasswordButtonVisible && (
                <GtbButton className="changePassword" onClick={showChangePasswordDialog}>
                    {translation("user.detail.changePassword_button")}
                </GtbButton>
            )}
        </div>
    );
}

export default LoginData;
