import makeReducerStore, { ActionType } from "../hooks/makeReducerStore";

type selectedItemKey = number | string;

type multiSelectActionTypes = "selectAll" | "deselectAll" | "toggle" | "updateItemCount";

type allSelectedType = "indeterminate" | "all" | "none";

interface MultiSelectStoreType {
    selectedItems: selectedItemKey[];
    allSelectedState: allSelectedType;
    itemCount: number;
    isBlacklist: boolean;
}

const reducer = (state: MultiSelectStoreType, action: MultiSelectAction): MultiSelectStoreType => {
    switch (action.type) {
        case "updateItemCount":
            return {
                selectedItems: [],
                allSelectedState: "none",
                itemCount: action.payload.itemCount as number,
                isBlacklist: false,
            };
        case "selectAll":
            return { ...state, selectedItems: [], allSelectedState: "all", isBlacklist: true };
        case "deselectAll":
            return { ...state, selectedItems: [], allSelectedState: "none", isBlacklist: false };
        case "toggle":
            const { id } = action.payload as { id: selectedItemKey };

            //Borderline case when only one item exists: switch allSelectedState between all and none. Indeterminate should not exist in this case.
            if (state.itemCount === 1) {
                if (state.allSelectedState === "all") {
                    return { ...state, selectedItems: [], allSelectedState: "none", isBlacklist: false };
                } else {
                    return { ...state, selectedItems: [], allSelectedState: "all", isBlacklist: true };
                }
            }

            if (state.allSelectedState === "all") {
                return { ...state, selectedItems: [id], allSelectedState: "indeterminate", isBlacklist: true };
            }
            if (state.allSelectedState === "none") {
                return { ...state, selectedItems: [id], allSelectedState: "indeterminate", isBlacklist: false };
            }

            if (state.selectedItems.includes(id)) {
                const allSelectedStateAfterRemove: allSelectedType = state.isBlacklist ? "all" : "none";
                return {
                    ...state,
                    selectedItems: [...state.selectedItems].filter((item) => item !== id),
                    allSelectedState: state.selectedItems.length <= 1 ? allSelectedStateAfterRemove : "indeterminate",
                };
            }
            const allSelectedStateAfterInclude: allSelectedType = state.isBlacklist ? "none" : "all";
            return {
                ...state,
                selectedItems: [...state.selectedItems, id],
                allSelectedState:
                    state.selectedItems.length + 1 >= state.itemCount ? allSelectedStateAfterInclude : "indeterminate",
            };
    }
};

interface MultiSelectPayloadType {
    id?: selectedItemKey;
    itemCount?: number;
}

interface MultiSelectAction extends ActionType<multiSelectActionTypes, MultiSelectPayloadType> {}

const [MultiSelectStoreProvider, useDispatchMultiSelectStore, useMultiSelectStore] = makeReducerStore<
    MultiSelectStoreType,
    MultiSelectAction
>(reducer, { selectedItems: [], allSelectedState: "none", itemCount: 0, isBlacklist: false });

export { MultiSelectStoreProvider, useMultiSelectStore, useDispatchMultiSelectStore };

export enum idHandlingMode {
    INCLUDE = "INCLUDE",
    EXCLUDE = "EXCLUDE",
}
