import { useLocation } from "react-router-dom";
import { useChangeNavigationContext } from "../components/breadcrumb/useNavigationContext";
import { SortOption, useQueryBuilder } from "../components/grid/component/useQueryBuilder";
import { useEffect } from "react";
import { I18nKey } from "../i18n/useGtbTranslation";

interface UseListViewProps<ItemType> {
    navigationContextLabel: I18nKey;
    baseQuery: string;
    defaultSortOption: SortOption<ItemType>;
    additionalQueryParameter?: { [key: string]: string };
}

function useListView<ItemType>({
    navigationContextLabel,
    baseQuery,
    defaultSortOption,
    additionalQueryParameter,
}: UseListViewProps<ItemType>) {
    const { key, state } = useLocation(); //key is needed as a dependency for the useEffect for navigation to the currently already opened route

    const { pushNavigationContext } = useChangeNavigationContext();

    const queryBuilder = useQueryBuilder<ItemType>(
        baseQuery,
        // @ts-ignore
        state?.parameter
            ? // @ts-ignore
              state?.parameter
            : { sort: defaultSortOption, filterActive: true, ...additionalQueryParameter }
    );

    // set Parameters if they changed
    useEffect(() => {
        if (!additionalQueryParameter) {
            return;
        }
        Object.entries(additionalQueryParameter)
            .filter(([paramKey, paramValue]) => queryBuilder.query.parameter[paramKey] !== paramValue)
            .forEach(([paramKey, paramValue]) => queryBuilder.setQueryParameter(paramKey, paramValue));
    }, [additionalQueryParameter, queryBuilder, queryBuilder.query.parameter]);

    useEffect(() => {
        pushNavigationContext(navigationContextLabel, navigationContextLabel as string, queryBuilder.query);
    }, [pushNavigationContext, queryBuilder.query, key, navigationContextLabel]);

    return {
        queryBuilder,
    };
}

export default useListView;
