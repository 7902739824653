import useDialog from "./useDialog";
import { useCallback } from "react";
import DialogHeader from "./DialogHeader";
import DialogFooter from "./DialogFooter";
import LoadingSpinner from "../LoadingSpinner";
import { I18nKey } from "../../i18n/useGtbTranslation";
import DialogContent from "./DialogContent";

export default function useLoadingDialog() {
    const { showDialog, closeDialog } = useDialog();

    return {
        showDialog: useCallback(
            (title: I18nKey) => {
                showDialog({
                    header: <DialogHeader title={title} variant="info" />,
                    content: (
                        <DialogContent>
                            <LoadingSpinner />
                        </DialogContent>
                    ),
                    footer: <DialogFooter />,
                });
            },
            [showDialog]
        ),
        closeDialog,
    };
}
