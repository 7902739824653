import gotsTheme from "./gotsTheme";
import demoTheme from "./demoTheme";

export type ThemeKey = "gots" | "demo";

export const themes: Record<ThemeKey, Theme> = { gots: gotsTheme, demo: demoTheme };

export interface Theme {
    "primary-color": string;
    "primary-background-color": string;
    "primary-text-color": string;
    "primary-neutral-color": string;
    "secondary-background-color": string;
    "secondary-text-color": string;
    "tertiary-text-color": string;
    "highlight-color": string;
    "error-color": string;
    "error-background-color": string;
    "warning-color": string;
    "warning-background-color": string;
    "info-color": string;
    "info-background-color": string;
    "main-menu-text-color": string;
    "main-menu-background-color": string;
    "dropdown-text-highlight-color": string;
    "header-border-color": string;
    "footer-text-color": string;
    "footer-background-color": string;
    "dashboard-background-color": string;
    "dashboard-icon-1-color": string;
    "dashboard-icon-1-background-color": string;
    "dashboard-icon-2-color": string;
    "dashboard-icon-2-background-color": string;
    "dashboard-icon-3-color": string;
    "dashboard-icon-3-background-color": string;
    "dashboard-icon-4-color": string;
    "dashboard-icon-4-background-color": string;
    "backdrop-background-color": string;
    "list-column-header-color": string;
    "list-column-header-highlight-color": string;
    "list-item-checkbox-color": string;
    "list-item-menu-color": string;
    "downtime-notification-color": string;
    "downtime-notification-background-color": string;
    "world-map-land": string;
    "world-map-sea": string;
    "product-traceability-color": string;
    "raw-material-traceability-color": string;
}
