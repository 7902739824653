import { VersionDetails } from "../versionTypes";
import VersionBasicData from "./VersionBasicData";
import VersionTcDeclaration from "./VersionTcDeclaration";
import { TypedDetailViewProps } from "../../../components/detailView/DetailView";
import { useHelpContext } from "../../../help/context-sensitivity/useHelpContext";
import Tabs, { TabKey } from "../../../components/tabs/Tabs";
import EntityDetailView from "../../../components/entity/EntityDetailView";

export default function VersionDetailView({
    form,
    readOnly,
    versionNumberReadOnly,
    standard,
    labelGradesVisible,
    logoUrl,
    ...rest
}: VersionDetailViewProps) {
    useHelpContext("standard-version-details");

    return (
        <EntityDetailView {...rest} form={form}>
            <Tabs<
                VersionDetails,
                TabKey<"standardVersion.detail.basicData_tab" | "standardVersion.detail.tcDeclarations_tab">
            >
                tabs={[
                    {
                        heading: "standardVersion.detail.basicData_tab",
                        element: (
                            <VersionBasicData
                                form={form}
                                readOnly={readOnly}
                                labelGradesVisible={labelGradesVisible}
                                versionNumberReadOnly={!!versionNumberReadOnly}
                                standard={standard}
                                logoUrl={logoUrl}
                            />
                        ),
                    },
                    {
                        heading: "standardVersion.detail.tcDeclarations_tab",
                        element: <VersionTcDeclaration form={form} readOnly={readOnly} />,
                    },
                ]}
                errors={form.form.formState.errors}
                errorToTab={{
                    id: "internal",
                    systemId: "internal",
                    versionNumber: "standardVersion.detail.basicData_tab",
                    standard: "standardVersion.detail.basicData_tab",
                    standardAcronym: "internal",
                    logo: "standardVersion.detail.basicData_tab",
                    startDate: "standardVersion.detail.basicData_tab",
                    endDate: "standardVersion.detail.basicData_tab",
                    defaultValidityPeriod: "standardVersion.detail.basicData_tab",
                    recertificationPeriod: "standardVersion.detail.basicData_tab",
                    recertificationBindingPeriod: "standardVersion.detail.basicData_tab",
                    rawMaterialsText: "standardVersion.detail.tcDeclarations_tab",
                    processingText: "standardVersion.detail.tcDeclarations_tab",
                    cugsText: "standardVersion.detail.tcDeclarations_tab",
                    hideUsdaNopCompliance: "standardVersion.detail.tcDeclarations_tab",
                }}
            />
        </EntityDetailView>
    );
}

export interface VersionDetailViewProps extends TypedDetailViewProps<VersionDetails> {
    readOnly: boolean;
    standard: string;
    labelGradesVisible: boolean;
    versionNumberReadOnly?: boolean;
    logoUrl: string;
}
