import { Nullable } from "../../utils/typeUtils";
import useDetailsCreate from "../../hooks/details/useDetailsCreate";
import useResolvedRoute from "../../components/routing/useResolvedRoute";
import { yupResolver } from "@hookform/resolvers/yup";
import { buyingRelationValidationSchema } from "./buyingRelationValidationSchema";
import { useMemo } from "react";
import { BuyingRelationDetailViewProps, BuyingRelationReadOnlyProps } from "./BuyingRelationDetailView";
import { BuyingRelationDetails } from "../buyingRelationTypes";
import useAuth from "../../auth/useAuth";
import UseConfirmationDialog from "../../components/dialog/UseConfirmationDialog";
import { pageUrl } from "../../utils/windowFunctions";

export default function useBuyingRelationCreate(): BuyingRelationDetailViewProps {
    const showRequestBusinessRelationDialog = UseConfirmationDialog();
    const {
        currentUser: { authorisationRole, organisation },
    } = useAuth();
    const { getResolvedId } = useResolvedRoute();

    const seller = useMemo(
        () =>
            getResolvedId("supplyChainOperator") ??
            (authorisationRole.type === "SUPPLY_CHAIN_OPERATOR" ? organisation : null),
        [authorisationRole.type, getResolvedId, organisation]
    );

    const initialData = useMemo<Nullable<BuyingRelationDetails>>(() => {
        return {
            id: null,
            sellerSupplyChainOperator: seller,
            buyerSupplyChainOperator: null,
            standard: null,
            accessLevel: "UNCONFIRMED",
            confirmationMethod: "EMAIL_TO_BUYER",
            startDate: new Date(),
            endDate: null,
            sellerSupplyChainOperatorBusinessName: null,
            buyerSupplyChainOperatorBusinessName: null,
            url: pageUrl() + "/buyingRelation/%s/confirmation/%s",
        };
    }, [seller]);

    const { detailViewProps, form, title } = useDetailsCreate<BuyingRelationDetails>({
        title: "buyingRelation.detail.newRecord_title",
        frontendRoute: "buyingRelation",
        initialData,
        resolver: yupResolver(buyingRelationValidationSchema),
        saveUrl: "/certificate-service/buying-relations",
        afterCreation: (savedItem) => {
            if (savedItem.accessLevel === "UNCONFIRMED" && savedItem.confirmationMethod === "EMAIL_TO_BUYER") {
                showRequestBusinessRelationDialog({
                    title: "buyingRelation.detail.businessRelationConfirmationRequested_dialogTitle",
                    message: {
                        key: "buyingRelation.detail.businessRelationConfirmationRequested_dialogText",
                        options: {
                            buyer: savedItem.buyerSupplyChainOperatorBusinessName,
                            seller: savedItem.sellerSupplyChainOperatorBusinessName,
                        },
                    },
                });
            }
        },
    });

    const readOnlyProps: BuyingRelationReadOnlyProps = {
        isAccessLevelReadOnly: !(authorisationRole.level === 2 && authorisationRole.type === "STANDARD_BODY"),
        isConfirmationMethodReadOnly: authorisationRole.level > 3 || authorisationRole.type === "SUPPLY_CHAIN_OPERATOR",
        isEndDateReadOnly: authorisationRole.level > 3 || authorisationRole.type === "SUPPLY_CHAIN_OPERATOR",
        isSellerReadOnly: !!seller,
        isStandardReadOnly: false,
        isStartDateReadOnly: false,
        isBuyerReadOnly: false,
    };

    return {
        title,
        detailViewProps,
        form,
        createRoute: "buyingRelation",
        readOnlyProps,
    };
}
