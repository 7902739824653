import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { TFuncKey, TOptions } from "i18next";

export type I18nKey = TFuncKey<"en"> | { key: TFuncKey<"en">; options: TOptions };

export type I18nFunction = (i18nKey: I18nKey) => string;

export default function useGtbTranslation() {
    const { t } = useTranslation();

    return useCallback<I18nFunction>(
        (i18nKey: I18nKey) => {
            if (typeof i18nKey === "string") {
                return t(i18nKey) as string;
            } else {
                return t(i18nKey.key, i18nKey.options) as string;
            }
        },
        [t]
    );
}
