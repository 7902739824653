import useIsAuthorised from "../auth/useIsAuthorised";
import { SortOption } from "../components/grid/component/useQueryBuilder";
import { useMemo } from "react";
import { GridColumn } from "../components/grid/component/Grid";
import useListView from "../hooks/useListView";
import { useDeleteAction, useShowDetailsAction } from "../utils/defaultActions";
import { SupplyChainOperatorDetails, SupplyChainOperatorSummary } from "./supplyChainOperatorTypes";
import {
    buildDataColumn,
    buildDateColumn,
    buildNumberColumn,
    buildTextColumn,
} from "../components/grid/ColumnProvider";
import useResolvedRoute, { getCreateUrl } from "../components/routing/useResolvedRoute";
import { EntityListViewGridProps } from "../components/entity/EntityListView";
import { EnumEntityType } from "../components/Input/pickList/typedEnumPicklist/enumEntityType";
import useSupplyChainOperatorDeduplicateAction from "./useSupplyChainOperatorDeduplicateAction";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";

export const defaultSupplyChainOperatorSortOption: SortOption<SupplyChainOperatorSummary | SupplyChainOperatorDetails> =
    {
        field: "systemId",
        direction: "ASC",
    };

const columns: GridColumn<SupplyChainOperatorSummary>[] = [
    buildTextColumn({ key: "systemId", title: "supplyChainOperator.list.systemId_column", minWidth: 134 }),
    buildTextColumn({ key: "businessName", title: "supplyChainOperator.list.businessName_column", minWidth: 174 }),
    buildTextColumn({
        key: "stateProvinceCountryAreaName",
        title: "supplyChainOperator.list.countryArea_column",
        minWidth: 155,
    }),
    buildDataColumn<SupplyChainOperatorSummary, EnumEntityType>({
        key: "organisationStatus",
        title: "supplyChainOperator.list.status_column",
        minWidth: 190,
        url: "/certificate-service/references/organisation-statuses",
        itemId: "id",
        itemLabel: "name",
    }),
    buildTextColumn({
        key: "addressDetails1",
        title: "supplyChainOperator.list.addressDetail1_column",
        minWidth: 180,
        visible: false,
    }),
    buildTextColumn({
        key: "addressDetails2",
        title: "supplyChainOperator.list.addressDetail2_column",
        minWidth: 180,
        visible: false,
    }),
    buildTextColumn({
        key: "addressDetails3",
        title: "supplyChainOperator.list.addressDetail3_column",
        minWidth: 180,
        visible: false,
    }),
    buildDateColumn({
        key: "endDate",
        title: "supplyChainOperator.list.endDate_column",
        minWidth: 124,
        visible: false,
    }),
    buildTextColumn({
        key: "legalName",
        title: "supplyChainOperator.list.legalName_column",
        minWidth: 145,
        visible: false,
    }),
    buildTextColumn({
        key: "legalNameInEnglish",
        title: "supplyChainOperator.list.legalNameInEnglish_column",
        minWidth: 145,
        visible: false,
    }),
    buildTextColumn({
        key: "organisationalContactPersonName",
        title: "supplyChainOperator.list.organisationalContactPersonName_column",
        minWidth: 260,
        visible: false,
    }),
    buildTextColumn({
        key: "organisationalContactPersonEmail",
        title: "supplyChainOperator.list.organisationalContactPersonEmail_column",
        minWidth: 259,
        visible: false,
    }),
    buildTextColumn({
        key: "organisationalEmail",
        title: "supplyChainOperator.list.organisationalEmailAddress_column",
        minWidth: 280,
        visible: false,
    }),
    buildTextColumn({
        key: "organisationalPhoneNumber",
        title: "supplyChainOperator.list.organisationalPhoneNumber_column",
        minWidth: 284,
        visible: false,
    }),
    buildTextColumn({
        key: "postCode",
        title: "supplyChainOperator.list.postcode_column",
        minWidth: 128,
        visible: false,
    }),
    buildTextColumn({
        key: "publicContactPersonName",
        title: "supplyChainOperator.list.publicContactPersonName_column",
        minWidth: 196,
        visible: false,
    }),
    buildTextColumn({
        key: "publicContactPersonEmail",
        title: "supplyChainOperator.list.publicContactPersonEmail_column",
        minWidth: 195,
        visible: false,
    }),
    buildDateColumn({
        key: "startDate",
        title: "supplyChainOperator.list.startDate_column",
        minWidth: 130,
        visible: false,
    }),
    buildTextColumn({
        key: "stateProvinceName",
        title: "supplyChainOperator.list.stateProvince_column",
        minWidth: 204,
        visible: false,
    }),
    buildTextColumn({ key: "town", title: "supplyChainOperator.list.town_column", minWidth: 123, visible: false }),
    buildTextColumn({
        key: "website",
        title: "supplyChainOperator.list.website_column",
        minWidth: 123,
        visible: false,
    }),
    buildTextColumn({
        key: "fixedRegistrationId",
        title: "supplyChainOperator.list.fixedRegistrationId_column",
        minWidth: 195,
        visible: false,
    }),
    buildTextColumn({
        key: "brandNames",
        title: "supplyChainOperator.list.brandNames_column",
        minWidth: 137,
        visible: false,
    }),
    buildDataColumn<SupplyChainOperatorSummary, EnumEntityType>({
        key: "membership",
        title: "supplyChainOperator.list.membership_column",
        minWidth: 135,
        url: "/certificate-service/references/memberships",
        itemId: "id",
        itemLabel: "name",
    }),
    buildNumberColumn({
        key: "latitude",
        title: "supplyChainOperator.list.latitude_column",
        allowDecimals: true,
        allowNegative: true,
        minWidth: 119,
        visible: false,
    }),
    buildNumberColumn({
        key: "longitude",
        title: "supplyChainOperator.list.longitude_column",
        allowDecimals: true,
        allowNegative: true,
        minWidth: 134,
        visible: false,
    }),
];

function useSupplyChainOperatorListView() {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedEditUrl } = useResolvedRoute();

    const {
        queryBuilder: { forceReload, columnOptions, query, queryCommands },
    } = useListView<SupplyChainOperatorSummary>({
        navigationContextLabel: "supplyChainOperator.breadcrumb",
        baseQuery: "/certificate-service/supply-chain-operators",
        defaultSortOption: defaultSupplyChainOperatorSortOption,
    });

    const showDetailsAction = useShowDetailsAction(
        (item: SupplyChainOperatorSummary) => getResolvedEditUrl("supplyChainOperator", item.id),
        query.parameter
    );

    const deleteAction = useDeleteAction(
        (item: SupplyChainOperatorSummary) => `/certificate-service/supply-chain-operators/${item.id}`,
        () => forceReload(),
        "deleteOperationLevelOrganisation"
    );

    const deduplicateAction = useSupplyChainOperatorDeduplicateAction(() => forceReload());

    const actions = useGridRowContextMenuActions(showDetailsAction, deleteAction, deduplicateAction);

    return useMemo<EntityListViewGridProps<SupplyChainOperatorSummary>>(() => {
        return {
            query,
            columns: columns,
            uniqueDataKey: "id" as keyof SupplyChainOperatorSummary,
            onItemDoubleClick: showDetailsAction,
            createUrl: hasAccessRight("createOperationLevelOrganisation")
                ? getCreateUrl("supplyChainOperator")
                : undefined,
            tableName: "supplyChainOperator",
            columnOptions,
            itemActions: actions,
            queryCommands,
            bulkDeleteAccessRight: () => hasAccessRight("deleteOperationLevelOrganisation"),
        };
    }, [query, showDetailsAction, hasAccessRight, columnOptions, actions, queryCommands]);
}

export default useSupplyChainOperatorListView;
