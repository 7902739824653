import { yupResolver } from "@hookform/resolvers/yup";
import { useMemo } from "react";
import { OrganicMaterialOriginDetails } from "../organicMaterialOriginTypes";
import { OrganicMaterialOriginDetailViewProps } from "./OrganicMaterialOriginDetailView";
import useResolvedRoute from "../../../../components/routing/useResolvedRoute";
import { Nullable } from "../../../../utils/typeUtils";
import useDetailsCreate from "../../../../hooks/details/useDetailsCreate";
import { organicMaterialOriginValidationSchema } from "./organicMaterialOriginValidationSchema";
import { useQuery } from "../../../../hooks/useAxios";
import { OrganicMaterialPurchaseReference } from "../../../organicMaterialPurchaseTypes";
import useConformanceCheck from "../../../../conformance/useConformanceCheck";

const readOnly: OrganicMaterialOriginDetailViewProps["readOnly"] = {
    organicMaterialProducerId: false,
    organicMaterialProduction: false,
    transferredQuantity: false,
};

export default function useOrganicMaterialOriginCreate(): OrganicMaterialOriginDetailViewProps {
    const { getResolvedId } = useResolvedRoute();
    const organicMaterialPurchaseId = getResolvedId("organicMaterialPurchase");
    const handleConformanceCheck = useConformanceCheck<OrganicMaterialOriginDetails>();

    const { data: organicMaterialPurchase } = useQuery<OrganicMaterialPurchaseReference>({
        url: "/certificate-service/references/organic-material-purchases/" + organicMaterialPurchaseId,
    });

    const initialData = useMemo<Nullable<OrganicMaterialOriginDetails>>(
        () => ({
            id: null,
            buyerLegalName: organicMaterialPurchase?.buyerLegalName ?? null,
            certifiedOrganicMaterialName: organicMaterialPurchase?.certifiedOrganicMaterialName ?? null,
            organicMaterialProducerId: null,
            organicMaterialProduction: null,
            organicMaterialProductionSystemId: null,
            organicMaterialPurchaseSystemId: null,
            transferredQuantity: null,
            organicMaterialProductionDisplayName: null,
            organicMaterialPurchaseId: organicMaterialPurchaseId ?? null,
            frozen: false,
            transferredQuantityFrozen: false,
        }),
        [
            organicMaterialPurchase?.buyerLegalName,
            organicMaterialPurchase?.certifiedOrganicMaterialName,
            organicMaterialPurchaseId,
        ]
    );

    const { detailViewProps, form, title } = useDetailsCreate<OrganicMaterialOriginDetails>({
        title: "organicMaterialOrigin.detail.newRecord_title",
        frontendRoute: "organicMaterialOrigin",
        initialData,
        resolver: yupResolver(organicMaterialOriginValidationSchema),
        saveUrl: `/certificate-service/organic-material-purchases/${organicMaterialPurchaseId}/organic-material-origins`,
        resolveErrors: (error, item, retrySaveAction) => handleConformanceCheck(error, item, retrySaveAction),
    });

    return {
        title,
        detailViewProps,
        form,
        readOnly,
        createRoute: "organicMaterialOrigin",
        organicMaterialPurchaseId,
    };
}
