import { Control, Controller } from "react-hook-form";
import GtbTextField, { GtbTextFieldProps } from "./GtbTextField";
import useGtbTranslation, { I18nKey } from "../../i18n/useGtbTranslation";

interface FixedRegistrationIdTextFieldProps extends GtbTextFieldProps {
    control: Control<any>;
    name: string;
}

export default function FixedRegistrationIdTextField({ control, name, ...rest }: FixedRegistrationIdTextFieldProps) {
    const translation = useGtbTranslation();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value }, fieldState }) => (
                <GtbTextField
                    className="fixedRegistrationId"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message && translation(fieldState.error?.message as I18nKey)}
                    value={value === null ? "" : value}
                    onChange={onChange}
                    {...rest}
                />
            )}
        />
    );
}
