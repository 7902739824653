import useUploadLogList from "./useUploadLogList";
import { useHelpContext } from "../help/context-sensitivity/useHelpContext";
import EntityListView, { EntityListViewGridProps } from "../components/entity/EntityListView";
import { UploadLogSummary } from "./uploadLogTypes";

function UploadLogListView() {
    const { listProps } = useUploadLogList();
    useHelpContext("upload-log-list");
    return <EntityListView heading={"uploadLog.list.header"} gridOptions={listProps} />;
}

export default UploadLogListView;

export interface UploadLogListViewProps {
    listProps: EntityListViewGridProps<UploadLogSummary>;
}
