import useExternalIdSubList from "../useExternalIdSubList";
import useResolvedRoute from "../../components/routing/useResolvedRoute";

export default function useExternalSupplyChainOperatorIdSubList() {
    const { getResolvedId } = useResolvedRoute();
    const supplyChainOperatorId = getResolvedId("supplyChainOperator");

    return useExternalIdSubList({
        tableName: "supplyChainOperatorExternalSupplyChainOperatorIds",
        i18nKey: "externalSupplyChainOperatorId",
        routeEnumKey: supplyChainOperatorId
            ? "supplyChainOperatorExternalSupplyChainOperatorId"
            : "externalSupplyChainOperatorId",
        parentIdParamValue: supplyChainOperatorId,
    });
}
