import { FieldValues } from "react-hook-form/dist/types";
import { DependentPicklist, DependentPicklistProps } from "./DependentPicklist";
import { getStandardVersionName, VersionReference } from "../../../../standard/version/versionTypes";
import { joinClassNames } from "../../../../utils/StringUtils";
import "./typedPickList.css";

interface AccreditedStandardVersionsPickListProps<FormItemType extends FieldValues>
    extends DependentPicklistProps<FormItemType, VersionReference> {}

export default function AccreditedStandardVersionsPickList<FormItemType extends FieldValues>({
    className,
    ...rest
}: AccreditedStandardVersionsPickListProps<FormItemType>) {
    return (
        <DependentPicklist
            className={joinClassNames("standardVersionPickList", className)}
            urlBuilder={(certificationBodyId) => ({
                url: `/certificate-service/references/standard-versions`,
                flags: { certificationBodyId },
            })}
            itemId="id"
            itemLabel={(item) => getStandardVersionName(item)}
            {...rest}
        />
    );
}
