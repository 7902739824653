import { UseGtbFormReturn } from "../../../../hooks/formHandling/useGtbForm";
import { joinClassNames } from "../../../../utils/StringUtils";
import GtbTextField from "../../../../components/Input/GtbTextField";
import "./tcProductBasicData.css";
import { TcProductDetails } from "../tcProductTypes";
import ScProductPickList from "../../../../components/Input/pickList/typedPicklist/ScProductPickList";
import { LabeledCheckbox } from "../../../../components/Input/Checkbox";
import { ScProductReference } from "../../../../scopeCertificate/details/products/scProductTypes";
import { TcProductReadOnlyProps } from "./TcProductDetailView";
import useSetFormValue from "../../../../hooks/formHandling/useSetFormValue";

export interface TcProductBasicDataProps {
    form: UseGtbFormReturn<TcProductDetails>;
    scopeCertificateId?: string;
    readOnlyProps: TcProductReadOnlyProps;
    selectedScProduct?: ScProductReference;
    cbProductReferenceNumber?: string;
}

export default function TcProductBasicData({
    form,
    scopeCertificateId,
    readOnlyProps,
    selectedScProduct,
    cbProductReferenceNumber,
}: TcProductBasicDataProps) {
    const setFormValue = useSetFormValue(form.form);

    return (
        <div className={joinClassNames("tcProductBasicData", "formGrid")}>
            <GtbTextField
                label="tcProduct.detail.tcProductNumber_input"
                {...form.registerWithErrors("tcProductNumber")}
                readOnly
                className="tcProductNumber"
            />
            {scopeCertificateId && (
                <ScProductPickList
                    label="tcProduct.detail.relatedScProduct_input"
                    control={form.form.control}
                    name="relatedScProduct"
                    readOnly={readOnlyProps.readOnly}
                    className="relatedScProduct"
                    flags={{ scopeCertificateId }}
                    onChangeListener={() => {
                        setFormValue("tcProcessors", []);
                        setFormValue("lastProcessor");
                    }}
                />
            )}
            {cbProductReferenceNumber && (
                <GtbTextField
                    label="tcProduct.detail.cbProductReferenceNumber_input"
                    {...form.registerWithErrors("cbProductReferenceNumber")}
                    readOnly
                    className="cbProductReferenceNumber"
                />
            )}
            <GtbTextField
                label="tcProduct.detail.seller_input"
                {...form.registerWithErrors("sellerLegalName")}
                readOnly
                className="seller"
            />
            <GtbTextField
                label="tcProduct.detail.sellerFacility_input"
                {...form.registerWithErrors("sellerFacilityBusinessName")}
                readOnly
                className="sellerFacility"
            />
            <GtbTextField
                label="tcProduct.detail.buyer_input"
                {...form.registerWithErrors("buyerLegalName")}
                readOnly
                className="buyer"
            />
            <GtbTextField
                label="tcProduct.detail.buyerFacility_input"
                {...form.registerWithErrors("buyerFacilityBusinessName")}
                readOnly
                className="buyerFacility"
            />
            <GtbTextField
                label="tcProduct.detail.productCategory_input"
                readOnly
                className="productCategory"
                value={selectedScProduct?.productCategoryName ?? ""}
            />
            <GtbTextField
                label="tcProduct.detail.productCategoryPrint_input"
                readOnly
                className="productCategoryPrint"
                value={selectedScProduct?.productCategoryPrint ?? ""}
            />
            <GtbTextField
                label="tcProduct.detail.productDetail_input"
                readOnly
                className="productDetail"
                value={selectedScProduct?.productDetailName ?? ""}
            />
            <GtbTextField
                label="tcProduct.detail.productDetailPrint_input"
                readOnly
                className="productDetailPrint"
                value={selectedScProduct?.productDetailPrint ?? ""}
            />
            <GtbTextField
                label="tcProduct.detail.labelGrade_input"
                readOnly
                className="labelGrade"
                value={selectedScProduct?.labelGradeLabelGrade ?? ""}
            />
            <LabeledCheckbox
                {...form.form.register("usdaNopCompliant")}
                readOnly
                id={"usda-nop-compliant"}
                label="tcProduct.detail.usdaNop_input"
                className="usdaNopCompliant"
            />
        </div>
    );
}
