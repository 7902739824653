import DropdownLinkItem from "../../../components/dropdown/DropdownLinkItem";
import { getBaseUrl } from "../../../components/routing/useResolvedRoute";
import MainMenuItem from "../MainMenuItem";
import useIsAuthorised from "../../../auth/useIsAuthorised";

export default function PreGtbTransactionCertificateMenu() {
    const { hasAccessRight } = useIsAuthorised();

    return (
        <MainMenuItem name="mainLayout.mainMenu.preGtbTcs_menuTitle">
            {hasAccessRight("readPreGtbTc") && (
                <>
                    <DropdownLinkItem
                        to={getBaseUrl("preGtbTransactionCertificate")}
                        label={"mainLayout.mainMenu.preGtbTcs_menuEntries.all_button"}
                    />
                    {hasAccessRight("readPreGtbTc", ["PRELIMINARY"]) && (
                        <DropdownLinkItem
                            to={getBaseUrl("searchPreliminaryPreGtbTcs")}
                            label="mainLayout.mainMenu.preGtbTcs_menuEntries.preliminary_button"
                        />
                    )}
                    {hasAccessRight("readPreGtbTc", ["FINAL"]) && (
                        <DropdownLinkItem
                            to={getBaseUrl("searchFinalPreGtbTcs")}
                            label="mainLayout.mainMenu.preGtbTcs_menuEntries.final_button"
                        />
                    )}
                    {hasAccessRight("readPreGtbTc", ["WITHDRAWN"]) && (
                        <DropdownLinkItem
                            to={getBaseUrl("searchWithdrawnPreGtbTcs")}
                            label="mainLayout.mainMenu.preGtbTcs_menuEntries.withdrawn_button"
                        />
                    )}
                </>
            )}
        </MainMenuItem>
    );
}
