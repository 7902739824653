import { FacilityDetailViewTabProps } from "./FacilityDetailView";
import OrganisationAddressData from "../../organisation/details/OrganisationAddressData";
import GtbTextField from "../../components/Input/GtbTextField";
import FixedRegistrationIdTextField from "../../components/Input/FixedRegistrationIdTextField";

export default function FacilityAddressData({ form, readOnlyProps, typeOfRegistrationId }: FacilityDetailViewTabProps) {
    return (
        <OrganisationAddressData form={form} readOnlyProps={readOnlyProps} organisationI18nKey="facility">
            <GtbTextField
                readOnly
                label="facility.detail.typeOfRegistrationId_input"
                className="typeOfRegistrationId"
                value={typeOfRegistrationId}
            />
            <FixedRegistrationIdTextField
                control={form.form.control}
                name="fixedRegistrationId"
                label="facility.detail.fixedRegistrationId_input"
                className="fixedRegistrationId"
                readOnly={readOnlyProps.isFixedRegistrationIdReadOnly}
            />
        </OrganisationAddressData>
    );
}
