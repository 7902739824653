import useByproductInputCombinationListView from "./useByproductInputCombinationListView";
import EntityListView from "../components/entity/EntityListView";
import { useHelpContext } from "../help/context-sensitivity/useHelpContext";

export default function ByproductInputCombinationListView() {
    const byproductInputCombinationGridOptions = useByproductInputCombinationListView();
    useHelpContext("by-product-input-combination-list");
    return (
        <EntityListView
            heading="byProductInputCombination.list.header"
            gridOptions={byproductInputCombinationGridOptions}
        />
    );
}
