import yup, { validateDate, validateString } from "../../utils/yupExtension";

export const timeBasedMailValidationSchema = yup.object().shape({
    subject: validateString().isRequired(),
    targetDate: validateDate().isRequired(),
    targetTime: validateDate().isRequired(),
    sent: yup.boolean(),
    body: validateString().isRequired(),
    dynamicUserGroup: validateString().isRequired(),
});
