import { useCallback } from "react";
import useRequireAuth, { requireConditionType } from "./useRequireAuth";
import LoadingSpinner from "../LoadingSpinner";
import { Navigate } from "react-router-dom";

interface GtbRouteProps {
    condition: requireConditionType;
    element: JSX.Element;
}

function RequireAuth({ condition, element }: GtbRouteProps) {
    const { isAuthorised, newTargetProperties } = useRequireAuth(condition);

    const buildConditionalComponent = useCallback(() => {
        if (isAuthorised === "pending") {
            return <LoadingSpinner />;
        }
        if (isAuthorised) {
            return element;
        }
        return <Navigate {...newTargetProperties} />;
    }, [element, isAuthorised, newTargetProperties]);

    return buildConditionalComponent();
}

export default RequireAuth;
