import useDetailsCreate from "../../hooks/details/useDetailsCreate";
import { yupResolver } from "@hookform/resolvers/yup";
import { Nullable } from "../../utils/typeUtils";
import { ScoLicenceDetails } from "../scoLicenceTypes";
import { ScoLicenceDetailViewProps, ScoLicenceReadOnly } from "./ScoLicenceDetailView";
import { scoLicenceValidationSchema } from "./scoLicenceValidationSchema";
import { useMemo } from "react";
import useAuth from "../../auth/useAuth";
import useResolvedRoute from "../../components/routing/useResolvedRoute";

export default function useScoLicenceCreate(): ScoLicenceDetailViewProps {
    const { getResolvedId } = useResolvedRoute();
    const scoId = getResolvedId("supplyChainOperator");
    const {
        currentUser: {
            authorisationRole: { level: authorisationLevel, type: authorisationType },
        },
    } = useAuth();

    const initialData = useMemo<Nullable<ScoLicenceDetails>>(
        () => ({
            id: null,
            standard: null,
            supplyChainOperator: scoId!,
            licenceNumber: null,
            startDate: null,
            endDate: null,
            bannedUntil: null,
            standardOfficialName: null,
        }),
        [scoId]
    );

    const { detailViewProps, form, title } = useDetailsCreate<ScoLicenceDetails>({
        title: "scoLicence.detail.newRecord_title",
        frontendRoute: "scoLicenceNumber",
        initialData,
        resolver: yupResolver(scoLicenceValidationSchema),
        saveUrl: `/certificate-service/sco-licences`,
    });

    const readOnly = useMemo<ScoLicenceReadOnly>(
        () => ({
            isReadOnly: false,
            isEndDateReadOnly: !(authorisationType === "STANDARD_BODY" && authorisationLevel === 2),
            isStandardReadOnly: !(authorisationType === "STANDARD_BODY" && authorisationLevel === 2),
            isBannedUntilReadOnly: true,
        }),
        [authorisationLevel, authorisationType]
    );

    return {
        detailViewProps,
        form,
        title,
        readOnly,
        createRoute: "scoLicenceNumber",
    };
}
