import { joinClassNames } from "../../utils/StringUtils";
import { HTMLAttributes } from "react";

export default function DialogContent({ className, children, ...divProps }: HTMLAttributes<HTMLDivElement>) {
    return (
        <div className={joinClassNames("dialog-content", className)} {...divProps}>
            {children}
        </div>
    );
}
