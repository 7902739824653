import { TypedDetailViewProps } from "../../components/detailView/DetailView";
import MaterialContentRuleBasicData from "./MaterialContentRuleBasicData";
import { MaterialContentRuleDetails } from "../materialContentRuleTypes";
import EntityDetailView from "../../components/entity/EntityDetailView";
import { useHelpContext } from "../../help/context-sensitivity/useHelpContext";
import { LabelGradeReference } from "../../labelGrade/labelGradeTypes";

export default function MaterialContentRuleDetailView({
    detailViewProps,
    form,
    readOnly,
    standard,
    standardVersion,
    labelGrade,
    subListVisible,
    ...rest
}: MaterialContentRuleDetailViewProps) {
    useHelpContext("material-content-rule-details");
    return (
        <EntityDetailView detailViewProps={detailViewProps} form={form} {...rest}>
            <MaterialContentRuleBasicData
                form={form}
                standard={standard}
                standardVersion={standardVersion}
                labelGrade={labelGrade}
                readOnly={readOnly}
                subListVisible={subListVisible}
            />
        </EntityDetailView>
    );
}

export interface MaterialContentRuleDetailViewProps extends TypedDetailViewProps<MaterialContentRuleDetails> {
    readOnly: boolean;
    standard?: string;
    standardVersion?: string;
    labelGrade?: LabelGradeReference;
    subListVisible?: boolean;
}
