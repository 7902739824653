import { FieldValues } from "react-hook-form/dist/types";
import { StateProvinceReference } from "../../../../stateProvince/stateProvinceTypes";
import { joinClassNames } from "../../../../utils/StringUtils";
import DataPickList, { DataPickListProps } from "../DataPickList";

export function MultiselectStateProvincePickList<FormItemType extends FieldValues>({
    className,
    ...rest
}: Omit<DataPickListProps<FormItemType, StateProvinceReference>, "queryUrl" | "itemId" | "itemLabel">) {
    return (
        <DataPickList
            className={joinClassNames("multiselectStateProvincePickList", className)}
            queryUrl={`/certificate-service/references/states-provinces`}
            multiple
            itemId="id"
            itemLabel="extendedDescription"
            {...rest}
        />
    );
}
