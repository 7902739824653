import useResolvedRoute from "../../../../components/routing/useResolvedRoute";
import { useQuery } from "../../../../hooks/useAxios";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { buildPreGtbProductValidationSchema } from "./preGtbProductValidationSchema";
import { useMemo } from "react";

export default function usePreGtbProductDetails() {
    const { getResolvedId } = useResolvedRoute();
    const preGtbTcId = getResolvedId("preGtbTransactionCertificate")!;

    const { data: labelGrades, isLoading: labelGradesLoading } = useQuery<[]>({
        url: `/certificate-service/references/label-grades?preGtbTcId=${preGtbTcId}`,
    });

    const labelGradesVisible = useMemo(() => !!labelGrades?.length, [labelGrades?.length]);

    return {
        preGtbTcId,
        resolver: yupResolver(buildPreGtbProductValidationSchema(labelGradesVisible)),
        labelGradesVisible,
        labelGradesLoading,
    };
}
