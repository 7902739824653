import DashboardGrid from "./DashboardGrid";
import { setTilePositionFunc, TileSettings } from "./useDashboard";
import ViewContainer from "../../components/ViewContainer";
import DashboardSettingsButton from "./DashboardSettingsButton";
import "./dashboard.css";

export default function Dashboard({
    maxPosition,
    tiles,
    setTilePosition,
    showDashboardSettingsDrawer,
}: {
    maxPosition: number;
    tiles: TileSettings[];
    setTilePosition: setTilePositionFunc;
    showDashboardSettingsDrawer: () => void;
}) {
    return (
        <ViewContainer className="dashboard">
            <DashboardSettingsButton showDashboardSettingsDrawer={showDashboardSettingsDrawer} />
            <DashboardGrid maxPosition={maxPosition} tiles={tiles} setTilePosition={setTilePosition} />
        </ViewContainer>
    );
}
