import { SvgCoordinate } from "./SvgMarker";
import { joinClassNames } from "../../utils/StringUtils";
import { worldMapHeight, worldMapWidth } from "./WorldMap";

const worldMapHypotenuse = Math.sqrt(worldMapHeight * worldMapHeight + worldMapWidth * worldMapWidth);
const minArcHeight = worldMapWidth / worldMapHeight;
export default function SvgArc({
    sourceCoordinate,
    targetCoordinate,
    className,
}: {
    sourceCoordinate: SvgCoordinate;
    targetCoordinate: SvgCoordinate;
    className: string;
}) {
    const centerX = (sourceCoordinate.x + targetCoordinate.x) / 2;
    const centerY = (sourceCoordinate.y + targetCoordinate.y) / 2;
    const deltaX = (targetCoordinate.x - sourceCoordinate.x) / 2;
    const deltaY = (targetCoordinate.y - sourceCoordinate.y) / 2;
    const hypotenuse = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
    const arcHeight = -1 * ((hypotenuse * 100) / worldMapHypotenuse + minArcHeight);

    return hypotenuse ? (
        <path
            className={joinClassNames("connectingArc", className)}
            vectorEffect="non-scaling-stroke"
            d={`M${sourceCoordinate.x} ${sourceCoordinate.y} Q${centerX + (deltaY / hypotenuse) * arcHeight} ${
                centerY - (deltaX / hypotenuse) * arcHeight
            } ${targetCoordinate.x} ${targetCoordinate.y}`}
        />
    ) : null;
}
