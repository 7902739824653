import Icon from "../../components/Icon";
import GtbLink, { GtbLinkProps } from "../../components/routing/GtbLink";
import useGtbTranslation, { I18nKey } from "../../i18n/useGtbTranslation";

export default function NavigatingTileAction({
    url,
    buttonText = "dashboard.tiles.goToList_button",
    target,
}: NavigatingTileActionProps) {
    const translation = useGtbTranslation();
    return (
        <GtbLink className={"secondaryLinkItemSmall"} to={url} target={target}>
            {translation(buttonText)}
            <Icon name="chevron-right" size={12} />
        </GtbLink>
    );
}

export interface NavigatingTileActionProps {
    url: string;
    buttonText?: I18nKey;
    target?: GtbLinkProps["target"];
}
