import usePreGtbProductDetails from "./usePreGtbProductDetails";
import { PreGtbProductDetails } from "../preGtbProductTypes";
import { defaultPreGtbProductSortOption } from "../usePreGtbProductSubList";
import { PreGtbProductDetailViewProps } from "./PreGtbProductDetailView";
import useIsAuthorised from "../../../../auth/useIsAuthorised";
import { useCallback, useMemo } from "react";
import useDomainEntityDetailsEdit from "../../../../hooks/details/useDomainEntityDetailsEdit";
import useResolvedRoute from "../../../../components/routing/useResolvedRoute";
import { preGtbTransactionCertificateReadOnlyByStatus } from "../../../preGtbTransactionCertificateTypes";

export default function usePreGtbProductEdit(): PreGtbProductDetailViewProps {
    const { hasAccessRight } = useIsAuthorised();

    const { resolver, preGtbTcId, labelGradesVisible, labelGradesLoading } = usePreGtbProductDetails();

    const { getResolvedId } = useResolvedRoute();
    const preGtbProductId = getResolvedId("preGtbProduct");

    const canSave = useCallback(
        (item: PreGtbProductDetails) =>
            !preGtbTransactionCertificateReadOnlyByStatus(item.preGtbTcStatus) &&
            hasAccessRight("updatePreGtbTc", [item.preGtbTcStatus]),
        [hasAccessRight]
    );

    const { detailViewProps, form, isReadOnly, title, recordNavigationProps, initialData } =
        useDomainEntityDetailsEdit<PreGtbProductDetails>({
            baseUrl: `/certificate-service/pre-gtb-tcs/${preGtbTcId}/pre-gtb-products/${preGtbProductId}`,
            frontendRoute: "preGtbProduct",
            recordNavigationDefaultSort: defaultPreGtbProductSortOption,
            titleBuilder: (item) => item.productNumber,
            resolver,
            canSave,
            historyColumns: [
                { identifier: "productNumber", title: "preGtbProduct.history.productNumber_column" },
                { identifier: "productCategory", title: "preGtbProduct.history.productCategory_column" },
                { identifier: "productCategoryPrint", title: "preGtbProduct.history.productCategoryPrint_column" },
                { identifier: "productDetail", title: "preGtbProduct.history.productDetail_column" },
                { identifier: "productDetailPrint", title: "preGtbProduct.history.productDetailPrint_column" },
                { identifier: "labelGrade", title: "preGtbProduct.history.labelGrade_column" },
                { identifier: "netShippingWeight", title: "preGtbProduct.history.netShippingWeight_column" },
                { identifier: "supplementaryWeight", title: "preGtbProduct.history.supplementaryWeight_column" },
                {
                    identifier: "initialAvailableQuantity",
                    title: "preGtbProduct.history.initialAvailableQuantity_column",
                },
                { identifier: "usdaNopCompliant", title: "preGtbProduct.history.usdaNopCompliant_column" },
            ],
        });

    const readOnly = useMemo<PreGtbProductDetailViewProps["readOnly"]>(
        () => ({
            productNumber: isReadOnly,
            productCategory: isReadOnly,
            productCategoryPrint: isReadOnly,
            productDetail: isReadOnly,
            productDetailPrint: isReadOnly,
            labelGrade: isReadOnly,
            netShippingWeight: isReadOnly,
            supplementaryWeight: isReadOnly,
            availableVolume: true,
            initialAvailableQuantity: isReadOnly,
            usdaNopCompliant: isReadOnly,
        }),
        [isReadOnly]
    );

    return {
        labelGradesVisible,
        preGtbTcId,
        detailViewProps: { ...detailViewProps, isLoading: detailViewProps.isLoading || labelGradesLoading },
        subListVisible: true,
        form,
        readOnly,
        title,
        recordNavigationProps,
        preGtbTcStatus: initialData?.preGtbTcStatus,
        createRoute:
            initialData &&
            !preGtbTransactionCertificateReadOnlyByStatus(initialData.preGtbTcStatus) &&
            hasAccessRight("updatePreGtbTc", [initialData.preGtbTcStatus])
                ? "preGtbProduct"
                : undefined,
    };
}
