import useDetailsCreate from "../../hooks/details/useDetailsCreate";
import { yupResolver } from "@hookform/resolvers/yup";
import { Nullable } from "../../utils/typeUtils";
import { AccreditationBodyDetails } from "../accreditationBodyTypes";
import { accreditationBodyValidationSchema } from "./accreditationBodyValidationSchema";
import { AccreditationBodyReadOnlyProps } from "./AccreditationBodyDetailView";
import { routeEnum } from "../../components/routing/useResolvedRoute";
import {
    organisationDefaultInitialData,
    useOrganisationDefaultReadOnlyProps,
} from "../../organisation/details/organisationDetailViewProps";
import { useMemo } from "react";

const initialData: Nullable<AccreditationBodyDetails> = {
    legalName: null,
    businessName: null,
    ...organisationDefaultInitialData,
};

function useAccreditationBodyCreate() {
    const { detailViewProps, form, title } = useDetailsCreate<AccreditationBodyDetails>({
        title: "accreditationBody.detail.newRecord_title",
        frontendRoute: "accreditationBody",
        initialData,
        resolver: yupResolver(accreditationBodyValidationSchema),
        saveUrl: "/certificate-service/accreditation-bodies",
    });

    const defaultReadOnlyProps = useOrganisationDefaultReadOnlyProps(false);

    const readOnlyProps = useMemo<AccreditationBodyReadOnlyProps>(
        () => ({
            ...defaultReadOnlyProps,
        }),
        [defaultReadOnlyProps]
    );

    return {
        title,
        detailViewProps,
        form,
        navigationEnabled: false,
        readOnlyProps,
        createRoute: "accreditationBody" as routeEnum,
    };
}

export default useAccreditationBodyCreate;
