import GtbIconButton from "./GtbIconButton";
import { useNavigationContext } from "./breadcrumb/useNavigationContext";
import { useGtbNavigate } from "./routing/GtbRouter";

export default function GoBackButton({ steps = 2, ...rest }) {
    const navigationContext = useNavigationContext();
    const navigate = useGtbNavigate();

    return (
        <GtbIconButton
            role="link"
            onClick={() => {
                const navContext = navigationContext[navigationContext.length - steps];
                navigate(navContext.to, navContext.state ? { state: navContext.state } : undefined, () =>
                    navContext.navigationContextCleanup()
                );
            }}
            label="components.detailView.toolbar.goBack_tooltip"
            iconName="back"
            {...rest}
        />
    );
}
