import React, { ReactNode } from "react";
import gtbLogo from "../assets/images/global_trace_base_logo_RGB.svg";
import Image from "../components/Image";
import "./external.css";
import Card from "../components/card/Card";
import Footer from "../mainLayout/footer/Footer";
import useGtbTranslation, { I18nKey } from "../i18n/useGtbTranslation";

function ExternalView({ children, header }: { children: ReactNode; header: I18nKey }) {
    const translation = useGtbTranslation();

    return (
        <div className="external-view">
            <Image src={gtbLogo} alt={"mainLayout.headerMenu.gtbLogo_alternativeText"} height={660} width={2593} />
            <Card>
                <h2 className="external-form-header">{translation(header)}</h2>
                {children}
            </Card>
            <Footer />
        </div>
    );
}

export default ExternalView;
