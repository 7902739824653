import useGtbTranslation, { I18nKey } from "../../../i18n/useGtbTranslation";
import { Controller, Path, PathValue } from "react-hook-form";
import { UseGtbFormReturn } from "../../../hooks/formHandling/useGtbForm";
import { FieldValues } from "react-hook-form/dist/types";
import InputWrapper, { InputWrapperProps } from "./InputWrapper";
import { joinClassNames } from "../../../utils/StringUtils";
import { ReactNode } from "react";

interface ControlledInputWrapperProps<FormItemType extends FieldValues>
    extends Omit<InputWrapperProps, "hasValue" | "children"> {
    control: UseGtbFormReturn<FormItemType>["form"]["control"];
    controlledInput: (
        value: PathValue<FormItemType, Path<FormItemType>>,
        onChange: (...event: any[]) => void
    ) => ReactNode;
    name: Path<FormItemType>;
}

export default function ControlledInputWrapper<FormItemType extends FieldValues>({
    control,
    name,
    className,
    controlledInput,
    ...rest
}: ControlledInputWrapperProps<FormItemType>) {
    const translation = useGtbTranslation();

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <InputWrapper
                    hasValue={!!value}
                    error={!!error}
                    helperText={error?.message && translation(error.message as I18nKey)}
                    className={joinClassNames("controlledInputWrapper", className)}
                    {...rest}
                >
                    {controlledInput(value, onChange)}
                </InputWrapper>
            )}
        />
    );
}
