import useDetailsCreate from "../../hooks/details/useDetailsCreate";
import { yupResolver } from "@hookform/resolvers/yup";
import { Nullable } from "../../utils/typeUtils";
import { useMemo } from "react";
import { ScopeCertificateDetails } from "../scopeCertificateTypes";
import { ScopeCertificateDetailViewProps } from "./ScopeCertificateDetailView";
import { scopeCertificateValidationSchema } from "./scopeCertificateValidationSchema";
import useAuth from "../../auth/useAuth";
import useResolvedRoute from "../../components/routing/useResolvedRoute";
import { useQuery } from "../../hooks/useAxios";
import { CertificationBodyReference } from "../../certificationBody/certificationBodyTypes";
import useNoActiveCertRelHandler from "./useNoActiveCertRelHandler";

export default function useScopeCertificateCreate(): ScopeCertificateDetailViewProps {
    const { getResolvedId } = useResolvedRoute();
    const handleNoActiveCertRelForSCOAndStandardVersionError = useNoActiveCertRelHandler();

    const {
        currentUser: { authorisationRole, organisation },
    } = useAuth();

    const currentCb = useMemo(
        () => (authorisationRole.type === "CERTIFICATION_BODY" ? organisation : null),
        [authorisationRole, organisation]
    );

    const currentSco = useMemo(
        () => (authorisationRole.type === "SUPPLY_CHAIN_OPERATOR" ? organisation : null),
        [authorisationRole, organisation]
    );

    const { data: relatedCbsForSco, isLoading: isLoadingRelatedCbsForSco } = useQuery<CertificationBodyReference[]>({
        url: "/certificate-service/references/certification-bodies?withActiveCertificationRelation=true",
        enabled: !!currentSco,
    });

    const parentSco = getResolvedId("supplyChainOperator") ?? null;

    const readOnly = useMemo<ScopeCertificateDetailViewProps["readOnly"]>(
        () => ({
            supplyChainOperator: !!parentSco || !!currentSco,
            standardVersion: false,
            certificationBody: !!currentCb,
            status: false,
            cbScReferenceNumber: false,
            approvingPerson: false,
            recertificationExpected: false,
            recertificationExpiryDate: true, // read only because it is hidden in status DRAFT
            additionalDeclaration: false,
        }),
        [currentCb, currentSco, parentSco]
    );

    const initialData = useMemo<Nullable<ScopeCertificateDetails>>(
        () => ({
            additionalDeclaration: null,
            approvingPerson: null,
            cbCertificate: null,
            cbScReferenceNumber: null,
            certificateDocument: null,
            certificationBody: currentCb ?? relatedCbsForSco?.[0]?.id ?? null,
            dateOfIssue: null,
            id: null,
            certificateOrigin: null,
            placeOfIssue: null,
            recertificationExpected: false,
            recertificationExpiryDate: null,
            revisionNumber: 0,
            scNumber: null,
            standardVersion: null,
            status: "DRAFT",
            supplyChainOperator: parentSco ?? currentSco,
            validFrom: null,
            validUntil: null,
            documentHash: null,
            documentSize: null,
            hashAlgorithm: null,
            recertifyable: false,
            revisionId: null,
            scRevisionNumber: null,
        }),
        [currentCb, parentSco, currentSco, relatedCbsForSco]
    );

    const { detailViewProps, form, title } = useDetailsCreate<ScopeCertificateDetails>({
        title: "scopeCertificate.detail.newRecord_title",
        frontendRoute: "scopeCertificate",
        initialData,
        resolver: yupResolver(scopeCertificateValidationSchema),
        saveUrl: "/certificate-service/scope-certificates",
        resolveErrors: (error) => handleNoActiveCertRelForSCOAndStandardVersionError(error, form),
    });

    return {
        title,
        detailViewProps: {
            ...detailViewProps,
            isLoading: detailViewProps.isLoading || (!!currentSco && isLoadingRelatedCbsForSco),
        },
        form,
        readOnly,
        createRoute: "scopeCertificate",
        isRecertificationExpiryVisible: false,
        documentTabVisible: false,
        subListVisible: false,
        humanReadableDocumentSize: null,
        revisionButtonVisibility: "hidden",
        withActiveCertificationRelation: !!currentSco,
    };
}
