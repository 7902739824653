import { Dispatch, DragEvent, ReactNode, SetStateAction, useCallback, useRef } from "react";
import { joinClassNames } from "../../utils/StringUtils";
import "./dropContainer.css";

export default function SortableDropContainer<ItemType>({
    items,
    setItems,
    renderItem,
    className,
    ...rest
}: SortableDropContainerProps<ItemType>) {
    const dragIndex = useRef<number>();

    const onDragStart = useCallback((e: DragEvent, index: number) => {
        setStyleOnDragStart(e);
        dragIndex.current = index;
    }, []);

    const onDrop = useCallback(
        (e: DragEvent, index: number) => {
            e.preventDefault();
            const fromIndex = dragIndex.current as number;
            let toIndex = index + 1;
            e.currentTarget.classList.remove("dragOver");
            if (fromIndex === toIndex) {
                return;
            }
            if (toIndex > fromIndex) {
                //the array will get shorter after removing the element so move toIndex one lower
                toIndex--;
            }
            setItems((oldItems) => {
                const copy = [...oldItems];
                const dragItem = copy.splice(fromIndex, 1)[0];
                copy.splice(toIndex, 0, dragItem);
                return copy;
            });
        },
        [setItems]
    );

    return (
        <div className={joinClassNames("dropContainer", className)} {...rest}>
            <div
                className="dragWrapper"
                onDragOver={onDragOver}
                onDragLeave={onDragLeave}
                onDrop={(e) => onDrop(e, -1)}
            />
            {items.map((item, index) => (
                <div
                    key={index}
                    className="dragWrapper"
                    draggable={true}
                    onDragOver={onDragOver}
                    onDragLeave={onDragLeave}
                    onDrop={(e) => onDrop(e, index)}
                    onDragStart={(e) => onDragStart(e, index)}
                    onDragEnd={removeStyleOnDragEnd}
                >
                    {renderItem(item)}
                </div>
            ))}
        </div>
    );
}

interface SortableDropContainerProps<ItemType> {
    items: ItemType[];
    setItems: Dispatch<SetStateAction<ItemType[]>>;
    renderItem: (item: ItemType) => ReactNode;
    className?: string;
}

export const setStyleOnDragStart = (e: DragEvent) => {
    e.currentTarget.classList.add("dragging");
    e.currentTarget.parentElement?.classList.add("dragOver");
};

export const removeStyleOnDragEnd = (e: DragEvent) => {
    e.currentTarget.classList.remove("dragging");
    e.currentTarget.classList.remove("dragOver");
    e.currentTarget.parentElement?.classList.remove("dragOver");
};

export const onDragOver = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    e.currentTarget.classList.add("dragOver");
};

export const onDragLeave = (e: DragEvent) => {
    e.preventDefault();
    e.currentTarget.classList.remove("dragOver");
};
