import useDialog from "../components/dialog/useDialog";
import { MouseEventHandler, useCallback, useMemo, useState } from "react";
import GtbIconButton from "../components/GtbIconButton";
import SimpleGrid, { SimpleGridColumn } from "../components/grid/component/SimpleGrid";
import GtbButton from "../components/GtbButton";
import Icon from "../components/Icon";
import "./historyDialog.css";
import { joinClassNames } from "../utils/StringUtils";
import { useQuery } from "../hooks/useAxios";
import LoadingSpinner from "../components/LoadingSpinner";
import { formatTimestamp } from "../utils/formatter";
import { useSimplifiedExport } from "../components/grid/hoc/useBulkExport";
import HtmlTooltip from "../components/HtmlTooltip";
import useGtbTranslation, { I18nKey } from "../i18n/useGtbTranslation";

export default function useHistoryDialog({ historyParams = { url: "", columns: [] } }: UseHistoryDialogProps) {
    const { showDialog: _showDialog, closeDialog } = useDialog();

    const showDialog = useCallback(() => {
        _showDialog({
            header: null,
            content: (
                <HistoryDialogContent
                    url={historyParams.url}
                    columns={historyParams.columns}
                    closeDialog={closeDialog}
                />
            ),
            footer: null,
            className: "history-dialog",
        });
    }, [_showDialog, closeDialog, historyParams]);

    return {
        showDialog,
        closeDialog,
    };
}

function HistoryDialogContent({ url, columns, closeDialog }: HistoryContentDialogProps) {
    const translation = useGtbTranslation();
    const { historyData, isHistoryLoading, allColumns } = useHistoryData(url, columns);
    const [isDownloading, setIsDownloading] = useState(false);
    const startDownload = useSimplifiedExport(
        url + "/export",
        "XLSX",
        allColumns.map((column) => {
            column.title = translation(column.title) as I18nKey;
            return column;
        }),
        () => setIsDownloading(false)
    );

    const changedValuesMap = useMemo(() => {
        const map = new Map();

        if (historyData && historyData.length > 1) {
            const reverseHistory = historyData.slice().reverse();

            for (let i = 1; i < historyData.length; i++) {
                const currentRevision = reverseHistory[i];
                const previousRevision = reverseHistory[i - 1];

                const ignoredAttributes = [
                    "revisionType",
                    "revisorId",
                    "revisorOrganisation",
                    "revisorFullName",
                    "timestamp",
                ];
                const changedValues = [];
                for (const attribute in currentRevision) {
                    if (
                        !ignoredAttributes.includes(attribute) &&
                        previousRevision[attribute] !== currentRevision[attribute]
                    ) {
                        changedValues.push(attribute);
                    }
                }

                if (changedValues.length > 0) {
                    map.set(currentRevision["timestamp"], changedValues);
                }
            }
        }

        return map;
    }, [historyData]);

    return (
        <>
            <div className={"dialog-header"}>
                <h2 id="dialogTitle">{translation("components.history.dialogTitle")}</h2>
                <div className="history-dialog-header-buttons">
                    {isHistoryLoading && (
                        <div style={{ marginRight: "8px" }}>
                            <LoadingSpinner size={32} />
                        </div>
                    )}
                    {!isHistoryLoading && (
                        <GtbButton
                            title={translation("components.history.exportButton_tooltip")}
                            aria-label={translation("components.history.exportButton_tooltip")}
                            className={joinClassNames("iconOnLeft", "history-dialog-download-button")}
                            variant="secondary"
                            size="medium"
                            onClick={() => {
                                startDownload();
                                setIsDownloading(true);
                            }}
                            disabled={isDownloading}
                        >
                            <Icon name="download" size={13} />
                            XLSX
                        </GtbButton>
                    )}
                    <GtbIconButton
                        label={"components.dialog.closeDialog"}
                        iconName={"cross"}
                        size={13}
                        onClick={closeDialog}
                    />
                </div>
            </div>
            <div className={"dialog-content history-dialog-content"}>
                {isHistoryLoading && <LoadingSpinner />}
                {!isHistoryLoading && (
                    <SimpleGrid
                        columns={allColumns}
                        data={historyData ?? []}
                        contentProvider={(entry, column) => {
                            const timestamp = entry["timestamp"];
                            const value = parseColumnValue(column, entry);
                            const hasChangedSincePreviousRevision = changedValuesMap.get(timestamp)?.includes(column);
                            return (
                                <HtmlTooltip title={value}>
                                    <div
                                        className={
                                            hasChangedSincePreviousRevision
                                                ? "history-dialog-changed-field"
                                                : "history-dialog-unchanged-field"
                                        }
                                    >
                                        {value}
                                    </div>
                                </HtmlTooltip>
                            );
                        }}
                    />
                )}
            </div>
        </>
    );
}

function useHistoryData(url: string, columns: SimpleGridColumn[]) {
    const { data: historyData, isLoading: isHistoryLoading } = useQuery<any[]>({
        url,
        enabled: true,
    });

    const allColumns = useMemo(() => {
        let concatenatedColumns: SimpleGridColumn[] = [];
        if (historyData) {
            concatenatedColumns.push({
                title: "components.history.revisionTimestamp_column",
                identifier: "timestamp",
            });
            concatenatedColumns = concatenatedColumns.concat(columns ?? []);
            concatenatedColumns.push({
                title: "components.history.revisionUserName_column",
                identifier: "revisorFullName",
            });
            concatenatedColumns.push({
                title: "components.history.revisionUserId_column",
                identifier: "revisorId",
            });
            concatenatedColumns.push({
                title: "components.history.revisionOrganisation_column",
                identifier: "revisorOrganisation",
            });
        }

        return concatenatedColumns;
    }, [historyData, columns]);

    return useMemo(() => {
        return { historyData, isHistoryLoading, allColumns };
    }, [allColumns, historyData, isHistoryLoading]);
}

function parseColumnValue(column: string, entry: any): string {
    let value = entry[column] ?? "";
    if (column === "timestamp") {
        value = formatTimestamp(new Date(value), "m");
    }
    return value;
}

export interface UseHistoryDialogProps {
    historyParams?: HistoryParams;
}

export interface HistoryParams {
    url: string;
    columns: SimpleGridColumn[];
}

interface HistoryContentDialogProps {
    url: string;
    columns: SimpleGridColumn[];
    closeDialog: MouseEventHandler<HTMLButtonElement>;
}
