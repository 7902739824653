export interface ApplicationLogSummary {
    id: string;
    timestamp: Date;
    logLevel: string;
    className: string;
    message: string;
    remarks: string;
    sessionId: string;
}

export interface ApplicationLogDetails {
    id: string;
    timestamp: Date;
    logLevel: logLevelEnum;
    className: string;
    message: string;
    remarks: string;
    sessionId: string;
}

export type logLevelEnum = "ERROR" | "WARN" | "INFO" | "DEBUG" | "TRACE";

export const logLevel: Record<logLevelEnum, string> = {
    ERROR: "Error",
    WARN: "Warn",
    INFO: "Info",
    DEBUG: "Debug",
    TRACE: "Trace",
};
