import { buildSchema, requiredObject, validateNumber, validateString } from "../utils/yupExtension";
import { OrganicMaterialPurchaseDetails, ScreeningResultEnum } from "./organicMaterialPurchaseTypes";
import * as yup from "yup";
import { BaseSchema } from "yup";

export const buildOrganicMaterialPurchaseValidationSchema = (
    gmoScreeningReportRequired: boolean,
    screeningResultRequired: boolean,
    rejectedRequired: boolean
) =>
    buildSchema<OrganicMaterialPurchaseDetails>({
        buyer: validateString().isRequired(),
        certifiedOrganicMaterial: validateString().isRequired(),
        gmoScreeningReport: gmoScreeningReportRequired ? requiredObject() : undefined,
        screeningResult: (screeningResultRequired
            ? validateString().isRequired()
            : validateString()) as BaseSchema<ScreeningResultEnum>,
        rejected: rejectedRequired ? validateString().isRequired() : validateString(),
        initialAvailableQuantity: validateNumber()
            .isRequired()
            .isLessEqual(
                yup.ref("initialQuantity"),
                "organicMaterialPurchase.detail.initialAvailableQuantity_input",
                "organicMaterialPurchase.detail.initialQuantity_input"
            ),
    });
