import { I18nKey } from "../../i18n/useGtbTranslation";
import GtbLink, { GtbLinkProps } from "./GtbLink";
import Icon, { IconName } from "../Icon";

interface GtbIconLinkProps extends Omit<GtbLinkProps, "children"> {
    label: I18nKey;
    iconName: IconName;
    size?: number;
}

export default function GtbIconLink({ label, iconName, size, ...rest }: GtbIconLinkProps) {
    return (
        <GtbLink aria-label={label} title={label} {...rest}>
            <Icon name={iconName} size={size} />
        </GtbLink>
    );
}
