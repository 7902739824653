import { SortOption } from "../components/grid/component/useQueryBuilder";
import { useMemo } from "react";
import { GridColumn } from "../components/grid/component/Grid";
import useListView from "../hooks/useListView";
import { useDeleteAction, useShowDetailsAction } from "../utils/defaultActions";
import { buildDataColumn, buildDateColumn, buildTextColumn } from "../components/grid/ColumnProvider";
import useResolvedRoute, { getCreateUrl } from "../components/routing/useResolvedRoute";
import { SupplyingRelationDetails, SupplyingRelationSummary } from "./supplyingRelationTypes";
import { EntityListViewGridProps } from "../components/entity/EntityListView";
import useIsAuthorised from "../auth/useIsAuthorised";
import { EnumEntityType } from "../components/Input/pickList/typedEnumPicklist/enumEntityType";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";

export const defaultSupplyingRelationSortOption: SortOption<SupplyingRelationSummary> = {
    field: "supplyChainOperatorBusinessName",
    direction: "ASC",
};

export const defaultSupplyingRelationDetailsSortOption: SortOption<SupplyingRelationDetails> = {
    field: "supplyChainOperator",
    direction: "ASC",
};

const columns: GridColumn<SupplyingRelationSummary>[] = [
    buildTextColumn({
        key: "supplyChainOperatorBusinessName",
        title: "supplyingRelation.list.supplyChainOperator_column",
        minWidth: 225,
    }),
    buildTextColumn({
        key: "organicMaterialProducerBusinessName",
        title: "supplyingRelation.list.organicMaterialProducer_column",
        minWidth: 257,
    }),
    buildDataColumn<SupplyingRelationSummary, EnumEntityType>({
        key: "accessLevel",
        title: "supplyingRelation.list.accessLevel_column",
        minWidth: 153,
        url: "/certificate-service/references/supplying-relation-access-levels",
        itemId: "id",
        itemLabel: "name",
    }),
    buildDateColumn({
        key: "startDate",
        title: "supplyingRelation.list.startDate_column",
        minWidth: 130,
        visible: false,
    }),
    buildDateColumn({
        key: "endDate",
        title: "supplyingRelation.list.endDate_column",
        minWidth: 124,
        visible: false,
    }),
];

export default function useSupplyingRelationsListView() {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedEditUrl } = useResolvedRoute();

    const {
        queryBuilder: { forceReload, columnOptions, query, queryCommands },
    } = useListView<SupplyingRelationSummary>({
        navigationContextLabel: "supplyingRelation.breadcrumb",
        baseQuery: "/certificate-service/supplying-relations",
        defaultSortOption: defaultSupplyingRelationSortOption,
    });

    const showDetailsAction = useShowDetailsAction(
        (item: SupplyingRelationSummary) => getResolvedEditUrl("supplyingRelation", item.id),
        query.parameter
    );

    const deleteAction = useDeleteAction(
        (item: SupplyingRelationSummary) => `/certificate-service/supplying-relations/${item.id}`,
        () => forceReload(),
        "deleteOperationLevelOrganisation"
    );

    const actions = useGridRowContextMenuActions(showDetailsAction, deleteAction);

    return useMemo<EntityListViewGridProps<SupplyingRelationSummary>>(() => {
        return {
            query,
            columns,
            uniqueDataKey: "id",
            onItemDoubleClick: showDetailsAction,
            createUrl: hasAccessRight("createOperationLevelOrganisation")
                ? getCreateUrl("supplyingRelation")
                : undefined,
            tableName: "supplyingRelations",
            columnOptions,
            itemActions: actions,
            queryCommands,
            bulkDeleteAccessRight: () => hasAccessRight("deleteOperationLevelOrganisation"),
        };
    }, [query, showDetailsAction, hasAccessRight, columnOptions, actions, queryCommands]);
}
