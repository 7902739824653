import { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import { joinClassNames } from "../../utils/StringUtils";
import GtbTextField, { GtbTextFieldProps } from "./GtbTextField";
import "./numberfield.css";
import { Path, useController } from "react-hook-form";
import { UseGtbFormReturn } from "../../hooks/formHandling/useGtbForm";
import { FieldValues } from "react-hook-form/dist/types";
import useGtbTranslation, { I18nKey } from "../../i18n/useGtbTranslation";
import { getNumberAsDecimalPresentation } from "../../utils/numberUtils";

export interface GtbNumberFieldProps extends Omit<GtbTextFieldProps, "type"> {
    unit?: string;
    decimalPlaces?: number;
    allowNegative?: boolean;
}

const GtbNumberField = forwardRef<HTMLDivElement, GtbNumberFieldProps>(
    ({ decimalPlaces = 0, allowNegative = false, onChange, unit, className, ...rest }: GtbNumberFieldProps, ref) => {
        const illegalCharacters = useMemo(
            () => new RegExp(`[^\\d${decimalPlaces ? "." : ""}${allowNegative ? "-" : ""}]`, "g"),
            [allowNegative, decimalPlaces]
        );

        const getExtractedNumberValue = useCallback(
            (value: string) => {
                return (
                    value
                        // replace everything but valid chars
                        .replace(illegalCharacters, "")
                        // replace every minus but a leading one
                        .replace(/(^(?<startingMinus>-?))|(?<notAMinus>[^-])|-/g, "$<startingMinus>$<notAMinus>")
                        // replace every dot but the first
                        .replace(/(^(?<untilFirstDot>[^.]*\.?))|(?<notADot>[^.])|\./g, "$<untilFirstDot>$<notADot>")
                        // replace possible extra digits
                        .replace(new RegExp(`^([^.]*\\.?.{0,${decimalPlaces}}).*$`), "$1")
                );
            },
            [decimalPlaces, illegalCharacters]
        );

        return (
            <GtbTextField
                ref={ref}
                onChange={(e) => {
                    // if the new value contains illegal characters remove them and set the cursor to the correct position again
                    const value = e.target.value;
                    const numberValue = getExtractedNumberValue(value.replace(",", "."));
                    let position = e.target.selectionStart;
                    position = position ? position - (value.length - numberValue.length) : position;
                    e.target.value = numberValue;
                    e.target.selectionStart = position;
                    e.target.selectionEnd = position;
                    onChange?.(e);
                }}
                className={joinClassNames("gtb-numberfield", className)}
                endAdornment={unit}
                {...rest}
            />
        );
    }
);

export default GtbNumberField;

interface ControlledNumberFieldProps<FormItemType extends FieldValues> extends GtbNumberFieldProps {
    control: UseGtbFormReturn<FormItemType>["form"]["control"];
    name: Path<FormItemType>;
    decimalPlaces: GtbNumberFieldProps["decimalPlaces"];
    onChangeListener?: GtbNumberFieldProps["onChange"];
}

interface TouchedPosition {
    target: HTMLInputElement;
    selectionStart: number | null;
    selectionEnd: number | null;
}

export function GtbNumberFieldOneFixedDecimal<FormItemType extends FieldValues>({
    control,
    name,
    onChangeListener,
    ...rest
}: ControlledNumberFieldProps<FormItemType>) {
    const translation = useGtbTranslation();

    const {
        field: { onChange, value, onBlur, ref },
        fieldState: { error },
    } = useController<FormItemType>({ control, name });

    const [isTouched, setIsTouched] = useState<TouchedPosition>();
    useEffect(() => {
        if (isTouched) {
            const numberValue = (+isTouched.target.value).toString();
            if (numberValue.length < isTouched.selectionStart!) {
                isTouched.target.value = numberValue + ".0";
            } else if (isTouched.target.value.endsWith(".0")) {
                isTouched.target.value = (+numberValue).toString();
            }
            isTouched.target.selectionStart = isTouched.selectionStart;
            isTouched.target.selectionEnd = isTouched.selectionEnd;
        }
    }, [isTouched]);

    const presentationValue = useMemo(() => {
        if (value === null || value === undefined || value === "") {
            return "";
        }
        if (isTouched) {
            return value;
        }
        return getNumberAsDecimalPresentation(value);
    }, [isTouched, value]);

    return (
        <GtbNumberField
            onChange={(event) => {
                onChange(event);
                onChangeListener?.(event);
            }}
            onFocus={(e) => {
                const target = e.target as HTMLInputElement;
                setIsTouched({
                    target,
                    selectionStart: target.selectionStart,
                    selectionEnd: target.selectionEnd,
                });
            }}
            value={presentationValue}
            error={!!error}
            helperText={error?.message && translation(error?.message as I18nKey)}
            ref={ref}
            onBlur={() => {
                onBlur();
                setIsTouched(undefined);
            }}
            {...rest}
        />
    );
}
