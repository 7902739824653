import { TypedDetailViewProps } from "../../components/detailView/DetailView";
import { UseGtbFormReturn } from "../../hooks/formHandling/useGtbForm";
import "../../components/spinner.css";
import ProductCategoryBasicData from "./ProductCategoryBasicData";
import { ProductCategoryDetails } from "../productCategoryTypes";
import { useHelpContext } from "../../help/context-sensitivity/useHelpContext";
import Tabs, { TabKey } from "../../components/tabs/Tabs";
import EntityDetailView from "../../components/entity/EntityDetailView";
import { buildLanguageVersionTab } from "../../i18n/LanguageVersionTab";
import "./productCategoryLanguageVersion.css";

export default function ProductCategoryDetailView({
    detailViewProps,
    form,
    isReadOnly,
    subListVisible,
    ...rest
}: ProductCategoryDetailViewProps) {
    useHelpContext("product-category-details");
    return (
        <EntityDetailView {...rest} detailViewProps={detailViewProps} form={form}>
            <Tabs<
                ProductCategoryDetails,
                TabKey<"productCategory.detail.basicData_tab" | "translation.languageVersion_tab">
            >
                tabs={[
                    {
                        heading: "productCategory.detail.basicData_tab",
                        element: (
                            <ProductCategoryBasicData
                                form={form}
                                readOnly={isReadOnly}
                                subListVisible={subListVisible}
                            />
                        ),
                    },
                    buildLanguageVersionTab({
                        form,
                        readOnly: isReadOnly,
                        baseName: "productCategory",
                        fields: ["name"],
                    }),
                ]}
                errors={form.form.formState.errors}
                errorToTab={{
                    id: "internal",
                    code: "productCategory.detail.basicData_tab",
                    pickListSequence: "productCategory.detail.basicData_tab",
                    name: "translation.languageVersion_tab",
                    startDate: "productCategory.detail.basicData_tab",
                    endDate: "productCategory.detail.basicData_tab",
                }}
            />
        </EntityDetailView>
    );
}

export interface ProductCategoryDetailViewProps extends TypedDetailViewProps<ProductCategoryDetails> {
    subListVisible?: boolean;
    isReadOnly: boolean;
}

export interface ProductCategoryDetailViewTabProps {
    form: UseGtbFormReturn<ProductCategoryDetails>;
    readOnly: boolean;
    subListVisible?: boolean;
}
