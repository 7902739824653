import useTlsCertificateAnalysisFailedDialog from "./useTlsCertificateAnalysisFailedDialog";
import { useCallback, useEffect, useMemo, useState } from "react";
import FileUploadComponent, { FileResource } from "../Input/UploadComponent/FileUploadComponent";
import { backendUrlType, useMutation, useQuery } from "../../hooks/useAxios";
import { error } from "../../utils/notification/notification";
import { env } from "../../env";
import { formatToHex } from "../../utils/formatter";
import useGtbTranslation from "../../i18n/useGtbTranslation";
import "./tlsCertificateComponent.css";
import LoadingSpinner from "../LoadingSpinner";
import useConfirmDeletionDialog from "../dialog/useConfirmDeletionDialog";

export interface CertificateData {
    certificateData: FileResource;
    organisation: string;
    serialNumber: string;
    validFrom: Date;
    expirationDate: Date;
}

export default function TlsCertificateComponent({
    tlsCertificateDataUrl,
    tlsCertificateFileUrl,
    readOnly,
}: {
    tlsCertificateDataUrl: backendUrlType;
    tlsCertificateFileUrl: backendUrlType;
    readOnly: boolean;
}) {
    const translation = useGtbTranslation();
    const showConfirmDeleteDialog = useConfirmDeletionDialog();
    const showCertificateAnalysisErrorDialog = useTlsCertificateAnalysisFailedDialog();

    const [tlsCertificate, setTlsCertificate] = useState<CertificateData | null>(null);

    const { runQuery: fetchCertificateMetaData, isLoading: isCertificateMetaDataLoading } = useQuery<CertificateData>({
        url: tlsCertificateDataUrl,
        enabled: false,
    });

    const { runQuery: runUpdateCertificateQuery, isLoading: isUploadCertificateLoading } = useMutation({
        method: "put",
        url: tlsCertificateDataUrl,
    });

    useEffect(() => {
        fetchCertificateMetaData()
            .then(setTlsCertificate)
            .catch((_error) => {
                if (_error.statusCode === 404) {
                    setTlsCertificate(null);
                } else {
                    error(translation("error.misc.generic_error"));
                }
            });
    }, [fetchCertificateMetaData, translation]);

    const handleUpload = useCallback(
        (data: CertificateData) => {
            runUpdateCertificateQuery({
                body: {
                    certificateData: data,
                },
            })
                .then(setTlsCertificate)
                .catch((_error) => {
                    showCertificateAnalysisErrorDialog(_error.data?.validationErrors?.tlscertificate?.split(", "));
                });
        },
        [runUpdateCertificateQuery, showCertificateAnalysisErrorDialog]
    );

    const handleDelete = useCallback(async () => {
        await showConfirmDeleteDialog(
            tlsCertificateDataUrl,
            () => setTlsCertificate(null),
            "components.tlsCertificate.confirm_delete_dialog_title",
            "components.tlsCertificate.confirm_delete_dialog_message",
            "components.tlsCertificate.delete_successful_message"
        );
    }, [showConfirmDeleteDialog, tlsCertificateDataUrl]);

    const isLoading = useMemo(
        () => isCertificateMetaDataLoading || isUploadCertificateLoading,
        [isCertificateMetaDataLoading, isUploadCertificateLoading]
    );

    return (
        <>
            {isLoading && <LoadingSpinner />}
            {!isLoading && (
                <>
                    <FileUploadComponent
                        readOnly={readOnly}
                        value={tlsCertificate?.certificateData ?? null}
                        label="components.tlsCertificate.upload_label"
                        name="tlsCertificate"
                        fileUrl={tlsCertificateFileUrl}
                        acceptedTypes={env.REACT_APP_VALID_UPLOAD_CERTIFICATE_TYPES}
                        onChange={async (changeEvent) => {
                            if (changeEvent.target.value != null) {
                                handleUpload(changeEvent.target.value);
                            } else {
                                await handleDelete();
                            }
                        }}
                        className={"tls_certificate_upload"}
                        uploadButtonDisabled={!!tlsCertificate}
                    />
                    {tlsCertificate && (
                        <div className="certificate_meta_data">
                            {translation({
                                key: "components.tlsCertificate.metadata",
                                options: {
                                    organisation: tlsCertificate.organisation,
                                    serialNumber: formatToHex(tlsCertificate.serialNumber),
                                    validFrom: tlsCertificate.validFrom,
                                    validTo: tlsCertificate.expirationDate,
                                },
                            })}
                        </div>
                    )}
                </>
            )}
        </>
    );
}
