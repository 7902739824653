import { Key, SyntheticEvent, useCallback, useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import GtbTextField, { GtbTextFieldProps } from "../GtbTextField";
import { joinClassNames } from "../../../utils/StringUtils";
import { warning } from "../../../utils/notification/notification";
import "./picklist.css";
import useGtbTranslation, { I18nKey } from "../../../i18n/useGtbTranslation";
import { Popper, PopperProps } from "@mui/material";

export interface GtbPickListProps<ItemType> extends PickListItemPresentationProps<ItemType> {
    id?: string;
    value?: ItemType[keyof ItemType] | ItemType[keyof ItemType][] | null;
    onChange?: (
        event: SyntheticEvent,
        value: ItemType[keyof ItemType] | ItemType[keyof ItemType][] | null,
        selectedOption: ItemType | null
    ) => void;
    label?: I18nKey;
    className?: string;
    error?: boolean;
    helperText?: string;
    readOnly?: boolean;
    data?: ItemType[];
    isLoading?: boolean;
    loadAdditionalItem?: (option: ItemType[keyof ItemType]) => Promise<ItemType>;
    multiple?: boolean;
    placeholder?: string;
    disabled?: boolean;
    borderless?: boolean;
    filter?: (option: ItemType) => boolean;
    "aria-labelledby"?: string;
}

export interface PickListItemPresentationProps<ItemType> {
    itemId: keyof ItemType;
    itemLabel: keyof ItemType | ((option: ItemType) => string);
    selectedItemLabel?: keyof ItemType | ((option: ItemType) => string);
}

const GtbPickList = <ItemType,>({
    readOnly,
    className,
    error,
    helperText,
    label,
    itemLabel,
    selectedItemLabel = itemLabel,
    itemId,
    data,
    loadAdditionalItem,
    isLoading,
    value,
    onChange,
    multiple = false,
    placeholder,
    disabled,
    borderless = false,
    filter,
    "aria-labelledby": ariaLabelledby,
    ...rest
}: GtbPickListProps<ItemType>) => {
    const [modifiedData, setModifiedData] = useState<ItemType[]>();
    const translation = useGtbTranslation();

    useEffect(() => {
        setModifiedData(undefined);
    }, [data, setModifiedData]);

    useEffect(() => {
        if (modifiedData) {
            return;
        }

        if (data && value && loadAdditionalItem) {
            if (multiple) {
                const dataToSet = data;
                const dataToLoad: ItemType[keyof ItemType][] = [];
                (value as ItemType[keyof ItemType][]).forEach((idValue: ItemType[keyof ItemType]) => {
                    if (!data.find((item) => item[itemId] === idValue)) {
                        dataToLoad.push(idValue);
                    }
                });
                if (dataToLoad.length === 0) {
                    setModifiedData(dataToSet);
                } else {
                    Promise.all(dataToLoad.map((idValue) => loadAdditionalItem(idValue))).then(
                        (loadedItems) => setModifiedData([...loadedItems, ...dataToSet]),
                        () => {
                            warning(
                                translation({
                                    key: "error.pickList.valueNotAvailable_message",
                                    options: { translatedLabel: label },
                                })
                            );
                            setModifiedData(data);
                        }
                    );
                }
            } else if (data.find((item) => item[itemId] === value)) {
                setModifiedData(data);
            } else {
                loadAdditionalItem(value as ItemType[keyof ItemType])
                    .then((additionalItem) => setModifiedData([additionalItem, ...data]))
                    .catch(() => {
                        warning(
                            translation({
                                key: "error.pickList.valueNotAvailable_message",
                                options: { translatedLabel: label },
                            })
                        );
                        setModifiedData(data);
                    });
            }
        } else if (data) {
            setModifiedData(data);
        }
    }, [data, itemId, loadAdditionalItem, modifiedData, value, multiple, disabled, translation, label]);

    const getLabel = useCallback(
        (option: ItemType[keyof ItemType], labelProp: typeof itemLabel) =>
            modifiedData
                ?.filter((item) => item[itemId] === option)
                .map((item) => (typeof labelProp === "function" ? labelProp(item) : item[labelProp]))[0] as string,
        [itemId, modifiedData]
    );

    return (
        <Autocomplete
            multiple={multiple}
            defaultValue={multiple ? [] : null}
            value={multiple && value === null ? [] : (value as ItemType[keyof ItemType])}
            options={modifiedData?.filter((item) => (filter ? filter(item) : true)).map((item) => item[itemId]) || []}
            readOnly={readOnly}
            loadingText={translation("components.spinner.accessibleLabel")}
            loading={isLoading || !modifiedData}
            disabled={disabled}
            PopperComponent={GtbPopper}
            renderOption={(props, option) => {
                return (
                    <li {...props} key={option as Key} className={joinClassNames("pick-list-item", props.className)}>
                        {getLabel(option, itemLabel)}
                    </li>
                );
            }}
            getOptionLabel={(option) => {
                if (isLoading || !modifiedData) {
                    return translation("components.spinner.accessibleLabel");
                }

                const selectedItem = getLabel(option, selectedItemLabel);
                if (selectedItem) {
                    return selectedItem;
                }

                return translation({
                    key: "error.pickList.itemNotAvailable_message",
                    options: { option: option },
                });
            }}
            className={className}
            renderInput={(params) => (
                <GtbTextField
                    className={joinClassNames("pick-list", className)}
                    {...(params as GtbTextFieldProps)}
                    readOnly={readOnly}
                    label={label}
                    rows={1}
                    minRows={1}
                    maxRows={1}
                    error={error}
                    helperText={helperText}
                    placeholder={placeholder}
                    disabled={disabled}
                    borderless={borderless}
                    aria-labelledby={ariaLabelledby}
                />
            )}
            clearText={translation({ key: "components.pickList.clearValue_button", options: { pickListLabel: label } })}
            autoHighlight
            openOnFocus
            onChange={(event, newValue) => {
                onChange?.(
                    event,
                    newValue,
                    newValue && modifiedData
                        ? modifiedData.filter((item) => item[itemId] === newValue).map((item) => item)[0]
                        : null
                );
            }}
            {...rest}
        />
    );
};

export default GtbPickList;

function GtbPopper({ className, ...rest }: PopperProps) {
    return <Popper className={joinClassNames("gtbPicklistPopper", className)} {...rest} />;
}
