import { useMemo } from "react";
import { I18nKey } from "../i18n/useGtbTranslation";

export default function useUserSearchContext(context: UserSearchContextSelection): UserSearchContext {
    const userSearchContextMap = useMemo(() => {
        const map = new Map<string, UserSearchContext>();
        map.set("all", {
            id: "ALL",
            navigationContextLabel: "user.list.allUsers_header",
        });

        map.set("active", {
            id: "ACTIVE",
            navigationContextLabel: "user.list.activeUsers_header",
        });

        map.set("inactive", {
            id: "INACTIVE",
            navigationContextLabel: "user.list.inactiveUsers_header",
        });

        return map;
    }, []);

    return userSearchContextMap.get(context)!;
}

export interface UserSearchContext {
    id: string;
    navigationContextLabel: I18nKey;
}

export type UserSearchContextSelection = "all" | "active" | "inactive";
