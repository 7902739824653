import { useDeduplicateAction } from "../utils/defaultActions";
import { OrganicMaterialProducerSummary } from "./organicMaterialProducerTypes";

export default function useOrganicMaterialProducerDeduplicateAction(afterDeduplicationAction: () => void) {
    return useDeduplicateAction(
        (item: OrganicMaterialProducerSummary) => item.id,
        "/certificate-service/organic-material-producers/reactive-deduplication",
        "omp",
        afterDeduplicationAction,
        1
    );
}
