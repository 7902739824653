import useExternalIdEdit from "../../details/useExternalIdEdit";
import useResolvedRoute, { routeEnum } from "../../../components/routing/useResolvedRoute";

export default function useExternalFacilityIdEdit() {
    const { getResolvedId } = useResolvedRoute();

    const routeEnumKey: routeEnum = getResolvedId("externalFacilityId")
        ? "externalFacilityId"
        : "facilityExternalFacilityId";

    return useExternalIdEdit({
        i18nKey: "externalFacilityId",
        routeEnumKey,
        recordNavigationParam: routeEnumKey === "externalFacilityId" ? "typeOfExternalIdParentEntity" : "parentId",
        recordNavigationParamValue: routeEnumKey === "externalFacilityId" ? "FACILITY" : getResolvedId("facility")!,
    });
}
