import { TypedDetailViewProps } from "../../../../../../components/detailView/DetailView";
import { ScProcessCategoryDetails } from "../scProcessCategoryTypes";
import { useHelpContext } from "../../../../../../help/context-sensitivity/useHelpContext";
import EntityDetailView from "../../../../../../components/entity/EntityDetailView";
import Tabs, { TabKey } from "../../../../../../components/tabs/Tabs";
import ScProcessCategoryBasicData from "./ScProcessCategoryBasicData";
import { scopeCertificateStatusType } from "../../../../../../auth/roleTypes";

export interface ScProcessCategoryDetailViewProps extends TypedDetailViewProps<ScProcessCategoryDetails> {
    readOnly: boolean;
    subListVisible: boolean;
    scopeCertificateStatus?: scopeCertificateStatusType;
}

export default function ScProcessCategoryDetailView({
    detailViewProps,
    form,
    readOnly,
    subListVisible,
    scopeCertificateStatus,
    ...rest
}: ScProcessCategoryDetailViewProps) {
    useHelpContext("sc-process-category-details");

    return (
        <EntityDetailView {...rest} detailViewProps={detailViewProps} form={form}>
            <Tabs<ScProcessCategoryDetails, ScProcessCategoryTabs>
                tabs={[
                    {
                        heading: "scProcessCategory.detail.basicData_tab",
                        element: (
                            <ScProcessCategoryBasicData
                                subListVisible={subListVisible}
                                form={form}
                                readOnly={readOnly}
                                scopeCertificateStatus={scopeCertificateStatus}
                            />
                        ),
                    },
                ]}
                errors={form.form.formState.errors}
                errorToTab={{
                    id: "internal",
                    scopeCertificateSupplyChainOperatorBusinessName: "scProcessCategory.detail.basicData_tab",
                    scProductScProductNumber: "scProcessCategory.detail.basicData_tab",
                    scProductProductCategoryName: "scProcessCategory.detail.basicData_tab",
                    scProductProductCategoryPrint: "scProcessCategory.detail.basicData_tab",
                    scProductProductDetailName: "scProcessCategory.detail.basicData_tab",
                    scProductProductDetailPrint: "scProcessCategory.detail.basicData_tab",
                    scProductLabelGradeLabelGrade: "scProcessCategory.detail.basicData_tab",
                    processCategory: "scProcessCategory.detail.basicData_tab",
                    processCategoryPrint: "scProcessCategory.detail.basicData_tab",
                    processCategoryName: "internal",
                    scopeCertificateStatus: "internal",
                }}
            />
        </EntityDetailView>
    );
}

type ScProcessCategoryTabs = TabKey<"scProcessCategory.detail.basicData_tab">;
