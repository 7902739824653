import { UseGtbFormReturn } from "../../../../hooks/formHandling/useGtbForm";
import { joinClassNames } from "../../../../utils/StringUtils";
import GtbTextField from "../../../../components/Input/GtbTextField";
import ProductCategoryPickList from "../../../../components/Input/pickList/typedPicklist/ProductCategoryPickList";
import ControlledTextField from "../../../../components/Input/ControlledTextField";
import ProductDetailPickList from "../../../../components/Input/pickList/typedPicklist/ProductDetailPickList";
import LabelGradePickList from "../../../../components/Input/pickList/typedPicklist/LabelGradePickList";
import useSetFormValue from "../../../../hooks/formHandling/useSetFormValue";
import { PreGtbProductDetails } from "../preGtbProductTypes";
import { GtbNumberFieldOneFixedDecimal } from "../../../../components/Input/GtbNumberField";
import useGtbTranslation from "../../../../i18n/useGtbTranslation";
import { LabeledCheckbox } from "../../../../components/Input/Checkbox";
import "./preGtbProductBasicData.css";

export interface PreGtbProductBasicDataProps {
    form: UseGtbFormReturn<PreGtbProductDetails>;
    preGtbTcId: string;
    labelGradesVisible: boolean;
    readOnly: {
        usdaNopCompliant: boolean;
        supplementaryWeight: boolean;
        netShippingWeight: boolean;
        labelGrade: boolean;
        productDetailPrint: boolean;
        productDetail: boolean;
        productCategoryPrint: boolean;
        productCategory: boolean;
        productNumber: boolean;
        availableVolume: boolean;
        initialAvailableQuantity: boolean;
    };
}

export default function PreGtbProductBasicData({
    form,
    preGtbTcId,
    labelGradesVisible,
    readOnly,
}: PreGtbProductBasicDataProps) {
    const setFormValue = useSetFormValue(form.form);
    const translation = useGtbTranslation();

    return (
        <div
            className={joinClassNames(
                "preGtbProductBasicData",
                "formGrid",
                labelGradesVisible ? "withLabelGrade" : null
            )}
        >
            <GtbTextField
                label="preGtbProduct.detail.tcNumber_input"
                {...form.registerWithErrors("preGtbTcTcNumber")}
                readOnly
                className="tcNumber"
            />
            <GtbTextField
                label="preGtbProduct.detail.productNumber_input"
                {...form.registerWithErrors("productNumber")}
                readOnly={readOnly.productNumber}
                className="productNumber"
            />
            <ProductCategoryPickList
                label="preGtbProduct.detail.productCategory_input"
                control={form.form.control}
                name="productCategory"
                readOnly={readOnly.productCategory}
                className="productCategory"
                onChangeListener={(_event, _value, selectedOption) =>
                    setFormValue("productCategoryPrint", selectedOption?.name)
                }
            />
            <ControlledTextField
                label="preGtbProduct.detail.productCategoryPrint_input"
                control={form.form.control}
                name="productCategoryPrint"
                readOnly={readOnly.productCategoryPrint}
                className="productCategoryPrint"
            />
            <ProductDetailPickList
                label="preGtbProduct.detail.productDetail_input"
                control={form.form.control}
                name="productDetail"
                readOnly={readOnly.productDetail}
                className="productDetail"
                onChangeListener={(_event, _value, selectedOption) =>
                    setFormValue("productDetailPrint", selectedOption?.name)
                }
            />
            <ControlledTextField
                label="preGtbProduct.detail.productDetailPrint_input"
                control={form.form.control}
                name="productDetailPrint"
                readOnly={readOnly.productDetailPrint}
                className="productDetailPrint"
            />
            {labelGradesVisible && (
                <LabelGradePickList
                    label="preGtbProduct.detail.labelGrade_input"
                    control={form.form.control}
                    name="labelGrade"
                    className="labelGrade"
                    itemLabel={(option) =>
                        `${option.standardVersionStandardAcronym} - ${option.standardVersionNumber} - ${option.labelGrade}`
                    }
                    flags={{ preGtbTcId: preGtbTcId }}
                    readOnly={readOnly.labelGrade}
                />
            )}
            <GtbNumberFieldOneFixedDecimal
                control={form.form.control}
                label="preGtbProduct.detail.netShippingWeight_input"
                name="netShippingWeight"
                readOnly={readOnly.netShippingWeight}
                className="netShippingWeight"
                decimalPlaces={1}
                unit="kg"
            />
            <GtbNumberFieldOneFixedDecimal
                control={form.form.control}
                label="preGtbProduct.detail.supplementaryWeight_input"
                name="supplementaryWeight"
                readOnly={readOnly.supplementaryWeight}
                className="supplementaryWeight"
                decimalPlaces={1}
                unit="kg"
            />
            <GtbNumberFieldOneFixedDecimal
                control={form.form.control}
                label="preGtbProduct.detail.certifiedWeight_input"
                aria-describedby="certifiedWeightDescription"
                name="certifiedWeight"
                readOnly
                className="certifiedWeight"
                decimalPlaces={1}
                unit="kg"
            />
            <span id="certifiedWeightDescription">
                {translation("preGtbProduct.detail.certifiedWeight_description")}
            </span>
            <GtbNumberFieldOneFixedDecimal
                control={form.form.control}
                label="preGtbProduct.detail.availableVolume_input"
                name="availableVolume"
                readOnly={readOnly.availableVolume}
                className="availableVolume"
                decimalPlaces={1}
                unit="kg"
            />
            <GtbNumberFieldOneFixedDecimal
                control={form.form.control}
                label="preGtbProduct.detail.initialAvailableQuantity_input"
                name="initialAvailableQuantity"
                readOnly={readOnly.initialAvailableQuantity}
                className="initialAvailableQuantity"
                decimalPlaces={1}
                unit="kg"
            />
            <LabeledCheckbox
                id="usdaNopCompliant"
                label="preGtbProduct.detail.usdaNopCompliant_input"
                {...form.form.register("usdaNopCompliant")}
                readOnly={readOnly.usdaNopCompliant}
                className="usdaNopCompliant"
            />
        </div>
    );
}
