import { ScProductDetailViewProps } from "./ScProductDetailView";
import { Nullable } from "../../../../utils/typeUtils";
import useDetailsCreate from "../../../../hooks/details/useDetailsCreate";
import { ScProductDetails } from "../scProductTypes";
import useScProductDetails from "./useScProductDetails";
import { useMemo } from "react";
import { useQuery } from "../../../../hooks/useAxios";
import { ScopeCertificateReference } from "../../../scopeCertificateTypes";

export default function useScProductCreate(): ScProductDetailViewProps {
    const { resolver, scopeCertificateId, labelGradesVisible, labelGradesLoading } = useScProductDetails();

    const { data: scopeCertificate, isLoading: isParentLoading } = useQuery<ScopeCertificateReference>({
        url: `/certificate-service/references/scope-certificates/${scopeCertificateId}`,
    });

    const initialData = useMemo<Nullable<ScProductDetails>>(
        () => ({
            id: null,
            supplyChainOperatorBusinessName: scopeCertificate?.supplyChainOperatorBusinessName ?? null,
            productCategoryPrint: null,
            productDetailPrint: null,
            productCategory: null,
            labelGrade: null,
            productDetail: null,
            cbScProductReferenceNumber: null,
            scProductNumber: null,
            scopeCertificateStatus: null,
        }),
        [scopeCertificate?.supplyChainOperatorBusinessName]
    );

    const { detailViewProps, form, title } = useDetailsCreate<ScProductDetails>({
        title: "scProduct.detail.newRecord_title",
        frontendRoute: "scProduct",
        initialData,
        resolver,
        saveUrl: `/certificate-service/scope-certificates/${scopeCertificateId}/sc-products`,
    });

    return {
        title,
        detailViewProps: {
            ...detailViewProps,
            isLoading: detailViewProps.isLoading || labelGradesLoading || isParentLoading,
        },
        form,
        scopeCertificateId,
        readOnly: false,
        labelGradesVisible,
        subListVisible: false,
        createRoute: "scProduct",
    };
}
