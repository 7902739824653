import "./fileUploadComponent.css";
import { ChangeEvent, InputHTMLAttributes, useCallback, useEffect, useMemo, useRef, useState } from "react";
import GtbButton from "../../GtbButton";
import { humanReadableFileSize } from "../../../utils/formatter";
import { joinClassNames } from "../../../utils/StringUtils";
import InputWrapper from "../inputWrapper/InputWrapper";
import GtbIconButton from "../../GtbIconButton";
import { env } from "../../../env";
import useGtbTranslation, { I18nKey } from "../../../i18n/useGtbTranslation";
import useGtbTooltip from "../../tooltip/useGtbTooltip";
import useLoadingDialog from "../../dialog/useLoadingDialog";
import FileDownloadLink, { FileDownloadLinkProps } from "./FileDownloadLink";

const MAX_FILE_SIZE_IN_BYTES = env.REACT_APP_MAX_FILE_SIZE_KB * 1000;

export const DOCX_TYPE: FileUploadComponentProps["acceptedTypes"] =
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
export const PDF_TYPE: FileUploadComponentProps["acceptedTypes"] = "application/pdf";
export const PNG_TYPE: FileUploadComponentProps["acceptedTypes"] = "image/png";
export const JPG_TYPE: FileUploadComponentProps["acceptedTypes"] = "image/jpeg";
export const GIF_TYPE: FileUploadComponentProps["acceptedTypes"] = "image/gif";

export default function FileUploadComponent({
    label,
    error,
    helperText,
    maxFileSize = MAX_FILE_SIZE_IN_BYTES,
    fileUrl,
    readOnly,
    value,
    onChange,
    name,
    className,
    acceptedTypes,
    uploadButtonDisabled,
}: FileUploadComponentProps) {
    const translation = useGtbTranslation();
    const hiddenFileInput = useRef<HTMLInputElement>(null);
    const { tooltipProps, tooltip } = useGtbTooltip(label);

    const [warning, setWarning] = useState<string>();

    useEffect(() => {
        if (value?.size && maxFileSize > 0 && value.size > maxFileSize) {
            setWarning(
                translation({
                    key: "components.fileUpload.fileToLarge_message",
                    options: { maxFileSize: humanReadableFileSize(maxFileSize) },
                })
            );
        } else {
            setWarning(undefined);
        }
    }, [maxFileSize, translation, value]);

    const setValue = useCallback(
        (newValue: FileResource | null) => {
            onChange({
                target: {
                    name,
                    value: newValue,
                },
            });
        },
        [name, onChange]
    );

    const { showDialog: showUploadingDialog, closeDialog: closeUploadingDialog } = useLoadingDialog();

    const handleChange = useCallback(
        (event: ChangeEvent<any>) => {
            if (!event.target.files) {
                setValue(null);
                return;
            }
            showUploadingDialog("components.fileUpload.uploading_dialogTitle");
            const file = event.target.files[0];
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                setValue({
                    name: file.name,
                    base64Content: (fileReader.result as string)?.split(",")[1],
                    type: file.type,
                    size: file.size,
                });
                closeUploadingDialog();
            };
            fileReader.onerror = () => {
                setValue(null);
                closeUploadingDialog();
            };
        },
        [closeUploadingDialog, setValue, showUploadingDialog]
    );

    const combinedHelperText = useMemo(() => {
        if (!helperText && !warning) {
            return undefined;
        } else {
            return [helperText, warning]
                .filter((t) => t)
                .map((t) => translation(t as I18nKey))
                .join("\n");
        }
    }, [helperText, translation, warning]);

    return (
        <InputWrapper
            label={label}
            labelFor={`__upload${label}`}
            hasValue={!!value}
            readOnly={readOnly}
            error={error}
            helperText={combinedHelperText}
            className={joinClassNames(className, warning ? "fileWarning" : null)}
            ignoreFocus={true}
        >
            <div className="fileUploadContainer" {...tooltipProps}>
                <div className="itemWrapper">
                    {value && (
                        <>
                            <FileDownloadLink file={value} fileUrl={fileUrl} />
                            {!readOnly && (
                                <GtbIconButton
                                    label="components.fileUpload.deleteFile_tooltip"
                                    iconName="cross"
                                    onClick={() => setValue(null)}
                                    size={12}
                                    className="deleteFile"
                                />
                            )}
                        </>
                    )}
                </div>
                {!readOnly && (
                    <GtbButton
                        id={`__upload${label}`}
                        className="upload"
                        onClick={() => hiddenFileInput.current?.click()}
                        disabled={uploadButtonDisabled}
                    >
                        {translation("components.fileUpload.upload_button")}
                    </GtbButton>
                )}
                <input
                    style={{ display: "none" }}
                    onChange={handleChange}
                    ref={hiddenFileInput}
                    type="file"
                    accept={acceptedTypes}
                    key={name + value?.name}
                />
            </div>
            {tooltip}
        </InputWrapper>
    );
}

export interface FileUploadComponentProps {
    name: string;
    label: I18nKey;
    acceptedTypes: InputHTMLAttributes<any>["accept"];
    value: FileResource | null;
    onChange: (...e: any) => void;
    error?: boolean;
    fileUrl: FileDownloadLinkProps["fileUrl"];
    className?: string;
    readOnly?: boolean;
    helperText?: string;
    maxFileSize?: number;
    uploadButtonDisabled?: boolean;
}

export interface FileResource {
    id?: string;
    base64Content?: string;
    name: string;
    type: string;
    size: number;
}
