import Form from "../components/Form";
import GtbButton from "../components/GtbButton";

import "./resetPassword.css";
import GtbPasswordField from "../components/Input/GtbPasswordField";
import useResetPassword, { ResetPasswordResultType } from "./useResetPassword";
import LoadingSpinner from "../components/LoadingSpinner";
import GtbLink from "../components/routing/GtbLink";
import { joinClassNames } from "../utils/StringUtils";
import "../components/button.css";
import { useEffect, useMemo } from "react";
import { useGtbNavigate } from "../components/routing/GtbRouter";
import { getBaseUrl } from "../components/routing/useResolvedRoute";
import useGtbTranslation from "../i18n/useGtbTranslation";

export default function ResetPasswordForm() {
    const translation = useGtbTranslation();

    const {
        register,
        onPasswordChange,
        isResetPasswordLoading,
        onResetPassword,
        resetPasswordSuccessful,
        resetPasswordResultType,
        resetPasswordLinkExpired,
        isCheckUUIDLoading,
    } = useResetPassword();

    if (isCheckUUIDLoading) {
        return <LoadingSpinner />;
    } else if (resetPasswordLinkExpired) {
        return (
            <div className="resetPassword">
                <div className="resetPassword-form-info">
                    <span>{translation("loginPage.reset_password_expired_form_info")}</span>
                </div>
            </div>
        );
    } else if (resetPasswordSuccessful) {
        return <SuccessComponent resetPasswordResultType={resetPasswordResultType} />;
    } else {
        return (
            <Form onSubmit={onResetPassword} className="resetPassword">
                <GtbPasswordField
                    label={"loginPage.reset_password_new_password_input"}
                    className="resetPassword-form-password"
                    autoFocus
                    aria-describedby="newPasswordDescription"
                    {...register("password", { onChange: onPasswordChange })}
                />
                <GtbPasswordField
                    label={"loginPage.reset_password_new_password_confirmation_input"}
                    className="resetPassword-form-passwordConfirmation"
                    {...register("passwordConfirmation")}
                />
                <GtbButton className="resetPassword-form-button" type="submit" disabled={isResetPasswordLoading}>
                    {translation("loginPage.reset_password_new_password_submit_button")}
                </GtbButton>
                <div className="resetPassword-form-info">
                    <span id="newPasswordDescription">{translation("loginPage.reset_password_password_policy")}</span>
                </div>
            </Form>
        );
    }
}

function SuccessComponent({ resetPasswordResultType }: { resetPasswordResultType: ResetPasswordResultType | null }) {
    const navigate = useGtbNavigate();
    const translation = useGtbTranslation();

    // As for this effect, the success component must be treated as a separate component
    // otherwise the keyboard shortcut will always be in effect and bypass any validation
    useEffect(() => {
        const eventHandler = (event: KeyboardEvent) => {
            if (event.key === "Enter") {
                event.preventDefault();
                navigate(getBaseUrl("login"), { replace: true });
            }
        };

        document.addEventListener("keydown", eventHandler);

        return () => document.removeEventListener("keydown", eventHandler);
    });

    const successMessage = useMemo(() => {
        switch (resetPasswordResultType) {
            case "SET":
                return translation("loginPage.reset_password_set_message");
            case "CHANGED_BLOCKED":
                return translation("loginPage.reset_password_changed_not_blocked_message");
            case "CHANGED_NOT_BLOCKED":
                return translation("loginPage.reset_password_changed_blocked_message");
            default:
                return null;
        }
    }, [resetPasswordResultType, translation]);

    return (
        <div className="resetPassword">
            <div className="resetPassword-form-info">
                <span id="proceedToLoginDescription">{successMessage}</span>
            </div>
            <GtbLink
                className={joinClassNames("resetPassword-form-button", "primary-button", "large-button")}
                to={getBaseUrl("login")}
                replace
                aria-describedby="proceedToLoginDescription"
            >
                {translation("loginPage.reset_password_proceed_link")}
            </GtbLink>
        </div>
    );
}
