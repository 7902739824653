import GridRow from "./GridRow";
import GridHeaderCell from "./GridHeaderCell";
import { GridColumn } from "./Grid";
import { ColumnOptions, itemKeyType } from "../ColumnProvider";
import withFilter from "../hoc/withFilter";

interface GridHeaderProps<T> {
    columns: GridColumn<T>[];
    columnOptions?: ColumnOptions<T>;
}

const FilteredHeaderCell = withFilter<any>(GridHeaderCell);

function GridHeader<T>({ columns, columnOptions }: GridHeaderProps<T>) {
    return (
        <thead>
            <GridRow>
                {columns.map((column: GridColumn<T>, index, allCols) => {
                    const ignoreCustomWidth = allCols.length - 1 === index;
                    const headerCellProps = {
                        key: column.key,
                        ...column.header,
                        sort: () => columnOptions?.sort.toggleSortOption(column.key as itemKeyType<T>),
                        sortDirection:
                            columnOptions?.sort?.sortOption.field === column.key
                                ? columnOptions?.sort.sortOption.direction
                                : undefined,
                        sortEnabled: column.header.sortEnabled,
                        width: ignoreCustomWidth ? undefined : column.header.width,
                        className: column.className,
                    };
                    return column.header.title && columnOptions?.filter ? (
                        <FilteredHeaderCell
                            {...headerCellProps}
                            filterKey={column.key as itemKeyType<T>}
                            // @ts-ignore is set in ColumnProvider.buildHeader
                            FilterComponent={column.header.FilterComponent}
                            filter={columnOptions.filter}
                        />
                    ) : (
                        <GridHeaderCell {...headerCellProps} />
                    );
                })}
            </GridRow>
        </thead>
    );
}

export default GridHeader;
