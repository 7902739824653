import "./externalFacilityIdDetailView.css";
import ExternalIdDetailView, { ExternalIdReadOnlyProps } from "../../details/ExternalIdDetailView";
import { useHelpContext } from "../../../help/context-sensitivity/useHelpContext";
import EntityDetailView from "../../../components/entity/EntityDetailView";
import { joinClassNames } from "../../../utils/StringUtils";
import FacilityPickList from "../../../components/Input/pickList/typedPicklist/FacilityPickList";
import { TypedDetailViewProps } from "../../../components/detailView/DetailView";
import { ExternalIdDetails } from "../../externalIdTypes";

export default function ExternalFacilityIdDetailView({
    detailViewProps,
    form,
    readOnlyProps,
    ...rest
}: ExternalFacilityIdDetailViewProps) {
    useHelpContext("external-facility-id-details");
    return (
        <EntityDetailView {...rest} detailViewProps={detailViewProps} form={form}>
            <div className={joinClassNames("externalFacilityIdDetailView", "externalIdDetailView", "formGrid")}>
                <FacilityPickList
                    control={form.form.control}
                    label="externalFacilityId.detail.facility_input"
                    className="facility"
                    readOnly={readOnlyProps.isParentEntityReadOnly}
                    name="parent"
                />
                <ExternalIdDetailView form={form} readOnlyProps={readOnlyProps} i18nKey="externalFacilityId" />
            </div>
        </EntityDetailView>
    );
}

export interface ExternalFacilityIdDetailViewProps extends TypedDetailViewProps<ExternalIdDetails> {
    readOnlyProps: ExternalIdReadOnlyProps;
}
