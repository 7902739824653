import "./typedPickList.css";
import { DependentPicklist, DependentPicklistProps } from "./DependentPicklist";
import { ScopeReference } from "../../../../scope/scopeTypes";
import { FieldValues } from "react-hook-form/dist/types";

export default function AccreditedScopesPickList<FormItemType extends FieldValues>({
    ...rest
}: DependentPicklistProps<FormItemType, ScopeReference>) {
    return (
        <DependentPicklist
            multiple={true}
            urlBuilder={(standardVersionId: any) =>
                `/certificate-service/references/scopes/by-standard-version/${standardVersionId}`
            }
            itemId="id"
            itemLabel="name"
            {...rest}
        />
    );
}
