import { useHelpContext } from "../../help/context-sensitivity/useHelpContext";
import { TypedDetailViewProps } from "../../components/detailView/DetailView";
import EntityDetailView from "../../components/entity/EntityDetailView";
import { joinClassNames } from "../../utils/StringUtils";
import "./buyingRelationData.css";
import ControlledDatePicker from "../../components/Input/datepicker/ControlledDatePicker";
import SupplyChainOperatorPickList from "../../components/Input/pickList/typedPicklist/SupplyChainOperatorPickList";
import StandardPickList from "../../components/Input/pickList/typedPicklist/StandardPickList";
import { BuyingRelationDetails } from "../buyingRelationTypes";
import EnumEntityRadiobuttonGroup from "../../components/Input/radiobutton/EnumEntityRadiobuttonGroup";
import useDefaultStandardPickListFlagsForRelations from "../../hooks/details/useDefaultStandardPickListFlagsForRelations";

export default function BuyingRelationDetailView({
    detailViewProps,
    form,
    readOnlyProps,
    ...rest
}: BuyingRelationDetailViewProps) {
    useHelpContext("buying-relations-details");

    const standardPickListFlags = useDefaultStandardPickListFlagsForRelations();

    return (
        <EntityDetailView {...rest} detailViewProps={detailViewProps} form={form}>
            <div className={joinClassNames("buyingRelationData", "formGrid")}>
                <SupplyChainOperatorPickList
                    control={form.form.control}
                    label="buyingRelation.detail.seller_input"
                    className="seller"
                    readOnly={readOnlyProps.isSellerReadOnly}
                    name="sellerSupplyChainOperator"
                />
                <SupplyChainOperatorPickList
                    control={form.form.control}
                    label="buyingRelation.detail.buyer_input"
                    className="buyer"
                    readOnly={readOnlyProps.isBuyerReadOnly}
                    name="buyerSupplyChainOperator"
                    flags={{ allActive: true }}
                />
                <StandardPickList
                    control={form.form.control}
                    name="standard"
                    label="buyingRelation.detail.standard_input"
                    className="standard"
                    readOnly={readOnlyProps.isStandardReadOnly}
                    itemLabel="acronym"
                    flags={standardPickListFlags}
                />
                <EnumEntityRadiobuttonGroup
                    label={"buyingRelation.detail.accessLevel_input"}
                    readOnly={readOnlyProps.isAccessLevelReadOnly}
                    {...form.form.register("accessLevel")}
                    className="accessLevel"
                    name="accessLevel"
                    referenceUrl="/certificate-service/references/buying-relation-access-levels"
                />
                <EnumEntityRadiobuttonGroup
                    label="buyingRelation.detail.confirmationMethod_input"
                    {...form.form.register("confirmationMethod")}
                    readOnly={readOnlyProps.isConfirmationMethodReadOnly}
                    className="confirmationMethod"
                    referenceUrl="/certificate-service/references/buying-relation-confirmation-methods"
                />
                <ControlledDatePicker
                    control={form.form.control}
                    name="startDate"
                    label="buyingRelation.detail.startDate_input"
                    className="startDate"
                    readOnly={readOnlyProps.isStartDateReadOnly}
                />
                <ControlledDatePicker
                    control={form.form.control}
                    name="endDate"
                    label="buyingRelation.detail.endDate_input"
                    className="endDate"
                    readOnly={readOnlyProps.isEndDateReadOnly}
                />
            </div>
        </EntityDetailView>
    );
}

export interface BuyingRelationDetailViewProps extends TypedDetailViewProps<BuyingRelationDetails> {
    readOnlyProps: BuyingRelationReadOnlyProps;
}

export interface BuyingRelationReadOnlyProps {
    isSellerReadOnly: boolean;
    isBuyerReadOnly: boolean;
    isStandardReadOnly: boolean;
    isAccessLevelReadOnly: boolean;
    isConfirmationMethodReadOnly: boolean;
    isStartDateReadOnly: boolean;
    isEndDateReadOnly: boolean;
}
