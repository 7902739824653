import { joinClassNames } from "../../utils/StringUtils";
import "./facilityOtherData.css";
import { FacilityDetailViewTabProps } from "./FacilityDetailView";
import GtbNumberField from "../../components/Input/GtbNumberField";
import InfinityScrollSubList from "../../components/grid/InfinityScrollSubList";
import useExternalFacilityIdSubList from "../../externalId/facility/useExternalFacilityIdSubList";
import GtbTextField from "../../components/Input/GtbTextField";
import { I18nKey } from "../../i18n/useGtbTranslation";

export default function FacilityOtherData({
    form,
    readOnlyProps,
    externalIdComponentType,
}: FacilityDetailViewTabProps) {
    const externalFacilityIdsSubListOptions = useExternalFacilityIdSubList();

    return (
        <div className={joinClassNames("facilityOtherData", "formGrid")}>
            <GtbNumberField
                {...form.registerNumberWithErrors("numberOfEmployees")}
                readOnly={readOnlyProps.isNumberOfEmployeesReadOnly}
                label="facility.detail.numberOfEmployees_input"
                className="numberOfEmployees"
            />
            {externalIdComponentType === "FIELD" && (
                <GtbTextField
                    {...form.registerWithErrors("cbInternalId")}
                    label={`facility.detail.cbInternalId_input` as I18nKey}
                    className="cbInternalId"
                    readOnly={readOnlyProps.isCbInternalIdReadOnly}
                />
            )}
            {externalIdComponentType === "SUBLIST" && (
                <InfinityScrollSubList {...externalFacilityIdsSubListOptions} className="externalIds" />
            )}
        </div>
    );
}
