import "./header.css";
import UserAccountButton from "./UserAccountButton";
import HeaderLinkDropdown from "./HeaderLinkDropdown";
import DropdownLinkItem from "../../components/dropdown/DropdownLinkItem";
import useIsAuthorised from "../../auth/useIsAuthorised";
import ContextSensitiveHelpLink from "../../help/context-sensitivity/ContextSensitiveHelpLink";
import { getBaseUrl } from "../../components/routing/useResolvedRoute";
import useBulkResetTermsOfUseAcceptanceDialog from "../../user/termsOfUse/useBulkResetTermsOfUseAcceptanceDialog";
import useAuth from "../../auth/useAuth";
import I18nDropdownItem from "../../components/dropdown/I18nDropdownItem";
import useLockchainExportDownload from "../../hooks/useLockchainExportDownload";
import DropdownMenuItem from "../../components/dropdown/DropdownMenuItem";
import useGtbTranslation from "../../i18n/useGtbTranslation";
import { useSetDropdownStore } from "../../components/dropdown/dropdownStore";
import DropdownItem from "../../components/dropdown/DropdownItem";
import useEmergencyShutdownDialog from "../../emergencyShutdown/useEmergencyShutdownDialog";

export default function HeaderMenus() {
    const showBulkResetToUAcceptanceDialog = useBulkResetTermsOfUseAcceptanceDialog();
    const showEmergencyShutdownDialog = useEmergencyShutdownDialog();
    const { isRoleLevelAtLeast, hasAccessRight, isSystemAdmin } = useIsAuthorised();
    const translation = useGtbTranslation();
    const setDropdownStore = useSetDropdownStore();
    const lockchainExportUrl = "/certificate-service/lockchain/export";
    const downloadLockchainExport = useLockchainExportDownload(lockchainExportUrl);
    const {
        currentUser: {
            authorisationRole: { level: authorisationRoleLevel },
        },
    } = useAuth();

    return (
        <>
            <ul className="headerLinks">
                {hasAccessRight("readUserAccount") && (
                    <HeaderLinkDropdown caption={"mainLayout.headerMenu.users_menuTitle"} icon="users">
                        <DropdownLinkItem
                            to={getBaseUrl("user")}
                            label={"mainLayout.headerMenu.users_menuEntries.all_button"}
                        />
                        <DropdownLinkItem
                            to={getBaseUrl("searchActiveUser")}
                            label={"mainLayout.headerMenu.users_menuEntries.active_button"}
                        />
                        <DropdownLinkItem
                            to={getBaseUrl("searchInactiveUser")}
                            label={"mainLayout.headerMenu.users_menuEntries.inactive_button"}
                        />
                        {isRoleLevelAtLeast(1) && (
                            <I18nDropdownItem
                                label="mainLayout.headerMenu.users_menuEntries.reset_all_tou_consent_button"
                                className="warningDropdownButton"
                                onClick={showBulkResetToUAcceptanceDialog}
                            />
                        )}
                    </HeaderLinkDropdown>
                )}
                {(hasAccessRight("readProductConversion") ||
                    hasAccessRight("readAuthorisationRole") ||
                    hasAccessRight("manageSystemParameter") ||
                    hasAccessRight("manageEventDetection") ||
                    hasAccessRight("useApplicationLog") ||
                    hasAccessRight("useUploadLog") ||
                    hasAccessRight("useMailTemplate") ||
                    hasAccessRight("readTimeBasedMail") ||
                    authorisationRoleLevel === 1 ||
                    authorisationRoleLevel === 2) && (
                    <HeaderLinkDropdown caption={"mainLayout.headerMenu.systemSettings_menuTitle"} icon="settings">
                        {hasAccessRight("readProductConversion") && (
                            <>
                                <DropdownLinkItem
                                    to={getBaseUrl("productConversion")}
                                    label={"mainLayout.headerMenu.systemSettings_menuEntries.productConversions_button"}
                                />
                                <DropdownLinkItem
                                    to={getBaseUrl("byproductInputCombination")}
                                    label={
                                        "mainLayout.headerMenu.systemSettings_menuEntries.byProductInputCombinations_button"
                                    }
                                />
                            </>
                        )}
                        {hasAccessRight("readAuthorisationRole") && (
                            <DropdownLinkItem
                                to={getBaseUrl("authorisationRole")}
                                label={"mainLayout.headerMenu.systemSettings_menuEntries.authorisationRoles_button"}
                            />
                        )}
                        {hasAccessRight("manageSystemParameter") && (
                            <DropdownLinkItem
                                to={getBaseUrl("systemParameters")}
                                label={"mainLayout.headerMenu.systemSettings_menuEntries.systemParameters_button"}
                            />
                        )}
                        {hasAccessRight("useMailTemplate") && (
                            <DropdownLinkItem
                                to={getBaseUrl("mailTemplate")}
                                label={"mainLayout.headerMenu.systemSettings_menuEntries.mailTemplate_button"}
                            />
                        )}
                        {hasAccessRight("readTimeBasedMail") && (
                            <DropdownLinkItem
                                to={getBaseUrl("timeBasedMail")}
                                label={"mainLayout.headerMenu.systemSettings_menuEntries.timeBasedMail_button"}
                            />
                        )}
                        {isSystemAdmin && <DropdownLinkItem
                            to={getBaseUrl("dynamicUserGroup")}
                            label={"mainLayout.headerMenu.systemSettings_menuEntries.dynamicUserGroup_button"}
                        />}
                        {(authorisationRoleLevel === 1 || authorisationRoleLevel === 2) && (
                            <DropdownLinkItem
                                to={getBaseUrl("allocatedTcNumber")}
                                label={"mainLayout.headerMenu.systemSettings_menuEntries.allocatedTcNumbers_button"}
                            />
                        )}
                        {hasAccessRight("manageEventDetection") && (
                            <DropdownLinkItem
                                to={getBaseUrl("eventMonitor")}
                                label={"mainLayout.headerMenu.systemSettings_menuEntries.siemConfiguration_button"}
                            />
                        )}
                        {authorisationRoleLevel === 1 && (
                            <DropdownMenuItem>
                                <a
                                    href={"/api" + lockchainExportUrl}
                                    download={"Lockchain export YYYY-MM-DD hhmmss.json"}
                                    onClick={(e) => {
                                        setDropdownStore(false);
                                        e.preventDefault();
                                        downloadLockchainExport();
                                    }}
                                >
                                    {translation(
                                        "mainLayout.headerMenu.systemSettings_menuEntries.exportLockchain_button",
                                    )}
                                </a>
                            </DropdownMenuItem>
                        )}
                        {isSystemAdmin && (
                            <DropdownItem className="warningDropdownButton" onClick={showEmergencyShutdownDialog}>
                                {translation(
                                    "mainLayout.headerMenu.systemSettings_menuEntries.emergencyShutdown_button",
                                )}
                            </DropdownItem>
                        )}
                        {hasAccessRight("useApplicationLog") && (
                            <DropdownLinkItem
                                to={getBaseUrl("applicationLog")}
                                label={"mainLayout.headerMenu.systemSettings_menuEntries.applicationLog_button"}
                            />
                        )}
                        {(authorisationRoleLevel === 1 || authorisationRoleLevel === 2) && (
                            <DropdownLinkItem
                                to={getBaseUrl("deduplicationLog")}
                                label={"mainLayout.headerMenu.systemSettings_menuEntries.deduplicationLog_button"}
                            />
                        )}
                        {hasAccessRight("useUploadLog") && (
                            <DropdownLinkItem
                                to={getBaseUrl("uploadLog")}
                                label={"mainLayout.headerMenu.systemSettings_menuEntries.uploadLog_button"}
                            />
                        )}
                    </HeaderLinkDropdown>
                )}
                <ContextSensitiveHelpLink />
            </ul>
            <UserAccountButton />
        </>
    );
}
