import { Nullable } from "../../../../utils/typeUtils";
import useDetailsCreate from "../../../../hooks/details/useDetailsCreate";
import { useMemo } from "react";
import { TcProductDetailViewProps } from "./TcProductDetailView";
import useResolvedRoute from "../../../../components/routing/useResolvedRoute";
import { TcProductDetails } from "../tcProductTypes";
import { yupResolver } from "@hookform/resolvers/yup";
import { tcProductValidationSchema } from "./tcProductValidationSchema";
import { useQuery } from "../../../../hooks/useAxios";
import { TransactionCertificateReference } from "../../../transactionCertificateTypes";
import useTcProductDetail from "./useTcProductDetail";

export default function useTcProductCreate(): TcProductDetailViewProps {
    const { getResolvedId } = useResolvedRoute();
    const transactionCertificateId = getResolvedId("transactionCertificate")!;

    const { data: transactionCertificate, isLoading: isTransactionCertificateLoading } =
        useQuery<TransactionCertificateReference>({
            url: `/certificate-service/references/transaction-certificates/${transactionCertificateId}`,
            enabled: !!transactionCertificateId,
        });

    const initialData = useMemo<Nullable<TcProductDetails>>(
        () => ({
            id: null,
            tcProductNumber: null,
            relatedScProduct: null,
            sellerLegalName: transactionCertificate?.sellerLegalName ?? null,
            sellerFacilityBusinessName: transactionCertificate?.facilityAsSellerBusinessName ?? null,
            buyerLegalName: transactionCertificate?.buyerLegalName ?? null,
            buyerFacilityBusinessName: transactionCertificate?.facilityAsBuyerBusinessName ?? null,
            usdaNopCompliant: false,
            numberOfUnits: 1,
            unitName: null,
            netShippingWeight: null,
            supplementaryWeight: 0,
            certifiedWeight: null,
            nonCertifiedWeight: null,
            availableQuantity: null,
            byProductWeight: null,
            availableByProductQuantity: null,
            orderNumber: null,
            articleNumber: null,
            productionDate: null,
            tcProcessors: null,
            lastProcessor: null,
            additionalInformation: null,
            transactionCertificateStatus: null,
            scopeCertificateId: null,
            cbProductReferenceNumber: null,
        }),
        [
            transactionCertificate?.buyerLegalName,
            transactionCertificate?.facilityAsBuyerBusinessName,
            transactionCertificate?.facilityAsSellerBusinessName,
            transactionCertificate?.sellerLegalName,
        ]
    );

    const { detailViewProps, form, title } = useDetailsCreate<TcProductDetails>({
        title: "tcProduct.detail.newRecord_title",
        frontendRoute: "tcProduct",
        initialData,
        resolver: yupResolver(tcProductValidationSchema),
        saveUrl: `/certificate-service/transaction-certificates/${transactionCertificateId}/tc-products`,
    });

    const { selectedScProduct, byProductWeightVisible } = useTcProductDetail(form);

    return {
        title,
        detailViewProps: {
            ...detailViewProps,
            isLoading: detailViewProps.isLoading || isTransactionCertificateLoading,
        },
        form,
        scopeCertificateId: transactionCertificate?.scopeCertificate,
        readOnlyProps: { readOnly: false },
        createRoute: "tcProduct",
        materialCompositionTabVisible: false,
        selectedScProduct,
        byProductWeightVisible,
        sublistVisible: false,
    };
}
