import { yupResolver } from "@hookform/resolvers/yup";
import useIsAuthorised from "../../auth/useIsAuthorised";
import useResolvedRoute, { routeEnum } from "../../components/routing/useResolvedRoute";
import { useMemo } from "react";
import useDomainEntityDetailsEdit from "../../hooks/details/useDomainEntityDetailsEdit";
import { ScoLicenceDetails } from "../scoLicenceTypes";
import { scoLicenceValidationSchema } from "./scoLicenceValidationSchema";
import { defaultScoLicenceSortOption } from "../useScoLicenceSubList";
import { ScoLicenceDetailViewProps, ScoLicenceReadOnly } from "./ScoLicenceDetailView";
import useAuth from "../../auth/useAuth";
import useBanConfirmationDialog from "./useBanConfirmationDialog";

export default function useScoLicenceEdit(): ScoLicenceDetailViewProps {
    const { hasAccessRight, isSBAdmin } = useIsAuthorised();
    const showConfirmationDialog = useBanConfirmationDialog();
    const {
        currentUser: {
            authorisationRole: { level: authorisationLevel, type: authorisationType },
        },
    } = useAuth();
    const { getResolvedId } = useResolvedRoute();
    const scoLicenceId = getResolvedId("scoLicenceNumber");

    const { detailViewProps, form, isReadOnly, title, recordNavigationProps } =
        useDomainEntityDetailsEdit<ScoLicenceDetails>({
            baseUrl: `/certificate-service/sco-licences/${scoLicenceId}`,
            resolver: yupResolver(scoLicenceValidationSchema),
            titleBuilder: (item) => item.licenceNumber,
            canSave: hasAccessRight("updateMasterData"),
            resolveErrors: (error, item, retrySaveAction) => {
                if (error.statusCode === 409 && error?.data?.message === "ban_confirmation") {
                    showConfirmationDialog(retrySaveAction, item, error.data.standardAcronym);
                    return true;
                }
                return false;
            },
            frontendRoute: "scoLicenceNumber",
            recordNavigationDefaultSort: defaultScoLicenceSortOption,
            historyColumns: [
                { identifier: "standard", title: "scoLicence.history.standard_column" },
                { identifier: "licenceNumber", title: "scoLicence.history.licenceNumber_column" },
                { identifier: "startDate", title: "scoLicence.history.startDate_column" },
                { identifier: "endDate", title: "scoLicence.history.endDate_column" },
            ],
        });

    const readOnly: ScoLicenceReadOnly = useMemo(
        () => ({
            isReadOnly,
            isStandardReadOnly: true,
            isEndDateReadOnly: !(authorisationType === "STANDARD_BODY" && authorisationLevel === 2),
            isBannedUntilReadOnly: !isSBAdmin,
        }),
        [authorisationLevel, authorisationType, isReadOnly, isSBAdmin]
    );

    const createRoute = useMemo<routeEnum | undefined>(() => {
        return hasAccessRight("createMasterData") ? "scoLicenceNumber" : undefined;
    }, [hasAccessRight]);

    return {
        detailViewProps,
        form,
        readOnly,
        title,
        recordNavigationProps,
        createRoute,
    };
}
