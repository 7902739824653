import { useMemo } from "react";
import { FieldValues } from "react-hook-form/dist/types";
import useRecordNavigation, { UseRecordNavigation } from "../../components/card/navigation/useRecordNavigation";
import useDetailsEdit, { UseDetailEditProps } from "./useDetailsEdit";
import useResolvedRoute from "../../components/routing/useResolvedRoute";

export interface UseNavigatableDetailsEdit<ItemType extends FieldValues> extends UseDetailEditProps<ItemType> {
    recordNavigationDefaultSort: UseRecordNavigation<ItemType>["defaultSortOption"];
    recordNavigationDefaultQueryParams?: UseRecordNavigation<ItemType>["defaultQueryParams"];
}

function useNavigatableDetailsEdit<ItemType extends FieldValues>({
    baseUrl,
    frontendRoute,
    recordNavigationDefaultSort,
    recordNavigationDefaultQueryParams,
    ...rest
}: UseNavigatableDetailsEdit<ItemType>) {
    const { initialData, ...detailsEditProps } = useDetailsEdit({ baseUrl, frontendRoute, ...rest });
    const { getResolvedBaseUrl } = useResolvedRoute();

    const recordNavigationProps = useRecordNavigation({
        baseUrl,
        defaultSortOption: recordNavigationDefaultSort,
        basicFrontendUrl: getResolvedBaseUrl(frontendRoute),
        loadOnChange: initialData,
        defaultQueryParams: recordNavigationDefaultQueryParams,
    });

    return useMemo(() => {
        return {
            recordNavigationProps,
            initialData,
            ...detailsEditProps,
        };
    }, [detailsEditProps, initialData, recordNavigationProps]);
}

export default useNavigatableDetailsEdit;
