import { joinClassNames } from "../../utils/StringUtils";
import GtbTextField from "../../components/Input/GtbTextField";
import "./productCategoryBasicData.css";
import ControlledDatePicker from "../../components/Input/datepicker/ControlledDatePicker";
import { ProductCategoryDetailViewTabProps } from "./ProductCategoryDetailView";
import GtbNumberField from "../../components/Input/GtbNumberField";
import CurrentLanguageWatchField from "../../i18n/CurrentLanguageWatchField";

function ProductCategoryBasicData({ form, readOnly }: ProductCategoryDetailViewTabProps) {
    return (
        <div className={joinClassNames("productCategoryBasicData", "formGrid")}>
            <GtbTextField
                {...form.registerWithErrors("code")}
                readOnly={readOnly}
                label="productCategory.detail.code_input"
                className="code"
            />
            <GtbNumberField
                {...form.registerNumberWithErrors("pickListSequence")}
                readOnly={readOnly}
                label="productCategory.detail.pickListSequence_input"
                className="pickListSequence"
            />
            <CurrentLanguageWatchField
                form={form}
                label="productCategory.detail.name_input"
                field={"name"}
                className="name"
            />
            <div className="activation-period">
                <ControlledDatePicker
                    control={form.form.control}
                    readOnly={readOnly}
                    name="startDate"
                    label="productCategory.detail.startDate_input"
                    className="startDate"
                />
                <ControlledDatePicker
                    control={form.form.control}
                    readOnly={readOnly}
                    name="endDate"
                    label="productCategory.detail.endDate_input"
                    className="endDate"
                />
            </div>
        </div>
    );
}

export default ProductCategoryBasicData;
