import EntityListView from "../components/entity/EntityListView";
import useOrganicMaterialProductionListView from "./useOrganicMaterialProductionListView";
import { useHelpContext } from "../help/context-sensitivity/useHelpContext";

export default function OrganicMaterialProductionListView() {
    const organicMaterialProductionGridOptions = useOrganicMaterialProductionListView();
    useHelpContext("organic-material-production-list");

    return (
        <EntityListView
            heading="organicMaterialProduction.list.header"
            gridOptions={organicMaterialProductionGridOptions}
        />
    );
}
