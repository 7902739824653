import * as React from "react";
import { useCallback, useMemo } from "react";
import DialogHeader from "../components/dialog/DialogHeader";
import DialogFooter from "../components/dialog/DialogFooter";
import DialogContent from "../components/dialog/DialogContent";
import useGtbTranslation from "../i18n/useGtbTranslation";
import { Dialog } from "../components/dialog/useDialog";
import { useModal } from "../components/modal/ModalProvider";
import { UseFormAfterSubmitAction } from "../hooks/formHandling/useFormSubmit";
import { FieldValues } from "react-hook-form/dist/types";
import { ConformanceCheckIssue, getConformanceCheckIssueMessages } from "../utils/conformanceCheck";
import GtbButton from "../components/GtbButton";
import "./conformanceCheck.css";

export default function useConformanceCheckDialog<ItemType extends FieldValues>() {
    const { showModal: _showDialog, closeModal: closeDialog } = useModal();
    return useCallback(
        (
            conformanceCheckIssues: ConformanceCheckIssue[],
            itemToCreate: ItemType,
            retrySaveAction: UseFormAfterSubmitAction<ItemType>,
            showWarningsOnCondition
        ) => {
            _showDialog(
                <ConformanceCheckDialog
                    conformanceCheckIssues={conformanceCheckIssues}
                    itemToCreate={itemToCreate}
                    closeDialog={closeDialog}
                    retrySaveAction={retrySaveAction}
                    showWarningsOnCondition={showWarningsOnCondition}
                />
            );
        },
        [_showDialog, closeDialog]
    );
}

export interface ConformanceCheckDialogProps<ItemType extends FieldValues> {
    closeDialog: Function;
    itemToCreate: ItemType;
    conformanceCheckIssues: ConformanceCheckIssue[];
    retrySaveAction: UseFormAfterSubmitAction<ItemType>;
    showWarningsOnCondition?: (item: ItemType) => boolean;
}

function ConformanceCheckDialog<ItemType extends FieldValues>({
    conformanceCheckIssues,
    closeDialog,
    retrySaveAction,
    itemToCreate,
    showWarningsOnCondition,
}: ConformanceCheckDialogProps<ItemType>) {
    const translation = useGtbTranslation();

    const conformanceCheckIssueMessages = getConformanceCheckIssueMessages(conformanceCheckIssues);
    const containsErrors = conformanceCheckIssueMessages.containsErrors;

    const displayWarningMessage = useMemo(
        () => (showWarningsOnCondition ? showWarningsOnCondition(itemToCreate) : true),
        [itemToCreate, showWarningsOnCondition]
    );

    return (
        <Dialog
            dialog={{
                header: (
                    <DialogHeader
                        title={"components.detailView.conformanceCheckDialog.dialog_title"}
                        variant={containsErrors ? "error" : "warning"}
                        onClick={() => closeDialog()}
                    />
                ),
                content: (
                    <>
                        <DialogContent className="conformanceCheckDialog">
                            <ul>
                                {conformanceCheckIssueMessages.messages.map((item, index) => (
                                    <li key={"conformanceCheckIssue_" + index}>
                                        {translation({
                                            key:
                                                item.severity === "ERROR"
                                                    ? "components.detailView.conformanceCheckDialog.error_message"
                                                    : "components.detailView.conformanceCheckDialog.warning_message",
                                            options: {
                                                message: translation(item.message),
                                                interpolation: { escapeValue: false },
                                            },
                                        })}
                                    </li>
                                ))}
                            </ul>
                        </DialogContent>
                        {!containsErrors && displayWarningMessage && (
                            <div className="conformanceCheckDialogProceedAnywayMessage">
                                <span>
                                    {translation("components.detailView.conformanceCheckDialog.proceedAnyway_message")}
                                </span>
                            </div>
                        )}
                    </>
                ),
                footer: (
                    <DialogFooter>
                        {!containsErrors && (
                            <GtbButton
                                variant="secondary"
                                onClick={() => {
                                    retrySaveAction({
                                        ...itemToCreate,
                                        ignoredConformanceChecks: conformanceCheckIssues,
                                    });
                                    closeDialog();
                                }}
                            >
                                {translation("components.detailView.conformanceCheckDialog.proceedAnyway_button")}
                            </GtbButton>
                        )}
                        <GtbButton onClick={() => closeDialog()}>
                            {translation(
                                containsErrors ? "components.dialog.ok_button" : "components.dialog.cancel_button"
                            )}
                        </GtbButton>
                    </DialogFooter>
                ),
            }}
        />
    );
}
