import InputSourceSelectionList, {
    InputSourceSelectionListProps,
} from "../../../../../../../components/grid/InputSourceSelectionList";
import { GridColumn } from "../../../../../../../components/grid/component/Grid";
import { buildNumberColumn, buildTextColumn } from "../../../../../../../components/grid/ColumnProvider";
import { OrganicMaterialPurchaseReference } from "../../../../../../../organicMaterialPurchase/organicMaterialPurchaseTypes";

export default function OrganicMaterialPurchaseInputSourceSelectionList({
    ...rest
}: Pick<
    InputSourceSelectionListProps<OrganicMaterialPurchaseReference>,
    "className" | "onItemClick" | "selectedItemId"
>) {
    return (
        <InputSourceSelectionList<OrganicMaterialPurchaseReference>
            tableName="organicMaterialPurchaseInputSources"
            url="/certificate-service/references/input-sources/organic-material-purchases"
            columns={organicMaterialPurchaseInputSourceColumns}
            sortField="systemId"
            {...rest}
        />
    );
}

const organicMaterialPurchaseInputSourceColumns: GridColumn<OrganicMaterialPurchaseReference>[] = [
    buildTextColumn({ key: "systemId", title: "organicMaterialPurchase.list.systemId_column", minWidth: 140 }),
    buildTextColumn({
        key: "certifiedOrganicMaterialName",
        title: "organicMaterialPurchase.list.rawMaterialName_column",
        minWidth: 155,
    }),
    buildTextColumn({
        key: "certifiedOrganicMaterialCode",
        title: "organicMaterialPurchase.list.rawMaterialCode_column",
        minWidth: 123,
    }),
    buildNumberColumn({
        key: "availableQuantity",
        title: "organicMaterialPurchase.list.availableQuantity_column",
        fixedDecimal: true,
        minWidth: 230,
    }),
    buildTextColumn({
        key: "inputSourceOrigin",
        title: "organicMaterialPurchase.list.origin_column",
        minWidth: 110,
        disableSorting: true,
        disableFiltering: true,
    }),
];
