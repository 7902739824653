import { TypedPicklist } from "./typedPicklistType";
import DataPickList from "../DataPickList";
import { joinClassNames } from "../../../../utils/StringUtils";
import { FieldValues } from "react-hook-form/dist/types";
import { UserReference } from "../../../../user/userTypes";
import "./typedPickList.css";
import { PickListItemPresentationProps } from "../GtbPickList";

export const userPickListCombinedClassName = (className?: string) => joinClassNames("userPickList", className);
export const userPickListItemPresentation: PickListItemPresentationProps<UserReference> = {
    itemId: "id",
    itemLabel: (user) => `${user.givenName} ${user.surname} (${user.systemId})`,
};
export const userPickListReferenceUrl = "/certificate-service/references/users";

export default function UserPickList<FormItemType extends FieldValues>({
    className,
    ...rest
}: UserPickListProps<FormItemType>) {
    return (
        <DataPickList
            className={userPickListCombinedClassName(className)}
            {...userPickListItemPresentation}
            queryUrl={userPickListReferenceUrl}
            {...rest}
        />
    );
}

interface UserPickListProps<FormItemType extends FieldValues> extends TypedPicklist<FormItemType, UserReference> {
    flags: UserPickListFlags;
}

export interface UserPickListFlags {
    canApproveSCs?: boolean;
    canApproveTCs?: boolean;
    activeUserOfCb?: string;
}
