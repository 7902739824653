import { backendUrlType, useQuery } from "./useAxios";
import { FileResource } from "../components/Input/UploadComponent/FileUploadComponent";
import { useEffect, useState } from "react";

export default function useLoadImage(url: backendUrlType) {
    const { runQuery } = useQuery<FileResource>({ url: "", enabled: false });
    const [image, setImage] = useState<string | null>(null);

    useEffect(() => {
        if (!url) {
            setImage(null);
            return;
        }
        runQuery({ url })
            .then((file) => setImage(`data:${file.type};base64,${file.base64Content}`))
            .catch(() => setImage(null));
    }, [runQuery, url]);

    return image;
}
