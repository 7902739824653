import { Path, PathValue, useWatch } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import DataPickList, { DataPickListProps } from "../DataPickList";
import { UseGtbFormReturn } from "../../../../hooks/formHandling/useGtbForm";
import { TypedPicklist } from "./typedPicklistType";
import { GtbPickListProps } from "../GtbPickList";
import { FieldValues } from "react-hook-form/dist/types";

export interface DependentPicklistProps<FormItemType extends FieldValues, ItemType>
    extends Omit<InternalDependentPicklistProps<FormItemType, ItemType>, "urlBuilder" | "itemId" | "itemLabel"> {}

interface InternalDependentPicklistProps<FormItemType extends FieldValues, ItemType>
    extends Omit<DataPickListProps<FormItemType, ItemType>, "queryUrl" | "control" | "flags"> {
    form: UseGtbFormReturn<FormItemType>["form"];
    parentField: Path<FormItemType>;
    urlBuilder: (parentId: string) =>
        | string
        | Promise<string>
        | {
              url: string;
              flags: {};
          };
    readOnly?: boolean;
    itemId: GtbPickListProps<ItemType>["itemId"];
    itemLabel: GtbPickListProps<ItemType>["itemLabel"];
}

export interface FlaggedQuery<FlagType = {}> {
    url: string;
    flags: FlagType;
}

interface InternalMultiDependentPicklistProps<FormItemType extends FieldValues, ItemType>
    extends Omit<TypedPicklist<FormItemType, ItemType>, "queryUrl" | "control" | "flags"> {
    form: UseGtbFormReturn<FormItemType>["form"];
    parentField: Path<FormItemType>[];
    urlBuilder: (parentIds: string[]) => string | Promise<string> | FlaggedQuery;
    readOnly?: boolean;
    itemId: GtbPickListProps<ItemType>["itemId"];
    itemLabel: GtbPickListProps<ItemType>["itemLabel"];
}

export function DependentPicklist<FormItemType extends FieldValues, ItemType>(
    props: InternalDependentPicklistProps<FormItemType, ItemType>
): JSX.Element;
export function DependentPicklist<FormItemType extends FieldValues, ItemType>(
    props: InternalMultiDependentPicklistProps<FormItemType, ItemType>
): JSX.Element;
export function DependentPicklist<FormItemType extends FieldValues>({
    form: { control, setValue },
    parentField,
    name,
    urlBuilder,
    readOnly,
    ...rest
}: any) {
    const parentIds = useWatch({
        control,
        name: Array.isArray(parentField) ? parentField : [parentField],
    });
    const [queryUrl, setQueryUrl] = useState("");
    const [queryFlags, setQueryFlags] = useState<{} | undefined>();

    //Save the volatile builder in a ref, so that the queryUrl does not get recalculated too often
    const _urlBuilder = useRef(urlBuilder);
    const _oldParentId = useRef(parentIds);

    useEffect(() => {
        // Clear the value of the dependent picklist if its parent changes
        if (parentIds.toString() !== _oldParentId.current.toString()) {
            setValue(name as Path<FormItemType>, null as PathValue<FormItemType, Path<FormItemType>>);
            _oldParentId.current = parentIds;
        }
    }, [parentIds, name, setValue]);

    useEffect(() => {
        // Only set Query if all parents are set
        if (!parentIds || parentIds.some((val) => !val)) {
            setQueryUrl("");
        } else {
            const query = _urlBuilder.current(Array.isArray(parentField) ? parentIds : parentIds[0]);
            if (query instanceof Promise) {
                query.then(setQueryUrl);
            } else if (typeof query === "string") {
                setQueryUrl(query);
            } else {
                setQueryUrl(query.url);
                setQueryFlags(query.flags);
            }
        }
    }, [parentField, parentIds]);

    return (
        <DataPickList
            control={control}
            name={name as Path<FormItemType>}
            readOnly={readOnly || !queryUrl}
            queryUrl={queryUrl}
            flags={queryFlags}
            {...rest}
        />
    );
}
