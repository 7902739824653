// @ts-nocheck
// TS is explicitly disabled because the ButtonUnstyled-API seemingly no longer accepts the onClick-Function, also some operations in the
// "onClick"-handler where already annotated with ts-ignore.
//
// This component itself is still working as expected (as of 08.11.22)

import useAuth from "../../auth/useAuth";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import * as React from "react";
import useLanguage from "../../i18n/LanguageContextProvider";
import useGtbTranslation, { I18nKey } from "../../i18n/useGtbTranslation";

function ZendeskWidgetButton({ name, className }: { name: I18nKey; className?: string }) {
    const translation = useGtbTranslation();
    const { currentUser } = useAuth();
    const currentLanguage = useLanguage();

    return (
        <li>
            <ButtonUnstyled
                className={className}
                variant="text"
                onClick={(e) => {
                    e.preventDefault();
                    zE.activate();
                    zE("webWidget", "clear");
                    zE.setLocale(currentLanguage);

                    zE("webWidget", "prefill", {
                        name: {
                            value:
                                currentUser.givenName + " " + currentUser.surname + " (" + currentUser.systemId + ")",
                            readOnly: true,
                        },
                        email: {
                            value: currentUser.email,
                            readOnly: true,
                        },
                    });
                }}
            >
                {translation(name)}
            </ButtonUnstyled>
        </li>
    );
}

export default ZendeskWidgetButton;
