import { buildSchema, validateNumber } from "../../../../../../utils/yupExtension";
import { TcMaterialDetails } from "../tcMaterialTypes";

export const buildTcMaterialValidationSchema = buildSchema<TcMaterialDetails>({
    scMaterialMinPercentage: validateNumber(),
    scMaterialMaxPercentage: validateNumber(),
    percentage: validateNumber()
        .isRequired()
        .when("scMaterialMinPercentage", (min, schema) => schema.hasMinValue(Number.isSafeInteger(min) ? min : 1))
        .when("scMaterialMaxPercentage", (max, schema) => schema.hasMaxValue(Number.isSafeInteger(max) ? max : 100)),
});
