import { TypedDetailViewProps } from "../../components/detailView/DetailView";
import { ProductConversionDetails } from "../productConversionTypes";
import ProductConversionBasicData from "./ProductConversionBasicData";
import EntityDetailView from "../../components/entity/EntityDetailView";
import { useHelpContext } from "../../help/context-sensitivity/useHelpContext";

export default function ProductConversionDetailView({
    detailViewProps,
    form,
    isReadOnly,
    ...rest
}: ProductConversionDetailViewProps) {
    useHelpContext("product-conversion-details");
    return (
        <EntityDetailView detailViewProps={detailViewProps} form={form} {...rest}>
            <ProductConversionBasicData form={form} readOnly={isReadOnly} />
        </EntityDetailView>
    );
}

export interface ProductConversionDetailViewProps extends TypedDetailViewProps<ProductConversionDetails> {
    isReadOnly: boolean;
}
