import { SupplyChainOperatorDetailViewTabProps } from "./SupplyChainOperatorDetailView";
import OrganisationAddressData from "../../organisation/details/OrganisationAddressData";
import GtbTextField from "../../components/Input/GtbTextField";
import FixedRegistrationIdTextField from "../../components/Input/FixedRegistrationIdTextField";

export default function SupplyChainOperatorAddressData({
    form,
    readOnlyProps,
    typeOfRegistrationId,
}: SupplyChainOperatorDetailViewTabProps) {
    return (
        <OrganisationAddressData form={form} readOnlyProps={readOnlyProps} organisationI18nKey="supplyChainOperator">
            <GtbTextField
                readOnly
                label="supplyChainOperator.detail.typeOfRegistrationId_input"
                className="typeOfRegistrationId"
                value={typeOfRegistrationId}
            />
            <FixedRegistrationIdTextField
                control={form.form.control}
                name="fixedRegistrationId"
                label="supplyChainOperator.detail.fixedRegistrationId_input"
                className="fixedRegistrationId"
                readOnly={readOnlyProps.isFixedRegistrationIdReadOnly}
            />
        </OrganisationAddressData>
    );
}
