import { Nullable } from "../../utils/typeUtils";
import useDetailsCreate from "../../hooks/details/useDetailsCreate";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { StandardDetails } from "../standardTypes";
import { standardValidationSchema } from "./standardValidationSchema";
import { routeEnum } from "../../components/routing/useResolvedRoute";

const initialData: Nullable<StandardDetails> = {
    id: null,
    acronym: null,
    officialName: null,
    standardBody: null,
    typeOfStandard: "TYPICAL",
    transactionCertificatesMandatory: null,
};

function useStandardCreate() {
    const { detailViewProps, form, title } = useDetailsCreate<StandardDetails>({
        title: "standard.detail.newRecord_title",
        frontendRoute: "standard",
        initialData,
        resolver: yupResolver(standardValidationSchema),
        saveUrl: "/certificate-service/standards",
    });

    const currentTypeOfStandard = form.form.watch("typeOfStandard");

    return {
        title,
        detailViewProps,
        form,
        currentTypeOfStandard,
        navigationEnabled: false,
        isReadOnly: false,
        subListVisible: false,
        createRoute: "standard" as routeEnum,
    };
}

export default useStandardCreate;
