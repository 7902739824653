import GtbIconButton from "../GtbIconButton";
import Icon, { IconName } from "../Icon";
import useGtbTranslation, { I18nKey } from "../../i18n/useGtbTranslation";

export interface DialogHeaderProps {
    title: I18nKey;
    variant: "info" | "warning" | "error";
    onClick?: () => void;
    icon?: IconName;
    closeButtonDisabled?: boolean;
}

function DialogHeader({ title, variant, onClick, icon, closeButtonDisabled }: DialogHeaderProps) {
    const translation = useGtbTranslation();

    return (
        <div className={variant + "-dialog-header dialog-header"}>
            <Icon name={icon ?? variant} />
            <h2 id="dialogTitle">{translation(title)}</h2>
            {onClick && (
                <GtbIconButton
                    className="dialog-close-button"
                    label="components.dialog.closeDialog"
                    iconName={"cross"}
                    size={13}
                    onClick={onClick}
                    disabled={closeButtonDisabled}
                />
            )}
        </div>
    );
}

export default DialogHeader;
