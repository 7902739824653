import GtbButton from "../../GtbButton";
import Icon from "../../Icon";
import { useEffect, useMemo, useState } from "react";
import { sortDirectionType } from "./useQueryBuilder";
import useGtbTranslation, { I18nKey } from "../../../i18n/useGtbTranslation";

export interface GridHeaderCellProps {
    title?: I18nKey;
    component?: JSX.Element;
    minWidth: number;
    width?: number;
    onWidthChange?: (column: string, width: number) => void;
    sortDirection?: sortDirectionType;
    sort?: () => void;
    sortEnabled?: boolean;
    className?: string;
}

export default function GridHeaderCell({
    title,
    sort,
    sortDirection,
    sortEnabled = true,
    component,
    width,
    minWidth,
    onWidthChange,
    className,
}: GridHeaderCellProps) {
    const [widthState, setWidthState] = useState<any>();
    const translation = useGtbTranslation();

    const columnProperties = useMemo(
        () => getColumnProps(minWidth, width, sortDirection),
        [minWidth, sortDirection, width]
    );

    useEffect(() => {
        let initialWidth: number;

        function mouseMoveListener(e: MouseEvent) {
            let diffX = e.pageX - widthState.pageX;
            const newWidth = initialWidth + diffX;
            if (newWidth >= 0) {
                widthState.col.width = newWidth;
                widthState.col.style.minWidth = newWidth + "px";
                widthState.col.style.maxWidth = newWidth + "px";
            }
        }

        function mouseUpListener() {
            const newWidth = widthState.col.width;
            setWidthState(undefined);
            onWidthChange?.(title as string, newWidth);
        }

        if (widthState) {
            initialWidth = widthState.col.offsetWidth;
            widthState.col.classList.add("resizing");
            document.addEventListener("mousemove", mouseMoveListener);
            document.addEventListener("mouseup", mouseUpListener);
        }

        return () => {
            if (widthState) {
                widthState.col.classList.remove("resizing");
            }
            document.removeEventListener("mousemove", mouseMoveListener);
            document.removeEventListener("mouseup", mouseUpListener);
        };
    }, [onWidthChange, title, widthState]);

    return (
        <th scope="col" {...columnProperties} className={className}>
            <div className="headerCell">
                {!!title && (
                    <GtbButton variant="tertiary" className="headerTitle" onClick={sort} disabled={!sortEnabled}>
                        {title && translation(title)}
                        <Icon size={16} name="arrow-up" />
                    </GtbButton>
                )}
                {component}
            </div>
            {!!title && (
                <div
                    className="resizeHandle"
                    onMouseDown={(event) => {
                        setWidthState({
                            pageX: event.pageX,
                            col: (event.target as HTMLTableCellElement)?.parentElement,
                        });
                    }}
                />
            )}
        </th>
    );
}

const getColumnProps = (
    minWidth: GridHeaderCellProps["minWidth"],
    width: GridHeaderCellProps["width"],
    sortDirection: GridHeaderCellProps["sortDirection"]
) => {
    const sort = sortDirection
        ? { "aria-sort": sortDirection === "DESC" ? ("descending" as const) : ("ascending" as const) }
        : {};
    const colWidth = width
        ? { width, style: { minWidth: `${width}px`, maxWidth: `${width}px` } }
        : { style: { minWidth: `${minWidth / 16}rem` } };
    return { ...sort, ...colWidth };
};
