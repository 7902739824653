import useErrorDialog from "../dialog/useErrorDialog";
import GtbButton from "../GtbButton";
import { useCallback } from "react";
import useGtbTranslation from "../../i18n/useGtbTranslation";

export default function useUnsavedChangesDialog() {
    const { showDialog: _showDialog, closeDialog: _closeDialog } = useErrorDialog();
    const translation = useGtbTranslation();

    const showDialog = useCallback(
        (onClickNo: () => void, onClickYes: () => void) => {
            _showDialog({
                title: "components.detailView.unsavedChanges_dialogTitle",
                message: "components.detailView.unsavedChanges_dialogText",
                footer: (
                    <>
                        <GtbButton variant="secondary" onClick={_closeDialog}>
                            {translation("components.detailView.continueEditing_dialogButton")}
                        </GtbButton>
                        <GtbButton
                            variant="secondary"
                            // onClick={() => {
                            //     closeDialog();
                            //     e.proceedNavigation();
                            // }}
                            onClick={() => onClickNo()}
                        >
                            {translation("components.dialog.no_button")}
                        </GtbButton>
                        <GtbButton
                            // onClick={async () => {
                            //     closeDialog();
                            //     handleSubmit(() => e.proceedNavigation());
                            // }}
                            onClick={() => onClickYes()}
                        >
                            {translation("components.dialog.yes_button")}
                        </GtbButton>
                    </>
                ),
            });
        },
        [_closeDialog, _showDialog, translation]
    );

    return { showDialog, closeDialog: _closeDialog };
}
