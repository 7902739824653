import DropdownMenu from "../../components/dropdown/DropdownMenu";
import useUserAccountButton from "./useUserAccountButton";
import DropdownContainer from "../../components/dropdown/DropdownContainer";
import OpenDropdownButton from "../../components/dropdown/OpenDropdownButton";
import Icon from "../../components/Icon";
import DropdownLinkItem from "../../components/dropdown/DropdownLinkItem";
import { getBaseUrl } from "../../components/routing/useResolvedRoute";
import { env } from "../../env";

const environmentName = env.REACT_APP_ENVIRONMENT_NAME;

/**
 * Component for displaying user information and to show user specific actions
 * @constructor
 */
function UserAccountButton() {
    const { fullUsername, roleName } = useUserAccountButton();

    return (
        <div className="userAccountButtonContainer">
            <DropdownContainer>
                <OpenDropdownButton className="userAccountButton">
                    <span className={"username"}>{fullUsername}</span>
                    <span className="authorisationRole">{roleName}</span>
                    {environmentName && <span className="environmentName">{environmentName}</span>}
                    <Icon name="caret-down" size={15} />
                </OpenDropdownButton>
                <DropdownMenu>
                    <DropdownLinkItem to={getBaseUrl("profile")} label={"mainLayout.headerMenu.editAccount_button"} />
                    <DropdownLinkItem to={getBaseUrl("logout")} label={"mainLayout.headerMenu.logout_button"} />
                </DropdownMenu>
            </DropdownContainer>
        </div>
    );
}

export default UserAccountButton;
