import { ReactNode, useCallback, useEffect, useRef } from "react";
import { BrowserRouter as Router, NavigateOptions, To, useLocation, useNavigate } from "react-router-dom";
import makeStore from "../../hooks/makeStore";

function _GtbRouter() {
    const [OnBeforeLeaveStoreProvider, dispatchBeforeLeaveStore, useBeforeLeaveStore] =
        makeStore<beforeLeaveAction | null>(null);

    const __GtbRouter = ({ children }: { children: ReactNode }) => (
        <Router>
            <OnBeforeLeaveStoreProvider>
                {children}
                <UnsetBeforeLeaveStoreOnNavigate />
            </OnBeforeLeaveStoreProvider>
        </Router>
    );

    return { __GtbRouter, dispatchBeforeLeaveStore, useBeforeLeaveStore };
}

function UnsetBeforeLeaveStoreOnNavigate() {
    const { unsetBeforeLeaveAction } = useDispatchBeforeLeave();
    const { pathname } = useLocation();

    useEffect(() => {
        unsetBeforeLeaveAction();
    }, [pathname, unsetBeforeLeaveAction]);

    return null;
}

const router = _GtbRouter();
const GtbRouter = router.__GtbRouter;
export default GtbRouter;

export function useDispatchBeforeLeave() {
    const dispatchStore = router.dispatchBeforeLeaveStore();

    const unsetBeforeLeaveAction = useCallback(() => {
        window.removeEventListener("beforeunload", unloadListener);
        removeBrowserNavigationEventListener();
        dispatchStore(null);
    }, [dispatchStore]);

    const setBeforeLeaveAction = useCallback(
        (beforeLeaveAction: beforeLeaveAction) => {
            window.addEventListener("beforeunload", unloadListener);
            addBrowserNavigationEventListener(beforeLeaveAction);
            dispatchStore(() => beforeLeaveAction);
        },
        [dispatchStore]
    );

    return {
        unsetBeforeLeaveAction,
        setBeforeLeaveAction,
    };
}

export function useGtbNavigate(forceNavigate = false) {
    const _navigate = useNavigate();
    const beforeLeaveStore = router.useBeforeLeaveStore();
    const beforeLeaveStoreRef = useRef(beforeLeaveStore);

    useEffect(() => {
        beforeLeaveStoreRef.current = beforeLeaveStore;
    }, [beforeLeaveStore]);

    return useCallback(
        (to: To, options?: NavigateOptions, beforeNavigate?: () => void) => {
            if (!forceNavigate && beforeLeaveStoreRef.current) {
                beforeLeaveStoreRef.current({
                    proceedNavigation: () => {
                        beforeNavigate?.();
                        _navigate(to, options);
                    },
                });
            } else {
                beforeNavigate?.();
                _navigate(to, options);
            }
        },
        [_navigate, forceNavigate]
    );
}

const TEMP_STATE = null;
let currentBeforeLeaveAction: beforeLeaveAction | undefined = undefined;

function removeBrowserNavigationEventListener() {
    window.removeEventListener("popstate", popState);
    if (window.history.state === TEMP_STATE) {
        window.history.back();
    }
}

function addBrowserNavigationEventListener(beforeLeaveAction: beforeLeaveAction) {
    currentBeforeLeaveAction = beforeLeaveAction;
    if (window.history.state !== TEMP_STATE) {
        window.history.pushState(TEMP_STATE, "");
        window.addEventListener("popstate", popState);
    }
}

const unloadListener = (event: { preventDefault: () => void; returnValue: string }) => handleTabClose(event);

const handleTabClose = (event: { preventDefault: () => void; returnValue: string }) => {
    event.preventDefault();
    //TODO: Translation
    event.returnValue = "You have unsaved changes. Are you sure you want to exit?";
    return event.returnValue;
};

function popState() {
    window.history.pushState(TEMP_STATE, "");
    currentBeforeLeaveAction?.({
        proceedNavigation: () => {
            window.removeEventListener("popstate", popState);
            window.history.go(-2);
        },
    });
}

type beforeLeaveAction = (event: OnBeforeLeaveEvent) => void;

interface OnBeforeLeaveEvent {
    proceedNavigation: () => void;
}
