import "./recordNavigation.css";
import useGtbTranslation, { I18nKey } from "../../../i18n/useGtbTranslation";
import GtbIconLink from "../../routing/GtbIconLink";
import GtbNav from "../../routing/GtbNav";
import { useLocation } from "react-router-dom";
import { useCallback } from "react";
import { IconName } from "../../Icon";

function RecordNavigation({ first, previous, current, next, last, recordSequenceChanged }: RecordNavigationProps) {
    const translation = useGtbTranslation();
    const { search } = useLocation();

    const buildRecordLink = useCallback(
        ({ pathname, disabled, state, ...rest }: RecordNavigationLink & { iconName: IconName; label: I18nKey }) => (
            <GtbIconLink disabled={disabled} to={{ pathname, search }} state={state} {...rest} />
        ),
        [search]
    );

    return (
        <div className="recordNavigation">
            {current && (
                <>
                    <GtbNav aria-label={"components.recordNavigation.accessibleLabel"}>
                        {buildRecordLink({
                            ...first,
                            iconName: "chevrons-left",
                            label: "components.recordNavigation.firstRecord_tooltip",
                        })}
                        {buildRecordLink({
                            ...previous,
                            iconName: "chevron-left",
                            label: "components.recordNavigation.previousRecord_tooltip",
                        })}
                        {buildRecordLink({
                            ...next,
                            iconName: "chevron-right",
                            label: "components.recordNavigation.nextRecord_tooltip",
                        })}
                        {buildRecordLink({
                            ...last,
                            iconName: "chevrons-right",
                            label: "components.recordNavigation.lastRecord_tooltip",
                        })}
                    </GtbNav>
                    {recordSequenceChanged && (
                        <span role="status" className="recordNavigationWarning">
                            {translation("components.recordNavigation.sequenceChangedAfterSave_text")}
                        </span>
                    )}
                </>
            )}
        </div>
    );
}

export interface RecordNavigationLink {
    disabled: boolean;
    pathname: string;
    state: any;
}

export interface RecordNavigationProps {
    first: RecordNavigationLink;
    previous: RecordNavigationLink;
    current?: RecordNavigationLink;
    next: RecordNavigationLink;
    last: RecordNavigationLink;
    recordSequenceChanged: boolean;
}

export default RecordNavigation;
