import { ComponentPropsWithoutRef } from "react";
import { DropdownStoreProvider } from "./dropdownStore";

interface DropdownContainerProps extends ComponentPropsWithoutRef<"div"> {}

function DropdownContainer({ children }: DropdownContainerProps) {
    return <DropdownStoreProvider>{children}</DropdownStoreProvider>;
}

export default DropdownContainer;
