import { RefObject, useCallback, useEffect, useRef } from "react";

/**
 * Hook to identify a click outside a given element
 * @param elRef the ref for the element, where clicks outside shall be catched
 * @param onClickOutside the callback when the click outside gets catched
 * @param isActive activates or deactivates this hook. For example when the elRef component is not in the dom the hook should be disabled.
 */
function useClickOutside(
  elRef: RefObject<HTMLElement>,
  onClickOutside: (this: Document, ev: MouseEvent) => any,
  isActive = true
) {
  const callbackRef = useRef<any>();
  callbackRef.current = onClickOutside;

  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      if (!elRef?.current?.contains(e.target as Node) && callbackRef.current) {
        callbackRef.current(e);
      }
    },
    [elRef]
  );

  useEffect(() => {
    if (isActive) {
      document.addEventListener("click", handleClickOutside, true);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [handleClickOutside, isActive]);
}

export default useClickOutside;
