import "../link.css";
import DataGrid, { GridProps } from "./DataGrid";
import hocCompose from "../../utils/hocCompose";
import { WithDataProps } from "./hoc/withData";
import withColumnSettings, { WithColumnSettingsProps } from "./hoc/columSettings/withColumnSettings";
import "./subList.css";
import useGtbTranslation, { I18nKey } from "../../i18n/useGtbTranslation";
import { withInfinityScrollData } from "./hoc/withInfinityScrollData";
import { withTotalItemCount } from "./hoc/withTotalItemCount";
import { Entity } from "../../utils/typeUtils";

export interface SubListProps<ItemType extends Entity>
    extends Omit<GridProps<ItemType>, "query">,
        WithColumnSettingsProps,
        WithDataProps<ItemType> {
    header: I18nKey;
}

const Grid = hocCompose(withInfinityScrollData, withColumnSettings, withTotalItemCount)(DataGrid);

export default function InfinityScrollSubList<ItemType extends Entity>({
    header,
    gridControlsLeft,
    tableName,
    ...rest
}: SubListProps<ItemType>) {
    const translation = useGtbTranslation();

    return (
        <Grid
            {...rest}
            gridControlsLeft={[
                <div key={tableName + "header"} className="subListHeader">
                    {translation(header)}
                </div>,
                ...(gridControlsLeft ?? []),
            ]}
            tableName={tableName}
        />
    );
}
