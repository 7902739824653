import useDetailsCreate from "../../hooks/details/useDetailsCreate";
import { yupResolver } from "@hookform/resolvers/yup";
import { Nullable } from "../../utils/typeUtils";
import { useMemo } from "react";
import { TimeBasedMailDetailViewProps } from "./TimeBasedMailDetailView";
import { TimeBasedMailDetails } from "../timeBasedMailTypes";
import { timeBasedMailValidationSchema } from "./timeBasedMailValidationSchema";
import useTargetDateTime from "./useTargetDateTime";

export default function useTimeBasedMailCreate(): TimeBasedMailDetailViewProps {
    const initialData = useMemo<Nullable<TimeBasedMailDetails>>(
        () => ({
            id: null,
            subject: null,
            targetDate: null,
            targetTime: null,
            targetDateTime: null,
            sent: false,
            body: null,
            dynamicUserGroup: null,
        }),
        []
    );

    const { detailViewProps, form, title } = useDetailsCreate<TimeBasedMailDetails>({
        title: "timeBasedMail.detail.newRecord_title",
        frontendRoute: "timeBasedMail",
        initialData,
        resolver: yupResolver(timeBasedMailValidationSchema),
        saveUrl: "/certificate-service/time-based-mails",
    });

    useTargetDateTime(form);

    return {
        title,
        detailViewProps,
        form,
        readOnly: false,
        createRoute: "timeBasedMail",
    };
}
