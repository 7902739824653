import hocCompose from "../../utils/hocCompose";
import withMultiSelectColumn, { WithMultiSelectColumnProps } from "../grid/hoc/withMultiSelectColumn";
import DataGrid from "../grid/DataGrid";
import ViewContainer from "../ViewContainer";
import ViewHeading from "../ViewHeading";
import { MultiSelectStoreProvider } from "../../utils/multiSelectStore";
import withColumnSettings, { WithColumnSettingsProps } from "../grid/hoc/columSettings/withColumnSettings";
import withBulkActions, { WithBulkActionAfterDataProps } from "../grid/hoc/withBulkActions";
import withFilterCommands, { WithFilterCommandsProps } from "../grid/hoc/withFilterCommands";
import { withData, WithDataProps } from "../grid/hoc/withData";
import useGtbTranslation, { I18nKey } from "../../i18n/useGtbTranslation";
import { Entity } from "../../utils/typeUtils";

const NonInfinityGrid = hocCompose(
    withData,
    withColumnSettings,
    withMultiSelectColumn,
    withBulkActions,
    withFilterCommands
)(DataGrid);

export default function EntityListViewWithoutInfinityScroll<MasterData extends Entity>({
    heading,
    gridOptions,
}: {
    heading: I18nKey;
    gridOptions: EntityListViewWithoutInfinityScrollGridOptions<MasterData>;
}) {
    const translation = useGtbTranslation();

    return (
        <ViewContainer>
            <ViewHeading>{translation(heading)}</ViewHeading>
            <MultiSelectStoreProvider>
                <NonInfinityGrid {...gridOptions} />
            </MultiSelectStoreProvider>
        </ViewContainer>
    );
}

export interface EntityListViewWithoutInfinityScrollGridOptions<MasterData extends Entity>
    extends WithDataProps<MasterData>,
        WithColumnSettingsProps,
        WithMultiSelectColumnProps<MasterData>,
        WithBulkActionAfterDataProps<MasterData>,
        WithFilterCommandsProps<MasterData> {}
