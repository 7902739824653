import ViewContainer from "../ViewContainer";
import ViewHeading from "../ViewHeading";
import { MultiSelectStoreProvider } from "../../utils/multiSelectStore";
import hocCompose from "../../utils/hocCompose";
import { withInfinityScrollData, WithInfinityScrollDataProps } from "../grid/hoc/withInfinityScrollData";
import withMultiSelectColumn, { WithMultiSelectColumnProps } from "../grid/hoc/withMultiSelectColumn";
import DataGrid from "../grid/DataGrid";
import withColumnSettings, { WithColumnSettingsProps } from "../grid/hoc/columSettings/withColumnSettings";
import withBulkActions, { WithBulkActionAfterDataProps } from "../grid/hoc/withBulkActions";
import withFilterCommands, { WithFilterCommandsProps } from "../grid/hoc/withFilterCommands";
import useGtbTranslation, { I18nKey } from "../../i18n/useGtbTranslation";
import { ReactNode } from "react";
import { Entity } from "../../utils/typeUtils";

const Grid = hocCompose(
    withInfinityScrollData,
    withMultiSelectColumn,
    withColumnSettings,
    withBulkActions,
    withFilterCommands
)(DataGrid);

export default function EntityListView<MasterData extends Entity>({
    heading,
    gridOptions,
    children,
}: {
    heading: I18nKey;
    gridOptions: EntityListViewGridProps<MasterData>;
    children?: ReactNode;
}) {
    const translation = useGtbTranslation();

    return (
        <ViewContainer>
            <ViewHeading>{translation(heading)}</ViewHeading>
            <MultiSelectStoreProvider>
                {children}
                <Grid {...gridOptions} />
            </MultiSelectStoreProvider>
        </ViewContainer>
    );
}

export interface EntityListViewGridProps<MasterData extends Entity>
    extends WithInfinityScrollDataProps<MasterData>,
        WithMultiSelectColumnProps<MasterData>,
        WithColumnSettingsProps,
        WithBulkActionAfterDataProps<MasterData>,
        WithFilterCommandsProps<MasterData> {}
