import { I18nKey } from "../i18n/useGtbTranslation";

const searchContextMap = new Map<string, PreGtbTcSearchContext>();
searchContextMap.set("all", {
    id: "ALL",
    navigationContextLabel: "preGtbTransactionCertificate.list.header_all",
});

searchContextMap.set("preliminary", {
    id: "PRELIMINARY",
    navigationContextLabel: "preGtbTransactionCertificate.list.header_preliminary",
});

searchContextMap.set("final", {
    id: "FINAL",
    navigationContextLabel: "preGtbTransactionCertificate.list.header_final",
});

searchContextMap.set("withdrawn", {
    id: "WITHDRAWN",
    navigationContextLabel: "preGtbTransactionCertificate.list.header_withdrawn",
});

export default function usePreGtbTcSearchContext(context: PreGtbTcSearchContextSelection): PreGtbTcSearchContext {
    return searchContextMap.get(context)!;
}

export interface PreGtbTcSearchContext {
    id: string;
    navigationContextLabel: I18nKey;
}

export type PreGtbTcSearchContextSelection = "all" | "preliminary" | "final" | "withdrawn";
