import { TypedDetailViewProps } from "../../components/detailView/DetailView";
import { UseGtbFormReturn } from "../../hooks/formHandling/useGtbForm";
import "../../components/spinner.css";
import { ProductDetailDetails } from "../productDetailTypes";
import ProductDetailBasicData from "../../productDetail/details/ProductDetailBasicData";
import EntityDetailView from "../../components/entity/EntityDetailView";
import { useHelpContext } from "../../help/context-sensitivity/useHelpContext";
import { buildLanguageVersionTab } from "../../i18n/LanguageVersionTab";
import Tabs, { TabKey } from "../../components/tabs/Tabs";
import "./productDetailLanguageVersion.css";

export default function ProductDetailDetailView({
    detailViewProps,
    form,
    isReadOnly,
    ...rest
}: ProductDetailDetailViewProps) {
    useHelpContext("product-detail-details");
    return (
        <EntityDetailView detailViewProps={detailViewProps} form={form} {...rest}>
            <Tabs<
                ProductDetailDetails,
                TabKey<"productDetail.detail.basicData_tab" | "translation.languageVersion_tab">
            >
                tabs={[
                    {
                        heading: "productDetail.detail.basicData_tab",
                        element: <ProductDetailBasicData form={form} readOnly={isReadOnly} />,
                    },
                    buildLanguageVersionTab({
                        form,
                        readOnly: isReadOnly,
                        fields: ["name"],
                        baseName: "productDetail",
                    }),
                ]}
                errors={form.form.formState.errors}
                errorToTab={{
                    id: "internal",
                    code: "productDetail.detail.basicData_tab",
                    pickListSequence: "productDetail.detail.basicData_tab",
                    name: "translation.languageVersion_tab",
                    startDate: "productDetail.detail.basicData_tab",
                    endDate: "productDetail.detail.basicData_tab",
                }}
            />
        </EntityDetailView>
    );
}

export interface ProductDetailDetailViewProps extends TypedDetailViewProps<ProductDetailDetails> {
    isReadOnly: boolean;
}

export interface ProductDetailDetailViewTabProps {
    form: UseGtbFormReturn<ProductDetailDetails>;
    readOnly: boolean;
}
