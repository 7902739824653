import useGtbTranslation, { I18nKey } from "../../i18n/useGtbTranslation";
import "./listPlaceholder.css";
import "./subList.css";
import { joinClassNames } from "../../utils/StringUtils";

export default function ListPlaceholder({
    className,
    message,
    header,
}: {
    className: string;
    message: I18nKey;
    header: I18nKey;
}) {
    const translation = useGtbTranslation();

    return (
        <div className={className}>
            <div className={joinClassNames("subListHeader", "listPlaceholderHeader")}>{translation(header)}</div>
            <div className={"listPlaceholderMessage"}>{translation(message)}</div>
        </div>
    );
}
