import GtbTextField, { GtbTextFieldProps } from "../components/Input/GtbTextField";
import { UseGtbFormReturn } from "../hooks/formHandling/useGtbForm";
import { FieldValues } from "react-hook-form/dist/types";
import { keysWithValueType } from "../utils/typeUtils";
import { TranslatableValue } from "./languageTypes";
import useLanguage from "./LanguageContextProvider";

export default function CurrentLanguageWatchField<ItemType extends FieldValues>({
    form,
    field,
    ...rest
}: {
    form: UseGtbFormReturn<ItemType>;
    field: keyof keysWithValueType<ItemType, TranslatableValue> & string;
    label: GtbTextFieldProps["label"];
    className: GtbTextFieldProps["className"];
}) {
    const currentLanguage = useLanguage();

    return (
        <GtbTextField
            value={
                form.form.watch(
                    `${field}.${currentLanguage}` as ItemType[keyof keysWithValueType<ItemType, TranslatableValue>]
                ) ?? ""
            }
            readOnly={true}
            {...rest}
        />
    );
}
