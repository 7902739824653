import { ComponentType, useCallback, useEffect, useMemo } from "react";
import { useDispatchMultiSelectStore, useMultiSelectStore } from "../../../utils/multiSelectStore";
import { GridColumn } from "../component/Grid";
import { AccessibleCheckbox } from "../../Input/Checkbox";
import SelectionCell from "../component/cell/SelectionCell";
import { GridProps } from "../DataGrid";
import GridItemCount from "../component/GridItemCount";
import { Entity } from "../../../utils/typeUtils";

export interface WithMultiSelectColumnProps<ItemType extends Entity> {
    uniqueDataKey: Extract<keyof ItemType, string>;
}

function withMultiSelectColumn<ItemType extends Entity>(
    Grid: ComponentType<GridProps<ItemType> & WithMultiSelectColumnProps<ItemType>>
) {
    return ({
        columns,
        uniqueDataKey,
        total,
        gridControlsRight,
        ...rest
    }: GridProps<ItemType> & WithMultiSelectColumnProps<ItemType>) => {
        const dispatch = useDispatchMultiSelectStore();
        const { selectedItems, allSelectedState, isBlacklist } = useMultiSelectStore();

        useEffect(() => {
            dispatch({ type: "updateItemCount", payload: { itemCount: total } });
        }, [dispatch, total]);

        const buildMultiSelectColumn = useCallback(
            (columnKey: GridColumn<ItemType>["key"]): GridColumn<any> => {
                return {
                    key: "gridSelectCol",
                    visible: true,
                    header: {
                        // width of checkbox + 2 * column padding = 18 + 2 * 20
                        minWidth: 58,
                        width: 58,
                        component: (
                            <AccessibleCheckbox
                                onChange={() =>
                                    dispatch({
                                        type: allSelectedState === "all" ? "deselectAll" : "selectAll",
                                        payload: {},
                                    })
                                }
                                className="gridFilterAll"
                                label={"components.grid.selectAll_accessibleLabel"}
                                checked={allSelectedState === "all"}
                                indeterminate={allSelectedState === "indeterminate"}
                            />
                        ),
                    },
                    bodyItem: {
                        contentProvider: (item) => (
                            <SelectionCell
                                ariaLabel={{
                                    key: "components.grid.select_accessibleLabel",
                                    options: { uniqueDataKey: item[columnKey] },
                                }}
                                onChange={() => dispatch({ type: "toggle", payload: { id: item[uniqueDataKey] } })}
                                selected={
                                    allSelectedState === "all" ||
                                    !isBlacklist === selectedItems.includes(item[uniqueDataKey])
                                }
                            />
                        ),
                    },
                };
            },
            [allSelectedState, dispatch, isBlacklist, selectedItems, uniqueDataKey]
        );

        const _columns = useMemo(
            () => [buildMultiSelectColumn(columns[0]?.key), ...columns],
            [buildMultiSelectColumn, columns]
        );

        const selectedItemCount = useMemo(() => {
            if (total === undefined) {
                return undefined;
            }
            return isBlacklist ? total - selectedItems.length : selectedItems.length;
        }, [isBlacklist, selectedItems.length, total]);

        return (
            <Grid
                uniqueDataKey={uniqueDataKey}
                columns={_columns}
                total={total}
                gridControlsRight={[
                    <GridItemCount
                        key="itemCount"
                        itemCountTranslation={{
                            key: "components.grid.itemCount_multiSelect",
                            options: {
                                total: total,
                                count: selectedItemCount,
                            },
                        }}
                    />,
                    ...(gridControlsRight ?? []),
                ]}
                {...rest}
            />
        );
    };
}

export default withMultiSelectColumn;
