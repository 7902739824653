import * as React from "react";
import { ReactNode } from "react";
import { I18nKey } from "../../i18n/useGtbTranslation";
import useComponentHasChildren from "../../hooks/useComponentHasChildren";
import DropdownComponent from "../../components/DropdownComponent";

function MainMenuItem({ name, children }: { name: I18nKey; children: ReactNode }) {
    const hasChildren = useComponentHasChildren(children);

    return hasChildren ? (
        <li className="mainMenuItem">
            <DropdownComponent label={name} className="openMainMenuButton">
                {children}
            </DropdownComponent>
        </li>
    ) : null;
}

export default MainMenuItem;
