import useDetailsCreate from "../../hooks/details/useDetailsCreate";
import { yupResolver } from "@hookform/resolvers/yup";
import { Nullable } from "../../utils/typeUtils";
import { ProductDetailDetails } from "../productDetailTypes";
import { productDetailValidationSchema } from "./productDetailValidationSchema";
import { routeEnum } from "../../components/routing/useResolvedRoute";
import { getInitialTranslatableValue } from "../../i18n/languageTypes";

const initialData: Nullable<ProductDetailDetails> = {
    code: null,
    id: null,
    name: getInitialTranslatableValue(),
    pickListSequence: null,
    startDate: null,
    endDate: null,
};

function useProductDetailCreate() {
    const { detailViewProps, form, title } = useDetailsCreate<ProductDetailDetails>({
        title: "productDetail.detail.newRecord_title",
        frontendRoute: "productDetail",
        initialData,
        resolver: yupResolver(productDetailValidationSchema),
        saveUrl: "/certificate-service/product-details",
    });

    return {
        title,
        detailViewProps,
        form,
        navigationEnabled: false,
        isReadOnly: false,
        createRoute: "productDetail" as routeEnum,
    };
}

export default useProductDetailCreate;
