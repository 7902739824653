import ScFacilityBasicData from "./ScFacilityBasicData";
import { TypedDetailViewProps } from "../../../../../../../components/detailView/DetailView";
import { useHelpContext } from "../../../../../../../help/context-sensitivity/useHelpContext";
import EntityDetailView from "../../../../../../../components/entity/EntityDetailView";
import Tabs, { TabKey } from "../../../../../../../components/tabs/Tabs";
import { ScFacilityDetails } from "./scFacilityTypes";

export interface ScFacilityDetailViewProps extends TypedDetailViewProps<ScFacilityDetails> {
    readOnly: boolean;
    scopeCertificateId: string;
}

export default function ScFacilityDetailView({
    detailViewProps,
    form,
    scopeCertificateId,
    readOnly,
    ...rest
}: ScFacilityDetailViewProps) {
    useHelpContext("sc-facility-details");

    return (
        <EntityDetailView {...rest} detailViewProps={detailViewProps} form={form}>
            <Tabs<ScFacilityDetails, ScFacilityTabs>
                tabs={[
                    {
                        heading: "scFacility.detail.basicData_tab",
                        element: (
                            <ScFacilityBasicData
                                form={form}
                                scopeCertificateId={scopeCertificateId}
                                readOnly={readOnly}
                            />
                        ),
                    },
                ]}
                errors={form.form.formState.errors}
                errorToTab={{
                    id: "internal",
                    scopeCertificateSupplyChainOperatorBusinessName: "scFacility.detail.basicData_tab",
                    scProductScProductNumber: "scFacility.detail.basicData_tab",
                    scProductProductCategoryName: "scFacility.detail.basicData_tab",
                    scProductProductDetailName: "scFacility.detail.basicData_tab",
                    scProductLabelGradeLabelGrade: "scFacility.detail.basicData_tab",
                    scProcessCategoryName: "scFacility.detail.basicData_tab",
                    facility: "scFacility.detail.basicData_tab",
                    facilityBusinessName: "internal",
                    scopeCertificateStatus: "internal",
                    scProcessCategoryScProductProductCategoryPrint: "scFacility.detail.basicData_tab",
                    scProcessCategoryScProductProductDetailPrint: "scFacility.detail.basicData_tab",
                    scProcessCategoryProcessCategoryPrint: "scFacility.detail.basicData_tab",
                }}
            />
        </EntityDetailView>
    );
}

type ScFacilityTabs = TabKey<"scFacility.detail.basicData_tab">;
