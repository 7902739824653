import { joinClassNames } from "../../utils/StringUtils";
import { UseGtbFormReturn } from "../../hooks/formHandling/useGtbForm";
import { OrganicMaterialPurchaseDetails } from "../organicMaterialPurchaseTypes";
import { GtbNumberFieldOneFixedDecimal } from "../../components/Input/GtbNumberField";
import { ReadOnlyProps } from "../../utils/typeUtils";
import ControlledFileUploadComponent from "../../components/Input/UploadComponent/ControlledFileUploadComponent";
import { PDF_TYPE } from "../../components/Input/UploadComponent/FileUploadComponent";
import { backendUrlType } from "../../hooks/useAxios";
import SupplyChainOperatorPickList from "../../components/Input/pickList/typedPicklist/SupplyChainOperatorPickList";
import RawMaterialPickList from "../../components/Input/pickList/typedPicklist/RawMaterialPickList";
import EnumEntityRadiobuttonGroup from "../../components/Input/radiobutton/EnumEntityRadiobuttonGroup";
import { RawMaterialReference } from "../../rawMaterial/rawMaterialTypes";
import "./organicMaterialPurchaseBasicData.css";
import OrganicMaterialOrigins from "./OrganicMaterialOrigins";
import useWarningDialog from "../../components/dialog/UseWarningDialog";
import { useCallback, useState } from "react";

export interface OrganicMaterialPurchaseBasicDataProps {
    form: UseGtbFormReturn<OrganicMaterialPurchaseDetails>;
    readOnly: Omit<
        ReadOnlyProps<OrganicMaterialPurchaseDetails>,
        "id" | "systemId" | "buyer" | "initialQuantity" | "availableQuantity" | "frozen"
    >;
    visibility: Pick<
        ReadOnlyProps<OrganicMaterialPurchaseDetails>,
        "gmoScreeningReport" | "screeningResult" | "rejected"
    >;
    frozen: boolean;
    gmoScreeningReportUrl: backendUrlType;
    onCertifiedMaterialChange?: (material: RawMaterialReference | null) => void;
    subListVisible?: boolean;
    refetchInitialVolume?: Function;
    initialAvailableQuantityIsSet: boolean;
}

export default function OrganicMaterialPurchaseBasicData({
    form,
    readOnly,
    visibility,
    frozen,
    gmoScreeningReportUrl,
    onCertifiedMaterialChange,
    subListVisible,
    refetchInitialVolume,
    initialAvailableQuantityIsSet,
}: OrganicMaterialPurchaseBasicDataProps) {
    const { showDialog } = useWarningDialog();
    const [shouldShowInitialAvailableQuantityDialog, setShouldShowInitialAvailableQuantityDialog] = useState(false);
    const showInitialAvailableQuantityDialog = useCallback(() => {
        if (shouldShowInitialAvailableQuantityDialog && form.form.getValues("initialAvailableQuantity") > 0) {
            showDialog({
                title: "organicMaterialPurchase.detail.initialAvailableQuantityDialog_title",
                message: "organicMaterialPurchase.detail.initialAvailableQuantityDialog_message",
            });
        }
        setShouldShowInitialAvailableQuantityDialog(false);
    }, [form.form, shouldShowInitialAvailableQuantityDialog, showDialog]);

    return (
        <div className={joinClassNames("organicMaterialPurchaseBasicData", "formGrid")}>
            <SupplyChainOperatorPickList
                label="organicMaterialPurchase.detail.buyer_input"
                control={form.form.control}
                name="buyer"
                className="buyer"
                readOnly
            />
            <RawMaterialPickList
                label="organicMaterialPurchase.detail.certifiedOrganicMaterial_input"
                control={form.form.control}
                name="certifiedOrganicMaterial"
                className="certifiedOrganicMaterial"
                flags={{
                    typeOfMaterial: ["ORGANIC", "IN_CONVERSION"],
                }}
                onChangeListener={(_e, _v, material) => onCertifiedMaterialChange?.(material)}
                readOnly={readOnly.certifiedOrganicMaterial}
            />
            <GtbNumberFieldOneFixedDecimal
                label="organicMaterialPurchase.detail.availableQuantity_input"
                control={form.form.control}
                name="availableQuantity"
                className="availableQuantity"
                decimalPlaces={1}
                unit="kg"
                readOnly
            />
            <GtbNumberFieldOneFixedDecimal
                label="organicMaterialPurchase.detail.initialQuantity_input"
                control={form.form.control}
                name="initialQuantity"
                readOnly
                className="initialQuantity"
                decimalPlaces={1}
                unit="kg"
            />
            <GtbNumberFieldOneFixedDecimal
                label="organicMaterialPurchase.detail.initialAvailableQuantity_input"
                control={form.form.control}
                name="initialAvailableQuantity"
                className="initialAvailableQuantity"
                decimalPlaces={1}
                unit="kg"
                readOnly={readOnly.initialAvailableQuantity}
                onBlur={() => showInitialAvailableQuantityDialog()}
                onKeyDown={(event) => {
                    if (event.key === "Enter" && shouldShowInitialAvailableQuantityDialog) {
                        event.preventDefault();
                        showInitialAvailableQuantityDialog();
                    }
                }}
                onChangeListener={(event) => {
                    const value = Number(event.target.value);
                    setShouldShowInitialAvailableQuantityDialog(
                        value > 0 && value <= form.form.getValues("initialQuantity")
                    );
                }}
            />
            {visibility.gmoScreeningReport && (
                <ControlledFileUploadComponent
                    label="organicMaterialPurchase.detail.gmoScreeningReport_input"
                    control={form.form.control}
                    name="gmoScreeningReport"
                    acceptedTypes={PDF_TYPE}
                    fileUrl={gmoScreeningReportUrl}
                    readOnly={readOnly.gmoScreeningReport}
                    className="gmoScreeningReport"
                />
            )}
            {visibility.screeningResult && (
                <EnumEntityRadiobuttonGroup
                    label={"organicMaterialPurchase.detail.screeningResult_input"}
                    readOnly={readOnly.screeningResult}
                    {...form.registerWithErrors("screeningResult")}
                    className="screeningResult"
                    name="screeningResult"
                    referenceUrl="/certificate-service/references/screening-results"
                />
            )}
            {visibility.rejected && (
                <EnumEntityRadiobuttonGroup
                    label={"organicMaterialPurchase.detail.rejected_input"}
                    readOnly={readOnly.rejected}
                    {...form.registerWithErrors("rejected")}
                    className="rejected"
                    name="rejected"
                    referenceUrl="/certificate-service/references/rejected"
                />
            )}
            {subListVisible && (
                <OrganicMaterialOrigins
                    isFrozen={frozen}
                    initialAvailableQuantityIsSet={initialAvailableQuantityIsSet}
                    afterDeletion={refetchInitialVolume!}
                />
            )}
        </div>
    );
}
