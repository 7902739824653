import useEventMonitorListView from "./useEventMonitorListView";
import ViewContainer from "../components/ViewContainer";
import ViewHeading from "../components/ViewHeading";
import hocCompose from "../utils/hocCompose";
import withColumnSettings, { WithColumnSettingsProps } from "../components/grid/hoc/columSettings/withColumnSettings";
import DataGrid from "../components/grid/DataGrid";
import { withData, WithDataProps } from "../components/grid/hoc/withData";
import { useHelpContext } from "../help/context-sensitivity/useHelpContext";
import { EventMonitorSummary } from "./eventMonitorTypes";
import useGtbTranslation from "../i18n/useGtbTranslation";
import { withTotalItemCount } from "../components/grid/hoc/withTotalItemCount";

const Grid = hocCompose(withData, withColumnSettings, withTotalItemCount)(DataGrid);
export default function EventMonitorListView() {
    const translation = useGtbTranslation();
    const eventMonitorGridOptions = useEventMonitorListView();
    useHelpContext("siem-configuration-list");
    return (
        <ViewContainer>
            <ViewHeading>{translation("eventMonitor.list.header")}</ViewHeading>
            <Grid {...eventMonitorGridOptions} />
        </ViewContainer>
    );
}

export interface EventMonitorGridProps extends WithDataProps<EventMonitorSummary>, WithColumnSettingsProps {}
