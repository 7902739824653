import { DependentPicklist, DependentPicklistProps } from "./DependentPicklist";
import { FieldValues } from "react-hook-form/dist/types";
import { joinClassNames } from "../../../../utils/StringUtils";
import { OrganisationReference } from "../../../../organisation/organisationTypes";
import { useQuery } from "../../../../hooks/useAxios";
import { useCallback } from "react";
import "./typedPickList.css";

export default function OrganisationPickList<FormItemType extends FieldValues>({
    className,
    ...rest
}: DependentPicklistProps<FormItemType, OrganisationReference>) {
    const { runQuery: fetchAuthorisationRole } = useQuery({
        url: "",
        enabled: false,
    });

    const organisationUrlResolver = useCallback(
        async (roleId: string) => {
            const authorisationRole = await fetchAuthorisationRole({
                url: `/certificate-service/references/roles/${roleId}`,
            });
            switch (authorisationRole?.type) {
                case "STANDARD_BODY":
                    return "/certificate-service/references/standard-bodies";
                case "CERTIFICATION_BODY":
                    return "/certificate-service/references/certification-bodies";
                case "SUPPLY_CHAIN_OPERATOR":
                    return "/certificate-service/references/supply-chain-operators";
                default:
                    return "";
            }
        },
        [fetchAuthorisationRole]
    );

    return (
        <DependentPicklist
            className={joinClassNames("organisationPickList", className)}
            urlBuilder={organisationUrlResolver}
            itemId="id"
            selectedItemLabel="legalName"
            itemLabel={(option) =>
                `${option.legalName}\n${option.town}, ${option.stateProvinceCountryAreaName}, (${option.stateProvinceIsoCode})`
            }
            {...rest}
        />
    );
}
