import {
    buildSchema,
    validateDate,
    validateNumber,
    validateString,
    validateTranslation,
} from "../../utils/yupExtension";
import { RawMaterialDetails } from "../rawMaterialTypes";

export const rawMaterialValidationSchema = buildSchema<RawMaterialDetails>({
    code: validateString().isRequired().hasMinLength(6).hasMaxLength(6),
    pickListSequence: validateNumber().isRequired(),
    name: validateTranslation(80),
    typeOfMaterial: validateString().isRequired(),
    startDate: validateDate(),
    endDate: validateDate(),
});
