import useAccreditationBodyListView from "./useAccreditationBodyListView";
import EntityListViewWithoutInfinityScroll from "../components/entity/EntityListViewExcludingInfinityScroll";
import { useHelpContext } from "../help/context-sensitivity/useHelpContext";

export default function AccreditationBodyListView() {
    const accreditationBodyGridOptions = useAccreditationBodyListView();
    useHelpContext("accreditation-body-list");

    return (
        <EntityListViewWithoutInfinityScroll
            heading="accreditationBody.list.header"
            gridOptions={accreditationBodyGridOptions}
        />
    );
}
