import { buildSchema, validateString } from "../../utils/yupExtension";
import { TransactionCertificateDetails } from "../transactionCertificateTypes";
import { BaseSchema } from "yup";
import { TransactionCertificateStatusType } from "../../auth/roleTypes";

export const transactionCertificateValidationSchema = buildSchema<TransactionCertificateDetails>({
    certificationBody: validateString().isRequired(),
    cbTcReferenceNumber: validateString().hasMaxLength(30),
    seller: validateString().isRequired(),
    scopeCertificate: validateString().isRequired(),
    declarationsBySeller: validateString().hasMaxLength(500),
    status: validateString().isRequired() as BaseSchema<TransactionCertificateStatusType>,
});
