import { yupResolver } from "@hookform/resolvers/yup";
import { eventMonitorValidationSchema } from "./eventMonitorValidationSchema";
import { EventMonitorDetails } from "../eventMonitorTypes";
import { EventMonitorDetailViewProps } from "./EventMonitorDetailView";
import useResolvedRoute from "../../components/routing/useResolvedRoute";
import useDetailsEdit from "../../hooks/details/useDetailsEdit";

function useEventMonitorEdit(): EventMonitorDetailViewProps {
    const { getResolvedId } = useResolvedRoute();
    const eventMonitorId = getResolvedId("eventMonitor");

    const { detailViewProps, form, initialData, title } = useDetailsEdit<EventMonitorDetails>({
        baseUrl: "/log-service/event-monitors/" + eventMonitorId,
        resolver: yupResolver(eventMonitorValidationSchema),
        titleBuilder: (item) => item.name,
        canSave: true,
        frontendRoute: "eventMonitor",
    });

    return {
        detailViewProps,
        form,
        title,
        subtitle: `${initialData?.eventType}`,
    };
}

export default useEventMonitorEdit;
