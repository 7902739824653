import { joinClassNames } from "../../utils/StringUtils";
import { DeduplicationLogDetailViewTabProps } from "./DeduplicationLogEditView";
import GtbTextField from "../../components/Input/GtbTextField";
import GtbTextArea from "../../components/Input/GtbTextArea";
import "./deduplicationLogData.css";
import GtbReadOnlyDateTimeField from "../../components/Input/GtbReadOnlyDateTimeField";

function DeduplicationLogData({ form }: DeduplicationLogDetailViewTabProps) {
    return (
        <div className={joinClassNames("deduplicationLogData", "formGrid")}>
            <GtbReadOnlyDateTimeField
                className="timestamp"
                label="deduplicationLog.detail.timestamp_input"
                control={form.form.control}
                name="timestamp"
            />
            <GtbTextArea
                className="comment"
                readOnly
                label="deduplicationLog.detail.comment_input"
                minRows={10}
                {...form.registerWithErrors("comment")}
            />
            <GtbTextField
                className="sourceId"
                readOnly
                label="deduplicationLog.detail.sourceId_input"
                {...form.registerWithErrors("sourceId")}
            />
            <GtbTextField
                className="sourceBusinessName"
                readOnly
                label="deduplicationLog.detail.sourceBusinessName_input"
                {...form.registerWithErrors("sourceBusinessName")}
            />
            <GtbTextField
                className="destinationId"
                readOnly
                label="deduplicationLog.detail.destinationId_input"
                {...form.registerWithErrors("destinationId")}
            />
            <GtbTextField
                className="destinationBusinessName"
                readOnly
                label="deduplicationLog.detail.destinationBusinessName_input"
                {...form.registerWithErrors("destinationBusinessName")}
            />
            <GtbTextArea
                className="transferredAttributes"
                readOnly
                label="deduplicationLog.detail.transferredAttributes_input"
                minRows={6}
                maxRows={6}
                {...form.registerWithErrors("transferredAttributes")}
            />
            <GtbTextArea
                className="transferredEntities"
                readOnly
                label="deduplicationLog.detail.transferredEntities_input"
                minRows={6}
                maxRows={6}
                {...form.registerWithErrors("transferredEntities")}
            />
            <GtbTextArea
                className="transferredRelations"
                readOnly
                label="deduplicationLog.detail.transferredRelations_input"
                minRows={6}
                maxRows={6}
                {...form.registerWithErrors("transferredRelations")}
            />
        </div>
    );
}

export default DeduplicationLogData;
