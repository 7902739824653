import { useMemo } from "react";
import { useDeleteAction, useShowDetailsAction } from "../utils/defaultActions";
import { SortOption, useQueryBuilder } from "../components/grid/component/useQueryBuilder";
import useResolvedRoute from "../components/routing/useResolvedRoute";
import { SubListProps } from "../components/grid/SubList";
import { SupplyingRelationSummary } from "./supplyingRelationTypes";
import { GridColumn } from "../components/grid/component/Grid";
import { buildDateColumn, buildTextColumn } from "../components/grid/ColumnProvider";
import useIsAuthorised from "../auth/useIsAuthorised";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";

const defaultSupplyingRelationSubListSortOption: SortOption<SupplyingRelationSummary> = {
    field: "organicMaterialProducerBusinessName",
    direction: "ASC",
};

const columns: GridColumn<SupplyingRelationSummary>[] = [
    buildTextColumn({
        key: "organicMaterialProducerBusinessName",
        title: "supplyingRelation.list.organicMaterialProducer_column",
        minWidth: 257,
    }),
    buildTextColumn({
        key: "accessLevel",
        title: "supplyingRelation.list.accessLevel_column",
        minWidth: 153,
    }),
    buildDateColumn({
        key: "startDate",
        title: "supplyingRelation.list.startDate_column",
        minWidth: 130,
    }),
    buildDateColumn({
        key: "endDate",
        title: "supplyingRelation.list.endDate_column",
        minWidth: 124,
    }),
];

export default function useSupplyingRelationSubList() {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedId, getResolvedEditUrl, getResolvedCreateUrl } = useResolvedRoute();

    const { query, columnOptions, forceReload } = useQueryBuilder<SupplyingRelationSummary>(
        "/certificate-service/supplying-relations",
        {
            sort: defaultSupplyingRelationSubListSortOption,
            filterActive: false,
            supplyChainOperatorId: getResolvedId("supplyChainOperator"),
        }
    );

    const showDetailsAction = useShowDetailsAction(
        (item: SupplyingRelationSummary) => getResolvedEditUrl("supplyingRelation", item.id),
        query.parameter
    );

    const deleteAction = useDeleteAction(
        (item: SupplyingRelationSummary) => `/certificate-service/supplying-relations/${item.id}`,
        () => forceReload(),
        "deleteOperationLevelOrganisation"
    );

    const contextMenuActions = useGridRowContextMenuActions(showDetailsAction, deleteAction);

    return useMemo<SubListProps<SupplyingRelationSummary>>(
        () => ({
            query,
            columns: columns,
            uniqueDataKey: "id",
            onItemDoubleClick: showDetailsAction,
            header: "supplyChainOperator.detail.supplyingRelations_tab",
            itemActions: contextMenuActions,
            columnOptions,
            createUrl: hasAccessRight("createOperationLevelOrganisation")
                ? getResolvedCreateUrl("supplyChainOperatorSupplyingRelation")
                : undefined,
            tableName: "supplyingRelation",
        }),
        [columnOptions, contextMenuActions, getResolvedCreateUrl, hasAccessRight, query, showDetailsAction]
    );
}
