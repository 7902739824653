import { UseGtbFormReturn } from "../../hooks/formHandling/useGtbForm";
import { TransactionCertificateDetails } from "../transactionCertificateTypes";
import GtbTextArea from "../../components/Input/GtbTextArea";
import { LabeledCheckbox } from "../../components/Input/Checkbox";
import "./transactionCertificateAdditionalData.css";
import { joinClassNames } from "../../utils/StringUtils";

export interface TransactionCertificateAdditionalDataProps {
    form: UseGtbFormReturn<TransactionCertificateDetails>;
    readOnly: { declarationsBySeller: boolean; doNotDisplayLastProcessorOnPrintedTc: boolean };
}

export default function TransactionCertificateAdditionalData({
    form,
    readOnly,
}: TransactionCertificateAdditionalDataProps) {
    return (
        <div className={joinClassNames("transactionCertificateAdditionalData", "formGrid")}>
            <LabeledCheckbox
                label="transactionCertificate.detail.doNotDisplayLastProcessorOnPrintedTc_input"
                {...form.form.register("doNotDisplayLastProcessorOnPrintedTc")}
                id="doNotDisplayOnPrint"
                readOnly={readOnly.doNotDisplayLastProcessorOnPrintedTc}
            />
            <GtbTextArea
                label="transactionCertificate.detail.declarationsBySeller_input"
                {...form.registerWithErrors("declarationsBySeller")}
                readOnly={readOnly.declarationsBySeller}
            />
        </div>
    );
}
