import { useCallback } from "react";
import { UseGtbFormReturn } from "../../hooks/formHandling/useGtbForm";
import { ScopeCertificateDetails } from "../scopeCertificateTypes";
import { error } from "../../utils/notification/notification";
import { EMPTY_ERROR_MESSAGE_INDICATOR } from "../../utils/errorHandler";
import useGtbTranslation from "../../i18n/useGtbTranslation";

export default function useNoActiveCertRelHandler() {
    const translation = useGtbTranslation();

    return useCallback(
        (errorParam: any, form: UseGtbFormReturn<ScopeCertificateDetails>) => {
            if (
                errorParam.statusCode === 400 &&
                errorParam.data?.validationErrors &&
                errorParam.data.validationErrors["no_active_cert_rel"]
            ) {
                error(translation("validation.noActiveCertRelForSCOAndStandardVersion_message"));
                //@ts-ignore
                form.setErrors({
                    supplyChainOperator: EMPTY_ERROR_MESSAGE_INDICATOR,
                    standardVersion: EMPTY_ERROR_MESSAGE_INDICATOR,
                });

                return true;
            }

            return false;
        },
        [translation]
    );
}
