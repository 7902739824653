import { useCallback, useEffect, useMemo, useState } from "react";
import useAuth, { AuthResponse } from "../auth/useAuth";
import useGtbForm from "../hooks/formHandling/useGtbForm";
import { setAuthorizationHeader, useMutation } from "../hooks/useAxios";
import { useLocation } from "react-router-dom";
import { info } from "../utils/notification/notification";
import { UseFormSetError } from "react-hook-form/dist/types/form";
import useResetTotpDialog from "../components/dialog/useResetTotpDialog";
import useStickyState from "../hooks/useStickyState";
import { generateHash } from "../utils/StringUtils";
import jwtDecode from "jwt-decode";
import { LogoutReasonType } from "./LogoutView";
import { env } from "../env";
import useGtbTranslation, { I18nKey } from "../i18n/useGtbTranslation";

export type LoginInputTypes = {
    username: string;
    password: string;
};

export type OneTimePasswordInputTypes = {
    otp: string;
    skipAdditionalAuthFactor?: boolean;
};

export type additionalAuthFactorTypeEnum = "NONE" | "MAIL_OTP" | "TOTP";

export const localStorageOtpExemptionTokens = "gtbOtpExemptionTokens";

function useLogin() {
    const translation = useGtbTranslation();
    const [additionalAuthFactorType, setAdditionalAuthFactorType] = useState<additionalAuthFactorTypeEnum>("NONE");
    const [isOneTimePasswordExpired, setIsOneTimePasswordExpired] = useState(false);
    const [isAdditionalAuthFactorSkippable, setIsAdditionalAuthFactorSkippable] = useState(false);

    const { setAuth } = useAuth();
    const {
        registerWithErrors: loginRegister,
        form: { handleSubmit: handleLoginSubmit, setError: setLoginError, reset: resetLogin },
    } = useGtbForm<LoginInputTypes>({
        defaultValues: {
            username: "",
            password: "",
        },
    });

    const oneTimePasswordForm = useGtbForm<OneTimePasswordInputTypes>({
        defaultValues: {
            otp: "",
            skipAdditionalAuthFactor: false,
        },
    });

    const { isLoading: isLoginLoading, runQuery: loginQuery } = useMutation<AuthResponse>({
        method: "post",
        url: "/certificate-service/auth",
    });

    const { isLoading: isOneTimePasswordLoading, runQuery: oneTimePasswordQuery } = useMutation<AuthResponse>({
        method: "post",
        url: "/certificate-service/mfa",
    });

    const state = useLocation().state as LogoutReasonType;

    useEffect(() => {
        if (state?.logoutReason) {
            info(translation("loginPage.login_logout_success_message"));
        }
    }, [state, translation]);

    const handleError = useCallback(
        (
            error: any,
            onError: UseFormSetError<LoginInputTypes | OneTimePasswordInputTypes>,
            name: keyof LoginInputTypes | keyof OneTimePasswordInputTypes
        ) => {
            if (error?.statusCode === 401) {
                if (error?.completeError?.response?.headers?.reason === "MAX_LOGIN_ATTEMPTS_REACHED") {
                    onError(
                        name,
                        {
                            message: translation({
                                key: "loginPage.login_max_login_attempts_message",
                                options: {
                                    maxloginattempts: error?.completeError?.response?.headers?.maxloginattempts,
                                },
                            }),
                        },
                        { shouldFocus: true }
                    );
                } else if (error?.completeError?.response?.headers?.reason === "ACCOUNT_BLOCKED") {
                    onError(
                        name,
                        {
                            message: translation("loginPage.login_account_blocked_message"),
                        },
                        { shouldFocus: true }
                    );
                } else if (error?.completeError?.response?.headers?.reason === "BAD_OTP") {
                    onError(
                        name,
                        {
                            message: translation("loginPage.login_wrong_otp_message"),
                        },
                        { shouldFocus: true }
                    );
                } else {
                    onError(
                        name,
                        {
                            message: translation("loginPage.login_invalid_username_or_password_message"),
                        },
                        { shouldFocus: true }
                    );
                }
            } else {
                onError(name, { message: translation("error.misc.generic_error") });
            }
        },
        [translation]
    );

    const handleLoginError = useCallback(
        (error: any) => {
            handleError(
                error,
                setLoginError as UseFormSetError<LoginInputTypes | OneTimePasswordInputTypes>,
                "username"
            );
        },
        [handleError, setLoginError]
    );

    const handleOneTimePasswordError = useCallback(
        (error: any) => {
            if (error?.statusCode === 401 && error?.data?.reason === "EXPIRED_OTP") {
                setIsOneTimePasswordExpired(true);
            } else {
                handleError(
                    error,
                    oneTimePasswordForm.form.setError as UseFormSetError<LoginInputTypes | OneTimePasswordInputTypes>,
                    "otp"
                );
            }
        },
        [handleError, oneTimePasswordForm.form.setError]
    );

    const [otpExemptionTokens, setOtpExemptionTokens] = useStickyState<{}>(localStorageOtpExemptionTokens, null);

    const onLogin = useCallback(
        (e: any) => {
            const onSubmit = handleLoginSubmit((body) => {
                const hashedUsername = generateHash(body.username.toLocaleLowerCase());
                // @ts-ignore
                const otpExemptionToken = otpExemptionTokens ? otpExemptionTokens[hashedUsername] : undefined;

                loginQuery({
                    body: {
                        ...body,
                        username: body.username.toLocaleLowerCase(),
                        otpExemptionToken: otpExemptionToken,
                    },
                })
                    .then((auth: AuthResponse) => {
                        if (auth.additionalAuthFactorRequired) {
                            setAuthorizationHeader(auth.jwt);
                            setAdditionalAuthFactorType(auth.additionalAuthFactorType as additionalAuthFactorTypeEnum);
                            setIsOneTimePasswordExpired(false);
                            setIsAdditionalAuthFactorSkippable(auth.additionalAuthFactorSkippable ?? false);
                        } else {
                            setAuth({ jwt: auth.jwt, refreshToken: auth.refreshToken });
                        }
                    })
                    .catch(handleLoginError);
            });
            onSubmit(e).then(/*empty call because of lint error otherwise*/);
        },
        [otpExemptionTokens, handleLoginError, handleLoginSubmit, loginQuery, setAuth]
    );

    const showResetTotpDialog = useResetTotpDialog();

    const onOneTimePassword = useCallback(
        (e: any) => {
            const onSubmit = oneTimePasswordForm.form.handleSubmit((body) => {
                oneTimePasswordQuery({
                    body: {
                        otp: body.otp,
                        exemptionRequest: body.skipAdditionalAuthFactor
                            ? {
                                  userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                              }
                            : undefined,
                    },
                })
                    .then((auth: AuthResponse) => {
                        if (auth.otpExemption?.token) {
                            const { sub: username } = jwtDecode<{ sub: string }>(auth.jwt);
                            if (username) {
                                const otpExemptionTokenMap = otpExemptionTokens ? otpExemptionTokens : {};
                                const usernameHash = generateHash(username);

                                setOtpExemptionTokens({
                                    ...otpExemptionTokenMap,
                                    [usernameHash]: auth.otpExemption.token,
                                });
                            }
                        }
                        setAuth({ jwt: auth.jwt, refreshToken: auth.refreshToken });
                        if (auth.totpIsReset) {
                            showResetTotpDialog();
                        }
                    })
                    .catch(handleOneTimePasswordError);
            });
            onSubmit(e).then(/*empty call because of lint error otherwise*/);
        },
        [
            oneTimePasswordForm.form,
            oneTimePasswordQuery,
            handleOneTimePasswordError,
            setAuth,
            otpExemptionTokens,
            setOtpExemptionTokens,
            showResetTotpDialog,
        ]
    );

    const backToLogin = useCallback(() => {
        setAdditionalAuthFactorType("NONE");
        setIsOneTimePasswordExpired(false);
        resetLogin();
        oneTimePasswordForm.form.reset();
    }, [oneTimePasswordForm.form, resetLogin]);

    const infoMessage = useMemo<I18nKey | undefined>(() => {
        switch (state?.logoutReason) {
            case "timeOut":
                return {
                    key: "sessionTimeout.timeoutOccurredLoginFormMessage",
                    options: { inactivityMinutes: Math.floor(env.REACT_APP_TIMEOUT_PERIOD_SECONDS / 60) },
                };
            case "declinedToU":
                return "error.misc.terms_of_use_declined_error";
            default: {
                return undefined;
            }
        }
    }, [state]);

    return {
        loginRegister,
        isLoginLoading,
        onLogin,
        additionalAuthFactorType,
        oneTimePasswordForm,
        isOneTimePasswordLoading,
        onOneTimePassword,
        isOneTimePasswordExpired,
        backToLogin,
        isAdditionalAuthFactorSkippable,
        infoMessage,
    };
}

export default useLogin;
