import { SortOption } from "../components/grid/component/useQueryBuilder";
import { useMemo } from "react";
import { GridColumn } from "../components/grid/component/Grid";
import useListView from "../hooks/useListView";
import { useDeleteAction, useShowDetailsAction } from "../utils/defaultActions";
import { buildTextColumn } from "../components/grid/ColumnProvider";
import { EntityListViewGridProps } from "../components/entity/EntityListView";
import useResolvedRoute, { getCreateUrl } from "../components/routing/useResolvedRoute";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";
import { DynamicUserGroupDetails, DynamicUserGroupSummary } from "./dynamicUserGroupTypes";

export const defaultDynamicUserGroupSortOption: SortOption<
    DynamicUserGroupSummary | DynamicUserGroupDetails
> = {
    field: "name",
    direction: "ASC",
};

const columns: GridColumn<DynamicUserGroupSummary>[] = [
    buildTextColumn({
        key: "name",
        title: "dynamicUserGroup.list.name_column",
        minWidth: 123,
    }),
    buildTextColumn({
        key: "description",
        title: "dynamicUserGroup.list.description_column",
        minWidth: 145,
    }),
    buildTextColumn({
        key: "definition",
        title: "dynamicUserGroup.list.queryDefinition_column",
        minWidth: 130,
    }),
];

export default function useDynamicUserGroupListView(): EntityListViewGridProps<DynamicUserGroupSummary> {
    const { getResolvedEditUrl } = useResolvedRoute();

    const {
        queryBuilder: { forceReload, columnOptions, query, queryCommands },
    } = useListView<DynamicUserGroupSummary>({
        navigationContextLabel: "dynamicUserGroup.breadcrumb",
        baseQuery: "/certificate-service/dynamic-user-groups",
        defaultSortOption: defaultDynamicUserGroupSortOption,
    });

    const showDetailsAction = useShowDetailsAction(
        (item: DynamicUserGroupSummary) => getResolvedEditUrl("dynamicUserGroup", item.id),
        query.parameter,
    );

    const deleteAction = useDeleteAction((item: DynamicUserGroupSummary) => `/certificate-service/dynamic-user-groups/${item.id}`, forceReload);

    const actions = useGridRowContextMenuActions(showDetailsAction, deleteAction);

    return useMemo(() => {
        return {
            query,
            columns,
            uniqueDataKey: "id",
            onItemDoubleClick: showDetailsAction,
            createUrl: getCreateUrl("dynamicUserGroup"),
            tableName: "dynamicUserGroup",
            columnOptions,
            bulkDeleteAccessRight: () => true,
            itemActions: actions,
            queryCommands,
        };
    }, [query, showDetailsAction, columnOptions, actions, queryCommands]);
}
