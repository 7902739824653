import { Nullable } from "../../../utils/typeUtils";
import { VersionDetails } from "../versionTypes";
import { VersionDetailViewProps } from "./VersionDetailView";
import { StandardDetails } from "../../standardTypes";
import { useQuery } from "../../../hooks/useAxios";
import useDetailsCreate from "../../../hooks/details/useDetailsCreate";
import { versionValidationSchema } from "./versionValidationSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import useResolvedRoute from "../../../components/routing/useResolvedRoute";

const initialData: Nullable<VersionDetails> = {
    id: null,
    standard: null,
    versionNumber: null,
    standardAcronym: null,
    logo: null,
    systemId: null,
    processingText: null,
    cugsText: null,
    defaultValidityPeriod: null,
    hideUsdaNopCompliance: false,
    rawMaterialsText: null,
    recertificationBindingPeriod: null,
    recertificationPeriod: null,
    startDate: null,
    endDate: null,
};

function useVersionCreate(): VersionDetailViewProps {
    const { getResolvedId } = useResolvedRoute();
    const standardId = getResolvedId("standard");

    const { data: standard } = useQuery<StandardDetails>({
        url: "/certificate-service/standards/" + standardId + "?view=MINIMISED",
    });

    const { detailViewProps, form, title } = useDetailsCreate<VersionDetails>({
        title: "standardVersion.detail.newRecord_title",
        frontendRoute: "standardVersion",
        initialData,
        resolver: yupResolver(versionValidationSchema),
        saveUrl: "/certificate-service/standards/" + standardId + "/standard-versions",
    });

    return {
        labelGradesVisible: false,
        readOnly: false,
        detailViewProps,
        form,
        title,
        standard: `${standard?.acronym}`,
        createRoute: "standardVersion",
        logoUrl: "",
    };
}

export default useVersionCreate;
