import { joinClassNames } from "../../utils/StringUtils";
import ControlledDatePicker from "../../components/Input/datepicker/ControlledDatePicker";
import { UseGtbFormReturn } from "../../hooks/formHandling/useGtbForm";
import { revisionButtonVisibilityType, ScopeCertificateDetails } from "../scopeCertificateTypes";
import GtbTextField from "../../components/Input/GtbTextField";
import SupplyChainOperatorPickList from "../../components/Input/pickList/typedPicklist/SupplyChainOperatorPickList";
import GtbNumberField from "../../components/Input/GtbNumberField";
import CertificationBodyPickList from "../../components/Input/pickList/typedPicklist/CertificationBodyPickList";
import { LabeledCheckbox } from "../../components/Input/Checkbox";
import "./scopeCertificateBasicData.css";
import ScopeCertificateStatusPickList from "../../components/Input/pickList/typedPicklist/ScopeCertificateStatusPickList";
import UserPickList from "../../components/Input/pickList/typedPicklist/UserPickList";
import AccreditedStandardVersionsPickList from "../../components/Input/pickList/typedPicklist/AccreditedStandardVersionsPickList";
import GtbButton from "../../components/GtbButton";
import { useMemo } from "react";
import useIsAuthorised from "../../auth/useIsAuthorised";
import useGtbTranslation from "../../i18n/useGtbTranslation";

export interface ScopeCertificateBasicDataProps {
    form: UseGtbFormReturn<ScopeCertificateDetails>;
    scopeCertificateId?: string;
    readOnly: {
        supplyChainOperator: boolean;
        certificationBody: boolean;
        standardVersion: boolean;
        status: boolean;
        recertificationExpiryDate: boolean;
        cbScReferenceNumber: boolean;
        approvingPerson: boolean;
        recertificationExpected: boolean;
    };
    withActiveCertificationRelation?: boolean;
    isRecertificationExpiryVisible: boolean;
    handleRecertification?: () => void;
    revisionButtonVisibility: revisionButtonVisibilityType;
    revisionButtonOnClickHandler?: () => void;
}

export default function ScopeCertificateBasicData({
    form,
    scopeCertificateId,
    readOnly,
    withActiveCertificationRelation,
    isRecertificationExpiryVisible,
    handleRecertification,
    revisionButtonVisibility,
    revisionButtonOnClickHandler,
}: ScopeCertificateBasicDataProps) {
    const translation = useGtbTranslation();
    const { hasAccessRight } = useIsAuthorised();

    const isRevisionButtonVisible = useMemo(
        () =>
            revisionButtonOnClickHandler &&
            hasAccessRight("createScopeCertificate") &&
            (revisionButtonVisibility === "enabled" || revisionButtonVisibility === "disabled"),
        [hasAccessRight, revisionButtonOnClickHandler, revisionButtonVisibility]
    );

    return (
        <div
            className={joinClassNames(
                "scopeCertificateBasicData",
                "formGrid",
                handleRecertification ? "withRecertifyButton" : null,
                isRevisionButtonVisible ? "withRevisionButton" : null
            )}
        >
            <SupplyChainOperatorPickList
                label="scopeCertificate.detail.supplyChainOperator_input"
                control={form.form.control}
                name="supplyChainOperator"
                className="supplyChainOperator"
                flags={{ isClientOfCurrentUser: true }}
                readOnly={readOnly.supplyChainOperator}
            />
            <GtbTextField
                label="scopeCertificate.detail.scNumber_input"
                {...form.registerWithErrors("scNumber")}
                readOnly
                className="scNumber"
            />
            <GtbNumberField
                label="scopeCertificate.detail.revisionNumber_input"
                {...form.registerNumberWithErrors("revisionNumber")}
                readOnly
                className="revisionNumber"
            />
            <AccreditedStandardVersionsPickList
                label="scopeCertificate.detail.standardVersion_input"
                form={form.form}
                parentField="certificationBody"
                name="standardVersion"
                className="standardVersion"
                readOnly={readOnly.standardVersion}
            />
            <CertificationBodyPickList
                label="scopeCertificate.detail.certificationBody_input"
                control={form.form.control}
                name="certificationBody"
                className="certificationBody"
                flags={{ withActiveCertificationRelation }}
                readOnly={readOnly.certificationBody}
            />
            <GtbTextField
                label="scopeCertificate.detail.cbScReferenceNumber_input"
                {...form.registerWithErrors("cbScReferenceNumber")}
                className="cbScReferenceNumber"
                readOnly={readOnly.cbScReferenceNumber}
            />
            <ControlledDatePicker
                control={form.form.control}
                name="validFrom"
                label="scopeCertificate.detail.validFrom_input"
                className="validFrom"
                readOnly
            />
            <ControlledDatePicker
                control={form.form.control}
                name="validUntil"
                label="scopeCertificate.detail.validUntil_input"
                className="validUntil"
                readOnly
            />
            <ScopeCertificateStatusPickList
                control={form.form.control}
                name="status"
                readOnly={readOnly.status}
                label="scopeCertificate.detail.status_input"
                flags={{ onlyAvailable: true, scopeCertificateId }}
                className="status"
            />
            <ControlledDatePicker
                control={form.form.control}
                name="dateOfIssue"
                label="scopeCertificate.detail.dateOfIssue_input"
                className="dateOfIssue"
                readOnly
            />
            <GtbTextField
                label="scopeCertificate.detail.placeOfIssue_input"
                {...form.registerWithErrors("placeOfIssue")}
                className="placeOfIssue"
                readOnly
            />
            <UserPickList
                control={form.form.control}
                name="approvingPerson"
                label="scopeCertificate.detail.approvingPerson_input"
                className="approvingPerson"
                flags={{ canApproveSCs: true }}
                readOnly={readOnly.approvingPerson}
            />
            <LabeledCheckbox
                id="recertificationExpected"
                label="scopeCertificate.detail.recertificationExpected_input"
                {...form.form.register("recertificationExpected")}
                className="recertificationExpected"
                readOnly={readOnly.recertificationExpected}
            />
            {handleRecertification && (
                <GtbButton className="recertify" onClick={handleRecertification}>
                    {translation("scopeCertificate.detail.recertify_button")}
                </GtbButton>
            )}
            {isRevisionButtonVisible && (
                <GtbButton
                    disabled={revisionButtonVisibility === "disabled"}
                    aria-label={
                        revisionButtonVisibility === "disabled"
                            ? translation("scopeCertificate.detail.revision_button_tooltip")
                            : ""
                    }
                    title={
                        revisionButtonVisibility === "disabled"
                            ? translation("scopeCertificate.detail.revision_button_tooltip")
                            : ""
                    }
                    onClick={revisionButtonOnClickHandler}
                    className={"revisionButton"}
                >
                    {translation("scopeCertificate.detail.revision_button")}
                </GtbButton>
            )}
            {isRecertificationExpiryVisible && (
                <ControlledDatePicker
                    control={form.form.control}
                    name="recertificationExpiryDate"
                    label="scopeCertificate.detail.recertificationExpiryDate_input"
                    className="recertificationExpiryDate"
                    readOnly={readOnly.recertificationExpiryDate}
                />
            )}
        </div>
    );
}
