import AuthorisationRoleDetailView from "./AuthorisationRoleDetailView";
import useAuthorisationRoleEdit from "./useAuthorisationRoleEdit";

function AuthorisationRoleEditView() {
    const authorisationRoleDetailProps = useAuthorisationRoleEdit();

    return <AuthorisationRoleDetailView {...authorisationRoleDetailProps} />;
}

export default AuthorisationRoleEditView;
