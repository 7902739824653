import { SortOption } from "../components/grid/component/useQueryBuilder";
import { GridColumn } from "../components/grid/component/Grid";
import { buildDataColumn, buildDateColumn, buildTextColumn } from "../components/grid/ColumnProvider";
import useIsAuthorised from "../auth/useIsAuthorised";
import useResolvedRoute, { getCreateUrl } from "../components/routing/useResolvedRoute";
import useListView from "../hooks/useListView";
import { useDeleteAction, useShowDetailsAction } from "../utils/defaultActions";
import { useMemo } from "react";
import { EntityListViewGridProps } from "../components/entity/EntityListView";
import {
    PreGtbTransactionCertificateDetails,
    PreGtbTransactionCertificateSummary,
} from "./preGtbTransactionCertificateTypes";
import { EnumEntityType } from "../components/Input/pickList/typedEnumPicklist/enumEntityType";
import { PreGtbTcSearchContext } from "./usePreGtbTcSearchContext";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";

export const defaultPreGtbTransactionCertificateSortOption: SortOption<
    PreGtbTransactionCertificateSummary | PreGtbTransactionCertificateDetails
> = {
    field: "tcNumber",
    direction: "ASC",
};

const columns: GridColumn<PreGtbTransactionCertificateSummary>[] = [
    buildTextColumn({ key: "tcNumber", title: "preGtbTransactionCertificate.list.tcNumber_column", minWidth: 141 }),
    buildTextColumn({
        key: "standardAcronym",
        title: "preGtbTransactionCertificate.list.standard_column",
        minWidth: 126,
    }),
    buildTextColumn({
        key: "sellersLicenceNumber",
        title: "preGtbTransactionCertificate.list.sellersLicenceNumber_column",
        minWidth: 123,
    }),
    buildTextColumn({
        key: "buyerBusinessName",
        title: "preGtbTransactionCertificate.list.buyer_column",
        minWidth: 123,
    }),
    buildDateColumn({
        key: "dateOfIssue",
        title: "preGtbTransactionCertificate.list.dateOfIssue_column",
        minWidth: 156,
    }),
    buildDataColumn<PreGtbTransactionCertificateSummary, EnumEntityType>({
        key: "status",
        title: "preGtbTransactionCertificate.list.status_column",
        url: "/certificate-service/references/pre-gtb-tc-statuses?sortOrdinal=true",
        itemId: "id",
        itemLabel: "name",
        minWidth: 232,
        visible: false,
    }),
];

export default function usePreGtbTransactionCertificateListView(searchContext: PreGtbTcSearchContext) {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedEditUrl } = useResolvedRoute();

    const {
        queryBuilder: { forceReload, columnOptions, query, queryCommands },
    } = useListView<PreGtbTransactionCertificateSummary>({
        navigationContextLabel: searchContext.navigationContextLabel,
        baseQuery: "/certificate-service/pre-gtb-tcs",
        defaultSortOption: defaultPreGtbTransactionCertificateSortOption,
        additionalQueryParameter: { ptcsc: searchContext.id },
    });

    const showDetailsAction = useShowDetailsAction(
        (item: PreGtbTransactionCertificateSummary) => getResolvedEditUrl("preGtbTransactionCertificate", item.id),
        query.parameter
    );

    const deleteAction = useDeleteAction(
        (item: PreGtbTransactionCertificateSummary) => `/certificate-service/pre-gtb-tcs/${item.id}`,
        () => forceReload(),
        "deletePreGtbTc"
    );

    const actions = useGridRowContextMenuActions(showDetailsAction, deleteAction);

    return useMemo<EntityListViewGridProps<PreGtbTransactionCertificateSummary>>(() => {
        return {
            query,
            columns: columns,
            uniqueDataKey: "id",
            onItemDoubleClick: showDetailsAction,
            createUrl: hasAccessRight("createPreGtbTc") ? getCreateUrl("preGtbTransactionCertificate") : undefined,
            tableName: "preGtbTransactionCertificate",
            columnOptions,
            itemActions: actions,
            queryCommands,
            bulkDeleteAccessRight: () => hasAccessRight("deletePreGtbTc"),
        };
    }, [query, showDetailsAction, hasAccessRight, columnOptions, actions, queryCommands]);
}
