import { Nullable } from "../../utils/typeUtils";
import useDetailsCreate from "../../hooks/details/useDetailsCreate";
import useResolvedRoute from "../../components/routing/useResolvedRoute";
import { yupResolver } from "@hookform/resolvers/yup";
import { subcontractingRelationValidationSchema } from "./subcontractingRelationValidationSchema";
import { useMemo } from "react";
import {
    SubcontractingRelationDetailViewProps,
    SubcontractingRelationReadOnlyProps,
} from "./SubcontractingRelationDetailView";
import { SubcontractingRelationDetails } from "../subcontractingRelationTypes";
import useAuth from "../../auth/useAuth";
import UseConfirmationDialog from "../../components/dialog/UseConfirmationDialog";
import { pageUrl } from "../../utils/windowFunctions";

export default function useSubcontractingRelationCreate(): SubcontractingRelationDetailViewProps {
    const showRequestBusinessRelationDialog = UseConfirmationDialog();
    const {
        currentUser: { authorisationRole, organisation },
    } = useAuth();
    const { getResolvedId } = useResolvedRoute();

    const principal = useMemo(
        () =>
            getResolvedId("supplyChainOperator") ??
            (authorisationRole.type === "SUPPLY_CHAIN_OPERATOR" ? organisation : null),
        [authorisationRole.type, getResolvedId, organisation]
    );

    const initialData = useMemo<Nullable<SubcontractingRelationDetails>>(() => {
        return {
            id: null,
            principalSupplyChainOperator: principal,
            subcontractorSupplyChainOperator: null,
            standard: null,
            accessLevel: "UNCONFIRMED",
            confirmationMethod: "EMAIL_TO_SUBCONTRACTOR",
            startDate: new Date(),
            endDate: null,
            principalSupplyChainOperatorBusinessName: null,
            subcontractorSupplyChainOperatorBusinessName: null,
            url: pageUrl() + "/subcontractingRelation/%s/confirmation/%s",
        };
    }, [principal]);

    const { detailViewProps, form, title } = useDetailsCreate<SubcontractingRelationDetails>({
        title: "subcontractingRelation.detail.newRecord_title",
        frontendRoute: "subcontractingRelation",
        initialData,
        resolver: yupResolver(subcontractingRelationValidationSchema),
        saveUrl: "/certificate-service/subcontracting-relations",
        afterCreation: (savedItem) => {
            if (savedItem.accessLevel === "UNCONFIRMED" && savedItem.confirmationMethod === "EMAIL_TO_SUBCONTRACTOR") {
                showRequestBusinessRelationDialog({
                    title: "subcontractingRelation.detail.businessRelationConfirmationRequested_dialogTitle",
                    message: {
                        key: "subcontractingRelation.detail.businessRelationConfirmationRequested_dialogText",
                        options: {
                            subcontractor: savedItem.subcontractorSupplyChainOperatorBusinessName,
                            principal: savedItem.principalSupplyChainOperatorBusinessName,
                        },
                    },
                });
            }
        },
    });

    const readOnlyProps: SubcontractingRelationReadOnlyProps = {
        isAccessLevelReadOnly: !(authorisationRole.level === 2 && authorisationRole.type === "STANDARD_BODY"),
        isConfirmationMethodReadOnly: authorisationRole.level > 3 || authorisationRole.type === "SUPPLY_CHAIN_OPERATOR",
        isEndDateReadOnly: authorisationRole.level > 3 || authorisationRole.type === "SUPPLY_CHAIN_OPERATOR",
        isPrincipalReadOnly: !!principal,
        isStandardReadOnly: false,
        isStartDateReadOnly: false,
        isSubcontractorReadOnly: false,
    };

    return {
        title,
        detailViewProps,
        form,
        createRoute: "subcontractingRelation",
        readOnlyProps,
    };
}
