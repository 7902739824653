import React from "react";
import "./helperText.css";

export interface HelperTextProps {
    helperText?: string;
}

export default function HelperText({ helperText }: HelperTextProps) {
    return helperText ? <p className="helperText">{helperText}</p> : null;
}
