import useByproductInputCombinationEdit from "./useByproductInputCombinationEdit";
import ByproductInputCombinationDetailView from "./ByproductInputCombinationDetailView";

function ByproductInputCombinationEditView() {
    const byproductInputCombinationDetailProps = useByproductInputCombinationEdit();

    return <ByproductInputCombinationDetailView {...byproductInputCombinationDetailProps} />;
}

export default ByproductInputCombinationEditView;
