import { UserDetails } from "../userTypes";
import { UserDetailViewProps } from "./UserDetailView";
import { yupResolver } from "@hookform/resolvers/yup";
import { userValidationSchema } from "./userValidationSchema";
import useIsAuthorised from "../../auth/useIsAuthorised";
import useAuth from "../../auth/useAuth";
import { defaultUserSortOption } from "../useUserListView";
import { useCallback, useMemo } from "react";
import useResolvedRoute, { routeEnum } from "../../components/routing/useResolvedRoute";
import useUserTotp from "./useUserTotp";
import useGtbTranslation from "../../i18n/useGtbTranslation";
import useDomainEntityDetailsEdit from "../../hooks/details/useDomainEntityDetailsEdit";

export default function useUserEdit(): UserDetailViewProps {
    const { hasAccessRight } = useIsAuthorised();
    const { currentUser, reloadCurrentUser } = useAuth();
    const { getResolvedId } = useResolvedRoute();
    const userId = getResolvedId("user");
    const translation = useGtbTranslation();

    const isEditingOwnDetails = useMemo(() => currentUser.id === userId, [currentUser.id, userId]);

    const ownRoleLevelIsGreaterThanRoleLevelOf = useCallback(
        (userToEdit: UserDetails) => {
            // lower role level is semantically greater
            return currentUser.authorisationRole.level < userToEdit.authorisationRoleLevel;
        },
        [currentUser.authorisationRole.level]
    );

    const { detailViewProps, form, initialData, isReadOnly, title, recordNavigationProps } =
        useDomainEntityDetailsEdit<UserDetails>({
            baseUrl: "/certificate-service/users/" + userId,
            resolver: yupResolver(userValidationSchema),
            frontendRoute: "user",
            recordNavigationDefaultSort: defaultUserSortOption,
            titleBuilder: (item) => `${item.givenName} ${item.surname}`,
            canSave: (item) =>
                hasAccessRight("updateUserAccount") &&
                (ownRoleLevelIsGreaterThanRoleLevelOf(item) || isEditingOwnDetails),
            afterSaveAction: () => (isEditingOwnDetails ? reloadCurrentUser() : undefined),
            historyColumns: [
                { identifier: "systemId", title: "user.history.systemId_column" },
                { identifier: "givenName", title: "user.history.givenName_column" },
                { identifier: "surname", title: "user.history.surname_column" },
                { identifier: "emailAddress", title: "user.history.emailAddress_column" },
                { identifier: "username", title: "user.history.username_column" },
                { identifier: "authorisationRole", title: "user.history.authorisationRole_column" },
                { identifier: "organisation", title: "user.history.organisation_column" },
                { identifier: "blocked", title: "user.history.blocked_column" },
                { identifier: "startDate", title: "user.history.startDate_column" },
                { identifier: "endDate", title: "user.history.endDate_column" },
                { identifier: "totpReady", title: "user.history.totp_ready_column" },
                { identifier: "testDataStamp", title: "user.history.testDataStamp_column" },
                { identifier: "acceptedToU", title: "user.history.acceptedToU_column" },
                { identifier: "acceptedGoogleToU", title: "user.history.acceptedGoogleToU_column" },
            ],
        });

    const { activateTotp, resetTotp, totpReady } = useUserTotp(initialData?.totpReady);

    const _activateTotp = useCallback(() => {
        activateTotp(initialData!);
    }, [activateTotp, initialData]);

    const _resetTotp = useCallback(() => {
        resetTotp(initialData!);
    }, [initialData, resetTotp]);

    const createRoute = useMemo<routeEnum | undefined>(() => {
        return hasAccessRight("createUserAccount") ? "user" : undefined;
    }, [hasAccessRight]);

    return {
        detailViewProps,
        form,
        title,
        subtitle: translation({ key: "user.detail.subtitle", options: { systemId: initialData?.systemId } }),
        recordNavigationProps,
        readOnlyProps: {
            isBlockedReadOnly: isEditingOwnDetails || isReadOnly || initialData?.authorisationRoleLevel === 1,
            isStandardBodyReadOnly: isReadOnly,
            isEmailAddressReadOnly: isEditingOwnDetails || isReadOnly,
            isEndDateReadOnly: isEditingOwnDetails || isReadOnly,
            isGivenNameReadOnly: isEditingOwnDetails || isReadOnly,
            isOrganisationReadOnly: isReadOnly,
            isRoleReadOnly: isReadOnly || currentUser.authorisationRole.level > 3,
            isStartDateReadOnly: isReadOnly,
            isSurnameReadOnly: isEditingOwnDetails || isReadOnly,
            isTestDataStampReadOnly: isReadOnly || currentUser.authorisationRole.level > 1,
            isUsernameReadOnly: isReadOnly,
        },
        visibilityProps: {
            isTestDataStampVisible: currentUser.authorisationRole.level === 1,
            isChangePasswordButtonVisible: isEditingOwnDetails,
            isAcceptedToUVisible: currentUser.authorisationRole.level <= 2,
        },
        createRoute,
        activateTotp: !isReadOnly && currentUser.id === initialData?.id && !totpReady ? _activateTotp : undefined,
        resetTotp: !isReadOnly && totpReady ? _resetTotp : undefined,
        totpReady,
    };
}
