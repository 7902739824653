import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import remarkGfm from "remark-gfm";
import { schema } from "./HTMLSanitization";
import "./markdown.css";

function MarkdownContainer({ content }: MarkdownContainerProps) {
    return (
        <div className="default-style markdown-container">
            <ReactMarkdown
                rehypePlugins={[rehypeRaw, [rehypeSanitize, schema]]}
                remarkPlugins={[remarkGfm]}
                children={content}
            ></ReactMarkdown>
        </div>
    );
}

export interface MarkdownContainerProps {
    content: string;
}

export default MarkdownContainer;
