import { useHelpContext } from "../help/context-sensitivity/useHelpContext";
import useMailTemplateListView from "./useMailTemplateListView";
import EntityListView from "../components/entity/EntityListView";

export default function MailTemplateListView() {
    useHelpContext("mail-template-list");
    const mailTemplateGridOptions = useMailTemplateListView();

    return <EntityListView heading="mailTemplate.list.header" gridOptions={mailTemplateGridOptions} />;
}
