import useExternalIdListView from "../useExternalIdListView";

export default function useExternalSupplyChainOperatorIdListView() {
    return useExternalIdListView({
        tableName: "externalSupplyChainOperatorIds",
        i18nKey: "externalSupplyChainOperatorId",
        routeEnumKey: "externalSupplyChainOperatorId",
        parentEntityType: "SUPPLY_CHAIN_OPERATOR",
    });
}
