import GtbTextField from "../../components/Input/GtbTextField";
import { ExternalIdDetails } from "../externalIdTypes";
import { I18nKey } from "../../i18n/useGtbTranslation";
import CertificationBodyPickList from "../../components/Input/pickList/typedPicklist/CertificationBodyPickList";
import { UseGtbFormReturn } from "../../hooks/formHandling/useGtbForm";
import "./externalIdDetailView.css";
import EnumEntityRadiobuttonGroup from "../../components/Input/radiobutton/EnumEntityRadiobuttonGroup";

export default function ExternalIdDetailView({ form, readOnlyProps, i18nKey }: ExternalIdDetailViewProps) {
    return (
        <>
            <EnumEntityRadiobuttonGroup
                label={`${i18nKey}.detail.type_input` as I18nKey}
                {...form.form.register("type")}
                name="type"
                readOnly={readOnlyProps.isTypeReadOnly}
                className="type"
                referenceUrl="/certificate-service/references/type-of-external-ids"
            />
            <CertificationBodyPickList
                control={form.form.control}
                label={`${i18nKey}.detail.certificationBody_input` as I18nKey}
                className="certificationBody"
                readOnly={readOnlyProps.isCertificationBodyReadOnly}
                name="certificationBody"
            />
            <GtbTextField
                {...form.registerWithErrors("externalId")}
                label={`${i18nKey}.detail.externalId_input` as I18nKey}
                className="externalId"
                readOnly={readOnlyProps.isExternalIdReadOnly}
            />
        </>
    );
}

export interface ExternalIdDetailViewProps {
    form: UseGtbFormReturn<ExternalIdDetails>;
    readOnlyProps: ExternalIdReadOnlyProps;
    i18nKey: I18nKey;
}

export interface ExternalIdReadOnlyProps {
    isParentEntityReadOnly: boolean;
    isTypeReadOnly: boolean;
    isCertificationBodyReadOnly: boolean;
    isExternalIdReadOnly: boolean;
}
