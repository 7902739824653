import { UserDetails, UserSummary } from "./userTypes";
import {
    buildBooleanColumn,
    buildDateColumn,
    buildDateTimeColumn,
    buildTextColumn,
} from "../components/grid/ColumnProvider";
import { useMemo } from "react";
import useIsAuthorised from "../auth/useIsAuthorised";
import useListView from "../hooks/useListView";
import { SortOption } from "../components/grid/component/useQueryBuilder";
import { useDeleteAction, useShowDetailsAction } from "../utils/defaultActions";
import { EntityListViewGridProps } from "../components/entity/EntityListView";
import { GridColumn } from "../components/grid/component/Grid";
import { UserSearchContext } from "./useUserSearchContext";
import useResolvedRoute, { getCreateUrl } from "../components/routing/useResolvedRoute";
import useAuth from "../auth/useAuth";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";

export const defaultUserSortOption: SortOption<UserSummary | UserDetails> = {
    field: "systemId",
    direction: "ASC",
};

export default function useUserListView(userSearchContext: UserSearchContext) {
    const { hasAccessRight } = useIsAuthorised();
    const {
        currentUser: { authorisationRole },
    } = useAuth();
    const { getResolvedEditUrl } = useResolvedRoute();

    const {
        queryBuilder: { query, forceReload, columnOptions, queryCommands },
    } = useListView<UserSummary>({
        navigationContextLabel: userSearchContext.navigationContextLabel,
        baseQuery: "/certificate-service/users",
        defaultSortOption: {
            field: "systemId",
            direction: "ASC",
        },
        additionalQueryParameter: { usc: userSearchContext.id },
    });

    const showDetailsAction = useShowDetailsAction(
        (item: UserSummary) => getResolvedEditUrl("user", item.id),
        query.parameter
    );

    const deleteAction = useDeleteAction(
        (item: UserSummary) => `/certificate-service/users/${item.id}`,
        () => forceReload(),
        "deleteUserAccount"
    );

    const actions = useGridRowContextMenuActions(showDetailsAction, deleteAction);

    const columns = useMemo<GridColumn<UserSummary>[]>(
        () => [
            buildTextColumn({ key: "systemId", title: "user.list.systemId_column", minWidth: 134 }),
            buildTextColumn({ key: "surname", title: "user.list.surname_column", minWidth: 125 }),
            buildTextColumn({ key: "givenName", title: "user.list.givenName_column", minWidth: 147 }),
            buildTextColumn({ key: "username", title: "user.list.username_column", minWidth: 134 }),
            buildBooleanColumn({ key: "blocked", title: "user.list.blocked_column", minWidth: 119 }),
            ...(authorisationRole.level === 1
                ? [
                      buildTextColumn<UserSummary>({
                          key: "testDataStamp",
                          title: "user.list.testDataStamp_column",
                          minWidth: 177,
                      }),
                  ]
                : []),
            buildTextColumn({
                key: "authorisationRoleName",
                title: "user.list.authorisationRole_column",
                visible: false,
                minWidth: 194,
            }),
            buildTextColumn({
                key: "emailAddress",
                title: "user.list.emailAddress_column",
                minWidth: 165,
                visible: false,
            }),
            buildDateColumn({ key: "endDate", title: "user.list.endDate_column", minWidth: 124, visible: false }),
            buildDateTimeColumn({
                key: "lastFailedLogin",
                title: "user.list.lastFailedLogin_column",
                minWidth: 178,
                visible: false,
                precision: "m",
            }),
            buildDateTimeColumn({
                key: "lastSuccessfulLogin",
                title: "user.list.lastSuccessfulLogin_column",
                minWidth: 219,
                visible: false,
                precision: "m",
            }),
            buildTextColumn({
                key: "organisationBusinessName",
                title: "user.list.organisation_column",
                visible: false,
                minWidth: 155,
            }),
            buildTextColumn({
                key: "standardBodyBusinessName",
                title: "user.list.standardBody_column",
                visible: false,
                minWidth: 155,
            }),
            buildDateColumn({ key: "startDate", title: "user.list.startDate_column", minWidth: 130, visible: false }),
            buildBooleanColumn({
                key: "totpReadyStatus",
                title: "user.list.totpReady_column",
                minWidth: 119,
                visible: false,
            }),
            buildBooleanColumn({
                key: "acceptedToU",
                title: "user.list.acceptedToU_column",
                minWidth: 177,
                visible: false,
            }),
            buildBooleanColumn({
                key: "acceptedGoogleToU",
                title: "user.list.acceptedGoogleToU_column",
                minWidth: 268,
                visible: false,
            }),
        ],
        [authorisationRole.level]
    );

    return useMemo<EntityListViewGridProps<UserSummary>>(() => {
        return {
            query,
            columns,
            uniqueDataKey: "id" as keyof UserSummary,
            onItemDoubleClick: hasAccessRight("readUserAccount") ? showDetailsAction : undefined,
            createUrl: hasAccessRight("createUserAccount") ? getCreateUrl("user") : undefined,
            tableName: "user",
            columnOptions,
            itemActions: actions,
            queryCommands,
            bulkDeleteAccessRight: "deleteUserAccount",
        };
    }, [query, columns, hasAccessRight, showDetailsAction, columnOptions, actions, queryCommands]);
}
