import useUserEdit from "./useUserEdit";
import UserDetailView from "./UserDetailView";
import { useHelpContext } from "../../help/context-sensitivity/useHelpContext";

function UserEditView() {
    const userDetailViewProps = useUserEdit();

    useHelpContext("user-details");

    return <UserDetailView {...userDetailViewProps} />;
}

export default UserEditView;
