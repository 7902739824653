import useOrganicMaterialOriginSubList from "./organicMaterialOrigin/useOrganicMaterialOriginSubList";
import InfinityScrollSubList from "../../components/grid/InfinityScrollSubList";

export default function OrganicMaterialOrigins({
    isFrozen,
    initialAvailableQuantityIsSet,
    afterDeletion,
}: {
    isFrozen: boolean;
    initialAvailableQuantityIsSet: boolean;
    afterDeletion: Function;
}) {
    const organicMaterialOriginsSubList = useOrganicMaterialOriginSubList(
        isFrozen,
        initialAvailableQuantityIsSet,
        afterDeletion
    );
    return <InfinityScrollSubList {...organicMaterialOriginsSubList} className="organicMaterialOrigin" />;
}
