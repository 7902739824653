import { buildSchema, validateNumber, validateString } from "../../../../utils/yupExtension";
import { PreGtbProductDetails } from "../preGtbProductTypes";
import * as yup from "yup";

export const buildPreGtbProductValidationSchema = (labelGradeRequired: boolean) =>
    buildSchema<PreGtbProductDetails>({
        productNumber: validateString().hasMaxLength(40).isRequired(),
        productCategory: validateString().isRequired(),
        productCategoryPrint: validateString().hasMaxLength(50).isRequired(),
        productDetail: validateString().isRequired(),
        productDetailPrint: validateString().hasMaxLength(50).isRequired(),
        labelGrade: labelGradeRequired ? validateString().isRequired() : validateString(),
        netShippingWeight: validateNumber().isRequired().hasMinValue(0.1),
        supplementaryWeight: validateNumber()
            .isRequired()
            .test(
                "max-supplementary-weight",
                "preGtbProduct.detail.supplementaryWeight_too_large",
                (supplementaryWeight, context) => {
                    const netShippingWeight = context.parent.netShippingWeight;
                    return validateSupplementaryWeight(netShippingWeight ?? 0, supplementaryWeight ?? 0);
                }
            ),
        certifiedWeight: validateNumber(),
        availableVolume: validateNumber(),
        initialAvailableQuantity: validateNumber()
            .isRequired()
            .isLessEqual(
                yup.ref("netShippingWeight"),
                "preGtbProduct.detail.initialAvailableQuantity_input",
                "preGtbProduct.detail.netShippingWeight_input"
            ),
    });

function validateSupplementaryWeight(netShippingWeight: number, supplementaryWeight: number) {
    return supplementaryWeight <= netShippingWeight * 0.15;
}
