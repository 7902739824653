import { ComponentType } from "react";
import { GridProps } from "../DataGrid";
import DropdownComponent from "../../DropdownComponent";
import { Entity, RequireInheritedProps } from "../../../utils/typeUtils";
import I18nDropdownItem from "../../dropdown/I18nDropdownItem";

export interface WithFilterCommandsProps<ItemType extends Entity>
    extends RequireInheritedProps<GridProps<ItemType>, "queryCommands"> {}

export default function withFilterCommands<ItemType extends Entity>(
    Grid: ComponentType<GridProps<ItemType> & WithFilterCommandsProps<ItemType>>
) {
    return ({ gridControlsLeft, queryCommands, ...rest }: GridProps<ItemType> & WithFilterCommandsProps<ItemType>) => (
        <Grid
            {...rest}
            queryCommands={queryCommands}
            gridControlsLeft={[
                ...(gridControlsLeft ?? []),
                <DropdownComponent
                    key="filterCommandSelection"
                    label={"components.grid.filters_menuTitle"}
                    className="medium-button"
                >
                    <I18nDropdownItem
                        onClick={queryCommands.toggleFiltering}
                        label={
                            queryCommands.filterActive
                                ? "components.grid.hideFilters_filtersMenuEntry"
                                : "components.grid.showFilters_filtersMenuEntry"
                        }
                    />
                    <I18nDropdownItem
                        onClick={queryCommands.clearFilter}
                        label={"components.grid.clearFilters_filtersMenuEntry"}
                    />
                    <I18nDropdownItem
                        onClick={queryCommands.refresh}
                        label={"components.grid.refreshSelection_filtersMenuEntry"}
                    />
                </DropdownComponent>,
            ]}
        />
    );
}
