import { calculateRestrictedLanguages, language, LanguageKey } from "../../../../i18n/languageTypes";
import { useCallback, useMemo, useState } from "react";
import { UncontrolledEnumPickList } from "../UncontrolledEnumPickList";
import { I18nKey } from "../../../../i18n/useGtbTranslation";
import useLanguage, { useChangeLanguage } from "../../../../i18n/LanguageContextProvider";

export default function LanguageEnumPickList(props: LanguageEnumPickListProps) {
    const currentLanguage = useLanguage();
    const changeLanguage = useChangeLanguage();
    const [oldValue, setOldValue] = useState(currentLanguage);

    const onlyAllowedLanguages = useMemo(() => {
        const copy = { ...language };
        for (const restrictedLang of calculateRestrictedLanguages()) {
            delete copy[restrictedLang as keyof typeof language];
        }

        return copy;
    }, []);

    const handleChangeLanguage = useCallback((newValue: string | string[] | null) => {
        if (newValue instanceof Array<string> || newValue === null) {
            newValue = oldValue;
        }

        changeLanguage(newValue as LanguageKey);
        setOldValue(newValue as LanguageKey);
    }, [changeLanguage, oldValue]);

    return (
        <UncontrolledEnumPickList
            onChange={(_event, newValue) => handleChangeLanguage(newValue)}
            value={currentLanguage}
            enumRecords={onlyAllowedLanguages}
            disableClearable
            {...props}
        />
    );
}

export interface LanguageEnumPickListProps {
    label: I18nKey;
    className?: string;
    borderless?: boolean;
    "aria-labelledby"?: string;
}
