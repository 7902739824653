import useProductDetailCreate from "./useProductDetailCreate";
import ProductDetailDetailView from "./ProductDetailDetailView";

function ProductDetailCreateView() {
    const productDetailDetailProps = useProductDetailCreate();

    return <ProductDetailDetailView {...productDetailDetailProps} />;
}

export default ProductDetailCreateView;
