import Form from "../components/Form";
import GtbTextField from "../components/Input/GtbTextField";
import GtbButton from "../components/GtbButton";

import useForgotPassword from "./useForgotPassword";
import "./forgotPassword.css";
import useGtbTranslation from "../i18n/useGtbTranslation";

function ForgotPasswordForm() {
    const translation = useGtbTranslation();
    const { register, isForgotPasswordLoading, onForgotPassword } = useForgotPassword();

    return (
        <Form onSubmit={onForgotPassword} className="forgotPassword">
            <GtbTextField
                className="forgotPassword-form-username"
                label="loginPage.forgot_password_username_input"
                autoFocus
                aria-describedby="usernameDescription"
                {...register("username", { required: true })}
            />
            <div className="forgotPassword-form-info">
                <span id="usernameDescription">{translation("loginPage.forgot_password_username_description")}</span>
            </div>
            <GtbButton className="forgotPassword-form-submit" type="submit" disabled={isForgotPasswordLoading}>
                {translation("loginPage.forgot_password_submit_button")}
            </GtbButton>
        </Form>
    );
}

export default ForgotPasswordForm;
