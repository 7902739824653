import "./typedPickList.css";
import { DependentPicklist, DependentPicklistProps } from "./DependentPicklist";
import { joinClassNames } from "../../../../utils/StringUtils";
import { FieldValues } from "react-hook-form/dist/types";
import { OrganicMaterialProductionReference } from "../../../../organicMaterialProductions/organicMaterialProductionTypes";
import { PickListItemPresentationProps } from "../GtbPickList";

interface OrganicMaterialProductionPicklistProps<FormItemType extends FieldValues>
    extends DependentPicklistProps<FormItemType, OrganicMaterialProductionReference> {
    flags?: OrganicMaterialProductionPickListFlags;
    organicMaterialPurchaseId?: string;
}

export interface OrganicMaterialProductionPickListFlags {
    organicMaterialProducerId: string;
    organicMaterialPurchaseId: string;
}

export const organicMaterialProductionCombinedClassName = (className?: string) =>
    joinClassNames("organicMaterialProductionPickList", className);
export const organicMaterialProductionPickListItemPresentation: PickListItemPresentationProps<OrganicMaterialProductionReference> =
    {
        itemId: "id",
        itemLabel: (option) => `${option.harvestYear}, ${option.town}, ${option.sizeOfProductionArea ?? 0} ha`,
    };

export default function DependentAvailableOrganicMaterialProductionPickList<FormItemType extends FieldValues>({
    className,
    organicMaterialPurchaseId,
    ...rest
}: OrganicMaterialProductionPicklistProps<FormItemType>) {
    return (
        <DependentPicklist
            {...organicMaterialProductionPickListItemPresentation}
            urlBuilder={(organicMaterialProducerId) => ({
                url: "/certificate-service/references/organic-material-productions/available-productions",
                flags: { organicMaterialProducerId, organicMaterialPurchaseId },
            })}
            additionalDataUrl="/certificate-service/references/organic-material-productions"
            className={organicMaterialProductionCombinedClassName(className)}
            {...rest}
        />
    );
}
