import useIsAuthorised from "../../../auth/useIsAuthorised";
import useResolvedRoute from "../../../components/routing/useResolvedRoute";
import { SortOption, useQueryBuilder } from "../../../components/grid/component/useQueryBuilder";
import { useDeleteAction, useShowDetailsAction } from "../../../utils/defaultActions";
import { useMemo } from "react";
import { SubListProps } from "../../../components/grid/SubList";
import { buildBooleanColumn, buildNumberColumn, buildTextColumn } from "../../../components/grid/ColumnProvider";
import { GridColumn } from "../../../components/grid/component/Grid";
import { PreGtbTcMaterialDetails, PreGtbTcMaterialSummary } from "./preGtbTcMaterialsTypes";
import {
    PreGtbTransactionCertificateDetails,
    preGtbTransactionCertificateReadOnlyByStatus,
} from "../../preGtbTransactionCertificateTypes";
import useGridRowContextMenuActions from "../../../components/grid/component/cell/useGridRowContextMenuActions";

export const defaultPreGtbTcMaterialSortOption: SortOption<PreGtbTcMaterialSummary | PreGtbTcMaterialDetails> = {
    field: "rawMaterialPrint",
    direction: "ASC",
};

const columns: GridColumn<PreGtbTcMaterialSummary>[] = [
    buildTextColumn({
        key: "rawMaterialName",
        title: "preGtbTcMaterial.list.rawMaterial_column",
        minWidth: 200,
    }),
    buildNumberColumn({
        key: "percentage",
        title: "preGtbTcMaterial.list.percentage_column",
        minWidth: 143,
    }),
    buildNumberColumn({
        key: "certifiedWeight",
        title: "preGtbTcMaterial.list.certifiedWeight_column",
        visible: false,
        fixedDecimal: true,
        minWidth: 194,
    }),
    buildBooleanColumn({
        key: "certified",
        title: "preGtbTcMaterial.list.certified_column",
        minWidth: 100,
    }),
    buildTextColumn({
        key: "joinedOriginNames",
        title: "preGtbTcMaterial.list.origin_column",
        disableSorting: true,
        minWidth: 800,
    }),
];

export default function usePreGtbTcMaterialSubList(preGtbTcStatus?: PreGtbTransactionCertificateDetails["status"]) {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedEditUrl, getResolvedCreateUrl, getResolvedId } = useResolvedRoute();
    const preGtbTCId = getResolvedId("preGtbTransactionCertificate");
    const preGtbTcProductId = getResolvedId("preGtbProduct");

    const { query, columnOptions, forceReload } = useQueryBuilder<PreGtbTcMaterialSummary>(
        `/certificate-service/pre-gtb-tcs/${preGtbTCId}/pre-gtb-products/${preGtbTcProductId}/pre-gtb-tc-materials`,
        {
            sort: defaultPreGtbTcMaterialSortOption,
            filterActive: false,
        }
    );

    const showDetailsAction = useShowDetailsAction(
        (item: PreGtbTcMaterialSummary) => getResolvedEditUrl("preGtbTcMaterial", item.id),
        query.parameter
    );

    const deleteAction = useDeleteAction(
        (item: PreGtbTcMaterialSummary) =>
            `/certificate-service/pre-gtb-tcs/${preGtbTCId}/pre-gtb-products/${preGtbTcProductId}/pre-gtb-tc-materials/${item.id}`,
        () => forceReload(),
        "updatePreGtbTc",
        preGtbTcStatus ? [preGtbTcStatus] : []
    );

    const contextMenuActions = useGridRowContextMenuActions(
        showDetailsAction,
        preGtbTransactionCertificateReadOnlyByStatus(preGtbTcStatus) ? undefined : deleteAction
    );

    return useMemo<SubListProps<PreGtbTcMaterialSummary>>(
        () => ({
            query,
            columns,
            uniqueDataKey: "id",
            onItemDoubleClick: showDetailsAction,
            header: "preGtbTcMaterial.list.header",
            itemActions: contextMenuActions,
            columnOptions,
            createUrl:
                preGtbTcStatus &&
                !preGtbTransactionCertificateReadOnlyByStatus(preGtbTcStatus) &&
                hasAccessRight("updatePreGtbTc", [preGtbTcStatus])
                    ? getResolvedCreateUrl("preGtbTcMaterial")
                    : undefined,
            tableName: "preGtbTcMaterials",
        }),
        [
            columnOptions,
            contextMenuActions,
            getResolvedCreateUrl,
            hasAccessRight,
            query,
            preGtbTcStatus,
            showDetailsAction,
        ]
    );
}
