import { joinClassNames } from "../../utils/StringUtils";
import GtbTextField from "../../components/Input/GtbTextField";
import "./certificationBodyBasicData.css";
import { CertificationBodyDetailViewTabProps } from "./CertificationBodyDetailView";
import OrganisationBasicData from "../../organisation/details/OrganisationBasicData";

function CertificationBodyBasicData({ form, readOnlyProps }: CertificationBodyDetailViewTabProps) {
    return (
        <div className={joinClassNames("certificationBodyBasicData", "organisationBasicData", "formGrid")}>
            <GtbTextField
                {...form.registerWithErrors("legalName")}
                readOnly={readOnlyProps.isLegalNameReadOnly}
                label="certificationBody.detail.legalName_input"
                className="legalName"
            />
            <OrganisationBasicData form={form} readOnlyProps={readOnlyProps} organisationI18nKey="certificationBody" />
        </div>
    );
}

export default CertificationBodyBasicData;
