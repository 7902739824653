import { TypedDetailViewProps } from "../../components/detailView/DetailView";
import "../../components/spinner.css";
import { useHelpContext } from "../../help/context-sensitivity/useHelpContext";
import EntityDetailView from "../../components/entity/EntityDetailView";
import Tabs, { TabKey, TabProps } from "../../components/tabs/Tabs";
import React, { useMemo } from "react";
import { revisionButtonVisibilityType, ScopeCertificateDetails } from "../scopeCertificateTypes";
import ScopeCertificateBasicData, { ScopeCertificateBasicDataProps } from "./ScopeCertificateBasicData";
import ScopeCertificateAdditionalData, { ScopeCertificateAdditionalDataProps } from "./ScopeCertificateAdditionalData";
import ScopeCertificateDocument, { ScopeCertificateDocumentProps } from "./ScopeCertificateDocument";
import ScProducts from "./products/ScProducts";
import { scopeCertificateStatusType } from "../../auth/roleTypes";

export default function ScopeCertificateDetailView({
    detailViewProps,
    scopeCertificateId,
    form,
    readOnly,
    withActiveCertificationRelation,
    isRecertificationExpiryVisible,
    subListVisible,
    humanReadableDocumentSize,
    documentTabVisible,
    scopeCertificateStatus,
    handleRecertification,
    revisionButtonVisibility,
    revisionButtonOnClickHandler,
    ...rest
}: ScopeCertificateDetailViewProps) {
    useHelpContext("scope-certificate-details");

    const documentTab = useMemo<TabProps<ScopeCertificateTabs>[]>(
        () => [
            {
                heading: "scopeCertificate.detail.scDocument_tab",
                element: (
                    <ScopeCertificateDocument
                        form={form}
                        humanReadableDocumentSize={humanReadableDocumentSize}
                        scopeCertificateId={scopeCertificateId}
                    />
                ),
            },
        ],
        [form, humanReadableDocumentSize, scopeCertificateId]
    );

    const sublistTabs = useMemo<TabProps<TabKey<"scopeCertificate.detail.scProducts_tab">>[]>(
        () => [
            {
                heading: "scopeCertificate.detail.scProducts_tab",
                element: <ScProducts scopeCertificateStatus={scopeCertificateStatus} />,
            },
        ],
        [scopeCertificateStatus]
    );

    return (
        <EntityDetailView {...rest} detailViewProps={detailViewProps} form={form}>
            <Tabs<ScopeCertificateDetails, ScopeCertificateTabs>
                tabs={[
                    {
                        heading: "scopeCertificate.detail.basicData_tab",
                        element: (
                            <ScopeCertificateBasicData
                                form={form}
                                scopeCertificateId={scopeCertificateId}
                                readOnly={readOnly}
                                isRecertificationExpiryVisible={isRecertificationExpiryVisible}
                                withActiveCertificationRelation={withActiveCertificationRelation}
                                handleRecertification={handleRecertification}
                                revisionButtonVisibility={revisionButtonVisibility}
                                revisionButtonOnClickHandler={revisionButtonOnClickHandler}
                            />
                        ),
                    },
                    ...(subListVisible ? sublistTabs : []),
                    {
                        heading: "scopeCertificate.detail.additionalData_tab",
                        element: <ScopeCertificateAdditionalData form={form} readOnly={readOnly} />,
                    },
                    ...(documentTabVisible ? documentTab : []),
                ]}
                errors={form.form.formState.errors}
                errorToTab={{
                    id: "internal",
                    supplyChainOperator: "scopeCertificate.detail.basicData_tab",
                    scNumber: "scopeCertificate.detail.basicData_tab",
                    revisionNumber: "scopeCertificate.detail.basicData_tab",
                    scRevisionNumber: "internal",
                    standardVersion: "scopeCertificate.detail.basicData_tab",
                    certificationBody: "scopeCertificate.detail.basicData_tab",
                    cbScReferenceNumber: "scopeCertificate.detail.basicData_tab",
                    validFrom: "scopeCertificate.detail.basicData_tab",
                    validUntil: "scopeCertificate.detail.basicData_tab",
                    status: "scopeCertificate.detail.basicData_tab",
                    dateOfIssue: "scopeCertificate.detail.basicData_tab",
                    placeOfIssue: "scopeCertificate.detail.basicData_tab",
                    approvingPerson: "scopeCertificate.detail.basicData_tab",
                    recertificationExpected: "scopeCertificate.detail.basicData_tab",
                    recertificationExpiryDate: "scopeCertificate.detail.basicData_tab",
                    additionalDeclaration: "scopeCertificate.detail.additionalData_tab",
                    certificateOrigin: "scopeCertificate.detail.scDocument_tab",
                    certificateDocument: "scopeCertificate.detail.scDocument_tab",
                    cbCertificate: "scopeCertificate.detail.scDocument_tab",
                    documentSize: "scopeCertificate.detail.scDocument_tab",
                    documentHash: "scopeCertificate.detail.scDocument_tab",
                    hashAlgorithm: "scopeCertificate.detail.scDocument_tab",
                    recertifyable: "internal",
                    revisionId: "internal",
                }}
            />
        </EntityDetailView>
    );
}

type ScopeCertificateTabs = TabKey<
    | "scopeCertificate.detail.basicData_tab"
    | "scopeCertificate.detail.scProducts_tab"
    | "scopeCertificate.detail.additionalData_tab"
    | "scopeCertificate.detail.scDocument_tab"
>;

export interface ScopeCertificateDetailViewProps extends TypedDetailViewProps<ScopeCertificateDetails> {
    scopeCertificateId?: string;
    readOnly: ScopeCertificateBasicDataProps["readOnly"] & ScopeCertificateAdditionalDataProps["readOnly"];
    withActiveCertificationRelation?: ScopeCertificateBasicDataProps["withActiveCertificationRelation"];
    documentTabVisible: boolean;
    isRecertificationExpiryVisible: ScopeCertificateBasicDataProps["isRecertificationExpiryVisible"];
    humanReadableDocumentSize: ScopeCertificateDocumentProps["humanReadableDocumentSize"];
    subListVisible: boolean;
    revisionButtonVisibility: revisionButtonVisibilityType;
    revisionButtonOnClickHandler?: () => void;
    scopeCertificateStatus?: scopeCertificateStatusType;
    handleRecertification?: () => void;
}
