import * as React from "react";
import useGtbTranslation, { I18nKey } from "../../i18n/useGtbTranslation";

interface DialogContentProps {
    message: I18nKey;
}

function DialogTextContent({ message }: DialogContentProps) {
    const translation = useGtbTranslation();

    return <p className={"dialog-content"}>{translation(message)}</p>;
}

export default DialogTextContent;
