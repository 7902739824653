import useIsAuthorised from "../auth/useIsAuthorised";
import { SortOption } from "../components/grid/component/useQueryBuilder";
import { useMemo } from "react";
import { GridColumn } from "../components/grid/component/Grid";
import useListView from "../hooks/useListView";
import { useDeleteAction, useShowDetailsAction } from "../utils/defaultActions";
import {
    buildDataColumn,
    buildDateColumn,
    buildNumberColumn,
    buildTextColumn,
} from "../components/grid/ColumnProvider";
import useResolvedRoute, { getCreateUrl } from "../components/routing/useResolvedRoute";
import { OrganicMaterialProducerDetails, OrganicMaterialProducerSummary } from "./organicMaterialProducerTypes";
import { EntityListViewGridProps } from "../components/entity/EntityListView";
import { EnumEntityType } from "../components/Input/pickList/typedEnumPicklist/enumEntityType";
import useOrganicMaterialProducerDeduplicateAction from "./useOrganicMaterialProducerDeduplicateAction";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";

export const defaultOrganicMaterialProducerSortOption: SortOption<
    OrganicMaterialProducerSummary | OrganicMaterialProducerDetails
> = {
    field: "systemId",
    direction: "ASC",
};

const columns: GridColumn<OrganicMaterialProducerSummary>[] = [
    buildTextColumn({ key: "systemId", title: "organicMaterialProducer.list.systemId_column", minWidth: 134 }),
    buildTextColumn({ key: "businessName", title: "organicMaterialProducer.list.businessName_column", minWidth: 174 }),
    buildTextColumn({
        key: "stateProvinceCountryAreaName",
        title: "organicMaterialProducer.list.countryArea_column",
        minWidth: 155,
    }),
    buildTextColumn({
        key: "stateProvinceName",
        title: "organicMaterialProducer.list.stateProvince_column",
        minWidth: 151,
    }),
    buildDataColumn<OrganicMaterialProducerSummary, EnumEntityType>({
        key: "organisationStatus",
        title: "organicMaterialProducer.list.status_column",
        minWidth: 190,
        url: "/certificate-service/references/organisation-statuses",
        itemId: "id",
        itemLabel: "name",
    }),
    buildTextColumn({
        key: "addressDetails1",
        title: "organicMaterialProducer.list.addressDetail1_column",
        minWidth: 180,
        visible: false,
    }),
    buildTextColumn({
        key: "addressDetails2",
        title: "organicMaterialProducer.list.addressDetail2_column",
        minWidth: 180,
        visible: false,
    }),
    buildTextColumn({
        key: "addressDetails3",
        title: "organicMaterialProducer.list.addressDetail3_column",
        minWidth: 180,
        visible: false,
    }),
    buildDateColumn({
        key: "endDate",
        title: "organicMaterialProducer.list.endDate_column",
        minWidth: 124,
        visible: false,
    }),
    buildTextColumn({
        key: "fixedRegistrationId",
        title: "supplyChainOperator.list.fixedRegistrationId_column",
        minWidth: 195,
        visible: false,
    }),
    buildTextColumn({
        key: "legalName",
        title: "organicMaterialProducer.list.legalName_column",
        minWidth: 145,
        visible: false,
    }),
    buildTextColumn({
        key: "legalNameInEnglish",
        title: "organicMaterialProducer.list.legalNameInEnglish_column",
        minWidth: 145,
        visible: false,
    }),
    buildTextColumn({
        key: "organisationalEmail",
        title: "organicMaterialProducer.list.organisationalEmailAddress_column",
        minWidth: 280,
        visible: false,
    }),
    buildTextColumn({
        key: "organisationalPhoneNumber",
        title: "organicMaterialProducer.list.organisationalPhoneNumber_column",
        minWidth: 284,
        visible: false,
    }),
    buildTextColumn({
        key: "postCode",
        title: "organicMaterialProducer.list.postcode_column",
        minWidth: 128,
        visible: false,
    }),
    buildDateColumn({
        key: "startDate",
        title: "organicMaterialProducer.list.startDate_column",
        minWidth: 130,
        visible: false,
    }),
    buildTextColumn({ key: "town", title: "organicMaterialProducer.list.town_column", minWidth: 123, visible: false }),
    buildTextColumn({
        key: "website",
        title: "organicMaterialProducer.list.website_column",
        minWidth: 123,
        visible: false,
    }),
    buildNumberColumn({
        key: "latitude",
        title: "organicMaterialProducer.list.latitude_column",
        allowDecimals: true,
        allowNegative: true,
        minWidth: 119,
        visible: false,
    }),
    buildNumberColumn({
        key: "longitude",
        title: "organicMaterialProducer.list.longitude_column",
        allowDecimals: true,
        allowNegative: true,
        minWidth: 134,
        visible: false,
    }),
];

export default function useOrganicMaterialProducerListView() {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedEditUrl } = useResolvedRoute();

    const {
        queryBuilder: { forceReload, columnOptions, query, queryCommands },
    } = useListView<OrganicMaterialProducerSummary>({
        navigationContextLabel: "organicMaterialProducer.breadcrumb",
        baseQuery: "/certificate-service/organic-material-producers",
        defaultSortOption: defaultOrganicMaterialProducerSortOption,
    });

    const showDetailsAction = useShowDetailsAction(
        (item: OrganicMaterialProducerSummary) => getResolvedEditUrl("organicMaterialProducer", item.id),
        query.parameter
    );

    const deleteAction = useDeleteAction(
        (item: OrganicMaterialProducerSummary) => `/certificate-service/organic-material-producers/${item.id}`,
        () => forceReload(),
        "deleteOperationLevelOrganisation"
    );

    const deduplicateAction = useOrganicMaterialProducerDeduplicateAction(() => forceReload());

    const actions = useGridRowContextMenuActions(showDetailsAction, deleteAction, deduplicateAction);

    return useMemo<EntityListViewGridProps<OrganicMaterialProducerSummary>>(() => {
        return {
            query,
            columns: columns,
            uniqueDataKey: "id",
            onItemDoubleClick: showDetailsAction,
            createUrl: hasAccessRight("createOperationLevelOrganisation")
                ? getCreateUrl("organicMaterialProducer")
                : undefined,
            tableName: "organicMaterialProducer",
            columnOptions,
            itemActions: actions,
            queryCommands,
            bulkDeleteAccessRight: () => hasAccessRight("deleteOperationLevelOrganisation"),
        };
    }, [query, showDetailsAction, hasAccessRight, columnOptions, actions, queryCommands]);
}
