import useResolvedRoute from "../../../../components/routing/useResolvedRoute";
import { useQuery } from "../../../../hooks/useAxios";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { buildScProductValidationSchema } from "./scProductValidationSchema";
import { useMemo } from "react";

export default function useScProductDetails() {
    const { getResolvedId } = useResolvedRoute();
    const scopeCertificateId = getResolvedId("scopeCertificate")!;

    const { data: labelGrades, isLoading: labelGradesLoading } = useQuery<[]>({
        url: `/certificate-service/references/label-grades?scopeCertificateId=${scopeCertificateId}`,
    });

    const labelGradesVisible = useMemo(() => !!labelGrades?.length, [labelGrades?.length]);

    return {
        scopeCertificateId,
        resolver: yupResolver(buildScProductValidationSchema(labelGradesVisible)),
        labelGradesVisible,
        labelGradesLoading,
    };
}
