import { joinClassNames } from "../../utils/StringUtils";

import GtbTextField from "../../components/Input/GtbTextField";
import { LabeledCheckbox } from "../../components/Input/Checkbox";
import "./countryAreaBasicData.css";
import ControlledDatePicker from "../../components/Input/datepicker/ControlledDatePicker";
import SubList from "../../components/grid/SubList";
import { CountryAreaDetailViewTabProps } from "./CountryAreaDetailView";
import useStateProvinceSubList from "../../stateProvince/useStateProvinceSubList";
import CurrentLanguageWatchField from "../../i18n/CurrentLanguageWatchField";

function CountryAreaBasicData({ form, readOnly, subListVisible }: CountryAreaDetailViewTabProps) {
    const stateProvinceListOptions = useStateProvinceSubList();
    return (
        <div className={joinClassNames("countryAreaBasicData", "formGrid")}>
            <CurrentLanguageWatchField
                form={form}
                field={"name"}
                label="countryArea.detail.name_input"
                className="name"
            />
            <GtbTextField
                {...form.registerWithErrors("isoCode")}
                readOnly={readOnly}
                label={"countryArea.detail.isoCode_input"}
                className="isoCode"
            />
            <LabeledCheckbox
                {...form.form.register("subdivisionsMandatory")}
                readOnly={readOnly}
                label={"countryArea.detail.subdivisionsMandatory_input"}
                id="subdivisionsMandatory"
                className="subdivisionsMandatory"
            />
            <LabeledCheckbox
                {...form.form.register("nameTranscriptions")}
                readOnly={readOnly}
                id={"allowNameTranscriptions"}
                className={"allowNameTranscriptions"}
                label={"countryArea.detail.name_transcriptions_checkbox"}
            />
            <GtbTextField
                {...form.registerWithErrors("callingCode")}
                readOnly={readOnly}
                label={"countryArea.detail.callingCode_input"}
                className="callingCode"
            />

            <GtbTextField
                {...form.registerWithErrors("registrationIdType")}
                readOnly={readOnly}
                label={"countryArea.detail.registrationIdType_input"}
                className="registrationIdType"
            />
            <GtbTextField
                {...form.registerWithErrors("registrationIdTypeOmp")}
                readOnly={readOnly}
                label={"countryArea.detail.registrationIdTypeOmp_input"}
                className="registrationIdTypeOmp"
            />

            <ControlledDatePicker
                control={form.form.control}
                readOnly={readOnly}
                name="startDate"
                label={"countryArea.detail.startDate_input"}
                className="startDate"
            />
            <ControlledDatePicker
                control={form.form.control}
                readOnly={readOnly}
                name="endDate"
                label="countryArea.detail.endDate_input"
                className="endDate"
            />

            {subListVisible && <SubList {...stateProvinceListOptions} className="stateProvince" />}
        </div>
    );
}

export default CountryAreaBasicData;
